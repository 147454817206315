/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Grid, GridCell } from '@rmwc/grid';
import { Checkbox } from '@rmwc/checkbox';
import {
  InputLabel,
  FormControl,
  NativeSelect,
  TextField,
} from '@material-ui/core';

import TimeField from 'react-simple-timefield';

import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';
import moment from '../utils/moment';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const validateDate = (date) => {
  console.log('VALIDATING DATE: ', date);
  if (!date) {
    return 'Pvm on pakollinen';
  }
  return null;
};

// const validateTime = (time) => {
//   if (!time) {
//     return 'Klo on pakollinen';
//   }
//   return null;
// };

const validateWorkSteps = (workSteps) => {
  if (!workSteps) {
    return 'Vaiheet ovat pakollisia';
  }
  return null;
};

const validateEventSteps = (eventSteps) => {
  if (!eventSteps) {
    return 'Tapahtumien kulku on pakollinen';
  }
  return null;
};

const validateCauses = (causes) => {
  if (!causes) {
    return 'Vaaratilanteen aiheuttajat ovat pakollisia';
  }
  return null;
};

const validateFixSuggestions = (fixSuggestions) => {
  if (!fixSuggestions) {
    return 'Ehdotukset ovat pakollisia';
  }
  return null;
};

const validateRelatedObject = (relatedObject) => {
  if (!relatedObject) {
    return 'Tapahtumiin osallinen asia on pakollinen';
  }
  return null;
};

const validate = {
  date: (date) => validateDate(date),
  // time: (time) => validateTime(time),
  workSteps: (workSteps) => validateWorkSteps(workSteps),
  eventSteps: (eventSteps) => validateEventSteps(eventSteps),
  causes: (causes) => validateCauses(causes),
  fixSuggestions: (fixSuggestions) => validateFixSuggestions(fixSuggestions),
  relatedObject: (relatedObject) => validateRelatedObject(relatedObject),
};

@inject('uiStore', 'loginStore', 't', 'accidentReportStore')
@observer
class NewCloseCallReportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id: props.id,
      // description: props.description,
      // fileURL: '',
      // filename: '',
      // files: props.files || [],
      // file: props.file,
      // name: props.name,
      // status: props.status,
      errors: {},
      // inputChanged: false,
      accidentReport: props.initialData || {
        disablementDurationEstimate: 'unknown',
        treatmentStartImmediacy: 'now',
        victimStoppedWorkingImmediacy: 'no',
      },
    };
  }

  handleSelectChange = (attr, value) => {
    this.setState((prevState) => ({
      accidentReport: { ...prevState.accidentReport, [attr]: value },
    }));
  };

  addTextField(variant, attr, label, required, styles = null, multiline, applyStyle = false) {
    const { errors, accidentReport } = this.state;

    const inputStyles = applyStyle ? { marginTop: '10px' } : {};

    return (
      <TextField {...{
        style: { ...styles },
        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
        variant,
        label,
        required,
        name: attr,
        rows: 4,
        invalid: errors[attr],
        helperText: errors[attr] && errors[attr],
        multiline,
        inputProps: {
          style: {
            ...inputStyles,
          },
        },
        onChange: (event) => {
          const { value } = event.target;
          this.setState((prevState) => ({
            accidentReport: { ...prevState.accidentReport, [attr]: value },
          }));
        },
        onKeyPress: handleEnter,
        value: accidentReport[attr] != null ? accidentReport[attr] : '',
      }}
      />
    );
  }

  handleValidate() {
    const { accidentReport } = this.state;

    let errors = {};

    Object.keys(validate).forEach((key) => {
      const error = validate[key](accidentReport[key]);
      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    this.setState({
      errors: { ...errors },
    });
  }

  addHourField(attr, label = null) {
    // const { disabled, updateStartTrip, maxStartDate, maxStartTime } = this.props;
    const { errors, accidentReport } = this.state;

    return (
      <div
        className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label mdc-theme--secondary-bg mui-label-dark"
        style={{
          width: '100%',
          color: 'white',
          flexDirection: 'column',
          borderRadius: '4px 4px 0px 0px',
        }}
      >
        <div style={{ fontSize: '12px', paddingLeft: '5px' }}>
          {label ? label : 'Klo.'} {/* eslint-disable-line */}
        </div>
        <TimeField
          value={(this.state != null && accidentReport[attr] != null && accidentReport[attr] !== '') ? accidentReport[attr] : '00:00'}
          onChange={(_, value) => {
            if (value.includes(':')) {
              value = value.replace('.', ''); // eslint-disable-line
            } else {
              value = value.replace('.', ':'); // eslint-disable-line
            }
            this.setState((prevState) => ({
              accidentReport: { ...prevState.accidentReport, [attr]: value },
            }));
          }}
          invalid={errors[attr]}
          colon=":"
          style={{ color: 'black' }}
          input={<input type="text" className="mdc-text-field__input" />}
          onKeyPress={handleEnter}
          onClick={(event) => event.target.setSelectionRange(0, 0)}
        />
      </div>
    );
  }

  renderActionButtons() {
    const {
      accidentReport: {
        date,
        workSteps,
        eventSteps,
        relatedObject,
        causes,
        fixSuggestions,
      },
    } = this.state;

    return (
      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <DialogButton
          type="button"
          className="accept-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          onClick={() => {
            this.handleValidate();
          }}
          action={date && workSteps && eventSteps && relatedObject && causes && fixSuggestions ? 'submit' : null}
        >
          Lähetä
        </DialogButton>

        <DialogButton
          type="button"
          // disabled={this.saveAsDraftButtonDisabled()}
          className="accept-button"
          style={{
            fontSize: '13px',
          }}
          onClick={() => this.saveAsDraft()}
          action="cancel"
        >
          Tallenna luonnos
        </DialogButton>

        <DialogButton
          type="button"
          style={{
            color: '#c7c9cc',
            border: '1px solid #c7c9cc',
            borderRadius: '0px',
            minWidth: '100px',
          }}
          action="cancel"
        >
          Peruuta
        </DialogButton>
      </DialogActions>
    );
  }

  saveAsDraft() {
    const { accidentReport } = this.state;
    const { accidentReportStore } = this.props;

    // Set the 'status' field to 1 to indicate it's a draft
    const draftAccidentReport = { ...accidentReport, status: 1 };

    if (accidentReport.id) {
      // If there's an existing draft ID, update the existing draft
      accidentReportStore.update(draftAccidentReport, () => {
        // Successfully updated the draft, you can add any necessary logic here
        console.log('Draft updated successfully.');
      }, (error) => {
        // Handle the error if the draft couldn't be updated
        console.error('Error updating the draft accident report:', error);
      });
    } else {
      // If there's no existing draft ID, create a new draft
      accidentReportStore.create(draftAccidentReport, () => {
        // Successfully saved the draft, you can add any necessary logic here
        console.log('Draft accident report saved successfully.');
      }, (error) => {
        // Handle the error if the draft couldn't be saved
        console.error('Error saving the draft accident report:', error);
      });
    }
  }

  render() {
    const
      {
        t,
        open,
        styles,
        handleClose,
        accidentReportStore,
      } = this.props;
    const { accidentReport, errors } = this.state;

    // WHAT IS THIS?
    // const inputRef = React.createRef();

    return (
      <Dialog
        className="modal-size trip-route-dialog red-helper-texts"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'submit' && Object.keys(errors).length === 0) {
            // Modify the accidentReport object to set the status to 0 (not a draft)
            const updatedAccidentReport = { ...accidentReport, status: 0 };

            // Check if the report already has an ID, which indicates it's an existing report
            if (updatedAccidentReport.id) {
              const sendPromise = fromPromise(
                new Promise((resolve, reject) =>
                  accidentReportStore.update(updatedAccidentReport, resolve, reject)
                )
              );

              when(
                () => sendPromise.state !== 'pending',
                () => {
                  sendPromise.case({
                    pending: () => { },
                    rejected: (err) => {
                      console.log(err);
                    },
                    fulfilled: () => {
                      handleClose();
                    },
                  });
                }
              );
              handleClose();
            } else {
              // If the report doesn't have an ID, it's a new report, so save it
              const sendPromise = fromPromise(
                new Promise((resolve, reject) =>
                  accidentReportStore.create(updatedAccidentReport, resolve, reject)
                )
              );

              when(
                () => sendPromise.state !== 'pending',
                () => {
                  sendPromise.case({
                    pending: () => { },
                    rejected: (err) => {
                      console.log('Tallennus epäonnistui', err);
                    },
                    fulfilled: (createdReport) => {
                      this.props.newAccidentReport(createdReport);
                      this.setState({
                        accidentReport: {
                          disablementDurationEstimate: 'unknown',
                          treatmentStartImmediacy: 'now',
                          victimStoppedWorkingImmediacy: 'no',
                        },
                      });
                      handleClose();
                    },
                  });
                }
              );
            }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle style={{ color: 'var(--mdc-theme-primary, #6200ee)' }}>Uusi tapaturmailmoitus</DialogTitle>
        <DialogContent>
          {/* hack to remove autofocus on calendar */}
          <input style={{ width: '1px', height: '0px', opacity: '0' }} />
          <Grid>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              <DayPickerInput
                component={
                  (props) => (
                    <TextField
                      {...props}
                      {...{
                        required: true,
                        label: 'Tapahtuma-pvm.',
                        variant: 'filled',
                        style: { width: '100%' },
                        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
                        helperText: errors.date && errors.date,
                      }}
                    />
                  )
                }
                inputProps={{
                  ref: null,
                  className: '',
                  // readOnly: true,
                  // disabled: directionTrip.status === 'accepted',
                }}
                dayPickerProps={{
                  locale: 'fi',
                  localeUtils: MomentLocaleUtils,
                }}

                formatDate={formatDate}
                parseDate={parseDate}
                keepFocus={false}
                invalid={errors.date}
                format="dd DD.MM.YYYY"
                // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
                onDayChange={(value) => this.setState((prevState) => ({
                  accidentReport: { ...prevState.accidentReport, date: moment(value) },
                }))}
                value={accidentReport.date != null ? moment(accidentReport.date).format('YYYY-MM-DD') : ''}
                classNames={{
                  container: '',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay forced-white-text',
                }}
              />
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('time', null)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'location', 'Tapahtumapaikka', false, { ...styles, width: '100%' }, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'workSteps', 'Työvaihe tai -vaiheet, joita tehtiin', true, { ...styles, width: '100%' }, true)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'relatedObject', 'Tapahtumiin osallinen kone, laite tai kemiallinen aine', true, { ...styles, width: '100%' }, false, true)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'eventSteps', 'Tapahtumien kulku vaiheittain', true, { ...styles, width: '100%' }, true)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'causes', 'Vaaratilanteen aiheuttajat', true, { ...styles, width: '100%' }, true)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'fixSuggestions', 'Ehdotetut korjaustoimenpiteet', true, { ...styles, width: '100%' }, true)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'victimName', 'Vahingoittuneen nimi', false, { ...styles, width: '100%' }, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'victimUnit', 'Yksikkö ja/tai osasto', false, { ...styles, width: '100%' }, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'victimTask', 'Vahingoittuneen työtehtävä / toimi', false, { ...styles, width: '100%' }, false)}
            </GridCell>

            <GridCell span={12} style={{ color: 'white', marginBottom: '-20px' }}>Työn piti tapaturmapäivänä</GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('workFrom', 'Alkaa')}
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('workTo', 'Loppua')}
            </GridCell>

            <GridCell span={12}>
              <FormControl
                variant="filled"
                style={{
                  width: '100%',
                }}
                className="mdc-theme--secondary-bg mui-select-dark"
              >
                <InputLabel
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    zIndex: '0',
                  }}
                >
                  Lopettiko vahingoittunut työnsä tapaturman satuttua
                </InputLabel>
                <NativeSelect
                  style={{
                    marginTop: '30px',
                  }}
                  className="mdc-theme--text-primary-on-dark"
                  {...{
                    onChange: (evt) => this.handleSelectChange('victimStoppedWorkingImmediacy', evt.target.value),
                    value: accidentReport.victimStoppedWorkingImmediacy,
                  }}
                >
                  <option key="no" value="no">{t('accident_reports.victimStoppedWorkingImmediacys.no')}</option>
                  <option key="immediately" value="immediately">{t('accident_reports.victimStoppedWorkingImmediacys.immediately')}</option>
                  <option key="later" value="later">{t('accident_reports.victimStoppedWorkingImmediacys.later')}</option>
                </NativeSelect>
              </FormControl>
            </GridCell>

            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              <DayPickerInput
                component={
                  (props) => (
                    <TextField
                      {...props}
                      {...{
                        label: 'Pvm.',
                        variant: 'filled',
                        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
                        // placeholder: '',
                        style: { width: '100%' },
                      }}
                    />
                  )
                }
                inputProps={{
                  ref: null,
                  className: '',
                  // disabled: directionTrip.status === 'accepted',
                }}
                dayPickerProps={{
                  locale: 'fi',
                  localeUtils: MomentLocaleUtils,
                  // modifiers: {
                  //   disabled: [
                  //     {
                  //       before: maxStartDate && moment(maxStartDate, 'YYYY-MM-DD', 'fi').toDate()
                  //     }
                  //   ]
                  // }
                }}

                formatDate={formatDate}
                parseDate={parseDate}
                keepFocus={false}
                invalid={errors.victimStoppedWorkingDate}
                helperText={errors.victimStoppedWorkingDate && errors.victimStoppedWorkingDate}
                format="dd DD.MM.YYYY"
                // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
                onDayChange={(value) => this.setState((prevState) => ({
                  accidentReport: { ...prevState.accidentReport, victimStoppedWorkingDate: moment(value) },
                }))}
                value={accidentReport.victimStoppedWorkingDate ? moment(accidentReport.victimStoppedWorkingDate, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                classNames={{
                  container: '',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay forced-white-text',
                }}
              />
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('victimStoppedWorkingTime')}
            </GridCell>

            <GridCell span={12}>
              <FormControl
                variant="filled"
                style={{
                  width: '100%',
                }}
                className="mdc-theme--secondary-bg mui-select-dark"
              >
                <InputLabel
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    zIndex: '0',
                  }}
                >
                  Arvio työkyvyttömyyden kestosta
                </InputLabel>
                <NativeSelect
                  style={{
                    marginTop: '30px',
                  }}
                  className="mdc-theme--text-primary-on-dark"
                  {...{
                    onChange: (evt) => this.handleSelectChange('disablementDurationEstimate', evt.target.value),
                    value: accidentReport.disablementDurationEstimate,
                  }}
                >
                  <option key="unknown" value="unknown">{t('accident_reports.disablementDurationEstimates.unknown')}</option>
                  <option key="accident_day" value="accident_day">{t('accident_reports.disablementDurationEstimates.accident_day')}</option>
                  <option key="one_to_two_days" value="one_to_two_days">{t('accident_reports.disablementDurationEstimates.one_to_two_days')}</option>
                  <option key="three_days_to_a_month" value="three_days_to_a_month">{t('accident_reports.disablementDurationEstimates.three_days_to_a_month')}</option>
                  <option key="over_a_month" value="over_a_month">{t('accident_reports.disablementDurationEstimates.over_a_month')}</option>
                </NativeSelect>
              </FormControl>
            </GridCell>

            <GridCell span={12}>
              <FormControl
                variant="filled"
                style={{
                  width: '100%',
                }}
                className="mdc-theme--secondary-bg mui-select-dark"
              >
                <InputLabel
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    zIndex: '0',
                  }}
                >
                  Sairaanhoidon alkaminen
                </InputLabel>
                <NativeSelect
                  style={{
                    marginTop: '30px',
                  }}
                  className="mdc-theme--text-primary-on-dark"
                  {...{
                    label: 'Sairaanhoidon alkaminen',
                    onChange: (evt) => this.handleSelectChange('treatmentStartImmediacy', evt.target.value),
                    value: accidentReport.treatmentStartImmediacy,
                  }}
                >
                  <option key="now" value="now">{t('accident_reports.treatmentStartImmediacys.now')}</option>
                  <option key="after" value="after">{t('accident_reports.treatmentStartImmediacys.after')}</option>
                </NativeSelect>
              </FormControl>
            </GridCell>

            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              <DayPickerInput
                component={
                  (props) => (
                    <TextField
                      {...props}
                      {...{
                        label: 'Pvm.',
                        variant: 'filled',
                        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
                        // placeholder: '',
                        style: { width: '100%' },
                      }}
                    />
                  )
                }
                inputProps={{
                  ref: null,
                  className: '',
                  // disabled: directionTrip.status === 'accepted',
                }}
                dayPickerProps={{
                  locale: 'fi',
                  localeUtils: MomentLocaleUtils,
                }}

                formatDate={formatDate}
                parseDate={parseDate}
                keepFocus={false}
                invalid={errors.date}
                helperText={errors.date && errors.date}
                format="dd DD.MM.YYYY"
                // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
                onDayChange={(value) => this.setState((prevState) => ({
                  accidentReport: { ...prevState.accidentReport, treatmentStartDate: moment(value) },
                }))}
                value={accidentReport.treatmentStartDate ? moment(accidentReport.treatmentStartDate, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                classNames={{
                  container: '',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay forced-white-text',
                }}
              />
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('treatmentStartTime')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'treatmentPlace', 'Hoitopaikan nimi', false, { ...styles, width: '100%' }, false)}
            </GridCell>

            <GridCell span={12} style={{ color: 'white', marginBottom: '-20px' }}>Milloin tapaturmasta on ilmoitettu esimiehelle?</GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              <DayPickerInput
                component={
                  (props) => (
                    <TextField
                      {...props}
                      {...{
                        label: 'Pvm.',
                        variant: 'filled',
                        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
                        // placeholder: '',
                        style: { width: '100%' },
                      }}
                    />
                  )
                }
                inputProps={{
                  ref: null,
                  className: '',
                  // disabled: directionTrip.status === 'accepted',
                }}
                dayPickerProps={{
                  locale: 'fi',
                  localeUtils: MomentLocaleUtils,
                }}

                formatDate={formatDate}
                parseDate={parseDate}
                keepFocus={false}
                invalid={errors.employerInformedDate}
                helperText={errors.employerInformedDate && errors.employerInformedDate}
                format="dd DD.MM.YYYY"
                // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
                onDayChange={(value) => this.setState((prevState) => ({
                  accidentReport: { ...prevState.accidentReport, employerInformedDate: moment(value) },
                }))}
                value={accidentReport.employerInformedDate ? moment(accidentReport.employerInformedDate, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                classNames={{
                  container: '',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay forced-white-text',
                }}
              />
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('employerInformedTime')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'injuryType', 'Vamman laatu (esim. luumurtuma)', false, { ...styles, width: '100%' }, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('filled', 'injuredBodyPart', 'Vahingoittunut ruumiinosa (esim. sormet)', false, { ...styles, width: '100%' }, false, true)}
            </GridCell>

            <GridCell span={6}>
              <Checkbox
                className="border-on-dark"
                style={{ color: 'white' }}
                label="Vasen"
                checked={accidentReport.leftBodyPart}
                onChange={() => {
                  this.setState((prevState) => ({
                    accidentReport: { ...prevState.accidentReport, leftBodyPart: !prevState.accidentReport.leftBodyPart },
                  }));
                }}
              />
            </GridCell>
            <GridCell span={6}>
              <Checkbox
                className="border-on-dark"
                style={{ color: 'white' }}
                label="Oikea"
                checked={accidentReport.rightBodyPart}
                onChange={() => {
                  this.setState((prevState) => ({
                    accidentReport: { ...prevState.accidentReport, rightBodyPart: !prevState.accidentReport.rightBodyPart },
                  }));
                }}
              />
            </GridCell>
          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default NewCloseCallReportDialog;
