/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle, List, ListItem, IconButton,
} from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import CallIcon from '@material-ui/icons/Call';

@inject('timelogStore', 'uiStore', 't')
@observer
class EmployeeInfoDialog extends Component {
  renderHomeAddress = (street, city, zipCode) => {
    let mapsQuery = '';

    const mapsAddress = `${street}%2C+${city}`;
    const mapsParams = mapsAddress.replace(/ /g, '+');

    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      mapsQuery = `http://maps.apple.com/?q=${mapsParams}`;
    } else {
      mapsQuery = `http://maps.google.com/?q=${mapsParams}`;
    }

    return (
      <>
        {(mapsQuery !== '') && (
          <span style={{ width: '100%' }}>
            {mapsQuery !== '' && (
              <a
                href={!street && !city && !zipCode ? null : mapsQuery}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', color: 'black' }}>
                  <span style={{ fontSize: '14px' }}>Kotisoite</span>
                  <span>
                    {!street && !city && !zipCode ? 'Osoitetta ei syötetty' : ''}
                    {street ? street : ''}
                    {(street && (zipCode || city)) ? ', ' : ''}
                    {zipCode ? zipCode : ''}
                    {(zipCode && city) ? ', ' : ''}
                    {city ? city : ''}
                  </span>
                </div>

                <IconButton
                  aria-label="location"
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: '0px',
                    fontSize: '20px',
                    border: '1px grey solid',
                    marginRight: '15px',
                  }}
                >
                  <PlaceIcon />
                </IconButton>
              </a>
            )}
          </span>
        )}
      </>
    );
  };

  renderPhoneNumber = (phoneNumber) => {
    return (
      <span style={{ width: '100%' }}>
        {phoneNumber !== '' && (
          <a
            href={phoneNumber ? `tel: ${phoneNumber}` : null}
            style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', color: 'black' }}>
              {/* <span style={{ color: 'black', fontSize: '14px' }}>Kotisoite</span> */}
              <span>
                {phoneNumber ? phoneNumber : 'Puhelinnumeroa ei syötetty'}
              </span>
            </div>

            <IconButton
              aria-label="location"
              style={{
                width: '40px',
                height: '40px',
                padding: '0px',
                fontSize: '20px',
                border: '1px grey solid',
                marginRight: '15px',
              }}
            >
              <CallIcon />
            </IconButton>
          </a>
        )}
      </span>
    );
  };

  render() {
    const { open, onClose, employee } = this.props;

    return (
      <Dialog
        className="employer-delete-modal work-order-trip-card-dialog"
        open={open}
        onClose={(evt) => {
          evt.stopPropagation();
          onClose();
        }}
      >
        <DialogTitle>{employee?.full_name}</DialogTitle>
        <DialogContent
          style={{ fontSize: '16px', color: 'black' }}
        >
          <List sx={{ width: '100%', maxWidth: 500 }}>
            <ListItem disableGutters>
              {this.renderHomeAddress(employee?.street, employee?.city, employee?.zip_code)}
            </ListItem>

            <ListItem disableGutters>
              {this.renderPhoneNumber(employee?.phone_number)}
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions
          style={{
            // justifyContent: 'flex-end',
            padding: '15px',
          }}
        >
          <Button
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
            }}
            onClick={() => onClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EmployeeInfoDialog;
