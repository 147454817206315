import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Button } from '@material-ui/core';
// import {
//   SimpleListItem,
//   ListItem,
//   CollapsibleList,
// } from '@rmwc/list';
import {
  Dialog,
  DialogContent,
  DialogButton,
  DialogActions,
} from '@rmwc/dialog';

import AddIcon from '@material-ui/icons/Add';

import CollapsibleValidity from './collapsibleValidity';
import ValidityDialog from './validityDialog';
import './userValidities.css';

// const handleEnter = (e) => {
//   if (e.key === 'Enter') {
//     document.activeElement.blur();
//   }
// };

@inject('validityStore', 'uiStore', 't')
@observer
class UserValidities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      openedValidity: null,
      deleteDialogOpen: false,
      deletingValidity: null,
    };
  }

  // doOnBlur(event, attr) {
  //   const { validityStore } = this.props;
  //   const { validity } = this.state;

  //   console.log('Do on blur');
  //   console.log('Value?: ', event.target.value);
  //   console.log('Attr: ', attr);

  //   validityStore.updateValidity(validity, )

  //   // this.setState({ validity: { ...validity, [attr]: e.target.value } });
  //   // Should save to DB here
  // }

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  }

  openDialog = (validity) => {
    this.setState({
      dialogOpen: true,
      openedValidity: validity,
    });
  }

  confirmDelete = (entity) => {
    // Open modal here
    this.setState({ deleteDialogOpen: true, deletingValidity: entity });
  }

  delete() {
    const { validityStore } = this.props;
    const { deletingValidity } = this.state;

    const deletePromise = fromPromise(new Promise((resolve, reject) => validityStore.deleteValidity(deletingValidity, resolve, reject)));
    when(
      () => deletePromise.state !== 'pending',
      () => {
        deletePromise.case({
          pending: () => {},
          rejected: (e) => {
            console.log('Error: ', e);
          },
          fulfilled: () => {},
        });
      },
    );
  }

  save() {
    const { validityStore } = this.props;
    const { validity } = this.state;

    const updatePromise = fromPromise(new Promise((resolve, reject) => validityStore.updateValidity(validity, resolve, reject)));
    when(
      () => updatePromise.state !== 'pending',
      () => {
        updatePromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.log('Tallennus epäonnistui', err);
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (newValidity) => {
            // POSSIBLE TYPE CHANGE?
            this.setState({ validity: newValidity });
            // validity.updateProperties(newValidity);

            // this.setState({
            //   userProductCollection: newUserProductCollection,
            //   selectedProducts: newUserProductCollection.userProducts,
            // });
          },
        });
      },
    );
  }

  doOnChange(event, attr) {
    const { validity } = this.state;

    this.setState({ validity: { ...validity, [attr]: event.target.value } });
  }

  // componentDidMount() {
  //   const { workOrderId } = this.props;

  //   this.setState({
  //     workOrderId,
  //   });
  // }

  // Should be turned into its own shared component
  renderDeleteDialog() {
    const { t } = this.props;
    const { deleteDialogOpen } = this.state;

    return (
      <Dialog
        className="dialog-background"
        open={deleteDialogOpen}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.delete();
          }
          this.setState({ deleteDialogOpen: false });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{t('profile.dialog.deleteBody_validity')}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            className="employee-accept-button"
            action="accept"
          >
            Kyllä
          </DialogButton>
          <DialogButton
            className="employee-reject-button"
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { uiStore: { currentUser }, employee, openCard } = this.props;
    const { dialogOpen, openedValidity } = this.state;

    return (
      <>
        <ValidityDialog open={dialogOpen} validity={openedValidity} handleClose={this.handleDialogClose} />
        {this.renderDeleteDialog()}
        {(employee || currentUser).validities.map((validity) => (
          <CollapsibleValidity key={validity.id} validity={validity} openCard={openCard} confirmDelete={this.confirmDelete} disabled={employee} />
        ))}
        {!employee && (
          <div style={{ textAlign: 'center', margin: '20px 0' }}>
            <Button
              startIcon={<AddIcon />}
              className="accept-button mui-button-hover-overwrite"
              style={{
                margin: '10px',
                borderRadius: '0px',
              }}
              onClick={() => this.setState({ dialogOpen: true })}
            >
              Lisää uusi
            </Button>
          </div>
        )}
      </>
    );
  }
}

export default UserValidities;
