
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
// import moment from '../../utils/moment';
// import WorkOrderAvailability from '../WorkOrderAvailability';
import WorkTask from './WorkTask';
// import Location from '../models/Location';

export default class WorkTaskStore {
  cable = null;

  intervalId = null;

  @observable workTasks = fromPromise.resolve([]);

  constructor(uiStore, requests, employerWorkOrderStore, actionCableStore) {
    this.uiStore = uiStore;
    this.requests = requests;
    this.employerWorkOrderStore = employerWorkOrderStore;
    this.actionCableStore = actionCableStore;

    // Note: also called in App.js afterLogin()
    // If we do not subscribe here, refreshing the page will cut the cable (user already logged in so afterLogin doesn't run)
    if (!this.cable) {
      this.subscribe();
    }
  }

  subscribe = () => {
    this.cable = this.actionCableStore.cableOn
      ? this.actionCableStore.subscribe('EmployeeWorkTasksChannel', this.handleResponse)
      : null;

    // Ping-ponging once every 30 seconds to keep heroku from closing the connection
    this.intervalId = setInterval(() => {
      if (this.uiStore.currentUser && this.cable) {
        this.cable.ping();
      }
    }, 30000);
  }

  unsubscribe = () => {
    this.actionCableStore.unsubscribe(this.cable);
    clearInterval(this.intervalId);
  }

  handleResponse = (response) => {
    const { currentUser } = this.uiStore;

    if (response.method === 'create') {
      const createdWorkTask = WorkTask.fromJsonProperties(response.body);
      this.handleCreatedWorkTaskEmployee(createdWorkTask);
      if (currentUser.role === 'employer') {
        this.addToEmployerWorkTasks(createdWorkTask);
      }
    }

    if (response.method === 'update') {
      const updatedWorkTask = WorkTask.fromJsonProperties(response.body);
      this.handleUpdatedWorkTaskEmployee(updatedWorkTask);
    }

    if (response.method === 'delete') {
      const deletedWorkTask = WorkTask.fromJsonProperties(response.body);
      this.handleDeletedWorkTaskEmployee(deletedWorkTask);
    }
  }

  handleCreatedWorkTaskEmployee = (newWorkTask) => {
    const { currentUser } = this.uiStore;
    const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === newWorkTask.workOrderId);
    if (foundEmployeeWorkOrder) {
      foundEmployeeWorkOrder.workTasks = [newWorkTask, ...foundEmployeeWorkOrder.workTasks];
    }
  }

  handleUpdatedWorkTaskEmployee = (updatedWorkTask) => {
    const { currentUser } = this.uiStore;
    const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === updatedWorkTask.workOrderId);
    let foundWorkTaskIndex = -1;
    const updatedWorkTasks = [...foundEmployeeWorkOrder.workTasks];
    if (foundEmployeeWorkOrder) {
      foundWorkTaskIndex = updatedWorkTasks.findIndex((task) => task.id === updatedWorkTask.id);
      if (foundWorkTaskIndex !== -1) {
        updatedWorkTasks[foundWorkTaskIndex] = updatedWorkTask;
      }
    }
    foundEmployeeWorkOrder.workTasks = updatedWorkTasks;
  }

  handleDeletedWorkTaskEmployee = (deletedWorkTask) => {
    const { currentUser } = this.uiStore;
    const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === deletedWorkTask.workOrderId);
    let foundWorkTaskIndex = -1;
    const updatedWorkTasks = [...foundEmployeeWorkOrder.workTasks];
    if (foundEmployeeWorkOrder) {
      foundWorkTaskIndex = updatedWorkTasks.findIndex((task) => task.id === deletedWorkTask.id);
      if (foundWorkTaskIndex !== -1) {
        updatedWorkTasks.splice(foundWorkTaskIndex, 1);
      }
    }
    foundEmployeeWorkOrder.workTasks = updatedWorkTasks;
  }

  // Employer-only method
  @action createWorkTask(data, resolve) {
    this.requests.WorkTasks.create(data.workOrderId, data).then((response) => {
      const createdWorkTask = WorkTask.fromJsonProperties(response);
      const { workOrderCache } = this.employerWorkOrderStore;

      // Employer work order, assumed to always exist if this method is able to be called
      const foundEmployerWo = workOrderCache?.workOrders.find((wo) => wo.id === createdWorkTask.workOrderId);
      const updatedEmployerWorkTasks = [...foundEmployerWo.workTasks];
      // The created task is a child, push it beneath a parent
      if (createdWorkTask.parentId) {
        // const foundParentWorkTask = updatedEmployerWorkTasks.find((task) => task.id === createdWorkTask.parentId);
        // NOTE: ONLY WORKS WITH TWO-LEVEL HIERARCHY
        const foundParentTaskIndex = updatedEmployerWorkTasks.findIndex((workTask) => workTask.id === createdWorkTask.parentId);
        // Push the new child task into the parent's child tasks
        if (foundParentTaskIndex !== -1) {
          updatedEmployerWorkTasks[foundParentTaskIndex].childTasks.push(createdWorkTask);
        }
      } else {
        // Pushing as a new "parent" work task (top level of the hierarchy)
        updatedEmployerWorkTasks.push(createdWorkTask);
      }
      foundEmployerWo.changeAttribute('workTasks', updatedEmployerWorkTasks);

      resolve(createdWorkTask);
    });
  }

  @action createWorkTaskEmployee(data, resolve) {
    const { currentUser } = this.uiStore;

    this.requests.WorkTasks.create(data.workOrderId, data).then((response) => {
      const createdWorkTask = WorkTask.fromJsonProperties(response);
      // If the employee who created the task is also an employer, we also update the employer work orders
      // The action cable doesn't broadcast to the creator so we handle the update here
      if (currentUser.role === 'employer') {
        this.addToEmployerWorkTasks(createdWorkTask);
      }

      resolve(createdWorkTask);
    });
  }

  addToEmployerWorkTasks(createdWorkTask) {
    const { workOrderCache } = this.employerWorkOrderStore;
    // Employer work order, assumed to always exist if this method is able to be called
    const foundEmployerWo = workOrderCache?.workOrders.find((wo) => wo.id === createdWorkTask.workOrderId);
    if (foundEmployerWo) {
      const updatedEmployerWorkTasks = [...foundEmployerWo.workTasks];
      if (createdWorkTask.parentId) {
        // The created task is a child, push it beneath a parent
        // NOTE: ONLY WORKS WITH TWO-LEVEL HIERARCHY
        const foundParentTaskIndex = updatedEmployerWorkTasks.findIndex((workTask) => workTask.id === createdWorkTask.parentId);
        // Push the new child task into the parent's child tasks
        if (foundParentTaskIndex !== -1) {
          updatedEmployerWorkTasks[foundParentTaskIndex].childTasks.push(createdWorkTask);
        }
      } else {
        // Pushing as a new "parent" work task (top level of the hierarchy)
        updatedEmployerWorkTasks.push(createdWorkTask);
      }
      foundEmployerWo.changeAttribute('workTasks', updatedEmployerWorkTasks);
    }
  }

  @action updateWorkTask(data, resolve) {
    const { currentUser } = this.uiStore;

    this.requests.WorkTasks.update(data.workOrderId, data).then((response) => {
      const updatedWorkTask = WorkTask.fromJsonProperties(response);

      const { workOrderCache } = this.employerWorkOrderStore;
      // Employer work order (separate list from "employee" work orders), assumed to always exist if this method is able to be called
      const foundEmployerWo = workOrderCache?.workOrders.find((wo) => wo.id === updatedWorkTask.workOrderId);
      const foundEmployeeWo = currentUser.workOrders.find((wo) => wo.id === updatedWorkTask.workOrderId);

      // Update employer's loaded work order
      if (foundEmployerWo) {
        const updatedEmployerWorkTasks = [...foundEmployerWo.workTasks];

        if (updatedWorkTask.parentId) {
          // The updated task is a child task, find the parent and its outdated child task, then replace
          const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === updatedWorkTask.parentId);
          // NOTE: ONLY UPDATES ON TWO-LEVEL HIERARCHY
          const foundChildTaskIndex = updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks.findIndex((childTask) => childTask.id === updatedWorkTask.id);
          updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks[foundChildTaskIndex] = updatedWorkTask;
        } else {
          const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === updatedWorkTask.id);
          updatedEmployerWorkTasks[foundWorkTaskIndex] = updatedWorkTask;
        }

        foundEmployerWo.changeAttribute('workTasks', updatedEmployerWorkTasks);
      }

      // Update employee's own work order
      if (foundEmployeeWo) {
        const updatedEmployeeWorkTasks = [...foundEmployeeWo.workTasks];

        if (updatedWorkTask.parentId) {
          // The updated task is a child task, find the parent and its outdated child task, then replace
          const foundWorkTaskIndex = foundEmployeeWo.workTasks.findIndex((workTask) => workTask.id === updatedWorkTask.parentId);

          if (foundWorkTaskIndex !== -1) {
            const foundChildTaskIndex = updatedEmployeeWorkTasks[foundWorkTaskIndex].childTasks.findIndex((childTask) => childTask.id === updatedWorkTask.id);
            updatedEmployeeWorkTasks[foundWorkTaskIndex].childTasks[foundChildTaskIndex] = updatedWorkTask;
          }
        } else {
          const foundWorkTaskIndex = foundEmployeeWo.workTasks.findIndex((workTask) => workTask.id === updatedWorkTask.id);
          if (foundWorkTaskIndex !== -1) {
            updatedEmployeeWorkTasks[foundWorkTaskIndex] = updatedWorkTask;
          }
        }

        foundEmployeeWo.changeAttribute('workTasks', updatedEmployeeWorkTasks);
      }

      resolve(updatedWorkTask);
    });
  }

  @action deleteFile(fileSignedId, workOrderId, workTaskParentId, workTaskId, resolve) {
    return this.requests.WorkTasks.purgeFile(workOrderId, workTaskId, fileSignedId).then(() => {
      const { workOrderCache } = this.employerWorkOrderStore;
      const foundEmployerWo = workOrderCache?.workOrders.find((wo) => wo.id === workOrderId);
      const updatedEmployerWorkTasks = [...foundEmployerWo.workTasks];

      if (workTaskParentId) {
        // The updated task is a child task, find the parent and its outdated child task, then replace
        const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === workTaskParentId);
        // NOTE: ONLY UPDATES ON TWO-LEVEL HIERARCHY
        const foundChildTaskIndex = updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks.findIndex((childTask) => childTask.id === workTaskId);
        updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks[foundChildTaskIndex].files = updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks[foundChildTaskIndex].files.filter((file) => file.signedId !== fileSignedId);
      } else {
        const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === workTaskId);
        updatedEmployerWorkTasks[foundWorkTaskIndex].files = updatedEmployerWorkTasks[foundWorkTaskIndex].files.filter((file) => file.signedId !== fileSignedId);
      }

      foundEmployerWo.changeAttribute('workTasks', updatedEmployerWorkTasks);

      resolve(fileSignedId);
    });
  }

  @action generatePDF(workTaskId, parentId, workOrderId, signedIds, resolve, reject) {
    this.requests.WorkTasks.generatePDF(workOrderId, workTaskId, signedIds).then((response) => {
      const newPDF = {
        filename: response.pdf.filename,
        fileURL: response.pdf.url,
        signedId: response.pdf.signed_id,
      };

      const { workOrderCache } = this.employerWorkOrderStore;
      const foundEmployerWo = workOrderCache?.workOrders.find((wo) => wo.id === workOrderId);
      const updatedEmployerWorkTasks = [...foundEmployerWo.workTasks];

      if (parentId) {
        // The updated task is a child task, find the parent and its outdated child task, then replace
        const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === parentId);
        // NOTE: ONLY UPDATES ON TWO-LEVEL HIERARCHY
        const foundChildTaskIndex = updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks.findIndex((childTask) => childTask.id === workTaskId);
        updatedEmployerWorkTasks[foundWorkTaskIndex].childTasks[foundChildTaskIndex].files.push(newPDF);
      } else {
        const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === workTaskId);
        updatedEmployerWorkTasks[foundWorkTaskIndex].files.push(newPDF);
      }

      foundEmployerWo.changeAttribute('workTasks', updatedEmployerWorkTasks);

      resolve({ pdf: newPDF, deletedFilesSignedIds: response.deleted_files });
    }).catch((err) => reject(err));
  }

  @action delete(workOrderId, workTaskId) {
    const { currentUser } = this.uiStore;

    return this.requests.WorkTasks.del(workOrderId, workTaskId).then(() => {
      const { workOrderCache } = this.employerWorkOrderStore;
      // Employer work order (separate list from "employee" work orders), assumed to always exist if this method is able to be called
      const foundEmployerWo = workOrderCache?.workOrders.find((wo) => wo.id === workOrderId);
      const foundEmployeeWo = currentUser.workOrders.find((wo) => wo.id === workOrderId);

      // Update employer's loaded work order
      if (foundEmployerWo) {
        const updatedEmployerWorkTasks = [...foundEmployerWo.workTasks];

        // Assuming no work task hierarchy (parentId)
        const foundWorkTaskIndex = foundEmployerWo.workTasks.findIndex((workTask) => workTask.id === workTaskId);
        updatedEmployerWorkTasks.splice(foundWorkTaskIndex, 1);

        foundEmployerWo.changeAttribute('workTasks', updatedEmployerWorkTasks);
      }

      // Update employee's own work order
      if (foundEmployeeWo) {
        const updatedEmployeeWorkTasks = [...foundEmployeeWo.workTasks];

        // Assuming no work task hierarchy (parentId)
        const foundWorkTaskIndex = foundEmployeeWo.workTasks.findIndex((workTask) => workTask.id === workTaskId);
        if (foundWorkTaskIndex !== -1) {
          updatedEmployeeWorkTasks.splice(foundWorkTaskIndex, 1);
        }

        foundEmployeeWo.changeAttribute('workTasks', updatedEmployeeWorkTasks);
      }
    }).catch((err) => {
      if (err.response.body?.error === 'Cannot delete work task with associated entries') {
        return 'entries-error';
      }
      return err;
    });
  }

  @action getWorkTasksByWorkOrder(workOrderId, resolve) {
    return this.requests.WorkTasks.getByWorkOrder(workOrderId).then((response) => {
      const workTasks = response.map(WorkTask.fromJsonProperties);
      resolve(workTasks);
    });
  }
}
