/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TabBar, Tab } from '@rmwc/tabs';

@inject('chatStore', 'uiStore', 't')
@observer
class Header extends Component {
  onEmployerTabSelect = (evt) => {
    const { onTabSelected, drawerTabIndex } = this.props;
    onTabSelected(evt, drawerTabIndex);
  }

  onSafetyTabSelect = (evt) => {
    const { onSafetyTabSelected } = this.props;
    onSafetyTabSelected(evt);
  }

  render() {
    const { tabIndex, t, uiStore, uiStore: { mobileMode, currentUser: { accountInfo: { workOrderTripsEnabled, absencesEnabled } } }, chatStore, onTabSelected, drawerTabIndex } = this.props;
    const { totalUnreadMessages } = chatStore;
    // const fixed = this.props.fixed || true;
    // const position = fixed ? 'fixed' : 'unset';
    const stackedEmployerHeader = mobileMode && workOrderTripsEnabled && absencesEnabled;

    if (uiStore.employerMode && uiStore.currentUser?.role === 'employer') {
      // Employer-only tabs
      switch (drawerTabIndex) {
        case 1:
          return (
            <header className="top-bar">
              <TabBar onActivate={this.onEmployerTabSelect} activeTabIndex={tabIndex}>
                <Tab stacked={stackedEmployerHeader} restrictIndicator icon="work" label={t('menu.tabs.title_work_orders')} className="wide-tab" />
                <Tab stacked={stackedEmployerHeader} restrictIndicator icon="access_time" label={t('menu.tabs.title_hours')} className="wide-tab" />
                {workOrderTripsEnabled && <Tab stacked={stackedEmployerHeader} restrictIndicator icon="directions_car" label={t('menu.tabs.title_work_order_trips')} className="wide-tab" />}
                {absencesEnabled && <Tab stacked={stackedEmployerHeader} restrictIndicator icon="beach_access" label={t('menu.tabs.title_absences')} className="wide-tab" />}
              </TabBar>
            </header>
          )
        // case 2:
        //   return (
        //     <header className="top-bar">
        //       <TabBar onActivate={this.onEmployerTabSelect} activeTabIndex={tabIndex}>
        //         <Tab restrictIndicator icon="description" label={t('menu.tabs.title_attachments')} className="wide-tab" />
        //         <Tab restrictIndicator icon="summarize" label={t('menu.tabs.title_reports')} className="wide-tab" />
        //       </TabBar>
        //     </header>
        //   )
        default:
          return null;
      }
    } else {
      switch (drawerTabIndex) {
        // TODO: This if else switch case structure is pretty stupid and annoying to modify, needs fixing somehow
        case 1:
          if (uiStore.currentUser?.role === 'employer') {
            return (
              <header className="top-bar">
                <TabBar onActivate={this.onSafetyTabSelect} activeTabIndex={tabIndex}>
                  <Tab restrictIndicator icon="description" label={t('menu.tabs.title_own_close_call_reports')} className="wide-tab" />
                  <Tab restrictIndicator icon="summarize" label={t('menu.tabs.title_process_close_call_reports')} className="wide-tab" />
                </TabBar>
              </header>
            )
          } else {
            return null;
          }
        case 2:
          // should be accident-reports
          return null;
        default:
          return (
            <header className="top-bar">
              <TabBar onActivate={onTabSelected} activeTabIndex={tabIndex}>
                <Tab stacked restrictIndicator icon="calendar_today" label={t('menu.tabs.title_calendar')} id="calendar-tab" />
                <Tab stacked restrictIndicator icon="account_circle" label={t('menu.tabs.title_profile')} id="profile-tab" />
                <Tab stacked restrictIndicator icon="access_time" label={t('menu.tabs.title_hours')} id="work-hours-tab" />
                <Tab stacked restrictIndicator icon="message" label={t('menu.tabs.title_messages')} className={totalUnreadMessages != 0 ? 'unread' : ''} id="messages-tab">
                  {totalUnreadMessages != 0 && (<span className="unread">{totalUnreadMessages > 99 ? 99 : totalUnreadMessages}</span>)}
                </Tab>
              </TabBar>
            </header>
          )
      }
      // return (
      //   <header className="top-bar">
      //     <TabBar onActivate={onTabSelected} activeTabIndex={tabIndex}>
      //       <Tab stacked restrictIndicator icon="calendar_today" label={t('menu.tabs.title_calendar')} />
      //       <Tab stacked restrictIndicator icon="account_circle" label={t('menu.tabs.title_profile')} />
      //       <Tab stacked restrictIndicator icon="access_time" label={t('menu.tabs.title_hours')} />
      //       <Tab stacked restrictIndicator icon="message" label={t('menu.tabs.title_messages')} className={totalUnreadMessages != 0 ? 'unread' : ''}>
      //         {totalUnreadMessages != 0 && (<span className="unread">{totalUnreadMessages > 99 ? 99 : totalUnreadMessages}</span>)}
      //       </Tab>
      //     </TabBar>
      //   </header>
      // )
    }
  }
}

export default Header;
