/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { toFloat } from 'validator';

import { TextField } from '@rmwc/textfield';
import { GridCell, GridInner } from '@rmwc/grid';
import { Typography } from '@rmwc/typography'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import AutocompletableLocation from '../shared/AutocompletableLocation';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';

import TimeField from 'react-simple-timefield';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import '@material/typography/dist/mdc.typography.css';

import noop from '../utils/noop';
import moment, { asTime } from '../utils/moment';
import CustomTimeField from '../shared/CustomTimeField';

import StartTrip from './StartTrip';
import AutocompleteLocation from './AutocompleteLocation';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('workOrderTripStore', 't', 'uiStore')
@observer
class TimelogTripStart extends Component {
  constructor(props) {
    super(props);

    const { startTrip, workOrder, defaultTripDate } = props;

    this.state = {
      calcEndDate: '',
    };

    this.updateLocation = this.updateLocation.bind(this);

    let trip = startTrip ? startTrip : new StartTrip({});

    if (trip) {
      if (!trip.tripRoute) {
        trip.tripRoute = [];
      }

      if (trip.tripRoute.length === 0) {
        trip.tripRoute.push({
          'id': null,
          'description': null,
          'endTripId': null,
          'startTripId': null,
          'routeType': null,
          'kms': null,
          'status': 'draft',
          'routeLocations': [
            {
              'id': null,
              'city': null,
              'zipCode': null,
              'street': null,
              'locationOrder': 0,
              'status': 'draft',
            },
            {
              'id': null,
              'city': null,
              'zipCode': null,
              'street': null,
              'locationOrder': 1,
              'status': 'draft',
            },
          ],
        });
      } else if (trip.tripRoute[0].routeLocations.length < 2) {
        let order = trip.tripRoute[0].routeLocations.length;
        while (trip.tripRoute[0].routeLocations.length < 2) {
          trip.tripRoute[0].routeLocations.push({
            'id': null,
            'city': null,
            'zipCode': null,
            'street': null,
            'locationOrder': order,
            'status': 'draft',
          });
          order++;
        }
      }

      this.state = {
        dialogOpen: false,
        // ...workOrderTrip[0],
        directionTrip: { ...trip },
        showDeleteDialog: false,
        itemToDelete: '',
        deleteMessage: '',
        streetOpen: false,
        matches: [],
        matchObjects: [],
      };
    } else {
      const fakeTrip = {
        'id': null,
        'date': null,
        'time': null,
        'toDate': null,
        'toTime': null,
        'status': 'draft',
        'uiWorkOrderId': workOrder.id,
        'workOrderTripId': null,
        'tripRoute': [
          {
            'id': null,
            'description': null,
            'endTripId': null,
            'startTripId': null,
            'routeType': null,
            'kms': null,
            'status': 'draft',
            'routeLocations': [
              {
                'id': null,
                'city': null,
                'zipCode': null,
                'street': null,
                'locationOrder': 0,
                'status': 'draft',
              },
              {
                'id': null,
                'city': null,
                'zipCode': null,
                'street': null,
                'locationOrder': 1,
                'status': 'draft',
              },
            ],
          },
        ],
      };

      this.state = {
        dialogOpen: false,
        // ...workOrderTrip[0],
        directionTrip: { ...fakeTrip },
        showDeleteDialog: false,
        itemToDelete: '',
        deleteMessage: '',
        streetOpen: false,
        matches: [],
        matchObjects: [],
      };
    }
  }

  componentDidMount() {
    const { currentUser } = this.props.uiStore;

    const foundAddresses = [...currentUser.autocompleteLocations];

    this.setState({
      autocompleteOptions: foundAddresses,
    })
  }

  capitalizeString(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
  }

  updateLocation(tripRoute, routeIndex = null) {
    const { directionTrip } = this.state;
    const { updateStartTrip } = this.props;

    const updatedTrip = { ...directionTrip }
    updatedTrip.tripRoute = tripRoute;

    this.setState({
      directionTrip: updatedTrip,
    }, () => {
      updateStartTrip(new StartTrip(updatedTrip));
    });
  }

  updateRouteLocation = (routeIndex, locationIndex, location) => {
    const { directionTrip } = this.state;
    const { updateStartTrip } = this.props;

    const updatedTrip = { ...directionTrip }
    updatedTrip.tripRoute[0].routeLocations[locationIndex] = location;

    this.setState({
      directionTrip: updatedTrip,
    }, () => {
      updateStartTrip(new StartTrip(updatedTrip), true);
    });
  }

  addHourField(attr) {
    const { disabled, updateStartTrip, maxStartDate, maxStartTime } = this.props;
    const { directionTrip, calcEndDate } = this.state;

    return (

      <div
        className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
        style={{
          // height: '44px',
          // width: '45px',
          // margin: 0,
          // padding: 0,
          width: '100%',
          backgroundColor: 'var(--mdc-theme-secondary)',
          color: 'white',
          flexDirection: 'column'
        }}
      >
        <div style={{ fontSize: '12px', paddingLeft: '5px' }}>Alkamisajankohta</div>
        <CustomTimeField
          value={directionTrip?.[attr] || '--:--'}
          attr={attr}
          doOnChange={(event, value) => { this.doOnChange(attr, value, event); }}
          doOnBlur={(attr, value, event) => {
            this.doOnChange(attr, value, event);
            updateStartTrip(new StartTrip({ ...directionTrip, [attr]: value }));
          }}
          handleEnter={handleEnter}
          disabled={disabled}
        />
      </div>
    );
  }

  addDecimalField(attr, label, updateEndTripRouteAttr = null, ctx = {}) {
    const { updateStartTrip, disabled } = this.props;
    const { directionTrip } = this.state;

    return (
      <TextField {...{
        onBlur: (event) => {
          updateStartTrip(new StartTrip(directionTrip));
          if (updateEndTripRouteAttr) {
            updateEndTripRouteAttr(attr, event.target.value);
          }
        },
        label,
        onChange: noop,
        onInput: (event) => {
          let value = event.target.value.replace(/[.,]{1,}/, '.');

          if (value.substr(-1) !== '.' && value !== '') {
            value = toFloat(value);
          }

          if ((!Number.isNaN(value) || value === '')) {
            this.doOnChangeTripRoute(attr, value, event);
          }
        },
        // disabled: this.props.disabled,
        disabled: disabled,
        pattern: '[0-9.,]*',
        rootProps: { ripple: false },
        style: {
          width: '100%'
        },
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        type: 'text',
        onKeyPress: handleEnter,
        value: (this.state != null && this.state.directionTrip.tripRoute[0] && this.state.directionTrip.tripRoute[0][attr] != null && !Number.isNaN(this.state.directionTrip.tripRoute[0][attr])) ? this.state.directionTrip.tripRoute[0][attr] : '',
        ...ctx,
      }}
      />
    );
  }

  generateQuickAddressOptions() {
    const { workOrder, uiStore: { currentUser } } = this.props;
    const quickAddresses = {};

    // Check if workOrder or required properties are missing, so we can't generate quick addresses and the render doesnt break
    if (!workOrder || !workOrder.location || !workOrder.location.city || !workOrder.location.street) {
      console.warn('workOrder or required location properties are missing.');
      return null;
    }

    if (workOrder.accomodationCity && workOrder.accomodationStreet) {
      quickAddresses.accomodation = [{
        name: 'Majoitus',
        city: workOrder.accomodationCity,
        street: workOrder.accomodationStreet,
        zipCode: workOrder.accomodationZipCode,
      }];
    }

    quickAddresses.location = [{
      name: 'Kohde',
      city: workOrder.location.city,
      street: workOrder.location.street,
      zipCode: workOrder.location.zip_code,
      defaultDestinationAddress: true,
    }];

    const homeAddress = currentUser.homeAddress();
    if (homeAddress) {
      homeAddress.defaultDepartureAddress = true;
      quickAddresses.home = [homeAddress];
    }
    currentUser.accountInfo.companyLocations.forEach((companyLocation) => {
      if (!quickAddresses.companyLocation) {
        quickAddresses.companyLocation = [];
      }
      quickAddresses.companyLocation.push({
        name: companyLocation.name,
        city: companyLocation.city,
        street: companyLocation.street,
        zipCode: companyLocation.zip_code,
      });
    });

    return quickAddresses;
  }

  renderOutwardForm() {
    const { directionTrip, autocompleteOptions, directionTrip: { tripRoute, date } } = this.state;
    const { maxStartDate, updateEndTripRouteAttr, disabled } = this.props;

    const quickAddresses = this.generateQuickAddressOptions();

    const styleInner = {
      rowGap: '0px'
    };

    let locations = [];
    if (tripRoute) {
      if (tripRoute[0]) {
        locations = tripRoute[0].routeLocations;
      }
    }

    const renderLocations = [];

    locations.forEach((_, index) => {
      let locationLabel = '';
      let selectedQuickAddressLabel = '';
      if (index === 0) {
        locationLabel = 'Lähtösijainnin katuosoite *';
        selectedQuickAddressLabel = 'Lähtösijainti';
      } else if (tripRoute[0] && index + 1 === tripRoute[0]?.routeLocations?.length) {
        locationLabel = 'Kohdesijainnin katuosoite *'
        selectedQuickAddressLabel = 'Kohdesijainti';
      } else {
        locationLabel = 'Katuosoite *'
        selectedQuickAddressLabel = 'Sijainti';
      }

      renderLocations.push(
        <div
          style={{
            margin: '5px -10px 5px -10px',
            // paddingBottom: '5px',
            // marginTop: '20px',
            // borderBottom: '1px solid var(--mdc-theme-primary)'
          }}
        >
          <Typography
            use="subtitle1"
            style={{ paddingLeft: '10px' }}
          >
            {index === 0 ? 'Syötä lähtösijainti' : 'Syötä kohdesijainti' }
          </Typography>
        </div>
      );

      renderLocations.push(
        <AutocompletableLocation
          key={index}
          locationIndex={index}
          locationLabel={locationLabel}
          selectedQuickAddressLabel={selectedQuickAddressLabel}
          tripRoute={tripRoute}
          autocompleteOptions={autocompleteOptions}
          updateLocation={this.updateLocation}
          updateRouteLocation={this.updateRouteLocation}
          disabled={disabled}
          quickAddresses={quickAddresses || {}}
        ></AutocompletableLocation>
      );
    });

    return (
      <div>
        {/* <div
          style={{
            margin: '0px -10px 5px -10px',
            paddingBottom: '5px',
            borderBottom: '1px solid var(--mdc-theme-primary)'
          }}
        >
          <Typography
            use="subtitle1"
            style={{ paddingLeft: '10px' }}
          >
            Työmatkan alkaminen
          </Typography>
        </div> */}

        {/* hack to remove autofocus on calendar */}
        <input style={{ width: '1px', opacity: '0' }} />
        <GridCell span={12}>
          <GridInner
            style={styleInner}
          >
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              <DayPickerInput
                component={
                  props =>
                    <TextField
                      {...props}
                      {...{
                        label: "Alkamispäivä *",
                        theme: ['textPrimaryOnLight', 'secondaryBg'],
                        placeholder: '',
                        style: { width: '100%' },
                      }}
                    />
                }
                inputProps={{
                  ref: null,
                  className: '',
                  // style: { padding: '0 15px 0 15px' },
                  readOnly: true,
                  disabled: disabled,
                }}
                dayPickerProps={{
                  locale: 'fi',
                  localeUtils: MomentLocaleUtils,
                  // modifiers: {
                  //   disabled: [
                  //     {
                  //       before: maxStartDate && moment(maxStartDate, 'YYYY-MM-DD', 'fi').toDate()
                  //     }
                  //   ]
                  // }
                }}
                formatDate={formatDate}
                parseDate={parseDate}
                keepFocus={false}
                format="dd DD.MM.YYYY"
                // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
                onDayChange={(value) => this.handleChangeForDate('date', value)}
                value={date ? moment(date, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                classNames={{
                  container: '',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay'
                }}
              />
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('time')}
            </GridCell>
          </GridInner>
        </GridCell>
        {renderLocations}
        <GridCell
          span={12}
          style={{ marginTop: '20px' }}
        >
          {this.addDecimalField('kms', 'Etäisyys (km)', updateEndTripRouteAttr)}
        </GridCell>
      </div>
    )
  }

  doOnChange(attr, value, event) {
    const { directionTrip } = this.state

    this.setState({ directionTrip: { ...directionTrip, [attr]: value } });
  }

  doOnChangeTripRoute(attr, value, event) {
    const { directionTrip } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    if (directionTrip.tripRoute[0][attr] !== value && event.target.validity.valid) {
      const updatingTripRoute = directionTrip.tripRoute[0];
      updatingTripRoute[attr] = value;
      this.setState({ tripRoute: [updatingTripRoute] });
    }
  }

  handleChangeForDate(attr, value) {
    const { mode, updateStartTrip } = this.props;
    const { directionTrip } = this.state;

    directionTrip[attr] = moment(value);

    // if (attr === 'date' && mode === 'end' && directionTrip.tripRoute[0] && directionTrip.date != null && directionTrip.time != null && directionTrip.tripRoute[0]['kms'] != null) {
    //   this.getTripEndTime();
    // }
    updateStartTrip(new StartTrip(directionTrip));
  }

  render() {
    return (
      this.renderOutwardForm()
    );
  }
}

export default TimelogTripStart;
