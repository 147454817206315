import { observable, action } from 'mobx';
import moment from '../utils/moment';

const mapToParseZone = (daySet) => [...daySet].map((d) => moment.parseZone(d));

class EmployeeCalendarStore {
  @observable availableEditMode = true;

  @observable updatingAvailability = false;

  constructor(uiStore) {
    this.uiStore = uiStore;
    this.cancelUpdatingAvailability();
  }

  @action setAvailableEditMode = (value = true) => {
    this.availableEditMode = value;
  };

  @action startUpdatingAvailability = () => {
    this.updatingAvailability = true;
  };

  @action cancelUpdatingAvailability = () => {
    const { currentUser } = this.uiStore;
    this.updatingAvailability = false;
    currentUser.setCalendarAvailabilities();

    // let daySets = availabilityDaysInMonth(currentUser.availability);
    // this.availableDays.replace(daySets.availableDays.map(cleanDate));
    // this.busyDays.replace(daySets.busyDays.map(cleanDate));

    // daySets = availabilityDaysInMonth(currentUser.workOrders);
    // this.workDays.replace(daySets.busyDays.map(cleanDate));
  };

  @action finishUpdatingAvailability = () => {
    this.updatingAvailability = false;

    const { currentUser: { availableDays, busyDays } } = this.uiStore;

    this.uiStore.markDaysAsAvailable(
      mapToParseZone(availableDays),
      mapToParseZone(busyDays),
    );
  };

  @action.bound handleDayClick = (day, { available, busy }) => {
    const { currentUser } = this.uiStore;
    if (!this.updatingAvailability) {
      return;
    }

    const dayList = this.availableEditMode ? 'availableDays' : 'busyDays';
    const toRemoveList = !this.availableEditMode ? 'availableDays' : 'busyDays';

    // in case the day is a working day, it cannot be chosen as busy/available anymore
    const workingDay = currentUser.calendarAvailabilities.find((refDay) => day.getTime() === refDay.getTime());
    if (workingDay != null) {
      return;
    }

    const removeFromList = (list) => {
      const mDay = day.getTime();
      const selectedIndex = list.findIndex(
        (selectedDay) => selectedDay.getTime() === mDay,
      );
      if (selectedIndex !== -1) {
        list.splice(selectedIndex, 1);
        return true;
      }

      return false;
    };

    if (available || busy) {
      if (this.availableEditMode && busy) {
        removeFromList(currentUser[toRemoveList]);
        currentUser[dayList].push(day);
      } else if (!this.availableEditMode && available) {
        removeFromList(currentUser[toRemoveList]);
        currentUser[dayList].push(day);
      } else {
        removeFromList(currentUser[dayList]);
      }
    } else {
      currentUser[dayList].push(day);
    }
  };
}

export default EmployeeCalendarStore;
