/* eslint-disable */
import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { inject, observer } from 'mobx-react';
// import { fromPromise } from 'mobx-utils';
// import { when } from 'mobx';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
// import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
import MUIDataTable from 'mui-datatables';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
// } from '@material-ui/core';
import { isEqual } from 'lodash';
import LoadingSpinner from '../shared/LoadingSpinner';
import FilePreviewDialog from '../shared/FilePreviewDialog';
import CertificateDialog from './CertificateDialog';
import moment from '../utils/moment';
// import normalizeFileName from '../utils/NormalizeFileName';
import './CertificatesTable.css';
import { onDownload, onViewColumnsChange } from '../utils/datatableUtils';

// const newBadge = () => {
//   return (
//     <div
//       style={{ padding: '5px 10px', width: 'fit-content', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
//     >
//       Uusi
//     </div>
//   );
// }

// const tableSelectionButton = (t, preset, activePreset, onClick) => {
//   return (
//     <Button
//       className={activePreset === preset ? 'selected-table-selection-button' : 'table-selection-button'}
//       onClick={() => onClick(preset)}
//       type="button"
//       key={`${preset}-button`}
//     >
//       {t(`employer_table.columnPresets.${preset}`)}
//     </Button>
//   )
// }

@inject('t', 'uiStore', 'employerUserStore')
@observer
class CerfificatesTable extends Component {
  constructor(props) {
    super(props);

    // const { employerUserStore } = this.props;

    this.state = {
      columns: [],
      activePreset: 'base',
      showImageDialog: false,
      imageUrl: '',
      imageName: '',
      certificateDialogOpen: false,
      openCertificate: null,
      readOnlyMode: false,
      openSnackbar: false,
      saveStatusMessage: '',
    };

    // employerUserStore.getUsers();
  }

  renderCustomImageColumn = (imageUrl, imageName) => {
    // Check if imageUrl exists and is a string
    if (typeof imageUrl === 'string') {
      // Remove the query parameter before passing the URL to Document
      const pdfUrl = imageUrl.split('?')[0];

      if (pdfUrl && pdfUrl.endsWith('.pdf')) {
        return (
          <div style={{ width: '100px', cursor: 'pointer' }}>
            <Document
              file={pdfUrl}
              loading="Avataan PDF..."
              noData="Tiedostoa ei löytynyt"
              error="Tiedoston avaus epäonnistui"
            >
              <Page
                pageNumber={1}
                width={100}
                height={80}
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ imageUrl, imageName, showImageDialog: true })
                }}
              />
            </Document>
          </div>
        );
      } else if (imageUrl) {
        return (
          <div style={{ width: '100px' }}>
            <img
              src={imageUrl}
              onClick={(e) => {
                e.stopPropagation();
                this.setState({ imageUrl, imageName, showImageDialog: true })
              }}
              style={{ width: '100px', height: '80px', objectFit: 'cover', cursor: 'pointer' }}
              alt="Image"
            />
          </div>
        );
      }
    }
    // Handle cases where the URL is empty or not a string
    return null;
  };

  renderCardValidity(validThrough) {
    if (!validThrough) {
      return null;
    }
    const formattedValidThrough = validThrough.format('dd DD.MM.YYYY');
    // Need to create copies for comparison because otherwise formattedValidThrough changes from some reason
    const endOfDay = moment(validThrough).endOf('day');
    const endOfWeek = moment(validThrough).endOf('week');

    const currentDateMoment = moment().local();
    // const validThroughMoment = Validity.convertDate(validThrough).dateObject; // moment(secondDate, 'MM/YYYY');
    // Add 1 to the months because for some reason they're zero indexed (e.g. January is 0 and December is 11)
    // const currentMonth = currentDate.month() + 1;
    // const secondDateMonth = secondDate.month() + 1;

    // Object not returned (invalid date given to conversion), which means the date cannot be calculated
    if (!validThrough) {
      return null;
    }

    if (currentDateMoment > endOfDay) {
      // Expired
      return (
        <div style={{ color: 'red', fontWeight: 'bold', textTransform: 'capitalize' }}>
          {formattedValidThrough}
        </div>
      );
    } else if (currentDateMoment.add(1, 'W') > endOfWeek) {
      // Expiring within a week
      return (
        <div style={{ color: 'var(--mdc-theme-primary)', fontWeight: 'bold', textTransform: 'capitalize' }}>
          {formattedValidThrough}
        </div>
      );
    } else {
      return (
        <div style={{ color: 'rgba(0, 0, 0, 0.87)', textTransform: 'capitalize' }}>
          {formattedValidThrough}
        </div>
      );
    }
  }

  setColumnDefinitions(data, firstRender = false) {
    const { t, employerUserStore } = this.props;
    // const onlyTruthyFilterOptions = {
    //   // names: [],
    //   logic: (value) => value !== null && value !== undefined && value !== '',
    // };
    // const handleEmptyFilters = {
    //   renderValue: v => {
    //     if (v === '' || v === null || v === undefined) {
    //       return 'Tyhjä'
    //     }
    //     return v;
    //   }
    // }

    const columnDefinitions = [
      {
        name: 'user.name',
        label: 'Työntekijä',
        options: {
          filter: false,
          sort: true,
        },
      },
      // {
      //   name: 'firstName',
      //   label: 'Etunimi',
      //   options: {
      //     filter: false,
      //     sort: true,
      //     customBodyRender: (value) => {
      //       return value ? value : newBadge;
      //     },
      //   },
      // },
      // {
      //   name: 'currentAccount.active',
      //   label: 'Aktiivinen',
      //   options: {
      //     sort: true,
      //     print: false,
      //     searchable: false,
      //     filterType: 'multiselect',
      //     customFilterListOptions: { render: v => `Aktiivinen: ${v}` },
      //     filterList: employerUserStore.employeesFilters['currentAccount.active']?.length !== 0 ? defaultFilterLists['active'] : employerUserStore.employeesFilters['currentAccount.active'],
      //     customBodyRender: (value) => {
      //       const result = value ? 'Kyllä' : 'Ei';
      //       return (
      //         result
      //       );
      //     },
      //   },
      // },

      {
        name: 'identifier',
        label: 'Kokeen merkintä',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'methodology.identifierOne',
        label: 'Menetelmä',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'material.identifierOne',
        label: 'Perusaine',
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'description',
        label: 'Kuvaus',
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: 'currentExam.expirationDate',
        label: 'Kokeen voimassaolo',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].currentExam.expirationDate);
          },
        },
      },
      {
        name: 'currentExam.file',
        label: 'Kokeen pätevyystodistus',
        options: {
          filter: false,
          sort: true,
          download: false,
          display: false,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCustomImageColumn(data[dataIndex].currentExam.file?.url, data[dataIndex].currentExam.file?.name);
          },
        },
      },
      {
        name: 'currentValidityCheck.expirationDate',
        label: 'Voimassaolon jatkaminen',
        options: {
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => {
            return this.renderCardValidity(data[dataIndex].currentValidityCheck.expirationDate);
          },
        },
      },
      {
        name: 'currentValidityCheck.file',
        label: 'Viimeisin pätevyystodistus',
        options: {
          filter: false,
          sort: true,
          download: false,
          customBodyRenderLite: (dataIndex) => {
            const latestImage = data[dataIndex].currentValidityCheck?.file || data[dataIndex].currentExam?.file;
            return this.renderCustomImageColumn(latestImage?.url, latestImage?.name);
          },
        },
      },
      // ...(salaryPeriodCategoriesEnabled ? [{
      //   name: 'salaryPeriodCategory.name',
      //   label: t('profile.labels.salaryPeriodCategory'),
      //   options: {
      //     filter: true,
      //     customFilterListOptions: { render: v => `${t('profile.labels.salaryPeriodCategory')}: ${v}` },
      //     sort: true,
      //     // filterType: 'multiselect',
      //   },
      // }] : []),
      // {
      //   name: 'isWatched',
      //   label: 'Seurannassa',
      //   options: {
      //     filter: true,
      //     sort: true,
      //     download: false,
      //   },
      // },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          print: false,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Icon
                icon="edit"
                style={{
                  cursor: 'pointer',
                  padding: '0 10px'
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  this.openCertificateDialog(data[dataIndex]);
                }}
              />
            )
          }
        },
      },
    ];

    console.log(employerUserStore.employeesFilters);

    return columnDefinitions;
  }

  componentDidMount() {
    const { data, presets } = this.props;

    const columnDefinitions = this.setColumnDefinitions(data, true);

    this.setState({
      data: data,
      columns: columnDefinitions,
    });
  }

  componentDidUpdate(prevProps) {
    const { data } = this.props;
    const { data: prevData } = prevProps;

    if (data && !isEqual(data, prevData)) {
      const columnDefinitions = this.setColumnDefinitions(data);

      this.setState({
        data: data,
        columns: columnDefinitions,
      })
    }
  }

  openCertificateDialog(certificate) {
    this.setState({
      certificateDialogOpen: true,
      openCertificate: certificate,
    });
  }

  closeCertificateDialog = (event) => {
    const { showSnackbarMessage } = this.props;

    if (event === 'saveSuccess') {
      this.setState({
        certificateDialogOpen: false,
        openCertificate: null,
      }, () => {
        showSnackbarMessage('Tallennus onnistui');
      });
    } else {
      this.setState({
        certificateDialogOpen: false,
        openCertificate: null,
      });
    }
  }

  showSnackbarMessage = (message) => {
    const { showSnackbarMessage: propShowSnackbarMessage } = this.props;
    propShowSnackbarMessage(message);
  }

  // This is copied from Validity since some changes were needed for rendering
  // renderValidity(validThrough) {
  //   if (!validThrough) {
  //     return null;
  //   }

  //   const currentDateMoment = moment().local();
  //   const validThroughMoment = Validity.convertDate(validThrough).dateObject; // moment(secondDate, 'MM/YYYY');
  //   // Add 1 to the months because for some reason they're zero indexed (e.g. January is 0 and December is 11)
  //   // const currentMonth = currentDate.month() + 1;
  //   // const secondDateMonth = secondDate.month() + 1;

  //   // Object not returned (invalid date given to conversion), which means the date cannot be calculated
  //   if (!validThroughMoment) {
  //     return null;
  //   }

  //   if (currentDateMoment > validThroughMoment.endOf('month')) {
  //     // Expired (last day of the month)
  //     return (
  //       <div style={{ color: 'red', fontWeight: 'bold' }}>
  //         {validThrough}
  //       </div>
  //     );
  //   } else if (currentDateMoment.add(1, 'M') > validThroughMoment.endOf('month')) {
  //     // Expiring within a month
  //     return (
  //       <div style={{ color: 'var(--mdc-theme-primary)', fontWeight: 'bold' }}>
  //         {validThrough}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
  //         {validThrough}
  //       </div>
  //     );
  //   }
  // }

  // toggleColumnVisibilityPreset = (preset) => {
  //   const { presets, employerUserStore, employerUserStore: { storedColumns } } = this.props;
  //   const { columns } = this.state;
  //   const updatedColumns = [...columns];

  //   let chosenPreset = presets[preset];
  //   let storePreset = storedColumns[preset]?.name;

  //   // Match the store and selected preset.
  //   if (storePreset === preset) {
  //     chosenPreset = storedColumns[preset].columns;
  //   }

  //   if (preset === 'all' || chosenPreset) {
  //     // Toggle column visibility with the selected preset
  //     updatedColumns.map((column) => {
  //       if (preset === 'all' || chosenPreset.includes(column.name)) {
  //         column.options.display = true;
  //       } else {
  //         column.options.display = false;
  //       }
  //       return column;
  //     });

  //     this.setState({
  //       activePreset: preset,
  //       columns: updatedColumns,
  //     }, () => {
  //       employerUserStore.setStoredColumns(preset, chosenPreset);
  //     });
  //   }
  // }

  closeFilePreviewDialog = () => {
    this.setState({ showImageDialog: false, imageUrl: null, imageName: null });
  }

  setColumns = (updatedColumns) => {
    this.setState({
      columns: updatedColumns,
    })
  }

  handleRowClick = (rowData, rowMeta) => {
    const { data } = this.state;

    const selectedRowIndex = rowMeta.dataIndex;
    const openCertificateData = data[selectedRowIndex]

    this.setState({
      certificateDialogOpen: true,
      openCertificate: openCertificateData,
    });
  }

  render() {
    const {
      data,
      columns,
      activePreset,
      showImageDialog,
      imageUrl,
      certificateDialogOpen,
      openCertificate,
    } = this.state;
    const { t, hasMargins, presets, csvFilename, employerUserStore, view } = this.props;

    // const filename = `${t(`employer_table.columnPresets.${activePreset}`)}_${moment().format('DD-MM-YYYY')}`
    // const normalizedFileName = normalizeFileName(filename);

    const options = {
      // filter: activePreset === 'basic' || activePreset === 'clothing' ? true : 'disabled',
      onDownload: (buildHead, buildBody, columns, data) => onDownload(buildHead, buildBody, columns, data),
      onFilterChange: ((_changedColumn, filterList, _type, changedColumnIndex, _displayData) => {
        // We need to update the state columns manually so that the default filters defined there aren't re-applied on re-render
        const updatedColumns = [...columns];
        // changedColumnIndex is null when we're emptying all filters
        if (changedColumnIndex) {
          updatedColumns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
          employerUserStore.setEmployeesFilters(_changedColumn, filterList[changedColumnIndex])
        } else {
          // When emptying the filters, we also need to empty them from the state
          updatedColumns.forEach((column) => column.options.filterList = null);
          employerUserStore.emptyFilters()
          // Because of the logic we use in these columns set these to empty so they don't reload
          employerUserStore.setEmployeesFilters('currentAccount.active', [])
          employerUserStore.setEmployeesFilters('hasCompletedRegistration', [])
        }

        this.setState({ columns: updatedColumns });
      }),
      onViewColumnsChange: (changedColumn, action) => {
        onViewColumnsChange(changedColumn, action, columns, this.setColumns)
        const columnIndex = columns.findIndex(col => col.name === changedColumn);
        if (columnIndex !== -1) {
          if (action === 'remove') {
            // Remove the changedColumn from the columns array
            employerUserStore.removeStoredColumns(activePreset, [changedColumn]);
          } else if (action === 'add') {
            // Add the changedColumn to the array.
            employerUserStore.setStoredColumns(activePreset, [changedColumn]);
          }
        }
      },
      onRowClick: this.handleRowClick,
      // downloadOptions: {
      //   filterOptions: {
      //     useDisplayedColumnsOnly: true,
      //     useDisplayedRowsOnly: true,
      //   },
      //   filename: csvFilename || `${normalizedFileName}.csv`,
      // },
      draggableColumns: {
        enabled: true,
        transitionTime: 300,
      },
      filterType: 'dropdown',
      enableNestedDataAccess: '.',
      selectableRows: 'none',
      responsive: 'standard',
      tableBodyMaxHeight: '800px',
      textLabels: {
        body: {
          noMatch: "Pätevyyksiä ei löytynyt",
        },
        // toolbar: {
        //   search: t(`employer_table.toolbar.search`),
        //   downloadCsv: t(`employer_table.toolbar.downloadCsv`),
        //   print: t(`employer_table.toolbar.print`),
        //   viewColumns: t(`employer_table.toolbar.viewColumns`),
        //   filterTable: t(`employer_table.toolbar.filterTable`),
        // },
        // pagination: {
        //   next: t(`employer_table.pagination.next`),
        //   previous: t(`employer_table.pagination.previous`),
        //   rowsPerPage: t(`employer_table.pagination.rowsPerPage`),
        //   displayRows: t(`employer_table.pagination.displayRows`),
        // },
        // filter: {
        //   all: t(`employer_table.filter.all`),
        //   title: t(`employer_table.filter.title`),
        //   reset: t(`employer_table.filter.reset`),
        // },
        // viewColumns: {
        //   title: t(`employer_table.viewColumns.title`),
        //   titleAria: t(`employer_table.viewColumns.titleAria`),
        // },
        // selectedRows: {
        //   text: t(`employer_table.selectedRows.text`),
        //   delete: t(`employer_table.selectedRows.delete`),
        //   deleteAria: t(`employer_table.selectedRows.deleteAria`),
        // },
      },
      rowsPerPageOptions: [25, 50, 100],
      rowsPerPage: 25,
      // setTableProps: () => {
      //   return {
      //     size: 'small',
      //   }
      // },
    };

    return (
      <div>
        {!data && (
          <GridCell style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          </GridCell>
        )}

        {data && (
          <>
            <div
              className="mui-billing-table"
              style={{ margin: hasMargins && '0px 20px' }}
            >
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </>
        )}

        <FilePreviewDialog
          open={showImageDialog}
          fileURL={imageUrl}
          fileName="pätevyys"
          onClose={this.closeFilePreviewDialog}
        />

        <CertificateDialog
          open={certificateDialogOpen}
          handleClose={this.closeCertificateDialog}
          certificate={openCertificate}
          styles={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' }}
          showSnackbarMessage={this.showSnackbarMessage}
        />

        {/* <Dialog
          open={showImageDialog}
          onClose={() => {
            this.setState({ showImageDialog: false, imageUrl: null });
          }}
          className="trip-route-dialog"
        >
          <DialogContent className="xl-buttons">
            <div>
              <img style={{ width: '100%' }} src={imageUrl} />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              action="close"
              className="employer-cancel-button"
            >
              Sulje
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default CerfificatesTable;
