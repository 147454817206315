import { observable, action } from 'mobx';
import UserAvailability from './UserAvailability';

export default class WorkOrderAvailability {
  @observable accepted = [];

  @observable fullyAvailable = [];

  @observable maybeAvailable = [];

  @observable noAnswer = [];

  @observable rejected = [];

  @observable someAvailable = [];

  @observable unavailable = [];

  @observable alreadyWorking = [];

  @observable negativeOverlaps = [];

  @observable subcontractors = [];

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action updatePropertiesFromJson(o) {
    const values = {};

    let accepted = [];
    if (o.accepted != null && o.accepted.length !== 0) {
      accepted = o.accepted.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let rejected = [];
    if (o.rejected != null && o.rejected.length !== 0) {
      rejected = o.rejected.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let noAnswer = [];
    if (o.no_answer != null && o.no_answer.length !== 0) {
      noAnswer = o.no_answer.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let fullyAvailable = [];
    if (o.fully_available != null && o.fully_available.length !== 0) {
      fullyAvailable = o.fully_available.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let maybeAvailable = [];
    if (o.maybe_available != null && o.maybe_available.length !== 0) {
      maybeAvailable = o.maybe_available.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let someAvailable = [];
    if (o.some_available != null && o.some_available.length !== 0) {
      someAvailable = o.some_available.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let unavailable = [];
    if (o.unavailable != null && o.unavailable.length !== 0) {
      unavailable = o.unavailable.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let alreadyWorking = [];
    if (o.already_working != null && o.already_working.length !== 0) {
      alreadyWorking = o.already_working.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let negativeOverlaps = [];
    if (o.negative_overlaps != null && o.negative_overlaps.length !== 0) {
      negativeOverlaps = o.negative_overlaps.map((item) => UserAvailability.fromJsonProperties(item));
    }

    let subcontractors = [];
    if (o.subcontractors != null && o.subcontractors.length !== 0) {
      subcontractors = o.subcontractors.map((item) => UserAvailability.fromJsonProperties(item));
    }

    values.accepted = accepted;
    values.rejected = rejected;
    values.noAnswer = noAnswer;
    values.fullyAvailable = fullyAvailable;
    values.maybeAvailable = maybeAvailable;
    values.someAvailable = someAvailable;
    values.unavailable = unavailable;
    values.alreadyWorking = alreadyWorking;
    values.negativeOverlaps = negativeOverlaps;
    values.subcontractors = subcontractors;

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const woAvailability = new WorkOrderAvailability({});
    woAvailability.updatePropertiesFromJson(object);
    return woAvailability;
  }

  static fromJson(json) {
    return WorkOrderAvailability.fromJsonProperties(JSON.parse(json));
  }
}
