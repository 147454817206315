import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Transition,
  animated,
  config,
} from 'react-spring';

import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';

import { Button, IconButton } from '@material-ui/core';

import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DashboardIcon from '@material-ui/icons/Dashboard';
import FilterListIcon from '@material-ui/icons/FilterList';
// import Brightness1Icon from '@material-ui/icons/Brightness1';
import StopIcon from '@material-ui/icons/Stop';

import EmployerTimelogCard from './employerTimelogCard';
import EmployerTimelogTable from './employerTimelogTable';
import FilterDialog, { customFilterComparison } from '../shared/FilterDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
import EmployeeInfoDialog from './EmployeeInfoDialog';
import WorkOrderInfoDialog from './WorkOrderInfoDialog';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import EmployerWorkOrderTripCard from '../employer-work-order-trips/employerWorkOrderTripCard';
import moment, { dateRange } from '../utils/moment';
import './index.css';

const TransitionedCards = (props) => {
  const {
    sortedWorkHoursWithMeta,
    updateWorkHour,
    openRejectWorkHourDialog,
    openEmployeeInfoDialog,
    openWorkOrderInfoDialog,
    afterUpdate,
    openWorkOrderTripAcceptanceDialog,
    // daysTillDeadlines,
  } = props;

  return (
    <Transition
      items={sortedWorkHoursWithMeta}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      // delay={100}
      config={config.default}
      keys={(item) => `transition_${item.work_hour.status === 'not_created' ? `${item.work_hour.user_id}-${item.work_hour.date}-${item.work_hour.work_order_id}` : item.work_hour.id}`}
    >
      {(styles, item) => (
        <animated.div style={styles}>
          <EmployerTimelogCard
            workHourWithMeta={item}
            key={item.work_hour.id}
            updateWorkHour={updateWorkHour}
            openRejectWorkHourDialog={openRejectWorkHourDialog}
            openEmployeeInfoDialog={openEmployeeInfoDialog}
            openWorkOrderInfoDialog={openWorkOrderInfoDialog}
            afterUpdate={afterUpdate}
            openWorkOrderTripAcceptanceDialog={openWorkOrderTripAcceptanceDialog}
            // nextDeadline={item.salary_period.days_till_deadline === daysTillDeadlines[0]}
          />
        </animated.div>
      )}
    </Transition>
  );
};


@inject('uiStore', 'timelogStore', 't', 'actionCableStore')
@observer
class EmployerTimelog extends Component {
  // cableApp = {};

  constructor(props) {
    super(props);
    const { uiStore: { mobileMode, currentEmployerAcceptanceMode } } = props;

    this.state = {
      originalWorkHoursWithMeta: null,
      currentWorkHoursWithMeta: null,
      // originalWorkOrderTripsWithMeta: null,
      // currentWorkOrderTripsWithMeta: null,
      filterDialogOpen: false,
      filterCount: 0,
      currentFilters: ['work_hour.status.pending'], // Must contain the FilterDialog defaultFilters
      // viewMode: mobileMode ? 'cards' : 'table',
      // Used for database queries
      workHoursSelected: [],
      // Used for MUIDataTable
      tableRowsSelected: [],
      rejectingEmployerComment: '',
      columnVisibility: {},

      showEmployeeInfoDialog: false,
      employeeInfoDialogData: null,

      showWorkOrderInfoDialog: false,
      workOrderInfoDialogData: null,
      // daysTillDeadlines: [],
      salaryPeriods: [],
    };

    if (currentEmployerAcceptanceMode) {
      // Use the user-set mode
      this.state.viewMode = currentEmployerAcceptanceMode;
    } else {
      // Use default mode according to screen size (mobile vs. desktop)
      this.state.viewMode = mobileMode ? 'cards' : 'table';
    }

    this.toggleFilterDialog = this.toggleFilterDialog.bind(this);
    this.toggleFilterHours = this.toggleFilterHours.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    const { actionCableStore } = this.props;

    this.getTimelogs();
    this.cable = actionCableStore.cableOn
      ? actionCableStore.subscribe('EmployerWorkHoursChannel', this.processUpdateResponse)
      : null;
  }

  componentWillUnmount() {
    const { actionCableStore } = this.props;

    if (this.cable) {
      actionCableStore.unsubscribe(this.cable);
    }
  }

  getTimelogs() {
    const { timelogStore, uiStore: { currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props;

    timelogStore.getResponsibleEmployerTimelogs().then((data) => {
      const salaryPeriods = [];
      let processedHours = data.hours;
      if (salaryPeriodCategoriesEnabled) {
        processedHours = data.hours.map((hour) => {
          const updatedHour = { ...hour };
          if (!salaryPeriods.find((salaryPeriod) => salaryPeriod.id === hour.salary_period.id)) {
            salaryPeriods.push(hour.salary_period);
          }
          const salaryPeriodFrom = moment(hour.salary_period.from).format('dd DD.MM.');
          const salaryPeriodTo = moment(hour.salary_period.to).format('dd DD.MM.');
          updatedHour.salary_period.nameWithDates = `${hour.salary_period.name}, ${salaryPeriodFrom} - ${salaryPeriodTo}`;
          return updatedHour;
        });

        this.setState({
          // Sort the days till deadlines ascending: earliest deadline first
          salaryPeriods, // daysTillDeadlines.sort((a, b) => a - b),
        });
      }

      this.setState({
        originalWorkHoursWithMeta: processedHours,
        salaryPeriod: data.salary_period,
      });
    });
  }

  processUpdateResponse = (response) => {
    if (response.type === 'work_hour') {
      if (response.method === 'update') {
        this.updateWorkHourStatusAndComment(response.body, response.body.status, response.body.employer_comment);
        // this.updateWorkHourEmployeeAccept(response.body.work_hours);
      } else {
        // Assuming "get" method and an array of hour IDs
        this.getWorkHours(response.body.ids);
      }
    }

    if (response.body.deleted_ids) {
      this.deleteWorkHourCard(response.body.deleted_ids);
    }
  }

  processSelfUpdateResponse = (response) => {
    response.body.updated_hours.forEach((hour) => this.updateWorkHourValues(hour));
  }

  setActiveFilters = (currentFilters) => {
    this.setState({ currentFilters });
  }

  getWorkHours = (ids) => {
    const { timelogStore } = this.props;
    timelogStore.getResponsibleEmployerTimelogsById(ids).then((hoursWithMeta) => {
      this.updateWorkHourEmployeeAccept(hoursWithMeta);
    });
  }

  updateWorkHourStatusAndComment = (workHour, newStatus, employerComment) => {
    const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
    const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];
    const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];
    const {
      id: workHourId,
      user_id: userId,
      work_order_id: workOrderId,
      date,
    } = workHour;

    const foundOriginalWorkHour = originalWorkHoursWithMeta.find((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
    const updatedOriginalWorkHour = { ...foundOriginalWorkHour };

    // let matchingFilters = [];
    if (foundOriginalWorkHour) {
      // Stupid, there's old_work_hour but it's status is an integer, not string
      // originalStatus = foundOriginalWorkHour.work_hour.status;
      updatedOriginalWorkHour.work_hour.status = newStatus;
      // Need to update the employer comment to keep it up to date for the employer
      updatedOriginalWorkHour.work_hour.employer_comment = employerComment;
    }

    const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
    const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
    // Do the current filters match the work hour?

    if (foundOriginalWorkHourIndex !== 1) {
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.status = newStatus;
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.employer_comment = employerComment;
    }

    const filtersMatch = customFilterComparison(updatedOriginalWorkHour, currentFilters);

    if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
      // Found but filters do not match, removing
      updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
    } else if (foundCurrentWorkHourIndex !== -1) {
      // Found and either the filters match or there's no filters at all, updating
      updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = updatedOriginalWorkHour;
    } else if (filtersMatch || currentFilters.length === 0) {
      // Not found but filters match or there's no filters at all, adding
      updatedCurrentWorkHoursWithMeta.push(updatedOriginalWorkHour);
    }

    this.setState({
      originalWorkHoursWithMeta: [...updatedOriginalWorkHoursWithMeta],
      currentWorkHoursWithMeta: [...updatedCurrentWorkHoursWithMeta],
    });
  }

  handleRejectedEmpty = (newWorkHour) => {
    const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
    const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];
    const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];

    const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => !item.work_hour.id && item.work_hour.user_id === newWorkHour.user_id && item.work_hour.date === newWorkHour.date && item.work_hour.work_order_id === newWorkHour.work_order_id);
    const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => !item.work_hour.id && item.work_hour.user_id === newWorkHour.user_id && item.work_hour.date === newWorkHour.date && item.work_hour.work_order_id === newWorkHour.work_order_id);

    let updatedOriginalWorkHour;
    if (foundOriginalWorkHourIndex !== 1) {
      // updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour = newWorkHour;
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.id = newWorkHour.id;
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.status = newWorkHour.status;
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.employer_comment = newWorkHour.employer_comment;
      updatedOriginalWorkHour = { ...updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex] };
    }

    const filtersMatch = customFilterComparison(updatedOriginalWorkHour, currentFilters);

    if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
      // Found but filters do not match, removing
      updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
    } else if (foundCurrentWorkHourIndex !== -1) {
      // Found and either the filters match or there's no filters at all, updating
      updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = updatedOriginalWorkHour;
    } else if (filtersMatch || currentFilters.length === 0) {
      // Not found but filters match or there's no filters at all, adding
      updatedCurrentWorkHoursWithMeta.push(updatedOriginalWorkHour);
    }

    this.setState({
      originalWorkHoursWithMeta: [...updatedOriginalWorkHoursWithMeta],
      currentWorkHoursWithMeta: [...updatedCurrentWorkHoursWithMeta],
    });
  }

  updateWorkHour = (workHourWithMeta) => {
    const {
      work_hour: {
        id: workHourId,
        user_id: userId,
        work_order_id:
        workOrderId,
        date,
      },
    } = workHourWithMeta;

    const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
    const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];
    const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];

    const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
    let foundOriginalWorkHour = null;
    // let matchingFilters = [];
    if (foundOriginalWorkHourIndex !== -1) {
      foundOriginalWorkHour = workHourWithMeta; // originalWorkHoursWithMeta[foundOriginalWorkHourIndex];
      // Stupid, there's old_work_hour but it's status is an integer, not string
      // originalStatus = foundOriginalWorkHour.work_hour.status;
      // foundOriginalWorkHour.work_hour.status = newStatus;
      // // Need to update the employer comment to keep it up to date for the employer
      // foundOriginalWorkHour.work_hour.employer_comment = employerComment;
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex] = workHourWithMeta;
    }

    const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
    // Do the current filters match the work hour?
    const filtersMatch = customFilterComparison(foundOriginalWorkHour, currentFilters);

    if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
      // Found but filters do not match, removing
      updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
    } else if (foundCurrentWorkHourIndex !== -1) {
      // Found and either the filters match or there's no filters at all, updating
      updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = foundOriginalWorkHour;
    } else if (filtersMatch || currentFilters.length === 0) {
      // Not found but filters match or there's no filters at all, adding
      updatedCurrentWorkHoursWithMeta.push(foundOriginalWorkHour);
    }

    this.setState({
      currentWorkHoursWithMeta: updatedCurrentWorkHoursWithMeta,
      originalWorkHoursWithMeta: updatedOriginalWorkHoursWithMeta,
    });
  }

  // After current user's self-initiated bulk update, for work hours without meta
  updateWorkHourValues = (workHour) => {
    const {
      id: workHourId,
      work_order_id: workOrderId,
      date,
      user_id: userId,
    } = workHour;

    const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
    const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];
    const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];

    // const foundOriginalWorkHourIndex = originalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId);
    const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (item.work_hour.work_order_id === workOrderId && item.work_hour.date === date && item.work_hour.user_id === userId));
    let foundOriginalWorkHour = null;

    if (foundOriginalWorkHourIndex !== -1) {
      // We update the keys one by one in order to maintain the old oldering from Rails generate_employer_hour_with_meta
      const updatedWorkHour = { ...originalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour };
      Object.keys(updatedWorkHour).forEach((attr) => {
        updatedWorkHour[attr] = workHour[attr];
      });
      updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour = updatedWorkHour;

      foundOriginalWorkHour = { ...originalWorkHoursWithMeta[foundOriginalWorkHourIndex], work_hour: updatedWorkHour };
    }

    const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId);
    // Do the current filters match the work hour?
    const filtersMatch = customFilterComparison(foundOriginalWorkHour, currentFilters);

    if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
      // Found but filters do not match, removing
      updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
    } else if (foundCurrentWorkHourIndex !== -1) {
      // Found and either the filters match or there's no filters at all, updating
      updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = foundOriginalWorkHour;
    } else if (filtersMatch || currentFilters.length === 0) {
      // Not found but filters match or there's no filters at all, adding
      updatedCurrentWorkHoursWithMeta.push(foundOriginalWorkHour);
    }

    this.setState({
      originalWorkHoursWithMeta: updatedOriginalWorkHoursWithMeta,
      currentWorkHoursWithMeta: updatedCurrentWorkHoursWithMeta,
    });
  }

  deleteWorkHourCard = (deletedWorkHourIds) => {
    const { originalWorkHoursWithMeta, currentWorkHoursWithMeta } = this.state;
    const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta].filter((workHourWithMeta) => !deletedWorkHourIds.includes(workHourWithMeta.work_hour.id));
    const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta].filter((workHourWithMeta) => !deletedWorkHourIds.includes(workHourWithMeta.work_hour.id));

    this.setState({
      currentWorkHoursWithMeta: updatedCurrentWorkHoursWithMeta,
      originalWorkHoursWithMeta: updatedOriginalWorkHoursWithMeta,
    });
  }

  closeRejectWorkHourDialog = () => {
    this.setState({
      showRejectWorkHourDialog: false,
      rejectingSendSMS: false,
      rejectingEmployerComment: '',
      rejectWorkHourDialogTitle: '',
    });
  }

  closeEmployeeInfoDialog = () => {
    this.setState({
      showEmployeeInfoDialog: false,
      employeeInfoDialogData: null,
    });
  }

  closeWorkOrderInfoDialog = () => {
    this.setState({
      showWorkOrderInfoDialog: false,
      workOrderInfoDialogData: null,
    });
  }

  openRejectWorkHourDialog = (rejectingWorkHour, rejectingWorkOrderId, rejectWorkHourDialogTitle, mode) => {
    this.setState({
      rejectingMode: mode,
      showRejectWorkHourDialog: true,
      rejectingWorkHour,
      rejectingWorkOrderId,
      rejectWorkHourDialogTitle,
    });
  }

  openEmployeeInfoDialog = (employee) => {
    this.setState({
      showEmployeeInfoDialog: true,
      employeeInfoDialogData: employee,
    });
  }

  openWorkOrderInfoDialog = (workOrderInfo) => {
    this.setState({
      showWorkOrderInfoDialog: true,
      workOrderInfoDialogData: workOrderInfo,
    });
  }

  openWorkOrderTripAcceptanceDialog = (workOrderTripId) => {
    const { timelogStore } = this.props;
    this.setState({
      showWorkOrderTripDialog: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.getResponsibleEmployerWorkOrderTripsById(workOrderTripId, resolve, reject))
        .then((woTrip) => {
          // Success
          const workOrderTrip = WorkOrderTrip.fromJsonProperties(woTrip);
          this.setState({
            openedWorkOrderTripWithMeta: workOrderTrip,
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            openedWorkOrderTripWithMeta: null,
          }, console.log('ERROR: ', err));
        });
    });
  }

  setWorkHoursSelected = (hours, rows) => {
    this.setState({
      tableRowsSelected: rows,
      workHoursSelected: hours,
    });
  }

  setColumnVisibility = (columnVisibility) => {
    this.setState({ columnVisibility });
  }

  toggleViewMode() {
    const { uiStore: { setCurrentEmployerAcceptanceMode } } = this.props;

    this.setState((prevState) => ({
      viewMode: prevState.viewMode === 'cards' ? 'table' : 'cards',
    }), () => {
      const { viewMode } = this.state;
      setCurrentEmployerAcceptanceMode(viewMode);
    });
  }

  filterData(filteredData, filterCount, activeFilters) {
    const { timelogStore } = this.props;
    const { workHoursSelected } = this.state;
    timelogStore.setHourFilters(activeFilters);

    if (filteredData) {
      filteredData.sort((a, b) => this.sortWorkHours(a, b));
    }

    // If the filters, and thus table content, change, we need to re-generate the selected row indexes
    // Otherwise the wrong rows are selected
    const updatedTableRowsSelected = [];
    const updatedWorkHoursSelected = [];
    // We check each selected work hour individually: is it still visible (in the table's new dataset) or not
    workHoursSelected.forEach((selectedWorkHour) => {
      const foundIndex = filteredData.findIndex((workHourWithMeta) => workHourWithMeta.work_hour.id === selectedWorkHour.id);
      if (foundIndex !== -1) {
        // Selected row is still visible after filter change: keep it in the selected list
        updatedTableRowsSelected.push(foundIndex);
        // By starting from an empty array and pushing the hour back if found, we remove missing hours
        updatedWorkHoursSelected.push(selectedWorkHour);
      }
    });

    this.setState({
      currentWorkHoursWithMeta: filteredData,
      filterCount,
      tableRowsSelected: updatedTableRowsSelected,
      workHoursSelected: updatedWorkHoursSelected,
    });
  }

  toggleFilterDialog() {
    this.setState((prevState) => ({
      filterDialogOpen: !prevState.filterDialogOpen,
    }));
  }

  toggleFilterHours() {
    this.setState((prevState) => ({
      filterHours: !prevState.filterHours,
    }));
  }

  updateWorkHourEmployeeAccept(workHoursWithMeta) {
    workHoursWithMeta.forEach((workHourWithMeta) => {
      this.updateWorkHour(workHourWithMeta);
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sortWorkHours(a, b) {
    // If both user name and work hour date are identical, sort by created_at
    if (a.user_name === b.user_name && a.work_hour.date === b.work_hour.date) {
      return a.work_hour.created_at > b.work_hour.created_at ? 1 : -1;
    }

    // Otherwise, sort by work hour date and user name
    if (a.user_name === b.user_name) {
      return (a.work_hour.date > b.work_hour.date) ? 1 : -1;
    }

    return (a.user_name > b.user_name) ? 1 : -1;
  }

  togglefilterCollapsibleOpen() {
    this.setState((prevState) => ({
      filterCollapsibleOpen: !prevState.filterCollapsibleOpen,
    }));
  }

  employerReject(workOrderId, workHourId, sendSMS) {
    const { timelogStore, uiStore } = this.props;
    const { rejectingEmployerComment } = this.state;
    const user = uiStore.currentUser;

    this.setState({
      // loading: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.employerReject(user, workOrderId, workHourId, rejectingEmployerComment, sendSMS, resolve, reject))
        .then((response) => {
          // Success
          this.setState({
            // loading: false,
            showRejectWorkHourDialog: false,
            rejectingWorkHour: null,
            rejectingWorkOrderId: null,
            rejectingSendSMS: false,
            rejectingEmployerComment: '',
          }, this.updateWorkHourStatusAndComment(response.body, response.body.status, response.body.employer_comment));
        })
        .catch((err) => {
          // Failure
          this.setState({
            // loading: false,
          }, console.log('ERROR: ', err));
        });
    });
  }

  // Rejecting an empty row, which means we are actually creating a new row with the rejected status and metadata, but no hours
  employerRejectEmpty(workHour, sendSMS) {
    const { timelogStore, uiStore } = this.props;
    const { rejectingEmployerComment } = this.state;
    const user = uiStore.currentUser;

    this.setState({
      // loading: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.employerRejectEmpty(user, workHour, rejectingEmployerComment, sendSMS, resolve, reject))
        .then((response) => {
          // Success
          this.setState({
            // loading: false,
            showRejectWorkHourDialog: false,
            rejectingWorkHour: null,
            rejectingWorkOrderId: null,
            rejectingSendSMS: false,
            rejectingEmployerComment: '',
          }, this.handleRejectedEmpty(response.body));
        })
        .catch((err) => {
          // Failure
          this.setState({
            // loading: false,
          }, console.log('ERROR: ', err));
        });
    });
  }

  acceptChecked() {
    const { timelogStore } = this.props;
    const { workHoursSelected } = this.state;

    timelogStore.employerAcceptMultiple(workHoursSelected).then(() => {
      // No need to handle response because it is received and handled through actioncable (broadcasted to all employers)
      this.setState({
        workHoursSelected: [],
        tableRowsSelected: [],
      });
    });
  }

  rejectChecked() {
    const { timelogStore } = this.props;
    const { workHoursSelected, rejectingSendSMS, rejectingEmployerComment } = this.state;


    timelogStore.employerRejectMultiple(workHoursSelected, rejectingEmployerComment, rejectingSendSMS).then(() => {
      this.setState({
        workHoursSelected: [],
        tableRowsSelected: [],
        rejectingSendSMS: false,
        rejectingEmployerComment: '',
        showRejectWorkHourDialog: false,
      });
    });
  }

  renderWorkOrderTripAcceptanceDialog() {
    const { showWorkOrderTripDialog, openedWorkOrderTripWithMeta } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog"
        open={showWorkOrderTripDialog}
        onClose={() => {
          this.setState({
            showWorkOrderTripDialog: false,
            openedWorkOrderTripWithMeta: null,
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          {openedWorkOrderTripWithMeta ? (
            <EmployerWorkOrderTripCard
              workOrderTrip={openedWorkOrderTripWithMeta}
              key={`trip-card-${openedWorkOrderTripWithMeta.id}`}
              updateWorkOrderTrip={this.updateWorkOrderTrip}
              dialogMode
            />
          ) : (
            <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
              <LoadingSpinner color="black" />
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderRejectWorkHourDialog() {
    const {
      showRejectWorkHourDialog,
      rejectingWorkHour,
      rejectingWorkOrderId,
      rejectingSendSMS,
      rejectingEmployerComment,
      rejectWorkHourDialogTitle,
      rejectingMode,
      // workHoursSelected,
    } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog"
        open={showRejectWorkHourDialog}
        onClose={() => {
          this.setState({
            showRejectWorkHourDialog: false,
            rejectingEmployerComment: '',
            rejectingSendSMS: false,
            rejectingMode: null,
          });
        }}
      >
        <DialogTitle>{rejectWorkHourDialogTitle || 'Pyydä korjaus'}</DialogTitle>
        <DialogContent
          style={{ color: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              style={{
                width: '94%',
              }}
              label="Kirjoita korjauksen ohje tai lisätietoa"
              icon="info"
              value={rejectingEmployerComment}
              onChange={(event) => this.setState({ rejectingEmployerComment: event.target.value })}
            />
          </div>
          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingSendSMS: !prevState.rejectingSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            onClick={() => {
              if (rejectingMode === 'single') {
                if (rejectingWorkHour.id) {
                  this.employerReject(rejectingWorkOrderId, rejectingWorkHour.id, rejectingSendSMS);
                } else {
                  this.employerRejectEmpty(rejectingWorkHour, rejectingSendSMS);
                }
              } else if (rejectingMode === 'multiple') {
                this.rejectChecked();
              }
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { uiStore } = this.props;
    const { timelogStore: { workHourFilters } } = this.props;
    const {
      originalWorkHoursWithMeta,
      // originalWorkOrderTripsWithMeta,
      currentWorkHoursWithMeta,
      // currentWorkOrderTripsWithMeta,
      filterHours,
      // filterTrips,
      filterDialogOpen,
      filterCount,
      viewMode,
      currentFilters,
      tableRowsSelected,
      workHoursSelected,
      columnVisibility,
      showEmployeeInfoDialog,
      employeeInfoDialogData,
      showWorkOrderInfoDialog,
      workOrderInfoDialogData,
      salaryPeriod,
      // daysTillDeadlines,
      salaryPeriods,
    } = this.state;
    const { uiStore: { currentUser, currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props;

    const acceptMultipleDisabled = workHoursSelected.find((whData) => !whData.id);

    const filters = [
      { title: 'Tila', key: 'work_hour.status', translate: true },
      { title: 'Kirjausten hyväksyjä', key: 'accepting_employers', translate: false },
      { title: 'Keikka', key: 'work_order_info.name', translate: false },
      { title: 'Työntekijä', key: 'user.full_name', translate: false },
    ];

    if (salaryPeriodCategoriesEnabled) {
      filters.unshift({ title: 'Menossa oleva palkkakausi', key: 'salary_period.nameWithDates', translate: false });
    }

    return (
      <div className="employer-timelog-wrapper">
        <div
          style={{
            padding: '10px',
            width: '100%',
            // textAlign: 'end',
            // maxWidth: '350px',
            display: 'flex',
            justifyContent: viewMode === 'table' ? 'space-between' : 'flex-end',
            flexWrap: viewMode === 'table' ? 'wrap-reverse' : 'initial',
          }}
          className="filter-button"
        >
          {viewMode === 'table' && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div>
                <Button
                  className="employer-accept-button"
                  style={{ height: '36px', width: '180px' }}
                  onClick={() => { this.acceptChecked(); }}
                  disabled={acceptMultipleDisabled || tableRowsSelected.length === 0}
                >
                  Hyväksy valitut
                </Button>

                <Button
                  className="employer-reject-button"
                  style={{ height: '36px', width: '180px', margin: '0 10px' }}
                  onClick={() => this.openRejectWorkHourDialog(null, null, '', 'multiple')}
                  disabled={tableRowsSelected.length === 0}
                >
                  Pyydä korjaus
                </Button>
              </div>
              {acceptMultipleDisabled && (
                <div style={{ fontSize: '12px', color: 'gray', marginTop: '5px' }}>
                  Kirjauksia odottavia päiviä ei voi hyväksyä.
                </div>
              )}
            </div>
          )}

          <div className="employer-timelogs-header">
            <div className={uiStore.mobileMode ? 'employer-timelogs-title employer-timelogs-title-mobile' : 'employer-timelogs-title'}>
              {salaryPeriod && !salaryPeriodCategoriesEnabled && `Jakso: ${dateRange(salaryPeriod.from, salaryPeriod.to)}`}
              {salaryPeriodCategoriesEnabled && (
                <span style={{ textTransform: 'initial' }}>Tuntikirjausten hyväksyntä</span>
              )}
              {salaryPeriodCategoriesEnabled && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    textTransform: 'none',
                    marginTop: '5px',
                  }}
                >
                  {/* Should be smallest days till deadline first */}
                  {/* {daysTillDeadlines?.map((deadline, index) => {
                    if (deadline >= 0) {
                      return (
                        <div style={{ display: 'flex' }}>
                          <Brightness1Icon style={{ fontSize: '18px', color: index === 0 ? '#FF6A00' : '#FF9E00' }} />
                          <span>{`${Math.abs(deadline)} pv jäljellä`}</span>
                        </div>
                      );
                    }
                    return (
                      <div style={{ display: 'flex' }}>
                        <Brightness1Icon style={{ fontSize: '18px', color: index === 0 ? '#FF6A00' : '#FF9E00' }} />
                        <span
                          style={{ marginLeft: '3px' }}
                        >
                          {`${Math.abs(deadline)} pv myöhässä`}
                        </span>
                      </div>
                    );
                  })} */}

                  {salaryPeriods?.map((timelogSalaryPeriod) => (
                    <div style={{ display: 'flex' }}>
                      <StopIcon style={{ fontSize: '35px', margin: '-5px', color: timelogSalaryPeriod.color }} />
                      <span style={{ alignSelf: 'center', textTransform: 'capitalize', marginRight: '5px' }}>
                        {timelogSalaryPeriod.nameShort ? (
                          `${timelogSalaryPeriod.nameShort}, ${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`
                        ) : (
                          `${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`
                        )}
                      </span>
                      {/* <span style={{ alignSelf: 'center', marginRight: '5px' }}>
                        {`${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`}
                      </span> */}
                      <span style={{ fontStyle: 'italic', alignSelf: 'center' }}>
                        {`${timelogSalaryPeriod.daysTillDeadline} pv`}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div>
              <Button
                type="button"
                onClick={() => this.toggleFilterDialog()}
                className="employer-reject-button mdc-button"
                startIcon={<FilterListIcon />}
                style={{
                  // width: '100%',
                  // maxWidth: '350px',
                  width: '335px',
                }}
              >
                Suodata
                {filterCount !== 0 && (
                  <span style={{ marginLeft: '5px' }}>
                    (
                      {filterCount}
                    )
                  </span>
                )}
              </Button>

              <IconButton
                style={{
                  display: uiStore.mobileMode ? 'none' : 'initial',
                  padding: 0,
                  width: '60px',
                  marginLeft: '20px',
                  height: '36px',
                }}
                className="employer-reject-button"
                onClick={() => this.toggleViewMode()}
                size="small"
              >
                {viewMode === 'table' ? <DashboardIcon fontSize="inherit" /> : <FormatListBulletedIcon fontSize="inherit" />}
              </IconButton>
            </div>
          </div>
        </div>
        <div>
          {/* Require originalWorkHoursWithMeta for the rendering here because otherwise FilterDialog renders with null originalWorkHoursWithMeta and would require some ComponentDidUpdate code */}
          {originalWorkHoursWithMeta && (
            <FilterDialog
              open={filterDialogOpen}
              toggleDialog={this.toggleFilterDialog}
              originalData={originalWorkHoursWithMeta}
              dataTitle="Suodata tunnit"
              // dataTitle2="Suodata matkat"
              filterOptions={{
                // NOTE: Using lodash's get() let's you use nested attributes as a single string like so: 'work_order.date'
                // Multiple filters can be combined (OR operator) by separating the attrs with '||'
                filters,
                defaultFilters: [
                  {
                    // filter: 'work_hour.status.pending',
                    filter: {
                      key: 'work_hour.status',
                      value: 'pending',
                    },
                    // Required for generating buttons for these default filters so that users can turn them off when there's no matching data
                    // IMPORTANT: Needs to match the filterOptions.filters object
                    filterMeta: {
                      title: 'Tila',
                      key: 'work_hour.status',
                      translate: true,
                    },
                  },
                  {
                    // IMPORTANT: filter parts constructed with user-given strings need to be wrapped in single quote
                    // This is to prevent things from breaking when they use .
                    // filter: `accepting_employers.${currentUser.lastName}, ${currentUser.firstName}`,
                    filter: {
                      key: 'accepting_employers',
                      value: `${currentUser.lastName}, ${currentUser.firstName}`,
                    },
                    filterMeta: {
                      title: 'Kirjausten hyväksyjä',
                      key: 'accepting_employers',
                      translate: false,
                    },
                  },
                ],
              }}
              filterData={this.filterData}
              // filterData2={this.filterData2}
              translationPrefix="work_hour_filters"
              setActiveFilters={this.setActiveFilters}
              previousFilters={workHourFilters}
            />
          )}

          {!currentWorkHoursWithMeta && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          )}

          {viewMode === 'cards' && (
            <div className="employer-card-wrapper">
              {
                // !filterHours && sortedWorkHoursWithMeta?.map((workHourWithMeta) => <EmployerTimelogCard workHourWithMeta={workHourWithMeta} key={workHourWithMeta.work_hour.id} />)
                !filterHours && currentWorkHoursWithMeta && (
                  <TransitionedCards
                    sortedWorkHoursWithMeta={currentWorkHoursWithMeta}
                    updateWorkHour={this.updateWorkHourStatusAndComment}
                    openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                    closeRejectWorkHourDialog={this.closeRejectWorkHourDialog}
                    openEmployeeInfoDialog={this.openEmployeeInfoDialog}
                    openWorkOrderInfoDialog={this.openWorkOrderInfoDialog}
                    afterUpdate={this.processSelfUpdateResponse}
                    openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                    // daysTillDeadlines={daysTillDeadlines}
                  />
                )
              }
            </div>
          )}

          {viewMode === 'table' && (
            currentWorkHoursWithMeta && (
              <EmployerTimelogTable
                workHours={currentWorkHoursWithMeta}
                currentFilters={currentFilters}
                updateWorkHour={this.updateWorkHourStatusAndComment}
                rowsSelected={tableRowsSelected}
                setWorkHoursSelected={this.setWorkHoursSelected}
                openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                columnVisibility={columnVisibility}
                setColumnVisibility={this.setColumnVisibility}
                afterUpdate={this.processSelfUpdateResponse}
                salaryPeriods={salaryPeriods}
                openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                // daysTillDeadlines={daysTillDeadlines}
              />
            )
          )}

          {viewMode === 'cards' && currentWorkHoursWithMeta?.length === 0 && (
            <div style={{ padding: '20px' }}>Tuntikirjauksia ei löytynyt</div>
          )}

          {this.renderRejectWorkHourDialog()}
          {this.renderWorkOrderTripAcceptanceDialog()}
          <EmployeeInfoDialog open={showEmployeeInfoDialog} employee={employeeInfoDialogData} onClose={this.closeEmployeeInfoDialog} />
          <WorkOrderInfoDialog open={showWorkOrderInfoDialog} workOrderInfo={workOrderInfoDialogData} onClose={this.closeWorkOrderInfoDialog} />
        </div>
      </div>
    );
  }
}

export default EmployerTimelog;
