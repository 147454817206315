import { action, observable, set } from 'mobx';
import moment, { asTime, dateWithoutTime } from '../utils/moment';
import WorkTask from '../employer-work-orders/work-tasks/WorkTask';
// import UserProductCollection from '../models/product/UserProductCollection';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class WorkTaskEntry {
  @observable id;

  @observable createdAt;

  @observable updatedAt;

  @observable description;

  @observable from;

  @observable to;

  @observable status;

  @observable time100;

  @observable time50;

  @observable timeNormal;

  @observable workTask;

  @observable workHourId;

  @observable nightShift = false;

  // A field that combines timeNormal, time50 and time100 for billing reports
  @observable timeTotal;

  // static sortByTime(a, b) {
  //   return (a.from > b.from) ? 1 : -1;
  // }

  // ChatGPT 4, 8.4.2024
  static sortByTime(a, b) {
    // First, compare by nightShift status, true values come before false
    if (a.nightShift && !b.nightShift) {
      return 1; // b comes before a
    }
    if (!a.nightShift && b.nightShift) {
      return -1; // a comes before b
    }
    // If nightShift status is the same for both, then compare by 'from'
    if (a.from > b.from) {
      return 1; // a comes after b
    }

    if (a.from < b.from) {
      return -1; // a comes before b
    }
    return 0; // a and b are equal
  }

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      description: o.description,
      from: o.from,
      to: o.to,
      // status: (o.status == null) ? 'draft' : o.status,
      time_100: o.time100,
      time_50: o.time50,
      time_normal: o.timeNormal,
      work_task_id: o.workTask ? o.workTask.id : null,
      work_hour_id: o.workHourId,
      night_shift: o.nightShift,
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      updatedAt,
      createdAt: getDate(o.created_at),
      description: o.description,
      from: asTime(o.from),
      to: asTime(o.to),
      status: o.status == null ? 'draft' : o.status,
      time100: getFloat(o.time_100),
      time50: getFloat(o.time_50),
      timeNormal: getFloat(o.time_normal),
      workTask: o.work_task ? WorkTask.fromJsonProperties(o.work_task) : null,
      timeTotal: ((getFloat(o.time_normal) || 0) + (getFloat(o.time_50) || 0) + (getFloat(o.time_100) || 0)) || null,
      nightShift: o.night_shift,
    };

    return this.updateProperties(values);
  }

  // tripRoutesFromJson(trips) {
  //   return trips.map(trip => TripRoute.fromJson(trip))
  // }

  // tripRoutesToJson(trips) {
  //   return trips.map(trip => TripRoute.toJson(trip))
  // }

  static fromJsonProperties(object) {
    const timelog = new WorkTaskEntry({});
    timelog.updatePropertiesFromJson(object);
    return timelog;
  }

  static fromJson(json) {
    return WorkTaskEntry.fromJsonProperties(JSON.parse(json));
  }
}
