/* eslint-disable */
import React, { Component } from 'react';
import { observable, action } from 'mobx';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';

import '@rmwc/avatar/avatar.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import './messages.css';

import ChatRoomRow from './ChatRoomRow';
import Messsage from './Message';
import { appcontentsize } from '../styles/inline-styles';
import ChatMessage from './stores/ChatMessage';

@inject('uiStore', 'chatStore', 't', 'actionCableStore')
@observer
class Chats extends Component {
  messagesEndRef = React.createRef();

  rootRef = document.documentElement;

  @observable messageContent = '';

  waitingTimeouts = {};

  constructor(props) {
    super(props);
    this.resetMessageInput();

    this.state = {
      typingStatus: false,
      usersTyping: [],
      chatId: this.props.uiStore.currentView.id,
    }
  }

  componentDidMount() {
    const { actionCableStore, chatStore, uiStore, chatRoom } = this.props;

    const room = chatRoom || chatStore.findById(uiStore.currentView.id);

    this.cable = actionCableStore.cableOn && room
      ? actionCableStore.subscribe({ channel: 'ChatRoomChannel', chat_id: room.id }, this.handleCableData)
      : null;

    this.scrollToBottom();
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  componentWillUnmount() {
    const { actionCableStore, uiStore } = this.props;
    const { typingStatus, chatId } = this.state;

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout)
    }

    if (Object.keys(this.waitingTimeouts).length > 0) {
      const keys = Object.keys(this.waitingTimeouts)

      keys.forEach((key) => {
        clearTimeout(this.waitingTimeouts[key])
      })
    }

    if (this.cable) {
      if (typingStatus) {
        this.cable.updateTyping(chatId, false)
      }
      actionCableStore.unsubscribe(this.cable);
    }
  }

  @action onMessageChanged = (e) => {
    const { uiStore, chatRoom } = this.props;
    const { typingStatus } = this.state;

    this.messageContent = e.target.value;

    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }

    this.startTypingTimer();

    if (this.messageContent.length % 10 === 0) {
      this.cable.updateTyping(uiStore.currentView.id || chatRoom.id, true);
    }

    if (this.messageContent.length > 0 && typingStatus === false) {
      this.setState({
        typingStatus: true,
      }, () => this.cable.updateTyping(uiStore.currentView.id || chatRoom.id, true));
    }
    else if (this.messageContent.length === 0 && typingStatus === true) {
      this.setState({
        typingStatus: false,
      }, () => this.cable.updateTyping(uiStore.currentView.id || chatRoom.id, false));
    }
  };

  @action sendMessage = () => {
    const { chatRoom, addToTotalChatMessagesCount } = this.props;
    const messageContent = this.messageContent != null ? this.messageContent.trim() : '';

    if (messageContent === '') {
      // don't send empty messages
      return;
    }
    const { chatStore, uiStore } = this.props;
    const room = chatRoom || chatStore.findById(uiStore.currentView.id);

    chatStore.sendMessage(room, messageContent, addToTotalChatMessagesCount);

    this.resetChatInput();
    this.resetTypingStatus();
  };

  startTypingTimer() {
    this.typingTimeout = setTimeout(this.notifyStopTyping, 5000);
  }

  notifyStopTyping = () => {
    const { uiStore } = this.props;

    this.cable.updateTyping(uiStore.currentView.id, false)
  }

  scrollToBottom = () => {
    if (this.messagesEndRef.current != null) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleCableData = (response) => {
    const { chatStore, uiStore, chatRoom, addToTotalChatMessagesCount } = this.props;
    const { usersTyping } = this.state;

    if (response.type === "message") {
      const room = chatRoom || chatStore.findById(uiStore.currentView.id);

      this.readMessage(room, [response.body.id])

      const senderId = response.body.sender.id

      if (senderId !== uiStore.currentUser.id) {
        room.messages.push(ChatMessage.fromJson(response.body));
        if (addToTotalChatMessagesCount) {
          addToTotalChatMessagesCount(chatRoom.workorder.id);
        }
      }
    }
    else if (response.type === "status") {
      const userData = {
        "firstName": response.body.first_name,
        "userId": response.body.id,
        "status": response.body.status,
      }

      let newUsersTyping = [...usersTyping]
      const foundIndex = newUsersTyping.findIndex((user) => user.userId === userData.userId)

      if (userData.userId !== uiStore.currentUser.id) {
        if (foundIndex === -1 && userData.status === true) {
          newUsersTyping.push(userData)
          this.startWaitingTimer(userData.userId)
        }
        else if (foundIndex !== -1 && userData.status !== true) {
          clearTimeout(this.waitingTimeouts[userData.userId])
          delete this.waitingTimeouts[userData.userId]
          newUsersTyping.splice(foundIndex, 1)
        } else if (foundIndex !== -1 && userData.status === true) {
          clearTimeout(this.waitingTimeouts[userData.userId])
          this.startWaitingTimer(userData.userId)
        }

        this.setState({
          usersTyping: newUsersTyping,
        })
      }
    }
  }

  startWaitingTimer(userId) {
    this.waitingTimeouts[userId] = setTimeout(this.dropUserTyping, 10000, userId)
  }

  dropUserTyping = (userId) => {
    const { usersTyping } = this.state;
    let newUsersTyping = [...usersTyping]
    const foundIndex = newUsersTyping.findIndex((user) => user.userId === userId)

    newUsersTyping.splice(foundIndex, 1)

    this.setState({
      usersTyping: newUsersTyping,
    })
  }

  async readMessage(room, msgId) {
    const { chatStore } = this.props;

    try {
      await chatStore.markNewAsRead(room, msgId);
      chatStore.unreadCounts();
    } catch (err) {
      console.error(err);
    }
  }

  resetMessageInput() {
    this.rootRef.style.setProperty('--chat-entry-height', '54px');
  }

  resetTypingStatus() {
    const { uiStore } = this.props;
    this.setState({
      typingStatus: false,
    }, () => this.cable.updateTyping(uiStore.currentView.id, false))
  }

  @action resetChatInput() {
    this.messageContent = '';
    this.resetMessageInput();
  }

  renderTypingStatus(usersTyping) {
    if (usersTyping.length === 1) {
      return `${usersTyping[0].firstName} kirjoittaa...`
    }
    else if (usersTyping.length === 2) {
      return `${usersTyping[0].firstName} ja ${usersTyping[1].firstName} kirjoittavat...`
    }
    else {
      return `${usersTyping[0].firstName} ja ${usersTyping.length - 1} muuta kirjoittavat...`
    }
  }

  render() {
    const { chatStore, uiStore, chatRoom, employeeMode } = this.props;
    const { usersTyping } = this.state;
    const room = chatRoom || chatStore.findById(uiStore.currentView.id);

    if (room == null) {
      // prevent stacktrace when reloading chatroom page
      uiStore.showMessages();
      return null;
    }

    const { messages } = room;

    return (
      <div {...{
        className: 'chat__controls',
        style: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          ...appcontentsize,
        },
      }}
      >
        <ChatRoomRow employerMode={chatRoom} room={room} usersTyping={this.state.usersTyping} asHeader />

        <div className="chat__container">
          <div className="chat">
            {messages.map((m) => (<Messsage key={`chat-msg-${m.id}`} message={m} room={room} employeeMode={employeeMode}/>))}
            <div className="chat__bottom_ref" ref={this.messagesEndRef} />
          </div>
        </div>
        <div
          style={{ minHeight: '1.2em', marginLeft: '5px' }}
        >
          {usersTyping.length > 0 && (
            <Typography
              use="capture"
              style={{ fontSize: '0.8rem' }}
            >
              {this.renderTypingStatus(usersTyping)}
            </Typography>
          )
          }
        </div>
        <div
          className="chat__input"
          style={{ display: 'flex', alignItems: 'flex-end' }}
        >
          <TextField
            fullwidth
            onChange={this.onMessageChanged}
            onInput={(ref) => {
              ref.currentTarget.style.setProperty('height', 'auto');
              this.rootRef.style.setProperty('--chat-entry-height', `${ref.currentTarget.scrollHeight}px`);
              ref.currentTarget.style.setProperty('height', 'var(--chat-entry-height)');
            }}
            style={{
              marginRight: '0.5rem',
              // paddingTop: '0.5rem',
              marginTop: '0px',
              filter: 'brightness(1.3)',
            }}
            textarea
            theme={['textPrimaryOnDark', 'secondaryBg']}
            value={this.messageContent}
          />
          <Icon
            icon="send"
            onClick={this.sendMessage}
            style={{ color: '#E9B801', marginBottom: '0.5em', cursor: 'pointer' }}
            tabIndex="0"
          />
        </div>
      </div>
    );
  }
}

export default Chats;
