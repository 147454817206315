/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card, CardActions, CardActionButtons, CardActionButton,
} from '@rmwc/card';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import { CollapsibleList, SimpleListItem } from '@rmwc/list';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { Button } from '@rmwc/button';

import moment, { getTripDurationWeekless } from '../utils/moment';
import { isEqual } from 'lodash';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import StartTrip from '../timelog/StartTrip';
import EndTrip from '../timelog/EndTrip';
import { buttonColors } from '../calendar/EmployeeCalendar';
// import EmployerTimelogDialogCard from '../employer-timelog/employerTimelogDialogCard';
import EmployerTimelogCard from '../employer-timelog/employerTimelogCard';
import EmployerTimelogViewDialog from '../employer-timelog/EmployerTimelogViewDialog';
import LoadingSpinner from '../shared/LoadingSpinner';

import './employerWorkOrderTripCard.css'

// const renderRouteLocations = (routeLocations, oldRouteLocations) => {
//   const routeLocationWrappers = [];
//   const sortedRouteLocations = routeLocations.sort((a, b) => (a.locationOrder.valueOf() - b.locationOrder.valueOf()));
//   const sortedOldRouteLocations = oldRouteLocations?.sort((a, b) => (a.locationOrder.valueOf() - b.locationOrder.valueOf()));

//   const currentRouteLocationElements = [];
//   sortedRouteLocations.forEach((routeLocation, index) => {
//     currentRouteLocationElements.push(
//       <div key={routeLocation.id} style={{ marginTop: index === 0 ? '0' : '10px' }}>
//         <span>{routeLocation.street}</span>
//         {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
//         {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
//       </div>,
//     );
//   });

//   routeLocationWrappers.push(
//     <div key="current_wrapper">
//       {currentRouteLocationElements}
//     </div>,
//   );

//   const oldRouteLocationElements = [];
//   // If route has changed, render the old route too
//   if (sortedOldRouteLocations && !isEqual(sortedRouteLocations, sortedOldRouteLocations)) {
//     sortedOldRouteLocations.forEach((routeLocation, index) => {
//       oldRouteLocationElements.push(
//         <div key={`${routeLocation.id}_old_location`} style={{ marginTop: index === 0 ? '0' : '10px' }} className="old-value">
//           <span>{routeLocation.street}</span>
//           {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
//           {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
//         </div>,
//       );
//     });

//     routeLocationWrappers.push(
//       <div key="old_wrapper" style={{ marginTop: '10px' }}>
//         {sortedOldRouteLocations}
//       </div>,
//     );
//   }

//   return routeLocationWrappers;
// };

@inject('timelogStore', 'uiStore', 't', 'employerContextStore')
@observer
class WorkOrderTripOrphanCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startLoading: false,
      endLoading: false,
      rejectingStart: false,
      rejectingEnd: false,
      commentStart: '',
      commentEnd: '',
      sendingSMSStart: false,
      sendingSMSEnd: false,
      showTimelogDialog: false,
      openedTimelogWithMeta: null,
      rejectingTimelog: false,
      showRejectTripDialog: false,
      rejectTripDialogTitle: '',

      rejectingTripExpenses: null,
      showRejectTripExpenseDialog: false,
      rejectTripExpenseDialogTitle: '',
      rejectingTripExpenseSendSMS: false,

      employerTimelogViewDialogOpen: false,
      // rejectingTripExpenseEmployerComments: {},

      mode: 'accepting',
      hasEdited: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  employerAccept(workOrderId, workHourId) {
    const { timelogStore, uiStore } = this.props;
    const user = uiStore.currentUser;
    timelogStore.employerAccept(user, workOrderId, workHourId);
  }

  findEarliestOrphan() {
    const { orphanObject } = this.props;

    const taxExemptTripExpenses = orphanObject.orphans.taxExemptTripExpenses || [];
    const tripRoutes = orphanObject.orphans.tripRoutes || [];

    let earliestOrphan = null;

    // Tax exempt trip expenses (allowances) have priority over which work hour gets the message, which is why they're first
    Object.keys(taxExemptTripExpenses).forEach((category) => {
      taxExemptTripExpenses[category].forEach((expense) => {
        if (!earliestOrphan || earliestOrphan.date > expense.work_hour_date) {
          earliestOrphan = expense;
        }
      });
    });

    tripRoutes.forEach((tripRoute) => {
      if (!earliestOrphan || earliestOrphan.date > tripRoute.work_hour_date) {
        earliestOrphan = tripRoute;
      }
    });

    return earliestOrphan || {};
  }

  rejectStartTrip(tripId, sendSMS, employerComment) {
    const { timelogStore, updateWorkOrderTrip } = this.props;
    // const { commentStart } = this.state;

    this.setState({
      startLoading: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.employerStartTripReject(tripId, employerComment, sendSMS, resolve, reject))
        .then((responseWorkOrderTrip) => {
          // Success
          this.setState({
            rejectingStart: false, sendingSMSStart: false,
          }, () => {
            const newWorkOrderTrip = WorkOrderTrip.updatePropertiesFromJson(responseWorkOrderTrip);
            updateWorkOrderTrip(newWorkOrderTrip);
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            startLoading: false,
          }, console.error(err));
        });
    });
  }

  rejectMissingTrip(employerComment) {
    const { timelogStore } = this.props;
    const { rejectingSendSMS } = this.state;

    const earliestOrphan = this.findEarliestOrphan();

    new Promise((resolve, reject) => timelogStore.missingWorkOrderTripReject(earliestOrphan.work_hour_id, employerComment, rejectingSendSMS, resolve, reject))
      .then((res) => {
        // Success
        earliestOrphan.work_order_trip_employer_comment = res.work_order_trip_employer_comment;
        this.setState({
          showRejectTripDialog: false,
        });
      })
      .catch((err) => {
        // Failure
        console.error(err);
      });
  }

  rejectTaxExemptTripExpenses(expenseComments) {
    const { timelogStore, orphanObject } = this.props;
    const { rejectingTripExpenseSendSMS } = this.state;

    const updatedExpenses = [];
    const taxExemptTripExpenses = orphanObject.orphans.taxExemptTripExpenses || [];

    for (const expenseId in expenseComments) {
      updatedExpenses.push({ id: expenseId, employer_comment: expenseComments[expenseId] });
    }

    new Promise((resolve, reject) => timelogStore.employerRejectTripExpenses(updatedExpenses, rejectingTripExpenseSendSMS, resolve, reject))
      .then((res) => {
        // Success
        // At the moment allowance is the only tax exempt trip expense category
        taxExemptTripExpenses.allowance[0].employer_comment = res[0].employer_comment
        this.closeRejectTripExpenseDialog();
      })
      .catch((err) => {
        // Failure
        console.log('ERROR: ', err);
      });
  }

  rejectTripRoutes(tripRouteComments) {
    const { timelogStore, orphanObject } = this.props;
    const { rejectingTripRouteSendSMS } = this.state;

    const updatedTripRoutes = [];
    const tripRoutes = orphanObject.orphans.tripRoutes || [];

    for (const tripRouteId in tripRouteComments) {
      updatedTripRoutes.push({ id: tripRouteId, employer_comment: tripRouteComments[tripRouteId] });
    }

    new Promise((resolve, reject) => timelogStore.employerRejectTripRoutes(updatedTripRoutes, rejectingTripRouteSendSMS, resolve, reject))
      .then((res) => {
        // Success
        tripRoutes[0].employer_comment = res[0].employer_comment;
        this.closeRejectTripRoutesDialog();
      })
      .catch((err) => {
        // Failure
        console.log('ERROR: ', err);
      });
  }

  acceptExpenses() {
    const { timelogStore, orphanObject, updateOrphans } = this.props;

    new Promise((resolve, reject) => timelogStore.employerAcceptTripExpenses(orphanObject.taxExemptTripExpenseIds, orphanObject.tripRouteIds, resolve, reject))
      .then((response) => {
        // const newWorkOrderTrip = WorkOrderTrip.fromJsonProperties(responseWorkOrderTrip);
        // updateWorkOrderTrip(newWorkOrderTrip);
        updateOrphans(response);
      })
      .catch((err) => {
        // Failure
        console.log('ERROR: ', err);
      });
  }

  openWorkOrderTrip = () => {
    const { openTripDialog, orphanObject, employerContextStore } = this.props;

    const allowances = orphanObject.orphans.taxExemptTripExpenses?.allowance || [];
    const tripRoutes = orphanObject.orphans.tripRoutes || [];
    const orphansFlattened = [...allowances, ...tripRoutes];
    const minDate = orphansFlattened.reduce((minDate, obj) => moment(obj.work_hour_date).isBefore(minDate) ? obj.work_hour_date : minDate, moment());

    employerContextStore.setCurrentEmployeeId(orphanObject.userId);

    // const workOrderTrip = new WorkOrderTrip({});
    // workOrderTrip.startTrip.date = moment()
    openTripDialog(null, moment(minDate), orphanObject.workOrderIds[0]);
  }

  rejectStartOrEndTrip = (title, startOrEndTrip) => {
    this.setState({
      rejectingTrip: startOrEndTrip,
      rejectTripDialogTitle: title,
      showRejectTripDialog: true,
    });
  }

  editTripExpenses = () => {
    this.setState({
      employerTimelogViewDialogOpen: true,
      initialTabIndex: 2,
    });
  }

  editTripRoutes = () => {
    this.setState({
      employerTimelogViewDialogOpen: true,
      initialTabIndex: 1,
    });
  }

  openRejectTripExpensesDialog = (title, expenses) => {
    const rejectingTripExpenseEmployerComments = {};
    // Object.keys(expensesByWorkOrder).forEach((workOrderName) => {
    //   expensesByWorkOrder[workOrderName].forEach((expense) => {
    //     // The employer comment should only apply to the first expense per work order
    //     // const expenseComment = expenses[workOrderName][0].employer_comment;
    //     rejectingTripExpenseEmployerComments[expense.id] = expense.employer_comment;
    //   });
    //   // const expenseId = expenses[workOrderName][0].id;
    //   // const expenseComment = expenses[workOrderName][0].employer_comment;
    //   // rejectingTripExpenseEmployerComments[expenseId] = expenseComment;
    // });

    expenses.forEach((expense) => {
      rejectingTripExpenseEmployerComments[expense.id] = expense.employer_comment;
    })

    this.setState({
      rejectingTripExpenses: expenses,
      rejectTripExpenseDialogTitle: title,
      showRejectTripExpenseDialog: true,
      rejectingTripExpenseEmployerComments: rejectingTripExpenseEmployerComments,
    });
  }

  openRejectTripRoutesDialog = (title, tripRoutes) => {
    const rejectingTripRouteComments = {};
    // Object.keys(tripRoutes).forEach((workOrderName) => {
    //   const tripRouteId = tripRoutes[workOrderName][0].id;
    //   const tripRouteComment = tripRoutes[workOrderName][0].employerComment;
    //   rejectingTripRouteComments[tripRouteId] = tripRouteComment;
    // });
    tripRoutes.forEach((tripRoute) => {
      rejectingTripRouteComments[tripRoute.id] = tripRoute.employerComment;
    });

    this.setState({
      rejectingTripRoutes: tripRoutes,
      rejectTripRoutesDialogTitle: title,
      showRejectTripRoutesDialog: true,
      rejectingTripRouteEmployerComments: rejectingTripRouteComments,
    });
  }

  calculateDurationFromKm(km) {
    if (km) {
      // 60 km/h
      const kmsPerHour = 60;
      const durationInHours = km / kmsPerHour;
      const onlyHours = Math.floor(durationInHours);
      // We get the latter decimal portion of durationInHours, then multiply it by 60 and round down to get minutes
      const onlyMinutes = Math.floor((durationInHours % 1) * kmsPerHour);

      if (onlyHours > 0 && onlyMinutes > 0) {
        return (
          <span
            style={{ marginLeft: '20px', fontSize: '12px' }}
          >
            {`${onlyHours} h ${onlyMinutes} min (${kmsPerHour} km/h)`}
          </span>
        );
      } else if (onlyMinutes > 0) {
        return (
          <span
            style={{ marginLeft: '20px', fontSize: '12px' }}
          >
            {`${onlyMinutes} min (${kmsPerHour} km/h)`}
          </span>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  handleChange(event, mode) {
    this.setState({ rejectingEmployerComment: event.target.value });
  }

  handleExpenseCommentChange(expenseId, value) {
    this.setState((prevState) => ({
      rejectingTripExpenseEmployerComments: { ...prevState.rejectingTripExpenseEmployerComments, [expenseId]: value },
    }));
  }

  handleTripRouteCommentChange(tripRouteId, value) {
    this.setState((prevState) => ({
      rejectingTripRouteEmployerComments: { ...prevState.rejectingTripRouteEmployerComments, [tripRouteId]: value },
    }));
  }

  renderRejectTripDialog() {
    const {
      showRejectTripDialog,
      rejectTripDialogTitle,
      rejectingSendSMS,
      rejectingEmployerComment,
    } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog"
        open={showRejectTripDialog}
        onClose={() => {
          this.setState({
            showRejectTripDialog: false,
            rejectingEmployerComment: '',
            rejectingSendSMS: false,
          });
        }}
      >
        <DialogTitle>{rejectTripDialogTitle}</DialogTitle>
        <DialogContent
          style={{ color: 'white' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <TextField
              style={{
                width: '94%',
              }}
              label="Kirjoita korjauksen ohje tai lisätietoa"
              icon="info"
              value={rejectingEmployerComment}
              onChange={this.handleChange}
            />
          </div>
          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingSendSMS: !prevState.rejectingSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            // onClick={() => this.employerReject(rejectingWorkOrderId, rejectingWorkHourId, rejectingSendSMS)}
            onClick={() => {
              // if (rejectingTrip instanceof StartTrip) {
              //   this.rejectStartTrip(rejectingTrip.id, rejectingSendSMS);
              // } else if (rejectingTrip instanceof EndTrip) {
              //   this.rejectEndTrip(rejectingTrip.id, rejectingSendSMS);
              // }
              this.rejectMissingTrip(rejectingEmployerComment);
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  closeRejectTripExpenseDialog() {
    this.setState({
      showRejectTripExpenseDialog: false,
      rejectingTripExpenseEmployerComments: {},
      rejectingTripExpenseSendSMS: false,
    });
  }

  closeRejectTripRoutesDialog() {
    this.setState({
      showRejectTripRoutesDialog: false,
      rejectingTripRouteEmployerComments: {},
      rejectingTripRouteSendSMS: false,
    })
  }

  closeEmployerTimelogViewDialog = () => {
    this.setState({
      employerTimelogViewDialogOpen: false,
    });
  }

  renderRejectTripExpenseDialog() {
    const { t } = this.props;
    const {
      rejectingTripExpenses,
      showRejectTripExpenseDialog,
      rejectTripExpenseDialogTitle,
      rejectingTripExpenseSendSMS,
      rejectingTripExpenseEmployerComments,
    } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog tax-exempt-trip-expense-reject-dialog"
        open={showRejectTripExpenseDialog}
        onClose={() => {
          this.closeRejectTripExpenseDialog();
        }}
      >
        <DialogTitle>{rejectTripExpenseDialogTitle}</DialogTitle>
        <DialogContent
          style={{ color: 'white' }}
        >
          {
            <div style={{ margin: '20px' }}>
              {this.renderExpenses(rejectingTripExpenses, false)}
              {/* REJECTING FIELDS HERE */}
              <TextField
                style={{
                  width: '100%',
                }}
                label="Kirjoita korjauksen ohje tai lisätietoa"
                icon="info"
                // value={rejectingTripExpenseEmployerComments[firstExpenseIdOfWorkOrder] || ''}
                onChange={(event) => this.handleExpenseCommentChange(rejectingTripExpenses[0].id, event.target.value)}
              />
            </div>
          }
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
          </div>
          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingTripExpenseSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingTripExpenseSendSMS: !prevState.rejectingTripExpenseSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            // onClick={() => this.employerReject(rejectingWorkOrderId, rejectingWorkHourId, rejectingSendSMS)}
            onClick={() => {
              // console.log('Reject multiple work hours');
              this.rejectTaxExemptTripExpenses(rejectingTripExpenseEmployerComments);
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderRejectTripRoutesDialog() {
    const { t } = this.props;
    const {
      rejectingTripRoutes,
      showRejectTripRoutesDialog,
      rejectTripRoutesDialogTitle,
      rejectingTripRouteSendSMS,
      rejectingTripRouteEmployerComments,
    } = this.state;

    const firstTripRouteIdOfWorkOrder = rejectingTripRoutes?.[0]?.id;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog tax-exempt-trip-expense-reject-dialog"
        open={showRejectTripRoutesDialog}
        onClose={() => {
          this.closeRejectTripRoutesDialog();
        }}
      >
        <DialogTitle>{rejectTripRoutesDialogTitle}</DialogTitle>
        <DialogContent
          style={{ color: 'white', lineHeight: 'normal'  }}
        >
          <div style={{ margin: '20px' }}>
            {/* <div className="work-order-name">{workOrderName}</div> */}
            {this.renderTripRoutesInner(rejectingTripRoutes, false)}
            <TextField
              style={{
                width: '100%',
              }}
              label="Kirjoita korjauksen ohje tai lisätietoa"
              icon="info"
              // value={rejectingTripRouteEmployerComments[firstTripRouteIdOfWorkOrder] || ''}
              onChange={(event) => this.handleTripRouteCommentChange(firstTripRouteIdOfWorkOrder, event.target.value)}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
          </div>

          <div className="employer-send-sms-checkbox">
            <div
              style={{
                width: '94%',
                paddingLeft: '9px',
              }}
            >
              <Checkbox
                label="Muistuta SMS-viestillä"
                checked={rejectingTripRouteSendSMS || false}
                onChange={() => {
                  this.setState((prevState) => ({
                    rejectingTripRouteSendSMS: !prevState.rejectingTripRouteSendSMS,
                  }));
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            // onClick={() => this.employerReject(rejectingWorkOrderId, rejectingWorkHourId, rejectingSendSMS)}
            onClick={() => {
              this.rejectTripRoutes(rejectingTripRouteEmployerComments);
            }}
          >
            Lähetä
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderComparedValues(label, currentValue, oldTrip, oldValue) {
    currentValue = currentValue || '?'
    oldValue = oldValue || '?'
    // The previous trip version does not exist, no need to show old value as ?
    if (!oldTrip) {
      oldValue = null;
    }
    if (currentValue === oldValue) {
      return <div>{label}: {currentValue}</div>
    } else {
      return <div>{label}: {currentValue} <span className="old-value">{oldValue}</span></div>
    }
  }

  renderRouteLocations(routeLocations, oldRouteLocations) {
    const routeLocationWrappers = [];

    // Mobx warns about how observableArray.sort() does not update the array in place, so we use slice() to create a copy to suppress said warning
    const sortedRouteLocations = routeLocations.slice().sort((a, b) => (a.locationOrder?.valueOf() - b.locationOrder?.valueOf()));
    const sortedOldRouteLocations = oldRouteLocations?.slice().sort((a, b) => (a.locationOrder?.valueOf() - b.locationOrder?.valueOf()));

    const currentRouteLocationElements = [];
    sortedRouteLocations?.forEach((routeLocation, index) => {
      currentRouteLocationElements.push(
        <div
          key={routeLocation.id + "_route_location"}
          style={{ paddingTop: index == 0 ? '0' : '5px'}}
        >
          <div>
            <span>{routeLocation.street}</span>
            {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
            {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
          </div>
        </div>
      );
    });

    routeLocationWrappers.push(
      <div key={"loc_current_wrapper"}>
        {currentRouteLocationElements}
      </div>
    );

    // If route has changed, print the old route too
    if (oldRouteLocations && !isEqual(sortedRouteLocations, sortedOldRouteLocations)) {
      const oldRouteLocationElements = [];
      sortedOldRouteLocations?.forEach((routeLocation, index) => {
        oldRouteLocationElements.push(
          <div
            key={routeLocation.id + "_route_location"}
            style={{ paddingTop: index == 0 ? '0' : '10px'}} className="old-value"
          >
            <span>{routeLocation.street}</span>
            {routeLocation.zipCode && <span style={{ marginLeft: '5px' }}>{routeLocation.zipCode}</span>}
            {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
          </div>
        );
      });

      routeLocationWrappers.push(
        <div
          key={"loc_old_wrapper"}
          style={{ marginTop: '10px' }}
        >
          {oldRouteLocationElements}
        </div>
      );
    }

    return routeLocationWrappers;
  }

  formatDate(date) {
    const formattedDate = moment(date).format('DD.MM.YY')
    if (formattedDate.toUpperCase() === 'INVALID DATE') {
      return '?';
    } else {
      return formattedDate;
    }
  }

  formatDateWithDay(date, zeroes = true) {
    const format = zeroes ? 'dd DD.MM.' : 'dd D.M.';
    const formattedDate = moment(date).format(format)
    if (formattedDate.toUpperCase() === 'INVALID DATE') {
      return '?';
    } else {
      return formattedDate;
    }
  }

  formatTime(time) {
    if (time) {
      return moment(time).format('HH:mm');
    } else {
      return '?'
    }
  }

  openTimelogAcceptanceDialog(timelogId) {
    const { timelogStore } = this.props;

    this.setState({
      showTimelogDialog: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.getResponsibleEmployerTimelogsByIdPromise([timelogId], resolve, reject))
        .then((response) => {
          // Success
          this.setState({
            // The response is an array of timelogs with meta but with only one element, which we want
            openedTimelogWithMeta: response[0],
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            openedTimelogWithMeta: null,
          }, console.log('ERROR: ', err));
        });
    });
  }

  updateWorkHour = (id, status, employerComment) => {
    const { workOrderTrip } = this.props;
    const { showTimelogDialog } = this.state;
    const updatedWorkHours = [...workOrderTrip.work_hours];

    const foundWorkHourIndex = updatedWorkHours.findIndex((workHour) => workHour.id === id);
    if (foundWorkHourIndex !== -1) {
      updatedWorkHours[foundWorkHourIndex].status = status;
      updatedWorkHours[foundWorkHourIndex].employer_comment = employerComment;
    }
    workOrderTrip.work_hours = updatedWorkHours;

    if (showTimelogDialog) {
      this.setState((prevState) => ({
        openedTimelogWithMeta: { ...prevState.openedTimelogWithMeta, work_hour: { ...prevState.openedTimelogWithMeta.work_hour, status: status, employer_comment: employerComment } }
      }));
    }
  }

  taxExemptTripExpenseRejectTitle(workOrderTrip, expenseName) {
    const { t } = this.props;

    return `${workOrderTrip.userName} matka ${workOrderTrip.startTrip.date.format('D.M.')}-${workOrderTrip.endTrip.toDate?.format('D.M.') || '?'} ${t(`timelog.attributes.${expenseName}_plural`)}`;
  }

  renderTaxExemptTripExpenses(taxExemptTripExpenseCategories, allAllowances) {
    const { t, dialogMode, workOrderTrip } = this.props;

    return (
      <div className="tax-exempt-trip-expense-category-collapsible">
        {Object.keys(taxExemptTripExpenseCategories).map((category) => {
          let totalAmount = allAllowances.length;
          if (category === 'allowance') {
            const combinationValues = ['full_plus_10', 'full_half', 'two_meals', 'se_double'];
            totalAmount = 0;
            allAllowances.forEach((allowance) => {
              if (combinationValues.includes(allowance.value)) {
                totalAmount += 2;
              } else {
                totalAmount += 1;
              }
            });
          }

          return (
            <CollapsibleList
              key={`${category}-collapsible`}
              handle={
                <SimpleListItem
                  className="allowance-list-item"
                  text={
                    <div className="category-collapsible-handle">
                      {t(`timelog.attributes.${category}_plural`) + `: ${totalAmount} kpl`}
                    </div>
                  }
                  metaIcon="chevron_right"
                />
              }
              startOpen
            >
              {/* Title param: this.taxExemptTripExpenseRejectTitle(workOrderTrip, category) */}
              {this.renderRejectAndEditButtons(taxExemptTripExpenseCategories[category], 'Pyydä korjaus päivärahoihin', this.editTripExpenses, this.openRejectTripExpensesDialog)}
              {this.renderExpenses(taxExemptTripExpenseCategories[category])}
            </CollapsibleList>
          );
        })}
      </div>
    );
  }

  renderTripRoutesInner(tripRoutes = [], oldTripRoutes) {
    return (
      <>
        {tripRoutes[0] && tripRoutes[0].status !== 'accepted' && tripRoutes[0].employer_comment && (
          <div className="tax-exempt-trip-expense-employer-comment">
            <span className="rejected-text">
              "{tripRoutes[0].employer_comment}"
            </span>
          </div>
        )}

        {tripRoutes.map((tripRoute, index) => (
            <div key={`${tripRoute.id}_trip_route`} style={{ padding: '10px', margin: '10px 0px 10px 10px' }} className={tripRoute.status}>
              <div
                style={{ textTransform: 'capitalize' }}
              >
                {`${moment(tripRoute.work_hour_date).format('dd D.M.')} reitti ${index + 1}:`}
              </div>
              <div style={{ marginLeft: '10px', marginTop: '5px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Selite:</span>
                  <span style={{ textAlign: 'end', hyphens: 'auto', marginBottom: '5px' }}>{tripRoute.description}</span>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Etäisyys (km):</span>
                  <span>{tripRoute.kms}</span>
                </div>
              </div>
              {/* <div style={{ padding: '10px 0 10px 25px' }}> */}
              {tripRoute.route_locations?.length > 0 && (
                <div style={{ marginLeft: '20px', marginTop: '10px' }}>
                  {this.renderRouteLocations(tripRoute.route_locations, oldTripRoutes?.[index]?.route_locations)}
                </div>
              )}
            </div>
          )
        )}
      </>
    );
  }

  renderTripRoutes(tripRoutes, oldTripRoutes) {
    let totalKms = 0;

    tripRoutes.forEach((tripRoute) => {
      totalKms += Number.parseFloat(tripRoute.kms || 0);
    });

    // Object.keys(tripRoutes).forEach((salaryPeriod) => {
    //   Object.keys(tripRoutes[salaryPeriod]).forEach((workOrderName) => {
    //     tripRoutes[salaryPeriod][workOrderName].forEach((tripRoute) => {
    //       totalKms += Number.parseFloat(tripRoute.kms || 0);
    //     });
    //   });
    // });

    // return (
    //   <div className="tax-exempt-trip-expense-category-collapsible">
    //     <CollapsibleList
    //       handle={
    //         <SimpleListItem
    //           className="allowance-list-item"
    //           text={
    //             <div className="category-collapsible-handle">
    //               {/* {`Kilometrikorvaukset: ${tripRoutes.flat().reduce((acc, tripRoute) => acc + (Number.parseFloat(tripRoute.kms) || 0), 0)} km`} */}
    //               {`Kilometrikorvaukset: ${totalKms} km`}
    //             </div>
    //           }
    //           metaIcon="chevron_right"
    //         />
    //       }
    //       open
    //     >
    //       <div className="trip-expense-category-collapsible-content">
    //         {this.renderRejectAndEditButtons(tripRoutes, 'Pyydä korjaus kilometrikorvauksiin', this.editTripRoutes, this.openRejectTripRoutesDialog)}
    //         <div style={{ margin: '10px' }}>
    //           <div style={{ fontSize: '14px' }}>
    //             {Object.keys(tripRoutes).map((workOrderName) => {
    //               const firstTripRoute = tripRoutes[workOrderName][0];

    //               return (
    //                 <div key={`${workOrderName}-trip-routes`}>
    //                   <div>{workOrderName}</div>
    //                   {tripRoutes[workOrderName].find((route) => route.status !== 'accepted') && firstTripRoute.employerComment && (
    //                     <div
    //                       style={{ marginLeft: '10px', fontSize: '12px' }}
    //                       className="rejected"
    //                     >
    //                       "{firstTripRoute.employerComment}"
    //                     </div>
    //                   )}
    //                   {this.renderTripRoutesInner(tripRoutes[workOrderName])}
    //                 </div>
    //               );
    //             })}
    //           </div>
    //         </div>
    //       </div>
    //     </CollapsibleList>
    //   </div>
    // );

    return (
      <div className="tax-exempt-trip-expense-category-collapsible">
          <CollapsibleList
            handle={
              <SimpleListItem
                className="allowance-list-item"
                text={
                  <div className="category-collapsible-handle">
                    {`Kilometrikorvaukset: ${totalKms} km`}
                  </div>
                }
                metaIcon="chevron_right"
              />
            }
            startOpen
          >
            <div>
              {this.renderRejectAndEditButtons(tripRoutes, 'Pyydä korjaus kilometrikorvauksiin', this.editTripRoutes, this.openRejectTripRoutesDialog)}
              <div style={{ fontSize: '14px' }}>
                {this.renderTripRoutesInner(tripRoutes)}
              </div>
            </div>
            {/* {Object.keys(tripRoutes).map((salaryPeriod) => {
              // Current salary period, render the content as-is
              if (salaryPeriod === 'current') {
                return (
                  <div>
                    {this.renderRejectAndEditButtons(tripRoutes[salaryPeriod], 'Pyydä korjaus kilometrikorvauksiin', this.editTripRoutes, this.openRejectTripRoutesDialog)}
                    <div style={{ margin: '10px', fontSize: '14px' }}>
                      {Object.keys(tripRoutes[salaryPeriod]).map((workOrderName) => {
                        const firstTripRoute = tripRoutes[salaryPeriod][workOrderName][0];

                        return (
                          <div key={`${workOrderName}-trip-routes`}>
                            <div>{workOrderName}</div>
                            {tripRoutes[salaryPeriod][workOrderName].find((route) => route.status !== 'accepted') && firstTripRoute.employerComment && (
                              <div
                                style={{ marginLeft: '10px', fontSize: '12px' }}
                                className="rejected-text"
                              >
                                "{firstTripRoute.employerComment}"
                              </div>
                            )}
                            {this.renderTripRoutesInner(tripRoutes[salaryPeriod][workOrderName])}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              } else {
                // Non-current salary period, wrap content in a collapsible
                return (
                  <div className="salary-period-collapsible">
                    <CollapsibleList
                      ripple={false}
                      // NOTE: Due to RMWC bug we can't assign className to CollapsibleList, we have to use a wrapper div
                      handle={
                        <SimpleListItem
                          className="allowance-list-item"
                          text={
                            <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>{salaryPeriod}</div>
                          }
                          metaIcon="chevron_right"
                        />
                      }
                    >
                      <div style={{ margin: '10px' }}>
                        <div style={{ fontSize: '14px' }}>
                          {Object.keys(tripRoutes[salaryPeriod]).map((workOrderName) => {
                            // const firstTripRoute = tripRoutes[salaryPeriod][workOrderName][0];
                            const firstTripRoute = tripRoutes[0];

                            return (
                              <div key={`${workOrderName}-trip-routes`}>
                                <div>{workOrderName}</div>
                                {tripRoutes.find((route) => route.status !== 'accepted') && firstTripRoute.employerComment && (
                                  <div
                                    style={{ marginLeft: '10px', fontSize: '12px' }}
                                    className="rejected-text"
                                  >
                                    "{firstTripRoute.employerComment}"
                                  </div>
                                )}
                                {this.renderTripRoutesInner(tripRoutes)}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </CollapsibleList>
                  </div>
                );
              }
            })} */}
          </CollapsibleList>
      </div>
    );
  }

  renderRejectAndEditButtons(data, dialogTitle, edit, reject) {
    const { mode } = this.state;

    if (mode !== 'editing') {
      return null;
    }

    return (
      <div style={{ width: '100%' }}>
        <Button
          icon="keyboard_arrow_left"
          className="trip-action-button trip-action-button-left"
          onClick={() => {
            if (edit) {
              edit();
              this.setState({ hasEdited: true });
            }
          }}
        >
          Korjaa itse
        </Button>
        <Button
          trailingIcon="keyboard_arrow_right"
          className="trip-action-button trip-action-button-right"
          onClick={() => {
            if (reject) {
              reject(dialogTitle, data);
              this.setState({ hasEdited: true });
            }
          }}
        >
          Pyydä korjaus
        </Button>
      </div>
    );
  }

  renderTimelogAcceptanceDialog() {
    const { showTimelogDialog, openedTimelogWithMeta } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal timelog-card-dialog"
        open={showTimelogDialog}
        onClose={(evt) => {
          this.setState({
            showTimelogDialog: false,
            openedTimelogWithMeta: null,
            rejectingTimelog: false,
          });
        }}
      >
        <DialogContent
          style={{ color: 'white', lineHeight: 'normal' }}
        >
          {openedTimelogWithMeta ? (
              <EmployerTimelogCard
                workHourWithMeta={openedTimelogWithMeta}
                updateWorkHour={this.updateWorkHour}
                dialogMode={true}
              />
            ) : (
              <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            )
          }
          {/* <p>Timelog card here: {openedTimelogWithMeta?.value?.id || 'no timelog'}</p> */}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            style={{
              color: '#645F5F',
              border: '1px solid #c7c9cc',
              borderRadius: '0px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>

          {/* {openedTimelogWithMeta && this.renderTimelogAcceptanceDialogActions()} */}
        </DialogActions>
      </Dialog>
    );
  }

  // renderTimelogAcceptanceDialogActions() {
  //   // const { workHourWithMeta } = this.props;
  //   const { openedTimelogWithMeta, rejectingTimelog } = this.state;
  //   const { sendSMS } = this.state;

  //   return (
  //     <div>
  //       {openedTimelogWithMeta.work_hour.status !== 'draft' && (
  //         <>
  //           <div className="employer-send-sms-checkbox">
  //             <div
  //               style={{
  //                 width: '94%',
  //                 paddingLeft: '9px',
  //               }}
  //             >
  //               {rejectingTimelog ? (
  //                 <Checkbox
  //                   label="Muistuta SMS-viestillä"
  //                   checked={sendSMS}
  //                   onChange={() => {
  //                     this.setState((prevState) => ({
  //                       sendSMS: !prevState.sendSMS,
  //                     }));
  //                   }}
  //                 />
  //               ) : null}
  //             </div>
  //           </div>
  //           <CardActions>
  //             <CardActionButtons className="work-hour-card-buttons">
  //               {/* Hide the accept button if the work hour has already been accepted or we are in the reject mode */}
  //               {rejectingTimelog || openedTimelogWithMeta.work_hour.status === 'accepted' ? (<div style={{ width: '87px' }}></div>) : (
  //                 <CardActionButton
  //                   className="employer-accept-button"
  //                   // style={{ width: '87px' }}
  //                   onClick={() => this.employerAccept(openedTimelogWithMeta.work_hour.work_order_id, openedTimelogWithMeta.work_hour.id)}
  //                 >
  //                   Hyväksy
  //                 </CardActionButton>
  //               )}

  //               {rejectingTimelog ? (
  //                 <CardActionButton
  //                   className="employer-reject-button"
  //                   // style={{ width: '87px' }}
  //                   // onClick={() => this.employerReject(workHourWithMeta.work_hour.work_order_id, workHourWithMeta.work_hour.id)}
  //                   onClick={() => this.employerReject(openedTimelogWithMeta.work_hour.work_order_id, openedTimelogWithMeta.work_hour.id, sendSMS)}
  //                 >
  //                   Lähetä
  //                 </CardActionButton>
  //               ) : null}

  //               {rejectingTimelog ? (
  //                 <CardActionButton
  //                   className="employer-cancel-button"
  //                   style={{ width: '117px' }}
  //                   onClick={() => this.cancelReject()}
  //                 >
  //                   Peruuta
  //                 </CardActionButton>
  //               ) : (
  //                 <CardActionButton
  //                   className="employer-reject-button"
  //                   style={{ width: '117px' }}
  //                   onClick={() => { this.setState({ rejectingTimelog: true }) }}
  //                 >
  //                   Korjattava
  //                 </CardActionButton>
  //               )}
  //             </CardActionButtons>
  //           </CardActions>
  //         </>
  //       )}
  //     </div>
  //   );
  // }

  renderExpenses(expenses, clickableExpenses = true) {
    const { t, dialogMode } = this.props;
    const firstExpense = expenses?.[0];

    return (
      <div style={{ margin: '10px 0' }}>
        {firstExpense && firstExpense.status !== 'accepted' && firstExpense.employer_comment && (
          <div className="tax-exempt-trip-expense-employer-comment">
            <span className="rejected-text">
              "{firstExpense.employer_comment}"
            </span>
          </div>
        )}
        {expenses && expenses.map((expense) => (
          <div key={`expense-${expense.id}`} className="tax-exempt-trip-expense" onClick={() => !dialogMode && clickableExpenses && this.openTimelogAcceptanceDialog(expense.work_hour_id)}>
            <div className={`tax-exempt-trip-expense-inner ${expense.status}`}>
              <div>
                {/* {moment(expense.date, 'YYYY-MM-DD').format('dd D.M.')} */}
                {expense.work_hour_date.format('dd D.M.')}
              </div>
              <div>
                {expense.name === 'allowance' ? t(`timelog.attributes.${expense.value}_short`) : expense.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const {
      orphanObject,
      dialogMode,
      openEmployeeInfoDialog,
      afterUpdate,
    } = this.props;
    const { employerTimelogViewDialogOpen, mode, initialTabIndex, hasEdited } = this.state;

    // let allAllowances = [];
    // Object.keys(workOrderTrip.taxExemptTripExpenses?.allowance || {}).forEach((salaryPeriod) => {
    //   Object.keys(workOrderTrip.taxExemptTripExpenses.allowance[salaryPeriod]).forEach((workOrderName) => {
    //     const allowancesByPeriodAndWorkOrder = workOrderTrip.taxExemptTripExpenses.allowance[salaryPeriod][workOrderName];
    //     allAllowances = allAllowances.concat(allowancesByPeriodAndWorkOrder);
    //   });
    // });
    // console.log('Obj: ', orphanObject);

    const allAllowances = orphanObject.orphans.taxExemptTripExpenses?.allowance || [];
    const taxExemptTripExpenses = orphanObject.orphans.taxExemptTripExpenses || [];
    const tripRoutes = orphanObject.orphans.tripRoutes || [];

    // TODO: Move this to a better place instead of render
    const earliestOrphan = this.findEarliestOrphan();

    return (
      <>
        {this.renderTimelogAcceptanceDialog()}
        {this.renderRejectTripDialog()}
        {this.renderRejectTripExpenseDialog()}
        {this.renderRejectTripRoutesDialog()}
        <EmployerTimelogViewDialog
          employeeId={orphanObject.userId}
          // workOrderTripId={workOrderTrip.id}
          open={employerTimelogViewDialogOpen}
          onClose={this.closeEmployerTimelogViewDialog}
          initialTabIndex={initialTabIndex}
          workOrderIds={orphanObject.workOrderIds}
          afterUpdate={afterUpdate}
        />

        <Card
          className="employer-work-hour-card no-bg"
          key={`${orphanObject.userName}_${orphanObject.workOrderName}`}
          style={{ maxWidth: dialogMode ? '100%' : '', width: dialogMode ? '100%' : ''}}
        >
          <div
            className="employer-work-hour-card-title"
          >
            <span
              role="button"
              onClick={() => openEmployeeInfoDialog(earliestOrphan.user)}
              style={{ cursor: 'pointer' }}
            >
              {orphanObject.userName}
            </span>

            {/* <span>{this.formatDate(workOrderTrip.startTrip.date)}-{this.formatDate(workOrderTrip.endTrip.date)}</span> */}
            <span>{orphanObject.workOrderName}</span>
          </div>

          {this.renderRejectAndEditButtons({}, 'Korjaa', this.openWorkOrderTrip, this.rejectStartOrEndTrip)}
          {earliestOrphan.work_order_trip_employer_comment && (
            <div className="tax-exempt-trip-expense-employer-comment" style={{ marginBottom: 0 }}>
              <span className="rejected-text">
                "{earliestOrphan.work_order_trip_employer_comment}"
              </span>
            </div>
          )}
          <div style={{ padding: '10px', fontSize: '14px' }}>Matkan aloitusta ei ole täytetty</div>

          {/* This section is horrible spaghetti that should be refactored and cleaned up */}
          {/* {this.renderTrip(workOrderTrip.startTrip, workOrderTrip.old_start_trip, 'startTrip', 'start')} */}
          {Object.keys(taxExemptTripExpenses)?.length > 0 && this.renderTaxExemptTripExpenses(taxExemptTripExpenses, allAllowances)}
          {tripRoutes.length > 0 && this.renderTripRoutes(tripRoutes)}
          <div className="tax-exempt-trip-expense-category-collapsible">
            <CollapsibleList
              handle={
                <SimpleListItem
                  className="allowance-list-item"
                  text={
                    <div className="category-collapsible-handle" style={{ display: 'unset' }}>
                      {/* {`Matkan päättyminen: ${workOrderTrip.endTrip.toDate ? this.formatDateWithDay(workOrderTrip.endTrip.toDate) : ''}`} */}
                      <span>Matkan päättyminen</span>
                      {/* <span style={{ textTransform: 'capitalize' }}>
                        {workOrderTrip.endTrip.toDate ? `: ${this.formatDateWithDay(workOrderTrip.endTrip.toDate)}` : ''}
                      </span> */}
                    </div>
                  }
                  metaIcon="chevron_right"
                />
              }
              startOpen
            >
              {this.renderRejectAndEditButtons({}, 'Korjaa', this.openWorkOrderTrip, this.rejectStartOrEndTrip)}
              {/* {this.renderTrip(workOrderTrip.endTrip, workOrderTrip.old_end_trip, 'endTrip', 'end')} */}
              <div style={{ padding: '10px', fontSize: '14px' }}>
                Matkan päättymistä ei ole täytetty
              </div>
            </CollapsibleList>
          </div>

          {/* <div
            className="employer-work-hour-card-title"
            style={{
              backgroundColor: '#1d2333',
              // marginTop: '10px',
            }}
          >
            {this.renderTripSummary(workOrderTrip, allAllowances)}
          </div> */}

          {mode === 'editing' ? (
            <div style={{ padding: '10px', textAlign: 'end' }}>
              <Button
                className="employer-reject-button"
                onClick={() => this.setState({ mode: 'accepting', hasEdited: false })}
              >
                {hasEdited ? 'Valmis' : 'Peruuta'}
              </Button>
            </div>
          ) : (
            <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <Button
                className="employer-accept-button"
                onClick={() => this.acceptExpenses()}
              >
                Hyväksy
              </Button>

              {!dialogMode && (
                <Button
                  className="employer-reject-button"
                  onClick={() => this.setState({ mode: 'editing' })}
                >
                  Korjaa
                </Button>
              )}
            </div>
          )}
        </Card>
      </>
    );
  }
}

export default WorkOrderTripOrphanCard;
