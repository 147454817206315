import { action, observable } from 'mobx';
// For JSX-using render methods
import React from 'react';
// import User from '../User';
import moment from '../utils/moment';

export default class Validity {
  @observable id;

  @observable createdAt;

  @observable updatedAt;

  @observable name;

  @observable validThroughDate;

  @observable validThroughDateFormat;

  @observable admittanceDate;

  @observable admittanceDateFormat;

  @observable number;

  @observable description;

  @observable location;

  @observable workOrder;

  @observable company;

  @observable user;

  @observable file;

  // These need to be compatible with moment().format()
  static validDateFormats = [
    'D/M/YYYY',
    'DD/MM/YYYY',
    'MM/YYYY',
    'MM/YY',
    'DD.MM.YYYY',
    'D.M.YYYY',
  ];

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  static toJson(o) {
    const formData = new FormData();
    formData.append('name', o.name);
    formData.append('id', o.id);
    formData.append('valid_through_date', o.validThroughDate ? moment(o.validThroughDate).format('YYYY-MM-DD H:mm:ss') : undefined);
    formData.append('valid_through_date_format', o.validThroughDateFormat);
    formData.append('admittance_date', o.admittanceDate ? moment(o.admittanceDate).format('YYYY-MM-DD H:mm:ss') : undefined);
    formData.append('admittance_date_format', o.admittanceDateFormat);
    formData.append('location_id', o.location?.id);
    formData.append('work_order_id', o.workOrder?.id);
    formData.append('company_id', o.company?.id);
    // null is saved as a string so we need to set it as undefined instead
    formData.append('number', o.number || undefined);
    // null is saved as a string so we need to set it as undefined instead
    formData.append('description', o.description || undefined);
    formData.append('file', o.file);

    return formData;

    // return {
    //   name: o.name,
    //   id: o.id,
    //   valid_through_date: o.validThroughDate,
    //   admittance_date: o.admittanceDate,
    //   location: o.location,
    //   work_order: o.workOrder,
    //   company: o.company,
    //   number: o.number,
    //   description: o.description,
    //   file: o.file,
    //   // file_test: o.fileTest,
    // };
  }

  // Use this to validate and convert date from user input into a proper datetime that can be saved in db
  static convertDate(dateString) {
    let isValid = false;
    let formatIndex = -1;
    let dateObject = null;

    Validity.validDateFormats.forEach((dateFormat, index) => {
      // We try to (strictly) format the dateString into a Moment object trying the validDateFormats one by one
      const momentDate = moment(dateString, dateFormat, true).utcOffset(0, true);
      if (momentDate.isValid()) {
        // If the input date doesn't have a day, we set it as the end of the month
        if (!dateFormat.toLowerCase().includes('d')) {
          momentDate.set('date', momentDate.endOf('month').get('date'));
        }
        // The time should always be set to the last moment of the day
        momentDate.set('hour', '23');
        momentDate.set('minute', '59');
        momentDate.set('second', '59');

        isValid = true;
        formatIndex = index;
        dateObject = momentDate;
      }
    });
    return {
      isValid,
      formatIndex,
      dateObject,
    };
  }

  static calculateTimeTillDate(validThroughDate) {
    const convertedValidThroughDate = Validity.convertDate(validThroughDate);
    if (convertedValidThroughDate.isValid) {
      const today = moment().local();
      // How many years from current date to the validThroughDate?
      const timeYears = Math.floor(convertedValidThroughDate.dateObject.diff(today, 'years', true));
      // const timeYearsRounded = Math.floor(timeYears);
      let timeMonths = -1;
      let timeDays = -1;

      // If the years are negative, months must be negative as well, no need to calcualte
      if (timeYears >= 0) {
        const todayWithAddedYears = today.add(timeYears, 'years');
        // Compare converted validThroughDate with today, for months
        timeMonths = Math.floor(convertedValidThroughDate.dateObject.diff(todayWithAddedYears, 'months', true));
        // }
        // If the months are negative, days must be negative as well, no need to calculate them
        if (timeMonths >= 0) {
          // We add the already-calculated months and years to the current date so that the day calculation gives the wanted result
          const todayWithAddedYearsAndMonths = today.add(timeYears, 'years').add(timeMonths, 'months');
          // Compare converted validThroughDate with today, for days
          timeDays = Math.floor(convertedValidThroughDate.dateObject.diff(todayWithAddedYearsAndMonths, 'days', true));
        }
      }

      return {
        years: timeYears,
        months: timeMonths,
        days: timeDays,
      };
    }
    return null;
  }

  static convertDateToOriginalInput(dateString, formatIndex) {
    const originalInputFormat = Validity.validDateFormats[formatIndex];
    return moment(dateString).format(originalInputFormat);
  }

  static renderValidityDate(validThrough, mode) {
    // Second date tells us if the card is still valid or not
    if (!validThrough) {
      return null;
    }

    const currentDateMoment = moment().local();
    const validThroughMoment = Validity.convertDate(validThrough).dateObject; // moment(secondDate, 'MM/YYYY');
    // Add 1 to the months because for some reason they're zero indexed (e.g. January is 0 and December is 11)
    // const currentMonth = currentDate.month() + 1;
    // const secondDateMonth = secondDate.month() + 1;

    // Object not returned (invalid date given to conversion), which means the date cannot be calculated
    if (!validThroughMoment) {
      return null;
    }

    let color;
    if (currentDateMoment > validThroughMoment.endOf('month')) {
      // Expired (last day of the month)
      color = 'red';
    } else if (currentDateMoment.add(1, 'M') > validThroughMoment.endOf('month')) {
      // Expiring within a month
      color = mode === 'employer' ? 'black' : 'var(--mdc-theme-primary)';
    } else {
      // Color from theme: text hint on dark (used in WorkOrderItem)
      color = mode === 'employer' ? 'black' : 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5))';
    }

    // if (firstDate) {
    //   return (
    //     <span style={{ color }}>
    //       {firstDate}
    //       -
    //       {secondDate}
    //     </span>
    //   );
    // }
    // No first date
    return (
      <span style={{ color }}>{validThrough}</span>
    );
  }

  static renderValidityDuration(validThroughDate, mode) {
    const durationObject = Validity.calculateTimeTillDate(validThroughDate);
    if (!durationObject) {
      // Something went wrong with the calculation
      return null;
    }

    let durationString = '';

    if (durationObject.years <= 0 && durationObject.months <= 0 && durationObject.days <= 0) {
      // Duration is negative, so the validity has expired
      return <span style={{ color: 'red' }}>Vanhentunut</span>;
    }

    if (durationObject.years > 0) {
      durationString += `${durationObject.years} v `;
    }

    if (durationObject.months > 0) {
      // Duration is at least one month
      durationString += `${durationObject.months} kk `;
    }
    if (durationObject.days > 0 && durationObject.years === 0) {
      // Duration is at least one day (in addition to months)
      durationString += `${durationObject.days} pv`;
    }

    // Color from theme: text hint on dark (used in WorkOrderItem)
    let color = mode === 'employer' ? 'black' : 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5))';
    if (durationObject.years <= 0 && durationObject.months <= 0 && durationObject.days > 0) {
      // Expiring with a month
      color = mode === 'employer' ? 'black' : 'var(--mdc-theme-primary)';
    }
    return <span style={{ color }}>{durationString}</span>;
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      id: o.id,
      name: o.name,
      updatedAt: o.updated_at ? moment(o.updated_at).local() : null,
      createdAt: moment(o.created_at).local(),
      // validThroughDate: moment(o.valid_through_date).format('MM/YYYY'),
      validThroughDate: o.valid_through_date ? Validity.convertDateToOriginalInput(o.valid_through_date, o.valid_through_date_format) : null,
      // admittanceDate: moment(o.admittance_date).format('MM/YYYY'),
      admittanceDate: o.admittance_date ? Validity.convertDateToOriginalInput(o.admittance_date, o.admittance_date_format) : null,
      number: o.number,
      description: o.description,
      location: o.location,
      workOrder: o.work_order,
      company: o.company,
      user: o.user,
      fileURL: o.file_url,
      fileName: o.file_name,
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const validity = new Validity({});
    validity.updatePropertiesFromJson(o);
    return validity;
  }
}
