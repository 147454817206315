import { action, observable } from 'mobx';
import { uniqBy } from 'lodash';
import moment, { getInterValDays } from '../utils/moment';
import User from '../models/User';
import TimelogEntry from '../timelog/TimelogEntry';
import WorkOrderInvitation from '../employer-work-orders/WorkOrderInvitation';
import BillingAttachment from '../employer-billing/BillingAttachment';
// import WorkOrderTrip from '../timelog/WorkOrderTrip';
import WorkTask from '../employer-work-orders/work-tasks/WorkTask';
import SalaryPeriodAttachment from '../timelog/SalaryPeriodAttachment';
import WorkPeriod from '../models/WorkPeriod';
import CalendarEntry from '../models/CalendarEntry';

const padBillingIdWithZeroes = (billingId) => {
  // Turn a number like 12 to 0012
  if (billingId) {
    const zeroesCount = 4 - billingId.toString().length;
    return zeroesCount > 0 ? ('0'.repeat(zeroesCount) + billingId.toString()) : billingId;
  }
  return billingId;
};

const convertKeysToCamelCase = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Recursively update keys from 'test_key' to 'testKey'
  return Object.keys(obj).reduce((newObj, key) => {
    const val = obj[key];
    const camelCaseKey = key.replace(/(_\w)/g, (m) => m[1].toUpperCase());
    // eslint-disable-next-line no-param-reassign
    newObj[camelCaseKey] = convertKeysToCamelCase(val);
    return newObj;
  }, {});
};

const convertKeysToSnakeCase = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // Recursively update keys from 'testKey' to 'test_key'
  return Object.keys(obj).reduce((newObj, key) => {
    const val = obj[key];
    const camelCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
    // eslint-disable-next-line no-param-reassign
    newObj[camelCaseKey] = convertKeysToCamelCase(val);
    return newObj;
  }, {});
};

export default class WorkOrder {
  // @observable accomodationAddress;

  @observable accomodationName;

  @observable accomodationStreet;

  @observable accomodationZipCode;

  @observable accomodationCity;

  @observable company;

  @observable description;

  @observable id;

  @observable interval;

  @observable from;

  @observable to;

  @observable moreInfo;

  @observable name;

  @observable participants = [];

  @observable uniqueParticipants = [];

  @observable updatedAt;

  @observable workHours = [];

  @observable workHourType;

  // DELETE, replaced by workTasks
  @observable workNumbers = [];

  // DELETE, replaced by workTasks
  @observable workTargets = [];

  // @observable workOrderTrips = [];

  @observable invitationsCount;

  @observable workOrderInvitations = [];

  @observable billingAttachments = [];

  // @observable originalWorkOrderTrips = [];

  // @observable userWorkOrderTrips = [];

  // Nested_params for work_order_user_privileges
  @observable userPrivileges = [];

  @observable workTasks = [];

  @observable bill;

  @observable allowanceAutofillReminders = [];

  @observable chat;

  @observable workHourAccepterNames = [];

  @observable responsibleEmployerNames = [];

  @observable workPeriods;

  @observable workDays;

  @observable workDaysFromDates;

  @observable billingId;

  @observable workTaskEmployeeManagementEnabled;

  @observable firstWorkDay;

  @observable lastWorkDay;

  @observable settings = {};

  defaultSettings = {
    employeesCanViewAllTasks: false,
    employeesCanAddFilesToWorkTasks: true,
  };

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action removeWorkTask(removingWorkTask) {
    const workTaskIndex = this.workTasks.find((woTask) => woTask.id === removingWorkTask.id);
    if (workTaskIndex !== -1) {
      this.workTasks.splice(workTaskIndex, 1);
    }
  }

  hasEmployeeRelevantAttachments() {
    const workTaskWithFiles = this.workTasks.find((task) => task.files.length > 0);
    return this.attachments.length > 0 || workTaskWithFiles;
  }

  static toJson(o) {
    const snakecaseSettings = convertKeysToSnakeCase(o.settings);
    return {
      accomodation_name: o.accomodationName != null ? o.accomodationName : '',
      accomodation_street: o.accomodationStreet != null ? o.accomodationStreet : '',
      accomodation_zip_code: o.accomodationZipCode != null ? o.accomodationZipCode : '',
      accomodation_city: o.accomodationCity != null ? o.accomodationCity : '',
      accomodation_address_description: o.accomodationDescription,
      // attachments: o.attachments,
      description: o.description,
      id: o.id,
      to: o.to,
      from: o.from,
      is_not_busy: o.isNotBusy,
      location_id: o.location?.id,
      name: o.name,
      purchaser_id: o.purchaser?.id,
      work_hour_type: o.workHourType,
      employer_acceptance: o.employerAcceptance,
      invitations_count: o.invitationsCount,
      extid: o.extid,
      // work_order_invitations_attributes: o.invitations.map((invite) => WorkOrderInvitation.toJson(invite)),
      send_as_sms: o.sendAsSms,
      admin_info: o.adminInfo,
      work_order_user_privileges_attributes: o.userPrivileges,
      work_periods_attributes: o.workPeriods.map((period) => WorkPeriod.toJson(period)),
      // workNumbers: o.work_numbers,
      // workTargets: o.work_targets,
      billing_id: o.billingId,
      work_task_employee_management_enabled: o.workTaskEmployeeManagementEnabled,
      settings: snakecaseSettings,
    };
  }

  // UNIFY WITH fromJsonProperties()
  @action updatePropertiesFromJson(o) {
    const camelcaseSettings = convertKeysToCamelCase(o.settings);
    const workDays = o.work_days ? o.work_days.map(CalendarEntry.fromJsonProperties) : [];
    const workDaysFromDates = workDays.length > 0 ? workDays.map((day) => day.from) : [];
    const workPeriods = [];
    const workPeriodNames = new Set();
    if (o.work_days) {
      o.work_days.forEach((workDay) => {
        // Set doesn't work with objects so we just use array and a conditional push
        if (!workPeriods.find((period) => period.id === workDay.work_period_id)) {
          workPeriods.push({ id: workDay.work_period_id, name: workDay.work_period_name, description: workDay.work_period_description });
        }
        workPeriodNames.add(workDay.work_period_name);
      });
    }

    const values = {
      // accomodationAddress: o.accomodation_address,
      accomodationName: o.accomodation_name,
      accomodationStreet: o.accomodation_street,
      accomodationZipCode: o.accomodation_zip_code,
      accomodationCity: o.accomodation_city,
      accomodationDescription: o.accomodation_address_description,
      attachments: o.attachments,
      description: o.description,
      id: o.id,
      interval: moment.interval(o.from, o.to),
      from: moment(o.from),
      to: moment(o.to),
      isNotBusy: o.is_not_busy,
      location: o.location,
      name: o.name,
      purchaser: o.purchaser,
      userId: o.userId,
      updatedAt: moment(o.updates_at),
      work_order: o.work_order,
      workHourType: o.work_hour_type,
      workNumbers: o.work_numbers,
      workTargets: o.work_targets,
      employerAcceptance: o.employer_acceptance,
      uiStartTrip: o.ui_start_trip,
      uiEndTrip: o.ui_end_trip,
      invitationsCount: o.invitations_count,
      // workOrderTrips: o.work_order_trips,
      // originalWorkOrderTrips: o.original_work_order_trips,
      isHiddenFromUser: o.is_hidden_from_user,
      extid: o.extid,
      adminInfo: o.admin_info,
      // workOrderInvitations: o.work_order_invitations.map((invite) => WorkOrderInvitation.fromJsonProperties(invite)),
      userPrivileges: o.work_order_user_privileges,
      workTasks: o.work_tasks ? o.work_tasks.map(WorkTask.fromJsonProperties) : [],
      // Creating an empty bill by default that we can use it in the billing table and save it into the database easier
      bill: o.bill ? o.bill : {
        id: null,
        work_order_id: o.id,
        status: null,
      },
      timeStatus: o.time_status,
      allowanceAutofillReminders: o.allowance_autofill_reminders,
      // workPeriods: o.work_periods ? o.work_periods.map(WorkPeriod.fromJsonProperties) : [],
      workPeriods,
      workPeriodNames,
      workDays,
      workDaysFromDates,
      firstWorkDay: moment.min(workDaysFromDates),
      lastWorkDay: moment.max(workDaysFromDates),
      billingId: padBillingIdWithZeroes(o.billing_id),
      workTaskEmployeeManagementEnabled: o.work_task_employee_management_enabled,
      settings: camelcaseSettings || {},
    };

    if (o.participants != null) {
      values.participants = o.participants.map(User.fromJsonProperties);
    }

    let workHours = [];
    // const workHoursPerDayCombined = [];

    if (o.work_hours != null && o.work_hours.length !== 0) {
      workHours = o.work_hours.map((item) => TimelogEntry.fromJsonProperties(item));
    }

    // let workOrderTrips = [];
    // if (o.work_order_trips != null) {
    //   workOrderTrips = o.work_order_trips.map((item) => WorkOrderTrip.fromJsonProperties(item));
    // }
    // values.workOrderTrips = workOrderTrips;

    let billingAttachments = [];
    if (o.billingAttachments != null) {
      billingAttachments = o.billing_attachments.map((attachment) => BillingAttachment.fromJsonProperties(attachment));
    }
    values.billingAttachments = billingAttachments.sort((a, b) => (a.createdAt.valueOf() - b.createdAt.valueOf()));

    // TODO: CHECK SALARY PERIODS + TRIP DATES HERE, REMOVE IF NEEDED
    // ORIGINAL TRIP + USER TRIP + OUTWARD_VISIBLE_BOOL + RETURN_VISIBLE_BOOL (conditional components)

    // if (o.user_work_order_trips != null && o.user_work_order_trips.length !== 0) {
    //   workOrder.userWorkOrderTrips = o.user_work_order_trips.map(WorkOrderTrip.fromJsonProperties);
    // }

    const days = getInterValDays(values.interval);

    if (o.work_hour_type === 'daily') {
      days.forEach((date) => {
        const workorderentry = workHours.find((wh) => (wh.date != null ? wh.date.isSame(date) : false));
        if (workorderentry == null) {
          workHours.push(new TimelogEntry({
            date,
            userId: values.userId,
            workOrderId: values.id,
          }));
        }
      });
      values.workHours = workHours.sort((a, b) => (a.date.valueOf() - b.date.valueOf()));
    } else {
      // Assuming workHourType 'hourly', organizing the work hours per date (multiple work hours per day)
      // days.forEach((date) => {
      //   const singleDay = { date: null, hours: null, dailyData: null };
      //   // Non-daily data, e.g. multiple work hours with work tasks
      //   const workOrderEntry = workHours.filter((wh) => (wh.workTask != null && wh.date != null ? wh.date.isSame(date) : false));
      //   if (workOrderEntry) {
      //     singleDay.date = date;
      //     singleDay.hours = workOrderEntry;
      //   }
      //   // Daily data (should always be just one entry per day, contains stuff like drive_time)
      //   const dailyEntry = workHours.find((wh) => (wh.date.isSame(date) && !wh.workTask));
      //   if (dailyEntry) {
      //     singleDay.dailyData = dailyEntry;
      //   } else {
      //     singleDay.dailyData = new TimelogEntry({
      //       date,
      //       userId: values.userId,
      //       workOrderId: values.id,
      //     });
      //   }
      //   if (singleDay.date != null) {
      //     workHoursPerDayCombined.push(singleDay);
      //   }
      // });
      // values.workHours = workHoursPerDayCombined;

      days.forEach((date) => {
        const workorderentry = workHours.find((wh) => (wh.date != null ? wh.date.isSame(date) : false));
        if (workorderentry == null) {
          workHours.push(new TimelogEntry({
            date,
            userId: values.userId,
            workOrderId: values.id,
          }));
        }
      });
      values.workHours = workHours.sort((a, b) => (a.date.valueOf() - b.date.valueOf()));
    }
    return this.updateProperties(values);
  }

  // How is this different from updatePropertiesFromJson? Is this unnecessary?
  // Look at other models for examples
  static fromJsonProperties(o, mode) {
    const camelcaseSettings = convertKeysToCamelCase(o.settings);
    const workDays = o.work_days ? o.work_days.map(CalendarEntry.fromJsonProperties) : [];
    const workDaysFromDates = workDays.length > 0 ? workDays.map((day) => day.from) : [];
    const workPeriods = [];
    const workPeriodNames = new Set();
    if (o.work_days) {
      o.work_days.forEach((workDay) => {
        // Set doesn't work with objects so we just use array and a conditional push
        if (!workPeriods.find((period) => period.id === workDay.work_period_id)) {
          workPeriods.push({ id: workDay.work_period_id, name: workDay.work_period_name, description: workDay.work_period_description });
        }
        workPeriodNames.add(workDay.work_period_name);
      });
    }

    const workOrder = new WorkOrder({
      // accomodationAddress: o.accomodation_address,
      accomodationName: o.accomodation_name,
      accomodationStreet: o.accomodation_street,
      accomodationZipCode: o.accomodation_zip_code,
      accomodationCity: o.accomodation_city,
      accomodationDescription: o.accomodation_address_description,
      attachments: o.attachments,
      description: o.description,
      id: o.id,
      interval: moment.interval(o.from, o.to),
      from: moment(o.from),
      to: moment(o.to),
      isNotBusy: o.is_not_busy,
      location: o.location,
      name: o.name,
      purchaser: o.purchaser,
      userId: o.userId,
      updatedAt: moment(o.updates_at),
      work_order: o.work_order,
      workHourType: o.work_hour_type,
      workNumbers: o.work_numbers,
      workTargets: o.work_targets,
      employerAcceptance: o.employer_acceptance,
      uiStartTrip: o.ui_start_trip,
      uiEndTrip: o.ui_end_trip,
      invitationsCount: o.invitations_count,
      // workOrderTrips: o.work_order_trips,
      // originalWorkOrderTrips: o.original_work_order_trips,
      isHiddenFromUser: o.is_hidden_from_user,
      extid: o.extid,
      adminInfo: o.admin_info,
      userPrivileges: o.work_order_user_privileges,
      workTasks: o.work_tasks ? o.work_tasks.map(WorkTask.fromJsonProperties) : [],
      workTasksCount: o.work_tasks_count,
      // Creating an empty bill by default that we can use it in the billing table and save it into the database easier
      bill: o.bill ? o.bill : {
        id: null,
        work_order_id: o.id,
        status: null,
      },
      timeStatus: o.time_status,
      salaryPeriodAttachments: o.salary_period_attachments ? o.salary_period_attachments.map(SalaryPeriodAttachment.fromJsonProperties) : [],
      allowanceAutofillReminders: o.allowance_autofill_reminders,
      chat: {
        id: o.chat?.id,
        // eslint-disable-next-line camelcase
        unreadMessagesCount: o.chat?.unread_messages_count,
        // eslint-disable-next-line camelcase
        totalMessagesCount: o.chat?.total_messages_count,
      },
      workHourAccepterNames: o.work_hour_accepter_names,
      responsibleEmployerNames: o.responsible_employer_names,
      // workPeriods: o.work_periods ? o.work_periods.map(WorkPeriod.fromJsonProperties) : [],
      // workDays: o.work_days ? o.work_days.map(CalendarEntry.fromJsonProperties) : [],
      billingId: padBillingIdWithZeroes(o.billing_id),
      workTaskEmployeeManagementEnabled: o.work_task_employee_management_enabled,
      workPeriods,
      workPeriodNames,
      workDays,
      workDaysFromDates,
      firstWorkDay: moment.min(workDaysFromDates),
      lastWorkDay: moment.max(workDaysFromDates),
      settings: camelcaseSettings || {},
    });

    if (o.participants != null) {
      workOrder.participants = o.participants.map((participant) => User.fromJsonProperties(participant, mode));
      workOrder.uniqueParticipants = uniqBy(workOrder.participants, 'id');
      workOrder.responsibleEmployers = o.responsible_employers.map(User.fromJsonProperties);
    }

    let workHours = [];
    // const workHoursPerDayCombined = [];

    if (o.work_hours != null && o.work_hours.length !== 0) {
      workHours = o.work_hours.map((item) => TimelogEntry.fromJsonProperties(item));
    }

    // let workOrderTrips = [];
    // if (o.work_order_trips != null) {
    //   workOrderTrips = o.work_order_trips.map((item) => WorkOrderTrip.fromJsonProperties(item));
    // }
    // workOrder.workOrderTrips = workOrderTrips;

    if (o.work_order_invitations != null) {
      workOrder.workOrderInvitations = o.work_order_invitations.map((invite) => WorkOrderInvitation.fromJsonProperties(invite));
    }

    let billingAttachments = [];
    if (o.billing_attachments != null) {
      billingAttachments = o.billing_attachments.map((attachment) => BillingAttachment.fromJsonProperties(attachment));
    }
    workOrder.billingAttachments = billingAttachments?.sort((a, b) => (b.createdAt.valueOf() - a.createdAt.valueOf())) || [];

    // TODO: CHECK SALARY PERIODS + TRIP DATES HERE, REMOVE IF NEEDED
    // ORIGINAL TRIP + USER TRIP + OUTWARD_VISIBLE_BOOL + RETURN_VISIBLE_BOOL (conditional components)

    // if (o.user_work_order_trips != null && o.user_work_order_trips.length !== 0) {
    //   workOrder.userWorkOrderTrips = o.user_work_order_trips.map(WorkOrderTrip.fromJsonProperties);
    // }

    const days = getInterValDays(workOrder.interval);

    // if (o.work_hour_type === 'daily') {
    days.forEach((date) => {
      const workorderentry = workHours.find((wh) => (wh.date != null ? wh.date.isSame(date) : false));
      if (workorderentry == null) {
        workHours.push(new TimelogEntry({
          date,
          userId: workOrder.userId,
          workOrderId: workOrder.id,
        }));
      }
    });
    workOrder.workHours = workHours.sort((a, b) => (a.date.valueOf() - b.date.valueOf()));
    // } else {
    //   // Assuming workHourType 'hourly', organizing the work hours per date (multiple work hours per day)
    //   days.forEach((date) => {
    //     const singleDay = { date: null, hours: null, dailyData: null };
    //     // Non-daily data, e.g. multiple work hours with work numbers
    //     const workOrderEntry = workHours.filter((wh) => (wh.workTask != null && wh.date != null ? wh.date.isSame(date) : false));
    //     if (workOrderEntry) {
    //       singleDay.date = date;
    //       singleDay.hours = workOrderEntry;
    //     }
    //     // Daily data (should always be just one entry per day, contains stuff like drive_time)
    //     const dailyEntry = workHours.find((wh) => (wh.date.isSame(date) && !wh.workTask));
    //     if (dailyEntry) {
    //       singleDay.dailyData = dailyEntry;
    //     } else {
    //       singleDay.dailyData = new TimelogEntry({
    //         date,
    //         userId: workOrder.userId,
    //         workOrderId: workOrder.id,
    //       });
    //     }
    //     if (singleDay.date != null) {
    //       workHoursPerDayCombined.push(singleDay);
    //     }
    //   });
    //   workOrder.workHours = workHoursPerDayCombined;
    // }
    return workOrder;
  }
}
