/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { Icon } from '@rmwc/icon';
import { IconButton } from '@rmwc/icon-button';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@rmwc/icon/icon.css';

import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/list/dist/mdc.list.css';

import 'moment/locale/fi';

import WorkOrderTrip from './WorkOrderTrip';
import StartTrip from './StartTrip';
import EndTrip from './EndTrip';
import { TripStart } from '../assets/images/custom-icons'

import TimelogTripSummary from './timelog-trip-summary';
import TimelogTripStart from './timelog-trip-start';
import TimelogTripEnd from './timelog-trip-end';
import moment, { combinedDateTime, isBetween } from '../utils/moment';
import { isEqual, cloneDeep } from 'lodash';
import RouteLocation from './RouteLocation';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('workOrderTripStore', 't', 'uiStore', 'timelogStore', 'employerContextStore')
@observer
class TimelogTripDialog extends Component {
  constructor(props) {
    super(props);

    const { workOrderTrip } = props;

    // this.dialogContentRef = React.createRef();

    const startLocations = workOrderTrip?.startTrip?.tripRoute?.[0]?.routeLocations.map((location) => {
      const loc = { street: location.street, zip_code: location.zip_code, city: location.city }

      return loc;
    });

    this.state = {
      // Allowance autofill is true by default
      allowanceAutofillEnabled: workOrderTrip ? workOrderTrip.allowanceAutofillEnabled : true,
      ...workOrderTrip,
      endTripHidden: true,
      moreOptionsOpen: false,
      startLocs: startLocations,
      tripChanged: false,
      // If we do not cloneDeep these, the start/end trips' tripRoute and routeLocations will still update with the current trip because they're their own objects
      originalStartTrip: cloneDeep(workOrderTrip?.startTrip),
      originalEndTrip: cloneDeep(workOrderTrip?.endTrip),
      showDeleteTripDialog: false,
      activeTab: 0,
    };

    this.saveTrip = this.saveTrip.bind(this);

    this.initWorkOrderTrip();
  }

  setAllowanceAutofill = (value) => {
    this.setState({
      allowanceAutofillEnabled: value,
      tripChanged: true,
    });
  }

  removeFromWorkOrders = (value) => {
    const { workOrders } = this.state;

    const updatedWorkOrders = [...workOrders].filter((workOrder) => workOrder.id !== value);

    this.setState({
      workOrders: updatedWorkOrders,
      tripChanged: true,
    });
  }

  cleanTripToBeCloned(tripToBeCloned) {
    const cleanedTrip = cloneDeep(tripToBeCloned);
    cleanedTrip.id = null;
    cleanedTrip.status = null;
    cleanedTrip.date.add(1, 'days');
    // Used for both startTrips and endTrips but startTrips don't have toDate
    cleanedTrip.toDate?.add(1, 'days');
    cleanedTrip.tripRoute[0].id = null;
    cleanedTrip.tripRoute[0].routeLocations.forEach((routeLocation) => { routeLocation.id = null });
    return cleanedTrip;
  }

  initWorkOrderTrip = () => {
    const { workOrderTrip, defaultTripDate, tripToBeCloned } = this.props;

    let initialStartTrip;
    let initialEndTrip;

    if (!tripToBeCloned) {
      initialStartTrip = new StartTrip({ date: defaultTripDate });
      initialEndTrip = new EndTrip({});

      if (workOrderTrip == null) {
        this.state = {
          id: null,
          status: 0,
          // userId: currentUser.id,
          startTrip: initialStartTrip,
          endTrip: initialEndTrip,
          endTripHidden: true,
          moreOptionsOpen: false,
          originalStartTrip: initialStartTrip,
          originalEndTrip: initialEndTrip,
          allowanceAutofillEnabled: true,
          activeTab: 0,
        }
      }
    } else {
      // const initialTrips = this.cleanTripToBeCloned(tripToBeCloned);
      initialStartTrip = this.cleanTripToBeCloned(tripToBeCloned.startTrip);
      initialEndTrip = this.cleanTripToBeCloned(tripToBeCloned.endTrip);

      this.state = {
        id: null,
        status: 0,
        // userId: currentUser.id,
        startTrip: initialStartTrip,
        endTrip: initialEndTrip,
        endTripHidden: true,
        moreOptionsOpen: false,
        originalStartTrip: initialStartTrip,
        originalEndTrip: initialEndTrip,
        tripChanged: true,
        allowanceAutofillEnabled: tripToBeCloned.allowanceAutofillEnabled,
        activeTab: 0,
      }
    }
  }

  componentDidMount() {
    const { endTrip } = this.state;
    const hasData = endTrip?.time || endTrip?.toTime || endTrip?.date || endTrip?.toDate ? true : false;

    if (hasData) {
      this.setState({
        endTripHidden: false,
      })
    }
  }

  // Scroll to end_trip attempt when opening the end trip fix
  // componentDidUpdate(prevProps) {
  //   const { tripDialogOpen, scrollToEndTrip } = this.props;
  //   // && prevProps.tripDialogOpen !== tripDialogOpen
  //   if (tripDialogOpen && scrollToEndTrip && this.dialogContentRef.current) {
  //     const dialogContentElement = this.dialogContentRef.current;
  //     dialogContentElement.scrollTop = dialogContentElement.scrollHeight;
  //   }
  // }

  getKmsAutofillByLocations = (mode, locations, quickAddressSelectUpdate) => {
    const { workOrderTripStore } = this.props;
    const allHaveStreetAddresses = !locations.find((location) => !location.street);
    if (allHaveStreetAddresses) {
      // const kmsMatches = workOrderTripStore.getAutofillKmsByLocations(locations);
      const promise = fromPromise(new Promise((resolve, reject) => workOrderTripStore.getKmsAutofillByLocations(locations, resolve, reject)));
      when(
        () => promise.state !== 'pending',
        () => {
          promise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Haku epäonnistui', err);
            },
            fulfilled: (kmsAutofillMatches) => {
              // 1. Update the kms (if empty) in the correct trip (mode)
              if (kmsAutofillMatches[0] && (!this.state[mode].tripRoute[0].kms || quickAddressSelectUpdate)) {
                const updatedTrip = { ...this.state[mode] };
                updatedTrip.tripRoute[0].kms = kmsAutofillMatches[0].kms;
                this.setState({
                  [mode]: updatedTrip,
                });
              } else if (quickAddressSelectUpdate) {
                // No match was found on quickAddress select: reset the kms
                const updatedTrip = { ...this.state[mode] };
                updatedTrip.tripRoute[0].kms = null;
                this.setState({
                  [mode]: updatedTrip,
                });
              }
              // 2. Add the kms as autofills (state array) and relay it to the form
            },
          });
        },
      );
    } else {
      console.log('Address missing');
    }
  }

  updateStartTrip = (item, quickAddressSelectUpdate = false) => {
    const { originalStartTrip } = this.state;
    const originalTripRoutes = [...originalStartTrip.tripRoute];

    const startLocations = item?.tripRoute?.[0].routeLocations.map((location, index) => {
      const loc = { street: location.street, zipCode: location.zipCode, city: location.city, tempIndex: index };
      return loc;
    });

    let tripChanged = false;
    if (!isEqual(item, originalStartTrip)) {
      tripChanged = true;
    }

    this.setState({
      startTrip: item,
      startLocs: startLocations,
      tripChanged: tripChanged,
    }, () => {
      // We want to remove the tempNames for accurate comparison and checking for changes
      const cleanedRouteLocations = [...item.tripRoute[0].routeLocations].map((location) => {
        const cleanedLocation = new RouteLocation({ ...location });
        delete cleanedLocation.tempName;
        return cleanedLocation;
      });
      if (!isEqual(cleanedRouteLocations, originalTripRoutes[0].routeLocations)) {
        this.getKmsAutofillByLocations('startTrip', item.tripRoute[0].routeLocations, quickAddressSelectUpdate);
      }
    });
  }

  updateEndTrip = (item, quickAddressSelectUpdate = false) => {
    const { endTrip, originalEndTrip } = this.state;
    const originalTripRoutes = [...originalEndTrip.tripRoute];

    let tripChanged = false;
    if (!isEqual(item, originalEndTrip)) {
      tripChanged = true;
    }

    this.setState({
      endTrip: item,
      tripChanged: tripChanged,
    }, () => {
      // We want to remove the tempNames for accurate comparison and checking for changes
      const cleanedRouteLocations = [...item.tripRoute[0].routeLocations].map((location) => {
        const cleanedLocation = new RouteLocation({ ...location });
        delete cleanedLocation.tempName;
        return cleanedLocation;
      });
      if (!isEqual(cleanedRouteLocations, originalTripRoutes[0].routeLocations)) {
        this.getKmsAutofillByLocations('endTrip', item.tripRoute[0].routeLocations, quickAddressSelectUpdate);
      }
    });
  }

  // Intended to be used with autofill when updating start trip route values like km OR when setting endTripHidden to false
  updateEndTripRouteAttr = (attr, value) => {
    const { endTrip, endTripHidden } = this.state;
    const endTripCopy = { ...endTrip };

    if (!endTripCopy.tripRoute[0][attr] && !endTripHidden) {
      endTripCopy.tripRoute[0][attr] = value;
      this.setState({
        endTrip: endTripCopy,
        tripChanged: true,
      });
    }
  }

  saveTrip() {
    const { workOrderTripStore, workOrder, uiStore: { currentUser }, employerContextStore: { currentEmployeeId } } = this.props;

    const trip = new WorkOrderTrip(this.state);

    trip.userId = (currentEmployeeId && currentUser.role === 'employer') ? currentEmployeeId : null;

    // This method is only called when saving as a draft, so we set statuses to draft here, but only if the trip hasn't been accepted already
    if (trip.startTrip.status !== 'accepted') {
      trip.startTrip.status = 0;
    }
    if (trip.endTrip.status !== 'accepted') {
      trip.endTrip.status = 0;
    }

    return workOrderTripStore.save(trip, workOrder.id, workOrder.name);
  }

  saveTripEmployer() {
    const { workOrderTripStore, workOrder, uiStore: { currentUser }, employerContextStore: { currentEmployeeId }, employerAfterUpdate } = this.props;

    const trip = new WorkOrderTrip(this.state);
    trip.userId = (currentEmployeeId && currentUser.role === 'employer') ? currentEmployeeId : null;

    return workOrderTripStore.save(trip, workOrder.id, workOrder.name, employerAfterUpdate);
  }

  deleteTrip = () => {
    const { workOrderTripStore, workOrderTrip, toggleTripDialog, employerDeleteCallback } = this.props;
    workOrderTripStore.delete(workOrderTrip).then(() => {
      if (employerDeleteCallback) {
        employerDeleteCallback(workOrderTrip.id);
      }
      toggleTripDialog();
    });
  }

  // This method should be cleaned up
  validateTripInputs() {
    const { startTrip, endTrip, endTripHidden } = this.state;
    const { maxEndDate, maxEndTime } = this.props;

    if ((!startTrip.date && startTrip.time || startTrip.date && !startTrip.time) && endTripHidden) {
      return false;
    }

    if ((endTrip.toTime && !endTrip.toDate || !endTrip.toTime && endTrip.toDate) && !endTripHidden) {
      return false;
    }

    // maxEndDate is the maximum allowed end trip date, should be the greatest start date among trips (the start date of the latest trip)
    // We compare this with the start trip's date to see if the trip comes before the chronologically last trip
    // If the current trip is earlier than the last trip, then there are requirements:
    //    1. End trip has to be created. This is to prevent overlapping trips
    //    2. End trip's toDate has to be smaller or equal to the maxEndDate
    //    3. If end trip's toDate and maxEndDate are equal, then end trip's toTime must be smaller than maxEndTime (from the same trip as maxEndDate)

    // if (
    //   startTrip[0].date < maxEndDate && startTrip[0].time < maxEndTime && (
    //     (!endTrip[0].toDate || !endTrip[0].toTime) ||
    //     (
    //       endTrip[0].toDate?.format('DD-MM-YYYY') > maxEndDate.format('DD-MM-YYYY') ||
    //       (endTrip[0].toDate?.format('DD-MM-YYYY') === maxEndDate.format('DD-MM-YYYY') && endTrip[0].toTime > maxEndTime)
    //     )
    //   )
    // ) {
    //   return false;
    // }

    return true;
  }

  findTripOverlap() {
    const { allTrips, workOrderTrip } = this.props;
    const { startTrip, endTrip } = this.state;

    const newTripStartDateTime = combinedDateTime(startTrip.date, startTrip.time);
    const newTripEndDateTime = combinedDateTime(endTrip.toDate, endTrip.toTime);
    let overlap = false;

    // Can't validate without start date and time info
    if (!newTripStartDateTime) {
      return false;
    }

    // TODO: OPTIMIZE
    // 1. sort trips newest first by startTrip.date at a hi
    // 2. break loop if both startDateTime and endDateTime are smaller than dateWithTime

    // Sort trips, newest first
    // const sortedAllTrips = allTrips.sort((a, b) => a.startTrip[0].date.format('DD-MM-YYYY') < b.startTrip[0].date.format('DD-MM-YYYY'));

    if (allTrips) {
      for (const trip of allTrips) {
        const oldTripStartDateTime = combinedDateTime(trip.startTrip.date, trip.startTrip.time);
        const oldTripEndDateTime = combinedDateTime(trip.endTrip.toDate, trip.endTrip.toTime);

        // Ignore the trip itself, if already saved
        if (workOrderTrip?.id && workOrderTrip.id === trip.id) {
          continue;
        }

        if (
          oldTripStartDateTime && oldTripEndDateTime &&
          (newTripStartDateTime.isBetween(oldTripStartDateTime, oldTripEndDateTime, null, '[]') || newTripEndDateTime?.isBetween(oldTripStartDateTime, oldTripEndDateTime, null, '[]'))
        ) {
          // Either start trip's date time or end trip's date time overlaps with an old ended trip
          overlap = true;
          break;
        } else if (newTripEndDateTime && oldTripStartDateTime && oldTripEndDateTime &&
          newTripStartDateTime <= oldTripStartDateTime &&
          newTripEndDateTime >= oldTripEndDateTime
        ) {
          // New trip covers an entire existing trip (full overlap)
          overlap = true;
          break;
        } else if (!newTripEndDateTime && (newTripStartDateTime < oldTripStartDateTime || newTripStartDateTime < oldTripEndDateTime)) {
          // New trip has no ending, can't have any other trip after it
          overlap = true;
          break;
        } else if (!oldTripEndDateTime && (!newTripEndDateTime || newTripStartDateTime > oldTripStartDateTime || newTripEndDateTime > oldTripStartDateTime)) {
          // Ongoing trip found. New trip has to have an end date time AND must not overlap with the ongoing trip (both date times must be smaller than ongoing trip's start date time)
          overlap = true;
          break;
        }
        // else if (endDateTime && dateWithTime > startDateTime && dateWithTime > endDateTime) {
        //   // Getting to older, irrelevant trips
        //   break;
        // }
      }
    }

    return overlap;
  }

  validateStartTrip() {
    const { startTrip } = this.state;

    let allValid = false;

    const timeData = startTrip?.date && startTrip?.time ? true : false;

    let locationData = true;
    startTrip?.tripRoute?.[0]?.routeLocations.forEach(location => {
      location.city && location.street && locationData ? locationData = true : locationData = false;
    });

    if (timeData && locationData) {
      allValid = true;
    } else {
      allValid = false;
    }

    return allValid;
  }

  validateEndTrip() {
    const { startTrip, endTrip } = this.state;

    let allValid = false;
    let alignsWithStartTrip = false;
    let datesAlign = false;

    const timeData = endTrip?.date && endTrip?.time && endTrip?.toDate && endTrip?.toTime ? true : false;

    if (endTrip?.date && endTrip?.toDate) {
      datesAlign = endTrip.date.format('YYYY-MM-DD') <= endTrip.toDate.format('YYYY-MM-DD');
    } else {
      datesAlign = true;
    }

    let locationData = true;

    endTrip?.tripRoute?.[0]?.routeLocations.forEach(location => {
      location.city && location.street && locationData ? locationData = true : locationData = false;
    });

    if (startTrip.date?.format('YYYY-MM-DD') < endTrip.date?.format('YYYY-MM-DD') && startTrip.date?.format('YYYY-MM-DD') < endTrip.toDate?.format('YYYY-MM-DD')) {
      // Start date is before the end date
      alignsWithStartTrip = true;
    } else if ((startTrip.date?.format('YYYY-MM-DD') === endTrip.date?.format('YYYY-MM-DD') || startTrip.date?.format('YYYY-MM-DD') === endTrip.toDate?.format('YYYY-MM-DD')) && (startTrip.time < endTrip?.time && startTrip?.time < endTrip?.toTime)) {
      // Start and end dates are the same but the start time is before the end time
      alignsWithStartTrip = true;
    } else {
      alignsWithStartTrip = false;
    }

    if (timeData && locationData && alignsWithStartTrip && datesAlign) {
      allValid = true;
    } else {
      allValid = false;
    }

    return allValid;
  }

  validateStartEndTrips() {
    return this.validateStartTrip() && this.validateEndTrip()
  }

  toggleEndTripHidden = () => {
    const { endTripHidden, startTrip } = this.state;

    this.setState({
      endTripHidden: !endTripHidden,
    }, () => {
      if (startTrip.tripRoute[0].kms && !this.state.endTripHidden) {
        this.updateEndTripRouteAttr('kms', startTrip.tripRoute[0].kms);
      }
    });
  }

  findNewerTripByStartTime() {
    const { workOrderTrip, allTrips } = this.props;
    const { startTrip } = this.state;

    const startTripDateTime = combinedDateTime(startTrip.date, startTrip.time);
    // Ignore the trip itself if saved, then check for an equal or greater date
    const foundTrip = allTrips?.find((trip) => (!workOrderTrip?.id || trip.id !== workOrderTrip?.id) && combinedDateTime(trip.startTrip.date, trip.startTrip.time).isSameOrAfter(startTripDateTime));

    return foundTrip;
  }

  actionButtonDisabled() {
    const { employerMode } = this.props;
    const { tripChanged, startTrip, endTrip, endTripHidden } = this.state;

    const startTripStatus = startTrip.status;
    const endTripStatus = endTrip.status;
    const today = new Date();

    if (startTripStatus === 'accepted' && endTripStatus === 'accepted' && !employerMode) {
      return true;
    } else if ((startTripStatus === 'accepted' || startTripStatus === 'pending' || startTripStatus === 'rejected') && (endTripStatus === 'accepted' || endTripStatus === 'pending' || endTripStatus === 'rejected') && !tripChanged) {
      // Both start and end already accepted, either by user or employer, and there's no changes, no need to accept again
      return true;
    } else if ((startTripStatus === 'pending' || startTripStatus === 'rejected') && endTripHidden && !tripChanged) {
      // Start is accepted or rejected, end is hidden and there's no changes, no need to accept
      return true;
    }
    else if (!this.validateStartTrip() || (!this.validateEndTrip() && !endTripHidden) || !this.validateTripInputs()) {
      // Something is invalid with the trip values
      return true;
    } else if (this.findTripOverlap()) {
      // An overlap was found with an existing trip
      return true;
    }
    else {
      // All is correct
      return false;
    }
  }

  renderActionButtons() {
    const { startTrip, tripChanged } = this.state;
    const today = new Date();
    const actionButtonsDisabled = this.actionButtonDisabled();

    return (
      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '15px'
        }}
      >
        <DialogButton
          disabled={actionButtonsDisabled || startTrip.date > today}
          className="accept-button"
          action="accept"
        >
          Hyväksy
        </DialogButton>

        <DialogButton
          disabled={actionButtonsDisabled}
          className="accept-button"
          style={{
            fontSize: '13px',
          }}
          onClick={() => this.selectedAction('save')}
        >
          Tallenna luonnos
        </DialogButton>

        {tripChanged ?
          <DialogButton
            className="cancel-button"
            action="cancel"
          >
            Peruuta
          </DialogButton>
          :
          <DialogButton
            className="cancel-button"
            action="cancel"
          >
            Sulje
          </DialogButton>
        }
      </DialogActions>
    );
  }

  renderActionButtonsEmployer() {
    const { startTrip, tripChanged } = this.state;
    const today = new Date();
    const actionButtonsDisabled = this.actionButtonDisabled();

    return (
      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '15px',
          gap: '20px',
          flexDirection: 'row',
        }}
      >
        <DialogButton
          disabled={actionButtonsDisabled || startTrip.date > today}
          className="accept-button"
          action="employerSaveAndAccept"
          // This width matches the employer work hour edit dialog button widths
          // For some reason we need to specify the order here, or else the rendered HTML button order doesn't match
          style={{ width: '125px', margin: 0, order: 1 }}
        >
          Tallenna ja hyväksy
        </DialogButton>

        <DialogButton
          disabled={actionButtonsDisabled}
          className="accept-button"
          style={{
            fontSize: '13px',
            // This width matches the employer work hour edit dialog button widths
            width: '125px',
            margin: 0,
            // For some reason we need to specify the order here, or else the rendered HTML button order doesn't match
            order: 2,
          }}
          onClick={() => this.selectedAction('employerSave')}
        >
          Tallenna
        </DialogButton>

        <DialogButton
          className="cancel-button"
          action="cancel"
          // This width matches the employer work hour edit dialog button widths
          // For some reason we need to specify the order here, or else the rendered HTML button order doesn't match
          style={{ width: '125px', margin: 0, order: 3 }}
        >
          {tripChanged ? 'Peruuta' : 'Sulje'}
        </DialogButton>
      </DialogActions>
    );
  }

  selectedAction(method, mode = null) {
    const { id, userId, status, startTrip, endTrip } = this.state;
    const { workOrderTripStore, toggleTripDialog, timelogStore, workOrder, employerAfterUpdate, uiStore: { currentUser }, employerContextStore: { currentEmployeeId } } = this.props;

    if (method === 'save') {
      this.saveTrip();
      toggleTripDialog();
    }

    if (method === 'employerSave') {
      this.saveTripEmployer();
      toggleTripDialog();
    }

    if (method === 'accept') {
      const trip = new WorkOrderTrip(this.state);
      workOrderTripStore.accept(trip, mode, workOrder.id, workOrder.name);
      toggleTripDialog();
    }

    if (method === 'employerSaveAndAccept') {
      const trip = new WorkOrderTrip(this.state);
      trip.userId = (currentEmployeeId && currentUser.role === 'employer') ? currentEmployeeId : null;
      workOrderTripStore.employerSaveAndAccept(trip, mode, workOrder.id, workOrder.name, employerAfterUpdate);
      toggleTripDialog();
    }
  }

  render() {
    const {
      toggleTripDialog, tripDialogOpen, workOrderTrip, workOrder, defaultTripDate,
      maxStartDate, maxStartTime, maxEndDate, maxEndTime, employerMode
    } = this.props;
    const { startTrip, endTrip, endTripHidden, startLocs, showDeleteTripDialog, allowanceAutofillEnabled, workOrders, activeTab } = this.state;

    return (
      <>
        <Dialog
          open={tripDialogOpen}
          onClose={(evt) => {
            evt.stopPropagation();
            if (evt.detail.action === 'save' || evt.detail.action === 'employerSave' || evt.detail.action === 'employerSaveAndAccept') {
              if (this.validateTripInputs()) {
                this.selectedAction(evt.detail.action);
              }
            } else if (evt.detail.action === 'accept') {
              this.selectedAction(evt.detail.action, endTripHidden ? 'start' : 'start_end');
            } else if (evt.detail.action === 'cancel') {
              toggleTripDialog();
            } else if (evt.detail.action === 'close') {
              toggleTripDialog();
            }
          }}
          className={employerMode ? "trip-route-dialog employer-trip-route-dialog" : "trip-route-dialog"}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <DialogTitle
              style={{ color: 'var(--mdc-theme-primary)', padding: '5px 10px' }}
            >
              Työmatka
            </DialogTitle>
            {/* hack to remove autofocus on trash can */}
            <input style={{ width: '1px', height: '0px', opacity: '0' }} />
            {workOrderTrip?.id && (workOrderTrip.startTrip.status !== 'accepted' || workOrderTrip.endTrip.status !== 'accepted') && (
              <IconButton
                icon="delete_outlined"
                onClick={() => this.setState({ showDeleteTripDialog: true })}
                style={{ padding: 0, alignSelf: 'end', color: '#c7c9cc' }}
              />
            )}
          </div>
          <DialogContent style={{ color: 'white', overflowX: 'hidden' }}>

            {/* {(startTrip.employerComment || endTrip.employerComment) && (startTrip.status !== 'rejected' || startTrip.status === 'draft' || endTrip.status === 'rejected' || endTrip.status === 'draft') && ( */}
            {((startTrip.employerComment && startTrip.status !== 'pending' && startTrip.status !== 'accepted') || (endTrip.employerComment && endTrip.status !== 'pending' && endTrip.status !== 'accepted')) && (
              <div
                className="employer-comment"
                style={{
                  backgroundColor: 'var(--mdc-theme-surface, #fff)',
                  padding: '15px 10px',
                  margin: '0px -10px',
                  color: 'red'
                }}
              >
                <Icon icon="info" />
                <div className="employer-comment-inner">
                  {/* {startTrip.employerComment} */}
                  {startTrip.employerComment && startTrip.status !== 'pending' && startTrip.status !== 'accepted' && (
                    <p
                      style={{
                        margin: '0px 0px 0px 0px'
                      }}
                    >
                      {startTrip.employerComment}
                    </p>
                  )}
                  {endTrip.employerComment && endTrip.status !== 'pending' && endTrip.status !== 'accepted' && (
                    <p
                      style={{
                        margin: `${startTrip.employerComment && startTrip.status !== 'pending' && startTrip.status !== 'accepted' ? '10px' : '0px'} 0px 0px 0px`
                      }}
                    >
                      {endTrip.employerComment}
                    </p>
                  )}
                </div>
              </div>
            )}

            <TimelogTripSummary
              workOrderTrip={workOrderTrip}
              // We give the state's allowanceAutofillEnabled as a prop here since otherwise the switch won't work when creating a new trip
              allowanceAutofillEnabled={allowanceAutofillEnabled}
              setAllowanceAutofill={this.setAllowanceAutofill}
              removeFromWorkOrders={this.removeFromWorkOrders}
              tripWorkOrders={workOrders}
              currentWorkOrderId={workOrder.id}
            />

            <TabBar
              activeTabIndex={activeTab}
              onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
            // style={{ maxWidth: '800px' }}
            >
              <Tab>
                Työmatkan alkaminen
              </Tab>
              <Tab>
                Päättyminen
              </Tab>
              {/* <Tab icon="history" /> */}
            </TabBar>

            <div>
              <div style={{ display: activeTab === 0 ? 'block' : 'none' }}>
                <TimelogTripStart
                  startTrip={startTrip}
                  updateStartTrip={this.updateStartTrip}
                  workOrder={workOrder}
                  defaultTripDate={defaultTripDate}
                  maxStartDate={maxStartDate}
                  maxStartTime={maxStartTime}
                  updateEndTripRouteAttr={this.updateEndTripRouteAttr}
                  disabled={startTrip.status === 'accepted' && !employerMode}
                />
              </div>

              <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
                <TimelogTripEnd
                  startTrip={startTrip}
                  endTrip={endTrip}
                  updateEndTrip={this.updateEndTrip}
                  workOrder={workOrder}
                  endTripHidden={endTripHidden}
                  toggleEndTripHidden={this.toggleEndTripHidden}
                  startLocs={startLocs}
                  defaultTripDate={defaultTripDate}
                  maxEndDate={maxEndDate}
                  maxEndTime={maxEndTime}
                  disabled={endTrip.status === 'accepted' && !employerMode}
                />
              </div>
            </div>
          </DialogContent>
          {employerMode ? this.renderActionButtonsEmployer() : this.renderActionButtons()}
        </Dialog>
        {showDeleteTripDialog && (
          <Dialog
            className="dialog-background"
            open={showDeleteTripDialog}
            onClose={(evt) => {
              if (evt.detail.action === 'accept') {
                this.deleteTrip();
              }
              this.setState({
                showDeleteTripDialog: false,
              });
            }}
          >
            <DialogContent
              style={{ color: 'white' }}
            >
              <p>Haluatko poistaa matkan lopullisesti?</p>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                padding: '15px',
              }}
            >
              <DialogButton
                className="accept-button"
                style={{
                  borderRadius: '0px',
                }}
                action="accept"
              >
                Kyllä
              </DialogButton>
              <DialogButton
                style={{
                  color: '#c7c9cc',
                  border: '1px solid #c7c9cc',
                  borderRadius: '0px',
                }}
                action="close"
              >
                Peruuta
              </DialogButton>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default TimelogTripDialog;
