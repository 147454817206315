/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
} from '@rmwc/card';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import moment, { asTime } from '../utils/moment';

@inject('timelogStore', 'uiStore', 't')
@observer
class AccidentReportCard extends Component {
  render() {
    const {
      accidentReport: {
        date, time, reporter, createdAt, location, workSteps, relatedObject, eventSteps, causes, fixSuggestions, status,
      },
      accidentReport,
      employer,
      openShowDialog,
      selectDraftReport,
    } = this.props;

    console.log('ALL PROPS: ', this.props);

    const isDraftStatus = status === 1;

    // Define the default background color based on the employer status
    const backgroundColor = employer ? 'white' : '';

    const style = {
      width: '100%',
      backgroundColor,
    };

    // If it's a draft status, add additional border styles
    // if (isDraftStatus) {
    //   style.borderStyle = 'solid';
    //   style.borderWidth = '5px';
    //   style.borderColor = 'yellow';
    // }

    return (
      <Card
        style={style}
        onClick={() => {
          if (isDraftStatus) { // If it's a draft, select the draft report
            selectDraftReport(accidentReport);
          } else {
            openShowDialog(accidentReport);
          }
        }}
      >
        <Grid
          style={{ width: '100%' }}
        >
          <GridInner
            style={{
              rowGap: '8px',
            }}
          >
            {isDraftStatus && (
              <div style={{
                textAlign: 'center',
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'yellow',
              }}
              >
                Luonnos
              </div>
            )}
            <GridCell
              span={12}
              style={{ borderBottom: '1px dimgray solid', paddingBottom: '10px' }}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    {moment(createdAt).format('dd DD.MM.YYYY HH:mm')}
                  </span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>
                    {reporter.firstName}
                    &nbsp;
                    {reporter.lastName}
                  </span>
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '14px' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{location}</span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '14px', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    {moment(date).format('dd DD.MM.YYYY')}
                    &nbsp;
                    {asTime(time)}
                  </span>
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '14px' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{workSteps}</span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '14px', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{eventSteps}</span>
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell
              span={12}
              style={{ fontSize: '14px' }}
            >
              <span>{relatedObject}</span>
            </GridCell>

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '14px' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{causes}</span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '14px', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{fixSuggestions}</span>
                </GridCell>
              </GridInner>
            </GridCell>

          </GridInner>
        </Grid>
      </Card>
    );
  }
}

export default AccidentReportCard;
