import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, Button, DialogContent,
} from '@material-ui/core';

// import { DirectUploadProvider } from 'react-activestorage-provider';

// import { buttonColors } from '../calendar/EmployeeCalendar';
// import { handleProgress } from '../models/user/user-ui-avatar';

@inject('t')
@observer
class ConfirmDeleteDialog extends Component {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     id: props.id,
  //     dialogOpen: props.open,
  //   };
  // }

  render() {
    // const { t } = this.props;
    const {
      open,
      message,
      onConfirm,
      onClose,
      mode,
    } = this.props;

    return (
      <Dialog
        className={mode !== 'employer' ? 'mui-dialog-background' : ''}
        open={open}
        onClose={() => onClose()}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <div>{message}</div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            className={mode === 'employer' ? 'employer-accept-button' : 'employee-accept-button'}
            style={{
              borderRadius: '0px',
            }}
            onClick={() => {
              onConfirm();
              onClose();
            }}
          >
            Kyllä
          </Button>

          <Button
            className={mode === 'employer' ? 'employer-reject-button' : 'employee-reject-button'}
            style={{
              borderRadius: '0px',
            }}
            onClick={() => onClose()}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDeleteDialog;
