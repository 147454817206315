import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell } from '@rmwc/grid';
import {
  Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';


const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const nameValidate = (name) => {
  if (!name) {
    return 'Tilaajan nimi on pakollinen';
  }
  return null;
};


const streetValidate = (street) => {
  if (!street) {
    return 'Katuosoite on pakollinen';
  }
  return null;
};

const cityValidate = (city) => {
  if (!city) {
    return 'Paikkakunta on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  name: (name) => nameValidate(name),
  street: (street) => streetValidate(street),
  city: (city) => cityValidate(city),
};

@inject('purchaserStore', 'loginStore', 't')
@observer
class PurchaserDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.purchaser,
      // id: props.id,
      name: props.dialogValue || props.purchaser?.name || '',
      // purchaser: props.purchaser,
      errors: {},
      inputChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { purchaser, dialogValue, open } = this.props;

    // Check if the dialog has just been opened
    if (!prevProps.open && open) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        id: purchaser?.id || '',
        name: dialogValue || purchaser?.name || '',
        street: purchaser?.street || '',
        zipCode: purchaser?.zipCode || '',
        city: purchaser?.city || '',
        errors: {},
        inputChanged: false,
      });
      // Original first case changed to here
    } else if (prevProps.purchaser?.id !== purchaser?.id) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        id: purchaser?.id || '',
        name: purchaser?.name || dialogValue || '',
        street: purchaser?.street || '',
        zipCode: purchaser?.zipCode || '',
        city: purchaser?.city || '',
        errors: {},
      });
    }
  }

  addTextField(attr, label, required, textarea) {
    const { errors } = this.state;

    return (
      <TextField {...{
        className: 'employer-dialog-input',
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        rows: 5,
        invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState({
            [attr]: value,
            inputChanged: true,
          });

          delete errors[attr];
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        /* eslint-disable-next-line react/destructuring-assignment */
        value: this.state[attr] != null ? this.state[attr] : '',
      }}
      />
    );
  }

  renderActionButtons() {
    const {
      inputChanged,
      name,
    } = this.state;
    const { id } = this.props;

    if (!id && !name) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    if (!id && name) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(name);
            // }}
            action={name ? 'accept' : null}
          >
            Tallenna
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    if (id && inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(name);
            // }}
            action={name ? 'accept' : null}
          >
            Tallenna
          </DialogButton>
        </DialogActions>
      );
    }
    if (id && !inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    return null;
  }

  render() {
    const {
      open,
      handleClose,
      savePurchaser,
      purchaser: { id },
      // purchaser,
    } = this.props;

    const { name, street, city } = this.state;

    return (
      <Dialog
        className="modal-size employer-modal"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            let errors = {};

            Object.keys(validate).forEach((key) => {
              /* eslint-disable-next-line react/destructuring-assignment */
              const value = this.state[key];

              // Calling the attr's validation method
              const error = validate[key](value);

              if (error) {
                errors = { ...errors, [key]: error };
              }
            });

            if (Object.keys(errors).length > 0) {
              this.setState({ errors });
            } else {
              // Transform the fields to start with uppercase
              const transformedData = {
                ...this.state,
                name: name.charAt(0).toUpperCase() + name.slice(1),
                street: street.charAt(0).toUpperCase() + street.slice(1),
                city: city.charAt(0).toUpperCase() + city.slice(1),
                id,
              };
              savePurchaser(transformedData);
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          { id ? 'Muokkaa tilaajaa' : 'Uusi tilaaja' }
        </DialogTitle>
        <DialogContent>
          <Grid className="employer-work-order">
            <GridCell span={12}>
              {this.addTextField('name', 'Nimi', true, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('street', 'Katuosoite', true, false)}
            </GridCell>

            <GridCell span={6}>
              {this.addTextField('zipCode', 'Postinumero', false, false)}
            </GridCell>
            <GridCell span={6}>
              {this.addTextField('city', 'Paikkakunta', true, false)}
            </GridCell>
          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default PurchaserDialog;
