import { observable, action } from 'mobx';

export default class WorkTaskUser {
  @observable id;

  @observable workTaskId;

  @observable user;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      workTaskId: o.work_task_id,
      user: {
        id: o.user.id,
        name: o.user.name,
      },
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const workTaskUser = new WorkTaskUser({});
    workTaskUser.updatePropertiesFromJson(object);
    return workTaskUser;
  }

  static fromJson(json) {
    return WorkTaskUser.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    return {
      id: o.id,
      work_task_id: o.workTaskId,
      user_id: o.user.id,
      _destroy: o._destroy,
    };
  }
}
