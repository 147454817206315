import React from 'react';
import ActiveStorageProvider from 'react-activestorage-provider';
import { Avatar } from '@rmwc/avatar';

export const handleProgress = (uploads, t, color = null) => uploads.map((upload) => {
  switch (upload.state) {
    case 'waiting':
      return (
        <p key={upload.id} style={{ color }}>
          {t('uploads.waiting_for_upload')}
          {' '}
          {upload.file.name}
        </p>
      );
    case 'uploading':
      return (
        <p key={upload.id} style={{ color }}>
          {t('uploads.uploading')}
          {' '}
          {upload.file.name}
          :
          {' '}
          {Math.floor(upload.progress)}
          %
        </p>
      );
    case 'error':
      return (
        <p key={upload.id} style={{ color }}>
          {t('uploads.error_uploading')}
          {' '}
          {upload.file.name}
          :
          {' '}
          {upload.error}
        </p>
      );
    case 'finished':
      return (
        <p key={upload.id} style={{ color }}>
          {t('uploads.finished_uploading')}
          {' '}
          {upload.file.name}
        </p>
      );
    default:
      return null;
  }
});

export const addProfilePicture = (currentUser, profileInputRef, profilePictureURL, t, mode = null) => (
  <ActiveStorageProvider
    key="user-provide-updater"
    endpoint={{
      attribute: 'personal_picture',
      method: 'PUT',
      model: 'User',
      multiple: false,
      path: `/api/users/${currentUser?.id}`,
    }}
    onSubmit={(newData) => {
    // eslint-disable-next-line no-param-reassign
      currentUser.personalPictureUrl = newData.personal_picture_url;
    }}
    render={({ handleUpload, uploads, ready }) => (
      <>
        <input
          className="inputfile"
          disabled={!ready}
          id="user-profile-uploader"
          onChange={(e) => handleUpload(e.currentTarget.files)}
          ref={profileInputRef}
          type="file"
        />
        <Avatar
          disabled={!ready}
          htmlFor="user-profile-uploader"
          interactive
          name={`${currentUser?.firstName} ${currentUser?.lastName}`}
          onClick={() => profileInputRef.current.click()}
          ripple={false}
          size="xlarge"
          src={profilePictureURL}
          style={{ backgroundColor: mode === 'employee-profile' && 'lightgray' }}
        />
        {handleProgress(uploads, t)}
      </>
    )}
    required
  />
);

export default addProfilePicture;
