import { observable, action } from 'mobx';

export default class WorkHourBank {
  @observable calcAmountToAdd

  @observable currentBalance;

  @observable minBalance;

  @observable maxBalance;

  @observable defaultBalanceChange;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updatePropertiesFromJson(object) {
    this.updateProperties({
      calcAmountToAdd: object.calc_amount_to_add,
      currentBalance: object.current_balance,
      minBalance: object.min_balance,
      maxBalance: object.max_balance,
      defaultBalanceChange: object.default_balance_change,
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const workHourBank = new WorkHourBank({});
    workHourBank.updatePropertiesFromJson(object);
    return workHourBank;
  }

  static fromJson(json) {
    return WorkHourBank.fromJsonProperties(JSON.parse(json));
  }
}
