import React, { Component } from 'react';
import { Icon } from '@rmwc/icon';
import { IconButton } from '@rmwc/icon-button';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { isEqual } from 'lodash';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import { buttonColors } from '../calendar/EmployeeCalendar';
import moment from '../utils/moment';
import {
  TripStartEnd,
  TripStart,
  TripMiddle,
  TripEndMiddle,
  TripEnd,
} from '../assets/images/custom-icons';

const getTripIconColor = (rowDate, trip, subTripTypes) => {
  const startDate = trip.startTrip.date.format('YYYY-MM-DD');
  // End trip may not have any values (ongoing trip), therefore we use optional chaining
  const endDate = trip.endTrip?.toDate?.format('YYYY-MM-DD');
  const startTripStatus = trip.startTrip.status;
  const endTripStatus = trip.endTrip?.status;
  const isRejected = (startTripStatus === 'rejected' && rowDate === startDate) || (endTripStatus === 'rejected' && rowDate === endDate) || (startTripStatus === 'rejected' && endTripStatus === 'rejected');

  if (isRejected) {
    return 'red';
  }
  if (subTripTypes.length === 2) {
    if (trip[subTripTypes[0]].status === 'accepted' && trip[subTripTypes[1]].status === 'accepted') {
      return buttonColors.green.color;
    }
  } else if (subTripTypes.length === 1) {
    if (trip[subTripTypes].status === 'accepted') {
      return buttonColors.green.color;
    }
  }
  return 'var(--mdc-theme-primary)';
};

const matchesWorkOrder = (trip, workOrderId) => (!trip.workOrders || trip.workOrders.length === 0 || trip.workOrders.map((wo) => wo.id).includes(workOrderId));

const matchesClickedWorkOrderDate = (trip, workOrderId, clickedDate) => {
  const tripStartDate = trip.startTrip.date;
  const tripEndDate = trip.endTrip.toDate;
  // Exclusive in-between: start and end dates can have overlapping trips on another work orders if the times are different
  const dateIsBetween = clickedDate.isBetween(tripStartDate, tripEndDate, undefined, '()');
  const ongoingTripOverlap = !tripEndDate && !clickedDate.isSame(tripStartDate, 'day');
  // Trip isn't connected to the current work order (belongs to another work order)
  // AND
  // Trip has work orders connected to it (backwards compatibility)
  // AND
  // Date within the trip (exclusive) OR trip doesn't have end date (ongoing) AND the clicked date is the same as the ongoing trip's start date
  return !trip.workOrders.map((wo) => wo.id).includes(workOrderId) && trip.workOrders.length !== 0 && (dateIsBetween || ongoingTripOverlap);
};

class WorkOrderTripIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tripListOpen: false,
    };
  }

  openTripList(overlappingTrips, iconName) {
    const { tripListOpen } = this.state;
    const { toggleTripDialog, item } = this.props;

    return (
      <MenuSurfaceAnchor>
        <Menu
          hoistToBody="true"
          open={tripListOpen}
          onSelect={(evt) => toggleTripDialog(overlappingTrips[evt.detail.index], item.date, overlappingTrips)}
          onClose={() => this.setState({ tripListOpen: false })}
          style={{ backgroundColor: '#0A131E' }}
        >
          {overlappingTrips?.map((trip) => (
            <MenuItem
              key={trip.id}
              style={{ color: 'white' }}
            >
              {`Työmatka ${trip.startTrip?.date.format('DD.MM')} - ${trip.endTrip?.toDate ? trip.endTrip?.toDate.format('DD.MM') : ''}`}
              {(trip.startTrip.status === 'rejected' || trip.endTrip.status === 'rejected') && <Icon style={{ marginLeft: '5px', color: 'red' }} icon="info" />}
            </MenuItem>
          )
          )}
        </Menu>

        <IconButton onClick={() => this.setState({ tripListOpen: true })} icon={iconName} />
      </MenuSurfaceAnchor>
    );
  }

  renderTripIcon() {
    const {
      item,
      disabled,
      onGoingTrip,
      endedTrips,
      toggleTripDialog,
      openTripDisabledDialog,
    } = this.props;

    const rowDate = item.date.format('YYYY-MM-DD');

    // Trips that overlap with the row date
    const overlappingTrips = [];
    // Trips that match the current work order context (clicked row's work order)
    const relevantOverlappingTrips = [];
    // Trips that do NOT match the current work order context (used to restrict creating new trips)
    const otherWoOverlappingTrips = [];

    const overlappingTripStartDates = [];
    const overlappingTripEndDates = [];
    const onGoingTripStartDate = onGoingTrip?.startTrip.date.format('YYYY-MM-DD');
    let icon = null;

    // Process ended trips
    // Why are we sorting here, in the row's render?
    endedTrips.sort((a, b) => a.endTrip.toDate - b.endTrip.toDate || moment(a.endTrip.toTime) - moment(b.endTrip.toTime));

    endedTrips.forEach((trip) => {
      const startDate = trip.startTrip.date.format('YYYY-MM-DD');
      const endDate = trip.endTrip.toDate.format('YYYY-MM-DD');

      // Get trips that overlap with the current row date (inclusive)
      if (item.date.isBetween(startDate, endDate, null, '[]')) {
        overlappingTrips.push(trip);
        if (matchesWorkOrder(trip, item.workOrderId, item.date)) {
          // The trip "belongs" to the current work order
          relevantOverlappingTrips.push(trip);
        } else if (matchesClickedWorkOrderDate(trip, item.workOrderId, item.date)) {
          // The trip "belongs" to another work order
          otherWoOverlappingTrips.push(trip);
        }
        overlappingTripStartDates.push(startDate);
        overlappingTripEndDates.push(endDate);
      }
    });

    // Process ongoing trip
    if (onGoingTripStartDate && rowDate >= onGoingTripStartDate) {
      overlappingTrips.push(onGoingTrip);
      if (matchesWorkOrder(onGoingTrip, item.workOrderId, item.date)) {
        // The trip "belongs" to the current work order
        relevantOverlappingTrips.push(onGoingTrip);
      } else if (matchesClickedWorkOrderDate(onGoingTrip, item.workOrderId, item.date)) {
        // The trip "belongs" to another work order
        otherWoOverlappingTrips.push(onGoingTrip);
      }

      overlappingTripStartDates.push(onGoingTripStartDate);
    }

    if (!disabled && relevantOverlappingTrips.length === 0 && otherWoOverlappingTrips.length > 0) {
      icon = (
        <IconButton
          icon="add"
          onClick={() => openTripDisabledDialog(otherWoOverlappingTrips, overlappingTrips)}
          style={{
            padding: '0px',
            color: 'var(--mdc-theme-primary)',
          }}
        />
      );
    } else if (relevantOverlappingTrips.length > 0) {
      if (relevantOverlappingTrips.length === 1) {
        const overlappingTrip = relevantOverlappingTrips[0];
        const overlappingTripStart = overlappingTrip.startTrip.date.format('YYYY-MM-DD');
        const overlappingTripEnd = overlappingTrip.endTrip?.toDate?.format('YYYY-MM-DD');

        if (rowDate === overlappingTripStart && rowDate === overlappingTripEnd) {
          // Single trip, trip starts and ends on the row
          icon = (
            <IconButton
              onClick={() => toggleTripDialog(overlappingTrip, item.date, overlappingTrips)}
              icon={<TripStartEnd color={getTripIconColor(rowDate, overlappingTrip, ['startTrip', 'endTrip'])} />}
            />
          );
        } else if (rowDate === overlappingTripStart && overlappingTripStart !== overlappingTripEnd) {
          // Single trip, start trip date
          icon = (
            <IconButton
              onClick={() => toggleTripDialog(overlappingTrip, item.date, overlappingTrips)}
              icon={<TripStart color={getTripIconColor(rowDate, overlappingTrip, ['startTrip'])} />}
            />
          );
        } else if (rowDate === overlappingTripEnd) {
          // Single trip, end trip date
          icon = (
            <IconButton
              onClick={() => toggleTripDialog(overlappingTrip, item.date, overlappingTrips)}
              icon={<TripEnd color={getTripIconColor(rowDate, overlappingTrip, ['endTrip'])} />}
            />
          );
        } else {
          // Single trip, date is in the middle of the trip
          icon = (
            <IconButton
              onClick={() => toggleTripDialog(overlappingTrip, item.date, overlappingTrips)}
              icon={<TripMiddle color={getTripIconColor(rowDate, overlappingTrip, ['startTrip', 'endTrip'])} />}
            />
          );
        }
      } else if (relevantOverlappingTrips.length > 1) {
        const allDatesEqual = isEqual(overlappingTripStartDates, overlappingTripEndDates);
        const startDatesAllEqual = overlappingTripStartDates.every((date) => date === overlappingTripStartDates[0]);
        const endDatesAllEqual = overlappingTripEndDates.every((toDate) => toDate === overlappingTripEndDates[0]);

        const trip = relevantOverlappingTrips[relevantOverlappingTrips.length - 1];

        if (allDatesEqual) {
          // All dates are equal: two single-day trips on the same day
          icon = this.openTripList(
            overlappingTrips,
            <TripStartEnd color={getTripIconColor(rowDate, trip, ['startTrip', 'endTrip'])} />,
          );
        } else if (startDatesAllEqual) {
          // Start dates are equal: starting on the same day
          icon = this.openTripList(
            overlappingTrips,
            <TripStart color={getTripIconColor(rowDate, trip, ['startTrip'])} />,
          );
        } else if (endDatesAllEqual && overlappingTripEndDates.length === overlappingTrips.length) {
          // End dates are equal: ending on the same day and no onGoingTrip (missing end date)
          icon = this.openTripList(
            overlappingTrips,
            <TripEnd color={getTripIconColor(rowDate, trip, ['endTrip'])} />,
          );
        } else {
          // No date equality, one trip ends on the day another trip starts
          icon = this.openTripList(
            overlappingTrips,
            <TripEndMiddle color={getTripIconColor(rowDate, trip, ['startTrip'])} />,
          );
        }
      }
    } else {
      icon = (
        <IconButton
          icon="add"
          onClick={() => toggleTripDialog(null, item.date, overlappingTrips, endedTrips)}
          style={{
            padding: '0px',
            color: 'var(--mdc-theme-primary)',
          }}
        />
      );
    }

    return (
      <div
        className="work-order-trip-column"
        style={{
          padding: 0,
          textAlign: 'center',
          width: '16.5%',
          display: 'flex',
        }}
      >
        <div
          style={{
            width: '100%',
            alignSelf: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {icon}
        </div>
      </div>
    );
  }

  render() {
    return this.renderTripIcon();
  }
}

export default WorkOrderTripIcon;
