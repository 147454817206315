/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

import CloseCallReportCard from './CloseCallReportCard.jsx';
import NewCloseCallReportDialog from './NewCloseCallReportDialog.jsx';
import ShowCloseCallReportDialog from './ShowCloseCallReportDialog.jsx';
import LoadingSpinner from './../shared/LoadingSpinner.jsx';
import { appcontentsize } from '../styles/inline-styles';

// const indexToStatus = [
//   'ongoing',
//   'past',
//   'upcomingAll',
//   'upcomingShort',
// ];

const renderCloseCallReportCards = (closeCallReports, openShowDialog) => {
  if (!closeCallReports) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <LoadingSpinner color="white" />
      </div>
    );
  } else if (closeCallReports.length === 0) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div>Lähetettyjä ilmoituksia ei löytynyt</div>
      </div>
    );
  }

  return (
    <GridInner
      style={{
        rowGap: '4px',
      }}
    >
      {closeCallReports.map((closeCallReport) => (
        <GridCell
          desktop={6}
          tablet={4}
          key={`woitem-${closeCallReport.id}`}
        >
          <CloseCallReportCard
            closeCallReport={closeCallReport}
            openShowDialog={openShowDialog}
          // selectedFilter={selectedFilter}
          />
        </GridCell>
      ))}
    </GridInner>
  );
};

@inject('uiStore', 'loginStore', 't', 'closeCallReportStore')
@observer
class Safety extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeCallReports: [],
      openCloseCallReport: null,
    };
  }

  componentDidMount() {
    const { uiStore: { currentView }, closeCallReportStore } = this.props;

    closeCallReportStore.get().then((closeCallReports) => {
      this.setState({
        closeCallReports,
      });
    });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (currentView.selectedFilter) {
      this.setState({
        selectedFilter: currentView.selectedFilter,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  newCloseCallReport = (newReport) => {
    this.setState((prevState) => ({
      closeCallReports: [newReport, ...prevState.closeCallReports],
    }));
  }

  handleCloseNewDialog = () => {
    this.setState({
      newCloseCallReportDialogOpen: false,
    });
  }

  handleCloseShowDialog = () => {
    this.setState({
      showCloseCallReportDialogOpen: false,
    });
  }

  openShowDialog = (closeCallReport) => {
    this.setState({
      showCloseCallReportDialogOpen: true,
      openCloseCallReport: closeCallReport,
    });
  }

  render() {
    const {
      newCloseCallReportDialogOpen,
      closeCallReports,
      showCloseCallReportDialogOpen,
      openCloseCallReport,
    } = this.state;

    return (
      <div style={appcontentsize}>
        <Grid
          style={{ width: '100%', height: '100%', padding: '16px 0px' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
              style={{
                textAlign: 'end',
              }}
            >
              <Button
                type="button"
                startIcon={<AddIcon />}
                className="accept-button mdc-button mui-button-hover-overwrite"
                style={{
                  margin: '10px',
                  borderRadius: '0px',
                }}
                onClick={() => this.setState({ newCloseCallReportDialogOpen: true })}
              >
                Tee ilmoitus
              </Button>
            </GridCell>

            <GridCell
              style={{ padding: '0px 16px' }}
              desktop={12}
              tablet={8}
              phone={4}
            >
              {closeCallReports && (
                renderCloseCallReportCards(closeCallReports, this.openShowDialog)
              )}
            </GridCell>

            <NewCloseCallReportDialog
              open={newCloseCallReportDialogOpen}
              handleClose={this.handleCloseNewDialog}
              newCloseCallReport={this.newCloseCallReport}
            />

            <ShowCloseCallReportDialog
              open={showCloseCallReportDialogOpen}
              handleClose={this.handleCloseShowDialog}
              closeCallReport={openCloseCallReport || {}}
              employerView={false}
            />
          </GridInner>
        </Grid>
      </div>
    );
  }
}

export default Safety;
