export default function normalizeFileName(input) {
  // Replace spaces, dots, and commas with underscores
  const underscored = input.replace(/[ .,]/g, '_');

  // Replace Nordic characters with English characters
  const normalized = underscored
    .replace(/ä/g, 'a')
    .replace(/æ/g, 'ae')
    .replace(/ö/g, 'o')
    .replace(/ø/g, 'o')
    .replace(/å/g, 'a');

  // Remove other special characters
  const cleaned = normalized.replace(/[^a-zA-Z0-9_]/g, '_');

  return cleaned;
}
