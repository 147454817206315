import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@material-ui/core';
import { Icon } from '@rmwc/icon';
import MUIDataTable from 'mui-datatables';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import LoadingSpinner from '../shared/LoadingSpinner';
import PurchaserDialog from './PurchaserDialog';
import './locations-purchasers.css';


@inject('uiStore', 'loginStore', 't', 'purchaserStore')
@observer
class Purchasers extends Component {
  constructor(props) {
    super(props);
    const { purchaserStore, uiStore: { currentUser } } = this.props;

    this.state = {
      // filterDialogOpen: false,
      purchaserDialogOpen: false,
      // currentPurchasers: null,
      selectedPurchaser: {},
      // currentWorkOrdersWithMeta: [] || workOrdersWithMeta,

      // Normally array, initializing as null here so that we can tell if the db query is complete or not
      // currentWorkOrdersWithMeta: null,
      // filterCount: 0,
      // offset: 0,
    };

    purchaserStore.getPurchasers(currentUser);
  }

  togglePurchaserDialog = (purchaser) => {
    this.setState((prevProps) => ({
      selectedPurchaser: purchaser || {},
      purchaserDialogOpen: !prevProps.purchaserDialogOpen,
    }));
  }

  savePurchaser = (purchaser) => {
    const { purchaserStore } = this.props;

    if (purchaser.id == null || purchaser.id === '') {
      const createPromise = fromPromise(new Promise((resolve, reject) => purchaserStore.createPurchaser(purchaser, resolve, reject)));
      // createPromise.then((newPurchaser) => {
      //   this.updatePurchasersState(newPurchaser);
      // });
      when(
        () => createPromise.state !== 'pending',
        () => {
          createPromise.case({
            pending: () => {},
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            // fulfilled: (newPurchaser) => {
            //   this.updatePurchasersState(newPurchaser);
            // },
            fulfilled: () => {
              this.setState({
                selectedPurchaser: {},
              });
            },
          });
        },
      );
    } else {
      const updatePromise = fromPromise(new Promise((resolve, reject) => purchaserStore.updatePurchaser(purchaser, resolve, reject)));
      // updatePromise.then((updatedPurchaser) => {
      //   this.updatePurchasersState(updatedPurchaser);
      // });
      when(
        () => updatePromise.state !== 'pending',
        () => {
          updatePromise.case({
            pending: () => {},
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            // fulfilled: (updatedPurchaser) => {
            //   this.updatePurchasersState(updatedPurchaser);
            // },
            fulfilled: () => {
              this.setState({
                selectedPurchaser: {},
              });
            },
          });
        },
      );
    }
  }

  renderPurchasersTable(purchasers, noDataText) {
    const { purchaserStore } = this.props;

    if (purchasers == null) {
      // DB query not yet complete
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <LoadingSpinner color="black" />
        </div>
      );
    }

    if (purchasers.length === 0) {
      return <p>{noDataText}</p>;
    }

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'name',
        label: 'Nimi',
        options: {
          filter: false,
          display: purchaserStore.purchaserDisplayColumns.name === undefined ? true : purchaserStore.purchaserDisplayColumns.name,
        },
      },
      {
        name: 'street',
        label: 'Katuosoite',
        options: {
          display: purchaserStore.purchaserDisplayColumns.street === undefined ? false : purchaserStore.purchaserDisplayColumns.street,
          filter: false,
        },
      },
      {
        name: 'zipCode',
        label: 'Postinumero',
        options: {
          filter: false,
          display: purchaserStore.purchaserDisplayColumns.zipCode === undefined ? false : purchaserStore.purchaserDisplayColumns.zipCode,
        },
      },
      {
        name: 'city',
        label: 'Paikkakunta',
        options: {
          filter: true,
          filterType: 'multiselect',
          filterList: purchaserStore.purchaserFilters.city,
          display: purchaserStore.purchaserDisplayColumns.city === undefined ? true : purchaserStore.purchaserDisplayColumns.city,
          filterOptions: {
            fullWidth: true,
          },
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          viewColumns: false,
          display: true,
          download: false,
          print: false,
          customBodyRenderLite: (dataIndex) => (
            <Icon
              icon="edit"
              style={{
                cursor: 'pointer',
                padding: '0 10px',
              }}
              onClick={() => {
                this.togglePurchaserDialog(data[dataIndex]); // eslint-disable-line
              }}
            />
          ),
        },
      },
    ];

    const data = purchasers.value;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'vertical',
      search: true,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: false,
      sort: false,
      print: false,
      download: false,
      onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
        if (changedColumnIndex) {
          purchaserStore.setPurchaserFilters(changedColumn, filterList[changedColumnIndex]);
        } else {
          purchaserStore.emptyFilters();
        }
      },
      onViewColumnsChange: (changedColumn, action) => {
        const columnIndex = columns.findIndex(col => col.name === changedColumn); // eslint-disable-line
        if (columnIndex !== -1) {
          if (action === 'remove') {
            purchaserStore.setPurchaserDisplayColumns(changedColumn, false);
          } else if (action === 'add') {
            purchaserStore.setPurchaserDisplayColumns(changedColumn, true);
          }
        }
      },
      textLabels: {
        body: {
          noMatch: 'Tilaajia ei löytynyt',
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        pagination: {
          next: 'Seuraava sivu',
          previous: 'Edellinen',
          rowsPerPage: 'Rivejä sivulla:',
          displayRows: '/',
        },
        filter: {
          all: 'Kaikki',
          title: 'SUODATIN',
          reset: 'Tyhjennä',
        },
        viewColumns: {
          title: 'Näytä sarakkeet',
          titleAria: 'Näytä/piilota sarakkeet',
        },
        selectedRows: {
          text: 'rivi(ä) valittu',
          delete: 'Poista',
          deleteAria: 'Poista valitut rivit',
        },
      },
    };

    return (
      <div className="table-wrapper">
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }

  render() {
    const { purchaserStore: { purchasers } } = this.props;
    const {
      // filterDialogOpen,
      purchaserDialogOpen,
      // filterCount,
      // currentPurchasers,
      // activeFilters,
      selectedPurchaser,
    } = this.state;

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // A margin to match the table below
                margin: '0 20px',
              }}
            >
              <Button
                onClick={() => this.togglePurchaserDialog()}
                className="employer-accept-button mdc-button"
                style={{
                  margin: '10px',
                  borderRadius: '0px',
                }}
              >
                Uusi tilaaja
              </Button>
            </GridCell>
            <GridCell span={12}>
              {purchasers.case({
                pending: () => (
                  <GridCell style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <LoadingSpinner color="black" />
                    </div>
                  </GridCell>
                ),
                rejected: () => <GridCell style={{ textAlign: 'center' }}><p>Tilaajia ei juuri nyt pystytty lataamaan.</p></GridCell>,
                fulfilled: () => (
                  <>
                    {/* <div>Kohteet ladattu</div> */}
                    {this.renderPurchasersTable(purchasers, 'Kohteita ei löytynyt')}
                  </>
                ),
              })}
            </GridCell>
          </GridInner>
        </Grid>

        <PurchaserDialog
          open={purchaserDialogOpen}
          handleClose={this.togglePurchaserDialog}
          purchaser={selectedPurchaser}
          savePurchaser={this.savePurchaser}
        />
      </>
    );
  }
}

export default Purchasers;
