export function onDownload(buildHead, buildBody, columns, data) {
  // Translate the booleans so that CSV has "Kyllä"/null instead of "TRUE"/"FALSE"
  const dataWithTranslatedBooleans = data.map((row, index) => (
    {
      index,
      data: row.data.map((value) => (value === true ? 'Kyllä' : (value || null))),
    }
  ));
  // Fix the CSV for Excel. Without this, letters like Ä and Ö won't work
  // See: https://github.com/gregnb/mui-datatables/issues/834
  // "Excel will won't open the file with a UTF-8 encoding unless a BOM character is found at the beginning"

  // eslint-disable-next-line prefer-template
  return '\uFEFF' + buildHead(columns) + buildBody(dataWithTranslatedBooleans);
}

// We need to update the state columns manually so that the default column displays defined there aren't re-applied on re-render, e.g. on opening a dialog
// Required in views where the MUIDataTable's columns are reset on rendering, which means that without this something like dialog opening resets the visible columns back to default
export function onViewColumnsChange(changedColumn, action, columns, setColumns) {
  const updatedColumnIndex = columns.findIndex((column) => column.name === changedColumn);
  const updatedColumns = [...columns];

  let displayValue;
  switch (action) {
    case 'add':
      displayValue = true;
      break;
    case 'remove':
      displayValue = false;
      break;
    default:
      // Shouldn't happen but we keep it here in case a new action is added on library update, for example
      displayValue = true;
      break;
  }

  updatedColumns[updatedColumnIndex].options.display = displayValue;

  setColumns(updatedColumns);
}
