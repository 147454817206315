/* eslint-disable */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  CollapsibleList,
  SimpleListItem,
} from '@rmwc/list';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogTitle,
  DialogContent,
} from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { toFloat } from 'validator';
import { GridCell } from '@rmwc/grid';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import WorkTaskEntry from '../WorkTaskEntry';
import WorkTask from '../../employer-work-orders/work-tasks/WorkTask';
import HourlyTimelogRowEditable from './hourly-timelog-row-editable';
import HourlyTimelogForm from './hourly-timelog-form';
import WorkTaskEmployeeCreateDialog from '../../employer-work-orders/work-tasks/WorkTaskEmployeeCreateDialog';

import noop from '../../utils/noop';
import { asDate } from '../../utils/moment';


const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('timelogStore', 't', 'uiStore', 'workTaskStore', 'workOrderStore')
@observer
class HourlyTimelogDialog extends Component {
  constructor(props) {
    super(props);
    this.creationFormRef = React.createRef();

    this.state = {
      createNewRowCollapsibleOpen: false,

      // Manage the UI
      // hourlyLogRows: this.props.hourlyLogRows,
      // Manage the database queries on save
      hourlyLogRows: [],
      // newHourlyLogRows: [],
      // deletedHourlyLogRows: [],
      newFrom: null,
      newTo: null,
      newWorkNumber: null,
      // newDescription: null,
      // tempTimeType: 'time_normal',
      selectedWorkNumber: null,
      createNewButtonEnabled: false,

      createWorkTaskDialogOpen: false,
      newWorkTask: new WorkTask(),
    };

    this.open = false;
  }

  componentDidMount() {
    this.setState({
      ...this.props.item,
    });
    // Open the new row creation form immediately if no old rows exist
    if (this.props.hourlyLogRows.length === 0) {
      this.setState({
        createNewRowCollapsibleOpen: true,
      });
    }
    // this.setState({ hourlyLogRows: this.parseLogRowsForUI(this.props.hourlyLogRows) });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hourlyLogRows.length != this.props.hourlyLogRows.length && this.props.hourlyLogRows.length !== 0) {
      this.setState({
        createNewRowCollapsibleOpen: false,
      });
    }
  }

  setCreateNewButtonEnabled = (enabled = false) => {
    this.setState({ createNewButtonEnabled: enabled });
  }

  setWorkType(type) {
    // e.g. 50 as a parameter here
    // set newTimeType as 50, use translation in HTML part
    // In save: look at newTimeType + inserted hours

    // this.setState({ newHourlyLog: { workType: type } });
    console.log('timeType: ', type);
    this.setState({ tempTimeType: type });
    // this.setState({ timeTypeCollapsibleOpen: false });
  }

  setWorkNumber(number) {
    this.setState({ newWorkNumber: number[0] });
    this.setState({ selectedWorkNumber: `${number[0]} - ${number[1]}` });
    this.setState({ workNumberCollapsibleOpen: false });
  }

  doOnBlur(attr, value, event) {
    // const { item } = this.props;
    // const { props: { uiStore: { currentUser: { accountId } } } } = this;

    if (this.state[attr] !== value && event.target.validity.valid) {
      this.setState({ attr: value });
      // item.changeAttribute(attr, value);

      // Split driveTime into normal driveTime, 50% driveTime and 100% driveTime in the background
      // TODO: Hardcoded to account id 1
      /* if (accountId === 1) {
        if (attr === 'driveTime') {
          this.distributeDrivetime(value);
        } else {
          this.distributeDrivetime(this.state.driveTimeCombined);
        }
      } */

      // timelogStore.updateTimelogEntry(item);
      console.log('Set state: ', this.state[attr]);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  parseLogRowsForUI(logRows) {
    console.log('parsing log row for dialog UI');
    const parsedRows = [];
    logRows.forEach((row) => {
      const parsedRow = row;
      if (row.timeNormal) {
        // console.log('row.timeNormal');
        parsedRow.tempTimeType = 'time_normal';
        parsedRow.tempDuration = row.timeNormal;
        parsedRow.tempId = `${row.timeFrom}_${row.timeTo}_${row.tempTimeType}_${row.workNumber}`;
      } else if (row.time50) {
        // console.log('row.Time50');
        parsedRow.tempTimeType = 'time_50';
        parsedRow.tempDuration = row.time50;
        parsedRow.tempId = `${row.timeFrom}_${row.timeTo}_${row.tempTimeType}_${row.workNumber}`;
      } else if (row.time100) {
        // console.log('row.Time100');
        parsedRow.tempTimeType = 'time_100';
        parsedRow.tempDuration = row.time100;
        parsedRow.tempId = `${row.timeFrom}_${row.timeTo}_${row.tempTimeType}_${row.workNumber}`;
      }
      parsedRows.push(parsedRow);
    });
    return parsedRows;
  }

  addDecimalFieldDialog(attr, label = null, ctx = {}) {
    return (
      <div
        style={{ padding: 0, textAlign: 'center' }}
      >
        <TextField {...{
          className: 'pk-number-field',
          /* onBlur: (event) => {
            this.doOnBlur(attr, event.target.value, event);
          }, */
          onChange: noop,
          onInput: (event) => {
            let value = event.target.value.replace(/[.,]{1,}/, '.');

            if (value.substr(-1) !== '.' && value !== '') {
              value = toFloat(value);
              if (value >= (ctx.max || 24)) {
                return;
              }
            }

            if ((!Number.isNaN(value) || value === '')) {
              this.doOnChange(attr, value, event);
            }
          },
          disabled: this.props.disabled,
          pattern: '[0-9.,]*',
          rootProps: { ripple: false },
          style: {
            height: '44px',
            margin: 0,
            minWidth: '40px',
            padding: 0,
            width: '90px',
          },
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          onKeyPress: handleEnter,
          value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
          label,
          ...ctx,
        }}
        />
      </div>
    );
  }

  doOnChange(attr, value, event) {
    if (this.state[attr] !== value && event.target.validity.valid) {
      this.setState({ [attr]: value });
      return asDate;
    } else {
      return asDate;
    }
  }

  renderDescription() {
    const { t, kmsType } = this.props;
    return (
      <GridCell
        style={{
          backgroundColor: 'var(--mdc-theme-surface, #fff)',
          padding: '15px 10px',
          margin: '5px',
          fontSize: '14px'
        }}
      > Valitse työ ja sen tunnit. Voit lisätä useamman ajanjakson.
      </GridCell>
    )
  }

  openCreateWorkTaskDialog = () => {
    this.setState({
      createWorkTaskDialogOpen: true,
      newWorkTask: new WorkTask(),
    });
  }

  closeCreateWorkTaskDialog = () => {
    this.setState({
      createWorkTaskDialogOpen: false,
      newWorkTask: new WorkTask(),
    });
  }

  createOrUpdateWorkTask = (workTask) => {
    const { createWorkTaskDialogOpen } = this.state;
    const { workOrder, workTaskStore, workOrderStore, workOrderId } = this.props; // updateWorkTaskState

    if (!workTask.id) {
      const createPromise = new Promise((resolve) => workTaskStore.createWorkTaskEmployee(workTask, resolve));
      createPromise.then((newTask) => {
        // updateWorkTaskState(newTask);
        // A hack to update the file list
        if (createWorkTaskDialogOpen) {
          this.setState({ newWorkTask: newTask });
        }
        // const workOrder = workOrderStore.findById(workOrderId);
        const foundTaskIndex = workOrder.workTasks.findIndex((task) => task.id === workTask.id);
        if (foundTaskIndex === -1) {
          workOrder.workTasks = [newTask, ...workOrder.workTasks];
        }
        this.creationFormRef.current.setWorkTask(newTask.id);
      });
    }
    // else {
    //   const updatePromise = new Promise((resolve) => workTaskStore.updateWorkTask(workTask, resolve));
    //   updatePromise.then((updatedTask) => {
    //     // A hack to update the file list
    //     if (createWorkTaskDialogOpen) {
    //       this.setState({ newWorkTask: updatedTask });
    //     }
    //   });
    // }
  }

  render() {
    const {
      uiStore: { currentUser },
      workOrder,
      date,
      // workNumbers,
      // workTargets,
      // t,
      dialogOpen,
      closeDialog,
      hourlyLogRows,
      editHourlyRow,
      deleteHourlyRow,
      createHourlyRow,
      workOrder: { workTasks },
      item,
      employerUpdateMode,
    } = this.props;
    const { createNewRowCollapsibleOpen, createNewButtonEnabled, createWorkTaskDialogOpen, newWorkTask } = this.state;

    const lastTask = hourlyLogRows?.[hourlyLogRows.length - 1]?.to || '';
    let oldTimeNormal = 0;
    let oldTime50 = 0;
    const dayStr = date.format('D.M. dd');
    // const weekday = date.weekday();

    // For non-employer users, filter out work tasks that haven't been added to user (for work orders where work task employee management is enabled)
    const ownWorkTasks = currentUser.role === 'employer' ? workTasks : workTasks.filter((workTask) => workTask.workTaskUsers.find((workTaskUser) => workTaskUser.user.id === currentUser.id));

    hourlyLogRows.forEach((hourlyRow) => {
      // WIP for autofilling every hourlyRow at once, using from and to instead of the timeNormal and time50

      // const timeFrom = moment(hourlyRow.from, 'H:m');
      // const timeTo = moment(hourlyRow.to, 'H:m');
      // let time = 0;

      // if (hourlyRow.from && hourlyRow.to && hourlyRow.from > hourlyRow.to) {
      //   const timeToMidnight = moment('24:00', 'H:m').diff(timeFrom) / 3600000;
      //   const timeFromMidnight = timeTo.diff(moment('00:00', 'H:m')) / 3600000;
      //   time = timeToMidnight + timeFromMidnight;
      // } else if (hourlyRow.from && hourlyRow.to) {
      //   time = timeTo.diff(timeFrom) / 3600000;
      // }
      // time = Number(time);

      // // Mon-Fri
      // if (weekday < 5) {
      //   // Max 8 normal, max 2 50%, the rest is 100%
      //   // First check how many normal hours are "left" for the day by removing existing ones from 8
      //   if (time > 8) {
      //     oldTimeNormal += 8;
      //     oldtime50 += (8 - time);
      //   } else {
      //     oldTimeNormal += time;
      //   }
      //   // Sat
      // } else if (weekday === 5) {
      //   oldTime50 += time;
      // }

      oldTimeNormal += Number(hourlyRow.timeNormal || 0);
      oldTime50 += Number(hourlyRow.time50 || 0);
    });

    return (
      <>
        <Dialog
          className={employerUpdateMode ? 'hourly-timelog-dialog2 employer-trip-route-dialog' : 'hourly-timelog-dialog2'}
          open={dialogOpen}
        // preventOutsideDismiss={true}
        // onClose={() => {
        //   closeDialog(false);
        // }}
        >
          <DialogTitle
            style={{
              color: 'var(--mdc-theme-primary)',
              padding: '5px 10px 10px 10px',
              textTransform: 'capitalize',
            }}
          >
            Tuntikirjaus {dayStr}
          </DialogTitle>
          <DialogContent style={{ color: 'white', padding: '0px' }}>
            {/* <div className="hourly-dialog-day-str">{dayStr}</div>
          <div>Valitse työkohde ja sen tunnit.</div>
          <div style={{ marginBottom: '30px' }}>Voit lisätä useamman ajanjakson.</div> */}
            {hourlyLogRows.length < 1 && this.renderDescription()}
            {hourlyLogRows.length > 0 && <div style={{ borderBottom: '1px #FFC900 solid' }} />}
            {/* {hourlyLogRows.map((workHour, index) => this.addHourlyRowDialog(workHour, workNumbers, index))} */}

            {hourlyLogRows.map((workHour) => (
              <HourlyTimelogRowEditable
                key={workHour.id || 'creating'}
                hourlyRow={workHour}
                workTasks={ownWorkTasks}
                editHourlyRow={editHourlyRow}
                deleteHourlyRow={deleteHourlyRow}
                date={date}
                closeDialog={closeDialog}
                oldTimeNormal={oldTimeNormal}
                oldTime50={oldTime50}
              />
            ))}

            <CollapsibleList
              style={{ marginTop: '1rem', padding: '0px 15px' }}
              // key="create_row_collapsible"
              key={`create_row_collapsible_${dayStr}`}
              handle={(
                <SimpleListItem
                  // TRUCATE HERE
                  // text={this.state.selectedWorkNumber ? this.state.selectedWorkNumber : t('timelog.hourly.chooseWorkNumber')}
                  // text={truncate(`${item.workNumber} - ${workNumbers[item.workNumber]}`, 45)}
                  text={hourlyLogRows.length === 0 ? 'Lisää tuntikirjaus' : 'Lisää seuraava tuntikirjaus'}
                  metaIcon="chevron_right"
                  // style={{ border: '1px white solid' }}
                  style={{
                    margin: '15px -15px 15px -15px',
                    borderBottom: '1px solid var(--mdc-theme-primary)',
                    paddingLeft: '10px',
                    paddingRight: '0px',
                    height: 'auto',
                  }}
                  onClick={() => {
                    this.setState({
                      createNewRowCollapsibleOpen: !createNewRowCollapsibleOpen,
                    });
                  }}
                />
              )}
              startOpen={false}
              open={createNewRowCollapsibleOpen}
            >
              <div style={{ height: 'fit-content', padding: '0px' }}>
                <HourlyTimelogForm
                  ref={this.creationFormRef}
                  item={item}
                  workTasks={ownWorkTasks}
                  hourlyRow={new WorkTaskEntry({ status: 0 })}
                  userId={currentUser.id}
                  // workOrderId={workOrder.id}
                  workTaskCreationEnabled={workOrder.settings.employeesCanCreateWorkTasks}
                  createHourlyRow={createHourlyRow}
                  date={date}
                  mode="create"
                  lastHour={lastTask}
                  oldTimeNormal={oldTimeNormal}
                  oldTime50={oldTime50}
                  afterSave={() => this.setState({ createNewRowCollapsibleOpen: false })}
                  setCreateNewButtonEnabled={this.setCreateNewButtonEnabled}
                  // closeDialog={closeDialog}
                  // workOrderParticipants={workOrder.participants}
                  // employeesCanAddFilesToWorkTasks={workOrder.settings.employeesCanAddFilesToWorkTasks}
                  openCreateWorkTaskDialog={this.openCreateWorkTaskDialog}
                  // closeCreateWorkTaskDialog={this.closeCreateWorkTaskDialog}
                />
              </div>
            </CollapsibleList>

          </DialogContent>
          <DialogActions
            style={{
              // Need to specify flex-direction here because for some reason the presence of <HourlyTimelogForm>'s night shift dialog causes the buttons to stack otherwise
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '15px',
              // pointerEvents: 'none',
            }}
          >
            <DialogButton
              // action="close"
              isDefaultAction
              className="accept-button"
              style={{
                borderRadius: '0px',
                pointerEvents: 'auto',
              }}
              onClick={() => closeDialog(false)}
            >
              Takaisin
            </DialogButton>

            {/* A stupid hacky button to save the new HourlyTimelog in <HourlyTimelogForm> from this parent element */}
            {createNewRowCollapsibleOpen && (
              <DialogButton
                className="add-hourly-row-button-2"
                onClick={() => {
                  this.creationFormRef.current.createNew();
                }}
                disabled={!createNewButtonEnabled}
              >
                Tallenna tuntikirjaus
              </DialogButton>
            )}
          </DialogActions>
        </Dialog>

        <WorkTaskEmployeeCreateDialog
          open={createWorkTaskDialogOpen}
          handleClose={this.closeCreateWorkTaskDialog}
          workOrderId={workOrder.id}
          workTask={newWorkTask}
          handleAttachmentAction={this.createOrUpdateWorkTask}
          workOrderParticipants={workOrder.participants}
          employeesCanAddFilesToWorkTasks={workOrder.settings.employeesCanAddFilesToWorkTasks}
        />
      </>
    );
  }
}

export default HourlyTimelogDialog;
