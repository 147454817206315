import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // TextField,
  // Button,
  FormControl,
  // FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import moment from 'moment';
// import NativeSelect from '@material-ui/core/NativeSelect';

@inject('uiStore', 't', 'employerWorkOrderStore')
@observer
class BillingStatusDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: null,
      statusChanged: false,
    };
  }

  handleStatusChange = (event) => {
    this.setState({
      selectedStatus: event.target.value,
      statusChanged: true,
    });
  };

  saveBillingStatus() {
    const { handleClose, workOrderBilling, employerWorkOrderStore } = this.props;
    const { selectedStatus } = this.state;
    const updatedWorkOrderBilling = { ...workOrderBilling, status: selectedStatus };

    employerWorkOrderStore.updateBilling(updatedWorkOrderBilling).then(() => {
      handleClose();
    });
  }

  render() {
    const {
      t,
      open,
      handleClose,
      workOrderBilling,
    } = this.props;
    const { statusChanged } = this.state;

    return (
      <Dialog
        onClose={() => {
          this.setState({
            statusChanged: false,
          }, () => {
            handleClose();
          });
        }}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          Laskutustila
          {workOrderBilling?.workOrderName ? ` - ${workOrderBilling.workOrderName}` : ''}
        </DialogTitle>

        <DialogContent>
          <FormControl>
            <RadioGroup
              aria-labelledby="billing-status-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={workOrderBilling?.status}
              onChange={this.handleStatusChange}
            >
              <FormControlLabel value="not_billed" control={<Radio style={{ color: '#FFC900' }} />} label={t('bill.statuses.not_billed')} />
              <FormControlLabel value="billable" control={<Radio style={{ color: '#FFC900' }} />} label={t('bill.statuses.billable')} />
              <FormControlLabel value="billed" control={<Radio style={{ color: '#FFC900' }} />} label={t('bill.statuses.billed')} />
            </RadioGroup>
          </FormControl>
          {/* eslint-disable-next-line camelcase */}
          {workOrderBilling?.user && workOrderBilling?.billed_at && (
            <div style={{ fontSize: '14px', padding: '10px 0' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingTop: '20px',
                  borderTop: 'lightgray 1px solid',
                  flexWrap: 'wrap',
                }}
              >
                <span>
                  {t(`bill.statuses.${workOrderBilling.status}`)}
                  :
                  &nbsp;
                  {workOrderBilling.user}
                </span>
                <span style={{ textTransform: 'capitalize' }}>
                  {moment(workOrderBilling.billed_at).local().format('dd DD.MM.YYYY HH:mm')}
                </span>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: statusChanged && 'space-between' }}>
          {statusChanged && (
            <Button onClick={() => this.saveBillingStatus()} className="employer-accept-button">
              Tallenna
            </Button>
          )}
          <Button
            onClick={() => {
              this.setState({
                statusChanged: false,
              }, () => {
                handleClose();
              });
            }}
            className="employer-reject-button"
          >
            {statusChanged ? 'Peruuta' : 'Sulje'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default BillingStatusDialog;
