import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell } from '@rmwc/grid';
import {
  Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';
import { isEqual } from 'lodash';


const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const identifierOneValidate = (name) => {
  if (!name) {
    return 'Tunniste 1 on pakollinen';
  }
  return null;
};


// const streetValidate = (street) => {
//   if (!street) {
//     return 'Katuosoite on pakollinen';
//   }
//   return null;
// };

// const cityValidate = (city) => {
//   if (!city) {
//     return 'Paikkakunta on pakollinen';
//   }
//   return null;
// };

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  identifierOne: (identifierOne) => identifierOneValidate(identifierOne),
  // street: (street) => streetValidate(street),
  // city: (city) => cityValidate(city),
};

@inject('certificateStore', 'loginStore', 't')
@observer
class CertificateTypeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.purchaser,
      // id: props.id,
      // name: props.name,
      // purchaser: props.purchaser,
      errors: {},
      inputChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { certificateType } = this.props;

    if (!isEqual(prevProps.certificateType, certificateType)) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        certificateType,
        errors: {},
      });
    }
  }

  addTextField(attr, label, required, textarea) {
    const { certificateType, errors } = this.state;

    return (
      <TextField {...{
        className: 'employer-dialog-input',
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        rows: 5,
        invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState((prevState) => ({
            certificateType: { ...prevState.certificateType, [attr]: value },
            inputChanged: true,
          }));

          delete errors[attr];
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        /* eslint-disable-next-line react/destructuring-assignment */
        value: certificateType?.[attr] != null ? certificateType[attr] : '',
      }}
      />
    );
  }

  renderActionButtons() {
    const {
      inputChanged,
      certificateType,
    } = this.state;

    if (!inputChanged && !certificateType?.identifierOne) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
    return (
      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <DialogButton
          type="button"
          className="employer-accept-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          // onClick={() => {
          //   this.handleValidate(name);
          // }}
          action={certificateType?.identifierOne ? 'accept' : null}
        >
          Tallenna
        </DialogButton>

        <DialogButton
          type="button"
          className="employer-reject-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          action="close"
        >
          Peruuta
        </DialogButton>
      </DialogActions>
    );
  }

  render() {
    const {
      open,
      handleClose,
      saveCertificateType,
    } = this.props;

    const { certificateType } = this.state;

    return (
      <Dialog
        className="modal-size employer-modal"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            let errors = {};

            Object.keys(validate).forEach((key) => {
              /* eslint-disable-next-line react/destructuring-assignment */
              const value = certificateType[key];

              // Calling the attr's validation method
              const error = validate[key](value);

              if (error) {
                errors = { ...errors, [key]: error };
              }
            });

            if (Object.keys(errors).length > 0) {
              this.setState({ errors });
            } else {
              saveCertificateType(certificateType);
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle>
          {certificateType?.typeCategory === 'methodology' ? 'Uusi menetelmä' : 'Uusi perusaine' }
        </DialogTitle>
        <DialogContent>
          <Grid className="employer-work-order">
            <GridCell span={12}>
              {this.addTextField('identifierOne', 'Tunniste 1', true, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('identifierTwo', 'Tunniste 2', false, false)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('description', 'Kuvaus', false, true)}
            </GridCell>
          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default CertificateTypeDialog;
