/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField, ThemeProvider } from '@material-ui/core';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import { IconButton } from '@rmwc/icon-button';
// import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';
import {
  List,
  ListGroup,
  ListItem,
  ListItemMeta,
  ListDivider,
  ListGroupSubheader,
  ListItemText,
} from '@rmwc/list';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { isEqual } from 'lodash';

import { DirectUploadProvider } from 'react-activestorage-provider';

import { buttonColors } from '../../calendar/EmployeeCalendar';
import { handleProgress } from '../../models/user/user-ui-avatar';
import WorkTaskUser from './WorkTaskUser';
import './WorkTaskDialog.css';
import FilePreviewDialog from '../../shared/FilePreviewDialog';
// import TextArea from '../../shared/TextArea';
import moment from '../../utils/moment';

import theme from '../../muiTheme';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const validateIdentifierOne = (identifierOne) => {
  if (!identifierOne) {
    return 'Tunniste 1 on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  identifierOne: (identifierOne) => validateIdentifierOne(identifierOne),
};

@inject('workTaskStore', 'loginStore', 't') // employerWorkOrderStore
@observer
class WorkTaskDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.workTask?.id,
      identifierOne: props.workTask?.identifierOne,
      identifierTwo: props.workTask?.identifierTwo,
      description: props.workTask?.description,
      files: props.workTask?.files || [],
      estimate: props.workTask?.estimate,
      errors: {},
      inputChanged: false,
      // This is used to store the to-be-updated or deleted workTaskUsers
      updatingWorkTaskUsers: [],
      // These are used to store files that were selected for PDF generation
      checkedFiles: [],
      // Kept separate from checkedFiles to prevent errors if there's multiple PDF generations going on at once
      generatingPdfFiles: [],
      parentId: props.workTask?.parentId,
      workTaskUsers: props.workTask?.workTaskUsers || [],
      workOrderParticipants: this.prunedWorkOrderParticipants(props.workTask?.workTaskUsers),
      showFilePreviewDialog: false,
    };
  }

  // Is is probably an overcomplicated way of ensuring that the dialog's state (the opened work task) stays up-to-date
  // Figure out a better solution, preferrably from an older edit dialog implementation
  componentDidUpdate(prevProps) {
    const { workTask } = this.props;
    // Does this actually work? prevWorkTask is always undefined?
    const { prevWorkTask } = prevProps;
    const { id, files } = this.state;

    if (!isEqual(workTask, prevWorkTask) && (workTask.id !== id || workTask?.files?.length !== files?.length)) {
      this.setState({
        id: workTask.id,
        identifierOne: workTask.identifierOne,
        identifierTwo: workTask.identifierTwo,
        description: workTask.description,
        parentId: workTask.parentId,
        files: workTask.files,
        estimate: workTask.estimate,
        inputChanged: false,
        workTaskUsers: workTask.workTaskUsers || [],
        workOrderParticipants: this.prunedWorkOrderParticipants(workTask.workTaskUsers),
        updatingWorkTaskUsers: [],
        errors: {},
      });
    }
  }

  prunedWorkOrderParticipants(workTaskUsers = []) {
    // We want to remove users already added to workTaskUsers from the workOrderParticipants so that they can't be added twice
    const { workOrderParticipants = [] } = this.props;
    const workTaskUserIds = workTaskUsers.map((workTaskUser) => workTaskUser.user.id);
    const tempWorkOrderParticipants = [...workOrderParticipants].filter((participant) => !workTaskUserIds.includes(participant.id));


    this.setState({ workOrderParticipants: tempWorkOrderParticipants });
    return tempWorkOrderParticipants;
  }

  handleValidate(identifierOne) {
    let errors = {};

    // Not properly dynamic, you have to define the validate object but also have the values are parameters
    // Could be made better somehow? Get values from state using keys from the validate object?
    Object.keys(validate).forEach((key) => {
      const error = validate[key](identifierOne);

      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    this.setState({
      errors: { ...errors },
    });

    return errors;
  }

  renderListGroup(category) {
    const { workOrderParticipants } = this.state;
    const participantIds = [];
    const uniqueParticipants = [];

    // Only one checkbox per user even though they might have multiple invitations
    workOrderParticipants.forEach((user) => {
      if (!participantIds.includes(user.id)) {
        participantIds.push(user.id);
        uniqueParticipants.push(user);
      }
    });

    return (
      <Elevation
        z={5}
        style={{ borderRadius: '0px 0px 3px 3px' }}
      >
        <List
          style={{
            marginTop: '10px',
            color: 'black',
            padding: '0px',
            // minHeight: '250px',
          }}
        >
          <ListGroup>
            <ListGroupSubheader
              style={{
                backgroundColor: '#FFC900', // colors[category].background,
                color: '#000000', // colors[category].font,
                margin: '0px',
                padding: '12px 16px',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              <span>
                {/* {t(`work_order.availability.${category}`)} */}
                Lisää tekijöitä
              </span>
            </ListGroupSubheader>

            {uniqueParticipants?.map((user, index) => (
              <React.Fragment
                key={`${category}-${user.id}`}
              >
                {this.renderListItem(user, index)}
                {index !== workOrderParticipants.length - 1 && <ListDivider />}
              </React.Fragment>
            ))}
          </ListGroup>
        </List>
      </Elevation>
    );
  }

  renderWorkTaskUsers() {
    const { workTaskUsers, updatingWorkTaskUsers } = this.state;

    // Filter workTaskUsers, we do not want to render items that are found in the updating list (going to be deleted)
    let filteredWorkTaskUsers = [...workTaskUsers];
    if (updatingWorkTaskUsers.length > 0) {
      const updatingWorkTaskUsersIds = [...updatingWorkTaskUsers].map((workTaskUser) => workTaskUser.user.id);
      filteredWorkTaskUsers = filteredWorkTaskUsers.filter((workTaskUser) => !updatingWorkTaskUsersIds.includes(workTaskUser.user.id));
    }

    if (filteredWorkTaskUsers.length > 0) {
      return (
        <Elevation
          z={5}
          style={{ borderRadius: '0px 0px 3px 3px' }}
        >
          <List
            twoLine
            style={{
              marginTop: '10px',
              color: 'black',
              paddingTop: '0px',
              // minHeight: '250px',
            }}
          >
            <ListGroup>
              <ListGroupSubheader
                style={{
                  backgroundColor: '#7CD715',
                  color: '#000000',
                  margin: '0px',
                  padding: '12px 16px',
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                <span>
                  {/* {t(`work_order.availability.invited`)} */}
                  Työn tekijät
                </span>
              </ListGroupSubheader>

              {filteredWorkTaskUsers.map((workTaskUser, index) => (
                <React.Fragment
                  key={`workTaskUser-${workTaskUser.user.id}`}
                >
                  {this.renderWorkTaskUser(workTaskUser, index)}
                  {index !== workTaskUsers.length - 1 && <ListDivider />}
                </React.Fragment>
              ))}
            </ListGroup>
          </List>
        </Elevation>
      );
    }
    return null;
  }

  renderWorkTaskUser(workTaskUser, index) {
    // const { workTaskUsers } = this.state;
    // const foundWorkTaskUser = workTaskUsers.find((task) => task.user.id === workTaskUser.user.id);

    return (
      <ListItem
        key={index}
        // className="availability-list-item"
        style={{
          overflow: 'visible',
        }}
      >
        <Icon
          style={{
            width: '40px'
          }}
          icon={
            <div
              style={{
                background: 'limegreen',
                width: '24px',
                height: '24px',
                borderRadius: '100px'
              }}
            />
          }
        />
        <ListItemText style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          {`${workTaskUser.user.name}`}
        </ListItemText>
        <ListItemMeta>
          <IconButton
            type='button'
            onClick={() => this.deleteWorkTaskUser(workTaskUser)}
            style={{ color: '#645F5F' }}
            icon="delete_outlined"
          >
          </IconButton>
        </ListItemMeta>
      </ListItem>
    );
  }

  deleteWorkTaskUser(workTaskUser) {
    this.setState((prevState) => ({
      updatingWorkTaskUsers: [...prevState.updatingWorkTaskUsers, { ...workTaskUser, _destroy: 1 }],
      inputChanged: true,
    }));
  }

  renderListItem(user, index) {
    const { updatingWorkTaskUsers } = this.state;

    const foundPerson = updatingWorkTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id);
    let checked = foundPerson ? true : false;

    return (
      <ListItem
        key={index}
        className="availability-list-item"
        style={{
          overflow: 'visible',
          height: 'initial',
          minHeight: '48px',
        }}
        onClick={() => {
          this.handleWorkTaskUserCheckbox(checked, user);
        }}
      >
        <Checkbox checked={checked} />
        {`${user.firstName} ${user.lastName}`}
      </ListItem>
    );
  }

  handleWorkTaskUserCheckbox(checked, user) {
    const { updatingWorkTaskUsers } = this.state;
    const tempWorkTaskUsers = [...updatingWorkTaskUsers];

    if (!checked) {
      tempWorkTaskUsers.push(new WorkTaskUser({ user: { id: user.id } }));
      this.setState({
        updatingWorkTaskUsers: tempWorkTaskUsers,
        inputChanged: true,
      });
    } else {
      const remainingWorkTaskUsers = tempWorkTaskUsers.filter((workTaskUser) => workTaskUser.user.id !== user.id);
      this.setState({
        updatingWorkTaskUsers: remainingWorkTaskUsers,
      });
    }
  }

  handleGeneratePDF = (id, checkedFiles, workTask, callback = null) => {
    const { workTaskStore, deleteWorkTaskFileFromState } = this.props;
    this.setState({
      generatingPdf: true,
    });

    const generatePromise = fromPromise(new Promise((resolve, reject) => workTaskStore.generatePDF(workTask.id, workTask.parentId, workTask.workOrderId, checkedFiles, resolve, reject)));
    when(
      () => generatePromise.state !== "pending",
      () => {
        generatePromise.case({
          pending: () => {
          },
          rejected: (err) => {
            this.setState({
              generatingPdf: false,
            }, () => {
              if (callback) {
                callback(checkedFiles);
              }
            });
            // Render snackbar from a prop method here
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: () => {
            // Remove the deleted files used to generate the PDF from the state
            deleteWorkTaskFileFromState(workTask.id, workTask.parentId, checkedFiles, this.generatePdfComplete);
          }
        })
      }
    )
  }

  generatePdfComplete = () => {
    this.setState({
      generatingPdf: false,
    })
  }

  attachmentUpload(disabled, data, inputRef) {
    const { t, handleAttachmentAction, id } = this.props;
    const { files, checkedFiles } = this.state;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={true}
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;
            const body = {
              ...data,
              files: signedIds,
            };

            handleAttachmentAction(body);
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t)}

            <ListItemMeta
              style={{
                display: 'flex',
                justifyContent: checkedFiles.length !== 0 ? 'space-between' : 'end',
                marginTop: '20px',
              }}
            >
              {checkedFiles.length !== 0 && (
                <Button
                  {...{
                    type: 'button',
                    label: "Luo PDF",
                    icon: 'picture_as_pdf',
                    outlined: true,
                    theme: 'secondary',
                    // outlined: true,
                    onClick: () => {
                      this.handleGeneratePDF(id, checkedFiles, { ...data, files }, this.removeFromFiles);
                      this.setState((prevState) => ({
                        checkedFiles: [],
                        // Appropriate emptying of the generatingPdfFiles will happen in the handleGeneratePdf callback
                        generatingPdfFiles: [...prevState.generatingPdfFiles, ...prevState.checkedFiles],
                      }));
                    },
                    style: {
                      // color: buttonColors.green.color,
                      borderRadius: '0px',
                      fontSize: '.75rem',
                      textTransform: 'none',
                    },
                  }}
                />
              )}
              <Button {...{
                disabled,
                type: 'button',
                label: 'Lisää tiedosto',
                icon: 'description',
                outlined: true,
                theme: 'secondary',
                // className: 'ml-1',
                onClick: () => inputRef.current.click(),
                style: {
                  // color: buttonColors.green.color,
                  borderRadius: '0px',
                  fontSize: '.75rem',
                  textTransform: 'none',
                },
              }}
              />
            </ListItemMeta>
          </>
        )}

        required
      />
    );
  }

  removeFromFiles = (signedIds) => {
    this.setState((prevState) => ({
      checkedFiles: prevState.checkedFiles.filter(item => signedIds.includes(item)),
      generatingPdfFiles: prevState.generatingPdfFiles.filter(item => signedIds.includes(item)),
    }));
  }

  addTextField(attr, label, required, styles, multiline = false) {
    const { errors } = this.state;

    const textFieldStyles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
    };

    return (
      <ThemeProvider theme={theme}>
        <TextField {...{
          style: { ...styles, ...textFieldStyles },
          className: 'employer-work-order',
          label,
          required,
          name: attr,
          multiline,
          variant: 'filled',
          invalid: errors[attr],
          helperText: errors[attr] && errors[attr],
          onChange: (event) => {
            const { value } = event.target;
            delete errors[attr];
            this.setState({
              [attr]: value,
              inputChanged: true,
              errors: { ...errors }
            });
          },
          fullWidth: true,
          inputProps: {
            style: {
              padding: '27px 12px 10px',
         }},
          value: this.state[attr] != null ? this.state[attr] : '',
        }}
      />
      </ThemeProvider>
    );
  }

  // addTextArea(attr, label, required, styles = null) {
  //   return (
  //     <TextArea
  //       className="mui-textfield-employer"
  //       required={required}
  //       label={label}
  //       styles={styles}
  //       value={this.state[attr] != null ? this.state[attr] : ''}
  //       onChange={(event) => {
  //         const { value } = event.target;
  //         this.setState({
  //           [attr]: value,
  //           inputChanged: true,
  //         });
  //       }}
  //     />
  //   );
  // }

  addNumberField(attr, label, required, styles) {
    const { errors } = this.state;

    const textFieldStyles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
    };

    return (
      <ThemeProvider theme={theme}>
        <TextField {...{
          style: { ...styles, ...textFieldStyles },
          className: 'employer-work-order',
          label,
          required,
          name: attr,
          variant: 'filled',
          invalid: errors[attr],
          helperText: errors[attr] && errors[attr],
          type: 'number',
          step: 'any',
          onChange: (event) => {
            const { value } = event.target;
            delete errors[attr];
            this.setState({
              [attr]: value,
              inputChanged: true,
              errors: { ...errors }
            });
          },
          inputProps: {
            style: {
              padding: '27px 12px 10px',
              height: '',
         }},
          // onBlur: (event) => {
          //   required && this.handleBlur(attr, event)
          // },
          // rootProps: { ripple: false },
          onKeyPress: handleEnter,
          value: this.state[attr] != null ? this.state[attr] : '',
        }}
      />
      </ThemeProvider>
    );
  }

  updateChecked(signedId) {
    const { checkedFiles } = this.state;
    if (checkedFiles.includes(signedId)) {
      //
      this.setState((prevState) => ({
        checkedFiles: prevState.checkedFiles.filter((item) => item !== signedId),
      }));
    } else {
      this.setState((prevState) => ({
        checkedFiles: [...prevState.checkedFiles, signedId],
      }));
    }
  }

  renderUploadedFile(file) {
    const { confirmDelete, workOrderId } = this.props;
    const { generatingPdf, generatingPdfFiles, checkedFiles, parentId, id } = this.state;

    const filename = file.filename;
    const fileURL = file.fileURL;
    const signedId = file.signedId;
    const fileExtension = filename.split('.').pop();
    const extensionsForPDF = ['jpg', 'png', 'jpeg'];

    const createdAt = moment(file.createdAt).local().format('dd DD.MM. HH:mm');

    // We want to hide checked files during PDF generation
    // generating PDF = true
    // AND
    // generatingPdfFiles.includes
    if ((generatingPdfFiles.includes(file?.signedId) && generatingPdf) === false) {
      return (
        <GridInner key={fileURL}>
          <GridCell
            desktop={8}
            phone={3}
            style={{
              display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // alignSelf: 'center',
            }}
          >
            {extensionsForPDF.includes(fileExtension.toLowerCase())
              ? (
                <Checkbox
                  checked={checkedFiles.includes(signedId)}
                  onChange={() => {
                    this.updateChecked(signedId);
                  }}
                />
              )
              : (
                <div style={{ height: '40px', width: '40px' }}></div>
              )
            }
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <a
                // href={fileURL}
                // download={filename}
                style={{
                  color: 'var(--mdc-theme-primary, #6200ee)',
                  cursor: 'pointer',
                  // fontSize: '14px'
                }}
                onClick={() => {
                  this.setState({
                    fileUrl: fileURL,
                    fileName: filename,
                    showFilePreviewDialog: true,
                  })
                }}
              >
                {filename}
              </a>

              <span
                style={{ color: 'black', textTransform: 'capitalize', fontSize: '12px', marginTop: '-5px' }}
              >
                {createdAt}
              </span>
            </div>
          </GridCell>
          <GridCell
            desktop={3}
            phone={1}
            style={{
              justifySelf: 'flex-end',
            }}
          >
            {signedId && <IconButton
              type="button"
              style={{
                color: '#645F5F',
              }}
              icon="delete_outlined"
              onClick={() => {
                confirmDelete('file', id, workOrderId, filename, signedId, parentId);
              }}
            />}
            {!signedId && <div style={{ height: '48px' }} />}
          </GridCell>
        </GridInner>
      );
    } else {
      return null;
    }
  }

  renderBottomButtons() {
    const {
      inputChanged,
      id,
      identifierOne,
    } = this.state;

    if (inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(identifierOne);
            // }}
            action="accept"
          >
            Tallenna
          </DialogButton>

          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      fileUrl: null,
      fileName: null,
    })
  }

  confirmDeleteWorkTask() {
    this.setState({ showDeleteDialog: true });
  }

  closeDeleteDialog() {
    this.setState({
      showDeleteDialog: false,
      errorResponse: null,
    })
  }

  handleDeleteWorkTask() {
    const { workTaskStore, workOrderId, handleClose, deleteWorkTaskState } = this.props;
    const { id } = this.state;

    workTaskStore.delete(workOrderId, id).then((res) => {
      if (res === 'entries-error') {
        this.setState({
          errorResponse: 'Poisto epäonistui. Työllä on kirjauksia.',
        });
      } else {
        deleteWorkTaskState(id);
        this.closeDeleteDialog();
        handleClose();
      }
    });
  }

  renderDeleteWorkPeriodDialog() {
    const { showDeleteDialog, errorResponse } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeleteDialog}
        onClose={() => this.closeDeleteDialog()}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <p>Haluatko varmasti poistaa työn?</p>
          {errorResponse && (
            <p style={{ color: 'red' }}>
              {errorResponse}
            </p>
          )}
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => this.handleDeleteWorkTask()}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => this.closeDeleteDialog()}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { open, styles, workOrderId, handleAttachmentAction, handleClose, workTask } = this.props;
    const { id, identifierOne, identifierTwo, description, files, estimate, parentId, workOrderParticipants, updatingWorkTaskUsers, generatingPdf, errors, showFilePreviewDialog, fileUrl, fileName } = this.state;
    const inputRef = React.createRef();
    const sortedFiles = files?.sort(this.sortByCreatedAtAsc);

    return (
      <>
        <Dialog
          className="modal-size employer-modal"
          open={open}
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              // const error = validate['identifierOne'];

              // A hack:
              // Errors are also set into the state but it is async and not fast enough, so we use the returned errors directly here
              const tempErrors = this.handleValidate(identifierOne);

              if (Object.keys(tempErrors).length === 0) {
                // this.handleSubmit({ ...this.state, parentId: workTask?.parentId, workOrderId: workOrderId });
                let signedIds = sortedFiles?.map((file) => {
                  return file['signedId'];
                });
                handleAttachmentAction({ ...this.state, parentId: workTask?.parentId, workOrderId: workOrderId, files: signedIds, workTaskUsers: updatingWorkTaskUsers });
                // Close the dialog through the parent component
                handleClose();
              }
            } else {
              // Close the dialog through the parent component
              handleClose();
            }
          }}
        >
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>
              <div>{id ? 'Muokkaa työtä' : 'Uusi työ'}</div>
              {id && (
                <IconButton
                  type='button'
                  onClick={() => this.confirmDeleteWorkTask()}
                  style={{ color: '#645F5F' }}
                  icon="delete_outlined"
                >
                </IconButton>
              )}
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid>
              <GridCell
                className="employer-work-order"
                span={12}
              >
                {this.addTextField('identifierOne', 'Tunniste 1', true, styles, false)}
              </GridCell>
              <GridCell
                span={12}
              >
                {this.addTextField('identifierTwo', 'Tunniste 2', false, styles, false)}
              </GridCell>
              <GridCell
                span={12}
              >
                {this.addTextField('description', 'Kuvaus', false, styles, true)}
              </GridCell>
              {/* <GridCell
                span={12}
              >
                {this.addTextArea('description', 'Kuvaus', false, styles)}
              </GridCell> */}

              <GridCell
                span={12}
              >
                {this.addNumberField('estimate', 'Tuntiarvio', false, styles)}
              </GridCell>

              <GridCell
                span={12}
              >
                {sortedFiles?.map((file) => {
                  return this.renderUploadedFile(file);
                })}

                {/* TODO: Backwards compatibility thing, REMOVE LATER */}
                {/* false ==== generatingPdf = true AND checkedFiles.includes TRUE */}
                {generatingPdf && <div>Luodaan PDF-tiedostoa...</div>}
                {/* {file && this.renderUploadedFile(file)} */}

                {this.attachmentUpload(
                  (identifierOne === ''),
                  {
                    id,
                    identifierOne,
                    identifierTwo,
                    description,
                    workOrderId,
                    parentId,
                    workTaskUsers: updatingWorkTaskUsers,
                    estimate,
                  },
                  inputRef,
                )}
                {/* {errors.attachments && (
                <p
                  style={{
                    color: 'red',
                    padding: '0px 16px',
                    fontSize: '.75rem',
                  }}
                >
                  Sisältö tai tiedosto on pakollinen
                </p>
              )} */}
              </GridCell>
              <GridCell span={12}>
                {/* Conditional rendering check is inside renderWorkTaskUsers */}
                {this.renderWorkTaskUsers()}
              </GridCell>
              <GridCell span={12}>
                {workOrderParticipants.length > 0 && this.renderListGroup()}
              </GridCell>

              {/* {inputChanged && (
            <GridCell
                span={12}
              >
                {this.addCheckBox('sendAsSms', t('work_order.labels.sendAsSms'))}
              </GridCell>
            )} */}

            </Grid>
          </DialogContent>
          {this.renderBottomButtons()}
        </Dialog>

        <FilePreviewDialog
          open={showFilePreviewDialog}
          fileURL={fileUrl}
          fileName={fileName}
          onClose={this.closeFilePreviewDialog}
        />

        {this.renderDeleteWorkPeriodDialog()}
      </>
    )
  }
}

export default WorkTaskDialog;
