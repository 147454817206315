import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
// import MUIDataTable from "mui-datatables";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import LoadingSpinner from '../shared/LoadingSpinner';
import EmployerTimelogTable from '../employer-timelog/employerTimelogTable';
import moment from '../utils/moment';

import './salaryPeriodProcessing.css';
import EmployerAbsenceTable from '../employer-absences/EmployerAbsenceTable';
import CalendarEntry from '../models/CalendarEntry';

// EmployeesTable.jsx tableSelectionButton()
const viewSelectionButton = (t, view, activeView, label, onClick) => (
  <Button
    className={activeView === view ? 'selected-table-selection-button' : 'table-selection-button'}
    onClick={() => onClick(view)}
    type="button"
    key={`${view}-button`}
  >
    {label}
    {/* {t(`employer_table.columnPresets.${preset}`)} */}
  </Button>
);

@inject('t', 'uiStore', 'timelogStore', 'absenceStore', 'salaryPeriodStore')
@observer
class SalaryPeriodHistory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showAddNewWorkOrder: false,
      // billingStatusDialogOpen: false,
      // openBilling: null,
      // workOrdersPerPage: this.defaultWorkOrdersPerPage,
      selectedSalaryPeriod: null,
      columnVisibility: {},
      currentWorkHoursWithMeta: null,
      currentAbsenceEntries: null,
      activeView: 'work-hour-report',
      salaryPeriodCategories: null,
      selectedSalaryPeriodCategory: null,
      // downloadingZip: false,
    };
  }

  componentDidMount() {
    // const { employerWorkOrderStore, uiStore: { currentView } } = this.props;

    // this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage, this.defaultSort);
    // if (employerWorkOrderStore.billingWorkOrderCache) {
    //   this.setState({
    //     workOrders: employerWorkOrderStore.billingWorkOrderCache.workOrders,
    //     workOrdersCount: employerWorkOrderStore.billingWorkOrderCache.count,
    //     offset: employerWorkOrderStore.billingWorkOrderCache.offset,
    //     workOrdersPerPage: employerWorkOrderStore.billingWorkOrderCache.limit,
    //     sort: employerWorkOrderStore.billingWorkOrderCache.sort,
    //   });
    // } else {
    //   this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage, this.defaultSort);
    // }

    // this.queryCurrentSalaryPeriods();
    this.queryAllSalaryPeriodsGroupedByCategory();
  }

  setColumnVisibility = (columnVisibility) => {
    this.setState({ columnVisibility });
  }

  changeView = (view) => {
    this.setState({
      activeView: view,
    });
  }

  // changeSorting = (column, direction) => {
  //   let sort;

  //   if (column === 'billingId') {
  //     sort = `work_order.billing_id-${direction}`;
  //   } else if (!column.includes('.')) {
  //     // Assuming a work order column by default, we need the 'dot notation' here for postgreSQL query generation
  //     sort = `work_order.${column}-${direction}`;
  //   } else {
  //     sort = `${column}-${direction}`;
  //   }

  //   this.setState({
  //     sort,
  //   }, () => {
  //     // Re-query using the new sorting, starting at offset 0
  //     this.queryWorkOrders(0, null, sort);
  //   });
  // }

  selectSalaryPeriod = (selectedId, nonInput = false) => {
    const { salaryPeriodsGroupedByCategory, selectedSalaryPeriod, selectedSalaryPeriodCategory: { id: categoryId } } = this.state;

    // With the nonInput parameter, we ignore the "did the dropdown value actually change" check
    if (selectedId !== selectedSalaryPeriod?.id || nonInput) {
      const foundSalaryPeriod = salaryPeriodsGroupedByCategory[categoryId].find((period) => period.id === selectedId);

      this.setState({
        selectedSalaryPeriod: foundSalaryPeriod,
      }, () => {
        this.queryWorkHours(foundSalaryPeriod);
        this.queryAbsences(foundSalaryPeriod);
      });
    }
  }

  selectSalaryPeriodCategory = (selectedId) => {
    const { salaryPeriodCategories, selectedSalaryPeriodCategory, salaryPeriodsGroupedByCategory } = this.state;
    if (selectedId !== selectedSalaryPeriodCategory.id) {
      const foundSalaryPeriodCategory = salaryPeriodCategories.find((cat) => cat.id === selectedId);

      this.setState({
        selectedSalaryPeriod: salaryPeriodsGroupedByCategory[foundSalaryPeriodCategory.id][0],
        selectedSalaryPeriodCategory: foundSalaryPeriodCategory,
      }, () => {
        const { selectedSalaryPeriod } = this.state;
        this.selectSalaryPeriod(selectedSalaryPeriod.id, true);
      });
    }
  }

  // handleDownloadZip = async () => {
  //   const { selectedSalaryPeriod } = this.state;
  //   const { salaryPeriodStore } = this.props;

  //   this.setState({
  //     downloadingZip: true,
  //   });

  //   const fromFormatted = moment(selectedSalaryPeriod.from, 'YYYY-MM-DD').format('DD.MM.YY');
  //   const toFormatted = moment(selectedSalaryPeriod.to, 'YYYY-MM-DD').format('DD.MM.YY');

  //   const downloadPromise = fromPromise(new Promise((resolve, reject) => salaryPeriodStore.downloadPayrollAdminReportsZip(selectedSalaryPeriod.id, resolve, reject)));
  //   when(
  //     () => downloadPromise.state !== 'pending',
  //     () => {
  //       downloadPromise.case({
  //         pending: () => {
  //           this.setState({ downloadingZip: true });
  //         },
  //         rejected: (err) => {
  //           this.setState({ downloadingZip: false });
  //           console.error('ERROR: ', err);
  //         },
  //         fulfilled: (res) => {
  //           // Thanks to requests.js getFile (GET that expects blobs) and Rails controller's send_data, the response is a blob
  //           const url = window.URL.createObjectURL(new Blob([res]));
  //           const link = document.createElement('a');
  //           link.href = url;
  //           link.setAttribute('download', `Palkkahallinnon aineisto ${fromFormatted} - ${toFormatted}.zip`);

  //           // Append to html link element page
  //           document.body.appendChild(link);

  //           // Start download
  //           link.click();

  //           // Clean up and remove the link
  //           link.parentNode.removeChild(link);

  //           // Update the salary period state
  //           this.setState({
  //             downloadingZip: false,
  //           });
  //         },
  //       });
  //     },
  //   );
  // }

  queryWorkHours(salaryPeriod) {
    const { timelogStore } = this.props;

    this.setState({
      currentWorkHoursWithMeta: null,
    }, () => {
      // Only query the closed work hours (status 2, processed salary period)
      timelogStore.getEmployerTimelogsByDates(salaryPeriod, 2).then((data) => {
        // const salaryPeriods = [];
        this.setState({
          currentWorkHoursWithMeta: data,
        });
      });
    });
  }

  queryAbsences(salaryPeriod) {
    const { absenceStore } = this.props;

    this.setState({
      currentAbsenceEntries: null,
    }, () => {
      absenceStore.getEmployerAbsenceEntriesBySalaryPeriod(salaryPeriod).then((data) => {
        // const salaryPeriods = [];
        this.setState({
          currentAbsenceEntries: data.map((entry) => ({ ...entry, calendar_entry: CalendarEntry.fromJsonProperties(entry.calendar_entry) })),
        });
      });
    });
  }

  queryAllSalaryPeriodsGroupedByCategory() {
    const { salaryPeriodStore } = this.props;

    const groupedSalaryPeriodsPromise = fromPromise(new Promise((resolve, reject) => salaryPeriodStore.getClosedSalaryPeriodsGroupedByCategory(resolve, reject)));

    when(
      () => groupedSalaryPeriodsPromise.state !== 'pending',
      () => {
        groupedSalaryPeriodsPromise.case({
          pending: () => {
            // this.setState({ isLoading: true });
          },
          rejected: (err) => {
            // this.setState({ isLoading: false });
            console.error('ERROR: ', err);
          },
          fulfilled: (res) => {
            const defaultSalaryPeriodCategory = res.salary_period_categories.find((cat) => cat.is_default);
            const defaultSalaryPeriod = res.grouped_salary_periods[defaultSalaryPeriodCategory.id][0];

            this.setState({
              // isLoading: false,
              salaryPeriodCategories: res.salary_period_categories,
              salaryPeriodsGroupedByCategory: res.grouped_salary_periods,
              selectedSalaryPeriodCategory: defaultSalaryPeriodCategory,
              selectedSalaryPeriod: defaultSalaryPeriod,
            }, () => {
              const { selectedSalaryPeriod } = this.state;
              this.selectSalaryPeriod(selectedSalaryPeriod.id, true);
            });
          },
        });
      },
    );
  }

  renderMenuItem(salaryPeriod) {
    const { salaryPeriodCategories } = this.state;
    const foundCategory = salaryPeriodCategories.find((cat) => cat.id === salaryPeriod.salary_period_category_id);
    if (foundCategory.name_short) {
      return (
        `${foundCategory.name_short}, ${moment(salaryPeriod.from).format('dd D.M.')} - ${moment(salaryPeriod.to).format('dd D.M.')}`
      );
    }
    return (
      `${moment(salaryPeriod.from).format('dd D.M.')} - ${moment(salaryPeriod.to).format('dd D.M.')}`
    );
  }

  render() {
    const { t, uiStore: { currentUser: { accountInfo: { absencesEnabled } } } } = this.props;
    const {
      selectedSalaryPeriod,
      columnVisibility,
      currentWorkHoursWithMeta,
      currentAbsenceEntries,
      activeView,
      salaryPeriodCategories,
      selectedSalaryPeriodCategory,
      salaryPeriodsGroupedByCategory,
      // downloadingZip,
    } = this.state;

    return (
      <>
        <div className="salary-period-processing-actions" style={{ justifyContent: 'space-between' }}>
          <div>
            {salaryPeriodCategories && salaryPeriodCategories.length > 1 && (
              <FormControl style={{ marginRight: '30px' }}>
                <InputLabel id="demo-simple-select-label">
                  Rytmi
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSalaryPeriodCategory?.id}
                  onChange={(event) => {
                    this.selectSalaryPeriodCategory(event.target.value);
                  }}
                // disabled
                >
                  {salaryPeriodCategories.map((category) => (
                    <MenuItem
                      value={category.id}
                      style={{ display: 'block', padding: '10px' }}
                      key={category.id}
                    >
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {salaryPeriodsGroupedByCategory && selectedSalaryPeriodCategory && (
              <FormControl>
                <InputLabel id="demo-simple-select-label">
                  Palkkakausi
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedSalaryPeriod?.id}
                  onChange={(event) => {
                    this.selectSalaryPeriod(event.target.value);
                  }}
                // disabled
                >
                  {salaryPeriodsGroupedByCategory[selectedSalaryPeriodCategory.id].map((salaryPeriod) => (
                    <MenuItem
                      value={salaryPeriod.id}
                      style={{ display: 'block', padding: '10px' }}
                      key={salaryPeriod.id}
                    >
                      {`${moment(salaryPeriod.from, 'YYYY-MM-DD').format('DD.MM.YY')} - ${moment(salaryPeriod.to, 'YYYY-MM-DD').format('DD.MM.YY')}`}
                      {/* {`${salaryPeriod.nameShort}, ${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`} */}
                      {/* {this.renderMenuItem(salaryPeriod)} */}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>

          {/* <Button
            className="employer-accept-button"
            style={{ height: '36px', width: '180px', alignSelf: 'end' }}
            // onClick={() => this.setState({ showLockSalaryPeriodDialog: true })}
            onClick={() => this.handleDownloadZip()}
            disabled={downloadingZip}
          >
            {downloadingZip ? <LoadingSpinner color="black" size="mini" /> : 'Lataa aineisto'}
          </Button> */}
        </div>

        <div style={{ display: 'inline-block', width: '100%', marginTop: '20px' }}>
          {viewSelectionButton(t, 'work-hour-report', activeView, 'Tuntiraportti', this.changeView)}
          {absencesEnabled && viewSelectionButton(t, 'absence-report', activeView, 'Poissaoloraportti', this.changeView)}
        </div>

        {/* eslint-disable-next-line camelcase */}
        {selectedSalaryPeriod?.salary_period_message_content && (
          <div style={{ margin: '20px' }}>
            <div style={{ fontWeight: 'bold', marginBottom: '10px', fontSize: '16px' }}>Lisätietoa palkanlaskijalle:</div>
            <div style={{ whiteSpace: 'pre', fontSize: '14px' }}>
              {/* eslint-disable-next-line camelcase */}
              {selectedSalaryPeriod?.salary_period_message_content}
            </div>
          </div>
        )}

        {activeView === 'work-hour-report' && (
          <div style={{ width: '100%' }}>
            {currentWorkHoursWithMeta && (
              <EmployerTimelogTable
                workHours={currentWorkHoursWithMeta}
                // currentFilters={currentFilters}
                // updateWorkHour={this.updateWorkHourStatusAndComment}
                // rowsSelected={tableRowsSelected}
                // setWorkHoursSelected={this.setWorkHoursSelected}
                // openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                columnVisibility={columnVisibility}
                setColumnVisibility={this.setColumnVisibility}
                // afterUpdate={this.processSelfUpdateResponse}
                salaryPeriods={[]}
                // openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                disableActions
              />
            )}
            {!currentWorkHoursWithMeta && (
              <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            )}
          </div>
        )}

        {activeView === 'absence-report' && (
          <div style={{ width: '100%' }}>
            {currentAbsenceEntries && (
              <EmployerAbsenceTable
                absenceEntries={currentAbsenceEntries}
                // currentFilters={currentFilters}
                // updateWorkHour={this.updateWorkHourStatusAndComment}
                // rowsSelected={tableRowsSelected}
                // setWorkHoursSelected={this.setWorkHoursSelected}
                // openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                // columnVisibility={columnVisibility}
                // setColumnVisibility={this.setColumnVisibility}
                // afterUpdate={this.processSelfUpdateResponse}
                salaryPeriods={[]}
                // openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                disableActions
              />
            )}
            {!currentWorkHoursWithMeta && (
              <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default SalaryPeriodHistory;
