import React from 'react';
import { ThemeProvider } from '@rmwc/theme';

import '@material/theme/dist/mdc.theme.css';

const options = {
  background: '#0a131e',
  error: '#b00020',
  onError: '#fff',
  onPrimary: 'rgba(255,255,255,.87)',
  onSecondary: 'rgba(0,0,0,0.87)',
  onSurface: 'rgba(255,255,255,.87)',
  primary: '#FFC900',
  secondary: '#2F3440',
  surface: '#0a131e',
  accentOrange: '#FF9300',
  accentOrangeDark: '#FF6A00',
  textDisabledOnBackground: 'rgba(255, 255, 255, 0.5',
  textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
  textDisabledOnLight: 'rgba(0, 0, 0, 0.38)',
  textHintOnBackground: 'rgba(255, 255, 255, 0.5',
  textHintOnDark: 'rgba(255, 255, 255, 0.5)',
  textHintOnLight: 'rgba(0, 0, 0, 0.38)',
  textIconOnBackground: 'rgba(255, 255, 255, 0.5',
  textIconOnDark: 'rgba(255, 255, 255, 0.5)',
  textIconOnLight: 'rgba(0, 0, 0, 0.38)',
  textPrimaryOnBackground: 'rgba(255, 255, 255, 1)',
  textPrimaryOnDark: 'rgba(255,255,255, 0.6)',
  textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
  textSecondaryOnBackground: 'rgba(255, 255, 255, 0.7)',
  textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
  textSecondaryOnLight: 'rgba(0, 0, 0, 0.54)',
};

export default function Theme({ children }) {
  return (
    <ThemeProvider
      className="theme-container"
      options={options}
      style={{
        height: '100%',
      }}
    >
      {children}
    </ThemeProvider>
  );
}
