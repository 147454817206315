import { observable, action } from 'mobx';
import moment from 'moment';
import WorkOrderInvitation from '../employer-work-orders/WorkOrderInvitation';

export default class WorkPeriod {
  @observable id;

  @observable calendarEntries;

  @observable name;

  @observable invitationsCount;

  @observable from;

  @observable to;

  @observable invitations = [];

  @observable tempId;

  @observable description;

  @observable _destroy = false;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updatePropertiesFromJson(object) {
    const splitDateRange = object.datetime_range.split('..');

    this.updateProperties({
      id: object.id,
      name: object.name,
      from: moment(splitDateRange[0], 'YYYY-MM-DD HH:mm:ss UTC'),
      to: moment(splitDateRange[1], 'YYYY-MM-DD HH:mm:ss UTC'),
      invitationsCount: object.invitations_count,
      invitations: object.work_order_invitations ? object.work_order_invitations.map(WorkOrderInvitation.fromJsonProperties) : [],
      description: object.description,
    });
  }

  static toJson(o) {
    return {
      id: o.id,
      name: o.name,
      datetime_range: `[${o.from.toDate().toISOString()}, ${o.to.toDate().toISOString()}]`,
      work_order_invitations_attributes: o.invitations ? o.invitations.map(WorkOrderInvitation.toJson) : [],
      invitations_count: o.invitationsCount,
      description: o.description,
      _destroy: o._destroy,
    };
  }


  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const workPeriod = new WorkPeriod({});
    workPeriod.updatePropertiesFromJson(object);
    return workPeriod;
  }

  static fromJson(json) {
    return WorkPeriod.fromJsonProperties(JSON.parse(json));
  }
}
