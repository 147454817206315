import { observable, action } from 'mobx';
import AbsenceEntry from './AbsenceEntry';
import AbsenceType from './AbsenceType';
// import moment from 'moment';
// import WorkOrderInvitation from '../employer-work-orders/WorkOrderInvitation';

export default class AbsencePeriod {
  @observable id;

  @observable absenceEntries;

  @observable name;

  @observable description;

  // This is used to save the values temporarily in absence period dialog before saving them into the generated absence entries
  @observable values = [];

  // @observable _destroy = false;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      name: o.name,
      description: o.description,
      // _destroy: o._destroy,
      absenceType: AbsenceType.fromJsonProperties(o.absence_type),
      absenceEntries: o.absence_entries.map(AbsenceEntry.fromJsonProperties),
    });
  }

  static toJson(o) {
    return {
      id: o.id,
      name: o.name,
      description: o.description,
      // _destroy: o._destroy,
      absence_type_id: o.absenceType.id,
      absence_entries_attributes: o.absenceEntries.map(AbsenceEntry.toJson),
      // Only for employers
      user_id: o.userId,
    };
  }


  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const absencePeriod = new AbsencePeriod({});
    absencePeriod.updatePropertiesFromJson(object);
    return absencePeriod;
  }

  static fromJson(json) {
    return AbsencePeriod.fromJsonProperties(JSON.parse(json));
  }
}
