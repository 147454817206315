import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
import Certificate from '../models/Certificate';
import CertificateType from '../models/CertificateType';

class CertificateStore {
  // @observable users = fromPromise.resolve([]);
  @observable certificates = null;

  @observable certificateFilters = [];

  @observable storedColumns = {};

  @observable certificateTypes = null;

  constructor(uiStore, requests) {
    this.uiStore = uiStore;
    this.requests = requests;
    // this.users = [];
  }

  @action setCertificateFilters(column, filters) {
    this.certificateFilters[column] = filters;
  }

  @action emptyFilters() {
    this.certificateFilters = [];
  }

  // @action setStoredColumns(name, columns = []) {
  //   console.log('SETTING STORED COLUMNS');
  //   console.log('name: ', name);
  //   console.log('columns: ', columns);
  //   // If the name doesn't exist in storedColumns, create a new entry
  //   if (!this.storedColumns[name]) {
  //     console.log('Stored column not found with name');
  //     this.storedColumns[name] = {
  //       name,
  //       columns: [...columns],
  //     };
  //     console.log('Added: ', this.storedColumns[name]);
  //   } else {
  //     console.log('Stored columns found: ', this.storedColumns);
  //     console.log('With name: ', name);

  //     // Update the existing columns for the specified name
  //     const storedColumnArray = this.storedColumns[name].columns;

  //     // Add new columns to the existing array
  //     columns.forEach((column) => {
  //       if (!storedColumnArray.includes(column)) {
  //         storedColumnArray.push(column);
  //       }
  //     });
  //   }
  // }

  // @action removeStoredColumns(name, columnsToRemove) {
  //   if (this.storedColumns[name]) {
  //     const storedColumnArray = this.storedColumns[name].columns;

  //     // Filter out the columns to be removed
  //     const updatedColumns = storedColumnArray.filter((column) => !columnsToRemove.includes(column));
  //     this.storedColumns[name].columns = updatedColumns;
  //   }
  // }

  @action getCertificates() {
    fromPromise(new Promise((resolve) => this.requests.Certificates.getAll().then((json) => {
      // We need to set _private to true in order to properly add relevant info, such as user's details, to the new User object
      const certificates = json.map((certificate) => Certificate.fromJsonProperties(certificate));
      this.certificates = certificates;
      resolve(certificates);
    })));
  }

  @action getCertificateTypes() {
    fromPromise(new Promise((resolve) => this.requests.CertificateTypes.getAll().then((json) => {
      // We need to set _private to true in order to properly add relevant info, such as user's details, to the new User object
      const certificateTypes = json.map((type) => CertificateType.fromJsonProperties(type));
      this.certificateTypes = certificateTypes;
      resolve(certificateTypes);
    })));
  }

  @action create(certificate) {
    return this.requests.Certificates.create(certificate).then((res) => {
      const updatedCertificate = Certificate.fromJsonProperties(res);

      const updatedCertificates = [updatedCertificate, ...this.certificates];
      this.certificates = updatedCertificates;
      return updatedCertificate;
    });
  }

  @action createCertificateType(certificateType) {
    return this.requests.CertificateTypes.create(certificateType).then((res) => {
      const newCertificateType = CertificateType.fromJsonProperties(res);

      const updatedCertificateTypes = [newCertificateType, ...this.certificateTypes];
      this.certificateTypes = updatedCertificateTypes;
      return newCertificateType;
    });
  }

  @action update(certificate) {
    console.log('Updating certificate in store: ', certificate);
    return this.requests.Certificates.update(certificate).then((res) => {
      const updatedCertificate = Certificate.fromJsonProperties(res);

      console.log('Got response in store: ', res);
      console.log('Response model: ', updatedCertificate);

      // Update the certificate in the certificate list
      const foundCertificateIndex = this.certificates.findIndex((oldCert) => oldCert.id === updatedCertificate.id);
      if (foundCertificateIndex !== -1) {
        // Trigger a UI re-render with this.certificates value re-assignment
        const updatedCertificates = [...this.certificates];
        updatedCertificates[foundCertificateIndex] = updatedCertificate;
        this.certificates = updatedCertificates;
      } else {
        console.error('Something went wrong, the updated certificate was not found');
      }

      // Update the current user's certificates here if necessary
      // if (currentUser.id === user.id) {
      //   const regularUserObj = User.fromJsonProperties(res);
      //   currentUser.updateProperties(regularUserObj);
      // }
      return updatedCertificate;
    });
  }

  // @action update(certificate, resolve, reject) {
  //   return this.requests.Certificates[!certificate.id ? 'create' : 'update'](certificate).then((res) => {
  //     const updatedCertificate = Certificate.fromJsonProperties(res);

  //     // Update the certificate in the certificate list
  //     const foundCertificateIndex = this.certificates.findIndex((oldCert) => oldCert.id === updatedCertificate.id);
  //     if (foundCertificateIndex !== -1) {
  //       // Trigger a UI re-render with this.certificates value re-assignment
  //       const updatedCertificates = [...this.certificates];
  //       updatedCertificates[foundCertificateIndex] = updatedCertificate;
  //       this.certificates = updatedCertificates;
  //     } else {
  //       const updatedCertificates = [updatedCertificate, ...this.certificates];
  //       this.certificates = updatedCertificates;
  //     }

  //     resolve(updatedCertificate);
  //   }).catch((err) => reject(err));
  // }

  // @action purgeOtherHelmetImage(user) {
  //   this.requests.Users.purgeHelmetImage(user).then(() => {
  //     user.changeAttribute('helmetImageUrl', null);
  //   });
  // }

  // @action purgeOtherCard(user, card) {
  //   return this.requests.Users.purgeCardEmployer(user, card);
  // }
}

export default CertificateStore;
