import { observable, action, toJS } from 'mobx';
import { fromPromise } from 'mobx-utils';
import User from '../models/User';

class EmployerUserStore {
  // @observable users = fromPromise.resolve([]);
  @observable users = null;

  @observable employeesFilters = [];

  @observable storedColumns = [];

  constructor(uiStore, requests) {
    this.uiStore = uiStore;
    this.requests = requests;
    // this.users = [];
  }

  @action setEmployeesFilters(column, filters) {
    this.employeesFilters[column] = filters;
  }

  @action emptyFilters() {
    this.employeesFilters = [];
  }

  @action setStoredColumns(name, columns) {
    // If the name doesn't exist in storedColumns, create a new entry
    if (!this.storedColumns[name]) {
      this.storedColumns[name] = {
        name,
        column: [...columns],
      };
    } else {
      // Update the existing columns for the specified name
      const storedColumnArray = this.storedColumns[name].column;

      // Add new columns to the existing array
      columns.forEach((column) => {
        if (!storedColumnArray.includes(column)) {
          storedColumnArray.push(column);
        }
      });
    }
  }

  @action removeStoredColumns(name, columnsToRemove) {
    if (this.storedColumns[name]) {
      const storedColumnArray = this.storedColumns[name].column;

      // Filter out the columns to be removed
      const updatedColumns = storedColumnArray.filter(column => !columnsToRemove.includes(column)); // eslint-disable-line
      // const updatedColumns = storedColumnArray.filter((column) => !columnsToRemove.includes(column));

      this.storedColumns[name].column = updatedColumns;
    }
  }

  @action getUsers() {
    fromPromise(new Promise((resolve) => this.requests.Users.getAll().then((json) => {
      // We need to set _private to true in order to properly add relevant info, such as user's details, to the new User object
      const users = json.map((user) => User.fromJsonProperties(user, 'employer'));
      this.users = users;
      resolve(users);
    })));
  }

  // Used to match already invited users email with the users list
  @action getUserByEmail(email) {
    // Convert the email to lowercase or uppercase for case-insensitive comparison
    const lowerCaseEmail = email.toLowerCase();

    // Convert the observable array to a regular JavaScript array
    const usersArray = toJS(this.users);

    // Find the user with the given email (using case-insensitive comparison)
    return usersArray.find((user) => user.email.toLowerCase() === lowerCaseEmail);
  }

  @action sendInvite(email, salaryPeriodCategoryId, resolve, reject) {
    return this.requests.Users.sendInvite(email, salaryPeriodCategoryId)
      .then((user) => {
        // Process the successful invitation
        const newUser = User.fromJsonProperties(user, 'employer');
        this.users.replace([newUser, ...this.users]);
        resolve(newUser);
      })
      // Catch errors
      .catch((err) => reject(err));
  }

  @action updateOtherUser(user) {
    const { uiStore: { currentUser } } = this;

    return this.requests.Users.update(user).then((res) => {
      const updatedUser = User.fromJsonProperties(res, 'employer');

      // Update the user in the user list
      const foundUserIndex = this.users.findIndex((oldUser) => oldUser.id === updatedUser.id);
      if (foundUserIndex !== -1) {
        // Trigger a UI re-render with this.users value re-assignment
        const updatedUsers = [...this.users];
        updatedUsers[foundUserIndex] = updatedUser;
        // this.users = fromPromise.resolve(updatedUsers);
        this.users = updatedUsers;
      } else {
        console.error('Something went wrong, the updated user not found');
      }

      // Update the current user if necessary
      // DOES NOT WORK AS-IS, OVERWRITES STUFF LIKE ACCOUNT INFO
      // NEED TO SELECTIVELY GRAB KEYS, OR IGNORE OTHERS, OR MAKE SURE THE RETURNED OBJECT IS THE SAME AS IN LOGIN
      if (currentUser.id === user.id) {
        const regularUserObj = User.fromJsonProperties(res);
        currentUser.updateProperties(regularUserObj);
      }
    }).catch((err) => (
      { errors: err.response.body }
    ));
  }

  @action createUser(user) {
    return this.requests.Users.create(user).then((res) => {
      const updatedUser = User.fromJsonProperties(res, 'employer');
      const updatedUsers = [updatedUser, ...this.users];
      this.users = updatedUsers;
    }).catch((err) => (
      { errors: err.response.body }
    ));
  }

  @action purgeOtherHelmetImage(user) {
    this.requests.Users.purgeHelmetImage(user).then(() => {
      user.changeAttribute('helmetImageUrl', null);
    });
  }

  @action purgeOtherTaxIncomeCard(user) {
    this.requests.Users.purgeTaxIncomeCard(user).then(() => {
      user.changeAttribute('taxIncomeCardUrl', null);
    });
  }

  @action purgeOtherCard(user, card) {
    return this.requests.Users.purgeCardEmployer(user, card);
  }
}

export default EmployerUserStore;
