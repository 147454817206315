/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { GridCell, GridInner } from '@rmwc/grid';
import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { TextField } from '@rmwc/textfield';

import '@rmwc/avatar/avatar.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
// import '@material/notched-outline/dist/mdc.notched-DialogButtonoutline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('uiStore', 'chatStore', 'loginStore', 't')
@observer
class AutocompletableAddress extends Component {
  isPossibleDoubleClick = false;

  constructor(props) {
    super(props);

    this.state = {
      matches: [],
      matchObjects: [],
      address: this.props.address,
    };
  }

  componentDidMount() {
    const { address } = this.props;
    this.setState({
      address: address
    });
  }

  capitalizeString(str) {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str
  }

  doOnBlur(address) {
    const { updateAddress } = this.props;
    updateAddress(address);
    // loginStore.newAutocompleteAddress(address);
  }

  doOnChangeAddress(attr, value) {
    const { address, autocompleteAddresses } = this.props;
    // const { tripRoute } = this.state;
    // const autocompleteLocations = uiStore.currentUser.autocompleteLocations;

    value = this.capitalizeString(value);

    // const newTripRoute = [...tripRoute];
    // const locationToChange = newTripRoute[0].routeLocations[locationIndex];
    address[attr] = value;

    const stateName = attr + 'Open';

    let results = [];
    let matches = [];

    if (attr === 'street' || attr === 'name') {
      results = autocompleteAddresses.filter(
        // (target) => value && (target[attr].indexOf(value) === 0 || target["name"]?.indexOf(value) === 0),
        (target) => value && target[attr] && (target[attr]?.toLowerCase().includes(value.toLowerCase()))
      );
      matches = results.map((result) => result[attr]);
    } else {
      results = autocompleteAddresses.filter(
        // (target) => value && target[attr] && target[attr].indexOf(value) === 0,
        (target) => value && target[attr] && target[attr]?.toLowerCase().includes(value.toLowerCase())
      );
      matches = [... new Set(results.map(q => q[attr]))];
    }

    this.setState({
      // tripRoute: newTripRoute,
      matches: matches,
      matchObjects: results,
      [stateName]: results.length > 0 ? true : false
    });
  }

  doOnSelect(attr, event) {
    const { updateAddress, address } = this.props;
    const { matchObjects } = this.state;
    let tempAddress;

    if ((attr === 'street' || attr === 'name') && matchObjects) {
      tempAddress = {
        'street': matchObjects[event.detail.index].street,
        'city': matchObjects[event.detail.index].city,
        'zipCode': matchObjects[event.detail.index].zipCode,
        ...(matchObjects[event.detail.index].name && {'name': matchObjects[event.detail.index].name})
      };
      // this.setState({ address: tempAddress });
    } else {
      tempAddress = {
        ...address,
        [attr]: this.state.matches[event.detail.index],
      };
      // this.setState({ address: {...address, tempAddress} });
    }

    updateAddress(tempAddress);
    document.activeElement.blur();
  }

  addLocationTextField(attr, label, styles = null, routeIndex, locationIndex, className = null) {
    const { disabled, address, error, nameNotRequired } = this.props;
    const { matchObjects, matches } = this.state;
    // const { tripRoute } = this.state.directionTrip;
    // const locations = tripRoute[0].routeLocations;

    const stateName = attr + "Open";

    const ref = React.createRef();

    return (
      <>
        <MenuSurfaceAnchor className="autocompletable-address">
          <Menu
            open={this.state[stateName]}
            anchorCorner="bottomLeft"
            style={{
              width: '100vw',
              maxWidth: '100%',
              backgroundColor: 'whitesmoke',
            }}
            onClose={() =>
              this.setState({
                [stateName]: false
              })}
            onSelect={event => {
              this.doOnSelect(attr, event)
            }}
            focusOnOpen={false}
            ref={ref}
            className="autocomplete-location-menu"
          >
            {attr !== 'street' && attr !== 'name' && matches.slice(0, 5).map((match, index) => (
              <MenuItem
                key={index}
                style={{
                  // color: "white",
                  textAlign: "center",
                  borderBottom: index != 4 && index != matchObjects.length - 1 ? '1px gray solid' : null,
                  paddingBottom: index != 4 && index != matchObjects.length - 1 ? '2px' : null,
                  backgroundColor: "whitesmoke",
                  color: "black",
                }}
                // theme={['textPrimaryOnLight', 'secondaryBg']}
              >
                <span>{match}</span>
              </MenuItem>
            ))}
            {(attr === 'street' || attr === 'name') && matchObjects.slice(0, 5).map((matchObj, index) => (
              <MenuItem
                key={index}
                style={{
                  // color: "white",
                  textAlign: "center",
                  borderBottom: index != 4 && index != matchObjects.length - 1 ? '1px gray solid' : null,
                  paddingBottom: index != 4 && index != matchObjects.length - 1 ? '2px' : null,
                  backgroundColor: "whitesmoke",
                  color: "black",
                }}
                // theme={['textPrimaryOnLight', 'secondaryBg']}
              >
                <div className="location-suggestion-wrapper">
                  <span>
                  {`${matchObj?.street}, ${matchObj?.zipCode != null ? matchObj?.zipCode : ''} ${matchObj?.city != null ? matchObj?.city : ''}`}
                  </span>
                  <span style={{ marginLeft: '10px', fontSize: '14px' }}>
                    {matchObj.name}
                  </span>
                </div>
              </MenuItem>
            ))}
          </Menu>
          <TextField {...{
            style: { ...styles },
            label,
            // This nameNotRequired is very awkward but this is a quick solution where we don't want to update every single <AutocompletableAddress> and its props
            // nameNotRequired = true, translated into false with !, required: false
            required: attr === 'name' && !nameNotRequired,
            type: "text",
            disabled: disabled,
            invalid: attr === 'name' && error,
            helpText: attr === 'name' && error,
            onChange: (event) => {
              const { value } = event.target;
              this.doOnChangeAddress(attr, value, event, routeIndex, locationIndex);
            },
            onKeyDown: (event) => {
              if (event.key === 'ArrowDown' && matches.length > 0) {
                ref.current.foundation.adapter_.focusItemAtIndex(0)
              }
            },
            onBlur: () => { this.doOnBlur(address) },
            rootProps: { ripple: false },
            // theme: ['textPrimaryOnLight', 'secondaryBg'],
            onKeyPress: handleEnter,
            className,
            value: address?.[attr] != null ? address?.[attr] : '',
          }}
          />
        </MenuSurfaceAnchor>
      </>
    );
  }

  render() {
    const { t, uiStore, error } = this.props;
    const styles = { backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' };

    return (
      <>
        <GridCell
          // span={12}
          style={{ marginTop: '7px' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              {this.addLocationTextField(
                'name',
                'Nimi',
                styles,
              )}
            </GridCell>
            <GridCell
              span={12}
            >
              {this.addLocationTextField(
                'street',
                'Katuosoite',
                styles,
              )}
            </GridCell>
            <GridCell
              desktop={6}
              phone={2}
            >
              {this.addLocationTextField('city', 'Paikkakunta', styles, null)}
            </GridCell>
            <GridCell
              desktop={6}
              phone={2}
            >
              {this.addLocationTextField('zipCode', 'Postinro.', styles, null)}
            </GridCell>
          </GridInner>
        </GridCell>
      </>
    )
  }
}

export default AutocompletableAddress;
