/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Avatar } from '@rmwc/avatar';
import { Typography } from '@rmwc/typography';
import {
  Dialog, DialogActions, DialogButton, DialogTitle, DialogContent, SimpleDialog,
} from '@rmwc/dialog';

import Linkify from '../common/Linkify';

import '@rmwc/avatar/avatar.css';
import '@material/typography/dist/mdc.typography.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
// import '@material/notched-outline/dist/mdc.notched-DialogButtonoutline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

@inject('uiStore', 'chatStore', 't')
@observer
class Message extends Component {
  isPossibleDoubleClick = false;

  constructor() {
    super();
    this.showContextMenu = this.showContextMenu.bind(this);
    this.deleteMessage = this.deleteMessage.bind(this);
    this.sendSMSMessage = this.sendSMSMessage.bind(this);

    this.startInterval = this.startInterval.bind(this);
    this.cancelInterval = this.cancelInterval.bind(this);

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);

    this.onClick = this.onClick.bind(this);
    this.cancelDoubleClick = this.cancelDoubleClick.bind(this);

    this.state = {
      showDeleteDialog: false,
      showAdvancedDialog: false,
    };
  }

  onTouchStart() {
    if (event.target.tagName.toLowerCase() !== 'a') {
      this.startInterval();
    }
  }

  onTouchEnd() {
    if (event.target.tagName.toLowerCase() !== 'a') {
      this.cancelInterval();
    }
  }

  onClick(ev) {
    if (event.target.tagName.toLowerCase() !== 'a') {
      ev.stopPropagation();
      ev.preventDefault();

      if (this.isPossibleDoubleClick) {
        this.cancelDoubleClick();
        this.showContextMenu();
      } else {
        this.isPossibleDoubleClick = true;
        this.startInterval(this.cancelDoubleClick);
      }
    }
  }

  showContextMenu() {
    const { message, uiStore } = this.props;
    const { currentUser } = uiStore;

    this.cancelInterval();
    this.cancelDoubleClick();

    if (currentUser.role === 'employer' || currentUser.id === message.sender.id) {
      this.setState({ showAdvancedDialog: true })
    }
  }

  cancelDoubleClick() {
    this.isPossibleDoubleClick = false;
  }

  cancelInterval(ev) {
    clearTimeout(this.timeout);
  }

  startInterval(action) {
    this.timeout = setTimeout(action || this.showContextMenu, 300);
  }

  deleteMessage() {
    const { chatStore, message, room } = this.props;
    chatStore.deleteMessage(room, message);
  }

  sendSMSMessage() {
    const { chatStore, message, room } = this.props;
    chatStore.sendSMSMessage(room, message);
  }

  render() {
    const { message, t, uiStore, employeeMode } = this.props;
    const { currentUser } = uiStore;
    const { sender } = message;
    const { showAdvancedDialog, showDeleteDialog } = this.state;

    let classes = 'chat__message';

    // Check if the message is ours and add the correct
    // class
    if (sender.isSameUser(currentUser)) {
      classes += ' chat__message--mine';
    }

    return (
      <>
        {showDeleteDialog && (
          <SimpleDialog
            className="chat-message-action-dialog"
            title={t('messages.dialogDeleteTitle')}
            body={`${t('messages.dialogDeleteBody')}\n${message.content}`}
            acceptLabel={t('messages.dialogDeleteButtonAccept')}
            cancelLabel={t('messages.dialogDeleteButtonCancel')}
            open={showDeleteDialog}
            onClose={(evt) => {
              if (evt.detail.action === 'accept') {
                this.deleteMessage();
              }
              this.setState({ showDeleteDialog: false });
            }}
          />
        )}

        {showAdvancedDialog && (
          <Dialog
            open={showAdvancedDialog}
            onClose={evt => {
              this.setState({ showAdvancedDialog: false });

              switch (evt.detail.action) {
                case 'delete': {
                  this.setState({ showDeleteDialog: true });
                  break;
                }
                case 'sendsms': {
                  this.sendSMSMessage();
                  break;
                }
                default: {
                  /* noop */
                }
              }
            }}
            className={employeeMode ? "employer-trip-route-dialog chat-message-action-dialog" : "chat-message-action-dialog"}
          >
            <DialogTitle>Valitse toiminto</DialogTitle>
            <DialogContent className="xl-buttons">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <DialogButton action='delete'>{t('messages.dialogDeleteButtonAccept')}</DialogButton>
                <DialogButton action='sendsms'>{t('messages.dialogSendSMS')}</DialogButton>
              </div>
            </DialogContent>
            <DialogActions>
              <DialogButton
                action="close"
                style={{
                  color: '#c7c9cc',
                  border: '1px solid #c7c9cc',
                  borderRadius: '0px',
                }}
              >
                {t('messages.dialogDeleteButtonCancel')}
              </DialogButton>
            </DialogActions>
          </Dialog>
        )}

        <div
          className={classes}
          style={{
            marginBottom: '0.5em',
          }}
          onClick={(ev) => this.onClick(ev, message)}
          onTouchStart={this.onTouchStart}
          onTouchEnd={this.onTouchEnd}
          role="button"
        >
          <Avatar
            name={message.senderName}
            ripple={false}
            size="medium"
            src={message.sender.personalPictureUrl}
            style={{ width: '2em', height: '2em' }}
          />
          <div {...{
            className: 'message__content',
            style: {
              alignItems: 'start',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginTop: 0,
              whiteSpace: 'pre-wrap',
              width: '70%',
            },
          }}
          >
            <Typography
              use="subtitle2 message-top"
              tag="div"
            >
              {message.senderName}
              <span className={`${message.smsDelivery ? 'sms' : ''}`}>{message.smsDelivery ? 'SMS' : ''}</span>
            </Typography>
            <Linkify>{message.content}</Linkify>
            <div style={{
              alignSelf: 'flex-end',
              fontSize: '0.65rem',
              margin: 0,
            }}
            >
              {message.sentAt.format('DD.MM. HH:mm')}
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Message;
