import React from 'react';
import ReactLinkify from 'react-linkify';

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

const Linkify = (props) => {
  const { children } = props;
  return (
    <ReactLinkify componentDecorator={componentDecorator}>
      {children}
    </ReactLinkify>
  );
};

export default Linkify;
