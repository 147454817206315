import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { when } from 'mobx';
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle,
} from '@material-ui/core';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';
import { Select } from '@rmwc/select';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { isEqual } from 'lodash';
import 'moment/locale/fi';
import moment from '../utils/moment';
import LoadingSpinner from '../shared/LoadingSpinner';
// import WorkOrderInvitation from './WorkOrderInvitation';

@inject('t', 'employerWorkOrderStore', 'uiStore')
@observer
class WorkOrderInvitationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'read',
      invitation: null,
      invitationChanged: false,
      saving: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      invitation,
    } = this.props;

    if (invitation && !isEqual(invitation, prevProps.invitation)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ invitation });
    }
  }

  onClose() {
    const { onClose } = this.props;

    // Reset back to default values
    this.setState({
      invitation: null,
      saving: false,
      mode: 'read',
      invitationChanged: false,
    });

    onClose();
  }

  onSave() {
    const { onSave } = this.props;
    const { workPeriod } = this.state;

    onSave(workPeriod);
  }

  handleChangeForDate = (attr, value) => {
    this.setState((prevState) => ({
      workPeriod: { ...prevState.workPeriod, [attr]: value },
    }), () => {
      this.updateWorkPeriodProp();
      this.getPeriodAvailabilities();
    });
  }

  addDayPicker(attr, label, required, styles, extraOverlayClassName = null) {
    const { customDateRangePickerOverlay } = this.props;
    const { workPeriod, errors } = this.state;

    return (
      <DayPickerInput
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                required,
                style: { ...styles },
              }}
            />
          )
        }
        inputProps={{
          ref: null,
          className: '',
          style: { ...styles, width: '100%' },
          name: attr,
          invalid: errors[attr],
          helpText: errors[attr] && errors[attr],
          // onBlur: (event) => required && this.handleBlur(attr, event),
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker',
          showWeekNumbers: true,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        keepFocus={false}
        format="dd DD.MM.YYYY"
        onDayChange={(value) => {
          delete errors[attr];
          this.handleChangeForDate(attr, value);
        }}
        // This is is almost certainly overcomplicating things but it works
        value={workPeriod && workPeriod?.[attr] ? moment(workPeriod?.[attr], 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          // overlay: 'DayPickerInput-Overlay',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
        overlayComponent={customDateRangePickerOverlay}
      />
    );
  }

  saveInvitation() {
    const { uiStore, updateInvitation } = this.props;
    const { invitation } = this.state;

    console.log('Saving invitation: ', invitation);

    this.setState({
      saving: true,
    }, () => {
      new Promise((resolve, reject) => uiStore.updateWorkOrderInvitation(invitation, resolve, reject))
        .then((response) => {
          // Success
          updateInvitation(response);
          this.onClose();
        })
        .catch((err) => {
          // Failure
          this.setState({
            saving: false,
          }, console.error('ERROR: ', err));
        });
    });
  }

  render() {
    const { open, invitation: originalInvitation } = this.props;
    const {
      invitationDialogMode,
      invitation,
      mode,
      invitationChanged,
      saving,
    } = this.state;

    // The !invitation here is just to prevent invitationChanged becoming true upon dialog closing, when the prop invitation is nullified
    // const invitationChanged = !isEqual(invitation, originalInvitation) && !invitation;

    let statusText;
    let color;

    switch (invitation?.canParticipate) {
      case null:
        statusText = 'Kutsuttu';
        color = '#c7c7c7';
        break;
      case false:
        statusText = 'Ei osallistu';
        color = 'red';
        break;
      case true:
        statusText = 'Hyväksynyt';
        color = 'limegreen';
        break;
      default:
        statusText = 'Tila';
        break;
    }

    const canParticipateValues = [
      { label: 'Hyväksynyt', value: true },
      { label: 'Ei osallistu', value: false },
    ];

    return (
      <Dialog
        className="employer-delete-modal marginless-material-dialog"
        open={open}
        onClose={() => {
          this.onClose();
        }}
      >
        <DialogTitle
          style={{ paddingBottom: '5px' }}
        >
          {invitationDialogMode === 'edit' ? 'Muokkaa kutsua' : 'Kutsu'}
        </DialogTitle>
        <DialogContent
          className="work-period-form-dialog-content"
          style={{ fontSize: '16px' }}
        >
          <div style={{ margin: '-10px 0 10px 0' }}>
            {/* {`${selectedWorkDaysUser?.lastName}, ${selectedWorkDaysUser?.firstName}`} */}
            {invitation?.userFullName}
          </div>

          {mode === 'read' && (
            <>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  height: '56px',
                  alignItems: 'center',
                  minWidth: '300px',
                }}
              >
                <div>
                  <Icon
                    style={{ width: '20px' }}
                    icon={(
                      <div
                        style={{
                          background: color,
                          width: '14px',
                          height: '14px',
                          borderRadius: '100px',
                        }}
                      />
                    )}
                  />

                  <span>
                    {statusText}
                  </span>
                </div>

                <Icon
                  icon="edit"
                  style={{
                    cursor: 'pointer',
                    padding: '0 10px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.setState({ mode: 'edit' });
                  }}
                />
              </div>
            </>
          )}

          {mode === 'edit' && (
            <Select
              {...{
                label: 'Tila',
                rootProps: {
                  style: { minWidth: '300px' },
                },
                // required,
                name: 'canParticipate',
                // invalid: errors[attr],
                // helpText: errors[attr] && errors[attr],
                placeholder: 'Valitse tila',
                // className: 'mdc-theme--text-primary-on-dark pk-select',
                // disabled: this.props.disabled,
                // onBlur: (event) => {
                //   required && this.handleBlur(attr, event)
                // },
                onChange: (event) => {
                  // delete errors[attr];
                  if (event.target.value === '') {
                    return;
                  }
                  if (invitation?.canParticpate !== event.target.value && event.target.validity.valid) {
                    this.setState({
                      invitation: { ...invitation, canParticipate: event.target.value },
                      invitationChanged: true,
                    });
                  }
                  event.target.blur();
                },
                value: invitation?.canParticipate,
              }}
            >
              {canParticipateValues?.map((canParticipateValue) => (
                <option
                  key={canParticipateValue.label}
                  value={canParticipateValue.value}
                >
                  {canParticipateValue.label}
                </option>
              ))}
            </Select>
          )}

          {/* Näytä status + kynänappi joka aktivoi muokkausmoodin */}
          {/* Jos muokkausmoodi: näytä dropdown statukselle joka muokkaa openInvitationia */}
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: invitationChanged ? 'space-between' : 'end',
            padding: '15px',
          }}
        >
          {invitationChanged ? (
            <>
              <Button
                type="button"
                className="employer-accept-button"
                disabled={saving}
                onClick={() => this.saveInvitation()}
                // Need to force the button height to keep it the same when LoadingSpinner replaces the text
                style={{ height: '38px' }}
              >
                {saving ? <LoadingSpinner color="black" size="mini" /> : 'Tallenna'}
              </Button>

              <Button
                type="button"
                className="employer-reject-button"
                onClick={() => {
                  // Discard changes by restoring OG invitation
                  this.setState({ invitation: originalInvitation, mode: 'read', invitationChanged: false });
                }}
              >
                Peruuta
              </Button>
            </>
          ) : (
            <Button
              type="button"
              className="employer-reject-button"
              onClick={() => {
                this.onClose();
              }}
            >
              Sulje
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default WorkOrderInvitationDialog;
