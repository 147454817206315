import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import React, { Component } from 'react';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
import { Icon } from '@rmwc/icon';
// import { Checkbox } from '@rmwc/checkbox';
import { Typography } from '@rmwc/typography';
import { inject, observer } from 'mobx-react';
import Steps, { Step } from 'rc-steps';
import ActiveStorageProvider from 'react-activestorage-provider';
import * as IBAN from 'iban';

import '@rmwc/icon/icon.css';

import { w100, appcontentsize } from '../styles/inline-styles';
import UserRegistrationStore from './UserRegistrationStore';
import { addProfilePicture } from '../models/user/user-ui-avatar';
import { mandatoryCardTypes } from '../models/User';
// eslint-disable-nextline import/no-named-as-default
import addCard from '../profile/cards/card';
import PhoneNumber from '../common/phonenumber';
import noop from '../utils/noop';

import '../styles/buttonAnim.css';

const phoneNumberValidator = (value) => (value != null && typeof value === 'string' && /^\+358[1-9]\d{6,10}$/.test(value));

const textInput = (model, field, label, index, extras = {}) => {
  const valid = !model.hasErrors && (extras.validator != null ? extras.validator(model[field]) : true);

  return (
    <TextField
      className="registration"
      helpText={extras.helpText || {
        persistent: false,
      }}
      invalid={!valid}
      key={field}
      label={label}
      onChange={(event) => {
        model[field] = event.target.value; // eslint-disable-line no-param-reassign
      }}
      required
      style={w100}
      theme={['textPrimaryOnDark', 'secondaryBg']}
      value={model[field] || ''}
    />
  );
};

// fileInput function for uploading images.
export const fileInput = (model, attribute, label, index, onSubmit) => (
  <ActiveStorageProvider
    endpoint={{
      attribute,
      method: 'PUT',
      model: 'User',
      multiple: false,
      path: `/api/users/${model.id}`,
    }}
    key={index}
    onSubmit={onSubmit}
    render={({ handleUpload, uploads, ready }) => (
      <div
        style={w100}
        className="padding-left"
      >
        {label != null
          ? (
            <Typography
              style={w100}
              use="headline6"
              tag="h2"
            >
              {label}
            </Typography>
          ) : null}
        <input
          style={{ ...w100 }}
          type="file"
          disabled={!ready}
          onChange={(e) => {
            handleUpload(e.currentTarget.files);
          }}
        />
        {uploads.map((upload) => {
          switch (upload.state) {
            case 'waiting':
              return (
                <p key={upload.id}>
                  Waiting to upload
                  {upload.file.name}
                </p>
              );
            case 'uploading':
              return (
                <p key={upload.id}>
                  Uploading
                  {' '}
                  {upload.file.name}
                  :
                  {' '}
                  {Math.floor(upload.progress)}
                  %
                </p>
              );
            case 'error':
              return (
                <p key={upload.id}>
                Error uploading
                  {' '}
                  {upload.file.name}
                  :
                  {' '}
                  {upload.error}
                </p>
              );
            case 'finished':
              return (
                <p key={upload.id}>
                  Finished uploading
                  {upload.file.name}
                </p>
              );
            default:
              return null;
          }
        })}
      </div>
    )}
  />
);

@inject('uiStore', 't')
@observer
class Registration extends Component {
  constructor(props) {
    super();
    const { uiStore: { currentUser } } = props;
    this.store = new UserRegistrationStore(currentUser);
    this.form = React.createRef();
    this.steps = React.createRef();
    this.__original__ = JSON.parse(JSON.stringify(currentUser));
    this.state = { submitButtonClasses: '' };
  }

  wizardItems = () => [
    {
      items: [
        [textInput, 'firstName', 'registration.labels.first_name'],
        [textInput, 'lastName', 'registration.labels.last_name'],
        [textInput, 'phoneNumber', 'registration.labels.phone_number', {
          helpText: {
            children: 'registration.labels.phone_number_hint',
            persistent: true,
            validationMsg: false,
          },
          validator: phoneNumberValidator,
        }],
      ],
    },
    {
      items: [
        [textInput, 'taxNumber', 'registration.labels.tax_number'],
        [textInput, 'socialSecurityNumber', 'registration.labels.social_security_number'],
        [textInput, 'bankNumber', 'registration.labels.bank_number', { validator: IBAN.isValid }],
      ],
    },
    {
      items: [
        [(user, t, index) => <Typography key={index} use="headline6" className="padding-left" tag="h2">{t('profile.labels.personal_picture')}</Typography>],
        [(user, t) => <p style={{ fontSize: '1rem', marginBottom: '15px' }} className="padding-left">{t('profile.labels.personal_picture_hint')}</p>],
        [(user, t) => <p style={{ marginBottom: '35px' }} className="padding-left">{addProfilePicture(user, React.createRef(), user.personalPictureUrl, t)}</p>], // eslint-disable-line no-param-reassign
        [fileInput, 'taxation_income_card', 'registration.labels.taxation_card', (currentUser, user) => {
          currentUser.taxIncomeCardUrl = user.taxation_income_card_url; // eslint-disable-line no-param-reassign
        }],
        /* eslint-disable react/jsx-wrap-multilines */
        // [(user, t) => <Checkbox // eslint-disable-line no-param-reassign
        //   checked={user.details.clothing.hasHelmet}
        //   className="border-on-dark"
        //   label={t('profile.labels.clothing.has_helmet')}
        //   onChange={() => {
        //     user.details.clothing.hasHelmet = !user.details.clothing.hasHelmet; // eslint-disable-line no-param-reassign
        //   }}
        // />],
        // [fileInput, 'helmet_image', 'registration.labels.helmet_image', (currentUser, user) => {
        //   currentUser.helmetImageUrl = user.helmet_image_url; // eslint-disable-line no-param-reassign
        // }],
        [() => <br />],
        [(user, t) => mandatoryCardTypes.map((cardType) => addCard(user.accountId, cardType, t, user.details.cards,
          (event, entity, attr) => {
            if ((event == null || event.currentTarget.validity.valid) && this.__original__.details.cards[entity][attr] !== user.details.cards[entity][attr]) {
              this.save();
            }
          },
          (event, entity, attr) => {
            user.details.cards[entity][attr] = event.target.value; // eslint-disable-line no-param-reassign
          }))],
      ],
    },
  ];

  previousStep = () => {
    const { store } = this;
    store.currentStage -= 1;
  };

  handleErrors = (err) => {
    const { props: { t } } = this;
    if (err.response.body != null && typeof err.response.body.map === 'function') {
      const errors = err.response.body;
      this.errors = Object.keys(errors).reduce(
        (acc, attr) => {
          if (Array.isArray(errors[attr])) {
            acc[attr] = errors[attr].join('; ');
          }

          return acc;
        },
        {},
      );
    } else {
      this.errors = {
        unknownError: t('errorsUnknownError'),
      };
    }
  };

  nextOrFinishedStep = (fields) => {
    const { form, store } = this;
    const { uiStore } = this.props;

    // are all fields "required" OR passing validator checks
    if (!form.current.reportValidity() || fields.props.children.some((item) => (item.props != null ? item.props.invalid : false))) {
      const { submitButtonClasses } = this.state;
      if (submitButtonClasses === '') {
        this.setState({
          submitButtonClasses: 'invalid-shake',
        });
        setTimeout(() => {
          this.setState({
            submitButtonClasses: '',
          });
        }, 1000);
      }
      return;
    }

    if (store.currentStage === 2) {
      uiStore.updateUser()
        .then(() => {
          if (uiStore.currentUser.hasCompletedRegistration) {
            uiStore.showCalendar();
          }
        })
        .catch(this.handleErrors);
    } else if (store.currentStage === 1) {
      uiStore.updateUser()
        .then(() => {
          store.currentStage += 1;
        })
        .catch(this.handleErrors);
    } else {
      store.currentStage += 1;
    }
  };

  save() {
    const { uiStore } = this.props;
    uiStore.updateUser()
      .then(() => this.hackSaveOriginalData())
      .catch(noop);
  }

  renderFields(items) {
    const { t, uiStore: { currentUser } } = this.props;

    return (
      <>
        {
        items.map(([type, ...rest], index) => {
          if (rest.length === 0) {
            return type(currentUser, t, index);
          }

          const [key, label, action] = rest;

          // TODO: refactor
          if (action != null && action.helpText != null && action.helpText.children != null) {
            action.helpText.children = t(action.helpText.children);
          }

          return type(
            currentUser,
            key,
            t(label),
            index,
            type === textInput ? action : (user) => action(currentUser, user),
          );
        })
      }
      </>
    );
  }

  render() {
    const { props: { t }, store: { currentStage } } = this;
    const wizardItems = this.wizardItems();
    const fields = this.renderFields(wizardItems[currentStage].items);
    const { submitButtonClasses } = this.state;
    return (
      <>
        <h4 style={{ textAlign: 'center', color: 'var(--mdc-theme-primary)' }}>{t('registration.title')}</h4>

        <Steps
          className="registration"
          current={currentStage}
          icons={{
            error: <Icon icon="close" />,
            finish: <Icon icon="check" />,
          }}
          labelPlacement="vertical"
          ref={this.steps}
        >
          {wizardItems.map((item, index) => {
            const stepName = `registration.steps.${index}`;
            return (
              <Step
                key={stepName}
                title={index === currentStage ? t(stepName) : ''}
              />
            );
          })}
        </Steps>

        <form
          className="theme-dark"
          onSubmit={(e) => e.preventDefault()}
          ref={this.form}
          style={appcontentsize}
        >
          <div
            className="steps-content"
            style={w100}
          >
            { fields }
          </div>

          <div
            className="steps-action"
            style={{
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <Button
              onClick={() => this.nextOrFinishedStep(fields)}
              outlined
              type="primary"
              style={{ marginRight: 8, borderRadius: 0 }}
              className={submitButtonClasses}
            >
              {t(`registration.buttons.${currentStage === wizardItems.length - 1 ? 'done' : 'next'}`)}
            </Button>
            {
              (currentStage > 0) && <Button style={{ marginLeft: 8, borderRadius: 0 }} onClick={this.previousStep}>{t('registration.buttons.previous')}</Button>
            }
          </div>
        </form>
        <br />
        <p>
          {t('registration.help')}
          <br />
          <br />
          <PhoneNumber number={t('registration.helpPhonenumber')} />
        </p>
      </>
    );
  }
}

export default Registration;
