import { observable, action } from 'mobx';
import WorkOrderTrip from './WorkOrderTrip';
import TripDistanceAutofill from '../models/TripDistanceAutofill';

export default class WorkOrderTripStore {
  @observable workOrderTrips = [];

  cable = null;

  intervalId = null;

  constructor(uiStore, requests, timelogStore, actionCableStore) {
    this.uiStore = uiStore;
    this.requests = requests;
    this.timelogStore = timelogStore;
    this.actionCableStore = actionCableStore;

    // Note: also called in App.js afterLogin()
    // If we do not subscribe here, refreshing the page will cut the cable (user already logged in so afterLogin doesn't run)
    if (!this.cable) {
      this.subscribe();
    }
  }

  @action.bound categorizeWorkOrderTrips(workOrderTrips) {
    this.workOrderTrips = workOrderTrips;
  }

  subscribe = () => {
    this.cable = this.actionCableStore.cableOn
      ? this.actionCableStore.subscribe('EmployeeWorkOrderTripsChannel', this.handleResponse)
      : null;

    // Ping-ponging once every 30 seconds to keep heroku from closing the connection
    this.intervalId = setInterval(() => {
      if (this.uiStore.currentUser && this.cable) {
        this.cable.ping();
      }
    }, 30000);
  }

  unsubscribe = () => {
    this.actionCableStore.unsubscribe(this.cable);
    clearInterval(this.intervalId);
  }

  handleResponse = (response) => {
    if (response.method !== 'pong') {
      // Assuming an updated work order trip from employer
      // Note: both start trip and end trip are handled here since th response is a complete work order trip
      const newWorkOrderTrip = WorkOrderTrip.fromJsonProperties(response.body);
      this.updateWorkOrderTripProp(newWorkOrderTrip);
    }
  }

  // @action updateWorkOrderTrip(item, workOrderId) {
  //   return this.requests.WorkOrderTrips[item.id == null ? 'create' : 'update'](item, workOrderId).then((workOrderTrip) => {
  //     item.updatePropertiesFromJson(workOrderTrip);
  //   });
  // }

  @action save(item, workOrderId, workOrderName, employerCallback) {
    if (workOrderId && !item.workOrders.map((wo) => wo.id).includes(workOrderId)) {
      item.workOrders.push({ id: workOrderId, name: workOrderName });
    }

    return this.requests.WorkOrderTrips[item.id == null ? 'create' : 'update'](item, workOrderId).then((res) => {
      const newTrip = item.updatePropertiesFromJson(res.work_order_trip);
      this.updateWorkOrderTripProp(newTrip);

      if (res.autofilled_work_hours && res.work_order_trip.allowance_autofill_enabled) {
        this.timelogStore.updateTimelogAllowances(res.autofilled_work_hours);
      }

      if (res.work_hours_with_removed_trip_employer_comments) {
        this.timelogStore.removeWorkOrderTripEmployerComments(res.work_hours_with_removed_trip_employer_comments);
      }

      if (employerCallback) {
        employerCallback(res);
      }
    });
  }

  @action saveWithAllowanceAutofill(trips, workOrderId, allowanceAutofill, currentEmployeeId, currentUser) {
    // We use this to save the allowance autofill setting outside of the normal trip dialog (timelog-row)
    // NOTE: Doesn't really need the entire trip objects, should probably just give trip ids and autofill

    const updatedWorkOrderTrips = trips.map((trip) => new WorkOrderTrip({
      ...trip,
      // If the one changing the allowance autofill is employer doing it for another employee, we make sure that the trip still belongs to the employee
      userId: (currentEmployeeId && currentUser.role === 'employer') ? currentEmployeeId : null,
      allowanceAutofillEnabled: allowanceAutofill,
    }));

    updatedWorkOrderTrips.forEach((trip) => {
      this.requests.WorkOrderTrips.update(trip, workOrderId).then((res) => {
        const newTrip = trip.updatePropertiesFromJson(res.work_order_trip);
        this.updateWorkOrderTripProp(newTrip);

        if (res.autofilled_work_hours && res.work_order_trip.allowance_autofill_enabled) {
          this.timelogStore.updateTimelogAllowances(res.autofilled_work_hours);
        }
      });
    });
  }

  @action delete(workOrderTrip) {
    return this.requests.WorkOrderTrips.del(workOrderTrip).then(() => {
      this.workOrderTrips = [...this.workOrderTrips].filter((trip) => trip.id !== workOrderTrip.id);
    });
  }

  @action updateWorkOrderTripProp(item) {
    const foundTripIndex = this.workOrderTrips.findIndex((tripObj) => tripObj.id === item.id);

    if (foundTripIndex === -1) {
      this.workOrderTrips = [].concat([...this.workOrderTrips], item);
      this.uiStore.currentUser.workOrderTrips = [].concat([...this.uiStore.currentUser.workOrderTrips], item);
    } else {
      this.workOrderTrips[foundTripIndex] = item;
    }

    this.uiStore.currentUser.workOrderTrips.replace([
      ...this.workOrderTrips,
    ]);
  }

  @action getKmsAutofillByLocations(locations, resolve, reject) {
    const departureLocation = locations.find((location) => location.locationOrder === 0);
    const destinationLocation = locations.find((location) => location.locationOrder === 1);
    return this.requests.WorkOrderTrips.getKmsAutofillByLocations(departureLocation, destinationLocation).then((res) => {
      const tripDistanceAutofills = res.map(TripDistanceAutofill.fromJsonProperties).sort((a, b) => a.acceptedCount > b.acceptedCount);
      resolve(tripDistanceAutofills);
    }).catch((err) => reject(err));
  }

  // Why does this use await instead of returning a promise with then like save()?
  @action async accept(workOrderTrip, mode, currentWorkOrderId, currentWorkOrderName) {
    if (workOrderTrip != null) {
      if (!workOrderTrip.workOrders.map((wo) => wo.id).includes(currentWorkOrderId)) {
        workOrderTrip.workOrders.push({ id: currentWorkOrderId, name: currentWorkOrderName });
      }

      const response = await this.requests.WorkOrderTrips.sendToEmployers(workOrderTrip, mode, currentWorkOrderId);

      const woTrip = WorkOrderTrip.fromJsonProperties(response.work_order_trip);

      this.updateWorkOrderTripProp(woTrip);

      if (response.autofilled_work_hours) {
        this.timelogStore.updateTimelogAllowances(response.autofilled_work_hours);
      }

      if (response.work_hours_with_removed_trip_employer_comments) {
        this.timelogStore.removeWorkOrderTripEmployerComments(response.work_hours_with_removed_trip_employer_comments);
      }
    }
  }

  // Why does this use await instead of returning a promise with then like save()?
  @action async employerSaveAndAccept(workOrderTrip, mode, currentWorkOrderId, currentWorkOrderName, callback) {
    if (workOrderTrip != null) {
      if (!workOrderTrip.workOrders.map((wo) => wo.id).includes(currentWorkOrderId)) {
        workOrderTrip.workOrders.push({ id: currentWorkOrderId, name: currentWorkOrderName });
      }

      const response = await this.requests.WorkOrderTrips.employerSaveAndAccept(workOrderTrip, mode, currentWorkOrderId);
      const woTrip = WorkOrderTrip.fromJsonProperties(response);

      this.updateWorkOrderTripProp(woTrip);

      if (response.autofilled_work_hours) {
        this.timelogStore.updateTimelogAllowances(response.autofilled_work_hours);
      }

      if (response.work_hours_with_removed_trip_employer_comments) {
        this.timelogStore.removeWorkOrderTripEmployerComments(response.work_hours_with_removed_trip_employer_comments);
      }

      if (callback) {
        callback(response);
      }
    }
  }

  @action async getEmployerWorkOrderTripById(currentUser, id) {
    return this.requests.WorkOrderTrips.getEmployerWorkOrderTripsById(currentUser, id);
  }
}
