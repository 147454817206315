import { observable } from 'mobx';

class ErrorStore {
  @observable hasError = false;

  @observable errorStatus = null;

  @observable errorMessage = 'Tapahtui virhe';

  @observable errorPriority = 0;

  setApiError(err) {
    this.errorStatus = err.status ? err.status : null;

    if (this.errorStatus === null || this.errorStatus === 504) {
      this.hasError = true;
      this.errorPriority = 1;
      const startPart = 'Palveluun ei saatu yhteyttä. Ole hyvä';
      const endPart = window.navigator.onLine
        ? ' ja yritä hetken kuluttua uudelleen.'
        : ' ja tarkista internet-yhteytesi.';
      this.errorMessage = startPart + endPart;
    } else if (this.errorStatus === 401) {
      this.hasError = true;
    } else {
      this.errorMessage = 'Tapahtui virhe.';
      this.clearError();
    }
  }

  clearError() {
    this.hasError = false;
    this.errorStatus = null;
    this.errorPriority = 0;
  }

  setCustomError(priority, message) {
    this.hasError = true;
    this.errorPriority = priority;
    this.errorMessage = message;
  }

  handleLoginError() {
    switch (this.errorStatus) {
      case 401:
        this.errorMessage = 'Tarkista käyttäjätunnus ja salasana.';
        break;
      default:
        this.errorMessage = 'Tapahtui virhe.';
        break;
    }
    this.clearError();
    return this.errorMessage;
  }
}

export default ErrorStore;
