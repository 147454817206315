import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { inject, observer } from 'mobx-react';
import {
  Card,
  CardPrimaryAction,
  CardActionButton,
  CardActions,
  CardActionButtons,
} from '@rmwc/card';
import { Button } from '@rmwc/button';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import { Typography } from '@rmwc/typography';
//  import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { isEqual } from 'lodash';

import 'moment/locale/fi';
import 'react-day-picker/lib/style.css';
import moment from '../utils/moment';

import WorkOrderList from './WorkOrderList';
import EmployeeCalendarStore from './EmployeeCalendarStore';
import { appcontentsize } from '../styles/inline-styles';

export const buttonColors = {
  yellow: {
    border: '1px solid #E9B801',
    color: '#E9B801',
  },
  orange: {
    border: '1px solid #FD6900',
    color: '#FD6900',
  },
  green: {
    border: '1px solid #6ABB6A',
    color: '#6ABB6A',
  },
};

export const buttonStyle = {
  borderRadius: 0,
  fontSize: '12px',
  width: '10em',
};

@inject('uiStore', 'loginStore', 't')
@observer
class EmployeeCalendar extends Component {
  constructor(props) {
    super();
    this.store = new EmployeeCalendarStore(props.uiStore);
    const { workOrders, employeeCalendarEntries } = props;
    // const { currentUser } = uiStore;
    this.state = {
      selectedDay: '',
      itemList: workOrders,
      calendarDay: null,
      selectedWorkDays: employeeCalendarEntries ? employeeCalendarEntries.workingCurrentPeriod : [],
      toBeDeletedWorkDays: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { workOrders, employeeCalendarEntries } = this.props;

    // if (workOrders.length !== prevProps.workOrders.length) {
    if (!isEqual(workOrders, prevProps.workOrders)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        itemList: workOrders,
      });
    }

    if (!isEqual(employeeCalendarEntries, prevProps.employeeCalendarEntries)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedWorkDays: employeeCalendarEntries ? employeeCalendarEntries.workingCurrentPeriod : [],
      });
    }
  }

  updateAvailability() {
    const { uiStore } = this.props;

    uiStore.showUpdateAvailability();
  }

  filterWorkOrders(day, modifiers) {
    const {
      uiStore,
      employerWorkDayMode,
      setSelectedWorkDays,
      employeeCalendarEntries,
    } = this.props;
    const { selectedWorkDays, toBeDeletedWorkDays } = this.state;
    const { currentUser } = uiStore;
    const date = moment(day);
    // console.log(selected);

    const updatedSelectedWorkDays = [...selectedWorkDays];
    const updatedToBeDepetedWorkDays = [...toBeDeletedWorkDays];

    if (employerWorkDayMode) {
      // REMVOE OR ADD?
      const foundDayIndex = updatedSelectedWorkDays.findIndex((existingDay) => isEqual(existingDay, day));
      if (foundDayIndex !== -1) {
        updatedSelectedWorkDays.splice(foundDayIndex, 1);
        // If day was also in original props, add it to separate toBeDeletedWorkDays array
        const foundOriginalDaySetIndex = employeeCalendarEntries.workingCurrentPeriod.findIndex((propDay) => isEqual(propDay, day));
        if (foundOriginalDaySetIndex !== -1) {
          updatedToBeDepetedWorkDays.push(day);
        }
      } else {
        updatedSelectedWorkDays.push(day);
        // If day was also in original props, remove it from separate toBeDeletedWorkDays array
        const foundOriginalDaySetIndex = employeeCalendarEntries.workingCurrentPeriod.findIndex((propDay) => isEqual(propDay, day));
        if (foundOriginalDaySetIndex !== -1) {
          updatedToBeDepetedWorkDays.splice(foundOriginalDaySetIndex, 1);
        }
      }

      this.setState({
        selectedWorkDays: updatedSelectedWorkDays,
        toBeDeletedWorkDays: updatedToBeDepetedWorkDays,
      }, () => {
        setSelectedWorkDays(updatedSelectedWorkDays, updatedToBeDepetedWorkDays);
      });
    }

    if (modifiers.working) {
      const workOrdersInDay = currentUser.workOrders.filter((wo) => date.isBetween(wo.from, wo.to, 'day', '[]'));

      if (modifiers.selected) {
        this.setState({
          calendarDay: null,
          itemList: currentUser.workOrders,
          selectedDay: '',
        });
      } else
      if (workOrdersInDay.length > 0) {
        this.setState({
          selectedDay: date.format('D.M.YY'),
          itemList: workOrdersInDay,
          calendarDay: day,
        });
      }
    }
  }

  renderUpdateAvailability(updatingAvailability) {
    const { store, props: { t } } = this;

    const buttons = updatingAvailability ? (
      <CardActionButtons
        style={{
          flexDirection: 'column',
        }}
      >
        <CardActionButton {...{
          onClick: () => this.store.cancelUpdatingAvailability(),
          outlined: true,
          style: {
            ...buttonStyle,
            ...buttonColors.orange,
            ...{
              border: 'unset',
              margin: 0,
              width: 'unset',
            },
          },
        }}
        >
          {t('calendar.employee.cards.cancel_action')}
        </CardActionButton>
        <CardActionButton {...{
          onClick: () => this.store.finishUpdatingAvailability(),
          style: {
            ...buttonStyle,
            ...buttonColors.yellow,
          },
        }}
        >
          {t('calendar.employee.cards.save_action')}
        </CardActionButton>
      </CardActionButtons>
    ) : (
      <CardActionButtons>
        <CardActionButton {...{
          onClick: () => this.store.startUpdatingAvailability(),
          outlined: true,
          style: {
            ...buttonStyle,
            ...buttonColors.yellow,
          },
        }}
        >
          {t('calendar.employee.cards.update_action')}
        </CardActionButton>
      </CardActionButtons>
    );

    return (
      <Card>
        <CardPrimaryAction
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <CardActions
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography {...{
              use: 'body1',
              tag: 'p',
              style: {
                marginBottom: 0,
                marginTop: 0,
                padding: '0 0 0 1rem',
              },
            }}
            >
              {t('calendar.employee.update_availability_title')}
            </Typography>
            {updatingAvailability
              && (
                <CardActions>
                  <CardActionButton
                    className="button-yesno"
                    onClick={() => store.setAvailableEditMode()}
                    outlined={store.availableEditMode}
                    style={{ borderColor: 'white', color: 'white' }}
                  >
                    <span className="dot dot-yes">&nbsp;</span>
                    {' '}
                    {t('common.yes')}
                  </CardActionButton>
                  <CardActionButton
                    onClick={() => store.setAvailableEditMode(false)}
                    outlined={!store.availableEditMode}
                    style={{ borderColor: 'white', color: 'white' }}
                  >
                    <span className="dot dot-no">&nbsp;</span>
                    {' '}
                    {t('common.no')}
                  </CardActionButton>
                </CardActions>
              )}

          </CardActions>
          <CardActions>{buttons}</CardActions>
        </CardPrimaryAction>
      </Card>
    );
  }

  render() {
    const {
      store,
      props: {
        t,
        employerWorkDayMode,
        employeeCalendarEntries,
        uiStore: {
          currentUser,
          currentUser: {
            availableDays,
            busyDays,
            workDays,
            lastSalaryPeriodDays,
          },
        },
      },
    } = this;
    const {
      selectedDay,
      itemList,
      calendarDay,
      selectedWorkDays,
    } = this.state;

    const updateAvailability = this.renderUpdateAvailability(store.updatingAvailability);

    const invitations = currentUser.invitations.slice().sort(
      (i1, i2) => {
        if (i1.canParticipate === i2.canParticipate) {
          return 0;
        }
        if (i1.canParticipate) {
          return -1;
        }
        return 1;
      },
    );

    let modifiers = {};
    if (employerWorkDayMode && employeeCalendarEntries) {
      modifiers = {
        available: [],
        busy: [],
        today: new Date(),
        working: employeeCalendarEntries.working,
        lastSalaryPeriodDay: Array.from(lastSalaryPeriodDays),
        workingCurrentPeriod: selectedWorkDays, // employeeCalendarEntries.workingCurrentPeriod,
      };
    } else {
      modifiers = {
        available: Array.from(availableDays),
        busy: Array.from(busyDays),
        today: new Date(),
        working: Array.from(workDays),
        lastSalaryPeriodDay: Array.from(lastSalaryPeriodDays),
      };
    }

    return (
      <div style={appcontentsize}>
        <Helmet>
          <style>
            {`
            .dot {
              border-radius: 50%;
              display: inline-block;
              height: 1.2em;
              width: 1.2em;
            }
            .dot-yes {
              background-color: #FFC900;
            }
            .dot-no {
              background-color: #FF6A00;
            }
            .DayPicker {
              text-align: center;
              width: 100%;
            }
            .DayPicker-wrapper {
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
            }
            .DayPicker-Month {
              margin: 0;
            }
            .DayPicker-WeekNumber {
              border-right: 1px solid #2C313D;
              width: 2.5em;
            }
            .DayPicker-Caption {
              padding-left: 2.5em;
              padding-right: 0;
              text-align: center;
              color: white;
            }
            .DayPicker-NavButton {
              top: 0;
            }
            .DayPicker-NavButton--prev {
              left: 5em;
            }
            .DayPicker-NavButton--next {
              right: 3em;
            }
            .DayPicker-Day {
              border: 1px solid #2C313D;
              height: 40px;
              padding: 0.6em;
              width: 40px;
            }

            .DayPicker-Day:not(.DayPicker-Day--outside) {
              color: white;
            }

            .DayPicker-Day--today {
              color: unset;
              font-weight: unset;
              position: relative;
            }
            .DayPicker-Day--today:after {
              border-bottom: 3px solid white;
              bottom: 0.4em;
              content: "";
              height: 1px;
              left: 15px;
              position: absolute;
              width: 10px;
            }

            .DayPicker-Day--available:before,
            .DayPicker-Day--busy:before,
            .DayPicker-Day--working:before {
              border-radius: 50%;
              content: "";
              height: 29px;
              left: 5px;
              position: absolute;
              top: 5px;
              width: 30px;
              z-index: -1;
            }
            .DayPicker-Day--available:before {
              background-color: #FFC900;
            }
            .DayPicker-Day--busy:before {
              background-color: #FF6A00;
            }
            .DayPicker-Day--working:before {
              background-color: #3b6333;
            }
            .DayPicker-Day--selected {
              background-color: transparent !important;
            }

            .employer-delete-modal .DayPicker-Day--selected, .employer-delete-modal .DayPicker-Day--workingCurrentPeriod,
            .trip-route-dialog .DayPicker-Day--selected, .employer-delete-modal .DayPicker-Day--workingCurrentPeriod {
              background-color: #4A90E2 !important;
            }

            .DayPicker-Day--working.DayPicker-Day--selected:before {
              border: 1px solid #FFC900;
              border-radius: 50%;
              // content: "";
              // height: 29px;
              // left: 5px;
              // position: absolute;
              // top: 5px;
              // width: 30px;
              // z-index: -1;
            }
            .DayPicker-Day--available,
            .DayPicker-Day--busy,
            .DayPicker-Day--working {
              color: black;
              position: relative;
              z-index: 2;
            }
            .DayPicker-Day--working {
              color: white;
            }
            .DayPicker-Day--lastSalaryPeriodDay {
              border-right: 2px #FFC900 dashed;
            }

            .DayPicker-Footer {
              padding-left: 2.5em;
              padding-right: 0.5em;
            }
          `}
          </style>
        </Helmet>

        {!employerWorkDayMode && updateAvailability}

        {/* <MenuSurfaceAnchor> */}
        <DayPicker
          enableOutsideDaysClick={false}
          fixedWeeks
          locale="fi"
          localeUtils={MomentLocaleUtils}
          modifiers={modifiers}
          onDayClick={store.updatingAvailability ? store.handleDayClick : (day, pickerModifiers) => this.filterWorkOrders(day, pickerModifiers)}
          showOutsideDays
          showWeekNumbers
          selectedDays={employerWorkDayMode ? selectedWorkDays : calendarDay}
          todayButton={t('calendar.employee.today')}
        />

        {/* {settingOpen
            && (
              <Menu
                hoistToBody="true"
                open={settingOpen}
                focusOnOpen={false}
                onClose={() => this.setState({
                  settingOpen: false,
                  workOrdersInDay: [],
                })}
                style={{
                  backgroundColor: '#1E232F',
                  // width: '100vw',
                  maxWidth: '100%',
                  position: 'absolute',
                }}
                anchorCorner="bottomStart"
              >
                {workOrdersInDay.map((order) => (
                  <MenuItem
                    style={{
                      color: 'white',
                    }}
                  >
                    {order.name}
                  </MenuItem>
                ))}
              </Menu>
            )}
        </MenuSurfaceAnchor> */}

        {selectedDay !== '' && (
          <div style={{
            maxWidth: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '10px',
            fontSize: '14px',
          }}
          >
            {'Valittu päivä: '}
            {selectedDay}
            <Button onClick={() => this.setState({
              selectedDay: '',
              itemList: currentUser.workOrders,
              calendarDay: undefined,
            })}
            >
              Poista valinta
            </Button>
          </div>
        )}

        {!employerWorkDayMode && (
          <>
            <WorkOrderList {...{
              mode: 'openInvitations',
              dividerColor: '#F3C000',
              shortDescription: false,
              items: invitations,
              title: 'calendar.employee.invitations_open',
            }}
            />

            <WorkOrderList {...{
              mode: 'acceptedInvitations',
              dividerColor: '#6BBD6B',
              items: itemList,
              showArrow: true,
              skipDescription: true,
              title: 'calendar.employee.invitations_accepted',
              loadMore: selectedDay === '',
            }}
            />
          </>
        )}

      </div>
    );
  }
}

export default EmployeeCalendar;
