/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { Button } from '@rmwc/button';
import { TextField } from '@rmwc/textfield';
import MUITextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@rmwc/checkbox';
import { toFloat } from 'validator';
import { Select } from '@rmwc/select';
import { Grid, GridCell } from '@rmwc/grid';
// import { Select, MenuItem } from '@material-ui/core';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogButton,
} from '@rmwc/dialog';
import { Switch } from '@rmwc/switch';
import { ThemeProvider } from '@rmwc/theme';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import noop from '../../utils/noop';

import moment from '../../utils/moment';
import CustomTimeField from '../../shared/CustomTimeField';
// import truncate from '../../utils/truncate';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const roundToTwoDecimals = (number) => (Math.round(number * 100) / 100);

@inject('timelogStore', 't', 'uiStore', 'workTaskStore', 'workOrderStore')
@observer
class HourlyTimelogForm extends Component {
  // textareaRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      selectedWorkTask: null,
      entryChanged: false,
      ...this.props.hourlyRow,
    };

    this.saveChanges = this.saveChanges.bind(this);
    this.createNew = this.createNew.bind(this);

    this.open = false;
  }

  componentDidMount() {
    const {
      hourlyRow,
      workTasks,
      mode,
      lastHour,
    } = this.props;

    // Old method of auto-resizing a textarea. material-ui/core/TextField makes this unnecessary
    // this.setState({
    //   ...hourlyRow,
    // }, () => {
    //   // Set the initial text area (description field) height
    //   const desc = this.props.hourlyRow.description;
    //   const lineBreaks = (desc?.match(/\n/g) || []).length;
    //   // One line is equal to 19px. We add 1 to line breaks because there's at least one line
    //   this.textareaRef.current.style.height = `${(lineBreaks + 1) * 19}px`;
    // });

    // If user only has one work task to choose from, we select it automatically
    if (workTasks.length === 1) {
      this.setWorkTask(workTasks[0].id);
    }

    if (hourlyRow) {
      // Set the initial CollapsibleList handle value to match the existing workNumber
      workTasks.forEach((workTask) => {
        if (workTask.id === hourlyRow.workTask?.id) {
          // this.setState({ selectedWorkTask: `${workNumber[0]} - ${workNumber[1]}` });
          this.setState({ selectedWorkTask: workTask });
        }
      });
    }

    if (!this.state.from && mode === 'create' && lastHour !== '') {
      this.setState({ from: lastHour });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      mode,
      lastHour,
    } = this.props;
    const { from } = this.state;

    if (!from && mode === 'create' && lastHour && (prevProps.lasthour !== lastHour)) {
      this.setState({ from: lastHour });
    }
  }

  setWorkTask(workTaskId) {
    const { timeNormal, time50, time100 } = this.state;
    const {
      workTasks,
      mode,
      setCreateNewButtonEnabled,
      openCreateWorkTaskDialog,
    } = this.props;

    if (workTaskId === 'new-work-task') {
      // Chose the 'New work task' option, opening the creation dialog and resetting the value
      this.setState({ selectedWorkTask: null }, () => openCreateWorkTaskDialog());
    } else {
      // Assuming actual work task id
      const foundWorkTask = workTasks.find((workTask) => workTask.id === Number(workTaskId));

      this.setState({ selectedWorkTask: foundWorkTask, entryChanged: true }, () => {
        if (mode === 'create' && setCreateNewButtonEnabled) {
          setCreateNewButtonEnabled((foundWorkTask && (timeNormal || time50 || time100)));
        }
      });
    }
  }

  setNightShift() {
    const { item } = this.props;
    // Find the work task entry with the greatest createdAt timestamp
    let latestWorkTaskEntry;
    if (item?.workTaskEntries && item?.workTaskEntries.length > 0) {
      latestWorkTaskEntry = item?.workTaskEntries.reduce((max, obj) => (obj.createdAt.isAfter(max.createdAt) ? obj : max));
    }

    // We are assuming that the work task entries are typically created in a chronological order
    // Therefore if the previous entry starts at 00:00 or crosses 00:00, we can assume that the next entries are night shifts
    if (latestWorkTaskEntry && (latestWorkTaskEntry.nightShift || latestWorkTaskEntry.from > latestWorkTaskEntry.to || latestWorkTaskEntry.to === '00:00')) {
      this.setState({
        nightShift: true,
      });
    }
  }

  doOnChange = (attr, value, event) => {
    if (this.state[attr] !== value && event.target.validity.valid) {
      this.setState({ [attr]: value });
    }
  }

  doOnBlurForHoursForm = (attr, value) => {
    const {
      date,
      oldTimeNormal,
      oldTime50,
      hourlyRow,
      setCreateNewButtonEnabled,
      mode,
    } = this.props;
    const {
      timeNormal,
      time50,
      time100,
      selectedWorkTask,
    } = this.state;

    // This entire thing needs to changed if we are to make the autofill actually smart
    // E.g. two entries: 7x normal and another with 1x normal, 1x 50%
    // Editing the first entry to 8x normal would update the other to 2x 50% hours
    // Because at the moment the updates are made in the state, we also need to do the nested_attributes saving
    // Otherwise we'd need to update the props directly
    this.setState({ [attr]: value }, () => {
      // For some reason moment interprets '--:00' is a valid time (midnight), so we need to handle the case ourselves
      if (this.state.from != null && this.state.to != null && this.state.from !== '--:00' && this.state.to !== '--:00') {
        const timeFrom = moment(this.state.from, 'H:m');
        const timeTo = moment(this.state.to, 'H:m');
        let time;
        let newTimeNormal = null;
        let newTime50 = null;
        let newTime100 = null;

        // Night shifts
        if (timeFrom > timeTo) {
          const timeToMidnight = moment('24:00', 'H:m').diff(timeFrom) / 3600000;
          const timeFromMidnight = timeTo.diff(moment('00:00', 'H:m')) / 3600000;
          time = timeToMidnight + timeFromMidnight;
        } else {
          time = timeTo.diff(timeFrom) / 3600000;
        }
        time = Number(time); // .toFixed(2);
        const weekday = date.weekday();

        // Mon-Fri
        if (weekday < 5) {
          // Max 8 normal, max 2 50%, the rest is 100%
          // First check how many normal hours are "left" for the day by removing existing ones from 8
          // Subtract previous values since we're updating a row that was already used to calculate oldTimeNormal
          const availableNormal = 8 - (oldTimeNormal - (hourlyRow.timeNormal || 0));

          if (availableNormal > 0) { // if there are more than 0 hours "left" for the day
            if (time > availableNormal) { // if the new work task hours are more than what is left for the day
              newTimeNormal = availableNormal;

              const leftoverTime = time - availableNormal;

              if (leftoverTime > 2) {
                newTime50 = 2;
                newTime100 = leftoverTime - 2;
              } else {
                newTime50 = leftoverTime;
              }
            } else { // new task hours are less than available hours, no need to worry about going over
              newTimeNormal = time;
            }
          } else if (oldTime50 < 2) { // no available normal hours left, check if there are 50% hours left
            // Subtract previous values since we're updating a row which was already used to calculate oldTime50
            const available50 = 2 - (oldTime50 - (hourlyRow.time50 || 0));

            if (time > available50) { // new hours are more than what is left, hours that go over the 50% allotment (2 hrs) will be put to time100
              newTime50 = available50;
              newTime100 = time - available50;
            } else {
              newTime50 = time;
            }
          } else {
            newTime100 = time;
          }
          // Sat
        } else if (weekday === 5) {
          // No normals, max 8 50%, the rest is 100%
          // Subtract previous values since we're updating a row that was already used to calculate oldTime50
          const availableSat50 = 8 - (oldTime50 - (hourlyRow.time50 || 0));

          if (availableSat50 > 0) {
            if (time > availableSat50) {
              newTime50 = availableSat50;
              newTime100 = time - availableSat50;
            } else {
              newTime50 = time;
            }
          } else {
            newTime100 = time;
          }
          // Sun
        } else {
          // Everything to 100% and sundayWork
          newTime100 = time;
          // TODO: WeeklyRest only if every day of the week had work. How much?
        }

        this.setNightShift();

        this.setState({
          timeNormal: roundToTwoDecimals(newTimeNormal),
          time50: roundToTwoDecimals(newTime50),
          time100: roundToTwoDecimals(newTime100),
          entryChanged: true,
        }, () => {
          if (mode === 'create' && setCreateNewButtonEnabled) {
            setCreateNewButtonEnabled((selectedWorkTask && (newTimeNormal || newTime50 || newTime100)));
          }
        });
      } else if (mode === 'create' && setCreateNewButtonEnabled) {
        setCreateNewButtonEnabled((selectedWorkTask && (timeNormal || time50 || time100)));
      }
    });
  }

  saveChanges() {
    const {
      editHourlyRow,
      hourlyRow,
      afterSave,
    } = this.props;

    const editedRow = hourlyRow;
    editedRow.changeAttribute('from', this.state.from);
    editedRow.changeAttribute('to', this.state.to);
    editedRow.changeAttribute('timeNormal', this.state.timeNormal);
    editedRow.changeAttribute('time50', this.state.time50);
    editedRow.changeAttribute('time100', this.state.time100);
    editedRow.changeAttribute('workTask', this.state.selectedWorkTask);
    editedRow.changeAttribute('description', this.state.description);
    editedRow.changeAttribute('nightShift', this.state.nightShift);
    editHourlyRow(editedRow);

    if (afterSave) {
      afterSave();
    }
  }

  createNew() {
    const {
      createHourlyRow,
      hourlyRow,
    } = this.props;

    const editedRow = hourlyRow;
    editedRow.changeAttribute('from', this.state.from);
    editedRow.changeAttribute('to', this.state.to);
    editedRow.changeAttribute('timeNormal', this.state.timeNormal);
    editedRow.changeAttribute('time50', this.state.time50);
    editedRow.changeAttribute('time100', this.state.time100);
    editedRow.changeAttribute('workTask', this.state.selectedWorkTask);
    editedRow.changeAttribute('description', this.state.description);
    editedRow.changeAttribute('nightShift', this.state.nightShift);
    createHourlyRow(editedRow);

    this.resetForm();
  }

  resetForm() {
    const { setCreateNewButtonEnabled } = this.props;

    this.setState({
      from: null,
      to: null,
      timeNormal: null,
      time50: null,
      time100: null,
      workTask: null,
      selectedWorkTask: null,
      description: null,
    });
    setCreateNewButtonEnabled(false);
  }


  doOnBlurForm(attr, value, event) {
    const { hourlyRow, setCreateNewButtonEnabled, mode } = this.props;

    if (this.state[attr] !== hourlyRow[attr] && event.target.validity.valid) {
      this.setState({ [attr]: value, entryChanged: true }, () => {
        if (mode === 'create' && setCreateNewButtonEnabled) {
          const {
            selectedWorkTask,
            timeNormal,
            time50,
            time100,
          } = this.state;

          setCreateNewButtonEnabled((selectedWorkTask && (timeNormal || time50 || time100)));
        }
      });
    }
  }

  addDecimalField(attr, label = null, marginLeft = null, marginRight = null) {
    return (
      <div
        style={{ padding: 0, textAlign: 'center' }}
      >
        <TextField {...{
          onBlur: (event) => {
            this.doOnBlurForm(attr, event.target.value, event);
          },
          onChange: noop,
          onInput: (event) => {
            let value = event.target.value.replace(/[.,]{1,}/, '.');

            if (value.substr(-1) !== '.' && value !== '') {
              value = toFloat(value);
              if (value >= 24) {
                return;
              }
            }

            if ((!Number.isNaN(value) || value === '')) {
              this.doOnChange(attr, value, event);
            }
          },
          disabled: this.props.disabled,
          pattern: '[0-9.,]*',
          rootProps: { ripple: false },
          style: {
            height: '56px',
            margin: '2px 0px',
            marginLeft,
            marginRight,
            // minWidth: '40px',
            padding: 0,
            // width: '105px',
            borderBottom: '2px solid #4A515C',
            // borderRadius: '0px',
          },
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          onKeyPress: handleEnter,
          // value: hourlyRow.description ? hourlyRow.description : '',
          value: (this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : '',
          label,
        }}
        />

        {/* Material UI TextField attempt, incorrect style and colors. Maybe should just overwrite them with high specifity CSS */}
        {/* <TextField
          variant="filled"
          // className: 'pk-number-field',
          onBlur={(event) => {
            this.doOnBlurForm(attr, event.target.value, event);
          }}
          onChange={noop}
          onInput={(event) => {
            let value = event.target.value.replace(/[.,]{1,}/, '.');

            if (value.substr(-1) !== '.' && value !== '') {
              value = toFloat(value);
              if (value >= 24) {
                return;
              }
            }

            if ((!Number.isNaN(value) || value === '')) {
              this.doOnChange(attr, value, event);
            }
          }}
          disabled={this.props.disabled}
          pattern="[0-9.,]*"
          rootProps={{ ripple: false }}
          sx={{
            height: '56px',
            margin: '2px',
            // minWidth: '40px',
            padding: 0,
            width: '105px',
            borderBottom: '2px solid #4A515C',
            borderRadius: '0px',
            backgroundColor: 'var(--mdc-theme-secondary)',
            color: 'white',
          }}
          // theme={['textPrimaryOnDark', 'secondaryBg']}
          type="text"
          onKeyPress={handleEnter}
          // value: hourlyRow.description ? hourlyRow.description : '',
          value={(this.state != null && this.state[attr] != null && !Number.isNaN(this.state[attr])) ? this.state[attr] : ''}
          label={label}
        /> */}
      </div>
    );
  }

  addHourFieldForm(attr) {
    const { disabled } = this.props;

    return (
      <div
        className="mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label hourly-timelog-hour-field"
        style={{
          margin: 0,
          padding: 0,
          backgroundColor: 'var(--mdc-theme-secondary)',
          color: 'white',
          height: '56px',
        }}
      >
        <CustomTimeField
          styles={{ width: '100px' }}
          value={this.state[attr] || '--:--'}
          attr={attr}
          doOnChange={this.doOnChange}
          doOnBlur={this.doOnBlurForHoursForm}
          handleEnter={handleEnter}
          disabled={disabled}
        />
      </div>
    );
  }

  addCheckbox(attr, label) {
    return (
      <Checkbox
        checked={this.state[attr]}
        className="border-on-dark"
        label={label}
        onChange={(evt) => {
          this.setState({
            [attr]: evt.target.checked,
            entryChanged: true,
          });
        }}
      />
    );
  }

  renderSelect() {
    const { workTasks, workTaskCreationEnabled } = this.props;
    const { selectedWorkTask } = this.state;

    return (
      <Grid
        style={{ padding: '0px' }}
      >
        <GridCell
          span={12}
          phone={6}
        >
          <Select {...{
            theme: ['textPrimaryOnDark', 'secondaryBg'],
            // label: 'Valitse työ',
            style: {
              backgroundColor: '#2d323e',
              borderBottom: '2px solid #4A515C',
              // borderRadius: 0,
              textOverflow: 'ellipsis',
              padding: '0 35px 0 20px',
            },
            rootProps: { style: { width: '100%', backgroundColor: 'transparent' } },
            placeholder: 'Valitse työ',
            value: selectedWorkTask?.id,
            onChange: (e) => this.setWorkTask(e.target.value),
          }}
          >
            {workTasks?.map((workTask) => (
              <option
                key={workTask.id}
                value={workTask.id}
              >
                {workTask.identifierTwo ? `${workTask.identifierOne} - ${workTask.identifierTwo}` : workTask.identifierOne}
              </option>
            ))}
            {workTaskCreationEnabled && (
              <option
                key="new-work-task"
                value="new-work-task"
              >
                + Uusi työ
              </option>
            )}
          </Select>

          {/* Material UI select attempt, styles need overwriting maybe with high specifity CSS or a theme */}
          {/* <Select
            label="Valitse työ"
            labelId="select-label"
            id="work-task"
            value={selectedWorkTask?.id || ''}
            onChange={(e) => this.setWorkTask(e.target.value)}
            style={{
              height: '44px',
              width: '90px',
              flexGrow: 1,
              borderBottom: '2px solid #4A515C',
              borderRadius: 0,
              backgroundColor: 'var(--mdc-theme-secondary)',
              color: 'white',
              textAlign: 'center',
            }}
          >
            {workTasks?.map((workTask) => (
              <MenuItem
                value={workTask.id}
                key={workTask.id}
              >
                {workTask.identifierTwo ? `${workTask.identifierOne} - ${workTask.identifierTwo}` : workTask.identifierOne}
              </MenuItem>
            ))}
          </Select> */}
        </GridCell>
      </Grid>
    );
  }

  render() {
    const {
      mode,
      // workOrderId,
      // workOrderParticipants,
      // employeesCanAddFilesToWorkTasks,
    } = this.props;
    const {
      selectedWorkTask,
      entryChanged,
      showNightShiftDialog,
      nightShift,
      // createWorkTaskDialogOpen,
      // newWorkTask,
    } = this.state;

    return (
      <>
        <div
          className="dialog-form-container"
          style={{ width: '100%' }}
        >
          <div style={{
            color: 'white',
            width: '100%',
            display: 'block',
            textAlign: 'center',
          }}
          >
            {this.renderSelect()}

            {((selectedWorkTask && mode === 'create') || mode !== 'create') && (
              <>
                <div style={{ width: '100%' }} className="hourly-dialog-table-content">
                  <div style={{ display: 'flex', justifyContent: 'center', margin: '10px 0 10px 0' }}>
                    <div style={{
                      display: 'flex', flexDirection: 'column', width: '100px',
                    }}
                    >
                      <span style={{ fontSize: '12px', textAlign: 'left' }}>Työaika alkoi</span>
                      {this.addHourFieldForm('from')}
                    </div>

                    <div
                      style={{
                        margin: '25px 15px 0 15px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        fontSize: '25px',
                      }}
                    >
                      -
                    </div>

                    <div style={{
                      display: 'flex', flexDirection: 'column', width: '100px',
                    }}
                    >
                      <span style={{ fontSize: '12px', textAlign: 'left' }}>Työaika loppui</span>
                      <div style={{ display: 'flex', width: 'fit-content' }}>
                        {this.addHourFieldForm('to')}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            marginLeft: '5px',
                            color: '#808080',
                            cursor: 'pointer',
                          }}
                          role="button"
                          onClick={() => {
                            this.setState({
                              showNightShiftDialog: true,
                            });
                          }}
                        >
                          <MoreVertIcon />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                  {this.addDecimalField('timeNormal', 'Normaali', '0', '5px')}
                  {this.addDecimalField('time50', '50%')}
                  {this.addDecimalField('time100', '100%', '5px', '0')}
                </div>

                <div
                  style={{
                    padding: 0,
                    textAlign: 'center',
                    width: '100%',
                  }}
                >
                  <MUITextField
                    className="mui-textfield-employee"
                    label="Selite"
                    variant="filled"
                    multiline
                    fullWidth
                    style={{
                      marginTop: '30px',
                    }}
                    disabled={this.props.disabled}
                    value={this.state?.description || ''}
                    onBlur={(event) => {
                      this.doOnBlurForm('description', event.target.value, event);
                    }}
                    onChange={(event) => {
                      this.doOnChange('description', event.target.value, event);
                    }}
                  />
                </div>
              </>
            )}
          </div>

          <div style={{ textAlign: 'end' }}>
            {mode === 'edit' && entryChanged && (
              <Button
                // className="add-hourly-row-btn"
                disabled={!(this.state.selectedWorkTask && (this.state.timeNormal || this.state.time50 || this.state.time100))}
                label="Tallenna muutokset"
                // outlined
                style={{
                  margin: '1rem 0rem',
                }}
                className="add-hourly-row-button-2"
                onClick={this.saveChanges}
              />
            )}
            {/* {mode === 'create' && (
              <Button
                className="add-hourly-row-btn"
                disabled={!this.state.selectedWorkTask}
                label="Tallenna tuntikirjaus"
                onClick={(e) => {
                  this.createNew();
                  e.stopPropagation();
                }}
              />
            )} */}
          </div>
        </div>

        <Dialog
          open={showNightShiftDialog}
          className="hourly-timelog-confirm-delete"
        >
          <DialogContent style={{ color: 'white' }}>
            <div style={{ width: '100%', textAlign: 'start', marginBottom: '10px' }}>
              {/* {this.addCheckbox('nightShift', 'Tuntikirjauksen alku on seuraavan päivämäärän puolella')} */}
              <ThemeProvider
                style={{ display: 'flex' }}
                options={{
                  primaryOnBackground: 'white',
                  secondary: '#FFC900',
                  textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
                }}
              >
                <Switch
                  className="pk-switch"
                  checked={nightShift}
                  onChange={(evt) => this.setState({
                    nightShift: evt.currentTarget.checked,
                    entryChanged: true,
                  })}
                  label="Järjestys"
                  use="text-hint-on-dark"
                />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginLeft: '5px',
                    color: '#808080',
                  }}
                >
                  {nightShift ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                </div>
              </ThemeProvider>
            </div>
          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'end',
              padding: '10px',
              flexDirection: 'row',
            }}
          >
            <DialogButton
              type="button"
              className="cancel-button"
              // action="close"
              onClick={() => {
                this.setState({
                  showNightShiftDialog: false,
                });
              }}
            >
              Takaisin
            </DialogButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default HourlyTimelogForm;
