import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
} from '@rmwc/card';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MessageIcon from '@material-ui/icons/Message';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import '@material/card/dist/mdc.card.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import './employerWorkOrderCard.css';
import moment, { shortDateRangeWithDay } from '../utils/moment';

const getDurationColorClasses = (from, to) => {
  const today = moment().startOf('day');

  if (today.isBetween(from, to)) {
    return 'work-order-card-border ongoing-color';
  }

  if (today.format('YYYY-MM-DD') < from.format('YYYY-MM-DD')) {
    return 'work-order-card-border upcoming-color';
  }
  // return 'work-order-date-range';
  return 'work-order-card-border archived-color';
};

@inject('timelogStore', 'uiStore', 't')
@observer
class EmployerWorkOrderCard extends Component {
  render() {
    const {
      from,
      to,
      workOrder: {
        id, name, participants, purchaser, invitationsCount, attachments, chat, workHourType, workTasksCount, billingId,
      },
      uiStore,
      uiStore: { currentUser: { accountInfo } },
      openEmployerChatDialog,
    } = this.props;

    let invitationsClass = 'invitations-neutral';
    if (invitationsCount && invitationsCount > participants.length) {
      invitationsClass = 'invitations-warning';
    } else if (invitationsCount && invitationsCount <= participants.length) {
      invitationsClass = 'invitations-ok';
    }

    return (
      <Card
        className={getDurationColorClasses(from, to)}
        style={{ backgroundColor: 'white', width: '100%', cursor: 'pointer' }}
        onClick={() => uiStore.showEmployerWorkOrder('edit', id)}
      >
        <Grid
          style={{ width: '100%' }}
        >
          <GridCell
            span={12}
          >
            <GridInner>
              <GridCell
                style={{ fontSize: '14px' }}
                desktop={9}
                tablet={6}
                phone={3}
              >
                <span>{name}</span>
                {accountInfo.billingIdsEnabled && billingId && (
                  <span className="work-order-billing-id">
                    {` ${billingId}`}
                  </span>
                )}
              </GridCell>
              <GridCell
                style={{
                  fontSize: '12px',
                  textAlign: 'right',
                  fontWeight: '500',
                  justifySelf: 'flex-end',
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
                desktop={3}
                tablet={2}
                phone={1}
              >
                <span
                  role="button"
                  className={invitationsClass}
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    width: 'fit-content',
                    height: 'fit-content',
                  }}
                  onClick={() => uiStore.setActiveTabAndShowWorkOrder('edit', id, 1)}
                >
                  <PeopleOutlineIcon
                    fontSize="small"
                    style={{
                      marginRight: '3px',
                    }}
                  />
                  <span>
                    {participants.length}
                    {invitationsCount && invitationsCount !== 0 ? `/${invitationsCount}` : null}
                  </span>
                </span>

                <span
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    openEmployerChatDialog(chat.id);
                  }}
                >
                  <MessageIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                  <span className="invitations-neutral">
                    {chat.totalMessagesCount}
                  </span>
                </span>
              </GridCell>
            </GridInner>
          </GridCell>

          <GridCell
            style={{ fontSize: '12px' }}
            span={12}
          >
            <GridInner>
              <GridCell
                desktop={3}
                tablet={2}
                phone={1}
                // style={{ opacity: 0.6 }}
              >
                <span className="employer-work-order-text-gray-sm">
                  {purchaser.name}
                </span>
              </GridCell>
              <GridCell
                style={{ textAlign: 'center' }}
                desktop={6}
                tablet={4}
                phone={2}
              >
                <span className="work-order-date-range employer-work-order-text-gray-sm">
                  {shortDateRangeWithDay(from, to)}
                </span>
              </GridCell>
              <GridCell
                style={{
                  fontSize: '12px',
                  textAlign: 'right',
                  // fontWeight: '500',
                  justifySelf: 'flex-end',
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
                desktop={3}
                tablet={2}
                phone={1}
              >
                {workHourType === 'hourly' && (
                  <span
                    role="button"
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                    }}
                    onClick={() => uiStore.setActiveTabAndShowWorkOrder('edit', id, 4)}
                  >
                    <FormatListBulletedIcon
                      fontSize="small"
                      style={{
                        color: 'black',
                        opacity: '1 !imporant',
                      }}
                    />
                    <span className="invitations-neutral">
                      {workTasksCount === 0 ? '-' : workTasksCount}
                    </span>
                  </span>
                )}

                <span
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                  onClick={() => uiStore.setActiveTabAndShowWorkOrder('edit', id, 3)}
                >
                  <AttachFileIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                  <span className="invitations-neutral">
                    {attachments.length === 0 ? '-' : attachments.length}
                  </span>
                </span>
              </GridCell>
            </GridInner>
          </GridCell>
        </Grid>
      </Card>
    );
  }
}

export default EmployerWorkOrderCard;
