import { action, observable, set } from 'mobx';
import moment from '../utils/moment';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

// const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class RouteLocation {
  @observable createdAt;

  @observable updatedAt;

  @observable city;

  @observable zipCode;

  @observable street;

  @observable locationOrder;

  @observable status;

  @observable tripRouteId;

  @observable _destroy;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      trip_route_id: o.tripRouteId,
      city: o.city,
      zip_code: o.zipCode,
      street: o.street,
      location_order: o.locationOrder,
      // status: (o.status == null) ? 'draft' : o.status,
      _destroy: (o._destroy == null) ? 0 : o._destroy,
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    // console.log('O: ', o);

    const values = {
      id: o.id,
      tripRouteId: o.trip_route_id,
      city: o.city,
      zipCode: o.zip_code,
      street: o.street,
      locationOrder: o.location_order,
      status: (o.status == null) ? 'draft' : o.status,
    };
    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const routeLocation = new RouteLocation({});
    routeLocation.updatePropertiesFromJson(object);
    return routeLocation;
  }

  static fromJson(json) {
    return RouteLocation.fromJsonProperties(JSON.parse(json));
  }
}
