import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';

import {
  Dialog, DialogTitle, DialogActions, DialogContent, Grid, Button, TextField,
} from '@material-ui/core';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

@inject('productStore', 'loginStore', 't')
@observer
class ProductFormDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.product,
      errors: {},
      inputChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { product } = this.props;

    if (prevProps.product?.id !== product?.id) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        id: product?.id,
        name: product?.name,
        code: product?.code,
        description: product?.description,
        errors: {},
      });
    }
  }

  addTextField(attr, label, required, styles, multiline) {
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        className: 'textarea-hack',
        label,
        required,
        name: attr,
        // rows: 5,
        invalid: errors[attr],
        helperText: errors[attr] && errors[attr],
        type: 'text',
        multiline,
        onChange: (event) => {
          const { value } = event.target;
          this.setState({
            [attr]: value,
            inputChanged: true,
          });

          delete errors[attr];
        },
        onBlur: (event) => {
          const { value } = event.target;
          if (!value && required) {
            this.setState((prevState) => ({
              errors: { ...prevState.errors, [attr]: `${label} on pakollinen tieto` },
            }));
          }
        },
        onKeyPress: handleEnter,
        /* eslint-disable-next-line react/destructuring-assignment */
        value: this.state[attr] != null ? this.state[attr] : '',
      }}
      />
    );
  }

  // eslint-disable-next-line class-methods-use-this
  saveProduct(product) {
    const { productStore, handleClose } = this.props;

    const updatePromise = fromPromise(new Promise((resolve, reject) => productStore.updateProduct(product, resolve, reject)));
    when(
      () => updatePromise.state !== 'pending',
      () => {
        updatePromise.case({
          pending: () => {},
          rejected: (err) => {
            console.error('Tallennus epäonnistui', err);
          },
          fulfilled: () => {
            handleClose();
            this.setState({
              id: null,
              name: null,
              code: null,
              description: null,
              errors: {},
              inputChanged: false,
            });
          },
        });
      },
    );
  }

  renderActionButtons() {
    const {
      inputChanged,
      errors,
    } = this.state;
    const { handleClose, product } = this.props;

    if (inputChanged && Object.keys(errors).length === 0) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => this.saveProduct({ ...this.state, id: product?.id })}
          >
            Tallenna
          </Button>
          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => {
              handleClose();
              this.setState({
                id: null,
                name: null,
                code: null,
                description: null,
                errors: {},
                inputChanged: false,
              });
            }}
          >
            Peruuta
          </Button>
        </DialogActions>
      );
    }
    return (
      <DialogActions>
        <Button
          type="button"
          className="employer-reject-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          onClick={() => {
            handleClose();
            this.setState({
              id: null,
              name: null,
              code: null,
              description: null,
              errors: {},
              inputChanged: false,
            });
          }}
        >
          Sulje
        </Button>
      </DialogActions>
    );
  }

  render() {
    const {
      open,
      product,
      handleClose,
    } = this.props;

    const styles = { backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' };

    return (
      <Dialog
        className="modal-size employer-modal"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>
          { product?.id ? 'Muokkaa tuotetta' : 'Uusi tuote' }
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} className="employer-work-order">
              {this.addTextField('name', 'Nimi', true, styles, false)}
            </Grid>
            <Grid item xs={12} className="employer-work-order">
              {this.addTextField('code', 'Tuotekoodi', false, styles, false)}
            </Grid>
            <Grid item xs={12} className="employer-work-order">
              {this.addTextField('description', 'Kuvaus', false, styles, true)}
            </Grid>
          </Grid>
          {product?.id && (
            <Grid item xs={12} style={{ fontSize: '12px', marginTop: '20px' }}>
              Muutokset tuotteen tiedoissa vaikuttavat myös menneisyyden raportteihin.
            </Grid>
          )}
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default ProductFormDialog;
