import { action, observable } from 'mobx';
import User from '../User';
import moment from '../../utils/moment';

export default class AccidentReport {
  @observable id;

  @observable createdAt;

  @observable reporter;

  @observable date;

  @observable time;

  @observable location;

  @observable workSteps;

  @observable eventSteps;

  @observable causes;

  @observable fixSuggestions;

  @observable relatedObject;

  @observable victimName;

  @observable victimUnit;

  @observable victimTask;

  @observable workFrom;

  @observable workTo;

  @observable victimStoppedWorking;

  @observable victimStoppedWorkingDate;

  @observable victimStoppedWorkingTime;

  @observable disablementDurationEstimate;

  @observable treatmentStartImmediacy;

  @observable treatmentStartDate;

  @observable treatmentStartTime;

  @observable treatmentPlace;

  @observable employerInformedDate;

  @observable employerInformedTime;

  @observable investigatorsInformedDate;

  @observable investigatorsInformedTime;

  @observable authoritiesInformedDate;

  @observable authoritiesInformedTime;

  @observable injuryType;

  @observable injuredBodypart;

  @observable leftBodyPart;

  @observable rightBodyPart;

  @observable status;

  // t.date :date
  // t.time :time
  // t.string :location
  // t.text :work_steps
  // t.text :event_steps
  // t.text :causes
  // t.text :fix_suggestions
  // t.string :related_object
  // t.text :response
  // t.datetime :responded_at
  // t.references :account, index: true
  // t.references :reporter, index: true, foreign_key: { to_table: :users }
  // t.references :responder, foreign_key: { to_table: :users }

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  static toJson(o) {
    return {
      id: o.id,
      date: o.date,
      time: o.time,
      location: o.location,
      work_steps: o.workSteps,
      event_steps: o.eventSteps,
      causes: o.causes,
      fix_suggestions: o.fixSuggestions,
      related_object: o.relatedObject,

      victim_name: o.victimName,
      victim_unit: o.victimUnit,
      victim_task: o.victimTask,
      work_from: o.workFrom,
      work_to: o.workTo,
      victim_stopped_working_immediacy: o.victimStoppedWorkingImmediacy,
      victim_stopped_working_date: o.victimStoppedWorkingDate,
      victim_stopped_working_time: o.victimStoppedWorkingTime,
      disablement_duration_estimate: o.disablementDurationEstimate,
      treatment_start_immediacy: o.treatmentStartImmediacy,
      treatment_start_date: o.treatmentStartDate,
      treatment_start_time: o.treatmentStartTime,
      treatment_place: o.treatmentPlace,
      employer_informed_date: o.employerInformedDate,
      employer_informed_time: o.employerInformedTime,
      investigators_informed_date: o.investigatorsInformedDate,
      investigators_informed_time: o.investigatorsInformedTime,
      authorities_informed_date: o.authoritiesInformedDate,
      authorities_informed_time: o.authoritiesInformedTime,
      injury_type: o.injuryType,
      injured_body_part: o.injuredBodyPart,
      left_body_part: o.leftBodyPart,
      right_body_part: o.rightBodyPart,
      status: o.status,
    };
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      id: o.id,
      createdAt: moment(o.created_at).local(),
      reporter: User.fromJsonProperties(o.reporter),
      date: moment(o.date),
      time: moment(o.time),
      location: o.location,
      workSteps: o.work_steps,
      eventSteps: o.event_steps,
      causes: o.causes,
      fixSuggestions: o.fix_suggestions,
      relatedObject: o.related_object,

      victimName: o.victim_name,
      victimUnit: o.victim_unit,
      victimTask: o.victim_task,
      workFrom: o.work_from,
      workTo: o.work_to,
      victimStoppedWorkingImmediacy: o.victim_stopped_working_immediacy,
      victimStoppedWorkingDate: o.victim_stopped_working_date,
      victimStoppedWorkingTime: o.victim_stopped_working_time,
      disablementDurationEstimate: o.disablement_duration_estimate,
      treatmentStartImmediacy: o.treatment_start_immediacy,
      treatmentStartDate: o.treatment_start_date,
      treatmentStartTime: o.treatment_start_time,
      treatmentPlace: o.treatment_place,
      employerInformedDate: o.employer_informed_date,
      employerInformedTime: o.employer_informed_time,
      investigatorsInformedDate: o.investigators_informed_date,
      investigatorsInformedTime: o.investigators_informed_time,
      authoritiesInformedDate: o.authorities_informed_date,
      authoritiesInformedTime: o.authorities_informed_time,
      injuryType: o.injury_type,
      injuredBodyPart: o.injured_body_part,
      leftBodyPart: o.left_body_part,
      rightBodyPart: o.right_body_part,
      status: o.status,
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const accidentReport = new AccidentReport({});
    accidentReport.updatePropertiesFromJson(o);
    return accidentReport;
  }
}
