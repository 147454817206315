import { observable, action } from 'mobx';

export default class Purchaser {
  @observable id;

  @observable name;

  @observable city;

  @observable zipCode;

  @observable street;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      name: o.name,
      city: o.city,
      zipCode: o.zip_code,
      street: o.street,
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const purchaser = new Purchaser({});
    purchaser.updatePropertiesFromJson(object);
    return purchaser;
  }

  static fromJson(json) {
    return Purchaser.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    return {
      id: o.id,
      name: o.name,
      city: o.city,
      zip_code: o.zipCode,
      street: o.street,
    };
  }
}
