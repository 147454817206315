import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
} from '@rmwc/card';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';

import moment, { asTime } from '../utils/moment';

@inject('timelogStore', 'uiStore', 't')
@observer
class CloseCallReportCard extends Component {
  render() {
    const {
      closeCallReport: {
        // id, date, time, createdAt, responder, workSteps, eventSteps, causes, fixSuggestions, relatedObject, respondedAt,
        date, time, reporter, createdAt, location, workSteps, relatedObject, eventSteps, causes, fixSuggestions, response,
      },
      closeCallReport,
      employer,
      openShowDialog,
    } = this.props;

    return (
      <Card
        style={{ width: '100%', backgroundColor: employer ? 'white' : '' }}
        onClick={() => openShowDialog(closeCallReport)}
      >
        <Grid
          style={{ width: '100%' }}
        >
          <GridInner
            style={{
              rowGap: '8px',
            }}
          >
            <GridCell
              span={12}
              style={{ borderBottom: '1px dimgray solid', paddingBottom: '10px' }}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '16px', fontWeight: 'bold' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    {moment(createdAt).format('dd DD.MM.YYYY HH:mm')}
                  </span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '16px', fontWeight: 'bold', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>
                    {reporter.firstName}
                    &nbsp;
                    {reporter.lastName}
                  </span>
                  {response && (
                    <Icon icon="done" style={{ color: '#5DB85F' }} />
                  )}
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '14px' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{location}</span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '14px', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span style={{ textTransform: 'capitalize' }}>
                    {moment(date).format('dd DD.MM.YYYY')}
                    &nbsp;
                    {asTime(time)}
                  </span>
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '14px' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{workSteps}</span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '14px', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{eventSteps}</span>
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell
              span={12}
              style={{ fontSize: '14px' }}
            >
              <span>{relatedObject}</span>
            </GridCell>

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  style={{ fontSize: '14px' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{causes}</span>
                </GridCell>
                <GridCell
                  style={{ fontSize: '14px', textAlign: 'right' }}
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <span>{fixSuggestions}</span>
                </GridCell>
              </GridInner>
            </GridCell>

          </GridInner>
        </Grid>
      </Card>
    );
  }
}

export default CloseCallReportCard;
