import { action, observable, set } from 'mobx';
import moment from '../utils/moment';
import EndTrip from './EndTrip';
import StartTrip from './StartTrip';
import TripRoute from './TripRoute';

export default class WorkOrderTrip {
  @observable id;

  @observable userId;

  @observable status;

  @observable startTrip;

  @observable endTrip;

  @observable allowanceAutofillEnabled;

  @observable workOrders = [];

  @observable taxExemptTripExpenses = {};

  @observable tripRoutes = {};

  // The list of all statuses present (StartTrip + EndTrip + TripRoutes + TaxExemptTripExpenses statuses)
  @observable statuses = [];

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  isAccepted() {
    return this.statuses.length === 1 && this.statuses.includes('accepted');
  }

  static toJson(o) {
    return {
      id: o.id,
      user_id: o.userId,
      // status: o.status === 'draft' && 0,
      status: o.status,
      // start_trip_attributes: o.startTrip.map(startTrip => StartTrip.toJson(startTrip)),
      // end_trip_attributes: o.endTrip.map(endTrip => EndTrip.toJson(endTrip)),
      start_trip_attributes: StartTrip.toJson(o.startTrip),
      end_trip_attributes: EndTrip.toJson(o.endTrip),
      allowance_autofill_enabled: o.allowanceAutofillEnabled,
      work_order_ids: o.workOrders.map((workOrder) => workOrder.id),
    };
  }

  // Used for employer acceptance filtering
  @action setStatuses() {
    const statuses = [];

    statuses.push(this.startTrip.status);

    if (this.endTrip.status !== 'draft') {
      statuses.push(this.endTrip.status);
    }

    Object.keys(this.tripRoutes).forEach((salaryPeriod) => {
      Object.keys(this.tripRoutes[salaryPeriod]).forEach((workOrderName) => {
        this.tripRoutes[salaryPeriod][workOrderName].forEach((tripRoute) => {
          statuses.push(tripRoute.status);
        });
      });
    });

    Object.keys(this.taxExemptTripExpenses).forEach((category) => {
      Object.keys(this.taxExemptTripExpenses[category]).forEach((salaryPeriod) => {
        Object.keys(this.taxExemptTripExpenses[category][salaryPeriod]).forEach((workOrderName) => {
          this.taxExemptTripExpenses[category][salaryPeriod][workOrderName].forEach((expense) => {
            statuses.push(expense.status);
          });
        });
      });
    });

    this.statuses = [...new Set(statuses)];
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    const assign = Object.assign(this, data);
    this.setStatuses();
    return assign;
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      status: o.status == null ? 'draft' : o.status, // MIGHT NEED TO CHANGE THIS
      id: o.id,
      userId: o.user_id,
      allowanceAutofillEnabled: o.allowance_autofill_enabled,
      workOrders: o.work_orders,

      taxExemptTripExpenses: o.tax_exempt_trip_expenses || {},
      // tripRoutes: Object.keys(o.trip_routes).map((workOrderWithTripRoutes) => {
      //   console.log('workOrderWithTripRoutes: ', workOrderWithTripRoutes);
      //   console.log('Should return an array of trip routes: ', (o.trip_routes[workOrderWithTripRoutes]?.map((tripRoute) => TripRoute.fromJsonProperties(tripRoute)) || []));

      //   return (o.trip_routes[workOrderWithTripRoutes]?.map((tripRoute) => TripRoute.fromJsonProperties(tripRoute)) || []);
      // }),
      tripRoutes: {},
      userName: o.user_name,
      acceptingEmployers: o.accepting_employers,
      workOrderNames: o.work_order_names,
      user: o.user,
      // 1. API REQUEST JSON ATTRIBUTES HERE TO GET THE FILTERS WORKING
      // 2. MAYBE MAKE A SEPARATE EMPLOYER FROM JSON
    };

    // if (o.trip_routes) {
    //   Object.keys(o.trip_routes).forEach((workOrderName) => {
    //     values.tripRoutes[workOrderName] = o.trip_routes[workOrderName]?.map((tripRoute) => TripRoute.fromJsonProperties(tripRoute));
    //   });
    // }

    if (o.trip_routes) {
      Object.keys(o.trip_routes).forEach((salaryPeriod) => {
        Object.keys(o.trip_routes[salaryPeriod]).forEach((workOrderName) => {
          if (!values.tripRoutes[salaryPeriod]) {
            values.tripRoutes[salaryPeriod] = {};
          }
          values.tripRoutes[salaryPeriod][workOrderName] = o.trip_routes[salaryPeriod][workOrderName]?.map((tripRoute) => TripRoute.fromJsonProperties(tripRoute));
        });
      });
    }

    // let startTrip = [];
    // if (o.start_trip != null) {
    //   if (Array.isArray(o.start_trip)) {
    //     startTrip = o.start_trip.map((item) => StartTrip.fromJsonProperties(item));
    //   } else {
    //     startTrip.push(StartTrip.fromJsonProperties(o.start_trip));
    //   }
    // } else {
    //   startTrip.push(new StartTrip({}));
    // }
    // values.startTrip = startTrip;

    values.startTrip = StartTrip.fromJsonProperties(o.start_trip);

    // let endTrip = [];
    // if (o.end_trip != null) {
    //   if (Array.isArray(o.end_trip)) {
    //     endTrip = o.end_trip.map((item) => EndTrip.fromJsonProperties(item));
    //   } else {
    //     endTrip.push(EndTrip.fromJsonProperties(o.end_trip));
    //   }
    // } else {
    //   endTrip.push(new EndTrip({}));
    // }
    // values.endTrip = endTrip;
    values.endTrip = EndTrip.fromJsonProperties(o.end_trip);

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const workOrderTrip = new WorkOrderTrip({});
    workOrderTrip.updatePropertiesFromJson(object);
    workOrderTrip.setStatuses();
    return workOrderTrip;
  }

  static fromJson(json) {
    return WorkOrderTrip.fromJsonProperties(JSON.parse(json));
  }

  // static processTripsMeta(workOrderTrip) {
  //   const updatedWorkOrderTrip = workOrderTrip;

  //   updatedWorkOrderTrip.start_trip = StartTrip.fromJsonProperties(updatedWorkOrderTrip.start_trip);

  //   if (updatedWorkOrderTrip.old_start_trip) {
  //     updatedWorkOrderTrip.old_start_trip = StartTrip.fromJsonProperties(updatedWorkOrderTrip.old_start_trip);
  //   }

  //   updatedWorkOrderTrip.end_trip = EndTrip.fromJsonProperties(updatedWorkOrderTrip.end_trip);
  //   updatedWorkOrderTrip.end_trip.time = updatedWorkOrderTrip.end_trip.time ? updatedWorkOrderTrip.end_trip.time : null;
  //   updatedWorkOrderTrip.end_trip.toTime = updatedWorkOrderTrip.end_trip.toTime ? updatedWorkOrderTrip.end_trip.toTime : null;

  //   if (updatedWorkOrderTrip.old_end_trip) {
  //     updatedWorkOrderTrip.old_end_trip = EndTrip.fromJsonProperties(updatedWorkOrderTrip.old_end_trip);
  //   }

  //   return updatedWorkOrderTrip;
  // }
}
