import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@material-ui/core';
import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';

import AddIcon from '@material-ui/icons/Add';

import WorkTaskCard from './WorkTaskCard';
import WorkTaskDialog from './WorkTaskDialog';
import WorkTask from './WorkTask';
import WorkTaskTable from './WorkTaskTable';
import LoadingSpinner from '../../shared/LoadingSpinner';

@inject('uiStore', 'loginStore', 't', 'workTaskStore')
@observer
class WorkTasks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showAddNewWorkOrder: false,
      // workTaskId: null,
      // workTaskIdentifierOne: null,
      // workTaskIdentifierTwo: null,
      // workTaskDescription: null,
      // parentId: null,
      workTasks: null,
      workTaskDialogOpen: false,
      showDeleteDialog: false,
      deleteInfo: {},
      generatingPdf: false,
      viewMode: props.uiStore.mobileMode ? 'mobile' : 'desktop',
    };
  }

  componentDidMount() {
    const { uiStore: { currentView } } = this.props;

    // this.updateWindowDimensions();
    // window.addEventListener('resize', this.updateWindowDimensions);

    if (currentView.selectedFilter) {
      this.setState({
        selectedFilter: currentView.selectedFilter, // eslint-disable-line
      });
    }

    this.queryWorkTasks();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  // updateWindowDimensions = () => {
  //   this.setState({ width: window.innerWidth });
  // }

  showWorkTaskDialog = (workTask) => {
    this.setState({
      workTaskDialogOpen: true,
      openWorkTask: workTask || new WorkTask(),
    });
  }

  showWorkTaskChildDialog = (parentId = null) => {
    this.setState({
      workTaskDialogOpen: true,
      openWorkTask: WorkTask.fromJsonProperties({ parent_id: parentId }),
    });
  }

  handleClose = () => {
    this.setState({
      workTaskDialogOpen: false,
      openWorkTask: new WorkTask(),
    });
  }

  updateWorkTaskState = (newWorkTask) => {
    // Skip this for child work tasks, otherwise they're pushed as parents into the UI in addition to pushed as children
    if (!newWorkTask.parentId) {
      const { workTasks } = this.state;
      const foundIndex = workTasks.findIndex((workTask) => workTask.id === newWorkTask.id);
      const updatedWorkTasks = [...workTasks];

      if (foundIndex !== -1) {
        updatedWorkTasks[foundIndex] = newWorkTask;
      } else {
        updatedWorkTasks.push(newWorkTask);
      }
      this.setState({
        workTasks: updatedWorkTasks,
      });
    }
  }

  deleteWorkTaskFileFromState = (workTaskId, workTaskParentId, signedIds, callback = null) => {
    const { workTasks } = this.state;
    const updatedWorkTasks = [...workTasks];

    if (!workTaskParentId) {
      const foundIndex = workTasks.findIndex((workTask) => workTask.id === workTaskId);

      if (foundIndex !== -1) {
        updatedWorkTasks[foundIndex].files = updatedWorkTasks[foundIndex].files.filter((file) => !signedIds.includes(file.signedId));
      }
    } else {
      const parentIndex = workTasks.findIndex((workTask) => workTask.id === workTaskParentId);
      const childIndex = workTasks[parentIndex]?.childTasks.findIndex((childTask) => childTask.id === workTaskId);

      if (parentIndex !== -1 && childIndex !== -1) {
        updatedWorkTasks[parentIndex].childTasks[childIndex].files = updatedWorkTasks[parentIndex].childTasks[childIndex].files.filter((file) => !signedIds.includes(file.signedId));
      }
    }

    this.setState({
      workTasks: updatedWorkTasks,
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  deleteWorkTaskState = (deletedId) => {
    // Assuming no work task hierarchy (parentId)
    const { workTasks } = this.state;
    const foundIndex = workTasks.findIndex((workTask) => workTask.id === deletedId);
    const updatedWorkTasks = [...workTasks];

    if (foundIndex !== -1) {
      updatedWorkTasks.splice(foundIndex, 1);
    }

    this.setState({
      workTasks: updatedWorkTasks,
    });
  }

  handleAttachmentAction = (workTask) => {
    const { workTaskDialogOpen } = this.state;
    const { workTaskStore } = this.props;

    if (!workTask.id) {
      const createPromise = new Promise((resolve) => workTaskStore.createWorkTask(workTask, resolve));
      createPromise.then((newTask) => {
        this.updateWorkTaskState(newTask);
        // A hack to update the file list
        if (workTaskDialogOpen) {
          this.setState({ openWorkTask: newTask });
        }
      });
      // employerWorkOrderStore.createWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    } else {
      const updatePromise = new Promise((resolve) => workTaskStore.updateWorkTask(workTask, resolve));
      updatePromise.then((updatedTask) => {
        this.updateWorkTaskState(updatedTask);
        // A hack to update the file list
        if (workTaskDialogOpen) {
          this.setState({ openWorkTask: updatedTask });
        }
      });
      // employerWorkOrderStore.updateWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    }
  }

  confirmDelete = (target, workTaskId, workOrderId, name, signedId, workTaskParentId) => {
    let deleteMessage;

    if (target === 'file') {
      deleteMessage = `Haluatko varmasti poistaa tiedoston ${name}`;
    } else if (target === 'attachment') {
      deleteMessage = `Haluatko varmasti poistaa liitteen ${name}`;
    }

    this.setState({
      showDeleteDialog: true,
      // deleteInfo.id, deleteInfo.workOrderId, deleteInfo.workTaskParentId, deleteInfo.workTaskId
      deleteInfo: {
        target,
        workTaskId,
        workOrderId,
        deleteMessage,
        workTaskParentId,
        signedIds: [signedId],
      },
    });
  }

  handleDeletion = (deleteInfo) => {
    // const { workTaskDialogOpen } = this.state;
    const { workTaskStore } = this.props;

    if (deleteInfo.target === 'file') {
      console.log('Deleting file');
      console.log('Delete info: ', deleteInfo);

      // Assuming that this is triggered from the single file delete button, hence the deleteInfo.signedIds[0]
      // Does not support deleting with multiple signed IDs at the moment
      const fileDeletionPromise = new Promise((resolve) => workTaskStore.deleteFile(deleteInfo.signedIds[0], deleteInfo.workOrderId, deleteInfo.workTaskParentId, deleteInfo.workTaskId, resolve));
      fileDeletionPromise.then(() => {
        // updateWorkTaskState(updatedTask);

        // deleteFileFromState = (workTaskId, workTaskParentId, fileSignedId)
        this.deleteWorkTaskFileFromState(deleteInfo.workTaskId, deleteInfo.workTaskParentId, deleteInfo.signedIds);

        // A hack to update the file list
        // if (workTaskDialogOpen) {
        //   this.setState({ openWorkTask: updatedTask });
        // }
      });
    }
  }

  // deleteFileFromStateWithSignedId = (id, signedId) => {
  updateTask = (updatedTask) => {
    this.updateWorkTaskState(updatedTask); // eslint-disable-line
    // A hack to update the file list
    if (workTaskDialogOpen) { // eslint-disable-line
      this.setState({ openWorkTask: updatedTask });
    }
  }

  queryWorkTasks() {
    const { workTaskStore, workOrderId } = this.props;

    const workTasksGetPromise = new Promise((resolve) => workTaskStore.getWorkTasksByWorkOrder(workOrderId, resolve));
    workTasksGetPromise.then((res) => {
      this.setState({
        workTasks: res,
      });
    });
  }

  renderWorkTasks() {
    const { workTasks } = this.state;
    return (
      <GridInner
        style={{
          rowGap: '4px',
        }}
      >
        {workTasks.map((task) => (
          <GridCell
            desktop={6}
            tablet={4}
            key={`woitem-${task.id}`}
          >
            <WorkTaskCard
              mode="employer"
              key={`task-${task.id}`}
              handleCardClick={this.showWorkTaskDialog}
              // childTasks={task.childTasks}
              workTask={task}
            />
          </GridCell>
        ))}
      </GridInner>
    );
  }

  renderDeleteAttachmentDialog() {
    const { showDeleteDialog, deleteInfo } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.handleDeletion(deleteInfo);
          }
          this.setState({
            showDeleteDialog: false,
            deleteInfo: {},
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{deleteInfo.deleteMessage}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            action="accept"
          >
            Kyllä
          </DialogButton>
          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const {
      workOrderId,
      workOrderParticipants,
    } = this.props;
    const {
      // selectedFilter,
      // width,
      workTaskDialogOpen,
      openWorkTask,
      generatingPdf,
      viewMode,
      workTasks,
    } = this.state;

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0px' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
              style={{
                margin: viewMode === 'mobile' && '0px -16px',
                display: 'flex',
              }}
            >
              <Typography
                style={{
                  paddingLeft: '25px',
                  flexGrow: 1,
                }}
                use="headline6"
                tag="h2"
                className="headline"
              >
                Työt
              </Typography>

              {viewMode === 'desktop' && (
                <Button
                  className="employer-accept-button"
                  type="button"
                  style={{
                    borderRadius: '0px',
                    minWidth: '100px',
                    width: 'fit-content',
                    height: 'fit-content',
                    margin: '16px',
                  }}
                  onClick={() => this.showWorkTaskDialog()}
                  startIcon={<AddIcon />}
                >
                  Uusi työ
                </Button>
              )}
            </GridCell>

            {!workTasks && (
              <GridCell span={12}>
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <LoadingSpinner color="black" />
                </div>
              </GridCell>
            )}

            {workTasks && viewMode === 'mobile' && (
              <GridCell
                style={{ padding: '0px 16px' }}
                desktop={12}
                tablet={8}
                phone={4}
              >
                {this.renderWorkTasks()}
              </GridCell>
            )}

            {workTasks && viewMode === 'desktop' && (
              <GridCell
                style={{ padding: '0px 16px' }}
                desktop={12}
                tablet={8}
                phone={4}
              >
                <WorkTaskTable
                  workTasks={workTasks}
                  editWorkTask={this.showWorkTaskDialog}
                  handleAttachmentAction={this.handleAttachmentAction}
                />
              </GridCell>
            )}

            {viewMode === 'mobile' && (
              <GridCell span={12} style={{ textAlign: 'center', marginTop: '20px' }}>
                <Button
                  startIcon={<AddIcon />}
                  className="employer-accept-button"
                  style={{
                    borderRadius: '0px',
                  }}
                  type="button"
                  onClick={() => this.showWorkTaskDialog()}
                >
                  Uusi Työ
                </Button>
              </GridCell>
            )}
          </GridInner>
        </Grid>

        <WorkTaskDialog
          open={workTaskDialogOpen}
          styles={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' }}
          workOrderId={workOrderId}
          handleAttachmentAction={this.handleAttachmentAction}
          handleGeneratePDF={this.handleGeneratePDF}
          handleClose={this.handleClose}
          workTask={openWorkTask}
          updateWorkTaskState={this.updateWorkTaskState}
          deleteWorkTaskState={this.deleteWorkTaskState}
          confirmDelete={this.confirmDelete}
          workOrderParticipants={workOrderParticipants}
          generatingPdf={generatingPdf}
          deleteWorkTaskFileFromState={this.deleteWorkTaskFileFromState}
          mode="employer"
        />
        {this.renderDeleteAttachmentDialog()}
      </>
    );
  }
}

export default WorkTasks;
