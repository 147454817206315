import { action } from 'mobx';
import TimelogEntry from '../timelog/TimelogEntry';

class ReportStore {
  constructor(requests) {
    this.requests = requests;
    // this.uiStore = uiStore;
  }

  // Generic work hour report, usually for a salary period
  @action getWorkHourReport(from, to, resolve, reject) {
    // const ctxUser = this.uiStore.currentUser;

    return this.requests.TimeLogs.getWorkHourReport(from, to).then((result) => {
      console.log('RESULT: ', result);

      const rows = result.rows.map((item) => TimelogEntry.fromJsonProperties(item));
      resolve(rows);
    }).catch((err) => reject(err));
  }

  // This action retrieves a work order report for the specified workOrderId
  @action getWorkOrderReportByUser(workOrderId, resolve, reject) {
    return this.requests.WorkOrders.getWorkOrderReportByUser(workOrderId).then((result) => {
      // The request was successful, perform transformations on the result

      // Map over each workHourObject in the workHours array and transform the workHour property
      // eslint-disable-next-line no-param-reassign
      result.workHours = result.workHours.map((workHourObject) => ({ ...workHourObject, workHour: TimelogEntry.fromJsonProperties(workHourObject.workHour) }));

      // Resolve the Promise with the transformed result
      resolve(result);
    }).catch((err) => reject(err));
  }

  // Used in billingreport.jsx retrieves the workorder and workhours and filters it by dates.
  @action
  getWorkOrderReportByUserFiltered(workOrderId, from, to, resolve, reject) {
    return this.requests.WorkOrders.getWorkOrderReportByUser(workOrderId).then((result) => {
      // Convert the from and to dates to JavaScript Date objects
      const fromDate = new Date(from);
      const toDate = new Date(to);

      // Filter the work hours based on the specified date range
      const filteredWorkHours = result.workHours.filter((workHourObject) => {
        const workHourDate = new Date(workHourObject.workHour.date);
        return workHourDate >= fromDate && workHourDate <= toDate;
      });

      // Map over each filtered workHourObject and transform the workHour property
      // eslint-disable-next-line
      result.workHours = filteredWorkHours.map((workHourObject) => ({
        ...workHourObject,
        workHour: TimelogEntry.fromJsonProperties(workHourObject.workHour),
      }));

      // Resolve the Promise with the transformed result
      resolve(result);
    }).catch((err) => reject(err));
  }
}

export default ReportStore;
