import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@material-ui/core';
import { Grid, GridCell } from '@rmwc/grid';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from '@rmwc/dialog';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import TimeField from 'react-simple-timefield';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';
import moment from '../utils/moment';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const validateDate = (date) => {
  console.log('VALIDATING DATE: ', date);
  if (!date) {
    return 'Pvm on pakollinen';
  }
  return null;
};

const validateTime = (time) => {
  if (!time) {
    return 'Klo on pakollinen';
  }
  return null;
};

const validateWorkSteps = (workSteps) => {
  if (!workSteps) {
    return 'Vaiheet ovat pakollisia';
  }
  return null;
};

const validateEventSteps = (eventSteps) => {
  if (!eventSteps) {
    return 'Tapahtumien kulku on pakollinen';
  }
  return null;
};

const validateCauses = (causes) => {
  if (!causes) {
    return 'Vaaratilanteen aiheuttajat ovat pakollisia';
  }
  return null;
};

const validateFixSuggestions = (fixSuggestions) => {
  if (!fixSuggestions) {
    return 'Ehdotukset ovat pakollisia';
  }
  return null;
};

const validateRelatedObject = (relatedObject) => {
  if (!relatedObject) {
    return 'Tapahtumiin osallinen asia on pakollinen';
  }
  return null;
};

const validate = {
  date: (date) => validateDate(date),
  time: (time) => validateTime(time),
  workSteps: (workSteps) => validateWorkSteps(workSteps),
  eventSteps: (eventSteps) => validateEventSteps(eventSteps),
  causes: (causes) => validateCauses(causes),
  fixSuggestions: (fixSuggestions) => validateFixSuggestions(fixSuggestions),
  relatedObject: (relatedObject) => validateRelatedObject(relatedObject),
};

@inject('uiStore', 'loginStore', 't', 'closeCallReportStore')
@observer
class NewCloseCallReportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // id: props.id,
      // description: props.description,
      // fileURL: '',
      // filename: '',
      // files: props.files || [],
      // file: props.file,
      // name: props.name,
      // status: props.status,
      errors: {},
      // inputChanged: false,
      closeCallReport: {},
    };
  }

  addHourField(attr) {
    const { closeCallReport } = this.state;
    const { errors } = this.state;

    return (
      <div
        className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
        style={{
          width: '100%',
          backgroundColor: 'var(--mdc-theme-secondary)',
          color: 'white',
          flexDirection: 'column',
        }}
      >
        <div style={{ fontSize: '12px', paddingLeft: '5px' }}>
          Klo.
        </div>
        <TimeField
          value={(this.state != null && closeCallReport[attr] != null && closeCallReport[attr] !== '') ? closeCallReport[attr] : '00:00'}
          onChange={(_, value) => {
            if (value.includes(':')) {
              value = value.replace('.', ''); {/* eslint-disable-line */}
            } else {
              value = value.replace('.', ':'); {/* eslint-disable-line */}
            }
            this.setState((prevState) => ({
              closeCallReport: { ...prevState.closeCallReport, [attr]: value },
            }));
          }}
          invalid={errors[attr]}
          colon=":"
          style={{ color: 'black' }}
          input={<input type="text" className="mdc-text-field__input" />}
          onKeyPress={handleEnter}
          onClick={(event) => event.target.setSelectionRange(0, 0)}
        />
      </div>
    );
  }

  addTextField(attr, label, required, styles = null, multiline, variant, applyStyle = false) {
    const { errors, closeCallReport } = this.state;

    const inputStyles = applyStyle ? { marginTop: '10px' } : {};

    return (
      <TextField {...{
        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
        style: { ...styles },
        label,
        required,
        name: attr,
        variant,
        rows: 4,
        invalid: errors[attr],
        helperText: errors[attr] && errors[attr],
        multiline,
        inputProps: {
          style: {
            ...inputStyles,
          },
        },
        onChange: (event) => {
          const { value } = event.target;
          this.setState((prevState) => ({
            closeCallReport: { ...prevState.closeCallReport, [attr]: value },
          }));
        },
        onKeyPress: handleEnter,
        value: closeCallReport[attr] != null ? closeCallReport[attr] : '',
      }}
      />
    );
  }

  handleValidate() {
    const { closeCallReport } = this.state;

    let errors = {};

    Object.keys(validate).forEach((key) => {
      const error = validate[key](closeCallReport[key]);
      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    this.setState({
      errors: { ...errors },
    });
  }

  renderActionButtons() {
    const {
      closeCallReport: {
        date,
        time,
        workSteps,
        eventSteps,
        relatedObject,
        causes,
        fixSuggestions,
      },
    } = this.state;

    return (
      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <DialogButton
          type="button"
          className="accept-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          onClick={() => {
            this.handleValidate();
          }}
          // action="submit"
          action={date && time && workSteps && eventSteps && relatedObject && causes && fixSuggestions ? 'submit' : null}
        >
          Lähetä
        </DialogButton>
        <DialogButton
          type="button"
          style={{
            color: '#c7c9cc',
            border: '1px solid #c7c9cc',
            borderRadius: '0px',
            minWidth: '100px',
          }}
          action="cancel"
        >
          Peruuta
        </DialogButton>
      </DialogActions>
    );
  }

  render() {
    const {
      open,
      styles,
      handleClose,
      closeCallReportStore,
    } = this.props;
    const { closeCallReport, errors } = this.state;

    // WHAT IS THIS?
    // const inputRef = React.createRef();

    return (
      <Dialog
        className="modal-size trip-route-dialog red-helper-texts"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'submit' && Object.keys(errors).length === 0) {
            // const error = validate.attachments(description, files);
            // if (!error) {
            const sendPromise = fromPromise(new Promise((resolve, reject) => closeCallReportStore.send(closeCallReport, resolve, reject)));

            when(
              () => sendPromise.state !== 'pending',
              () => {
                sendPromise.case({
                  pending: () => {
                  },
                  rejected: (err) => {
                    console.log('Tallennus epäonnistui', err);
                  },
                  fulfilled: (newCloseCallReport) => {
                    this.props.newCloseCallReport(newCloseCallReport); // eslint-disable-line
                    handleClose();
                  },
                });
              },
            );
            // }
            handleClose();
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle style={{ color: 'var(--mdc-theme-primary, #6200ee)' }}>Uusi vaaratilanneilmoitus</DialogTitle>
        <DialogContent>
          {/* hack to remove autofocus on calendar */}
          <input style={{ width: '1px', height: '0px', opacity: '0' }} />
          <Grid>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              <DayPickerInput
                component={
                  (props) => (
                    <TextField
                      {...props}
                      {...{
                        variant: 'filled',
                        required: true,
                        label: 'Tapahtuma-pvm.',
                        className: 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
                        placeholder: '',
                        helperText: errors.date && errors.date,
                        style: { width: '100%' },
                      }}
                    />
                  )
                }
                inputProps={{
                  ref: null,
                  className: '',
                  readOnly: true,
                }}
                dayPickerProps={{
                  locale: 'fi',
                  localeUtils: MomentLocaleUtils,
                }}

                formatDate={formatDate}
                parseDate={parseDate}
                keepFocus={false}
                invalid={errors.date}
                helpText={errors.date && errors.date}
                format="dd DD.MM.YYYY"
                onDayChange={(value) => this.setState((prevState) => ({
                  closeCallReport: { ...prevState.closeCallReport, date: moment(value) },
                }))}
                value={closeCallReport.date ? moment(closeCallReport.date, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                classNames={{
                  container: '',
                  overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  overlay: 'DayPickerInput-Overlay forced-white-text',
                }}
              />
            </GridCell>
            <GridCell
              desktop={6}
              tablet={4}
              phone={2}
            >
              {this.addHourField('time')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('location', 'Tapahtumapaikka', false, { ...styles, width: '100%' }, false, 'filled')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('workSteps', 'Työvaihe tai -vaiheet, joita tehtiin', true, { ...styles, width: '100%' }, true, 'filled')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('relatedObject', 'Tapahtumiin osallinen kone, laite tai kemiallinen aine', true, { ...styles, width: '100%' }, false, 'filled', true)}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('eventSteps', 'Tapahtumien kulku vaiheittain', true, { ...styles, width: '100%' }, true, 'filled')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('causes', 'Vaaratilanteen aiheuttajat', true, { ...styles, width: '100%' }, true, 'filled')}
            </GridCell>

            <GridCell span={12}>
              {this.addTextField('fixSuggestions', 'Ehdotetut korjaustoimenpiteet', true, { ...styles, width: '100%' }, true, 'filled')}
            </GridCell>
          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default NewCloseCallReportDialog;
