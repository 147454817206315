import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import {
  CardActionButton,
  CardActions,
  CardActionButtons,
} from '@rmwc/card';
import {
  ListDivider,
} from '@rmwc/list';
import { Button } from '@material-ui/core';

import {
  Transition,
  animated,
  config,
} from 'react-spring';

import WorkOrderItem from './WorkOrderItem';
import { buttonColors, buttonStyle } from '../styles';

const TransitionedInvitations = (props) => {
  const {
    t,
    uiStore,
    items,
    shortDescription,
    showArrow,
    skipDescription,
    acceptInvitation,
    rejectInvitation,
  } = props;

  return (
    <Transition
      items={items}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      // delay={100}
      config={config.default}
      keys={(item) => `transition_${item.id}`}
    >
      {(styles, item) => (
        <animated.div style={styles}>
          <WorkOrderItem {...{
            item,
            key: item.id,
            shortDescription,
            showArrow,
            skipDescription,
            useGap: true,
            onClick: () => {
              if (item.isInvitation !== true) {
                uiStore.showWorkOrder(item.id);
              }
            },
            actionButtons: item.isInvitation === true ? (
              <>
                <hr
                  style={{
                    borderBottomWidth: 0,
                    borderColor: '#8B9898',
                    width: '90%',
                  }}
                />
                <Typography
                  style={{ padding: '0.5rem 1rem', textAlign: 'center' }}
                  tag="div"
                  theme="textPrimaryOnDark"
                  use="subtitle1"
                >
                  {t(
                    'calendar.employee.deadline',
                    {
                      date: item.inviteDeadline.format('dd DD.MM.'),
                      time: item.inviteDeadline.format('HH:mm'),
                    },
                  )}
                </Typography>
                <CardActions>
                  <CardActionButtons
                    style={{
                      justifyContent: 'space-evenly',
                      width: '100%',
                    }}
                  >
                    <CardActionButton {...{
                      onClick: () => rejectInvitation(item),
                      outlined: true,
                      style: { ...buttonStyle, ...buttonColors.orange },
                    }}
                    >
                      {t('calendar.employee.cards.reject_action')}
                    </CardActionButton>
                    <CardActionButton {...{
                      onClick: () => acceptInvitation(item),
                      outlined: true,
                      style: { ...buttonStyle, ...buttonColors.green },
                    }}
                    >
                      {t('calendar.employee.cards.accept_action')}
                    </CardActionButton>
                  </CardActionButtons>
                </CardActions>
              </>
            ) : null,
          }}
          />
        </animated.div>
      )}
    </Transition>
  );
};

@inject('loginStore', 't', 'uiStore')
@observer
class WorkOrderList extends Component {
  getArchivedWorkOrders() {
    const { loginStore } = this.props;
    loginStore.findArchivedWorkOrders();
  }

  acceptInvitation = (invitation) => {
    const { uiStore } = this.props;
    uiStore.acceptWorkOrderInvitation(invitation);
  }

  rejectInvitation = (invitation) => {
    const { loginStore, uiStore } = this.props;
    uiStore.rejectWorkOrderInvitation(invitation)
      .then(() => loginStore.findMe(null, true));
  }

  // loadWorkOrders() {
  //   const { loginStore } = this.props;
  //   loginStore.findWorkOrders();
  // }

  render() {
    const {
      dividerColor,
      items,
      shortDescription,
      showArrow,
      skipDescription,
      t,
      title,
      uiStore,
      loginStore: { getArchivedWorkOrdersDisabled },
      loadMore,
      mode,
    } = this.props;

    return (items != null && items.length > 0) ? (
      <>
        {
          title != null && title !== '' && (
            <>
              <Typography
                style={{ padding: '0.5rem 1rem' }}
                tag="div"
                theme="textHintOnBackground"
                use="subtitle1"
              >
                {t(title)}
              </Typography>
              <ListDivider
                style={{ borderBottomColor: dividerColor }}
              />
            </>
          )
        }

        {items && mode === 'openInvitations' ? (
          <TransitionedInvitations
            t={t}
            items={items}
            shortDescription={shortDescription}
            showArrow={showArrow}
            skipDescription={skipDescription}
            acceptInvitation={this.acceptInvitation}
            rejectInvitation={this.rejectInvitation}
          />
        ) : items.map((item) => (
          <WorkOrderItem {...{
            item,
            key: item.id,
            shortDescription,
            showArrow,
            skipDescription,
            useGap: true,
            view: 'calendar',
            onClick: () => {
              if (item.isInvitation !== true) {
                uiStore.showWorkOrder(item.id);
              }
            },
            actionButtons: item.isInvitation === true ? (
              <>
                <hr
                  style={{
                    borderBottomWidth: 0,
                    borderColor: '#8B9898',
                    width: '90%',
                  }}
                />
                <Typography
                  style={{ padding: '0.5rem 1rem', textAlign: 'center' }}
                  tag="div"
                  theme="textPrimaryOnDark"
                  use="subtitle1"
                >
                  {t(
                    'calendar.employee.deadline',
                    {
                      date: item.inviteDeadline.format('dd DD.MM.'),
                      time: item.inviteDeadline.format('HH:mm'),
                    },
                  )}
                </Typography>
                <CardActions>
                  <CardActionButtons
                    style={{
                      justifyContent: 'space-evenly',
                      width: '100%',
                    }}
                  >
                    <CardActionButton {...{
                      onClick: () => this.rejectInvitation(item),
                      outlined: true,
                      style: { ...buttonStyle, ...buttonColors.orange },
                    }}
                    >
                      {t('calendar.employee.cards.reject_action')}
                    </CardActionButton>
                    <CardActionButton {...{
                      onClick: () => this.acceptInvitation(item),
                      outlined: true,
                      style: { ...buttonStyle, ...buttonColors.green },
                    }}
                    >
                      {t('calendar.employee.cards.accept_action')}
                    </CardActionButton>
                  </CardActionButtons>
                </CardActions>
              </>
            ) : null,
          }}
          />
        ))}

        {!getArchivedWorkOrdersDisabled && loadMore && (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
          }}
          >
            <Button {...{
              className: 'mdc-button',
              type: 'button',
              label: t('calendar.employee.show_more'),
              onClick: () => this.getArchivedWorkOrders(),
            }}
            >
              {t('calendar.employee.show_more')}
            </Button>
          </div>
        )}
      </>
    ) : null;
  }
}

export default WorkOrderList;
