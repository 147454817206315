/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Icon } from '@rmwc/icon';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';

// import { buttonColors } from '../../calendar/EmployeeCalendar';
import truncate from '../../utils/truncate';

@inject('timelogStore', 't', 'uiStore')
@observer
class HourlyTimelogRow extends Component {
  ALLOWANCES = {
    none: this.props.t('timelog.allowances.none'),
    full: this.props.t('timelog.allowances.1'),
    half: this.props.t('timelog.allowances.2'),
    meal: this.props.t('timelog.allowances.3'),
    two_meals: this.props.t('timelog.allowances.4'),
    full_plus_2: this.props.t('timelog.allowances.5'),
    full_plus_10: this.props.t('timelog.allowances.6'),
    full_half: this.props.t('timelog.allowances.7'),
  };

  constructor(props) {
    super(props);
    this.state = {
      collapsibleOpen: false,
    };
  }

  componentDidMount() {
    this.setState({
      ...this.props.item,
    });
  }

  addReadOnlyField(attr) {
    const { item } = this.props;
    // console.log('attr: ', attr);
    // console.log('state: ', this.state[attr]);
    return (
      <div
        style={{
          padding: 0, textAlign: 'center', height: 'unset', width: '20%',
        }}
        className="lighterCell"
      >
        {/* { (this.state != null && this.state[attr] != null && this.state[attr] !== '' && !Number.isNaN(this.state[attr])) ? this.state[attr] : '0' } */}
        {item[attr]}
      </div>
    );
  }

  doOnBlur(attr, value, event) {
    const { item, timelogStore } = this.props;
    if (this.state[attr] !== item[attr] && event.target.validity.valid) {
      item.changeAttribute(attr, value);
      timelogStore.updateHourlyTimelogEntry(item);
    }
  }

  doOnChange(attr, value, event) {
    if (this.state[attr] !== value && event.target.validity.valid) {
      this.setState({ [attr]: value });
    }
  }

  addHourlyRow() {
    const {
      item,
      isFirstRow,
      // date,
      // mode,
      toggleDialogOpen,
      // activeTab,
      // employerAcceptance,
      dayStatus,
      disabled,
    } = this.props;
    // console.log(item.date.format('dd'));
    // console.log(item);
    // const { collapsibleOpen } = this.state;

    return (
      <div>
        {/* The employer comment, which may be added by the employer upon rejecting the work hour in employer acceptance mode */}
        {item.employerComment && (dayStatus === 'rejected' || dayStatus === 'draft') && (
          <div
            className="employer-comment"
            style={{ marginBottom: '8px' }}
          >
            <Icon icon="info" />
            <div className="employer-comment-inner">
              { item.employerComment }
            </div>
          </div>
        )}

        <div
          className={`${isFirstRow ? 'hourly-timelog-row-first' : 'hourly-timelog-row'}`}
          onClick={disabled ? undefined : toggleDialogOpen}
          role="button"
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              fontSize: '.875rem',
              cursor: 'pointer',
            }}
            role="button"
          >
            {/* FIX THIS DATE COLOR + ICON */}

            {/* <div
              style={{
                color: allAccepted ? buttonColors.green.color : 'var(--mdc-theme-primary)',
                padding: 0,
                textAlign: 'center',
                width: '17.5%',
                textTransform: 'uppercase',
                fontSize: '.875rem', // Copied from the old DataTable implementation
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {index === 0 && <span>{date.format('D.M. dd')}</span>}
            </div> */}

            {/* <div
              className={`sticky-left ${activeTab === 3 ? 'mobile-column' : ''}`}
              style={{
                color: dayStatus === 'accepted' ? buttonColors.green.color : 'var(--mdc-theme-primary)',
                padding: 0,
                textAlign: 'center',
                width: '17.5%',
                textTransform: 'uppercase',
                fontSize: '.875rem', // Copied from the old DataTable implementation
                display: 'flex',
                // flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
            >
              {index === 0 && (
                <>
                  <span className="vertical-align-middle">{date.format('D.M. dd')}</span>
                  <div className="timelog-row-icon-hourly vertical-align-middle">
                    {dayStatus === 'pending' && (
                      <Icon icon="done" />
                    )}
                    {dayStatus === 'accepted' && employerAcceptance && (
                      <Icon icon="done_all" />
                    )}
                    {dayStatus === 'rejected' && employerAcceptance && !item.employerComment && (
                      <Icon icon="info" />
                    )}
                    {dayStatus === 'accepted' && !employerAcceptance && (
                      <Icon icon="done" />
                    )}
                    { dayStatus === 'closed' && (
                      <Icon icon="lock_outline" />
                    )}
                  </div>
                </>
              )}
            </div> */}

            <div
              style={{
                // Needs to match the item.from + item.to alignment below this
                // Using margin instead of padding to position the border better
                marginLeft: '10px',
                paddingRight: 0,
                width: '100%',
                paddingTop: !isFirstRow ? '8px' : '0',
                marginTop: !isFirstRow ? '8px' : '0',
                borderTop: !isFirstRow ? '1px gray solid' : '0',
              }}
            >
              {truncate(HourlyTimelogRow.renderIdentifiers(item.workTask), 45)}
            </div>
          </div>

          <div
            style={{
              width: '100%',
              border: 'none',
              display: 'flex',
              paddingTop: '8px',
              fontSize: '.875rem',
              cursor: 'pointer',
            }}
            role="button"
          >
            {/* <div style={{ width: '17.5%' }} /> */}
            {/* <div
              style={{ position: 'static', height: 'unset' }}
            /> */}
            <div
              style={{
                padding: 0,
                height: 'unset',
                width: '36.5%',
                paddingLeft: '10px',
              }}
            >
              {/* { (this.state != null && this.state.from != null && this.state.from !== '') ? this.state.from : '00:00' }
              { ' - ' }
              { (this.state != null && this.state.to != null && this.state.to !== '') ? this.state.to : '00:00' } */}
              {item.from}
              {' - '}
              {item.to}
            </div>
            { this.addReadOnlyField('timeNormal') }
            { this.addReadOnlyField('time50') }
            { this.addReadOnlyField('time100') }
          </div>

          {item.description && (
            <div style={{ display: 'flex', paddingTop: '8px', cursor: 'pointer' }}>
              <div style={{ fontSize: '.875rem', wordBreak: 'break-word', paddingLeft: '10px' }}>
                {item.description}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  addHourlyRowDialog() {
    const {
      item,
      deleteHourlyLogRow,
      isFirstRow,
      // workNumbers,
      // mode,
    } = this.props;

    // const { collapsibleOpen } = this.state;
    return (
      <>
        <div
          style={{
            color: 'white', width: '100%', borderTop: isFirstRow ? 'gray 1px solid' : '0', borderBottom: 'gray 1px solid',
          }}
        >
          <div
            style={{
              color: 'white',
              width: '100%',
              display: 'flex',
              padding: '10px 10px 5px 10px',
            }}
          >
            <div style={{ flexGrow: '1', textAlign: 'start', fontSize: '14.5px' }}>
              <span>
                Työ:
                &nbsp;
                {this.state.workTask.identifierOne}
              </span>
              <br />
              <span>
                {this.state.from}
                &nbsp;
                -
                &nbsp;
                {this.state.to}
              </span>

              &nbsp;
              &nbsp;
              &nbsp;

              <span>
                {this.state.tempDuration ? this.state.tempDuration.toPrecision(3) : 0}
                &nbsp;
                h
              </span>

              &nbsp;
              &nbsp;
              &nbsp;

              <span>
                {this.props.t(`timelog.hourly.${this.state.tempTimeType}`)}
              </span>
            </div>
            <div
              role="button"
              style={{ display: 'flex', alignItems: 'center', padding: '10px' }}
              onClick={() => deleteHourlyLogRow(item)}
            >
              <Icon
                icon="delete_outline"
                style={{ color: 'var(--mdc-theme-accent-orange-dark)', width: '30px' }}
              />
            </div>
          </div>
          {this.state.description && (
            <div style={{
              marginTop: '0px', marginBottom: '5px', fontSize: '14.5px', marginLeft: '32.5%', textAlign: 'start', wordBreak: 'break-word',
            }}
            >
              {this.state.description}
            </div>
          )}
        </div>
      </>
    );
  }

  static renderIdentifiers(workTask) {
    if (workTask.identifierTwo) {
      return `${workTask.identifierOne} - ${workTask.identifierTwo}`;
    }
    return workTask.identifierOne;
  }

  render() {
    if (this.props.mode === 'dialog') {
      return (
        this.addHourlyRowDialog()
      );
    }
    return (
      this.addHourlyRow()
    );
  }
}

export default HourlyTimelogRow;
