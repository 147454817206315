import { observable, action, computed } from 'mobx';

export default class RegistrationStore {
  constructor(requests, uiStore, loginStore) {
    this.requests = requests;
    this.uiStore = uiStore;
    this.loginStore = loginStore;
  }

  @computed get passwordsMatch() {
    return this.password != null && this.password.trim() !== '' && this.password === this.passwordConfirmation;
  }

  @computed get canSubmit() {
    return this.passwordsMatch;
  }

  @computed get hasErrors() {
    return this.errors != null;
  }

  @action updatePassword = (event) => {
    this.password = event.target.value;
    this.errors = null;
  };

  @action updatePasswordConfirmation = (event) => {
    this.passwordConfirmation = event.target.value;
  };

  @observable passwordConfirmation;

  @observable password;

  @observable errors;

  // Accepts the invitation request. By default after accepting a request
  // the user is automatically logged in so there's no need to authenticate
  // again.
  register = (token) => {
    if (!this.canSubmit) {
      return;
    }

    const { password, passwordConfirmation } = this;
    this.errors = null;

    this.requests.Users.register(password, passwordConfirmation, token)
      .then(() => {
        // TODO: this state is leaking everywhere
        this.loginStore.isLoggedIn = true;

        return this.loginStore.findMe();
      })
      .then(() => {
        this.uiStore.showRegistration();
      })
      .catch((err) => {
        if (err.response.body.errors != null && Array.isArray(err.response.body.errors.password)) {
          this.errors = err.response.body.errors.password.join('; ');
        }
      });
  };
}
