/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { IconButton } from '@rmwc/icon-button';
import {
  ListItemMeta,
} from '@rmwc/list';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from '@rmwc/dialog';
import { DirectUploadProvider } from 'react-activestorage-provider';

import 'moment/locale/fi';
import moment from '../utils/moment';

import { buttonColors } from '../calendar/EmployeeCalendar';
import { handleProgress } from '../models/user/user-ui-avatar';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const validateResponse = (response) => {
  if (!response || response === '') {
    return 'Vaadittu';
  }
  return null;
}

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  // name: (name) => nameValidate(name),
  tempResponse: (tempResponse) => validateResponse(tempResponse),
};

@inject('uiStore', 'loginStore', 't', 'closeCallReportStore')
@observer
class ShowCloseCallReportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      description: props.description,
      // fileURL: '',
      // filename: '',
      files: props.files || [],
      file: props.file,
      name: props.name,
      status: props.status,
      errors: {},
      inputChanged: false,
      closeCallReport: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name || prevProps.description !== this.props.description || prevProps.files !== this.props.files) {
      this.setState({
        name: this.props.name,
        description: this.props.description,
        files: this.props.files,
        file: this.props.file,
      });
    }
  }

  handleValidate(name, description, files) {
    let errors = {};

    Object.keys(validate).forEach((key) => {
      if (key === 'attachments') {
        const error = validate[key](description, files);

        if (error) {
          errors = { ...errors, [key]: error };
        }
      } else if (key === 'name') {
        const error = validate[key](name);

        if (error) {
          errors = { ...errors, [key]: error };
        }
      }
    });

    this.setState({
      errors: { ...errors },
    });
  }

  attachmentUpload(disabled, data, inputRef) {
    const { t, handleAttachmentAction } = this.props;
    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={true}
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;
            const body = {
              ...data,
              files: signedIds,
            };

            handleAttachmentAction(body);
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t)}

            <ListItemMeta>
              <Button {...{
                disabled,
                type: 'button',
                label: 'Lisää tiedosto',
                icon: 'description',
                outlined: true,
                theme: 'secondary',
                // className: 'ml-1',
                onClick: () => {
                  inputRef.current.click();
                  // console.log(inputRef.current.files);
                },
                style: {
                  color: buttonColors.green.color,
                  borderRadius: '0px',
                },
              }}
              />
            </ListItemMeta>
          </>
        )}

        required
      />
    );
  }

  addHourField(attr) {
    const { closeCallReport } = this.state;

    return (
      <TextField {...{
        required: true,
        onChange: (event) => {
          let { value } = event.target
          if (value.includes(':')) {
            value = value.replace('.', '')
          } else {
            value = value.replace('.', ':')
          }
          this.setState((prevState) => ({
            closeCallReport: {...prevState.closeCallReport, [attr]: value }
          }))
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        label: 'Klo.',
        style: {
          width: '100%'
        },
        type: 'time',
        onKeyPress: handleEnter,
        value: (this.state != null && this.state.closeCallReport[attr] != null && this.state.closeCallReport[attr] !== '') ? this.state.closeCallReport[attr] : '00:00',
      }}
      />
    );
  }

  addTextField(attr, label, required, styles = null, textarea) {
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        rows: 5,
        // invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState(() => ({
            [attr]: value,
          }))
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: this.state[attr] != null ? this.state[attr] : '',
        // theme: ['textPrimaryOnDark', 'secondaryBg'],
      }}
      />
    );
  }

  renderUploadedFile(file) {
    const { confirmDelete, id, workOrderId } = this.props;

    const filename = file.filename;
    const fileURL = file.fileURL;
    const signedId = file.signedId;

    return (
      <GridInner key={fileURL}>
        <GridCell
          desktop={9}
          phone={3}
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignSelf: 'center',
          }}
        >
          <a
            href={fileURL}
            download={filename}
            style={{
              color: 'var(--mdc-theme-primary, #6200ee)',
              // fontSize: '14px'

            }}
          >
            {filename}
          </a>
        </GridCell>
        <GridCell
          desktop={3}
          phone={1}
          style={{
            justifySelf: 'flex-end',
          }}
        >
          {signedId && <IconButton
            type="button"
            style={{
              color: '#645F5F',
            }}
            icon="delete_outlined"
            onClick={() => {
              confirmDelete('file', id, workOrderId, filename, signedId);
            }}
          />}
          {!signedId && <div style={{ height: '48px' }}/>}
        </GridCell>
      </GridInner>
    );
  }

  renderActionButtons() {
    const {
      name,
      description,
      files,
    } = this.state;
    const { employerView, closeCallReport: { response } } = this.props;

    if (employerView && !response) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              minWidth: '100px',
            }}
            onClick={() => {
              this.handleValidate(name, description, files);
            }}
            action="submit"
          >
            Vastaa
          </DialogButton>
          <DialogButton
              type="button"
              className={employerView ? "employer-reject-button" : "employee-reject-button"}
              action="cancel"
            >
              Peruuta
            </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
              type="button"
              className={employerView ? "employer-reject-button" : "employee-reject-button"}
              action="cancel"
            >
              Sulje
            </DialogButton>
        </DialogActions>
      );
    }
  }

  respond() {
    const { closeCallReport: { id }, closeCallReportStore, updateCloseCallReport, handleClose } = this.props;
    const { tempResponse } = this.state;
    console.log('Responding: ', id);
    console.log(tempResponse);
    // closeCallReportStore.respond()
    const respondPromise = fromPromise(new Promise((resolve, reject) => closeCallReportStore.respond(id, tempResponse, resolve, reject)));

    when(
      () => respondPromise.state !== "pending",
      () => {
        respondPromise.case({
          pending: () => {
          },
          rejected: (err) => {
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (newCloseCallReport) => {
            console.log('Tallennus onnistui', newCloseCallReport);
            updateCloseCallReport(newCloseCallReport);
            handleClose();
          }
        });
      }
    )
  }

  renderAttr(attr, content) {
    const { t, employerView } = this.props;
    return (
      <div>
        <div
          style={{
            color: !employerView ? 'var(--mdc-theme-primary, #6200ee)' : 'black',
            fontWeight: employerView ? '500' : '',
          }}
        >
          {t(`close_call_reports.${attr}`)}
        </div>
        <div
          style={{
            color: employerView ? 'black' : '',
            fontSize: '14px',
          }}
        >
          {content || '-'}
        </div>
      </div>
    )
  }

  render() {
    const {
      t,
      open,
      handleClose,
      employerView,
      closeCallReport: {
        reporter,
        createdAt,
        date,
        time,
        location,
        workSteps,
        relatedObject,
        eventSteps,
        causes,
        fixSuggestions,
        responder,
        response,
        respondedAt,
      },
      styles
    } = this.props;

    return (
      <Dialog
        className={`modal-size close-call-report-dialog ${employerView ? 'employer-modal': 'trip-route-dialog'}`} // "modal-size employer-modal"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'submit') {
            this.respond();
            // DO NOT PROCEED IF RESPOND FAILS?
            // handleClose();

            // const error = validate.attachments(description, files);
            // if (!error) {
            //   // We cannot send the whole file objects, only the signed IDs, for finding and attaching the files to the model
            //   let signedIds = files.map((file) => {
            //     return file['signedId'];
            //   });
            //   handleAttachmentAction({...this.state, workOrderId: workOrderId, id: id, files: signedIds });
            //   handleClose();
            // }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle
          style={{
            padding: '20px 20px 0 20px',
            display: 'flex',
          }}
        >
          {/* <div style={{ color: 'var(--mdc-theme-primary)' }}>Vaaratilanneilmoitus</div>
          <div>{reporter?.firstName} {reporter?.lastName} - {createdAt?.format('dd DD.MM.YYYY HH:mm')}</div> */}
          <div>
            <span
              style={{ color: employerView ? null : 'var(--mdc-theme-primary)' }}
            >
              Vaaratilanneilmoitus
            </span>
            <br />
            <span
              style={{
                color: employerView ? null : 'white',
                fontSize: '14px',
              }}
            >
              {reporter?.firstName} {reporter?.lastName} - {createdAt?.format('dd DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid style={{ color: 'white' }}>
            <GridCell span={6}>
              <div
                style={{
                  color: !employerView ? 'var(--mdc-theme-primary, #6200ee)' : 'black',
                  fontWeight: employerView ? '500' : '',
                }}
              >
                {t(`close_call_reports.eventTime`)}
              </div>
              <div
                style={{
                  color: !employerView ? 'white' : 'black',
                  fontSize: '14px',
                }}
              >
                {date?.format('dd DD.MM.YYYY')} {time?.format('HH:mm')}
              </div>
            </GridCell>

            <GridCell span={6}>
              {/* {location} */}
              {this.renderAttr('location', location)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('workSteps', workSteps)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('relatedObject', relatedObject)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('eventSteps', eventSteps)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('causes', causes)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('fixSuggestions', fixSuggestions)}
            </GridCell>

            {employerView && !response && (
              <GridCell span={12}>
                {this.addTextField('tempResponse', 'Vastaus', true, {...styles, width: '100%'}, true)}
              </GridCell>
            )}

            {response && (
              <>
                <GridCell
                  span={12}
                  style={{
                    borderTop: '1px gray solid',
                    paddingTop: '20px',
                    fontSize: '20px',
                    fontWeight: '500',
                    color: !employerView ? 'var(--mdc-theme-primary, #6200ee)' : 'black',
                  }}
                >
                  Vastaus
                </GridCell>
                <GridCell span={6}>
                  <div
                    style={{
                      color: !employerView ? 'white' : 'black',
                      // fontSize: '14px',
                    }}
                  >
                    {responder?.firstName} {responder?.lastName}
                  </div>
                </GridCell>

                <GridCell span={6}>
                  <div
                    style={{
                      color: !employerView ? 'white' : 'black',
                      // fontSize: '14px',
                    }}
                  >
                    {respondedAt?.format('dd DD.MM.YYYY HH:mm')}
                  </div>
                </GridCell>

                <GridCell span={12}>
                  <div
                    style={{
                      color: employerView ? 'black' : '',
                      fontSize: '14px',
                    }}
                  >
                    {response || '-'}
                  </div>
                </GridCell>
              </>
            )}

          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    )
  }
}

export default ShowCloseCallReportDialog;
