import dayjs from 'dayjs';

const customParseFormat = require('dayjs/plugin/customParseFormat');
const duration = require('dayjs/plugin/duration');
const weekOfYear = require('dayjs/plugin/weekOfYear');

// Enable parsing dates by custom formats
dayjs.extend(customParseFormat);
// Enable durations
dayjs.extend(duration);
// Enable week numbers
dayjs.extend(weekOfYear);

dayjs.locale('fi');

export default dayjs;
