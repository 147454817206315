import { observable, computed } from 'mobx';
import moment from '../../utils/moment';

import User from '../../models/User';

export default class ChatMessage {
  @observable id;

  @observable sender;

  @observable content;

  @observable sentAt;

  @observable smsDelivery;

  constructor({
    id, sender, content, sentAt, smsDelivery,
  }) {
    this.id = id;
    this.content = content;
    this.sender = sender instanceof User ? sender : new User(sender);
    this.sentAt = moment.isMoment(sentAt) ? sentAt.local() : moment(sentAt).local();
    this.smsDelivery = smsDelivery;
  }

  @computed get senderName() {
    return this.sender != null ? `${this.sender.firstName} ${this.sender.lastName}` : '';
  }

  isSentBy(user) {
    return user.isSameUser(this.sender);
  }

  static fromJson(jsonProperties) {
    const {
      content, id, sender, sentAt, smsDelivery,
    } = jsonProperties;
    return new ChatMessage({
      content,
      id,
      smsDelivery,
      sender: User.fromJson(sender),
      sentAt: moment(sentAt),
    });
  }
}
