import React, { Component } from 'react'; // Fragment
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import groupArray from 'group-array';
import {
  IconButton,
  Icon,
  Grid,
} from '@material-ui/core';
// import { sortBy } from 'lodash';
// import { createTheme } from '@material-ui/core/styles';
// import { Icon } from '@rmwc/icon';
// import MUIDataTable, { ExpandButton } from 'mui-datatables';
import MUIDataTable from 'mui-datatables';
// import TableFooter from '@mui/material/TableFooter';
import ReactToPrint from 'react-to-print';
import moment from '../utils/moment';
// import ReportSettingsDialog from './ReportSettingsDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
import DeepViewHeader from '../shared/DeepViewHeader';
import './report.css';
import normalizeFileName from '../utils/NormalizeFileName';

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore', 'productStore')
@observer
class ProductReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reportData: null,
      // settingsDialogOpen: false,
      columns: [],
    };
  }

  componentDidMount() {
    const { uiStore } = this.props;
    const workOrderId = uiStore.currentView.id;

    this.getWorkOrderReportByUser(workOrderId);
    const columns = [
      {
        name: 'product_name',
        label: 'Tuote',
        display: true,
      },
      {
        name: 'product_code',
        label: 'Tuotekoodi',
        display: true,
      },
      {
        name: 'count',
        label: 'Määrä',
        display: true,
      },
    ];

    this.setState({ columns });
  }

  getWorkOrderReportByUser = (workOrderId) => {
    const { productStore } = this.props;
    const reportPromise = fromPromise(new Promise((resolve, reject) => productStore.getProductReport(workOrderId, resolve, reject)));

    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          pending: () => {
          },
          rejected: (e) => {
            console.log('REJECTED: ', e);
          },
          fulfilled: (data) => {
            // Need to do the grouping here because the Rails-side grouping didn't work for some reason (selected columns disappeared when grouped)
            const groupedData = [];
            data.userProducts.forEach((userProduct) => {
              const foundGroupedProduct = groupedData.find((groupedProduct) => groupedProduct.product_id === userProduct.product_id);
              if (foundGroupedProduct) {
                foundGroupedProduct.count += userProduct.count;
              } else {
                groupedData.push(userProduct);
              }
            });

            this.setState({
              reportData: groupedData,
              reportWorkOrder: data.workOrder,
            });
          },
        });
      },
    );
  }

  // handleSettingsDialogClose = () => {
  //   this.setState({
  //     settingsDialogOpen: false,
  //   });
  // }

  toggleColumnVisibility = (columnName) => {
    const { columns } = this.state;
    const columnIndex = columns.findIndex((column) => column.name === columnName);
    const foundColumn = columnIndex !== -1 ? columns[columnIndex] : null;

    const updatedColumns = [...columns];

    if (foundColumn) {
      // Toggle visibility
      updatedColumns[columnIndex].display = !foundColumn.display;
      this.setState({
        columns: updatedColumns,
      });
    }
  }

  // hideEmptyColumns(groupedWorkHours) {
  //   const { columns } = this.state; // mainColumns

  //   const columnsWithValues = [];
  //   Object.keys(groupedWorkHours).forEach((fullName) => {
  //     // Filter mainColumns with columnsWithValues to prevent looping over keys that we know are needed
  //     // Quit the whole loop if the mainColumns.length is the same as columnsWithValues.length
  //     const filteredColumns = [...columns].filter((col) => !columnsWithValues.includes(col.name));
  //     const userWorkHours = groupedWorkHours[fullName];
  //     userWorkHours.forEach((userWorkHour) => {
  //       const { workHour } = userWorkHour;

  //       filteredMainColumns.forEach((col) => {
  //         const key = col.name.split('.').pop();
  //         // console.log('Key: ', key);
  //         const value = workHour[key];
  //         if (value && !columnsWithValues.includes(col.name)) {
  //           columnsWithValues.push(col.name);
  //         }
  //       });
  //     });
  //     // Update mainColumns using columnsWithValues to hide the relevant ones
  //     // console.log('Grouped work hour: ', groupedWorkHours[fullName].workHour);
  //   });

  //   const hiddenEmptyMainColumns = [...mainColumns].map((col) => ({ ...col, display: columnsWithValues.includes(col.name) }));

  //   this.setState({
  //     mainColumns: hiddenEmptyMainColumns,
  //   });
  // }

  // eslint-disable-next-line class-methods-use-this
  categorizeRows(data, category) {
    const groupedWorkHours = groupArray(data, category);

    // The column being grouped by is the first
    return groupedWorkHours;
  }

  // eslint-disable-next-line class-methods-use-this
  findVisibleColumn(columns, columnName) {
    // true or false
    return columns.some((column) => column.name === columnName && column.options?.display !== false);
  }

  render() {
    const {
      reportData,
      reportWorkOrder,
      // settingsDialogOpen,
      columns,
    } = this.state;
    const { t, uiStore, uiStore: { currentUser } } = this.props;

    const filename = `Tuoteraportti_${reportWorkOrder?.name}_${moment().format('DD-MM-YYYY')}`;
    const normalizedFileName = normalizeFileName(filename);

    const options = {
      filter: false,
      // filterType: 'dropdown',
      responsive: 'standard',
      search: false,
      viewColumns: false,
      selectableRows: 'none',
      fixedHeader: false,
      sort: true,
      download: true,
      print: false,
      pagination: false,
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
        filename: `${normalizedFileName}.csv`,
      },
      textLabels: {
        body: {
          noMatch: 'Kirjattuja tuotteita ei löytynyt',
        },
        toolbar: {
          search: t('employer_table.toolbar.search'),
          downloadCsv: t('employer_table.toolbar.downloadCsv'),
          print: t('employer_table.toolbar.print'),
          viewColumns: t('employer_table.toolbar.viewColumns'),
          filterTable: t('employer_table.toolbar.filterTable'),
        },
        pagination: {
          next: t('employer_table.pagination.next'),
          previous: t('employer_table.pagination.previous'),
          rowsPerPage: t('employer_table.pagination.rowsPerPage'),
          displayRows: t('employer_table.pagination.displayRows'),
        },
        filter: {
          all: t('employer_table.filter.all'),
          title: t('employer_table.filter.title'),
          reset: t('employer_table.filter.reset'),
        },
        viewColumns: {
          title: t('employer_table.viewColumns.title'),
          titleAria: t('employer_table.viewColumns.titleAria'),
        },
        selectedRows: {
          text: t('employer_table.selectedRows.text'),
          delete: t('employer_table.selectedRows.delete'),
          deleteAria: t('employer_table.selectedRows.deleteAria'),
        },
      },
    };

    return (
      <div className="action-buttons" style={{ height: '100%', width: '100%' }}>
        <DeepViewHeader
          content={reportWorkOrder?.name}
          showPreviousView={uiStore.showEmployerBilling}
        />

        <div style={{ textAlign: 'right' }}>
          <ReactToPrint
            // NOTE: Do NOT pass an `onClick` prop to the root node of the returned component as it will be overwritten.
            trigger={() => (
              <IconButton
                aria-label="settings"
                className="small"
              >
                <Icon style={{ color: 'black' }} fontSize="large">print</Icon>
              </IconButton>
            )}
            content={() => this.componentRef}
          />
        </div>

        {reportWorkOrder ? (
          /* eslint-disable-next-line no-return-assign */
          <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
            <Grid
              container
              spacing={2}
              className="billing-report-header pdf-header"
              justifyContent="space-between"
            >
              <Grid item xs={4}>
                <span>{moment().format('DD/MM/YYYY')}</span>
              </Grid>
              <Grid item xs={4}>
                <span>{`Tuntiraportit - ${currentUser?.accountInfo?.officialName}`}</span>
              </Grid>
              <Grid item xs={4}>
                {/* <span>{currentUser?.accountInfo?.accountPicture}</span> */}
                <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }}
              className="billing-report-header"
            >
              <Grid item md={6} xs={12}>
                <div className="header-label">Tilaaja</div>
                <div className="header-value">{reportWorkOrder?.purchaser}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Ajalta</div>
                {/* <div style={{ fontSize: '30px' }}>12.09.2022 - 18.09.2022</div> */}
                <div className="header-value">{reportWorkOrder?.duration}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                {/* style={{ fontSize: '16px', marginLeft: '20px' }} */}
                <div className="header-label">Kohde</div>
                <div className="header-value">{reportWorkOrder?.location}</div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div className="header-label">Nimi</div>
                <div className="header-value">{reportWorkOrder?.name}</div>
              </Grid>
            </Grid>
            <div className="table-wrapper">
              <MUIDataTable
                data={reportData}
                columns={columns}
                options={options}
              />
            </div>
          </div>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <LoadingSpinner color="black" />
          </div>
        )}
      </div>
    );
  }
}

export default ProductReport;
