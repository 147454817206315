import { observable, action } from 'mobx';
// import { fromPromise } from 'mobx-utils';
import AbsencePeriod from '../models/AbsencePeriod';
import AbsenceEntry from '../models/AbsenceEntry';

class AbsenceStore {
  // @observable users = fromPromise.resolve([]);
  @observable absencePeriods = [];

  constructor(uiStore, requests) {
    this.uiStore = uiStore;
    this.requests = requests;
    // this.users = [];
  }

  // @action setCertificateFilters(column, filters) {
  //   this.certificateFilters[column] = filters;
  // }

  // @action emptyFilters() {
  //   this.certificateFilters = [];
  // }

  // @action setStoredColumns(name, columns = []) {
  //   console.log('SETTING STORED COLUMNS');
  //   console.log('name: ', name);
  //   console.log('columns: ', columns);
  //   // If the name doesn't exist in storedColumns, create a new entry
  //   if (!this.storedColumns[name]) {
  //     console.log('Stored column not found with name');
  //     this.storedColumns[name] = {
  //       name,
  //       columns: [...columns],
  //     };
  //     console.log('Added: ', this.storedColumns[name]);
  //   } else {
  //     console.log('Stored columns found: ', this.storedColumns);
  //     console.log('With name: ', name);

  //     // Update the existing columns for the specified name
  //     const storedColumnArray = this.storedColumns[name].columns;

  //     // Add new columns to the existing array
  //     columns.forEach((column) => {
  //       if (!storedColumnArray.includes(column)) {
  //         storedColumnArray.push(column);
  //       }
  //     });
  //   }
  // }

  // @action removeStoredColumns(name, columnsToRemove) {
  //   if (this.storedColumns[name]) {
  //     const storedColumnArray = this.storedColumns[name].columns;

  //     // Filter out the columns to be removed
  //     const updatedColumns = storedColumnArray.filter((column) => !columnsToRemove.includes(column));
  //     this.storedColumns[name].columns = updatedColumns;
  //   }
  // }

  // PAGINATE THIS
  // @action getAbsences() {
  //   fromPromise(new Promise((resolve) => this.requests.Certificates.getAll().then((json) => {
  //     // We need to set _private to true in order to properly add relevant info, such as user's details, to the new User object
  //     const certificates = json.map((certificate) => Certificate.fromJsonProperties(certificate));
  //     this.certificates = certificates;
  //     resolve(certificates);
  //   })));
  // }

  // When to call this vs. login? When you open the absences?
  // @action getAbsenceTypes() {
  //   fromPromise(new Promise((resolve) => this.requests.CertificateCategories.getAll().then((json) => {
  //     console.log('GOT CERTIFICATE CATEGORIES: ', json);
  //     // We need to set _private to true in order to properly add relevant info, such as user's details, to the new User object
  //     const certificateCategories = json.map((category) => CertificateType.fromJsonProperties(category));
  //     this.certificateCategories = certificateCategories;
  //     this.primaryCategories = certificateCategories.filter((category) => category.categoryType === 'category_one');
  //     this.secondaryCategories = certificateCategories.filter((category) => category.categoryType === 'category_two');
  //     resolve(certificateCategories);
  //   })));
  // }

  @action createAbsencePeriod(absencePeriod) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsencePeriods.create(absencePeriod).then((res) => {
      const newAbsencePeriod = AbsencePeriod.fromJsonProperties(res);

      const updatedAbsencePeriods = [newAbsencePeriod, ...currentUser.absencePeriods];
      currentUser.absencePeriods = updatedAbsencePeriods;
      return newAbsencePeriod;
    });
  }

  @action employerCreateAbsencePeriod(absencePeriod) {
    // const { currentUser } = this.uiStore;

    return this.requests.AbsencePeriods.employerCreate(absencePeriod).then((res) => (
      // V1: returning entries instead of a period for acceptance view
      // Should also return the period in case employer creates a period for themselves so we add it to the currentUser's list
      res
    ));
  }

  @action updateAbsencePeriod(absencePeriod) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsencePeriods.update(absencePeriod).then((res) => {
      const updatedAbsencePeriod = AbsencePeriod.fromJsonProperties(res);

      // Update the absence period in the absence period list
      const foundAbsencePeriodIndex = currentUser.absencePeriods.findIndex((oldPeriod) => oldPeriod.id === updatedAbsencePeriod.id);
      if (foundAbsencePeriodIndex !== -1) {
        // Trigger a UI re-render with this.absencePeriods value re-assignment
        const updatedAbsencePeriods = [...currentUser.absencePeriods];
        updatedAbsencePeriods[foundAbsencePeriodIndex] = updatedAbsencePeriod;
        currentUser.absencePeriods = updatedAbsencePeriods;
      } else {
        console.error('Something went wrong, the updated period was not found');
      }

      return updatedAbsencePeriod;
    });
  }

  @action deleteAbsencePeriod(periodId) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsencePeriods.del(periodId).then(() => {
      const updatedAbsencePeriods = [...currentUser.absencePeriods];
      const foundAbsenceIndex = updatedAbsencePeriods.findIndex((period) => period.id === periodId);
      if (foundAbsenceIndex !== -1) {
        updatedAbsencePeriods.splice(foundAbsenceIndex, 1);
      }
      currentUser.absencePeriods = updatedAbsencePeriods;
    }).catch((err) => {
      console.error(err);
    });
  }

  @action employeeAcceptAbsencePeriod(periodId) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsencePeriods.employeeAccept(periodId).then((res) => {
      const updatedAbsencePeriod = AbsencePeriod.fromJsonProperties(res);

      // Update the absence period in the absence period list
      const foundAbsencePeriodIndex = currentUser.absencePeriods.findIndex((oldPeriod) => oldPeriod.id === updatedAbsencePeriod.id);
      if (foundAbsencePeriodIndex !== -1) {
        // Trigger a UI re-render with this.absencePeriods value re-assignment
        const updatedAbsencePeriods = [...currentUser.absencePeriods];
        updatedAbsencePeriods[foundAbsencePeriodIndex] = updatedAbsencePeriod;
        currentUser.absencePeriods = updatedAbsencePeriods;
      } else {
        console.error('Something went wrong, the updated absence period was not found');
      }

      return updatedAbsencePeriod;
    });
  }

  @action async getEmployerAbsenceEntries() {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.AbsenceEntries.getEmployerAbsenceEntries(ctxUser, ['pending', 'accepted']);
  }

  @action async employerAcceptMultipleEntries(absenceEntryData) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.AbsenceEntries.employerAcceptMultipleEntries(ctxUser, absenceEntryData);
  }

  @action async getEmployerAbsenceEntriesBySalaryPeriod(salaryPeriod) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.AbsenceEntries.getEmployerAbsenceEntriesBySalaryPeriod(ctxUser, salaryPeriod.from, salaryPeriod.to, salaryPeriod.salary_period_category_id);
  }

  @action updateAbsenceEntry(absenceEntry) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsenceEntries.update(absenceEntry).then((res) => {
      const updatedAbsenceEntry = AbsenceEntry.fromJsonProperties(res);

      // Update the absence period in the absence period list
      const foundAbsencePeriodIndex = currentUser.absencePeriods.findIndex((oldPeriod) => oldPeriod.id === updatedAbsenceEntry.absencePeriodId);
      if (foundAbsencePeriodIndex !== -1) {
        // Trigger a UI re-render with this.absencePeriods value re-assignment
        const updatedAbsencePeriods = [...currentUser.absencePeriods];
        const foundAbsenceEntryIndex = updatedAbsencePeriods[foundAbsencePeriodIndex].absenceEntries.findIndex((existingEntry) => existingEntry.id === updatedAbsenceEntry.id);
        if (foundAbsenceEntryIndex !== -1) {
          updatedAbsencePeriods[foundAbsencePeriodIndex].absenceEntries[foundAbsenceEntryIndex] = updatedAbsenceEntry;
        } else {
          console.error('Something went wrong, the updated entry was not found');
        }
        currentUser.absencePeriods = updatedAbsencePeriods;
      } else {
        console.error('Something went wrong, the updated period was not found');
      }

      return updatedAbsenceEntry;
    });
  }

  @action updateAbsenceEntryEmployer(absenceEntry) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsenceEntries.update(absenceEntry).then((res) => {
      const updatedAbsenceEntry = AbsenceEntry.fromJsonProperties(res);

      // Update the absence period in the absence period list
      // Since this is the employer update, this will only affect the employer's own absence entries
      const foundAbsencePeriodIndex = currentUser.absencePeriods.findIndex((oldPeriod) => oldPeriod.id === updatedAbsenceEntry.absencePeriodId);
      if (foundAbsencePeriodIndex !== -1) {
        // Trigger a UI re-render with this.absencePeriods value re-assignment
        const updatedAbsencePeriods = [...currentUser.absencePeriods];
        const foundAbsenceEntryIndex = updatedAbsencePeriods[foundAbsencePeriodIndex].absenceEntries.findIndex((existingEntry) => existingEntry.id === updatedAbsenceEntry.id);
        if (foundAbsenceEntryIndex !== -1) {
          updatedAbsencePeriods[foundAbsencePeriodIndex].absenceEntries[foundAbsenceEntryIndex] = updatedAbsenceEntry;
        }
        currentUser.absencePeriods = updatedAbsencePeriods;
      }

      return updatedAbsenceEntry;
    });
  }

  @action deleteAbsenceEntry(absenceEntry) {
    const { currentUser } = this.uiStore;

    return this.requests.AbsenceEntries.del(absenceEntry).then(() => {
      const updatedAbsencePeriods = [...currentUser.absencePeriods];
      const foundAbsencePeriodIndex = updatedAbsencePeriods.findIndex((period) => absenceEntry.absencePeriodId === period.id);
      if (foundAbsencePeriodIndex !== -1) {
        // updatedAbsencePeriods.splice(foundAbsenceIndex, 1);
        const foundAbsenceEntryIndex = updatedAbsencePeriods[foundAbsencePeriodIndex].absenceEntries.findIndex((existingEntry) => existingEntry.id === absenceEntry.id);
        if (foundAbsenceEntryIndex !== -1) {
          updatedAbsencePeriods[foundAbsencePeriodIndex].absenceEntries.splice(foundAbsenceEntryIndex, 1);
        } else {
          console.error('Something went wrong, the updated entry was not found');
        }
      }
      currentUser.absencePeriods = updatedAbsencePeriods;
    }).catch((err) => {
      console.error(err);
    });
  }

  // @action update(certificate, resolve, reject) {
  //   return this.requests.Certificates[!certificate.id ? 'create' : 'update'](certificate).then((res) => {
  //     const updatedCertificate = Certificate.fromJsonProperties(res);

  //     // Update the certificate in the certificate list
  //     const foundCertificateIndex = this.certificates.findIndex((oldCert) => oldCert.id === updatedCertificate.id);
  //     if (foundCertificateIndex !== -1) {
  //       // Trigger a UI re-render with this.certificates value re-assignment
  //       const updatedCertificates = [...this.certificates];
  //       updatedCertificates[foundCertificateIndex] = updatedCertificate;
  //       this.certificates = updatedCertificates;
  //     } else {
  //       const updatedCertificates = [updatedCertificate, ...this.certificates];
  //       this.certificates = updatedCertificates;
  //     }

  //     resolve(updatedCertificate);
  //   }).catch((err) => reject(err));
  // }

  // @action purgeOtherHelmetImage(user) {
  //   this.requests.Users.purgeHelmetImage(user).then(() => {
  //     user.changeAttribute('helmetImageUrl', null);
  //   });
  // }

  // @action purgeOtherCard(user, card) {
  //   return this.requests.Users.purgeCardEmployer(user, card);
  // }
}

export default AbsenceStore;
