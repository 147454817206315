/* eslint-disable quotes, camelcase */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { get } from 'lodash';

import { Icon as RMWCIcon } from '@rmwc/icon';
import CustomTooltip from './employerTimelogTable';

import { shortDateRange } from '../utils/moment';
import truncate from '../utils/truncate';
import './html-table.css';

const renderDateWithStatus = (date, status) => (
  <div style={{ position: 'relative' }}>
    <span
      style={{ textTransform: 'capitalize' }}
      className={`work-hour-status-${status}`}
    >
      {date}
    </span>

    <div className="custom-tooltip-icon" style={{ position: 'absolute', top: '-10px', right: '-12px' }}>
      {status === 'pending' && (
        <RMWCIcon icon="done" />
      )}
      {status === 'accepted' && (
        <RMWCIcon icon="done_all" />
      )}
      {status === 'rejected' && (
        <RMWCIcon icon="info_outlined" />
      )}
    </div>
  </div>
);

@inject('uiStore', 'timelogStore', 't')
@observer
class TimelogReportTableHtml extends Component {
  renderHTMLTableCell = (row, key) => {
    const { openEmployeeInfoDialog, openWorkOrderInfoDialog } = this.props;
    if (key === 'work_hour.date') {
      return (
        <CustomTooltip
          workHour={row.work_hour}
          mode="wo_status"
          // eslint-disable-next-line camelcase
          tooltippedNode={renderDateWithStatus(row?.work_hour.formattedDate, row?.work_hour.status)}
          // eslint-disable-next-line camelcase
          tooltipContent={this.renderStatusTextWithComment(row?.work_hour.status, row?.work_hour.employer_comment)}
          customWidth="65px"
        />
      );
    }

    if (key === 'user.full_name') {
      return (
        <span
          role="button"
          onClick={(e) => {
            e.stopPropagation();
            openEmployeeInfoDialog(row.user);
          }}
          style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
        >
          {row.user.full_name}
        </span>
      );
    }

    if (key === 'work_order_info.name') {
      return (
        <div
          role="button"
          onClick={(e) => {
            if (row.work_order_info.mode !== 'absence') {
              e.stopPropagation();
              openWorkOrderInfoDialog(row.work_order_info);
            }
          }}
        >
          {/* eslint-disable-next-line camelcase */}
          <div className="no-print" style={{ minWidth: '100px' }}>
            <CustomTooltip workHour={row.work_hour} mode="wo_name" tooltippedNode={row?.work_order_info.name ? truncate(row.work_order_info.name, 20) : ''} tooltipContent={row?.work_order_info.name} />
          </div>
          <div className="only-print work-order-name">
            <span>{row?.work_order_info.name}</span>
          </div>
        </div>
      );
    }

    if (key === 'work_order_trip') {
      if (Object.keys(row.work_order_trip).length > 0) {
        const result = this.renderTrip(row.work_order_trip);
        return result;
      }
      return '';
    }

    if (key === 'work_hour.kms') {
      const result = this.renderKms(row);
      return result;
    }

    if (key === 'work_hour.description') {
      const value = get(row, key) || '';
      return (
        <div
          style={{ width: '150px', whiteSpace: 'pre-line', hyphens: 'auto' }}
        >
          {value}
        </div>
      );
    }

    const value = get(row, key) || '';
    return value;
  }

  renderTrip = (workOrderTrip) => {
    const { openWorkOrderTripAcceptanceDialog } = this.props;
    const tripDateRange = shortDateRange(workOrderTrip.start_trip.date, workOrderTrip.end_trip.to_date);

    return (
      <div
        key={workOrderTrip.id}
        onClick={(e) => {
          e.stopPropagation();
          openWorkOrderTripAcceptanceDialog(workOrderTrip.id);
        }}
        style={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
        }}
        role="button"
      >
        {tripDateRange}
      </div>
    );
  };

  constructColumnsHTML(columnVisibility) {
    const { uiStore: { currentUser, currentUser: { accountInfo: { workOrderTripsEnabled } } } } = this.props;

    const staticColumns = [
      {
        key: 'user.full_name',
        label: 'Työntekijä',
      },
      {
        key: 'work_order_info.name',
        label: 'Aihe',
      },
      {
        key: 'work_hour.date',
        label: 'Pvm',
      },
      {
        key: 'hours',
        label: 'Klo',
      },
      {
        key: 'work_hour.time_normal',
        label: 'Norm.',
      },
      {
        key: 'work_hour.time_50',
        label: '50%',
      },
      {
        key: 'work_hour.time_100',
        label: '100%',
      },
      {
        key: 'work_hour.travel_time',
        label: 'Matka h työajan ulkop.',
      },
      {
        key: 'work_hour.drive_time',
        label: 'Matka h työajalla',
      },
    ];

    const dynamicColumns = [
      {
        key: 'work_hour.drive_time_50',
        label: 'Matka h 50%',
      },
      {
        key: 'work_hour.drive_time_100',
        label: 'Matka h 100%',
      },
      {
        key: 'work_hour.kms',
        label: 'KM oma auto',
      },
      {
        key: 'work_hour.kms_person',
        label: 'KM + 1 hlö',
      },
      {
        key: 'work_hour.kms_trailer',
        label: 'KM + peräkärry',
      },
      {
        key: 'work_hour.kms_service',
        label: 'KM Huolto-auto',
      },
      {
        key: 'work_hour.kms_heavy_load',
        label: 'KM iso kuorma',
      },
      {
        key: 'work_hour.allowance',
        label: 'PV-raha',
      },
      {
        key: 'work_hour.sunday_work',
        label: 'Su-korv.',
      },
      {
        key: 'work_hour.weekly_rest',
        label: 'Vko-lepo',
      },
      {
        key: 'work_hour.evening_shift',
        label: 'Ilta-lisä',
      },
      {
        key: 'work_hour.night_shift',
        label: 'Yö-lisä',
      },
      {
        key: 'work_hour.emergency_work',
        label: 'Hälytys-raha',
      },
      {
        key: 'work_hour.condition_compensation',
        label: 'Olos. lisä',
      },
      {
        key: 'work_hour.condition_compensation_two',
        label: 'Olos. lisä 2',
      },
      {
        key: 'work_hour.condition_compensation_three',
        label: 'Olos. lisä 3',
      },
      {
        key: 'work_hour.condition_compensation_four',
        label: 'Olos. lisä 4',
      },
      {
        key: 'work_hour.mining_compensation',
        label: 'Kaivos- lisä',
      },
      {
        key: 'work_hour.midweek_holiday_compensation',
        label: 'Arkipyhä-korv.',
      },
      {
        key: 'work_hour.description',
        label: 'Lisätieto',
      },
    ];

    if (workOrderTripsEnabled) {
      const workOrderTripColumn = {
        key: 'work_order_trip',
        label: 'Matka',
      };
      staticColumns.splice(17, 0, workOrderTripColumn);
    }

    // which account uses which columns
    const accountIdColumnMap = [
      {
        accountId: 1,
        columns: ["work_hour.drive_time_50", "work_hour.drive_time_100", "work_hour.kms", "work_hour.kms_person", "work_hour.kms_service", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.midweek_holiday_compensation", "work_hour.description"],
      },
      {
        accountId: 2,
        columns: ["work_hour.kms", "work_hour.kms_person", "work_hour.kms_trailer", "work_hour.kms_service", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.condition_compensation", "work_hour.midweek_holiday_compensation", "work_hour.description"],
      },
      {
        accountId: 7,
        columns: ["work_hour.kms", "work_hour.kms_person", "work_hour.kms_trailer", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.condition_compensation", "work_hour.mining_compensation", "work_hour.midweek_holiday_compensation", "work_hour.description"],
      },
      {
        accountId: 10,
        columns: ["work_hour.drive_time_50", "work_hour.drive_time_100", "work_hour.kms", "work_hour.kms_person", "work_hour.kms_trailer", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.condition_compensation", "work_hour.mining_compensation", "work_hour.midweek_holiday_compensation", "work_hour.description"],
      },
      {
        accountId: 6,
        columns: ["work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.midweek_holiday_compensation", "work_hour.description"],
      },
      {
        accountId: 49,
        columns: ["work_hour.kms", "work_hour.kms_person", "work_hour.kms_trailer", "work_hour.kms_service", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.condition_compensation", "work_hour.midweek_holiday_compensation", "work_hour.description"],
      },
      {
        accountId: 58,
        columns: ["work_hour.kms", "work_hour.kms_service", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.condition_compensation", "work_hour.midweek_holiday_compensation", "work_hour.description", "work_hour.condition_compensation_two", "work_hour.condition_compensation_three", "work_hour.condition_compensation_four", "work_hour.kms_person", "work_hour.kms_heavy_load"],
      },
    ];

    // these are what previously used to be in 'else'
    const defaultOptionalColumns = ["work_hour.kms", "work_hour.kms_person", "work_hour.kms_service", "work_hour.allowance", "work_hour.sunday_work", "work_hour.weekly_rest", "work_hour.evening_shift", "work_hour.night_shift", "work_hour.emergency_work", "work_hour.midweek_holiday_compensation", "work_hour.description"];

    // finds the current account
    const accountIdMap = accountIdColumnMap.filter((aIdMap) => aIdMap.accountId === currentUser.accountId);

    // initializes optional columns with default ones
    let optionalColumns = dynamicColumns.filter((column) => defaultOptionalColumns.includes(column.key));

    // finds the columnns associated with the current account and then reassigns optional columns to the correct ones
    if (accountIdMap.length > 0) {
      const firstFoundAccountIdMap = accountIdMap[0];
      optionalColumns = dynamicColumns.filter((column) => firstFoundAccountIdMap.columns.includes(column.key));
    }

    // combines the static columns that everyone has with the optional (or "dynamic") columns into one array
    let finalColumns = staticColumns.concat(optionalColumns);

    // Retain the column visibility setting even when the columns are reconstructed with new data
    // E.g. user has hid columns manually and we don't want to reveal them

    // // columnVisibility isn't set yet on the first column construction (componentDidMount)
    if (columnVisibility && columnVisibility.length > 0) {
      // Force the previous visibility settings on the reconstructed columns
      // finalColumns = finalColumns.map((finalCol) => ({ ...finalCol, visible: columnVisibility[finalCol.name].visible }));
      finalColumns = finalColumns.filter((finalCol) => (
        columnVisibility.find((colVis) => colVis.key === finalCol.key && colVis.visible)
      ));
    } else {
      // Assuming first render, all visible
      finalColumns = finalColumns.map((finalCol) => ({ ...finalCol, visible: true }));
    }

    return finalColumns;
  }

  renderStatusTextWithComment = (status, employerComment) => {
    const { t } = this.props;
    return (
      <div>
        <div>{t(`timelog.attributes.statuses.${status}`)}</div>
        {status === 'rejected' && (
          <>
            <br />
            <div className="tooltip-employer-comment">
              {`"${employerComment}"`}
            </div>
          </>
        )}
      </div>
    );
  }

  emitColumnVisibility(columns) {
    const { emitColumnVisibility } = this.props;
    const newColumnVisibility = [];
    // First render, this is all true (visible)
    columns.forEach((col) => {
      // newColumnVisibility[col.key] = col.visible;
      newColumnVisibility.push({
        key: col.key,
        label: col.label,
        visible: true,
      });
    });
    // Second and other renders, do we even need to emit again?
    emitColumnVisibility(newColumnVisibility);
  }

  renderKms(workHour) {
    const { openTripRouteDialog } = this.props;
    // eslint-disable-next-line camelcase
    const result = workHour?.work_hour.kms;
    const clickable = workHour?.work_hour.trip_routes && workHour?.work_hour.trip_routes.length > 0;

    return (
      <div
        role="button"
        style={{ cursor: clickable ? 'pointer' : 'inherit' }}
        onClick={() => {
          if (workHour?.work_hour.trip_routes && workHour?.work_hour.trip_routes.length > 0) {
            // this.showTripDialog(workHour.work_hour.trip_routes, workHour.old_work_hour_trip_routes, workHour.work_hour.date, workHour.user_name);
            // this.setState({
            //   tripRouteDialogOpen: true,
            //   currentTimelogTrip: {
            //     tripRoutes: workHour?.work_hour.trip_routes,
            //     date: workHour?.work_hour.date,
            //     user: workHour?.user,
            //   },
            // });
            openTripRouteDialog(workHour);
          }
        }}
      >
        <div
          className="no-print-underline"
          style={{ textDecorationLine: clickable ? 'underline' : 'none', textDecorationStyle: 'dotted' }}
        >
          {result}
        </div>
      </div>
    );
  }

  render() {
    const { currentWorkHoursWithMeta, columnVisibility } = this.props;

    // Array of columns as constructed by constructColumnsHTML
    const columns = this.constructColumnsHTML(columnVisibility);
    if (Object.keys(columnVisibility).length === 0) {
      // Initialize the column visibility by setting all columns to visible
      this.emitColumnVisibility(columns);
    }

    // Create the table header
    const tableHeader = (
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={column.key} className={index === 0 ? 'sticky-column' : ''}>
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
    );

    // Create the table body
    const tableBody = (
      <tbody>
        {(currentWorkHoursWithMeta || []).map((row) => (
          <tr key={`${row.work_hour.id}`}>
            {columns.map((column, index) => (
              <td key={`${row.work_hour.id}-${column.key}`} className={index === 0 ? 'sticky-column' : ''}>
                {this.renderHTMLTableCell(row, column.key)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    );

    return (
      <table className="timelog-report-html-table">
        {tableHeader}
        {tableBody}
      </table>
    );
  }
}

export default TimelogReportTableHtml;
