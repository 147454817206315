/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { TextField } from '@rmwc/textfield';
import { GridCell, GridInner } from '@rmwc/grid';
import { Checkbox } from '@rmwc/checkbox';
import { Typography } from '@rmwc/typography';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import AutocompletableLocation from '../shared/AutocompletableLocation';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';
import { toFloat } from 'validator';

import TimeField from 'react-simple-timefield';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/textfield/dist/mdc.textfield.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/ripple/dist/mdc.ripple.css';
import '@material/typography/dist/mdc.typography.css';

import EndTrip from './EndTrip';
import AutocompleteLocation from './AutocompleteLocation';
import noop from '../utils/noop';
import moment, { dateWithoutTime } from '../utils/moment';
import CustomTimeField from '../shared/CustomTimeField';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('t', 'uiStore')
@observer
class TimelogTripEnd extends Component {
  constructor(props) {
    super(props);

    const { endTrip } = props;

    this.state = {
      calcEndDate: '',
    };

    this.updateLocation = this.updateLocation.bind(this);

    let trip = endTrip ? endTrip : new EndTrip({});

    if (trip) {
      if (!trip.tripRoute) {
        trip.tripRoute = [];
      }

      if (trip.tripRoute.length === 0) {
        trip.tripRoute.push({
          id: null,
          description: null,
          endTripId: null,
          startTripId: null,
          routeType: null,
          kms: null,
          statu: 'draft',
          routeLocations: [
            {
              id: null,
              city: null,
              zipCode: null,
              stree: null,
              locationOrder: 0,
              status: 'draft',
            },
            {
              id: null,
              city: null,
              zipCode: null,
              street: null,
              locationOrder: 1,
              status: 'draft',
            },
          ],
        });
      } else if (trip.tripRoute[0].routeLocations.length < 2) {
        let order = trip.tripRoute[0].routeLocations.length;
        while (trip.tripRoute[0].routeLocations.length < 2) {
          trip.tripRoute[0].routeLocations.push({
            id: null,
            city: null,
            zipCode: null,
            street: null,
            locationOrder: order,
            status: 'draft',
          });
          order++;
        }
      }

      this.state = {
        dialogOpen: false,
        // ...workOrderTrip[0],
        directionTrip: { ...trip },
        showDeleteDialog: false,
        itemToDelete: '',
        deleteMessage: '',
        streetOpen: false,
        matches: [],
        matchObjects: [],
        endTest: props.testiProp || '',
      };
    } else {
      const fakeTrip = {
        id: null,
        date: null,
        time: null,
        toDate: null,
        toTime: null,
        status: 'draft',
        // uiWorkOrderId: workOrder.id,
        workOrderTripId: null,
        tripRoute: [
          {
            id: null,
            description: null,
            endTripId: null,
            startTripId: null,
            routeType: null,
            kms: null,
            status: 'draft',
            routeLocations: [
              {
                id: null,
                city: null,
                zipCode: null,
                street: null,
                locationOrder: 0,
                status: 'draft',
              },
              {
                id: null,
                city: null,
                zipCode: null,
                street: null,
                locationOrder: 1,
                status: 'draft',
              },
            ],
          },
        ],
      };

      this.state = {
        dialogOpen: false,
        // ...workOrderTrip[0],
        directionTrip: { ...fakeTrip },
        showDeleteDialog: false,
        itemToDelete: '',
        deleteMessage: '',
        streetOpen: false,
        matches: [],
        matchObjects: [],
      };
    }
  }

  componentDidMount() {
    const { currentUser } = this.props.uiStore;

    const foundAddresses = [...currentUser.autocompleteLocations];

    this.setState({
      autocompleteOptions: foundAddresses,
    });
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.startLocs) != JSON.stringify(prevProps.startLocs)) {
      this.changeAutocompleteLocations();
    }
  }

  getTripEndTime() {
    const { directionTrip } = this.state;

    const startDate = directionTrip.date.format('YYYY-MM-DD');
    const startTime = directionTrip.time;
    const dateWithTime = moment(`${startDate}T${startTime}:00Z`);
    const dateToMs = new Date(dateWithTime).getTime();
    const kms = directionTrip.tripRoute[0]['kms'];

    // 60 km/h, 1km per minute so kms = mins
    const travelTimeToMs = kms * 60000;
    let endTime = new Date(dateToMs + travelTimeToMs);
    endTime = moment(endTime);

    // We need to do this dateWithoutTime() thing here because otherwise the toDate object is different from the original object that was constructed from the API response
    // And if the date objects are different, comparing the new and old trips will always say that the trips changed even though the actual values are the same
    directionTrip.toDate = dateWithoutTime(endTime.format('YYYY-MM-DD'));
    directionTrip.toTime = endTime.format('HH:mm');
  }

  capitalizeString = (str) => {
    str = str.charAt(0).toUpperCase() + str.slice(1);
    return str;
  }

  updateLocation(tripRoute) {
    const { directionTrip } = this.state;
    const { updateEndTrip } = this.props;

    const updatedTrip = { ...directionTrip }
    updatedTrip.tripRoute = tripRoute;

    this.setState({
      directionTrip: updatedTrip,
    }, () => {
      updateEndTrip(new EndTrip(updatedTrip));
    });
  }

  updateRouteLocation = (routeIndex, locationIndex, location) => {
    const { directionTrip } = this.state;
    const { updateEndTrip } = this.props;

    const updatedTrip = { ...directionTrip };
    updatedTrip.tripRoute[0].routeLocations[locationIndex] = location;

    this.setState({
      directionTrip: updatedTrip,
    }, () => {
      updateEndTrip(new EndTrip(updatedTrip), true);
    });
  }

  changeAutocompleteLocations() {
    const { startLocs } = this.props;
    const { autocompleteOptions } = this.state;

    const foundAddresses = autocompleteOptions;

    if (startLocs) {
      startLocs.forEach((location) => {
        if (location.street != null && location.city != null) {
          const foundTempIndex = foundAddresses.findIndex((item) => item.tempIndex == location.tempIndex);

          if (foundTempIndex != -1) {
            foundAddresses[foundTempIndex].updateProperties(location);
          } else if (!foundAddresses.filter((loc) => loc.street == location.street && loc.city == location.city).length > 0) {
            const itemToAdd = {
              street: location.street,
              zipCode: location.zipCode,
              city: location.city,
              tempIndex: location.tempIndex,
            };
            foundAddresses.push(new AutocompleteLocation(itemToAdd));
          }
        }
      });
    }
  }

  addHourField(attr, label) {
    const { startTrip, disabled } = this.props;
    const { directionTrip } = this.state;

    return (
      <div
        className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
        style={{
          // height: '44px',
          // width: '45px',
          // margin: 0,
          // padding: 0,
          width: '100%',
          backgroundColor: 'var(--mdc-theme-secondary)',
          color: 'white',
          flexDirection: 'column'
        }}
      >
        <div style={{ fontSize: '12px', paddingLeft: '5px' }}>{label}</div>
        <CustomTimeField
          value={directionTrip?.[attr] || '--:--'}
          attr={attr}
          doOnChange={(event, value) => { this.doOnChange(attr, value, event); }}
          doOnBlur={(attr, value, event) => {
            // Same as doOnChange(), but we call doOnEndTimeBlur on the callback
            this.setState({
              directionTrip: { ...directionTrip, [attr]: value },
            }, () => {
              this.doOnEndTimeBlur(attr);
            });
          }}
          handleEnter={handleEnter}
          disabled={disabled}
        />
      </div>
    );
  }

  doOnEndTimeBlur(attr) {
    const { updateEndTrip } = this.props;
    const { directionTrip } = this.state;

    if ((attr !== 'toTime' && attr !== 'toDate') && directionTrip.tripRoute[0] && directionTrip.date != null && directionTrip.time != null && directionTrip.tripRoute[0]['kms'] != null) {
      this.getTripEndTime();
    }

    updateEndTrip(new EndTrip(directionTrip));
  }

  addDecimalField(attr, label, ctx = {}) {
    const { disabled } = this.props;
    const { directionTrip } = this.state;

    return (
      <TextField {...{
        // onBlur: (event) => {
        //   this.doOnTripRouteBlur(attr, event.target.value, event, null, null);
        // },
        onBlur: (event) => {
          // updateEndTrip(directionTrip)
          this.doOnEndTimeBlur(attr);
        },
        label,
        onChange: noop,
        onInput: (event) => {
          let value = event.target.value.replace(/[.,]{1,}/, '.');

          if (value.substr(-1) !== '.' && value !== '') {
            value = toFloat(value);
            // if (value >= (ctx.max || 24)) {
            //   return;
            // }
          }

          if ((!Number.isNaN(value) || value === '')) {
            this.doOnChangeTripRoute(attr, value, event);
          }
        },
        disabled: disabled,
        pattern: '[0-9.,]*',
        rootProps: { ripple: false },
        style: {
          width: '100%',
        },
        // style: {
        //   margin: 0,
        //   minWidth: '40px',
        //   padding: 0,
        //   width: '130px',
        // },
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        type: 'text',
        onKeyPress: handleEnter,
        value: (this.state != null && this.state.directionTrip.tripRoute[0] && this.state.directionTrip.tripRoute[0][attr] != null && !Number.isNaN(this.state.directionTrip.tripRoute[0][attr])) ? this.state.directionTrip.tripRoute[0][attr] : '',
        ...ctx,
      }}
      />
    );
  }

  addCheckboxField(label) {
    const { endTripHidden, toggleEndTripHidden, defaultTripDate, disabled } = this.props;
    const { directionTrip } = this.state;

    return (
      <Checkbox {...{
        checked: endTripHidden,
        className: 'border-on-dark',
        disabled: disabled,
        label,
        style: {
          height: '44px',
          margin: '10px 0',
          minWidth: '40px',
          padding: 0,
        },
        onChange: () => {
          // // If the end trip has been saved and not hidden, autofill according to the row's date
          // if (endTripHidden && directionTrip.id) {
          //   // Previous value is true, therefore we are unchecking the "hide" box
          //   this.autofillStartTimeWithWorkHour(defaultTripDate);
          // }
          toggleEndTripHidden();
        },
      }}
      />
    );
  }

  generateQuickAddressOptions() {
    const { workOrder, uiStore: { currentUser } } = this.props;
    const quickAddresses = {};

    // Check if workOrder or required properties are missing, so we can't generate quick addresses and the render doesnt break
    if (!workOrder || !workOrder.location || !workOrder.location.city || !workOrder.location.street) {
      console.warn('workOrder or required location properties are missing.');
      return null;
    }

    if (workOrder.accomodationCity && workOrder.accomodationStreet) {
      quickAddresses.accomodation = [{
        name: 'Majoitus',
        city: workOrder.accomodationCity,
        street: workOrder.accomodationStreet,
        zipCode: workOrder.accomodationZipCode,
      }];
    }

    quickAddresses.location = [{
      name: 'Kohde',
      city: workOrder.location.city,
      street: workOrder.location.street,
      zipCode: workOrder.location.zip_code,
      defaultDepartureAddress: true,
    }];

    const homeAddress = currentUser.homeAddress();
    if (homeAddress) {
      homeAddress.defaultDestinationAddress = true;
      quickAddresses.home = [homeAddress];
    }

    currentUser.accountInfo.companyLocations.forEach((companyLocation) => {
      if (!quickAddresses.companyLocation) {
        quickAddresses.companyLocation = [];
      }
      quickAddresses.companyLocation.push({
        name: companyLocation.name,
        city: companyLocation.city,
        street: companyLocation.street,
        zipCode: companyLocation.zip_code,
      });
    });

    return quickAddresses;
  }

  renderReturnForm() {
    const { date, toDate, tripRoute } = this.state.directionTrip;
    const { directionTrip, autocompleteOptions } = this.state;
    const { endTripHidden, startTrip, maxEndDate, disabled } = this.props;

    const quickAddresses = this.generateQuickAddressOptions();

    const styleInner = {
      rowGap: '0px'
    };

    let locations = [];
    if (tripRoute) {
      if (tripRoute[0]) {
        locations = tripRoute[0].routeLocations;
      }
    }

    const renderLocations = [];

    locations.forEach((_, index) => {
      let locationLabel = '';
      let selectedQuickAddressLabel = '';
      if (index === 0) {
        locationLabel = 'Lähtösijainnin katuosoite *';
        selectedQuickAddressLabel = 'Lähtösijainti';
      } else if (tripRoute[0] && index + 1 === tripRoute[0]?.routeLocations?.length) {
        locationLabel = 'Kohdesijainnin katuosoite *'
        selectedQuickAddressLabel = 'Kohdesijainti';
      } else {
        locationLabel = 'Katuosoite *'
        selectedQuickAddressLabel = 'Sijainti';
      }

      // First end trip location is the second start trip location by default
      // Second end trip location is the first start trip location by default
      let defaultMatches = null;
      const matchingStartTripLocation = startTrip.tripRoute[0].routeLocations[index === 0 ? 1 : 0];
      if (matchingStartTripLocation.street) {
        if (!matchingStartTripLocation.tempName) {
          matchingStartTripLocation.tempName = index === 0 ? 'Kohdesijainnin katuosoite' : 'Lähtösijainnin katuosoite';
        }
        defaultMatches = [matchingStartTripLocation];
      }

      renderLocations.push(
        <div
          style={{
            margin: '5px -10px 5px -10px',
            // marginTop: '20px',
            // borderBottom: '1px solid var(--mdc-theme-primary)'
          }}
        >
          <Typography
            use="subtitle1"
            style={{ paddingLeft: '10px' }}
          >
            {index === 0 ? 'Syötä paluumatkan lähtösijainti' : 'Syötä paluumatkan kohdesijainti' }
          </Typography>
        </div>
      );

      renderLocations.push(
        <AutocompletableLocation
          key={index}
          locationIndex={index}
          locationLabel={locationLabel}
          selectedQuickAddressLabel={selectedQuickAddressLabel}
          tripRoute={tripRoute}
          autocompleteOptions={autocompleteOptions}
          updateLocation={this.updateLocation}
          updateRouteLocation={this.updateRouteLocation}
          defaultMatches={defaultMatches}
          disabled={disabled}
          quickAddresses={quickAddresses || {}}
        />
      );
    });

    return (
      <>
        {/* <div
          style={{
            margin: '10px -10px 5px -10px',
            paddingBottom: '5px',
            borderBottom: '1px solid var(--mdc-theme-primary)'
          }}
        >
          <Typography
            use="subtitle1"
            style={{ paddingLeft: '10px' }}
          >
            Työmatkan Päättyminen
          </Typography>
        </div> */}

        {this.addCheckboxField('En tiedä milloin työmatka päättyy')}

        {!endTripHidden && (
          <>
            {renderLocations}

            {this.addDecimalField('kms', 'Etäisyys (km)', { style: { marginTop: '20px', width: '100%' } })}

            <GridCell span={12}>
              <GridInner
                style={styleInner}
              >
                <GridCell
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <DayPickerInput
                    component={
                      props =>
                        <>
                          <TextField
                            {...props}
                            {...{
                              label: 'Paluumatkan alkamispäivä *',
                              theme: ['textPrimaryOnLight', 'secondaryBg'],
                              placeholder: '',
                              style: { width: '100%' },
                            }}
                          />
                        </>
                    }
                    inputProps={{
                      ref: null,
                      className: '',
                      // style: { padding: '0 15px 0 15px' },
                      readOnly: true,
                      disabled: disabled,
                    }}
                    dayPickerProps={{
                      locale: 'fi',
                      localeUtils: MomentLocaleUtils,
                      // modifiers: {
                      //   disabled: [
                      //     {
                      //       before: moment(startTrip.date, 'YYYY-MM-DD', 'fi').toDate(),
                      //     },
                      //     {
                      //       after: maxEndDate && moment(maxEndDate, 'YYYY-MM-DD', 'fi').toDate(),
                      //     },
                      //   ],
                      // },
                    }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="dd DD.MM.YYYY"
                    // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
                    onDayChange={(value) => this.handleChangeForDate('date', value)}
                    // style={{ marginLeft: '15px' }}
                    style={{ display: 'block' }}
                    // This is is almost certainly overcomplicating things but it works
                    value={date ? moment(date, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                  />
                </GridCell>
                <GridCell
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  {this.addHourField('time', 'Alkamisajankohta')}
                </GridCell>
              </GridInner>
            </GridCell>

            <GridCell span={12}>
              <GridInner
                style={styleInner}
              >
                <GridCell
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <DayPickerInput
                    component={
                      props =>
                        <>
                          <TextField
                            {...props}
                            {...{
                              label: 'Paluumatkan päättymispäivä *',
                              theme: ['textPrimaryOnLight', 'secondaryBg'],
                              placeholder: '',
                              style: { width: '100%' },
                            }}
                          />
                        </>
                    }
                    inputProps={{
                      ref: null,
                      className: '',
                      // style: { padding: '0 15px 0 15px' },
                      readOnly: true,
                      disabled: disabled,
                    }}
                    dayPickerProps={{
                      locale: 'fi',
                      localeUtils: MomentLocaleUtils,
                      // modifiers: {
                      //   disabled: [
                      //     {
                      //       before: moment(startTrip.date, 'YYYY-MM-DD', 'fi').toDate(),
                      //     },
                      //     {
                      //       after: maxEndDate && moment(maxEndDate, 'YYYY-MM-DD', 'fi').toDate(),
                      //     },
                      //   ],
                      // },
                    }}
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="dd DD.MM.YYYY"
                    onDayChange={(value) => this.handleChangeForDate('toDate', value)}
                    // style={{ marginLeft: '15px' }}
                    style={{ display: 'block' }}
                    // This is is almost certainly overcomplicating things but it works
                    // value={calcEndDate ? moment(calcEndDate, 'YYYY-MM-DD', 'fi').toDate() : (toDate ? moment(toDate, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä')}
                    value={toDate ? moment(toDate, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
                  // classNames={{
                  //   container: '',
                  //   overlayWrapper: 'DayPickerInput-OverlayWrapper',
                  //   overlay: 'DayPickerInput-Overlay'
                  // }}
                  />
                </GridCell>
                <GridCell
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  {this.addHourField('toTime', 'Päättymisajankohta')}
                </GridCell>
              </GridInner>
            </GridCell>
          </>
        )}
      </>
    );
  }

  doOnChange(attr, value, event) {
    const { directionTrip } = this.state;

    this.setState({ directionTrip: { ...directionTrip, [attr]: value } });
  }

  doOnChangeTripRoute(attr, value, event) {
    const { directionTrip } = this.state;
    // eslint-disable-next-line react/destructuring-assignment
    if (directionTrip.tripRoute[0][attr] !== value && event.target.validity.valid) {
      const updatingTripRoute = directionTrip.tripRoute[0];
      updatingTripRoute[attr] = value;
      this.setState({ tripRoute: [updatingTripRoute] });
      // trip.changeAttribute(attr, value);
    }
  }

  autofillStartTimeWithWorkHour(inputValue) {
    const { workOrder } = this.props;
    const { directionTrip } = this.state;

    const inputDate = moment(inputValue).format('YYYY-MM-DD');
    const foundWorkHour = workOrder.workHours?.find((workHour) => workHour.date && workHour.date?.format('YYYY-MM-DD') === inputDate);

    if (foundWorkHour) {
      if (workOrder.workHourType === 'hourly' && foundWorkHour.workTaskEntries && foundWorkHour.workTaskEntries > 0) {
        // For hourly work orders, we need to find the oldest workTaskEntry time for autofilling
        let oldestTime = null;
        foundWorkHour.workTaskEntries.forEach((workTaskEntry) => {
          if (!oldestTime || workTaskEntry.to > oldestTime) {
            oldestTime = workTaskEntry.to;
          }
        });
        directionTrip.time = oldestTime;
      } else {
        // For daily work orders, we can use foundWorkHour.to for autofilling
        if (foundWorkHour && foundWorkHour.to) {
          directionTrip.time = foundWorkHour.to;
        }
      }

      // Autofill date with the found work hour if it hasn't been set yet
      if (!directionTrip.date) {
        directionTrip.date = foundWorkHour.date;
      }
      // Autofill end date and end time if kms exists (all necessary pieces are there: kms, date, time)
      if (directionTrip.kms) {
        this.getTripEndTime();
      }
    }
  }

  handleChangeForDate(attr, value, disabled) {
    const { directionTrip } = this.state;

    if (attr === 'date') {
      this.autofillStartTimeWithWorkHour(value);
    }

    directionTrip[attr] = moment(value);

    this.doOnEndTimeBlur(attr);
  }

  render() {
    return (
      this.renderReturnForm()
    );
  }
}

export default TimelogTripEnd;
