import React from 'react';
import ReactDOM from 'react-dom';
import { RMWCProvider } from '@rmwc/provider';

import App from './app/App';
import * as serviceWorker from './serviceWorker';
import './index.css';
import '../node_modules/material-components-web/dist/material-components-web.min.css';
import './app/App.css';
import './app/responsive.css';

ReactDOM.render(
  <RMWCProvider ripple={false}>
    <App />
  </RMWCProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module != null && module.hot) {
  module.hot.accept();
}
