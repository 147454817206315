import { action } from 'mobx';
// import TimelogEntry from '../timelog/TimelogEntry';

// const findCurrentSalaryPeriodIndex = (salaryPeriods) => {
//   let earliestPeriodIndex = -1;
//   let earliestPeriod = null;

//   salaryPeriods.forEach((period, index) => {
//     if (period.status === 'opened' || period.status === 'locked') {
//       if (!earliestPeriod || new Date(period.from) < new Date(earliestPeriod.from)) {
//         earliestPeriod = period;
//         earliestPeriodIndex = index;
//       }
//     }
//   });

//   return earliestPeriodIndex;
// };

class SalaryPeriodStore {
  constructor(requests) {
    this.requests = requests;
    // this.uiStore = uiStore;
  }

  // Generic work hour report, usually for a salary period
  @action getCurrentSalaryPeriods(resolve, reject) {
    // const ctxUser = this.uiStore.currentUser;
    return this.requests.SalaryPeriods.getCurrent().then((result) => {
      // const rows = result.rows.map((item) => TimelogEntry.fromJsonProperties(item));
      resolve(result);
    }).catch((err) => reject(err));
  }

  @action getClosedSalaryPeriodsGroupedByCategory(resolve, reject) {
    return this.requests.SalaryPeriods.getClosedGroupedByCategory().then((result) => {
      // const resultWithCurrentPeriodsFound = { ...result };
      // Find the current work periods
      // OPTIMIZE: Do this on the backend, with some kind of virtual column? We want to avoid doing a loop like this: not efficient
      // result.salary_period_categories.forEach((category) => {
      //   const categorySalaryPeriods = result.grouped_salary_periods[category.id];
      //   const currentSalaryPeriodIndex = findCurrentSalaryPeriodIndex(categorySalaryPeriods);
      //   if (findCurrentSalaryPeriodIndex !== -1) {
      //     resultWithCurrentPeriodsFound.grouped_salary_periods[category.id][currentSalaryPeriodIndex].isCurrent = true;
      //   }
      // });

      resolve(result);
    }).catch((err) => reject(err));
  }

  @action lockSalaryPeriod(salaryPeriodId, resolve, reject) {
    console.log('Locking id in store: ', salaryPeriodId);
    return this.requests.SalaryPeriods.lock(salaryPeriodId).then((result) => {
      console.log('RESULT of lock: ', result);
      // const rows = result.rows.map((item) => TimelogEntry.fromJsonProperties(item));
      resolve(result);
    }).catch((err) => reject(err));
  }

  @action closeSalaryPeriod(salaryPeriodId, resolve, reject) {
    console.log('Closing id in store: ', salaryPeriodId);
    return this.requests.SalaryPeriods.close(salaryPeriodId).then((result) => {
      console.log('RESULT of close: ', result);
      // const rows = result.rows.map((item) => TimelogEntry.fromJsonProperties(item));
      resolve(result);
    }).catch((err) => reject(err));
  }

  @action updateMessage(salaryPeriodId, messageContent, resolve, reject) {
    console.log('Updating message: ', messageContent);
    console.log('Salary period: ', salaryPeriodId);
    return this.requests.SalaryPeriods.updateMessage(salaryPeriodId, messageContent).then((result) => {
      console.log('Updated message: ', result);
      resolve(result);
    }).catch((err) => reject(err));
  }

  @action downloadPayrollAdminReportsZip(salaryPeriodId, resolve, reject) {
    console.log('Downloading zip: ', salaryPeriodId);
    return this.requests.SalaryPeriods.downloadPayrollAdminReportsZip(salaryPeriodId).then((result) => {
      console.log('RESULT of downloadPayrollAdminReportsZip: ', result);
      resolve(result);
    }).catch((err) => reject(err));
  }
}

export default SalaryPeriodStore;
