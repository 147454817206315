import { action } from 'mobx';
import Validity from '../models/Validity';

class ValidityStore {
  constructor(uiStore, requests) {
    this.requests = requests;
    this.uiStore = uiStore;
  }

  // @action updateUserProductCollection(userProductCollection, resolve, reject) {
  //   const { currentUser } = this.uiStore;
  //   return this.requests.UserProductCollections[!userProductCollection.id ? 'create' : 'update'](currentUser, userProductCollection).then((result) => {
  //     console.log('Got result in update/create: ', result);
  //     // userProductCollection.updatePropertiesFromJson(result);
  //     const newUserProductCollection = UserProductCollection.fromJsonProperties(result);

  //     console.log('After turning into object: ', newUserProductCollection);
  //     resolve(newUserProductCollection);
  //   }).catch((err) => reject(err));
  // }

  @action updateValidity(validity, resolve, reject) {
    const ctxUser = this.uiStore.currentUser;

    return this.requests.Validities[!validity.id ? 'create' : 'update'](validity).then((result) => {
      const newValidity = Validity.fromJsonProperties(result);
      const updatedValidities = [...ctxUser.validities];
      const foundIndex = updatedValidities.findIndex((el) => el.id === newValidity.id);

      if (foundIndex !== -1) {
        updatedValidities.splice(foundIndex, 1, newValidity);
      } else {
        updatedValidities.push(newValidity);
      }
      ctxUser.validities.replace(updatedValidities);

      resolve(newValidity);
    }).catch((err) => reject(err));
  }

  @action deleteValidity(validity, resolve, reject) {
    const ctxUser = this.uiStore.currentUser;
    const validityId = validity.id;

    return this.requests.Validities.del(validity).then(() => {
      const updatedValidities = ctxUser.validities.filter((oldValidity) => oldValidity.id !== validityId);
      ctxUser.validities.replace(updatedValidities);

      resolve(true);
    }).catch((err) => reject(err));
  }

  @action purgeFile(validity, fileURL, resolve, reject) {
    const ctxUser = this.uiStore.currentUser;
    const splitURL = fileURL.split('/');
    const signedId = splitURL.at(-2);

    return this.requests.Validities.purgeFile(validity, signedId).then((response) => {
      const newValidity = Validity.fromJsonProperties(response);
      const updatedValidities = [...ctxUser.validities];
      const foundIndex = updatedValidities.findIndex((el) => el.id === newValidity.id);

      if (foundIndex !== -1) {
        // Replace the old validity with the new one by index
        updatedValidities.splice(foundIndex, 1, newValidity);
      } else {
        // Push the non-existent validity into the list (SHOULD NEVER ACTUALLY HAPPEN WITH FILE PURGE)
        updatedValidities.push(newValidity);
      }
      ctxUser.validities.replace(updatedValidities);

      resolve(newValidity);
    }).catch((err) => reject(err));
  }

  // @action deleteUserProductCollectionFile(signedId, userProductCollectionId) {
  //   const ctxUser = this.uiStore.currentUser;
  //   console.log('In store: trying to delete file by signed id ', signedId);
  //   return this.requests.UserProductCollections.purgeFile(ctxUser, userProductCollectionId, signedId).then(() => {
  //     console.log('File purged in store, updating prop');
  //     // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === workOrderId);
  //     // const foundIndex = foundWo.attachments.findIndex((attachment) => attachment.id === id);
  //     // const attachments = [...foundWo.attachments];
  //     // attachments[foundIndex] = { ...attachments[foundIndex], fileURL: null, filename: null };

  //     const updatedUserProductCollections = [...ctxUser.userProductCollections];
  //     const foundCollection = updatedUserProductCollections.find((el) => el.id === userProductCollectionId);

  //     if (foundCollection) {
  //       const newFiles = foundCollection.files.filter((file) => file.signedId !== signedId);
  //       foundCollection.changeAttribute('files', newFiles);
  //       console.log('Collection prop updated');
  //     } else {
  //       console.log('Something went wrong with purging file: user product collection not found');
  //     }
  //   });
  // }
}

export default ValidityStore;
