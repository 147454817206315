import { action, observable } from 'mobx';
import User from '../models/User';
import moment from '../utils/moment';

export default class BillingAttachment {
  @observable id;

  @observable closedAt;

  @observable closedBy;

  @observable createdAt;

  @observable description;

  @observable id;

  @observable name;

  @observable status;

  @observable uploadedBy;

  @observable workOrderId;

  @observable files;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  static toJson(o) {
    return {
      // file: o.file,
      files: o.files,
      description: o.description,
      id: o.id,
      name: o.name,
      status: o.status == null ? 'draft' : o.status,
      work_order_id: o.workOrderId,
    };
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      closedAt: o.closed_at ? moment(o.closed_at).local() : null,
      createdAt: moment(o.created_at).local(),
      // filename: o.filename,
      // fileURL: o.file_url,
      files: o.files.map((file) => ({
        filename: file.filename,
        fileURL: file.url,
        signedId: file.signed_id,
        createdAt: file.created_at,
      })),
      // TODO: File vs. Files is a backwards compatibility thing, REMOVE LATER
      file: o.file && { filename: o.file.filename, fileURL: o.file.url },
      description: o.description,
      id: o.id,
      name: o.name,
      status: o.status,
      uploadedBy: User.fromJsonProperties(o.uploaded_by),
      workOrderId: o.work_order_id,
    };

    if (o.closed_by != null) {
      values.closedBy = User.fromJsonProperties(o.closed_by);
    }

    return this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const billingAttachment = new BillingAttachment({});
    billingAttachment.updatePropertiesFromJson(o);
    return billingAttachment;
  }
}
