import { observable, action } from 'mobx';
import moment from 'moment';
import { isEqual } from 'lodash';

export default class CalendarEntry {
  // @observable calendarEntries;

  @observable id;

  @observable user;

  @observable from;

  @observable to;

  @observable workPeriodName;

  @observable _destroy = false;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updatePropertiesFromJson(object) {
    const splitDateRange = object.datetime_range.split('..');

    this.updateProperties({
      id: object.id,
      userId: object.user_id,
      status: object.status,
      from: moment(splitDateRange[0], 'YYYY-MM-DD HH:mm:ss UTC'),
      to: moment(splitDateRange[1], 'YYYY-MM-DD HH:mm:ss UTC'),
      workPeriodName: object.work_period_name || 'Muut',
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static packageJsonIntoRanges = (calendarEntries) => {
    // A stupid hack to quickly fix the problem where the database query/cache invents duplicate entries that don't actually exist in the DB
    const alreadyProcessedRanges = [];

    const calendarEntriesAsRanges = [];
    let potentialRangeCalendarEntry = null;
    // We are receiving calendarEntries as individual dates
    // We want to convert them into ranges for a neater UI, for example, Monday, Tuesday and Wednesday becomes Monday-Wednesday
    if (calendarEntries) {
      // Sort entries by the datetime range, ascending, so that the range grouping works
      const sortedEntries = calendarEntries.slice().sort((a, b) => {
        const dateA = new Date(a.datetime_range.split('..')[0]);
        const dateB = new Date(b.datetime_range.split('..')[0]);
        return dateA - dateB;
      });

      sortedEntries.forEach((entry, index) => {
        if (alreadyProcessedRanges.includes(entry.datetime_range)) {
          return;
        }

        alreadyProcessedRanges.push(entry.datetime_range);

        if (!potentialRangeCalendarEntry) {
          potentialRangeCalendarEntry = { ...entry };
        } else {
          // Assuming that calendarEntries are single day only (datetime_range 0 and 1 are the same date)
          const day = entry.datetime_range.split('..')[0];
          const oldStartDay = potentialRangeCalendarEntry.datetime_range.split('..')[0];
          const oldEndDay = potentialRangeCalendarEntry.datetime_range.split('..')[1];

          const momentDay = moment(day, 'YYYY-MM-DD HH:mm:ss UTC');
          const momentOldEndDay = moment(oldEndDay, 'YYYY-MM-DD HH:mm:ss UTC');

          const momentDayFormatted = momentDay.format('YYYY-MM-DD');
          const momentOldEndDayPlusOneFormatted = momentOldEndDay.add(1, 'days').format('YYYY-MM-DD');

          // if (momentDay.format('DD.MM.YY') === momentOldEndDay.add(1, 'days').format('DD.MM.YY')) {
          if (momentDayFormatted === momentOldEndDayPlusOneFormatted) {
            const newDatetimeRange = `${oldStartDay}..${momentDay.format('YYYY-MM-DD HH:mm:ss UTC')}`;
            potentialRangeCalendarEntry.datetime_range = newDatetimeRange;
          } else {
            calendarEntriesAsRanges.push(potentialRangeCalendarEntry);
            potentialRangeCalendarEntry = { ...entry };
          }
        }

        if (index + 1 === sortedEntries.length) {
          // Last item, push the range
          calendarEntriesAsRanges.push(potentialRangeCalendarEntry);
        }
      });

      // Ensure the last potential range is pushed if it wasn't already
      // This is necessary in some cases to prevent an empty array, such as when there's two absence calendar entries with identical dates
      if (potentialRangeCalendarEntry && !calendarEntriesAsRanges.find((entry) => isEqual(entry, potentialRangeCalendarEntry))) {
        calendarEntriesAsRanges.push(potentialRangeCalendarEntry);
      }
    }

    return calendarEntriesAsRanges;
  };

  // Used for rendering CalendarEntry objects with moment object "from" and "to" in the UI
  static packageJsonIntoRangesMoment = (calendarEntries) => {
    const calendarEntriesAsRanges = [];
    let potentialRangeCalendarEntry = null;
    // We are receiving calendarEntries as individual dates
    // We want to convert them into ranges for a neater UI, for example, Monday, Tuesday and Wednesday becomes Monday-Wednesday
    if (calendarEntries) {
      // Sort entries by the datetime range, ascending, so that the range grouping works
      const sortedEntries = calendarEntries.slice().sort((a, b) => {
        const dateA = a.from.toDate(); // .format('DD.MM.YY');
        const dateB = b.from.toDate(); // .format('DD.MM.YY');
        return dateA - dateB;
      });

      sortedEntries.forEach((entry, index) => {
        if (!potentialRangeCalendarEntry) {
          potentialRangeCalendarEntry = { ...entry };
        } else {
          // Assuming that calendarEntries are single day only (datetime_range 0 and 1 are the same date)
          const day = moment(entry.from);
          const oldEndDay = moment(potentialRangeCalendarEntry.to);

          const dayFormatted = day.format('YYYY-MM-DD');
          const oldEndDayPlusDayFormatted = oldEndDay.add(1, 'days').format('YYYY-MM-DD');

          if (dayFormatted === oldEndDayPlusDayFormatted) {
            potentialRangeCalendarEntry.to = day;
          } else {
            calendarEntriesAsRanges.push(potentialRangeCalendarEntry);
            potentialRangeCalendarEntry = { ...entry };
          }
        }

        if (index + 1 === sortedEntries.length && potentialRangeCalendarEntry) {
          // Last item, push the range
          calendarEntriesAsRanges.push(potentialRangeCalendarEntry);
        }
      });
    }

    return calendarEntriesAsRanges;
  };

  static toJson(o) {
    return {
      id: o.id,
      owner_type: 'User',
      owner_id: o.userId,
      status: o.status,
      datetime_range: `[${o.from.startOf('day').toISOString()}, ${o.to.endOf('day').toISOString()}]`,
      _destroy: o._destroy,
    };
  }

  static toJsonPreserveTimes(o) {
    return {
      id: o.id,
      owner_type: 'User',
      owner_id: o.userId,
      status: o.status,
      datetime_range: `[${o.from.toISOString()}, ${o.to.toISOString()}]`,
      _destroy: o._destroy,
    };
  }

  static fromJsonProperties(object) {
    const calendarEntry = new CalendarEntry({});
    calendarEntry.updatePropertiesFromJson(object);
    return calendarEntry;
  }

  static fromJson(json) {
    return CalendarEntry.fromJsonProperties(JSON.parse(json));
  }
}
