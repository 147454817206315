import { get } from 'lodash';

const filterMatch = (item, searchedValueKey, searchedValue) => {
  // Get value from item using the key, e.g. 'start_trip.status' = item.start_trip.status
  const itemValue = get(item, searchedValueKey);

  // Check if the value we found matches the desired filter value, e.g. 'accepted'
  if (Array.isArray(itemValue) && itemValue.includes(searchedValue)) {
    // Match found: item's value is an array that contains the desired value
    return true;
  }

  if (itemValue === searchedValue) {
    // One-to-one match found
    return true;
  }

  // No match found
  return false;
};

export default filterMatch;
