/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import TimeField from '../components/react-simple-timefield/src/index.tsx';

@inject('t')
@observer
class CustomTimeField extends Component {
  render() {
    const { disabled, doOnChange, handleEnter, doOnBlur, attr, value, styles, input, doOnFocus } = this.props;

    return (
      <TimeField
        value={value || '--:--'}
        onChange={(event, value) => {
          const currentValue = event.target.value;
          // Check if user is modifying only the minutes part
          if(value && value.length === 2 && currentValue.length === 5 && currentValue !== '--:--'){
            // Modify only the minutes if input is length of 2 (example: 30) and the current value is for example 07:00
            const updatedValue = `${currentValue.slice(0, 3)}${value}`;
            doOnChange(attr, updatedValue, event);
          }
          else { 
            doOnChange(attr, value != null ? value : '', event); 
          }
        }}
        colon=":"
        onBlur={(event) => {
          // We automatically turn inputs like 12 into 12:00
          const patchedValue = event.target.value.replace(':--', ':00');
          if (doOnBlur) {
            doOnBlur(attr, patchedValue, event);
          }
        }}
        style={{ color: 'black', ...styles }}
        // a test input prop for potentially intercepting a number like 8 (8-:--) and converting it to 08:--
        // onInputCapture={(e) => console.log('BEFORE CAPTURE', e.target.value)}
        input={input || <input type="text" className="mdc-text-field__input" />}
        onKeyPress={handleEnter}
        onClick={(event) => {
          const cursorPosition = event.target.selectionStart
          const currentValue = event.target.value

          if(currentValue === '--:--'){
            event.target.setSelectionRange(0, 0);
          }else if(cursorPosition > 2){
            event.target.setSelectionRange(3, 5);
          } else {
            event.target.setSelectionRange(0, 0)
          }
          }}
        disabled={disabled}
        onFocus={() => doOnFocus && doOnFocus()}
      />
    );
  }
}

export default CustomTimeField;
