import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card,
} from '@rmwc/card';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import '@material/card/dist/mdc.card.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';

@inject('timelogStore', 'uiStore', 't')
@observer
class WorkTaskCard extends Component {
  render() {
    const {
      handleCardClick,
      workTask,
    } = this.props;

    return (
      <Card
        style={{ backgroundColor: 'white', width: '100%', cursor: 'pointer' }}
        // onClick={() => uiStore.showWorkTaskDialog(id, identifierOne, identifierTwo, users)}
        onClick={(e) => {
          e.stopPropagation();
          handleCardClick(workTask);
        }}
      >
        <Grid
          style={{ width: '100%' }}
        >
          <GridCell
            span={12}
          >
            <GridInner>
              <GridCell
                style={{ fontSize: '14px' }}
                desktop={9}
                tablet={6}
                phone={3}
              >
                <span>{workTask.identifiers}</span>
              </GridCell>
              <GridCell
                style={{
                  fontSize: '12px',
                  textAlign: 'right',
                  fontWeight: '500',
                  justifySelf: 'flex-end',
                  display: 'flex',
                  gap: '10px',
                }}
                desktop={3}
                tablet={2}
                phone={1}
              >
                <span
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    width: 'fit-content',
                  }}
                >
                  <PeopleOutlineIcon
                    fontSize="small"
                    style={{
                      marginRight: '3px',
                    }}
                  />
                  <span>
                    {workTask.workTaskUsers?.length || 0}
                  </span>
                </span>
              </GridCell>
            </GridInner>
          </GridCell>

          <GridCell
            // className="employer-work-order-text-gray-sm"
            style={{
              color: '#000000',
              fontSize: '12px',
            }}
            span={12}
          >
            <GridInner>
              <GridCell
                desktop={6}
                tablet={4}
                phone={2}
              >
                <span
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    width: 'fit-content',
                    opacity: workTask.estimate && workTask.estimate < workTask.totalHours ? '1' : '0.6',
                  }}
                >
                  <AccessTimeIcon
                    fontSize="small"
                    style={{
                      marginRight: '3px',
                    }}
                  />
                  <span className={(workTask.estimate && workTask.estimate < workTask.totalHours) ? 'invitations-warning' : 'invitations-neutral'}>
                    {workTask.estimate ? `${workTask.totalHours}/${workTask.estimate}` : workTask.totalHours}
                  </span>
                </span>
              </GridCell>

              <GridCell
                style={{ textAlign: 'right' }}
                desktop={6}
                tablet={4}
                phone={2}
              >
                <span
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                    opacity: 0.6,
                  }}
                >
                  <AttachFileIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                  <span className="invitations-neutral" style={{ paddingRight: 0 }}>
                    {workTask.files.length === 0 ? '-' : workTask.files.length}
                  </span>
                </span>
              </GridCell>
            </GridInner>
          </GridCell>
        </Grid>
      </Card>
    );
  }
}

export default WorkTaskCard;
