import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@material-ui/core';
import { Elevation } from '@rmwc/elevation';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';

import AddIcon from '@material-ui/icons/Add';

import BillingAttachmentCard from './BillingAttachmentCard';
import AttachmentDialog from '../shared/AttachmentDialog';
import FilePreviewDialog from '../shared/FilePreviewDialog';
import DeepViewHeader from '../shared/DeepViewHeader';
import LoadingSpinner from '../shared/LoadingSpinner';

@inject('employerWorkOrderStore', 'uiStore', 't')
@observer
class BillingAttachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      showAttachmentDialog: false,
      description: '',
      // fileURL: '',
      // filename: '',
      files: [],
      file: null,
      // id: null,
      name: '',
      // status: null,
      // errors: {},
      // inputChanged: false,
      showDeleteDialog: false,
      deleteInfo: {},
      generatingPdf: false,
      showFilePreviewDialog: false,
      filePreviewURL: null,
      billingAttachments: null,
      salaryPeriodAttachments: null,
    };

    this.handleAttachmentAction = this.handleAttachmentAction.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount() {
    const {
      employerWorkOrderStore: {
        billingWorkOrderCache,
      },
      uiStore,
    } = this.props;

    const foundWo = billingWorkOrderCache?.workOrders.find((wo) => (
      uiStore.currentView.id === wo.id
    ));

    this.setState({
      workOrder: { ...foundWo },
      workOrderId: foundWo.id,
    }, () => {
      this.queryAllAttachments();
    });
  }

  deleteAttachmentFromState = (id) => {
    const { billingAttachments } = this.state;
    const { employerWorkOrderStore: { billingWorkOrderCache }, uiStore } = this.props;

    const updatedBillingAttachments = billingAttachments.filter((attachment) => attachment.id !== id);

    console.log('Delete attachment');

    this.setState({
      billingAttachments: updatedBillingAttachments,
    }, () => {
      const foundWo = billingWorkOrderCache?.workOrders.find((wo) => (uiStore.currentView.id === wo.id));
      foundWo.billingAttachmentsCount -= 1;
    });
  }

  deleteFileFromState = (id) => {
    const { billingAttachments } = this.state;

    const attachmentIndex = billingAttachments.findIndex((attachment) => attachment.id === id);
    const updatedBillingAttachments = [...billingAttachments];
    updatedBillingAttachments[attachmentIndex] = { ...updatedBillingAttachments[attachmentIndex], fileURL: null, filename: null };

    this.setState({
      billingAttachments: updatedBillingAttachments,
    });
  }

  deleteFileFromStateWithSignedId = (id, signedId) => {
    const { billingAttachments } = this.state;

    const attachmentIndex = billingAttachments.findIndex((attachment) => attachment.id === id);
    const foundAttachment = billingAttachments[attachmentIndex];
    // const fileIndex = attachment.files.findIndex((file) => file.signedId === signedId);
    // const updatedFiles = [...attachment.files];
    // updatedFiles.splice(fileIndex, 1);
    const updatedFiles = [...foundAttachment.files].filter((file) => file.signedId !== signedId);
    const updatedBillingAttachments = [...billingAttachments];
    updatedBillingAttachments[attachmentIndex] = {
      ...updatedBillingAttachments[attachmentIndex], fileURL: null, filename: null, files: updatedFiles,
    };

    this.setState({
      billingAttachments: updatedBillingAttachments,
      // filename: null,
      // fileURL: null,
      files: updatedFiles,
    });
  }

  // Multiple files
  deleteFileFromStateWithSignedIds = (id, signedIds) => {
    const { billingAttachments } = this.state;

    const attachmentIndex = billingAttachments.findIndex((attachment) => attachment.id === id);
    const foundAttachment = billingAttachments[attachmentIndex];
    const updatedFiles = [...foundAttachment.files].filter((file) => !signedIds.includes(file.signedId));
    const updatedBillingAttachments = [...billingAttachments];
    updatedBillingAttachments[attachmentIndex] = {
      ...updatedBillingAttachments[attachmentIndex], fileURL: null, filename: null, files: updatedFiles,
    };

    this.setState({
      billingAttachments: updatedBillingAttachments,
      files: updatedFiles,
    });

    return updatedFiles;
  }

  confirmDelete = (target, id, workOrderId, name, signedId) => {
    let deleteMessage;

    if (target === 'file') {
      deleteMessage = `Haluatko varmasti poistaa tiedoston ${name}`;
    } else if (target === 'attachment') {
      deleteMessage = `Haluatko varmasti poistaa liitteen ${name}`;
    }

    this.setState({
      showDeleteDialog: true,
      deleteInfo: {
        target,
        id,
        workOrderId,
        deleteMessage,
        signedId,
      },
    });
  }

  updateAttachmentState = (newAttachment, callback = null) => {
    const { employerWorkOrderStore: { billingWorkOrderCache }, uiStore } = this.props;
    const { billingAttachments } = this.state;
    const foundIndex = billingAttachments.findIndex((attachment) => attachment.id === newAttachment.id);
    const updatedBillingAttachments = [...billingAttachments];

    if (foundIndex !== -1) {
      // Updating an old attachment
      updatedBillingAttachments[foundIndex] = newAttachment;

      this.setState({
        billingAttachments: updatedBillingAttachments,
        ...newAttachment,
      }, () => {
        if (callback) {
          callback();
        }
      });
    } else {
      // Creating a new attachment
      updatedBillingAttachments.push(newAttachment);

      this.setState({
        billingAttachments: updatedBillingAttachments,
        ...newAttachment,
        attachmentId: newAttachment.id,
      }, () => {
        const foundWo = billingWorkOrderCache?.workOrders.find((wo) => (uiStore.currentView.id === wo.id));
        foundWo.billingAttachmentsCount += 1;

        if (callback) {
          callback();
        }
      });
    }
  }

  handleCardClick = (attachment, attachmentType) => {
    this.setState({
      ...attachment,
      attachmentType: attachmentType, // eslint-disable-line
      showAttachmentDialog: true,
      attachmentId: attachment.id,
    });
  }

  handleGeneratePDF = (id, checkedFiles, attachment, callback = null) => {
    const { workOrderId } = this.state;
    const { employerWorkOrderStore } = this.props;
    this.setState({
      generatingPdf: true,
    });
    const generatePromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.generatePDF(id, checkedFiles, workOrderId, resolve, reject)));

    when(
      () => generatePromise.state !== 'pending',
      () => {
        generatePromise.case({
          pending: () => {
          },
          rejected: () => {
            this.setState({
              generatingPdf: false,
            }, () => {
              if (callback) {
                callback(checkedFiles);
              }
            });
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (response) => {
            const updatedFiles = this.deleteFileFromStateWithSignedIds(id, response.deletedFilesSignedIds);
            const attachmentWithPdf = { ...attachment, files: [...updatedFiles, response.pdf] };
            const updatePromise = new Promise((resolve) => employerWorkOrderStore.updateBillingAttachmentProp(attachmentWithPdf, resolve));

            updatePromise.then((updatedAttachment) => {
              // This is stupid, two callbacks but called at different times
              this.updateAttachmentState(updatedAttachment, this.generatePdfComplete);
              if (callback) {
                callback(checkedFiles);
              }
              // this.deleteFileFromStateWithSignedIds(id, response.deletedFilesSignedIds);
            });
          },
        });
      },
    );
  }

  generatePdfComplete = () => {
    this.setState({
      generatingPdf: false,
    });
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      filePreviewURL: null,
    });
  }

  openFilePreviewDialog = (file) => {
    this.setState({
      showFilePreviewDialog: true,
      filePreviewURL: file.fileURL,
      filePreviewName: file.filename,
    });
  }

  queryAllAttachments() {
    const { employerWorkOrderStore, employerWorkOrderStore: { billingWorkOrderCache }, uiStore } = this.props;
    const { workOrderId } = this.state;

    const attachmentsPromise = new Promise((resolve) => employerWorkOrderStore.getAllWorkOrderAttachments(workOrderId, resolve));
    attachmentsPromise.then((res) => {
      // Update the counts to make sure they're up-to-date (e.g. other users maybe have added new attachments, so the billing index's count numbers are inaccurate)
      const foundWo = billingWorkOrderCache?.workOrders.find((wo) => (uiStore.currentView.id === wo.id));
      if (foundWo) {
        foundWo.billingAttachmentsCount = res.billingAttachments.length;
        foundWo.salaryPeriodAttachmentsCount = res.salaryPeriodAttachments.length;
      }

      this.setState({
        billingAttachments: res.billingAttachments,
        salaryPeriodAttachments: res.salaryPeriodAttachments,
      });
    }).catch((err) => {
      console.error('ERROR: ', err);
    });
  }

  handleClose() {
    this.setState({
      showAttachmentDialog: false,
    });
  }

  handleDeletion(deleteInfo) {
    const { employerWorkOrderStore } = this.props;

    if (deleteInfo.target === 'file') {
      // has_one_attached
      // employerWorkOrderStore.deleteBillingAttachmentFile(deleteInfo.id, deleteInfo.workOrderId, deleteInfo.signedId)
      //   .then(this.deleteFileFromState(deleteInfo.id));

      // has_many_attached
      employerWorkOrderStore.deleteBillingAttachmentFile(deleteInfo.id, deleteInfo.workOrderId, deleteInfo.signedId)
        .then(this.deleteFileFromStateWithSignedId(deleteInfo.id, deleteInfo.signedId));
    } else if (deleteInfo.target === 'attachment') {
      employerWorkOrderStore.deleteBillingAttachment(deleteInfo.id, deleteInfo.workOrderId)
        .then(this.deleteAttachmentFromState(deleteInfo.id));
    }
  }

  handleAttachmentAction(attachment) {
    const { employerWorkOrderStore } = this.props;

    if (attachment.id == null || attachment.id === '') {
      const createPromise = new Promise((resolve) => employerWorkOrderStore.createBillingAttachment(attachment, resolve));
      createPromise.then((newAttachment) => {
        this.updateAttachmentState(newAttachment);
      });
      // employerWorkOrderStore.createWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    } else {
      const updatePromise = new Promise((resolve) => employerWorkOrderStore.updateBillingAttachment(attachment, resolve));
      updatePromise.then((updatedAttachment) => {
        this.updateAttachmentState(updatedAttachment);

        // this.setState({
        //   ...updatedAttachment,
        // });
      });
      // employerWorkOrderStore.updateWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    }
  }

  renderDeleteAttachmentDialog() {
    const { showDeleteDialog, deleteInfo } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.handleDeletion(deleteInfo);
          }
          this.setState({
            showDeleteDialog: false,
            deleteInfo: {},
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{deleteInfo.deleteMessage}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
            }}
            action="accept"
          >
            Kyllä
          </DialogButton>
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderFilePreviewDialog() {
    const { showFilePreviewDialog, filePreviewURL, filePreviewName } = this.state;

    return (
      <FilePreviewDialog open={showFilePreviewDialog} fileURL={filePreviewURL} fileName={filePreviewName} onClose={this.closeFilePreviewDialog} />
    );
  }

  renderTabOneSection() {
    const { billingAttachments, salaryPeriodAttachments } = this.state;

    return (
      <GridCell
        span={12}
        style={{
          padding: '0px 16px',
        }}
      >
        <GridInner
          style={{
            rowGap: '4px',
          }}
        >
          {!billingAttachments?.length && !salaryPeriodAttachments?.length && (
            <GridCell
              span={12}
              style={{ textAlign: 'center', fontSize: '20px', padding: '10px' }}
            >
              <p>Keikalla ei ole liitteitä</p>
            </GridCell>
          )}

          {/* Billing attachments (from employers) */}
          {billingAttachments && billingAttachments.length > 0
            && billingAttachments.map((attachment) => (
              <GridCell
                span={6}
                key={attachment.id}
              >
                <BillingAttachmentCard
                  attachment={attachment}
                  attachmentType="billing"
                  handleCardClick={this.handleCardClick}
                  confirmDelete={this.confirmDelete}
                  processing={false}
                  showFilePreviewDialog={this.openFilePreviewDialog}
                />
              </GridCell>
            ))}

          {/* Salary period attachments from (employees) */}
          {salaryPeriodAttachments && salaryPeriodAttachments.length > 0
            && salaryPeriodAttachments.map((attachment) => (
              <GridCell
                span={6}
                key={attachment.id}
              >
                <BillingAttachmentCard
                  attachment={attachment}
                  attachmentType="salaryPeriod"
                  handleCardClick={this.handleCardClick}
                  confirmDelete={this.confirmDelete}
                  processing={false}
                  showFilePreviewDialog={this.openFilePreviewDialog}
                />
              </GridCell>
            ))}

        </GridInner>
      </GridCell>
    );
  }

  renderTabTwoSection() {
    const { billingAttachments } = this.state;

    const draftAttachments = billingAttachments.filter((attachment) => attachment.status !== 'accepted');

    return (
      <GridCell
        span={12}
        style={{
          padding: '0px 16px',
        }}
      >
        <GridInner
          style={{
            rowGap: '4px',
          }}
        >
          {draftAttachments.length > 0
            ? draftAttachments.map((attachment) => (

              <GridCell
                span={6}
                key={attachment.id}
              >
                <BillingAttachmentCard
                  attachment={attachment}
                  attachmentType="billing"
                  handleCardClick={this.handleCardClick}
                  confirmDelete={this.confirmDelete}
                  processing={true} // eslint-disable-line
                  updateBillingAttachmentState={this.updateAttachmentState}
                />
              </GridCell>

            ))
            : (
              <GridCell
                span={12}
                style={{ textAlign: 'center', fontSize: '20px', padding: '10px' }}
              >
                <p>Ei käsiteltäviä liitteitä</p>
              </GridCell>
            )}
        </GridInner>
      </GridCell>
    );
  }

  render() {
    // "file" (compare "files") is here for compatibility reasons (back when you could only have 1 file per attachment), remove at some point
    const {
      activeTab,
      workOrder,
      workOrderId,
      showAttachmentDialog,
      attachmentId,
      name,
      description,
      files,
      file,
      generatingPdf,
      attachmentType,
      billingAttachments,
      salaryPeriodAttachments,
    } = this.state;
    const { uiStore } = this.props;

    const attachmentsLoaded = billingAttachments && salaryPeriodAttachments;

    return (
      <div
        className="work-order__list"
      >
        <DeepViewHeader
          content={workOrder?.name}
          showPreviousView={uiStore.showEmployerBilling}
        />

        {/* Note: this HTML structure is significantly different from the "main" navigation tabs in other views */}
        <div style={{ backgroundColor: '#0D121C' }}>
          <TabBar
            style={{ maxWidth: '500px' }}
            activeTabIndex={activeTab}
            onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
          >
            <Tab>Laskutuksen liitteet</Tab>
            <Tab>Käsittele</Tab>
          </TabBar>
        </div>

        <Grid
          style={{
            padding: '0px 0px',
          }}
        >
          {!attachmentsLoaded && (
            <GridCell span={12}>
              <div style={{ padding: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            </GridCell>
          )}

          {attachmentsLoaded && activeTab === 0 && this.renderTabOneSection()}
          {attachmentsLoaded && activeTab === 1 && this.renderTabTwoSection()}

          <GridCell
            span={12}
            className="employer-work-order-sticky-bottom"
          >
            <Elevation
              z={5}
              style={{
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <Button
                startIcon={<AddIcon />}
                className="employer-accept-button"
                style={{
                  margin: '10px',
                  borderRadius: '0px',
                  width: 'fit-content',
                }}
                onClick={() => this.setState({
                  showAttachmentDialog: true, name: '', description: '', files: [], file: null, attachmentId: null,
                })}
              >
                Lisää Liite
              </Button>
              <Button
                type="button"
                className="employer-reject-button"
                style={{
                  margin: '10px',
                  borderRadius: '0px',
                }}
                onClick={() => uiStore.showEmployerBilling(uiStore.currentView.selectedFilter)}
              >
                Takaisin
              </Button>
            </Elevation>
          </GridCell>
        </Grid>
        {/* {workOrderId && this.renderAttachmentDialog(workOrderId)} */}
        {/* TODO: File vs. Files here is a backwards compatibility thing, REMOVE LATER */}
        <AttachmentDialog
          open={showAttachmentDialog}
          styles={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' }}
          id={attachmentId}
          attachmentType={attachmentType}
          workOrderId={workOrderId}
          handleAttachmentAction={this.handleAttachmentAction}
          handleGeneratePDF={this.handleGeneratePDF}
          handleClose={this.handleClose}
          confirmDelete={this.confirmDelete}
          name={name}
          description={description}
          files={files}
          file={file}
          generatingPdf={generatingPdf}
          openFilePreviewDialog={this.openFilePreviewDialog}
        />
        {this.renderDeleteAttachmentDialog()}
        {this.renderFilePreviewDialog()}
      </div>
    );
  }
}

export default BillingAttachments;
