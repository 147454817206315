import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';
import {
  Snackbar,
} from '@rmwc/snackbar';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/ripple/dist/mdc.ripple.css';

import '@material/snackbar/dist/mdc.snackbar.css';

@inject('errorStore')
@observer
class Error extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true,
      openSnackbar: true,
    };
  }

  renderConfirmDialog = () => {
    const { errorStore } = this.props;
    const { openDialog } = this.state;

    return (
      <Dialog
        open={openDialog}
        onClose={() => {
          this.setState({ openDialog: false });
        }}
        onClosed={() => {
          errorStore.clearError();
        }}
      >
        <DialogContent style={{ color: 'var(--mdc-theme-primary)' }}>
          {errorStore.errorMessage}
        </DialogContent>
        <DialogActions>
          <DialogButton
            action="close"
            style={{
              color: 'var(--mdc-theme-primary)',
              border: '1px solid var(--mdc-theme-primary)',
            }}
          >
            Ok
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderSnackbar = () => {
    const { errorStore } = this.props;
    const { openSnackbar } = this.state;

    return (
      <Snackbar
        open={openSnackbar}
        onClose={() => {
          errorStore.clearError();
          this.setState({ openSnackbar: false });
        }}
        message={errorStore.errorMessage}
        timeout={3000}
      />
    );
  }

  renderError = () => {
    const { errorStore: { errorPriority } } = this.props;

    let component = null;

    if (errorPriority === 1) {
      component = this.renderConfirmDialog();
    } else if (errorPriority === 2) {
      component = this.renderSnackbar();
    }

    return component;
  }

  render() {
    return (
      this.renderError()
    );
  }
}

export default Error;
