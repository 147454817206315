/* eslint-disable react/destructuring-assignment */
/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import actionCable from 'actioncable';
import {
  CollapsibleList,
  SimpleListItem,
} from '@rmwc/list';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Card,
  CardPrimaryAction,
  CardActionButton,
  CardActions,
  CardActionButtons,
} from '@rmwc/card';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import { Typography } from '@rmwc/typography';
import { Switch } from '@rmwc/switch';
import { ThemeProvider } from '@rmwc/theme';
import { Icon } from '@rmwc/icon';
import { IconButton as RMWCIconButton } from '@rmwc/icon-button';
import { TextField } from '@rmwc/textfield';
import { isEqual } from 'lodash';

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import '@material/list/dist/mdc.list.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/switch/dist/mdc.switch.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import '@rmwc/list/collapsible-list.css';

// import TimelogRow from './timelog-row';
// import WorkOrderItem from '../calendar/WorkOrderItem.jsx';
import { buttonColors, buttonStyle } from '../calendar/EmployeeCalendar.js';
import moment, { combinedDateTime, longDateRange } from '../utils/moment.js';
import TimelogViewStore from '../timelog/TimelogViewStore';
// import iconDailyAllowance from '../assets/images/daily_allowance.svg';
// import iconDailyAllowanceActive from '../assets/images/daily_allowance_active.svg';
// import iconExtras from '../assets/images/extra_allowance.svg';
// import iconExtrasActive from '../assets/images/extra_allowance_active.svg';
// import Attachments from './TimelogAttachments';
// import TimelogRoute from './timelog-route';
// import TimelogTripDialog from './timelog-trip-dialog.js'
import AbsencePeriodView from './AbsencePeriodView.jsx';
import AbsencePeriodDialog from './absence-period-dialog.js';
import AbsencePeriod from '../models/AbsencePeriod';

const columnWidths = { työaika: '30%', päiväraha: '66%', tuotteet: '100%' };
const defaultHeaderStyle = { width: '16.5%', color: 'var(--mdc-theme-primary)' };

// const totalByIntAttr = (acc, value) => Math.round((acc + (Number.parseInt(value, 10) || 0)) * 100) / 100;
// const totalByAttr = (acc, value) => Math.round((acc + (Number.parseFloat(value) || 0)) * 100) / 100;
// const totalByBoolAttr = (acc, value) => acc + (value ? 1 : 0);

// const getSummary = (workHourType, workHours, accountId) => {
//   // const { uiStore: { currentUser } } = this.props;
//   const summary = {
//     driveTime: 0.00,
//     emergencyWork: 0.00,
//     eveningShift: 0.00,
//     hasUnacceptedHours: false,
//     kms: 0,
//     kmsPerson: 0,
//     kmsTrailer: 0,
//     kmsService: 0,
//     kmsHeavyLoad: 0,
//     nightShift: 0.00,
//     sundayWork: 0.00,
//     time100: 0.00,
//     time50: 0.00,
//     timeNormal: 0.00,
//     travelTime: 0.00,
//     weeklyRest: 0.00,
//     conditionCompensation: 0.00,
//     miningCompensation: 0.00,
//     midweekHolidayCompensation: 0.00,
//   };

//   if (workHourType === 'hourly') {
//     // In this case workHours contains an array of objects with keys "date", "hours" and "dailyData"
//     // One per date, because workers input multiple work hours per day (by work number)
//     workHours.forEach((workHour) => {
//       workHour.workTaskEntries.forEach((item) => {
//         summary.timeNormal = totalByAttr(summary.timeNormal, item.timeNormal);
//         summary.time50 = totalByAttr(summary.time50, item.time50);
//         summary.time100 = totalByAttr(summary.time100, item.time100);
//       });
//       // if (workHoursPerDay.dailyData) {
//       summary.travelTime = totalByAttr(summary.travelTime, workHour.travelTime);
//       // TODO: Hardcoded accounts 1, 10 and 3 (10 and 3 being test accounts)
//       if (accountId === 1 || accountId === 10 || accountId === 3) {
//         summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime);
//         summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime50);
//         summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime100);
//       } else {
//         summary.driveTime = totalByAttr(summary.driveTime, workHour.driveTime);
//       }
//       summary.dailyAllowance = totalByAttr(summary.dailyAllowance, workHour.dailyAllowance);
//       summary.kms = totalByIntAttr(summary.kms, workHour.kms);
//       summary.kmsPerson = totalByIntAttr(summary.kmsPerson, workHour.kmsPerson);
//       summary.kmsTrailer = totalByIntAttr(summary.kmsTrailer, workHour.kmsTrailer);
//       summary.kmsService = totalByIntAttr(summary.kmsService, workHour.kmsService);
//       summary.kmsHeavyLoad = totalByIntAttr(summary.kmsHeavyLoad, workHour.kmsHeavyLoad);

//       summary.eveningShift = totalByAttr(summary.eveningShift, workHour.eveningShift);
//       summary.nightShift = totalByAttr(summary.nightShift, workHour.nightShift);
//       summary.sundayWork = totalByAttr(summary.sundayWork, workHour.sundayWork);
//       summary.weeklyRest = totalByAttr(summary.weeklyRest, workHour.weeklyRest);
//       summary.emergencyWork = totalByBoolAttr(summary.emergencyWork, workHour.emergencyWork);
//       summary.conditionCompensation = totalByAttr(summary.conditionCompensation, workHour.conditionCompensation);
//       summary.miningCompensation = totalByAttr(summary.miningCompensation, workHour.miningCompensation);
//       summary.midweekHolidayCompensation = totalByAttr(summary.midweekHolidayCompensation, workHour.midweekHolidayCompensation);

//       if (workHour.isDraft()) {
//         console.log('FOUND DRAFT WORK HOUR: ', workHour);
//         summary.hasUnacceptedHours = true;
//       }
//       if (workHour.updatedByEmployer) {
//         summary.hasUpdatedByEmployer = true;
//       }
//       // }
//     });
//   } else {
//     workHours.forEach(
//       (item) => {
//         summary.timeNormal = totalByAttr(summary.timeNormal, item.timeNormal);
//         summary.time50 = totalByAttr(summary.time50, item.time50);
//         summary.time100 = totalByAttr(summary.time100, item.time100);

//         summary.travelTime = totalByAttr(summary.travelTime, item.travelTime);
//         // TODO: Hardcoded accounts 1, 10 and 3 (10 and 3 being test accounts)
//         if (accountId === 1 || accountId === 10 || accountId === 3) {
//           summary.driveTime = totalByAttr(summary.driveTime, item.driveTime);
//           summary.driveTime = totalByAttr(summary.driveTime, item.driveTime50);
//           summary.driveTime = totalByAttr(summary.driveTime, item.driveTime100);
//         } else {
//           summary.driveTime = totalByAttr(summary.driveTime, item.driveTime);
//         }
//         summary.dailyAllowance = totalByAttr(summary.dailyAllowance, item.dailyAllowance);
//         summary.kms = totalByIntAttr(summary.kms, item.kms);
//         summary.kmsPerson = totalByIntAttr(summary.kmsPerson, item.kmsPerson);
//         summary.kmsTrailer = totalByIntAttr(summary.kmsTrailer, item.kmsTrailer);
//         summary.kmsService = totalByIntAttr(summary.kmsService, item.kmsService);
//         summary.kmsHeavyLoad = totalByIntAttr(summary.kmsHeavyLoad, item.kmsHeavyLoad);

//         summary.eveningShift = totalByAttr(summary.eveningShift, item.eveningShift);
//         summary.nightShift = totalByAttr(summary.nightShift, item.nightShift);
//         summary.sundayWork = totalByAttr(summary.sundayWork, item.sundayWork);
//         summary.weeklyRest = totalByAttr(summary.weeklyRest, item.weeklyRest);
//         summary.emergencyWork = totalByBoolAttr(summary.emergencyWork, item.emergencyWork);
//         summary.conditionCompensation = totalByAttr(summary.conditionCompensation, item.conditionCompensation);
//         summary.miningCompensation = totalByAttr(summary.miningCompensation, item.miningCompensation);
//         summary.midweekHolidayCompensation = totalByAttr(summary.midweekHolidayCompensation, item.midweekHolidayCompensation);

//         if (item.isDraft()) {
//           summary.hasUnacceptedHours = true;
//           console.log('ITEM IS DRAFT: ', item);
//         }
//         if (item.updatedByEmployer) {
//           summary.hasUpdatedByEmployer = true;
//         }
//       },
//     );
//   }

//   return summary;
// };

@inject('uiStore', 'absenceStore', 't')
@observer
class Absences extends Component {
  cableApp = {};

  constructor(props) {
    super(props);

    // this.deleteHourlyTimelog = this.deleteHourlyTimelog.bind(this);
    // this.updateWorkOrderTripState = this.updateWorkOrderTripState.bind(this);
    // this.toggleTripDialog = this.toggleTripDialog.bind(this);
    // this.overwriteTripRoutes = this.overwriteTripRoutes.bind(this);

    // TODO: In order to add compatibility with
    // const foundWorkOrderTripForThisWorkOrder = props.workOrder.workOrderTrips.find((woTrip) => woTrip.startTrip[0]?.uiWorkOrderId === props.workOrder.id || woTrip?.endTrip[0]?.uiWorkOrderId === props.workOrder.id);
    // console.log('FOUND WORK ORDER TRIP: ', foundWorkOrderTripForThisWorkOrder);

    //const foundOnGoingTrip = props.workOrder.workOrderTrips.find(woTrip => woTrip.endTrip.length == 0 || woTrip.endTrip[0]?.toTime == null);
    //const foundEndedTrips = props.workOrder.workOrderTrips.filter(woTrip => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
    // const foundOnGoingTrip = props.workOrderTrips.find(woTrip => woTrip.endTrip.length > 0 || woTrip.endTrip?.[0]?.toTime == null || woTrip.endTrip?.[0]?.toDate == null);
    // const foundOnGoingTrip = props.workOrderTrips.find(woTrip => !woTrip.endTrip || (woTrip.endTrip && woTrip.endTrip?.toTime == null || woTrip.endTrip?.toDate == null));
    // const foundEndedTrips = props.workOrderTrips.filter(woTrip => woTrip.endTrip && woTrip.endTrip?.toTime != null);

    this.state = {
      // activeTabById: {},
      // dialogOpen: false,
      // workOrderTrip: foundWorkOrderTripForThisWorkOrder || [],
      // routeDialogOpen: false,
      // tripDialogOpen: false,
      // cloneTripDialogOpen: false,
      // routeItem: '',
      // tripItem: '',
      // currentKmsType: null,
      // onGoingTrip: foundOnGoingTrip || null,
      // endedTrips: foundEndedTrips || [],
      // maxEndDate: null,
      // tripDisabledDialogOpen: false,
      // tripDisabledDialogOverlappingTrips: [],
      absencePeriodDialogOpen: false,
    };
    this.store = new TimelogViewStore();

    // if (props.initialTabIndex) {
    //   this.state.activeTabById[props.workOrder.id] = props.initialTabIndex;
    // }
    // const activeTab = initialTabIndex ? initialTabIndex : (activeTabById[workOrder.id] || 0);
  }

  getHeader = (columns, tab = null) => columns.map((text) => this.getHeaderTitle(
    text,
    text,
    {
      borderBottomWidth: '1px',
      borderTopWidth: 0,
      fontSize: '10px',
      lineHeight: '1rem',
      width: columnWidths[text] ? columnWidths[text] : '16.5%',
      // width: '17.5%',
    },
    tab,
  ));

  getFirstColumn = (text, ctx, tab) => {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    return (
      <div
        className={`pk-multiline-header sticky-left ${((tab === 1 && (accountId === 43 || accountId === 1)) || tab === 3) ? "mobile-column" : ""}`}
        style={{
          // color: 'var(--mdc-theme-primary)',
          fontSize: '10px',
          lineHeight: '1rem',
          padding: 0,
          textAlign: 'center',
          textTransform: 'uppercase',
          ...ctx,
        }}
      >
        {text}
      </div>
    );
  };

  // componentDidUpdate(prevProps) {
  //   console.log('IS EQUAL??', isEqual(prevProps.workOrder.workHours, this.props.WorkOrder.workHours));
  // }

  getHeaderTitle = (text, name = text, styles = {}, tab = null) => {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    return (
      <div
        className={
          `pk-multiline-header
          ${((tab === 1 && (accountId === 43 || accountId === 1)) || tab === 3) ? 'mobile-column' : ''}
          ${text === 'päiväraha' ? 'allowance-column' : ''}
          ${text === 'työmatka' ? 'work-order-trip-column' : ''}`
        }
        key={`tbheader-${name}`}
        style={{
          borderBottomWidth: 0,
          // color: 'var(--mdc-theme-primary)',
          fontSize: '1rem',
          lineHeight: '2rem',
          padding: accountId === 1 ? '0 1px' : 0,
          textAlign: 'center',
          textTransform: 'uppercase',
          ...styles,
        }}
      >
        {text}
      </div>
    );
  };

  // updateWorkOrderTripState(newWorkOrderTrip) {
  //   const { workOrder, timelogStore } = this.props;
  //   this.setState({
  //     workOrderTrip: newWorkOrderTrip,
  //   });
  //   timelogStore.updateWorkOrderTripProp(newWorkOrderTrip, workOrder.id);
  // }

  // toggleRouteDialog = (item = null, kmsType, workOrder) => {
  //   const { openDialogCallback } = this.props;
  //   const { routeItem, routeDialogOpen } = this.state;

  //   if (routeItem && routeDialogOpen) {
  //     this.setState({
  //       routeItem: '',
  //       routeDialogOpen: false,
  //       currentKmsType: null,
  //       currentWorkOrder: null,
  //     })
  //   }
  //   else {
  //     this.setState({
  //       routeItem: item,
  //       routeDialogOpen: true,
  //       currentKmsType: kmsType,
  //       currentWorkOrder: workOrder,
  //     })
  //   }

  //   if (openDialogCallback) {
  //     openDialogCallback();
  //   }
  // }

  // eslint-disable-next-line class-methods-use-this
  getHeader2(columns) {
    let width = '17.5%';
    columns.map((text) => {
      if (text === 'työaika') {
        width = '30%';
      } else if (text === 'päiväraha') {
        width = '82.5%';
      }
      return this.getHeaderTitle(
        text,
        text,
        {
          borderBottomWidth: '1px',
          borderTopWidth: 0,
          fontSize: '10px',
          lineHeight: '1rem',
          // width: text === 'työaika' ? '30%' : '17.5%',
          // width: '17.5%',
          width,
        },
      );
    });
  }

  // TODO: Make the data table actually dynamic
  conditionOrSundayWorkHeader = (summary, width, color, tab) => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 7 || accountId === 10) {
      return this.getHeaderTitle(summary.conditionCompensation, 'total3-5', { width, color }, tab);
    }
    // return this.getHeaderTitle(summary.emergencyWork, 'total3-5', { width, color });
    // return this.getHeaderTitle(summary.sundayWork, 'total3-1', defaultHeaderStyle)
    return this.getHeaderTitle(summary.sundayWork, 'total3-1', { width: width, color: 'var(--mdc-theme-primary)' }, tab)
  };

  conditionOrEmergencyHeader = (summary, width, color, tab) => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2) {
      return this.getHeaderTitle(summary.conditionCompensation, 'total3-5', { width, color }, tab);
    }
    return this.getHeaderTitle(summary.emergencyWork, 'total3-5', { width, color }, tab);
  };

  // TODO: Make the data table actually dynamic
  kmsTrailerOrServiceHeader = (summary, width, color, tab = null) => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2 || accountId === 42 || accountId === 6) {
      return this.getHeaderTitle(summary.kmsService, 'total2-6', { width, color }, tab);
    }
    return this.getHeaderTitle(summary.kmsTrailer, 'total2-6', { width, color }, tab);
  };

  // TODO: Hardcoded to a specific customer for now, but in the future should be configured per work order / account
  getTabOneHeaders = () => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 1) {
      return ['matka\u00ADtunnit', 'kulj.\ntunnit', 'km oma\nauto', 'km +\n1 hlö', 'km huolto\u00ADauto'];
    }
    if (accountId === 2) {
      return ['työajan\nulkop.', 'työ\u00ADajalla', 'km oma\nauto', 'km +\n1 hlö', 'km huolto\u00ADauto'];
    }
    // if (accountId === 6) {
    //   return ['matka h'];
    // }
    // Hardcoded to accounts 7, 10 and 3 (indirectly related to work hour trips/kms routes)
    if (accountId === 7 || accountId === 3) {
      return ['matka h', 'km oma\nauto', 'km huolto\u00ADauto'];
    }
    if (accountId === 10) {
      return ['matka h', 'km oma\nauto', 'kulj.\ntunnit'];
    }
    if (accountId === 41) {
      return ['matka h', 'km oma\nauto', 'km +\n1 hlö', 'km +\nperäkär.'];
    }
    if (accountId === 49) {
      return ['matka h', 'km oma\nauto', 'km +\n1 hlö', 'km +\nperäkär.', 'km huolto\u00ADauto'];
    }
    if (accountId === 42 || accountId === 6) {
      return ['matka\u00ADtunnit', 'km oma\nauto', 'km +\n1 hlö', 'km huolto\u00ADauto'];
    }
    if (accountId === 43) {
      return ['työajan\nulkop.', 'työ\u00ADajalla', 'km oma\nauto', 'km +\n1 hlö', 'km +\nperäkär.', 'km iso\nkuorma'];
    }
    return ['matka\u00ADtunnit', 'km huolto\u00ADauto', 'km oma\nauto', 'km +\n1 hlö'];
  };

  getTabTwoHeaders = () => {
    const { uiStore: { currentUser: { accountInfo: { workOrderTripsEnabled } } } } = this.props;

    if (workOrderTripsEnabled) {
      return ['työmatka', 'päiväraha'];
    }
    return ['päiväraha'];
  }

  // TODO: Hardcoded to a specific customer for now, but in the future should be configured per work order / account
  getTabThreeHeaders = () => {
    const { props: { uiStore: { currentUser: { accountId } } } } = this;
    if (accountId === 2) {
      return ['su\nkorvaus', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'olosuhde\u00ADlisä', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 7 || accountId === 10) {
      return ['su\nkorvaus', 'olosuhde\u00ADlisä', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADkorvaus', 'kaivos\u00ADlisä', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 43) {
      return ['su\nkorvaus', 'vkolepo\u00ADkorvaus', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
    }
    if (accountId === 49) {
      return ['su\nkorvaus', 'olosuhde\u00ADlisä', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
    }
    return ['su\nkorvaus', 'vkolepo\u00ADkorvaus', 'ilta\u00ADlisä', 'yö\u00ADlisä', 'hälytys\u00ADraha', 'arkipyhä\u00ADkorv.'];
  };

  deleteHourlyTimelog(timelog, hourlyTimelogs) {
    const { timelogStore } = this.props;
    // needs the hourly-timelog prop + the workOrder to be deleted
    timelogStore.deleteHourlyTimelogEntry(timelog, hourlyTimelogs);
  }

  // renderAcceptance(workOrder) {
  //   const {
  //     props: {
  //       t, timelogStore, uiStore, to, from,
  //     }, store,
  //   } = this;
  //   const buttonStyles = { ...buttonStyle, ...buttonColors.yellow };

  //   let buttons = (
  //     <CardActionButtons
  //       style={{
  //         flexDirection: 'column',
  //       }}
  //     >
  //       <CardActionButton
  //         {...{
  //           onClick: () => {
  //             timelogStore.accept(workOrder, uiStore.currentUser, from, to);
  //             store.setShowDraftsOnly(false);
  //           },
  //           outlined: true,
  //           style: buttonStyles,
  //         }}
  //       >
  //         {t('timelog.acceptance.accept')}
  //       </CardActionButton>
  //     </CardActionButtons>
  //   );

  //   return (
  //     <Card>
  //       <Typography {...{
  //         use: 'body1',
  //         tag: 'p',
  //         style: {
  //           margin: '0 1rem 0 1rem',
  //           padding: '0 0 0 1rem',
  //         },
  //       }}
  //       >
  //         {t('timelog.acceptance.title')}
  //       </Typography>
  //       <CardPrimaryAction
  //         style={{
  //           flexDirection: 'row',
  //           justifyContent: 'space-between',
  //           margin: '0 1rem 0 1.5rem',
  //         }}
  //       >
  //         <CardActions
  //           style={{
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //           }}
  //         >
  //           <ThemeProvider
  //             options={{
  //               primaryOnBackground: 'white',
  //               secondary: buttonColors.yellow.color,
  //               textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
  //             }}
  //           >
  //             <Switch
  //               className="pk-switch"
  //               checked={store.showDraftsOnly}
  //               onChange={(evt) => store.setShowDraftsOnly(evt.currentTarget.checked)}
  //               label={t('timelog.acceptance.showDraftsOnly')}
  //               use="text-hint-on-dark"
  //             />
  //           </ThemeProvider>

  //         </CardActions>
  //         <CardActions>{buttons}</CardActions>
  //       </CardPrimaryAction>
  //     </Card>
  //   );
  // }

  // renderAcceptanceEmployer() {
  //   const {
  //     t,
  //     timelogStore,
  //     uiStore,
  //     to,
  //     from,
  //     workOrder,
  //     resetUpdatedByEmployer,
  //     employerContextTripId,
  //     relayUpdatedWorkHours,
  //   } = this.props;
  //   const { store, store: { showEmployerUpdatedOnly } } = this;
  //   const buttonStyles = { ...buttonStyle, ...buttonColors.yellow };

  //   return (
  //     <Card>
  //       <CardPrimaryAction
  //         style={{
  //           flexDirection: 'row',
  //           justifyContent: 'space-between',
  //           margin: '0 1rem 0 1.5rem',
  //         }}
  //       >
  //         <CardActions
  //           style={{
  //             flexDirection: 'column',
  //             justifyContent: 'center',
  //           }}
  //         >
  //           <ThemeProvider
  //             options={{
  //               primaryOnBackground: 'white',
  //               secondary: buttonColors.yellow.color,
  //               textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
  //             }}
  //           >
  //             <Switch
  //               className="pk-switch"
  //               checked={showEmployerUpdatedOnly}
  //               onChange={(evt) => store.setShowEmployerUpdatedOnly(evt.currentTarget.checked)}
  //               label={t('timelog.acceptance.showDraftsOnly')}
  //               use="text-hint-on-dark"
  //             />
  //           </ThemeProvider>
  //         </CardActions>
  //       </CardPrimaryAction>
  //     </Card>
  //   );
  // }

  // renderCloneTripDialog() {
  //   const { cloneTripDialogOpen, clonableTrip, tripDialogParams } = this.state;
  //   let tripDateTimeString;
  //   if (clonableTrip) {
  //     tripDateTimeString = longDateRange(clonableTrip.startTrip.date, clonableTrip.startTrip.time, clonableTrip.endTrip.toDate, clonableTrip.endTrip.toTime);
  //   }

  //   return (
  //     <Dialog
  //       className="mui-dialog-background"
  //       fullScreen={false}
  //       open={cloneTripDialogOpen}
  //       onClose={() => this.setState({ cloneTripDialogOpen: false })}
  //       aria-labelledby="responsive-dialog-title"
  //     >
  //       {/* <DialogTitle id="responsive-dialog-title">
  //         {"Use Google's location service?"}
  //       </DialogTitle> */}
  //       <DialogContent>
  //         <DialogContentText>
  //           Haluatko kopioida edellisen päivän matkan?
  //         </DialogContentText>
  //         <DialogContentText style={{ color: 'darkgray', fontSize: '14px', textTransform: 'capitalize' }}>
  //           {tripDateTimeString}
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions
  //         style={{ justifyContent: 'space-between' }}
  //       >
  //         <Button
  //           onClick={() => this.setState({
  //             cloneTripDialogOpen: false,
  //           }, () => this.openOrCloseTrip(null, tripDialogParams.defaultTripDate, tripDialogParams.overlappingTrips, tripDialogParams.endedTrips, clonableTrip))}
  //           className="employee-accept-button"
  //         >
  //           Kyllä
  //         </Button>
  //         <Button
  //           onClick={() => this.setState({
  //             cloneTripDialogOpen: false,
  //             clonableTrip: null,
  //           }, () => this.openOrCloseTrip(tripDialogParams.item, tripDialogParams.defaultTripDate, tripDialogParams.overlappingTrips, tripDialogParams.endedTrips))}
  //           className="employee-reject-button"
  //         >
  //           Ei
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // }

  openEditAbsenceDialog = (absencePeriod) => {
    this.setState({
      currentAbsencePeriod: absencePeriod,
      absencePeriodDialogOpen: true,
    });
  }

  renderTopActions() {
    const buttonStyles = { ...buttonStyle, ...buttonColors.yellow };

    let buttons = (
      <CardActionButtons
        style={{
          flexDirection: 'column',
        }}
      >
        <CardActionButton
          {...{
            onClick: () => {
              // timelogStore.accept(workOrder, uiStore.currentUser, from, to);
              // store.setShowDraftsOnly(false);
              // this.setState({
              //   currentAbsencePeriod: new AbsencePeriod({}),
              //   absencePeriodDialogOpen: true,
              // });
              const newAbsencePeriod = new AbsencePeriod({});
              this.openEditAbsenceDialog(newAbsencePeriod);
            },
            // outlined: true,
            // style: buttonStyles,
            icon: 'add',
            className: 'accept-button mui-button-hover-overwrite',
            style: { borderRadius: 0 },
          }}
        >
          Uusi
        </CardActionButton>
      </CardActionButtons>
    );

    return (
      <Card>
        {/* <Typography {...{
          use: 'body1',
          tag: 'p',
          style: {
            margin: '0 1rem 0 1rem',
            padding: '0 0 0 1rem',
          },
        }}
        >
          {t('timelog.acceptance.title')}
        </Typography> */}
        <CardPrimaryAction
          style={{
            flexDirection: 'row',
            // justifyContent: 'space-between',
            justifyContent: 'end',
            margin: '0 1rem 0 1.5rem',
            // borderLeft: '1px solid #FFC900',
            borderRadius: 0,
          }}
        >
          {/* <CardActions
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ThemeProvider
              options={{
                primaryOnBackground: 'white',
                secondary: buttonColors.yellow.color,
                textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
              }}
            >
              <Switch
                className="pk-switch"
                checked={store.showDraftsOnly}
                onChange={(evt) => store.setShowDraftsOnly(evt.currentTarget.checked)}
                label={t('timelog.acceptance.showDraftsOnly')}
                use="text-hint-on-dark"
              />
            </ThemeProvider>
          </CardActions> */}
          <CardActions>{buttons}</CardActions>
        </CardPrimaryAction>
      </Card>
    );
  }

  // renderTripDisabledDialog() {
  //   const { uiStore: { mobileMode, currentUser: { workOrders } }, workOrderTripStore, workOrder: { id : workOrderId, name : workOrderName } } = this.props;
  //   const { tripDisabledDialogOpen, tripDisabledDialogOverlappingTrips, allOverlappingTrips } = this.state;

  //   const trips = tripDisabledDialogOverlappingTrips.map((trip) => ({
  //     id: trip.id,
  //     date: longDateRange(trip.startTrip.date, trip.startTrip.time, trip.endTrip.toDate, trip.endTrip.toTime),
  //     workOrderNames: trip.workOrders.map((wo) => wo.name), // trip.workOrders.map((tripWorkOrder) => workOrders.find((workOrder) => workOrder.id === tripWorkOrderId)?.name),
  //     originalObject: trip,
  //   }));

  //   return (
  //     <Dialog
  //       className="mui-dialog-background"
  //       fullScreen={false}
  //       open={tripDisabledDialogOpen}
  //       // This onClose is triggered when clicking outside the dialog to close it
  //       onClose={() => this.setState({
  //         tripDisabledDialogOpen: false,
  //         tripDisabledDialogOverlappingTrips: [],
  //         allOverlappingTrips: [],
  //       })}
  //       aria-labelledby="responsive-dialog-title"
  //     >
  //       <DialogTitle id="responsive-dialog-title" className="responsive-dialog-title-button-only">
  //         <IconButton
  //           aria-label="close"
  //           onClick={() => this.setState({
  //             tripDisabledDialogOpen: false,
  //             tripDisabledDialogOverlappingTrips: [],
  //             allOverlappingTrips: [],
  //           })}
  //         >
  //           <Icon icon="close" role="button" />
  //         </IconButton>
  //       </DialogTitle>
  //       <DialogContent>
  //         <DialogContentText>
  //           Valitulla päivällä on jo matka toisella keikalla:
  //         </DialogContentText>
  //         {trips.map((trip) => (
  //           <div
  //             key={trip.id} style={{ marginTop: '10px' }}
  //           >
  //             <div>
  //               <div style={{ fontSize: '14px' }}>{trip.date}</div>
  //               {trip.workOrderNames.map((workOrderName) => (
  //                 <div
  //                   key={workOrderName}
  //                   style={{ marginLeft: '20px', fontSize: '12px', color: 'darkgray' }}
  //                 >
  //                   {workOrderName}
  //                 </div>
  //               ))}
  //             </div>
  //             {trips.length > 1 && (
  //               <RMWCIconButton
  //                 icon="edit"
  //                 onClick={() => {
  //                   // const endedTrips = allOverlappingTrips.filter((woTrip) => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
  //                   this.setState({
  //                     tripDisabledDialogOpen: false,
  //                     tripDisabledDialogOverlappingTrips: [],
  //                     allOverlappingTrips: [],
  //                   }, () => {
  //                     this.openOrCloseTrip(trip.originalObject, null, allOverlappingTrips, null, null);
  //                   })
  //                 }}
  //                 style={{ color: '#c7c9cc', paddingTop: '0', paddingBottom: '15px', height: 'fit-content' }}
  //               />
  //             )}
  //             {/* <div>
  //               <IconButton>
  //                 <Icon icon="edit" role="button" style={{ color: 'var(--mdc-theme-primary)' }} />
  //               </IconButton>
  //             </div> */}
  //           </div>
  //         ))}
  //       </DialogContent>
  //       <DialogActions
  //         style={{ justifyContent: trips.length === 1 && 'space-between' }}
  //         className={mobileMode ? 'responsive-dialog-stacked-action-buttons' : ''}
  //       >
  //         {trips.length === 1 && (
  //           <>
  //             <Button
  //               className="employee-accept-button"
  //               onClick={() => {
  //                 // const endedTrips = allOverlappingTrips.filter((woTrip) => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
  //                 this.setState({
  //                   tripDisabledDialogOpen: false,
  //                   tripDisabledDialogOverlappingTrips: [],
  //                   allOverlappingTrips: [],
  //                 }, () => {
  //                   this.openOrCloseTrip(trips[0].originalObject, null, allOverlappingTrips, null, null);
  //                 });
  //               }}
  //             >
  //               Päätä matka
  //             </Button>

  //             <Button
  //               onClick={() => {
  //                 // const endedTrips = allOverlappingTrips.filter((woTrip) => woTrip.endTrip.length > 0 && woTrip.endTrip[0]?.toTime != null);
  //                 this.setState({
  //                   tripDisabledDialogOpen: false,
  //                   tripDisabledDialogOverlappingTrips: [],
  //                   allOverlappingTrips: [],
  //                 }, () => {
  //                   // We're saving the whole trip here, we could also just use trip id + work order id to attach the work order to the trip
  //                   workOrderTripStore.save(trips[0].originalObject, workOrderId, workOrderName);
  //                 });
  //               }}
  //               className="employee-accept-button"
  //             >
  //               Jatka matkaa keikalla
  //             </Button>
  //           </>
  //         )}
  //       </DialogActions>
  //     </Dialog>
  //   );
  // }

  closeAbsencePeriodDialog = () => {
    this.setState({
      absencePeriodDialogOpen: false,
      currentAbsencePeriod: null,
    });
  }

  handleDeleteAbsencePeriod = (absencePeriodId) => {
    const { absenceStore } = this.props;

    absenceStore.deleteAbsencePeriod(absencePeriodId).then(() => {
      this.closeAbsencePeriodDialog();
    }).catch((err) => {
      console.error(err);
    });
  }

  renderAbsencePeriodDialog() {
    const { absencePeriodDialogOpen, currentAbsencePeriod } = this.state;
    return (
      <AbsencePeriodDialog
        open={absencePeriodDialogOpen}
        absencePeriod={currentAbsencePeriod}
        onClose={this.closeAbsencePeriodDialog}
        handleDeleteAbsencePeriod={this.handleDeleteAbsencePeriod}
      />
    );
  }

  render() {
    const { uiStore: { currentUser: { absencePeriods, absenceTypes } }, t } = this.props;

    return (
      <>
        <CollapsibleList
          style={{
            backgroundColor: '#0a131e',
            marginTop: '1rem',
            maxWidth: '100vw',
          }}
          handle={(
            <SimpleListItem
              metaIcon="chevron_right"
              style={{
                backgroundColor: 'var(--mdc-theme-surface, #fff)',
                flexDirection: 'row-reverse',
                height: 'fit-content',
              }}
            >
              {/* <WorkOrderItem {...{
                item: workOrder,
                key: `woi-${workOrder.id}-${workOrder.updatedAt.valueOf()}`,
                skipDescription: true,
                tripRejected: tripRejected,
                workHourRejected: workHourRejected,
                useGap: false,
                timelogViewTab: activeTab,
                view: 'timelogview',
              }}
              /> */}
              <div
                style={{ fontSize: '16px', padding: '20px', paddingLeft: '14px', display: 'flex' }}
              >
                <BeachAccessIcon style={{ color: 'white', height: '24px', width: '24px' }} />
                <span style={{ paddingLeft: '10px', margin: 'auto 0' }}>
                  {/* Lomat ja poissaolot */}
                  {t('view_titles.absences')}
                </span>
              </div>
              {/* {locked && <Icon icon="lock_outline" style={{ color: buttonColors.orange.color }} />} */}
            </SimpleListItem>
          )}
          // open={employerUpdateMode || undefined}
        >
          {this.renderTopActions()}
          {/* {(!employerUpdateMode && disabled !== true && summary.hasUnacceptedHours) && this.renderAcceptance(workOrder)}
          {(employerUpdateMode && summary.hasUpdatedByEmployer) && this.renderAcceptanceEmployer()} */}
          {/* <TabBar
            activeTabIndex={activeTab}
            onActivate={(evt) => {
              // Load the products when changing to products tab
              if (evt.detail.index === 5 && currentUser.accountInfo.productsEnabled) {
                productStore.findProducts();
                productStore.findUserProductCollections(currentUser);
              }
              this.setState({
                activeTabById: {
                  ...activeTabById,
                  [workOrder.id]: evt.detail.index,
                },
              });
            }}
          >
            <Tab icon="access_time" />
            <Tab icon="directions_car" />
            <Tab className="pro-icon" icon={activeTab === 2 ? iconDailyAllowanceActive : iconDailyAllowance} />
            <Tab className="pro-icon" icon={activeTab === 3 ? iconExtrasActive : iconExtras} />
            <Tab icon="description" />
            {accountInfo.productsEnabled && <Tab icon="list_alt" />}
          </TabBar> */}

          <div>
            <div
              style={{ backgroundColor: 'var(--mdc-theme-surface, #fff)', width: '100%' }}
              theme="textPrimaryOnDark"
            >
              {absencePeriods.map((period) => (
                <AbsencePeriodView
                  key={period.id}
                  absencePeriod={period}
                  openEditDialog={this.openEditAbsenceDialog}
                />
              ))}
            </div>
          </div>
        </CollapsibleList>
        {this.renderAbsencePeriodDialog()}
      </>
    );
  }
}

export default Absences;
