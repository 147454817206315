/* eslint-disable no-else-return */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { fromPromise } from 'mobx-utils';
// import { when } from 'mobx';
// import { Select } from '@rmwc/select';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Snackbar } from '@rmwc/snackbar';
import {
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogActions,
  // TextField,
  Button,
} from '@material-ui/core';
import LoadingSpinner from '../shared/LoadingSpinner';
import CertificatesTable from './CertificatesTable';
import CertificateDialog from './CertificateDialog';

@inject('uiStore', 'loginStore', 't', 'certificateStore', 'employerUserStore')
@observer
class Certificates extends Component {
  constructor(props) {
    super(props);

    const { certificateStore, employerUserStore } = this.props;

    this.state = {
      certificateDialogOpen: false,
      openCertificate: null,
      openSnackbar: false,
      saveStatusMessage: '',
    };

    certificateStore.getCertificates();
    certificateStore.getCertificateTypes();
    if (!employerUserStore.users) {
      employerUserStore.getUsers();
    }
  }

  // componentDidMount() {
  //   const { employerUserStore: { users } } = this.props;

  //   users.then((data) => {
  //     console.log('Did mount data: ', data);
  //     this.setState({
  //       data: data,
  //     }, () => {
  //       return true;
  //     });
  //   });
  // }

  closeCertificateDialog = (event) => {
    if (event === 'saveSuccess') {
      this.setState({
        certificateDialogOpen: false,
        openCertificate: null,
        openSnackbar: true,
        saveStatusMessage: 'Tallennus onnistui',
      });
    } else {
      this.setState({
        certificateDialogOpen: false,
        openCertificate: null,
      });
    }
  }

  showSnackbarMessage = (message) => {
    this.setState({
      openSnackbar: true,
      saveStatusMessage: message,
    });
  }

  openCertificateDialog() {
    this.setState({
      certificateDialogOpen: true,
    });
  }

  // sendInvitation() {
  //   const { employerUserStore } = this.props;
  //   const { invitationEmail, invitationSalaryPeriodCategory } = this.state;

  //   // Create a promise using 'fromPromise' to handle the asynchronous invitation process
  //   const invitationPromise = fromPromise(
  //     new Promise((resolve, reject) => employerUserStore.sendInvite(invitationEmail, invitationSalaryPeriodCategory, resolve, reject)),
  //   );

  //   // Use 'when' to observe the promise and perform actions based on its state
  //   when(
  //     () => invitationPromise.state !== 'pending',
  //     () => {
  //       invitationPromise.case({
  //         pending: () => { },
  //         rejected: (err) => {
  //           if (err.response?.body?.message === 'Email taken') {
  //             // Handle the "Email taken" error case
  //             this.setState({
  //               emailHelperText: 'Sähköposti on jo käytössä',
  //               emailError: true,
  //             });
  //           } else {
  //             // console.log(err.message);
  //             // Handle other errors
  //             // TO DO There is a problem here. When we send a new invitation to a user that has not yet registered we get Unprocessable Entity error because we already have an user account but we still need to send the new invitation.
  //             // This and all other errors are currently ignored and we just close the dialog because there has not been a good solution that takes all exceptions in to account.
  //             this.setState({
  //               // emailHelperText: 'Kutsun lähettämisessä tapahtui virhe. Yritä hetken kuluttua uudelleen',
  //               emailHelperText: '',
  //               emailError: false,
  //               showInvitationDialog: false,
  //             });
  //           }
  //         },
  //         fulfilled: () => {
  //           // Handle the fulfillment case (invitation sent successfully)
  //           this.setState({ showInvitationDialog: false });
  //         },
  //       });
  //     },
  //   );

  //   // Check if the email is an existing user
  //   const existingUser = employerUserStore.getUserByEmail(invitationEmail);
  //   if (existingUser) {
  //     this.setState({
  //       existingUser,
  //     });
  //   }
  // }

  // This is copied from Validity since some changes were needed for rendering
  // renderCardValidity(validThrough) {
  //   if (!this.validThrough) {
  //     return null;
  //   }

  //   const currentDateMoment = moment().local(); // eslint-disable-line
  //   // eslint-disable-next-line
  //   const validThroughMoment = Validity.convertDate(validThrough).dateObject; // moment(secondDate, 'MM/YYYY');
  //   // Add 1 to the months because for some reason they're zero indexed (e.g. January is 0 and December is 11)
  //   // const currentMonth = currentDate.month() + 1;
  //   // const secondDateMonth = secondDate.month() + 1;

  //   // Object not returned (invalid date given to conversion), which means the date cannot be calculated
  //   if (!validThroughMoment) {
  //     return null;
  //   }

  //   if (currentDateMoment > validThroughMoment.endOf('month')) {
  //     // Expired (last day of the month)
  //     return (
  //       <div style={{ color: 'red', fontWeight: 'bold' }}>
  //         {validThrough}
  //       </div>
  //     );
  //   } else if (currentDateMoment.add(1, 'M') > validThroughMoment.endOf('month')) {
  //     // Expiring within a month
  //     return (
  //       <div style={{ color: 'var(--mdc-theme-primary)', fontWeight: 'bold' }}>
  //         {validThrough}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
  //         {validThrough}
  //       </div>
  //     );
  //   }
  // }

  renderSnackbar() {
    const { openSnackbar, saveStatusMessage } = this.state;

    return (
      <Snackbar
        open={openSnackbar}
        style={{
          textAlign: 'center',
          top: '0',
        }}
        onClose={() => {
          this.setState({
            openSnackbar: false,
            saveStatusMessage: '',
          });
        }}
        message={saveStatusMessage}
        timeout={2000}
      />
    );
  }

  render() {
    const { t, certificateStore: { certificates, certificateTypes }, employerUserStore: { users } } = this.props;
    const {
      openCertificate,
      certificateDialogOpen,
    } = this.state;

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              <div className="view-heading">
                <div style={{ flexDirection: 'column', justifyContent: 'center' }}>
                  {t('view_titles.certificates')}
                </div>
                {certificates && users && certificateTypes && (
                  <Button
                    onClick={() => this.openCertificateDialog()}
                    // icon="add"
                    className="employer-accept-button"
                    style={{
                      borderRadius: '0px',
                      width: '150px',
                    }}
                  >
                    Uusi pätevyys
                  </Button>
                )}
              </div>

              {certificates && users && certificateTypes && (
                <CertificatesTable
                  hasMargins
                  data={certificates}
                  showSnackbarMessage={this.showSnackbarMessage}
                />
              )}

              {(!certificates || !users || !certificateTypes) && (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <LoadingSpinner color="black" />
                </div>
              )}

              <CertificateDialog
                open={certificateDialogOpen}
                handleClose={this.closeCertificateDialog}
                certificate={openCertificate}
                styles={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' }}
                showSnackbarMessage={this.showSnackbarMessage}
              />

              {this.renderSnackbar()}

            </GridCell>
          </GridInner>
        </Grid>
      </>
    );
  }
}

export default Certificates;
