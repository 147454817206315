/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';
import { Icon, IconButton } from '@material-ui/core';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import './FilePreviewDialog.css';

@inject('t')
@observer
class FilePreviewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfPages: 1,
    };
  }

  setNumberOfPages = ({ numPages }) => {
    if (numPages) {
      this.setState({
        numberOfPages: numPages,
      });
    }
  }

  render() {
    const { t, open, fileURL, fileName, onClose } = this.props;
    const { numberOfPages } = this.state;
    const fakePageArray = Array.from({ length: numberOfPages }, (_, index) => index);

    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
        className="trip-route-dialog"
      >
        <DialogContent className="xl-buttons">
          <div style={{ position: 'relative', width: '100%' }}>
            <div
              style={{
                width: '100%',
                textAlign: 'end',
                position: 'absolute',
                zIndex: 1,
                top: '35px',
                right: '5px',
              }}
            >
              <IconButton
                style={{
                  color: 'white',
                  backgroundColor: 'rgb(128 128 128 / 75%)',
                  borderRadius: '30px',
                  marginRight: '15px',
                }}
                onClick={() => {
                  window.open(fileURL, '_blank', 'noopener,noreferrer');
                }}
              >
                <Icon>open_in_new</Icon>
              </IconButton>

              <IconButton
                style={{
                  color: 'white',
                  // padding: '10px',
                  backgroundColor: 'rgb(128 128 128 / 75%)',
                  borderRadius: '30px',
                }}
                onClick={async (e) => {
                  // ChatGPT 4o, 23.5.2024
                  e.stopPropagation();
                  // Fetch the file content
                  const response = await fetch(fileURL);
                  const blob = await response.blob();

                  // Create a temporary anchor tag to trigger the download
                  const link = document.createElement('a');
                  link.href = URL.createObjectURL(blob);
                  link.download = fileName;

                  // Append the link to the document body
                  document.body.appendChild(link);

                  // Trigger the download by programmatically clicking the link
                  link.click();

                  // Clean up by revoking the object URL and removing the link
                  URL.revokeObjectURL(link.href);
                  document.body.removeChild(link);
                }}
              >
                <Icon>get_app</Icon>
              </IconButton>
            </div>
            <div style={{ width: '100%', minHeight: '100px', fontSize: '16px', color: 'white' }}>
              {fileURL?.split('.').pop().includes('pdf') ? (
                // onLoadSuccess={onDocumentLoadSuccess}
                <Document
                  loading="Avataan PDF..."
                  noData="Tiedostoa ei löytynyt"
                  error="Tiedoston avaus epäonnistui"
                  file={fileURL}
                  onLoadSuccess={this.setNumberOfPages}
                >
                  {/* Render each page */}
                  {fakePageArray.map((_, index) => (
                    <Page pageNumber={index + 1} />
                  ))}
                </Document>
                // Does not work in Safari, presumably because Rails does a 304 redirect when loading PDF
                // <object
                //   aria-label={file.filename}
                //   width="100%"
                //   height="800"
                //   // data={file.file_url}
                //   data="http://www.africau.edu/images/default/sample.pdf"
                //   type="application/pdf"
                // />
              ) : (
                <img style={{ maxWidth: '100%' }} src={fileURL} alt={fileURL} />
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <DialogButton
            type="button"
            action="close"
            className="cancel-button"
          >
            {t('messages.dialogDeleteButtonCancel')}
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }
}

export default FilePreviewDialog;
