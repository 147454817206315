
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
import WorkOrder from '../calendar/WorkOrder';
import BillingAttachment from '../employer-billing/BillingAttachment';
import moment from '../utils/moment';
import WorkOrderAvailability from './WorkOrderAvailability';
import WorkPeriod from '../models/WorkPeriod';
import AutocompleteLocation from '../timelog/AutocompleteLocation';
import Location from '../models/Location';
import WorkOrderBilling from '../models/WorkOrderBilling';
import SalaryPeriodAttachment from '../timelog/SalaryPeriodAttachment';

const isBetweenPeriod = (ref, start, end) => moment(ref).isBetween(start, end, null, '[]');

export default class EmployerWorkOrderStore {
  @observable workOrdersWithMeta = null; // fromPromise.resolve([]);

  @observable.shallow periods = observable([]);

  @observable purchasers;

  @observable locations;

  // This is used to limit the amount of archived work orders that are queried
  @observable employerWoLimit = 75;

  // Every time the archived work orders are queried, this is increased by the limit to form the pagination in the queries
  @observable employerWoOffset = 0;

  @observable workOrderFilters = null;

  @observable billingFilters = [];

  @observable billingDisplayColumns = [];

  @observable workOrderCache;

  @observable billingWorkOrderCache;

  constructor(uiStore, loginStore, requests) {
    this.uiStore = uiStore;
    this.loginStore = loginStore;
    this.requests = requests;
  }

  @action setFilters(filters) {
    this.workOrderFilters = filters;
  }

  @action setBillingFilters(column, filters) {
    this.billingFilters[column] = filters;
  }

  @action cacheWorkOrders(workOrders, count, limit, offset, sort) {
    this.workOrderCache = {
      workOrders,
      count,
      limit,
      offset,
      sort,
    };
  }

  @action cacheBillingWorkOrders(workOrders, count, limit, offset, sort) {
    this.billingWorkOrderCache = {
      workOrders,
      count,
      limit,
      offset,
      sort,
    };
  }

  // For logout
  @action emptyCaches() {
    this.workOrderCache = null;
    this.billingWorkOrderCache = null;
  }

  @action emptyFilters() {
    this.billingFilters = [];
  }

  @action setBillingDisplayColumns(column, display) {
    this.billingDisplayColumns[column] = display;
  }

  @action createWorkOrder(item, resolve, reject) {
    // invitationRequired parameter here
    return this.requests.WorkOrders.create(item).then((workOrder) => {
      let newWo = WorkOrder.fromJsonProperties(workOrder);
      newWo = this.categorizeWorkOrder(newWo);
      // const allWorkOrders = [].concat(this.uiStore.currentUser.workOrders, newWo);
      // this.workOrdersWithMeta = [...this.workOrdersWithMeta, newWo];
      this.workOrderCache = { ...this.workOrderCache, workOrders: [newWo, ...this.workOrderCache.workOrders] };

      resolve(newWo);
    }).catch((err) => reject(err));
  }

  @action updateWorkOrder(item, resolve, reject) {
    this.requests.WorkOrders.update(item).then((workOrder) => {
      // Problem: when updating as employer, API returns an EmployerWorkOrder
      // This is incompatible with WorkOrder (for employees), even though it also needs updating if the employer is a participant

      // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === workOrder.id);
      // if (foundWo) {
      //   foundWo.updatePropertiesFromJson(workOrder);
      // }

      const foundEmployerWoIndex = this.workOrderCache.workOrders.findIndex((wo) => wo.id === workOrder.id);
      let foundEmployerWo = this.workOrderCache.workOrders[foundEmployerWoIndex];
      if (foundEmployerWo) {
        foundEmployerWo.updatePropertiesFromJson(workOrder);
        foundEmployerWo = this.categorizeWorkOrder(foundEmployerWo);

        // Trigger a UI re-render with this.workOrdersWithMeta re-assignment
        const updatedWorkOrders = [...this.workOrderCache.workOrders];
        updatedWorkOrders[foundEmployerWoIndex] = foundEmployerWo;
        this.workOrderCache = { ...this.workOrderCache, workOrders: updatedWorkOrders };
      }

      const foundEmployerWoBillingIndex = this.billingWorkOrderCache?.workOrders.findIndex((wo) => wo.id === workOrder.id);
      let foundEmployerBillingWo = this.billingWorkOrderCache?.workOrders[foundEmployerWoBillingIndex];
      if (foundEmployerBillingWo) {
        foundEmployerBillingWo.updatePropertiesFromJson(workOrder);
        foundEmployerBillingWo = this.categorizeWorkOrder(foundEmployerBillingWo);

        // Trigger a UI re-render with this.workOrdersWithMeta re-assignment
        const updatedBillingWorkOrders = [...this.billingWorkOrderCache.workOrders];
        updatedBillingWorkOrders[foundEmployerWoBillingIndex] = foundEmployerBillingWo;
        this.billingWorkOrderCache = { ...this.billingWorkOrderCache, workOrders: updatedBillingWorkOrders };
      }

      resolve(foundEmployerWo);
    }).catch((err) => reject(err));
  }

  @action deleteWorkOrderInvite(invite, resolve, reject) {
    return this.requests.WorkOrders.deleteInvite(invite).then((workOrder) => {
      // Handle updating this.uiStore.currentUser.workOrders
      if (invite.userId === this.uiStore.currentUser.id) {
        const filteredWorkOrders = this.uiStore.currentUser.workOrders.filter((wo) => wo.id !== invite.workOrderId);
        this.uiStore.currentUser.workOrders = filteredWorkOrders;
      }

      // Handle updating this.workOrdersWithMeta.value
      const foundEmployerWo = this.workOrderCache?.workOrders.find((wo) => wo.id === workOrder.id);
      if (foundEmployerWo) {
        foundEmployerWo.updatePropertiesFromJson(workOrder);
      }

      resolve(foundEmployerWo);
    }).catch((err) => reject(err));
  }

  @action deleteUserPrivilege(privilege, resolve, reject) {
    return this.requests.WorkOrderUserPrivileges.del(privilege.id).then((workOrder) => {
      // Handle updating this.uiStore.currentUser.workOrders
      // if (privilege.user_id === this.uiStore.currentUser.id) {
      //   const filteredWorkOrders = this.uiStore.currentUser.workOrders.filter((wo) => wo.id !== privilege.work_order_id);
      //   this.uiStore.currentUser.workOrders = filteredWorkOrders;
      // }

      // Handle updating this.workOrdersWithMeta.value
      const foundEmployerWo = this.workOrderCache?.workOrders.find((wo) => wo.id === workOrder.id);
      if (foundEmployerWo) {
        foundEmployerWo.updatePropertiesFromJson(workOrder);
      }

      resolve(foundEmployerWo);
    }).catch((err) => reject(err));
  }

  @action clone(id, resolve, reject) {
    return this.requests.WorkOrders.clone(id).then((workOrder) => {
      const newWo = WorkOrder.fromJsonProperties(workOrder);

      // We need to categorize the new work order by its dates (from + to) to give it a status for filtering (employer-work-orders/index.jsx)
      // Might be unnecessary in the current implementation
      const categorizedNewWos = this.categorizeWorkOrders([newWo]);

      // A freshly created clone is never under currentUser.workOrders immediately so we only need to update this.workOrdersWithMeta
      this.workOrderCache = { ...this.workOrderCache, workOrders: [...categorizedNewWos, ...this.workOrderCache.workOrders] };

      resolve(newWo);
    }).catch((err) => reject(err));
  }

  @action delete(id, resolve, reject) {
    return this.requests.WorkOrders.delete(id).then((res) => {
      // Remove the deleted work order from work order set
      // const updatedWorkOrdersWithMeta = [...this.workOrdersWithMeta];
      // const foundIndex = updatedWorkOrdersWithMeta.findIndex((wo) => wo.id === id);
      // if (foundIndex !== -1) {
      //   updatedWorkOrdersWithMeta.splice(foundIndex, 1);
      // }
      // this.workOrdersWithMeta = updatedWorkOrdersWithMeta;

      // Remove the deleted work order from cache
      const updatedCacheWorkOrders = this.workOrderCache.workOrders ? [...this.workOrderCache.workOrders] : [];
      const foundCacheIndex = updatedCacheWorkOrders.findIndex((wo) => wo.id === id);
      if (foundCacheIndex !== -1) {
        updatedCacheWorkOrders.splice(foundCacheIndex, 1);
      }
      this.workOrderCache = { ...this.workOrderCache, workOrders: updatedCacheWorkOrders };

      // Remove the deleted work order from billing cache
      const updatedBillingCacheWorkOrders = this.billingWorkOrderCache ? [...this.billingWorkOrderCache.workOrders] : [];
      const foundBillingCacheIndex = updatedBillingCacheWorkOrders.findIndex((wo) => wo.id === id);
      if (foundBillingCacheIndex !== -1) {
        updatedBillingCacheWorkOrders.splice(foundBillingCacheIndex, 1);
      }
      this.billingWorkOrderCache = { ...this.billingWorkOrderCache, workOrders: updatedBillingCacheWorkOrders };

      resolve(res);
    }).catch((err) => {
      if (err.response.body?.error === 'Cannot delete work order with associated work hours or invitations') {
        reject('hours-or-invitations-error');
      }
      reject(err);
    });
  }

  // TODO: Refactor with TimelogStore.updateForWorkOrder, maybe move into a separate file as a utility method if identical
  updateForWorkOrder = (workOrders, cb) => {
    const result = {
      // Fill with work orders depending on their relation to the current salary period
      ongoing: [],
      upcomingShort: [],
      upcomingAll: [],
      past: [],
    };

    // let currentPeriod;
    const today = moment().startOf('day');
    const tomorrow = moment(today).add(1, 'days');
    const twoWeeks = moment(today).add(14, 'days');
    let startdate;
    let enddate;
    let newItem;

    workOrders.forEach((wo) => {
      startdate = wo.interval.start;
      enddate = wo.interval.end;

      if (isBetweenPeriod(today, startdate, enddate)) {
        newItem = {
          from: startdate,
          to: enddate,
          updatedAt: moment(),
          workOrder: wo,
        };
        result.ongoing.push(newItem);
      }

      if (enddate < today) {
        newItem = {
          from: startdate,
          to: enddate,
          updatedAt: moment(),
          workOrder: wo,
        };
        result.past.push(newItem);
      }

      if (startdate > today) {
        newItem = {
          from: startdate,
          to: enddate,
          updatedAt: moment(),
          workOrder: wo,
        };
        result.upcomingAll.push(newItem);
      }

      if (isBetweenPeriod(startdate, tomorrow, twoWeeks)) {
        newItem = {
          from: startdate,
          to: enddate,
          updatedAt: moment(),
          workOrder: wo,
        };
        result.upcomingShort.push(newItem);
      }
    });

    // As of the time of writing, this callback is used to resolve a promise or update the state with the result
    cb(result);
  }

  categorizeWorkOrder = (workOrder) => {
    const today = moment().startOf('day');
    const tomorrow = moment(today).add(1, 'days');
    const twoWeeks = moment(today).add(14, 'days');
    const startDate = workOrder.interval.start;
    const endDate = workOrder.interval.end;

    // This map + return new WorkOrder() satisfies eslint's no-param-reassign, but there's probably a better way
    if (isBetweenPeriod(today, startDate, endDate)) {
      return new WorkOrder({ ...workOrder, status: 'ongoing' });
    }
    if (endDate < today) {
      return new WorkOrder({ ...workOrder, status: 'past' });
    }

    if (startDate > today) {
      return new WorkOrder({ ...workOrder, status: 'upcomingAll' });
    }

    if (isBetweenPeriod(startDate, tomorrow, twoWeeks)) {
      return new WorkOrder({ ...workOrder, status: 'upcomingShort' });
    }

    // Should never happen
    return new WorkOrder({ ...workOrder, status: 'unknown' });
  }

  // Filter compatible version, the old (updateForWorkOrder) should be removed at some point
  // eslint-disable-next-line consistent-return
  categorizeWorkOrders = (workOrders, cb = null) => {
    const categorizedWorkOrders = workOrders.map((wo) => this.categorizeWorkOrder(wo));

    // As of the time of writing, this callback is used to resolve a promise or update the state with the result
    if (cb) {
      cb(categorizedWorkOrders);
    } else {
      return categorizedWorkOrders;
    }
  }

  // Move to purchaser store
  @action async getPurchasers(user) {
    this.purchasers = await this.requests.Purchasers.getAll(user);
  }

  // Move to location store
  @action async getLocations(user) {
    this.locations = await this.requests.Locations.getAll(user).then((json) => json?.map((location) => Location.fromJsonProperties(location)));
  }

  // Move to location store
  @action addNewLocation = (location) => {
    this.locations.push(location);
  }

  // Move to purchaser store
  @action addNewPurchaser = (purchaser) => {
    this.purchasers.push(purchaser);
  }

  // TODO: Compare to getAvailabilitiesMultipleWeeks in term of resolving/rejecting and how to handle them in the UI
  @action.bound getAvailabilities(id, from, to) {
    const availabilities = fromPromise(new Promise((resolve) => this.requests.WorkOrders.getAvailabilities(id, from, to).then((json) => WorkOrderAvailability.fromJsonProperties(json)).then(resolve)));
    return availabilities;
  }

  @action.bound getWorkPeriods(id) {
    const workPeriods = fromPromise(new Promise((resolve) => this.requests.WorkOrders.getWorkPeriods(id).then((json) => json.map(WorkPeriod.fromJsonProperties)).then(resolve)));
    return workPeriods;
  }

  @action.bound getAvailabilitiesPromise(id, from, to, periodId, resolve, reject) {
    // const availabilities = fromPromise(new Promise((resolve) => this.requests.WorkOrders.getAvailabilities(id, from, to).then((json) => WorkOrderAvailability.fromJsonProperties(json)).then(resolve)));
    // return availabilities;
    return this.requests.WorkOrders.getAvailabilities(id, from, to, periodId).then((json) => {
      resolve(WorkOrderAvailability.fromJsonProperties(json));
    }).catch((err) => reject(err));
  }

  @action.bound getAvailabilitiesMultipleWeeks(id, from, to, resolve, reject) {
    return this.requests.WorkOrders.getAvailabilitiesMultipleWeeks(id, from, to).then((json) => {
      const processedJson = { ...json };
      json.forEach((availabilityCollection, index) => {
        processedJson[index].availabilities = WorkOrderAvailability.fromJsonProperties(availabilityCollection.availabilities);
      });
      resolve(processedJson);
    }).catch((err) => reject(err));
  }

  @action.bound getEmployers() {
    const employers = fromPromise(new Promise((resolve) => this.requests.Users.employers().then((json) => resolve(json))));
    return employers;
  }

  @action createWorkOrderAttachment(data, resolve) {
    this.requests.WorkOrderAttachments.create(data).then((newAttachment) => {
      // User work order, e.g. the employer is a participant if this is found
      const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === newAttachment.workOrderId);
      if (foundWo) {
        const updatedAttachments = [...foundWo.attachments];
        updatedAttachments.push(newAttachment);
        foundWo.changeAttribute('attachments', updatedAttachments);
      }

      // Employer work order, assumed to always exist if this method is able to be called
      const foundEmployerWo = this.workOrderCache?.workOrders.find((wo) => wo.id === newAttachment.workOrderId);
      const employerWoUpdatedAttachments = [...foundEmployerWo.attachments];
      employerWoUpdatedAttachments.push(newAttachment);
      foundEmployerWo.changeAttribute('attachments', employerWoUpdatedAttachments);

      resolve(newAttachment);
    });
  }

  @action updateWorkOrderAttachment(data, resolve) {
    this.requests.WorkOrderAttachments.update(data).then((updatedAttachment) => {
      // User work order, e.g. the employer is a participant if this is found
      const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === updatedAttachment.workOrderId);
      if (foundWo) {
        const foundIndex = foundWo.attachments.findIndex((attachment) => attachment.id === updatedAttachment.id);
        const updatedAttachments = [...foundWo.attachments];
        updatedAttachments[foundIndex] = updatedAttachment;
        foundWo.changeAttribute('attachments', updatedAttachments);
      }

      // Employer work order, assumed to always exist if this method is able to be called
      const foundEmployerWo = this.workOrderCache?.workOrders.find((wo) => wo.id === updatedAttachment.workOrderId);
      const foundEmployerIndex = foundEmployerWo.attachments.findIndex((attachment) => attachment.id === updatedAttachment.id);
      const updatedEmployerAttachments = [...foundEmployerWo.attachments];
      updatedEmployerAttachments[foundEmployerIndex] = updatedAttachment;
      foundEmployerWo.changeAttribute('attachments', updatedEmployerAttachments);

      resolve(updatedAttachment);
    });
  }

  @action deleteWorkOrderAttachment(id, workOrderId) {
    return this.requests.WorkOrderAttachments.del(id).then(() => {
      // User work order, e.g. the employer is a participant if this is found
      const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === workOrderId);
      if (foundWo) {
        const updatedAttachments = foundWo.attachments.filter((attachment) => attachment.id !== id);
        foundWo.changeAttribute('attachments', updatedAttachments);
      }

      // Employer work order, assumed to always exist if this method is able to be called
      const foundEmployerWo = this.workOrderCache.workOrders?.find((wo) => wo.id === workOrderId);
      const employerWoUpdatedAttachments = foundEmployerWo.attachments.filter((attachment) => attachment.id !== id);
      foundEmployerWo.changeAttribute('attachments', employerWoUpdatedAttachments);
    });
  }

  @action deleteAttachmentFile(id, workOrderId) {
    return this.requests.WorkOrderAttachments.purgeAttachmentFile(id).then(() => {
      const foundEmployerWo = this.workOrderCache?.workOrders.find((wo) => wo.id === workOrderId);
      const foundBillingWo = this.billingWorkOrderCache?.workOrders.find((wo) => wo.id === workOrderId);

      if (foundEmployerWo) {
        const foundIndex = foundEmployerWo.attachments.findIndex((attachment) => attachment.id === id);
        const attachments = [...foundEmployerWo.attachments];
        attachments[foundIndex] = { ...attachments[foundIndex], fileURL: null, filename: null };
        foundEmployerWo.changeAttribute('attachments', attachments);
      }

      if (foundBillingWo) {
        const foundIndex = foundBillingWo.attachments.findIndex((attachment) => attachment.id === id);
        const attachments = [...foundBillingWo.attachments];
        attachments[foundIndex] = { ...attachments[foundIndex], fileURL: null, filename: null };
        foundBillingWo.changeAttribute('attachments', attachments);
      }
    });
  }

  @action createBillingAttachment(data, resolve) {
    this.requests.BillingAttachments.create(data).then((response) => {
      const newAttachment = BillingAttachment.fromJsonProperties(response);

      // Billing attachments aren't used in the basic user work order, only update employer work order
      // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === data.workOrderId);
      const foundWo = this.billingWorkOrderCache?.workOrders.find((wo) => wo.id === data.workOrderId);

      const updatedBillingAttachments = [...foundWo.billingAttachments];
      updatedBillingAttachments.push(newAttachment);
      foundWo.changeAttribute('billingAttachments', updatedBillingAttachments);

      resolve(newAttachment);
    });
  }

  @action generatePDF(attachmentId, signedIds, woId, resolve, reject) {
    this.requests.BillingAttachments.generatePDF(attachmentId, signedIds).then((response) => {
      const newPDF = {
        filename: response.pdf.filename,
        fileURL: response.pdf.url,
        signedId: response.pdf.signed_id,
      };

      // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === woId);
      // const updatedAttachments = [...foundWo.billingAttachments];
      // const foundIndex = updatedAttachments.findIndex((attachment) => attachment.id === attachmentId);

      // updatedAttachments[foundIndex].changeAttribute('files', [...updatedAttachments[foundIndex].files, newPDF]); // ].push(newPDF);
      // foundWo.changeAttribute('billingAttachments', updatedAttachments);

      resolve({ pdf: newPDF, deletedFilesSignedIds: response.deleted_files });
    }).catch((err) => reject(err));
  }

  @action updateBillingAttachmentProp(data, resolve) {
    // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === data.workOrderId);
    const foundWo = this.billingWorkOrderCache?.workOrders.find((wo) => wo.id === data.workOrderId);
    const updatedBillingAttachments = [...foundWo.billingAttachments];
    const foundIndex = updatedBillingAttachments.findIndex((attachment) => attachment.id === data.id);
    // Note: Using updateProperties instead of updatePropertiesFromJson
    updatedBillingAttachments[foundIndex].changeAttribute('files', data.files);
    foundWo.changeAttribute('billingAttachments', updatedBillingAttachments);
    resolve(updatedBillingAttachments[foundIndex]);
  }

  @action updateBillingAttachment(data, resolve) {
    this.requests.BillingAttachments.update(data).then((updatedAttachment) => {
      // Billing attachments aren't used in the basic user work order, only update employer work order
      // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === data.workOrderId);
      const foundWo = this.billingWorkOrderCache?.workOrders.find((wo) => wo.id === data.workOrderId);

      const foundIndex = foundWo.billingAttachments.findIndex((attachment) => attachment.id === updatedAttachment.id);

      const updatedBillingAttachments = [...foundWo.billingAttachments];

      updatedBillingAttachments[foundIndex].updatePropertiesFromJson(updatedAttachment);

      foundWo.changeAttribute('billingAttachments', updatedBillingAttachments);

      resolve(updatedBillingAttachments[foundIndex]);
    });
  }

  @action deleteBillingAttachment(id, workOrderId) {
    return this.requests.BillingAttachments.del(id).then(() => {
      // Billing attachments aren't used in the basic user work order, only update employer work order
      // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === workOrderId);
      const foundWo = this.billingWorkOrderCache?.workOrders.find((wo) => wo.id === workOrderId);

      const updatedBillingAttachments = foundWo.billingAttachments.filter((attachment) => attachment.id !== id);

      foundWo.changeAttribute('billingAttachments', updatedBillingAttachments);
    });
  }

  @action deleteBillingAttachmentFile(id, workOrderId, signedId) {
    return this.requests.BillingAttachments.purgeAttachmentFile(id, signedId).then(() => {
      // no-op
    });
  }

  @action acceptBillingAttachment(id, cb) {
    return this.requests.BillingAttachments.employerAccept(id).then((acceptedAttachment) => {
      // Billing attachments aren't used in the basic user work order, only update employer work order
      const newAttachment = BillingAttachment.fromJsonProperties(acceptedAttachment);

      if (cb) {
        // Used for updating the accepted billing attachment in state
        cb(newAttachment);
      }
    });
  }

  // Copypasted from client/src/login/LoginStore.js
  newAccomodationForAutocomplete(workOrder, user) {
    const newAccomodation = {
      street: workOrder.accomodation_street,
      zipCode: workOrder.accomodation_zip_code,
      city: workOrder.accomodation_city,
      name: workOrder.accomodation_name,
    };
    this.newAutocompleteAddress(newAccomodation, user);
  }

  // Copypasted from client/src/login/LoginStore.js
  newAutocompleteAddress(newAddressParam, user = null) {
    const ctxUser = user || this.uiStore.currentUser;
    const newAddress = AutocompleteLocation.fromJsonProperties({
      street: newAddressParam.street,
      zip_code: newAddressParam.zipCode,
      city: newAddressParam.city,
      name: newAddressParam.name,
    });

    if (newAddress.street && newAddress.city) {
      const foundIndex = ctxUser.autocompleteLocations.findIndex((address) => address.street === newAddress.street && address.city === newAddress.city);
      // Match found, check if should be replaced or not
      if (foundIndex !== -1) {
        const foundAddress = ctxUser.autocompleteLocations[foundIndex];
        // Replace the old address without a zip code if the new one has it
        if (!foundAddress.zipCode && newAddress.zipCode) {
          ctxUser.autocompleteLocations[foundIndex] = newAddress;
        } else if ((foundAddress.zipCode === newAddress.zipCode) && !foundAddress.name && newAddress.name) {
          ctxUser.autocompleteLocations[foundIndex] = newAddress;
        }
        // No match found, push to the autocompleteLocations
      } else {
        ctxUser.autocompleteLocations.push(newAddress);
      }
    }
  }

  @action getEmployerWorkOrdersPaginated(offset, limit, sort) {
    const ctxUser = this.uiStore.currentUser;

    return new Promise((resolve, reject) => {
      this.requests.Users.getEmployerWorkOrdersPaginated(ctxUser, offset, limit, sort)
        .then(
          action((json) => {
            const workOrders = json.work_orders.map((wo) => {
              this.newAccomodationForAutocomplete(wo, ctxUser);
              return WorkOrder.fromJsonProperties(wo, 'employer');
            });

            const categorizedWorkOrders = this.categorizeWorkOrders(workOrders);

            resolve({
              workOrders: categorizedWorkOrders,
              count: json.total_count,
            });
          }),
        )
        .catch((err) => {
          console.log('error', err);
          reject(err);
        });
    });
  }

  @action getEmployerWorkOrdersBillingPaginated(offset, limit, sort) {
    const ctxUser = this.uiStore.currentUser;

    return new Promise((resolve, reject) => {
      this.requests.Users.getEmployerWorkOrdersBillingPaginated(ctxUser, offset, limit, sort)
        .then(
          action((json) => {
            const workOrders = json.work_orders.map((wo) => {
              this.newAccomodationForAutocomplete(wo, ctxUser);
              return WorkOrderBilling.fromJsonProperties(wo, 'employer');
            });

            const categorizedWorkOrders = this.categorizeWorkOrders(workOrders);

            resolve({
              workOrders: categorizedWorkOrders,
              count: json.total_count,
            });
          }),
        )
        .catch((err) => {
          console.log('error', err);
          reject(err);
        });
    });
  }

  @action getAllWorkOrderAttachments(workOrderId, resolve) {
    return this.requests.WorkOrders.getAllWorkOrderAttachments(workOrderId)
      .then(
        action((json) => {
          const billingAttachments = json.salary_period_attachments ? json.billing_attachments.map(BillingAttachment.fromJsonProperties) : [];
          const salaryPeriodAttachments = json.salary_period_attachments ? json.salary_period_attachments.map(SalaryPeriodAttachment.fromJsonProperties) : [];

          const result = {
            billingAttachments,
            salaryPeriodAttachments,
          };

          resolve(result);
        }),
      );
  }

  @action updateBilling(workOrderBilling) {
    // Actually just "bill" in the DB but this is incorrect, it is used to track to billing status and isn't a bill
    return this.requests.WorkOrderBillings[workOrderBilling.id ? 'update' : 'create'](workOrderBilling)
      .then(
        action((json) => {
          const foundWo = this.billingWorkOrderCache?.workOrders.find((wo) => wo.id === json.work_order_id);
          foundWo.bill = json;
          return json;
        }),
      )
      .catch((err) => {
        console.log('error', err);
      });
  }
}
