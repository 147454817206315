import { observable, action } from 'mobx';
// import moment from 'moment';
// import WorkOrderInvitation from '../employer-work-orders/WorkOrderInvitation';

export default class AbsenceType {
  @observable id;

  @observable officialName;

  @observable nickname;

  @observable valueTemplates;

  // @observable _destroy = false;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      officialName: o.official_name,
      nickname: o.nickname,
      valueTemplates: o.value_templates,
      hasTimes: o.has_times,
      // valueType: o.value_type,
      // defaultValue: o.default_value,
      // absence_entries_attributes: absenceEntries mapped fromJson
    });
  }

  // static toJson(o) {
  //   return {
  //     id: o.id,
  //     name: o.name,
  //     description: o.description,
  //     // _destroy: o._destroy,
  //     // absence_entries_attributes: absenceEntries mapped toJson
  //   };
  // }


  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const absenceType = new AbsenceType({});
    absenceType.updatePropertiesFromJson(object);
    return absenceType;
  }

  static fromJson(json) {
    return AbsenceType.fromJsonProperties(JSON.parse(json));
  }
}
