import { observable, action } from 'mobx';
import moment from 'moment';
import WorkTaskUser from './WorkTaskUser';

export default class WorkTask {
  @observable id;

  @observable workOrderId;

  @observable identifierOne;

  @observable identifierTwo;

  @observable description;

  @observable childTasks;

  // Not actually used in the database column, but instead in awesome_nested_set's moving methods
  @observable parentId;

  @observable files;

  @observable status;

  @observable statuses;

  @observable estimate;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      workOrderId: o.work_order_id,
      identifierOne: o.identifier_one,
      identifierTwo: o.identifier_two,
      identifiers: o.identifier_two ? `${o.identifier_one} - ${o.identifier_two}` : o.identifier_one,
      description: o.description,
      // childTasks: o.child_tasks.map((task) => WorkTask.fromJson(task)),
      childTasks: o.child_tasks ? o.child_tasks.map(WorkTask.fromJsonProperties) : [],
      parentId: o.parent_id,
      files: o.files ? o.files.map((file) => ({
        filename: file.filename,
        fileURL: file.url,
        signedId: file.signed_id,
        createdAt: file.created_at,
      })) : [],
      workTaskUsers: o.work_task_users ? o.work_task_users.map(WorkTaskUser.fromJsonProperties) : [],
      totalHours: o.total_hours,
      // A quick fix for table filtering so that null statuses aren't represented with an empty checkbox
      status: o.status ? o.status : { name: 'Ei asetettu' },
      statuses: o.statuses,
      createdAt: moment(o.created_at),
      estimate: o.estimate,
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const workTask = new WorkTask({});
    workTask.updatePropertiesFromJson(object);
    return workTask;
  }

  static fromJson(json) {
    return WorkTask.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    return {
      id: o.id,
      work_order_id: o.workOrderId,
      identifier_one: o.identifierOne,
      identifier_two: o.identifierTwo,
      description: o.description,
      parent_id: o.parentId,
      files: o.files,
      work_task_users_attributes: o.workTaskUsers.map(WorkTaskUser.toJson),
      status_id: o.statusId,
      estimate: o.estimate,
    };
  }
}
