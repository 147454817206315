import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { DirectUploadProvider } from 'react-activestorage-provider';

import {
  List, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText,
} from '@rmwc/list';
import { Icon } from '@rmwc/icon';
// import { Select } from '@rmwc/select';
import '@rmwc/icon/icon.css';
import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';

import { buttonColors } from '../calendar/EmployeeCalendar';
import { handleProgress } from '../models/user/user-ui-avatar';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';

const addButton = (data, participants, inputRef, timelogStore, t) => {
  const uploadedFor = null;

  return (
    <DirectUploadProvider
      directUploadsPath="/rails/active_storage/direct_uploads"

      multiple={false}

      onSuccess={(newData) => {
        if (Array.isArray(newData)) {
          const [value] = newData;
          const body = {
            ...data,
            file: value,
          };

          if (uploadedFor != null && uploadedFor !== '') {
            body.uploaderId = body.userId;
            body.userId = uploadedFor;
          }

          timelogStore.createSalaryPeriodAttachment(body);
        }
      }}

      render={({ handleUpload, uploads, ready }) => (
        <>
          <input
            className="inputfile"
            disabled={!ready}
            multiple={false}
            onChange={(e) => {
              handleUpload(e.currentTarget.files);
            }}
            ref={inputRef}
            type="file"
          />

          <ListItemMeta>
            {/* <Select
              className="mdc-theme--text-primary-on-dark pk-select"
              label="Kenelle?"
              onChange={(v) => {
                uploadedFor = v.currentTarget.value;
              }}
              options={participants}
              placeholder=""
              theme={['textPrimaryOnDark', 'secondaryBg']}
            /> */}
            <Icon {...{
              icon: 'add_cirle',
              className: 'ml-1',
              onClick: () => inputRef.current.click(),
              style: { color: buttonColors.green.color, width: '1em', marginBottom: '0.8em' },
            }}
            />
          </ListItemMeta>

          {handleProgress(uploads, t)}
        </>
      )}

      required
    />
  );
};

@inject('timelogStore', 't')
@observer
class Attachments extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deletingFile: {},
      confirmDeleteDialogOpen: false,
    };
  }

  openConfirmDeleteDialog(deletingFile) {
    this.setState({
      deletingFile,
      confirmDeleteDialogOpen: true,
    });
  }

  closeConfirmDeleteDialog() {
    this.setState({
      deletingFile: {},
      confirmDeleteDialogOpen: false,
    });
  }

  render() {
    const {
      t,
      timelogStore,
      disabled,
      periodId,
      userId,
      workOrder,
      inputRef,
      data,
    } = this.props;
    const { deletingFile, confirmDeleteDialogOpen } = this.state;

    let attachments = [];
    if (data != null) {
      attachments = data.get(`${periodId}-${workOrder.id}`) || [];
    }

    return (
      <>
        <List twoLine>
          <ListItem>
            <ListItemText>
              <ListItemPrimaryText>
                Liitetiedostot
              </ListItemPrimaryText>
            </ListItemText>

            {!disabled && addButton(
              {
                periodId,
                userId,
                workOrderId: workOrder.id,
              },
              workOrder.participants.filter((p) => p.id !== userId).map((p) => ({
                label: `${p.lastName}, ${p.firstName}`,
                value: p.id,
              })),
              inputRef, timelogStore, t,
            )}
          </ListItem>
          {attachments.map((item) => {
            let uploaderText = '';
            if (item.uploaderId != null) {
              if (item.uploaderId === userId) {
                uploaderText = `${item.uploaderName} lisäsi puolestasi`;
              } else {
                uploaderText = `liitit ${item.uploaderName}:lle`;
              }
            }

            return (
              <ListItem key={`attachment-${item.id}`}>
                <ListItemText>
                  <ListItemPrimaryText>
                    <a
                      className=""
                      download={item.filename}
                      href={item.fileUrl}
                      style={{
                        color: 'var(--mdc-theme-primary, #6200ee)',
                        lineHeight: '4em',
                        marginBottom: 'unset',
                        textDecoration: 'none',
                      }}
                    >
                      {item.filename}
                    </a>
                  </ListItemPrimaryText>
                  <ListItemSecondaryText>
                    {uploaderText}
                  </ListItemSecondaryText>
                </ListItemText>
                {!disabled && (
                  <ListItemMeta {...{
                    icon: 'delete_outline',
                    onClick: () => {
                      // timelogStore.deleteSalaryPeriodAttachment(item.id, periodId, workOrder.id);
                      this.openConfirmDeleteDialog({
                        itemId: item.id,
                        periodId,
                        workOrderId: workOrder.id,
                        name: item.filename,
                      });
                    },
                    style: { color: '#C7C9CC' },
                  }}
                  />
                )}
              </ListItem>
            );
          })}
        </List>

        <ConfirmDeleteDialog
          onConfirm={() => timelogStore.deleteSalaryPeriodAttachment(deletingFile.itemId, deletingFile.periodId, deletingFile.workOrderId)}
          onClose={() => this.closeConfirmDeleteDialog()}
          open={confirmDeleteDialogOpen}
          message={`Haluatko varmasti poistaa liitteen ${deletingFile.name}?`}
        />
      </>
    );
  }
}

export default Attachments;
