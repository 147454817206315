/* eslint-disable quotes, camelcase */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogTitle, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';

import LoadingSpinner from '../shared/LoadingSpinner';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import EmployerWorkOrderTripCard from '../employer-work-order-trips/employerWorkOrderTripCard';
import EmployeeInfoDialog from './EmployeeInfoDialog';
import WorkOrderInfoDialog from './WorkOrderInfoDialog';
import EmployerTimelogTableHtml from './EmployerTimelogTableHtml';

import moment from '../utils/moment';
import './html-table.css';

@inject('uiStore', 'timelogStore', 't')
@observer
class TimelogReportTableHtmlWrapper extends Component {
  constructor(props) {
    super(props);
    // this.employerTimelogTableRef = React.createRef();
    // this._dateRangePicker = React.createRef();

    this.state = {
      // originalWorkHoursWithMeta: null,
      // currentWorkHoursWithMeta: null,

      showEmployeeInfoDialog: false,
      employeeInfoDialogData: null,

      showWorkOrderInfoDialog: false,
      workOrderInfoDialogData: null,
      // isLoading: false,
      currentTimelogTrip: {},
    };
  }

  closeEmployeeInfoDialog = () => {
    this.setState({
      showEmployeeInfoDialog: false,
      employeeInfoDialogData: null,
    });
  }

  closeWorkOrderInfoDialog = () => {
    this.setState({
      showWorkOrderInfoDialog: false,
      workOrderInfoDialogData: null,
    });
  }

  openEmployeeInfoDialog = (employee) => {
    this.setState({
      showEmployeeInfoDialog: true,
      employeeInfoDialogData: employee,
    });
  }

  openWorkOrderInfoDialog = (workOrderInfo) => {
    this.setState({
      showWorkOrderInfoDialog: true,
      workOrderInfoDialogData: workOrderInfo,
    });
  }

  openWorkOrderTripAcceptanceDialog = (workOrderTripId) => {
    const { timelogStore } = this.props;
    this.setState({
      showWorkOrderTripDialog: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.getResponsibleEmployerWorkOrderTripsById(workOrderTripId, resolve, reject))
        .then((woTrip) => {
          // Success
          const workOrderTrip = WorkOrderTrip.fromJsonProperties(woTrip);
          this.setState({
            openedWorkOrderTripWithMeta: workOrderTrip,
          });
        })
        .catch((err) => {
          // Failure
          this.setState({
            openedWorkOrderTripWithMeta: null,
          }, console.log('ERROR: ', err));
        });
    });
  }

  openTripRouteDialog = (workHour) => {
    this.setState({
      tripRouteDialogOpen: true,
      currentTimelogTrip: {
        tripRoutes: workHour?.work_hour.trip_routes,
        date: workHour?.work_hour.date,
        user: workHour?.user,
      },
    });
  }

  renderWorkOrderTripAcceptanceDialog() {
    const { showWorkOrderTripDialog, openedWorkOrderTripWithMeta } = this.state;

    return (
      <Dialog
        style={{ padding: '0' }}
        className="employer-delete-modal work-order-trip-card-dialog"
        open={showWorkOrderTripDialog}
        onClose={() => {
          this.setState({
            showWorkOrderTripDialog: false,
            openedWorkOrderTripWithMeta: null,
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          {openedWorkOrderTripWithMeta ? (
            <EmployerWorkOrderTripCard
              workOrderTrip={openedWorkOrderTripWithMeta}
              key={`trip-card-${openedWorkOrderTripWithMeta.id}`}
              updateWorkOrderTrip={this.updateWorkOrderTrip}
              dialogMode
              readOnly
            />
          ) : (
            <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
              <LoadingSpinner color="black" />
            </div>
          )}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderRouteLocations(routeLocations) {
    const routeLocationWrappers = [];
    routeLocations.sort((a, b) => (a.location_order.valueOf() - b.location_order.valueOf()));

    // const currentRouteLocationElements = [];
    const currentRouteLocationElements = routeLocations?.map((routeLocation, index) => (
      <div key={routeLocation.id} style={{ paddingTop: index === 0 ? '0' : '10px' }}>
        <div>{routeLocation.street}</div>
        <div>{routeLocation.zip_code}</div>
        <div>{routeLocation.city}</div>
      </div>
    ));

    routeLocationWrappers.push(
      <div key="current_wrapper">
        {currentRouteLocationElements}
      </div>,
    );

    return routeLocationWrappers;
  }

  renderTripRoutes(tripRoutes, key) {
    const tripRouteElements = [];
    tripRoutes.forEach((tripRoute, index) => {
      if (tripRoute.kms_type === key) {
        tripRouteElements.push(
          <div key={`${tripRoute.id}-trip_route`} style={{ paddingLeft: '10px' }}>
            <div>
              Reitti
              &nbsp;
              {index + 1}
              :
            </div>
            <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Selite:</span>
                <span style={{ textAlign: 'end', hyphens: 'auto', marginBottom: '5px' }}>{tripRoute.description}</span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Etäisyys (km):</span>
                <span>{tripRoute.kms}</span>
              </div>
              {/* <div>Selite: {tripRoute.description}</div>
              <div>Etäisyys (km): {tripRoute.kms}</div> */}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 10px 25px' }}>
              {this.renderRouteLocations(tripRoute.route_locations)}
            </div>
          </div>,
        );
      }
    });
    return tripRouteElements;
  }

  renderTripRouteDialog() {
    const { tripRouteDialogOpen, currentTimelogTrip } = this.state;
    return (
      <Dialog
        className="timelog-trip-route-dialog"
        open={tripRouteDialogOpen}
        onClose={() => {
          this.setState({
            tripRouteDialogOpen: false,
            currentTimelogTrip: {},
          });
        }}
        style={{ zIndex: 111 }}
      >
        <DialogTitle
          style={{
            color: 'var(--mdc-theme-primary)',
            padding: '5px 10px',
            textTransform: 'capitalize',
          }}
        >
          {currentTimelogTrip?.user?.full_name}
          &nbsp;
          -
          &nbsp;
          {moment(currentTimelogTrip.date).format('dd DD.MM.YYYY')}
        </DialogTitle>
        <DialogContent
          style={{ color: 'white' }}
        >
          {currentTimelogTrip.tripRoutes && this.renderTripRoutes(currentTimelogTrip.tripRoutes, 'kms')}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            className="accept-button"
            style={{
              borderRadius: '0px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { currentWorkHoursWithMeta, emitColumnVisibility, columnVisibility } = this.props;
    const {
      showEmployeeInfoDialog,
      employeeInfoDialogData,
      showWorkOrderInfoDialog,
      workOrderInfoDialogData,
    } = this.state;


    // Return the complete table element + dialogs
    return (
      <>
        {this.renderWorkOrderTripAcceptanceDialog()}
        {this.renderTripRouteDialog()}
        <EmployeeInfoDialog open={showEmployeeInfoDialog} employee={employeeInfoDialogData} onClose={this.closeEmployeeInfoDialog} />
        <WorkOrderInfoDialog open={showWorkOrderInfoDialog} workOrderInfo={workOrderInfoDialogData} onClose={this.closeWorkOrderInfoDialog} />
        <EmployerTimelogTableHtml
          currentWorkHoursWithMeta={currentWorkHoursWithMeta}
          openTripRouteDialog={this.openTripRouteDialog}
          openEmployeeInfoDialog={this.openEmployeeInfoDialog}
          openWorkOrderInfoDialog={this.openWorkOrderInfoDialog}
          openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
          emitColumnVisibility={emitColumnVisibility}
          columnVisibility={columnVisibility}
        />
      </>
    );
  }
}

export default TimelogReportTableHtmlWrapper;
