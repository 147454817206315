import React, { Component } from 'react';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';
// import { CircularProgress } from '@rmwc/circular-progress';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import LoadingSpinner from '../shared/LoadingSpinner';
import { appcontentsize as styles } from '../styles/inline-styles';


@inject('uiStore', 'loginStore', 't')
@observer


class Login extends Component {
  componentDidMount() {
    // Show a message on password reset success
    const { loginStore } = this.props;
    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.get('pwreset') === 'success') {
      loginStore.feedbackText = 'Salasanan vaihto onnistui, voit nyt kirjautua sisään.';
    } else {
      loginStore.feedbackText = '';
    }
  }

  render() {
    const { t, loginStore, afterLogin } = this.props;

    const buttonStyles = {
      marginTop: '1em',
      width: '95%',
      borderRadius: '0',
    };

    // if (loginStore.isLoggingIn) {
    //   return <CircularProgress size="large" />;
    // }

    return (
      <>
        <div style={{
          minHeight: '230px', textAlign: 'center', width: '100%', maxWidth: '800px',
        }}
        >
          <div style={{ height: '30px' }}>
            {loginStore.feedbackText && (
              <Typography
                use="body2"
                theme="primary"
                style={{ textAlign: 'center', fontSize: '20px' }}
              >
                {loginStore.feedbackText}
              </Typography>
            )}
          </div>
          <TextField
            label={t('login.labels.email')}
            onChange={loginStore.updateEmail}
            style={{ ...styles, borderRadius: '0', marginTop: '1em' }}
            value={loginStore.emailValue}
            type="email"
            id="email"
          />
          <TextField
            label={t('login.labels.password')}
            onChange={loginStore.updatePassword}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                event.stopPropagation();
                loginStore.login(afterLogin);
              }
            }}
            style={{ ...styles, borderRadius: '0', marginTop: '0.8em' }}
            type="password"
            value={loginStore.passwordValue}
            id="password"
          />
          <Button
            onClick={() => loginStore.login(afterLogin)}
            raised
            style={buttonStyles}
            className="accept-button"
            theme="onSecondary"
            {...loginStore.shakeLoginButton === true && { id: 'shake' }}
            id="login-button"
            disabled={loginStore.isLoggingIn || loginStore.isLoggedIn}
          >
            {loginStore.isLoggingIn || loginStore.isLoggedIn ? <LoadingSpinner color="black" size="mini" /> : t('login.labels.login')}
            {/* <LoadingSpinner color="black" size="mini" /> */}
          </Button>
          {loginStore.loginFailed && (
            <Button
              onClick={() => loginStore.resetPassword()}
              raised
              style={{ marginTop: '2em', border: '1px solid #FFC900', borderRadius: '0' }}
              theme="secondaryBg primary"
              {...loginStore.shakePwResetButton === true && { id: 'shake' }}
              id="reset-password-button"
            >
              Unohditko salasanasi?
            </Button>
          )}
        </div>
      </>
    );
  }
}

export default Login;
