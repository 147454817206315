/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { when } from 'mobx';
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import WorkPeriod from '../models/WorkPeriod';

import 'moment/locale/fi';
import moment, { cleanDate, shortDateRangeWithDay } from '../utils/moment';
import WorkPeriodForm from './WorkPeriodForm';
import './WorkPeriodDialog.css';

@inject('t', 'employerWorkOrderStore', 'uiStore')
@observer
class WorkPeriodDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workPeriod: props.currentWorkPeriod ? currentWorkPeriod : new WorkPeriod(),
      errors: {},
      showDeleteDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      open,
      workPeriod,
      workOrder,
      employerWorkOrderStore: {
        getAvailabilities,
      },
    } = this.props;

    if (open && !prevProps.open && workPeriod && workPeriod.from && workPeriod.to) {
      // this.setState({
      //   workOrdersWithSalaryPeriod: null,
      //   workOrderTrips: null,
      // });

      // const availabilities = getAvailabilities(workOrder.id, workPeriod?.from?.format('YYYY-MM-DD'), workPeriod?.to?.format('YYYY-MM-DD'));
      // this.setState({
      //   workPeriod,
      //   availabilities,
      // });
      this.setState({
        workPeriod,
      });
    }
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      workPeriod: new WorkPeriod(),
      availabilities: null,
    });

    onClose();
  }

  onSave() {
    const { onSave } = this.props;
    const { workPeriod } = this.state;

    onSave(workPeriod);

    this.setState({
      workPeriod: new WorkPeriod(),
      availabilities: null,
    });
  }

  updateWorkPeriod = (workPeriod) => {
    this.setState({
      workPeriod: workPeriod,
    });
  }

  confirmDeleteWorkPeriod() {
    this.setState({ showDeleteDialog: true });
  }

  handleDeleteWorkPeriod = () => {
    // Set work order period _destroy true here
    // this.setState({ showDeleteDialog: false });
    const { onSave } = this.props;
    const { workPeriod } = this.state;

    const updatedWorkPeriod = { ...workPeriod };
    updatedWorkPeriod._destroy = true;

    onSave(updatedWorkPeriod);

    this.setState({
      workPeriod: new WorkPeriod(),
      availabilities: null,
      showDeleteDialog: false,
    });
  }

  closeDeleteDialog = () => {
    this.setState({
      showDeleteDialog: false,
    });
  }

  workPeriodInvalid() {
    const { workPeriod } = this.state;
    // Should use proper form validation later on, but for now this works
    if (!workPeriod.from || !workPeriod.to) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    // const { t } = this.props;
    const { t, open, workOrder, uiStore: { mobileMode } } = this.props;
    const { workPeriod, availabilities, showDeleteDialog } = this.state;

    return (
      <Dialog
        className="modal-size employer-timelog-view-dialog"
        // className={mode !== 'employer' ? 'mui-dialog-background' : ''}
        open={open}
        onClose={(evt) => {
          evt.stopPropagation();
          this.onClose();
        }}
      >
        <DialogTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>Työjakso</div>
            {(
              <IconButton
                style={{
                  color: '#645F5F',
                }}
                aria-label="delete"
                onClick={() => {
                  this.confirmDeleteWorkPeriod();
                }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </div>
          {workOrder && (
            <div className="work-period-dialog-context" style={{ lineHeight: (workPeriod.id && !mobileMode) ? 0 : 1 }}>
              <span>{workOrder.name}</span>
              {workOrder.from && workOrder.to && (
                <span>{` | ${shortDateRangeWithDay(workOrder.from, workOrder.to)}`}</span>
              )}
            </div>
          )}
        </DialogTitle>

        <DialogContent
          style={{ fontSize: '16px', color: 'white', padding: '24px' }}
          className="work-period-dialog"
        >
          <WorkPeriodForm
            workOrder={workOrder}
            workPeriod={workPeriod}
            updateWorkPeriod={this.updateWorkPeriod}
            availabilities={availabilities}
            dialogMode
            showDeleteDialog={showDeleteDialog}
            closeDeleteDialog={this.closeDeleteDialog}
            handleDeleteWorkPeriod={this.handleDeleteWorkPeriod}
          />
        </DialogContent>
        <DialogActions
          className="dialog-actions-less-padding"
          style={{
            // display: showFullNestedDialog && 'none',
            // The closing button is moved to the left to prevent user from accidentally pressing the dialog close button when sending messages
            justifyContent: 'space-between',
          }}
        >
          {!this.workPeriodInvalid() && (
            <Button
              className="employer-accept-button"
              style={{
                borderRadius: '0px',
              }}
              onClick={() => this.onSave()}
              type="button"
            >
              Valmis
            </Button>
          )}

          <Button
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
            }}
            onClick={() => this.onClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default WorkPeriodDialog;
