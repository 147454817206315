import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
// import { Grid, GridInner, GridCell } from '@rmwc/grid';
// import { IconButton, TextField } from '@material-ui/core';
// import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import MomentLocaleUtils, {
//   formatDate,
//   parseDate,
// } from 'react-day-picker/moment';
import {
  Grid,
  IconButton,
  Icon,
  TextField,
} from '@material-ui/core';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import ReactToPrint from 'react-to-print';
import { groupBy } from 'lodash';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import '../locations-purchasers/locations-purchasers.css';
import DeepViewHeader from '../shared/DeepViewHeader';
import BillingReportsHourly from './BillingReportsHourly';
import BillingReports from './BillingReports';
import moment from '../utils/moment';
import LoadingSpinner from '../shared/LoadingSpinner';

const reconstructReportDataByUsers = (data) => {
  const groupedWorkHours = groupBy(data.workHours, 'user.fullName');
  const rawReportDataByUsers = {};

  Object.keys(groupedWorkHours).sort().forEach((userFullName) => {
    rawReportDataByUsers[userFullName] = {
      workOrder: data.workOrder,
      workHours: groupedWorkHours[userFullName],
    };
  });
  return rawReportDataByUsers;
};

@inject('uiStore', 'loginStore', 'reportStore', 't')
@observer
class BillingReportsHourlyIndex extends Component {
  constructor(props) {
    super(props);

    this._dateRangePicker = React.createRef();

    this.state = {
      activeTabIndex: 0,
      rawReportData: null,
      rawReportDataByUsers: null,
      reportWorkOrder: null,
      hourlyState: null,
      // dailyState: null,
    };
  }

  componentDidMount() {
    const { uiStore } = this.props;
    const workOrderId = uiStore.currentView.id;
    this.getWorkOrderReportByUser(workOrderId);
  }

  setHourlyState = (state) => {
    this.setState({
      hourlyState: state,
    });
  }

  // setDailyState = (state) => {
  //   console.log('Daily state in index: ', state);
  //   this.setState({
  //     dailyState: state,
  //   });
  // }

  getWorkOrderReportByUser = (workOrderId) => {
    const { t, reportStore } = this.props;
    const reportPromise = fromPromise(new Promise((resolve, reject) => reportStore.getWorkOrderReportByUser(workOrderId, resolve, reject)));

    this.setState({
      isLoading: true,
    });

    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          pending: () => {
          },
          rejected: (e) => {
            console.log('REJECTED: ', e);
            this.setState({
              isLoading: false,
            });
          },
          fulfilled: (data) => {
            const rawReportDataByUsers = reconstructReportDataByUsers(data);

            // Construct the defaultInputValue for dateRangePicker
            const durationRange = data.workOrder.duration.split('-');
            const rangeStart = moment(durationRange[0], 'DD.MM.YYYY');
            const rangeStartFormatted = rangeStart.format('dd DD.MM.YYYY');
            const rangeEnd = moment(durationRange[1], 'DD.MM.YYYY');
            const rangeEndFormatted = rangeEnd.format('dd DD.MM.YYYY');
            const dayRange = `${rangeStartFormatted} - ${rangeEndFormatted}`;

            const fromWeekNumber = rangeStart.isoWeek();
            const toWeekNumber = rangeEnd.isoWeek();
            // We do not want to add the '-' if there's no second different week number
            const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
            const weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;
            const dayRangeWithWeeks = `${weekNumberString} | ${dayRange}`;

            this.setState({
              rawReportData: data,
              rawReportDataByUsers,
              reportWorkOrder: data.workOrder,
              defaultInputValue: dayRangeWithWeeks,
              isLoading: false,
            });
          },
        });
      },
    );
  }

  // Get report data filtered by date.
  getWorkOrderReportByUserFiltered = (workOrderId, from, to) => {
    const { reportStore } = this.props;

    // Create a promise to retrieve the work order report.
    const reportPromise = fromPromise(new Promise((resolve, reject) => {
      // Call the reportStore method to get the work order report.
      reportStore.getWorkOrderReportByUserFiltered(workOrderId, from, to, resolve, reject);
    }));

    this.setState({
      isLoading: true,
    });

    // Wait for the promise to be fulfilled or rejected.
    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          rejected: (e) => {
            console.log('REJECTED: ', e); // Log the error if the promise is rejected.
            this.setState({
              isLoading: true,
            });
          },
          fulfilled: (data) => {
            const rawReportDataByUsers = reconstructReportDataByUsers(data);

            // Process the data
            this.setState({
              rawReportData: data,
              rawReportDataByUsers,
              reportWorkOrder: data.workOrder,
              isLoading: false,
            });
          },
        });
      },
    );
  }

  handleWeekClick = (weekNumber, days) => {
    this.setRangeAndWeek({ from: days[0], to: days[6] }, weekNumber, this.closeDateRangePicker);
  }

  handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.state);
    // this.setState(range);
    this.setRangeAndWeek(range);
  }

  // Alternative method to not trigger the query immediately on calendar day/week click, only on calendar close in order to reduce unnecessary queries
  // Not in use because the daypicker onBlur prop doesn't work properly (clicking two different days in a row triggers it?)
  // handleDayClick = (day) => {
  //   const { t } = this.props;
  //   const { range, from, to } = this.state;

  //   const oldRange = {
  //     from: range?.from || from,
  //     to: range?.to || to,
  //   };
  //   const newRange = DateUtils.addDayToRange(day, oldRange);

  //   // This finalValue is the rendered content of the text field that opens the daypicker
  //   let finalValue = '';

  //   if (newRange.from && newRange.to) {
  //     const rangeFrom = moment(newRange.from);
  //     const rangeTo = moment(newRange.to);

  //     let weekNumberString = '';
  //     // User selected a single day, we need to find the week numbers manually
  //     const fromWeekNumber = rangeFrom.isoWeek();
  //     const toWeekNumber = rangeTo.isoWeek();
  //     // We do not want to add the '-' if there's no second different week number
  //     const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
  //     weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;

  //     const dayRange = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
  //     finalValue = `${weekNumberString} | ${dayRange}`;
  //   } else {
  //     finalValue = 'Valitse päivät'; // Default value if range is not set
  //   }

  //   this.setState({
  //     range: newRange,
  //     from: newRange.from,
  //     to: newRange.to,
  //     inputValue: finalValue,
  //   });
  // }

  // Alternative method to not trigger the query immediately on calendar day/week click, only on calendar close in order to reduce unnecessary queries
  // Not in use because the daypicker onBlur prop doesn't work properly (clicking two different days in a row triggers it?)
  // handleWeekClick = (weekNumber, days) => {
  //   const { t } = this.props;
  //   // const { setRangeAndWeek: indexSetRangeAndWeek } = this.props;

  //   const newRange = { from: days[0], to: days[6] };
  //   let finalValue = '';
  //   const rangeFrom = moment(newRange.from);
  //   const rangeTo = moment(newRange.to);

  //   const weekNumberString = `${t('resource_availability.week_prefix')}${weekNumber}`;
  //   const dayRangeString = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
  //   finalValue = `${weekNumberString} | ${dayRangeString}`;

  //   this.setState({
  //     range: newRange,
  //     from: newRange.from,
  //     to: newRange.to,
  //     inputValue: finalValue,
  //   });
  // }

  setRangeAndWeek = (range, weekNumber = null, callback = null) => {
    const { t, uiStore } = this.props;
    const workOrderId = uiStore.currentView.id;
    let finalValue = '';

    // Check if the range has both 'from' and 'to' values
    if (range.from && range.to) {
      const rangeFrom = moment(range.from);
      const rangeTo = moment(range.to);

      let weekNumberString = '';
      if (!weekNumber) {
        // User selected a single day, we need to find the week numbers manually
        const fromWeekNumber = rangeFrom.isoWeek();
        const toWeekNumber = rangeTo.isoWeek();
        // We do not want to add the '-' if there's no second different week number
        const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
        weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;
      } else {
        // User selected a week by clicking on the number
        weekNumberString = `${t('resource_availability.week_prefix')}${weekNumber}`;
      }

      const dayRange = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
      finalValue = `${weekNumberString} | ${dayRange}`;
    } else {
      finalValue = 'Valitse päivät'; // Default value if range is not set
    }

    // Update component state with the final values
    this.setState({
      inputValue: finalValue,
      from: range.from,
      to: range.to,
    }, () => {
      // If both 'from' and 'to' values are available, call the corresponding function
      if (range.from && range.to) {
        const formattedFrom = moment(range.from).format('YYYY-MM-DD');
        const formattedTo = moment(range.to).format('YYYY-MM-DD');
        this.getWorkOrderReportByUserFiltered(workOrderId, formattedFrom, formattedTo);
      }

      // Call the optional callback function if provided
      if (callback) {
        callback();
      }
    });
  }

  // Alternative method to not trigger the query immediately on calendar day/week click, only on calendar close in order to reduce unnecessary queries
  // Not in use because the daypicker onBlur prop doesn't work properly (clicking two different days in a row triggers it?)
  // closeDateRangePicker = () => {
  //   const { range } = this.state;

  //   this._dateRangePicker.current.hideDayPicker();
  //   this.setRangeAndWeek(range);
  // }

  closeDateRangePicker = () => {
    this._dateRangePicker.current.hideDayPicker();
  }

  customDateRangePickerOverlay = ({ classNames, children, ...props }) => (
    <div
      className={classNames.overlayWrapper}
      {...props}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }} className={classNames.overlay}>
        {children}
        <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
          <button type="button" className="accept-button mdc-dialog__button mdc-button" onClick={() => this.closeDateRangePicker()}>Valmis</button>
        </div>
      </div>
    </div>
  );

  handleRestoreButtonClick = () => {
    const { uiStore } = this.props;
    const { defaultInputValue } = this.state;

    const workOrderId = uiStore.currentView.id;
    this.getWorkOrderReportByUser(workOrderId);
    this.setState({
      inputValue: defaultInputValue,
      from: null,
      to: null,
    });
  };

  selectTab(index) {
    this.setState({ activeTabIndex: index });
  }

  renderDateRangePickerHeading = () => {
    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      maxWidth: '400px',
    };


    return (
      <>
        {this.renderDateRangePicker('', styles)}
        <IconButton
          className="restore-icon"
          aria-label="restore"
          style={{
            height: '40px',
            width: '40px',
          }}
          onClick={this.handleRestoreButtonClick}
        >
          <SettingsBackupRestoreIcon
            className="restore-icon"
          />
        </IconButton>
      </>
    );
  }

  renderDateRangePicker = (label, styles, extraOverlayClassName = null) => {
    const {
      from,
      to,
      inputValue,
      defaultInputValue,
      reportWorkOrder,
    } = this.state;
    const modifiers = { start: from, end: to };
    const { duration } = reportWorkOrder; // this returns in a wrong format for example. '18.05.2023-30.05.2023'

    // Splitting the duration into beginning and end dates
    const [beginningDate, endDate] = duration.split('-');

    const workOrderEndDate = moment(endDate, 'DD.MM.YYYY');
    const today = moment();
    const dayPickerMonth = workOrderEndDate.isBefore(today, 'month') ? workOrderEndDate.toDate() : today.toDate();

    // Changing the dates to year.month.day format
    const [beginningDay, beginningMonth, beginningYear] = beginningDate.split('.');
    const [endDay, endMonth, endYear] = endDate.split('.');

    // Rearranging the date parts to the desired format
    const formattedBeginningDate = new Date(
      Number(beginningYear),
      Number(beginningMonth) - 1, // Month in JavaScript Date object is 0-based
      Number(beginningDay),
    );
    const formattedEndDate = new Date(
      Number(endYear),
      Number(endMonth) - 1, // Month in JavaScript Date object is 0-based
      Number(endDay),
    );

    // Function to check if a day is within the duration
    const isDayWithinDuration = (day) => day >= formattedBeginningDate && day <= formattedEndDate;

    return (
      <DayPickerInput
        ref={this._dateRangePicker}
        value={inputValue || defaultInputValue}
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                autoComplete: 'off',
                className: 'daypicker-input',
                type: 'button',
                label,
                style: { ...styles, width: '100%' },
              }}
            />
          )
        }
        inputProps={{
          ref: null, // This is needed to remove a "Function components cannot be given refs" warning
          className: '',
          style: { ...styles, width: '100%', textTransform: 'capitalize' },
          name: 'date-range',
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker range-daypicker',
          showWeekNumbers: true,
          selectedDays: [from, { from, to }],
          modifiers,
          onDayClick: this.handleDayClick,
          onWeekClick: this.handleWeekClick,
          showOutsideDays: true,
          disabledDays: ((day) => !isDayWithinDuration(day)),
          month: dayPickerMonth,
          // onBlur: this.closeDateRangePicker,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        overlayComponent={this.customDateRangePickerOverlay}
        keepFocus={false}
        hideOnDayClick={false}
        format="dd DD.MM.YYYY"
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
      />
    );
  }


  renderReportHeader() {
    const { uiStore: { currentUser } } = this.props;
    const { rawReportData: { workOrder }, inputValue, defaultInputValue } = this.state;

    const grid = 6;

    return (
      <>
        <Grid
          container
          spacing={2}
          className="billing-report-header pdf-header"
          justifyContent="space-between"
        >
          <Grid item xs={4}>
            <span>{moment().format('DD/MM/YYYY')}</span>
          </Grid>
          <Grid item xs={4}>
            <span style={{ textTransform: 'uppercase' }}>Tuntiraportit</span>
            <span>{` - ${currentUser?.accountInfo?.officialName}`}</span>
          </Grid>
          <Grid item xs={4}>
            {/* <span>{currentUser?.accountInfo?.accountPicture}</span> */}
            <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }} className="billing-report-header">
          <Grid item md={grid} xs={12} className="report-purchaser">
            <div className="header-label">Tilaaja</div>
            <div className="header-value">{workOrder?.purchaser}</div>
          </Grid>

          <Grid item md={grid} xs={12} className="daypicker-input-value show-print">
            <div className="header-label">Ajalta</div>
            <div className="header-value capitalized">{inputValue || defaultInputValue}</div>
          </Grid>
          <Grid item md={grid} xs={12} className="report-duration hidden-print">
            <div className="header-label" style={{ lineHeight: 1 }}>Ajalta</div>
            <div
              className="header-value daypicker-value"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {this.renderDateRangePickerHeading()}
            </div>
          </Grid>

          <Grid item md={grid} xs={12} className="report-location">
            <div className="header-label">Kohde</div>
            <div className="header-value">{workOrder?.location}</div>
          </Grid>
          <Grid item md={grid} xs={12} className="report-name">
            <div className="header-label">Nimi</div>
            <div className="header-value">{workOrder?.name}</div>
          </Grid>
        </Grid>
      </>
    );
  }

  renderBothReportsSummary() {
    const { rawReportData, hourlyState } = this.state;

    return (
      <>
        {/* Copied styles from billing-report-header and header-value classes to quickly get the same appearance but without changes when printing */}
        <div
          className="print-margin-bottom-20 billing-user-heading-wrapper"
        >
          <div className="billing-user-heading">
            Yhteenveto
          </div>
        </div>

        <BillingReportsHourly
          summaryOnly
          mode="tabReport"
          hidePrint
          hideHeader
          hideUserCol
          height="fit-content"
          rawReportData={rawReportData}
          setIndexState={this.setHourlyState}
          indexState={hourlyState}
        />

        {/* <br />

        <BillingReports
          summaryOnly
          mode="tabReport"
          hidePrint
          hideHeader
          // hideUserCol
          getWorkOrderReportByUserFiltered={this.getWorkOrderReportByUserFiltered}
          rawReportData={rawReportData}
        /> */}
      </>
    );
  }

  renderBothReportsByUsers() {
    const { rawReportDataByUsers, hourlyState } = this.state;

    return (
      <>
        {Object.keys(rawReportDataByUsers).map((userFullName) => (
          // If there's rawReportDataByUsers[userFullName], render
          // Otherwise, return null
          <>
            <div className="page-breaker" />
            {/* Copied inline styles from billing-report-header and header-value classes to quickly get the same appearance but without changes when printing */}
            <div
              className="print-margin-bottom-20 billing-user-heading-wrapper"
            >
              <div className="billing-user-heading">
                {userFullName}
              </div>
            </div>

            <BillingReportsHourly
              mode="tabReport"
              hideHeader
              hideUserCol
              height="fit-content"
              rawReportData={rawReportDataByUsers[userFullName]}
              setIndexState={this.setHourlyState}
              indexState={hourlyState}
            />

            {/* <br /> */}
            <div style={{ margin: '20px' }} />

            <div className="print-60-width">
              <BillingReports
                mode="tabReport"
                hideHeader
                hideUserCol
                getWorkOrderReportByUserFiltered={this.getWorkOrderReportByUserFiltered}
                rawReportData={rawReportDataByUsers[userFullName]}
              />
            </div>
          </>
        ))}
      </>
    );
  }

  render() {
    const { uiStore, uiStore: { mobileMode } } = this.props;
    const {
      activeTabIndex,
      rawReportData,
      reportWorkOrder,
      // dailyState,
      hourlyState,
      inputValue,
      defaultInputValue,
      from,
      to,
      isLoading,
    } = this.state;

    return (
      <>
        <DeepViewHeader
          content={reportWorkOrder?.name}
          showPreviousView={uiStore.showEmployerBilling}
        />

        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          {/* <GridInner
            style={{ rowGap: '10px' }}
          > */}
          <Grid
            span={12}
          >
            <div
              className="view-heading"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
                <span
                  role="button"
                  onClick={() => this.selectTab(0)}
                  className={`heading-tab ${activeTabIndex === 0 ? 'active' : 'inactive'}`}
                  style={{ margin: 0 }}
                >
                  Raportti
                </span>
                <span
                  role="button"
                  onClick={() => this.selectTab(1)}
                  className={`heading-tab ${activeTabIndex === 1 ? 'active' : 'inactive'}`}
                  style={{ margin: 0 }}
                >
                  Työkohtainen raportti
                </span>
                <span
                  role="button"
                  onClick={() => this.selectTab(2)}
                  className={`heading-tab ${activeTabIndex === 2 ? 'active' : 'inactive'}`}
                  style={{ margin: 0 }}
                >
                  Päiväkohtainen raportti
                </span>
              </div>
            </div>
          </Grid>
          <Grid
            span={12}
          >
            {activeTabIndex === 1 && (
              <BillingReportsHourly
                mode="tabReport"
                getWorkOrderReportByUserFiltered={this.getWorkOrderReportByUserFiltered}
                getWorkOrderReportByUser={this.getWorkOrderReportByUser}
                rawReportData={rawReportData}
                setIndexState={this.setHourlyState}
                indexState={hourlyState}
                inputValue={inputValue || defaultInputValue}
                // These values aren't needed in the BillingReportsHourly
                // In BillingReports, we may use either BillingReports's date picker or this BillingReportHourlyIndex's date picker
                // In BillingReportsHourly, we can always use the BillingReportHourlyIndex's date picker as a prop
                // from={from}
                // to={to}
                setRangeAndWeek={this.setRangeAndWeek}
                handleRestoreButtonClick={this.handleRestoreButtonClick}
                renderDateRangePickerHeading={this.renderDateRangePickerHeading}
                isLoading={isLoading}
              />
            )}
            {activeTabIndex === 2 && (
              <BillingReports
                mode="tabReport"
                getWorkOrderReportByUserFiltered={this.getWorkOrderReportByUserFiltered}
                getWorkOrderReportByUser={this.getWorkOrderReportByUser}
                rawReportData={rawReportData}
                inputValue={inputValue || defaultInputValue}
                from={from}
                to={to}
                hourlyIndex
                setRangeAndWeek={this.setRangeAndWeek}
                handleRestoreButtonClick={this.handleRestoreButtonClick}
                isLoading={isLoading}
              />
            )}
            {activeTabIndex === 0 && (
              <>
                <div style={{ textAlign: 'right', marginBottom: mobileMode ? 0 : '-70px' }}>
                  <ReactToPrint
                    // NOTE: Do NOT pass an `onClick` prop to the root node of the returned component as it will be overwritten.
                    trigger={() => (
                      <IconButton
                        aria-label="settings"
                        className="small"
                      >
                        <Icon style={{ color: 'black' }} fontSize="large">
                          print
                        </Icon>
                      </IconButton>
                    )}
                    content={() => this.componentRef}
                  />
                </div>

                {/* eslint-disable-next-line no-return-assign */}
                <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
                  {rawReportData ? (
                    <div className="print-margin">
                      {this.renderReportHeader()}
                      {!isLoading ? (
                        <>
                          {this.renderBothReportsSummary()}
                          {this.renderBothReportsByUsers()}
                        </>
                      ) : (
                        <div style={{ textAlign: 'center', padding: '20px' }}>
                          <LoadingSpinner color="black" />
                        </div>
                      )}
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <LoadingSpinner color="black" />
                    </div>
                  )}
                  {/* {rawReportData && this.renderBothReportsByUsers()} */}
                </div>
              </>
            )}
          </Grid>
          {/* </GridInner> */}
        </Grid>
      </>
    );
  }
}

export default BillingReportsHourlyIndex;
