import { observable, action } from 'mobx';

class TimelogViewStore {
  @observable showDraftsOnly = false;

  // Used in employer update mode
  @observable showEmployerUpdatedOnly = false;

  // Used in employer update mode
  // @observable employerUpdateComments = {};

  @action setShowDraftsOnly = (value = true) => {
    this.showDraftsOnly = value;
  };

  @action setShowEmployerUpdatedOnly = (value = true) => {
    this.showEmployerUpdatedOnly = value;
  }

  // ARRAY VERSION
  // @action setEmployerUpdateComment = (employerUpdateComment) => {
  //   const employerUpdateCommentIndex = this.employerUpdateComments.findIndex((existingComment) => existingComment.workHourId === employerUpdateComment);
  //   if (employerUpdateComment !== -1) {
  //     this.employerUpdateComments[employerUpdateCommentIndex] = employerUpdateComment;
  //   } else {
  //     this.employerUpdateComments.push(employerUpdateComment);
  //   }
  // }

  // OBJECT VERSION
  // @action setEmployerComment = (employerUpdateComment) => {
  //   this.employerUpdateComments[employerUpdateComment.workHourId] =
  // }
}

export default TimelogViewStore;
