import { action, observable } from 'mobx';
import actionCable from 'actioncable';

class ActionCableStore {
  @observable cable = null;

  @observable cableOn = false;

  constructor() {
    this.setCable();
  }

  @action setCable() {
    if (process.env.NODE_ENV === 'development') {
      this.cable = actionCable.createConsumer('ws://localhost:3001/api/cable');
    } else {
      this.cable = actionCable.createConsumer('/api/cable');
    }

    if (this.cable.connection?.open()) {
      this.cableOn = true;
    }
  }

  @action subscribe(channelData, handleData) {
    switch (channelData.channel) {
      case 'ChatRoomChannel':
        return this.cable.subscriptions.create(channelData, {
          received: (response) => {
            handleData(response);
          },
          updateTyping(chatId, status) {
            return this.perform('typing_status', {
              chat_id: chatId,
              status,
            });
          },
        });
      default:
        return this.cable.subscriptions.create(channelData, {
          received: (response) => {
            handleData(response);
          },
          // ping: () => this.perform('ping', {}),
          ping() {
            return this.perform('ping', {});
          },
        });
    }
    //   if (channelData.channel === 'ChatRoomChannel') {
    //     return this.cable.subscriptions.create(channelData, {
    //       received: (response) => {
    //         handleData(response);
    //       },
    //       updateTyping(chatId, status) {
    //         return this.perform('typing_status', {
    //           chat_id: chatId,
    //           status,
    //         });
    //       },
    //     });
    //   } else {
    //     return this.cable.subscriptions.create(channelData, {
    //       received: (response) => {
    //         handleData(response);
    //       },
    //     });
    //   }
  }

  @action unsubscribe(sub) {
    if (this.cable) {
      this.cable.subscriptions.remove(sub);
    }
  }

  @action disconnectConsumer() {
    if (this.cable !== null) {
      this.unsubscribeAllChannels();
      this.cable.disconnect();
      this.cable = null;
      this.cableOn = false;
    }
  }

  @action unsubscribeAllChannels() {
    // this.cable.subscriptions.subscriptions.forEach(sub => {
    //   const channeltoObj = JSON.parse(sub.identifier)
    //   console.log(channeltoObj.channel)
    // });
    this.cable.subscriptions.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}

export default ActionCableStore;
