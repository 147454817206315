import { observable, action } from 'mobx';
import moment from 'moment';

export default class CertificateType {
  @observable id;

  @observable identifierOne;

  @observable identifierTWo;

  @observable description;

  @observable categoryType;

  // t.references :certificate, index: true
  // t.references :created_by, index: true, foreign_key: { to_table: :users }
  // t.integer :type
  // t.date :issue_date
  // t.date :expiration_date
  // t.text :description

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      typeCategory: o.type_category,
      identifierOne: o.identifier_one,
      identifierTwo: o.identifier_two,
      description: o.description,
      createdAt: moment(o.created_at),
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const certificateCategory = new CertificateType({});
    certificateCategory.updatePropertiesFromJson(object);
    return certificateCategory;
  }

  static fromJson(json) {
    return CertificateType.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    return {
      id: o.id,
      identifier_one: o.identifierOne,
      identifier_two: o.identifierTwo,
      description: o.description,
      type_category: o.typeCategory,
      // work_task_users_attributes: o.workTaskUsers.map(WorkTaskUser.toJson),
    };
  }
}
