export const w100 = {
  width: '100%',
};
export const wmax = {
  maxWidth: '800px',
};

export const appcontentsize = { ...w100, ...wmax };

export const buttonColors = {
  yellow: {
    border: '1px solid #E9B801',
    color: '#E9B801',
  },
  orange: {
    border: '1px solid #FD6900',
    color: '#FD6900',
  },
  green: {
    border: '1px solid #6ABB6A',
    color: '#6ABB6A',
  },
};
export const buttonStyle = {
  borderRadius: 0,
  fontSize: '12px',
  width: '10em',
};

export const leftPadding = {
  paddingLeft: '1rem',
};

const styles = {
  appcontentsize,
  buttonColors,
  buttonStyle,
  leftPadding,
  w100,
  wmax,
};

export default styles;
