import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
import Product from '../models/product/Product';
import UserProductCollection from '../models/product/UserProductCollection';

class ProductStore {
  @observable products = fromPromise.resolve([]);

  @observable productDisplayColumns = [];

  constructor(uiStore, requests) {
    this.requests = requests;
    this.uiStore = uiStore;
  }

  @action setProductDisplayColumns(column, display) {
    this.productDisplayColumns[column] = display;
  }

  @action updateProduct(product, resolve, reject) {
    return this.requests.Products[!product.id ? 'create' : 'update'](product).then((result) => {
      const newProduct = Product.fromJsonProperties(result);

      if (product.id) {
        // Try to replace the already-loaded old product with the new one
        const oldProductIndex = this.products.value.findIndex((oldProduct) => oldProduct.id === newProduct.id);

        if (oldProductIndex !== -1) {
          this.products.value.splice(oldProductIndex, 1, newProduct);
        }
      } else {
        // Push the new product to the list
        this.products.value.push(newProduct);
      }

      resolve(newProduct);
    }).catch((err) => reject(err));
  }

  // @action updateUserProductCollection(userProductCollection, resolve, reject) {
  //   const { currentUser } = this.uiStore;
  //   return this.requests.UserProductCollections[!userProductCollection.id ? 'create' : 'update'](currentUser, userProductCollection).then((result) => {
  //     console.log('Got result in update/create: ', result);
  //     // userProductCollection.updatePropertiesFromJson(result);
  //     const newUserProductCollection = UserProductCollection.fromJsonProperties(result);

  //     console.log('After turning into object: ', newUserProductCollection);
  //     resolve(newUserProductCollection);
  //   }).catch((err) => reject(err));
  // }

  @action updateUserProductCollection(userProductCollection, resolve, reject) {
    const ctxUser = this.uiStore.currentUser;

    return this.requests.UserProductCollections[!userProductCollection.id ? 'create' : 'update'](ctxUser, userProductCollection).then((result) => {
      const newUserProductCollection = UserProductCollection.fromJsonProperties(result);
      const updatedUserProductCollections = [...ctxUser.userProductCollections];
      const foundIndex = updatedUserProductCollections.findIndex((el) => el.id === newUserProductCollection.id);

      if (foundIndex !== -1) {
        updatedUserProductCollections.splice(foundIndex, 1, newUserProductCollection);
      } else {
        updatedUserProductCollections.push(newUserProductCollection);
      }
      ctxUser.userProductCollections.replace(updatedUserProductCollections);

      resolve(newUserProductCollection);
    }).catch((err) => reject(err));
  }

  findProducts = () => {
    this.products = fromPromise(new Promise((resolve) => this.requests.Products.getAll().then((json) => {
      const products = json.map((product) => Product.fromJsonProperties(product));
      resolve(products);
    })));
  };

  getProductReport = (workOrderId, resolve, reject) => this.requests.WorkOrders.getProductReport(workOrderId).then((json) => resolve(json)).catch((err) => reject(err));

  findUserProductCollections = (user) => {
    const ctxUser = user || this.uiStore.currentUser;

    return this.requests.UserProductCollections.getAll(ctxUser)
      .then(
        action((json) => {
          const userProductCollections = json.map((product) => UserProductCollection.fromJsonProperties(product));
          ctxUser.userProductCollections.replace([
            ...userProductCollections,
          ]);
        }),
      )
      .catch((err) => {
        console.log('error', err);
      });
  }

  @action deleteUserProductCollectionFile(signedId, userProductCollectionId) {
    const ctxUser = this.uiStore.currentUser;
    console.log('In store: trying to delete file by signed id ', signedId);
    return this.requests.UserProductCollections.purgeFile(ctxUser, userProductCollectionId, signedId).then(() => {
      console.log('File purged in store, updating prop');
      // const foundWo = this.uiStore.currentUser.workOrders.find((wo) => wo.id === workOrderId);
      // const foundIndex = foundWo.attachments.findIndex((attachment) => attachment.id === id);
      // const attachments = [...foundWo.attachments];
      // attachments[foundIndex] = { ...attachments[foundIndex], fileURL: null, filename: null };

      const updatedUserProductCollections = [...ctxUser.userProductCollections];
      const foundCollection = updatedUserProductCollections.find((el) => el.id === userProductCollectionId);

      if (foundCollection) {
        const newFiles = foundCollection.files.filter((file) => file.signedId !== signedId);
        foundCollection.changeAttribute('files', newFiles);
        console.log('Collection prop updated');
      } else {
        console.log('Something went wrong with purging file: user product collection not found');
      }
    });
  }
}

export default ProductStore;
