/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';
import { Typography } from '@rmwc/typography';
import { Button } from '@material-ui/core';

import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/typography/dist/mdc.typography.css';

import { appcontentsize } from '../styles/inline-styles';
import TimelogView from './TimelogView';

import bankIcon from '../assets/images/tuntipankki.svg';
import Absences from '../absences/absences';

const indexToStatus = [
  'current',
  'next',
  'archived',
];

const renderWorkOrderView = (items, noDataText, tripItems, workOrderTrips) => {
  if (items == null || items.length === 0) {
    return <p>{noDataText}</p>;
  }

  return (
    <div style={appcontentsize}>
      {items.map((woItem) => (
        <TimelogView
          disabled={woItem.disabled}
          locked={woItem.locked}
          from={woItem.from}
          key={`woitem-${woItem.key}`}
          periodId={woItem.periodId}
          to={woItem.to}
          workOrder={woItem.workOrder}
          periodFrom={woItem.periodFrom}
          periodTo={woItem.periodTo}
          workOrderTrips={workOrderTrips}
          allowanceAutofillReminder={woItem.allowanceAutofillReminder}
        />
      ))}
    </div>
  );
};

@inject('uiStore', 'timelogStore', 't', 'loginStore', 'workOrderTripStore')
@observer
class Timelog extends Component {
  constructor(props) {
    super(props);
    const { timelogStore, workOrderTripStore, uiStore: { currentUser } } = this.props;

    this.state = {
      activeTab2: 0,
    };

    timelogStore.getSalaryPeriods(currentUser.workOrders);
    timelogStore.getSalaryPeriodAttachments();
    workOrderTripStore.categorizeWorkOrderTrips(currentUser.workOrderTrips);
  }

  getArchivedWorkOrders() {
    const { loginStore } = this.props;
    loginStore.findArchivedWorkOrders();
  }

  renderTimeBank = () => {
    const { uiStore: { currentUser: { workHourBank } } } = this.props;

    return (
      <Grid
        style={{
          marginTop: '10px',
          maxWidth: '800px',
          width: '100%',
          backgroundColor: 'rgb(10, 19, 30)',
        }}
      >
        <GridInner
          style={{
            padding: '0 16px',
          }}
        >
          <GridCell
            span={12}
            style={{
              display: 'flex',
              // marginBottom: '15px',
            }}
          >
            <Icon
              style={{
                // alignSelf: 'center',
              }}
              icon={{
                // icon: 'savings',
                icon: bankIcon,
                size: 'small',
              }}
            />
            <Typography
              use="subtitle1"
              style={{
                fontSize: '1rem',
                paddingLeft: '10px',
              }}
            >
              Tuntipankki
            </Typography>
          </GridCell>
          <GridCell span={12}>
            <GridInner>
              <GridCell
                desktop={10}
                phone={3}
              >
                <Typography
                  style={{ marginTop: '-1rem' }}
                  tag="div"
                  theme="textHintOnDark"
                  use="body2"
                >
                  Käytettävissä olevat
                </Typography>
              </GridCell>
              <GridCell
                desktop={2}
                phone={1}
                style={{
                  color: '#5db7f3',
                }}
              >
                <Typography
                  style={{ marginTop: '-1rem' }}
                  tag="div"
                  use="body2"
                >
                  {workHourBank.currentBalance ? `${(Math.round(workHourBank.currentBalance * 100) / 100).toFixed(2)} h` : '0.00 h'}
                </Typography>
              </GridCell>
            </GridInner>
          </GridCell>
          <GridCell span={12}>
            <GridInner>
              <GridCell
                desktop={10}
                phone={3}
              >
                <Typography
                  style={{ marginTop: '-1rem' }}
                  tag="div"
                  theme="textHintOnDark"
                  use="body2"
                >
                  Palkkakauden alusta kertyneet
                </Typography>
              </GridCell>
              <GridCell
                desktop={2}
                phone={1}
                className="green-text"
              >
                <Typography
                  style={{ marginTop: '-1rem' }}
                  tag="div"
                  use="body2"
                >
                  {`${(Math.round(workHourBank.calcAmountToAdd * 100) / 100).toFixed(2)} h`}
                </Typography>
              </GridCell>
            </GridInner>
          </GridCell>
        </GridInner>
      </Grid>
    );
  };

  // eslint-disable-next-line class-methods-use-this
  renderAbsences() {
    return (
      <Absences />
    );
  }

  render() {
    const {
      props: {
        t, timelogStore: { workOrdersWithMeta }, workOrderTripStore: { workOrderTrips },
        uiStore: { currentUser, currentUser: { currentAccount, accountInfo } },
        loginStore: { getArchivedWorkOrdersDisabled, archivedOffset },
      },
      state: { activeTab2 },
    } = this;

    // TODO: The use of archived offset here is a hack to re-render the view when workOrdersWithMeta.value changes
    // Using promise directly in the state is problematic, here we basically update another query's promise's results
    // We should really use the promise's result in the state instead
    // See: LoginStore.findArchivedWorkOrders()
    console.log('Archived offset: ', archivedOffset);

    return workOrdersWithMeta.case({
      pending: () => <p>Ladataan palkkakausia...</p>,
      rejected: () => <p>Palkkakausia ei juuri nyt pystytty lataamaan!</p>,
      fulfilled: () => (
        <>
          <TabBar
            activeTabIndex={activeTab2}
            onActivate={(evt) => this.setState({ activeTab2: evt.detail.index })}
            style={{ maxWidth: '800px' }}
          >
            <Tab>
              Nykyinen palkkakausi (
              {workOrdersWithMeta.value.current?.length}
              )
            </Tab>
            <Tab>
              Tulevat (
              {workOrdersWithMeta.value.next?.length}
              )
            </Tab>
            <Tab icon="history" />
          </TabBar>

          {renderWorkOrderView(workOrdersWithMeta.value[indexToStatus[activeTab2]], t(`timelog.noWorkOrders.${indexToStatus[activeTab2]}`), workOrdersWithMeta.value[`${indexToStatus[activeTab2]}_trips`], workOrderTrips)}

          {accountInfo.absencesEnabled && currentAccount.absences_enabled && (
            <div style={appcontentsize}>
              {this.renderAbsences()}
            </div>
          )}

          {activeTab2 === 2 && (
            // renderWorkOrderView = (items, noDataText, tripItems, workOrderTrips)
            <>
              {/* {currentUser.archivedWorkOrders.length > 0 && renderWorkOrderView(currentUser.archivedWorkOrders, '', null, workOrderTrips)} */}
              {!getArchivedWorkOrdersDisabled && (
                <div style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                  <Button {...{
                    type: 'button',
                    className: 'mdc-button',
                    onClick: () => this.getArchivedWorkOrders(),
                  }}
                  >
                    {t('calendar.employee.show_more')}
                  </Button>
                </div>
              )}
            </>
          )}

          { currentUser.workHourBankEnabled && activeTab2 === 0 && this.renderTimeBank() }
        </>
      ),
    });
  }
}

export default Timelog;
