/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';

import CloseCallReportCard from './CloseCallReportCard.jsx';
import ShowCloseCallReportDialog from './ShowCloseCallReportDialog.jsx';
import LoadingSpinner from './../shared/LoadingSpinner.jsx';

const indexToStatus = [
  'ongoing',
  'past',
  'upcomingAll',
  'upcomingShort',
];

const renderCloseCallReportCards = (closeCallReports, openShowDialog) => {

  console.log('CLOSE CALL REPORTS: ', closeCallReports);
  if (!closeCallReports) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <LoadingSpinner color="black" />
      </div>
    );
  } else if (closeCallReports.length === 0) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div>Lähetettyjä ilmoituksia ei löytynyt</div>
      </div>
    )
  }

  return (
    <GridInner
      style={{
        rowGap: '4px'
      }}
    >
      {closeCallReports.map((closeCallReport) => (
        <GridCell
          desktop={6}
          tablet={4}
          key={`woitem-${closeCallReport.id}`}
        >
          <CloseCallReportCard
            closeCallReport={closeCallReport}
            openShowDialog={openShowDialog}
            employer={true}
            // selectedFilter={selectedFilter}
          />
        </GridCell>


      ))}
    </GridInner>
  );
}

@inject('uiStore', 'loginStore', 't', 'closeCallReportStore')
@observer
class EmployerCloseCallReports extends Component {
  constructor(props) {
    super(props);
    // const { closeCallReportStore, uiStore: { currentUser } } = this.props;

    this.state = {
      showAddNewWorkOrder: false,
      closeCallReports: [],
      openCloseCallReport: {},
    };
    // employerWorkOrderStore.getPurchasers(currentUser);
  }

  componentDidMount() {
    const { uiStore: { currentView }, closeCallReportStore } = this.props;

    closeCallReportStore.getEmployer().then((closeCallReports) => {
      this.setState({
        closeCallReports: closeCallReports,
      })
    });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (currentView.selectedFilter) {
      this.setState({
        selectedFilter: currentView.selectedFilter,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  newCloseCallReport = (newReport) => {
    this.setState((prevState) => ({
      closeCallReports: [...prevState.closeCallReports, newReport],
    }))
  }

  updateCloseCallReport = (updatedReport) => {
    const { closeCallReports } = this.state;
    // Remove the old report from state
    const updatedCloseCallReports = closeCallReports.filter((closeCallReport) => closeCallReport.id !== updatedReport.id);

    // Add the updated version of the old report into the state through deconstruction
    this.setState((prevState) => ({
      closeCallReports: [updatedReport, ...updatedCloseCallReports],
    }))
  }

  toggleAddNewWorkOrder = () => {
    const { showAddNewWorkOrder } = this.state;

    if (!showAddNewWorkOrder) {
      this.setState({
        showAddNewWorkOrder: true,
      });
    } else {
      this.setState({
        showAddNewWorkOrder: false,
      });
    }
  }

  handleClose = () => {
    this.setState({
      showDialogOpen: false,
    })
  }

  openDialog = (closeCallReport) => {
    this.setState({
      showDialogOpen: true,
      openCloseCallReport: closeCallReport,
    })
  }

  render() {
    const { t, uiStore } = this.props;
    const { selectedFilter, width, showDialogOpen, closeCallReports, openCloseCallReport } = this.state;

    return (
      <Grid
        style={{ width: '100%', height: '100%', padding: '16px 0px' }}
      >
        <GridInner
          style={{ rowGap: '0px' }}
        >
          <GridCell
            style={{ padding: '0px 16px' }}
            desktop={12}
            tablet={8}
            phone={4}
          >
              {renderCloseCallReportCards(closeCallReports, this.openDialog)}
          </GridCell>

          <ShowCloseCallReportDialog
            open={showDialogOpen}
            handleClose={this.handleClose}
            closeCallReport={openCloseCallReport || {}}
            employerView={true}
            styles={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' }}
            updateCloseCallReport={this.updateCloseCallReport}
          />
        </GridInner>
      </Grid>
    );
  }
}

export default EmployerCloseCallReports;
