import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import Button from '@mui/material/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  // TextField,
  // Button,
  // List,
  // ListItem,
  // ListItemText,
  Checkbox,
} from '@material-ui/core';
// import Typography from '@mui/material/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Button from '@material-ui/core/Button';
// import ListItemText from '@material-ui/core/ListItemText';

@inject('uiStore', 't')
@observer
class ReportSettingsDialog extends Component {
  render() {
    const {
      open,
      handleClose,
      columns,
      toggleColumnVisibility,
    } = this.props;

    return (
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>Raportin asetukset</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Valitse sarakkeet
          </DialogContentText>

          <List className="primary-color">
            {columns.map((column) => (
              <ListItem
                button
                onClick={() => toggleColumnVisibility(column.name)}
                key={column.name}
                style={{ padding: 0 }}
              >
                {/* <ListItemText primary={column.label} /> */}
                {/* For some reason var(--mdc-theme-primary) color, that is supposed to be #FFC900, is purple when used here */}
                {/* eslint-disable-next-line no-unneeded-ternary */}
                <Checkbox checked={column.options?.display === false ? false : true} readOnly name={column.name} style={{ color: '#FFC900' }} />
                <span style={{ fontSize: '16px' }}>{column.label}</span>
              </ListItem>
            ))}
          </List>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} className="employer-reject-button">
            Sulje
          </Button>
          {/* <Button onClick={handleClose} color="primary">
            Subscribe
          </Button> */}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ReportSettingsDialog;
