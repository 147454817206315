import React from 'react';
import { Icon } from '@rmwc/icon';

import '@rmwc/icon/icon.css';

const PhoneNumber = (props) => {
  const { number } = props;
  return (
    <a
      href={`tel: ${number}`}
      style={{
        alignItems: 'center',
        color: 'var(--mdc-theme-primary)',
        display: 'flex',
        justifyContent: 'center',
        textDecoration: 'none',
        fontSize: '14px',
      }}
    >
      <Icon icon="call" />
      &nbsp;
      {number}
    </a>
  );
};

export default PhoneNumber;
