import { observable, action } from 'mobx';
import moment from 'moment';

export default class CertificateValidity {
  @observable id;

  @observable certificateId;

  @observable type;

  // @observable description;

  @observable issueDate;

  @observable expirationDate;

  // t.references :certificate, index: true
  // t.references :created_by, index: true, foreign_key: { to_table: :users }
  // t.integer :type
  // t.date :issue_date
  // t.date :expiration_date
  // t.text :description

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      certificateId: o.certificate_id,
      validityType: o.validity_type,
      issueDate: o.issue_date && moment(o.issue_date),
      expirationDate: o.expiration_date && moment(o.expiration_date),
      description: o.description,
      createdAt: moment(o.created_at),
      file: o.file,
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const certificateValidity = new CertificateValidity({});
    certificateValidity.updatePropertiesFromJson(object);
    return certificateValidity;
  }

  static fromJson(json) {
    return CertificateValidity.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    console.log('VALIDITY TO BE TURNED INTO JSON: ', o);
    return {
      id: o.id,
      certificate_id: o.certificateId,
      validity_type: o.validityType,
      issue_date: o.issueDate?.toDate().toISOString(),
      expiration_date: o.expirationDate?.toDate().toISOString(),
      file: o.fileSignedId,
      // work_task_users_attributes: o.workTaskUsers.map(WorkTaskUser.toJson),
    };
  }
}
