/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle, List, ListItem, IconButton,
} from '@material-ui/core';
import PlaceIcon from '@material-ui/icons/Place';
import moment from '../utils/moment';

@inject('timelogStore', 'uiStore', 't')
@observer
class WorkOrderInfoDialog extends Component {
  renderAddress = (street, city, zipCode, label, labelPrefix = null) => {
    let mapsQuery = '';

    const mapsAddress = `${street}%2C+${city}`;
    const mapsParams = mapsAddress.replace(/ /g, '+');

    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      mapsQuery = `http://maps.apple.com/?q=${mapsParams}`;
    } else {
      mapsQuery = `http://maps.google.com/?q=${mapsParams}`;
    }

    return (
      <>
        {(mapsQuery !== '') && (
          <span style={{ width: '100%' }}>
            {mapsQuery !== '' && (
              <a
                href={!street && !city && !zipCode ? null : mapsQuery}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'flex', justifyContent: 'space-between', gap: '10px' }}
              >
                <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', color: 'black' }}>
                  <span className="readonly-dialog-label">{labelPrefix ? `${labelPrefix}: ${label}` : label}</span>
                  <span>
                    {!street && !city && !zipCode ? 'Osoitetta ei syötetty' : ''}
                    {street ? street : ''}
                    {(street && (zipCode || city)) ? ', ' : ''}
                    {zipCode ? zipCode : ''}
                    {(zipCode && city) ? ', ' : ''}
                    {city ? city : ''}
                  </span>
                </div>

                <IconButton
                  aria-label="location"
                  style={{
                    width: '40px',
                    height: '40px',
                    padding: '0px',
                    fontSize: '20px',
                    border: '1px grey solid',
                    marginRight: '15px',
                  }}
                >
                  <PlaceIcon />
                </IconButton>
              </a>
            )}
          </span>
        )}
      </>
    );
  };

  render() {
    const { open, onClose, workOrderInfo } = this.props;

    return (
      <Dialog
        className="employer-delete-modal work-order-trip-card-dialog"
        open={open}
        onClose={(evt) => {
          evt.stopPropagation();
          onClose();
        }}
      >
        <DialogTitle>{workOrderInfo?.name}</DialogTitle>
        <DialogContent
          style={{ fontSize: '16px', color: 'black' }}
        >
          <List sx={{ width: '100%', maxWidth: 500 }}>
            <ListItem>
              <span>
                <span
                  style={{ display: 'flex' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', color: 'black' }}>
                    <span className="readonly-dialog-label">Kesto</span>
                    <span>
                      {`${moment(workOrderInfo?.from).format('DD.MM.YY') || '?'} - ${moment(workOrderInfo?.to).format('DD.MM.YY') || '?'}`}
                    </span>
                  </div>
                </span>
              </span>
            </ListItem>

            <ListItem>
              <span>
                <span
                  style={{ display: 'flex' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', color: 'black' }}>
                    <span className="readonly-dialog-label">Lisätietoja</span>
                    <span style={{ whiteSpace: 'break-spaces', marginTop: '10px' }}>
                      {workOrderInfo?.accomodation_address_description || '-'}
                    </span>
                  </div>
                </span>
              </span>
            </ListItem>

            <ListItem>
              <span>
                <span
                  style={{ display: 'flex' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', color: 'black' }}>
                    <span className="readonly-dialog-label">Lisätietoja (ei näy työntekijöille)</span>
                    <span style={{ whiteSpace: 'break-spaces', marginTop: '10px' }}>
                      {workOrderInfo?.admin_info || '-'}
                    </span>
                  </div>
                </span>
              </span>
            </ListItem>

            <ListItem>
              {this.renderAddress(workOrderInfo?.accomodation?.street, workOrderInfo?.accomodation?.city, workOrderInfo?.accomodation?.zip_code, workOrderInfo?.accomodation?.name, 'Majoitus')}
            </ListItem>

            <br />

            <ListItem>
              {this.renderAddress(workOrderInfo?.location?.street, workOrderInfo?.location?.city, workOrderInfo?.location?.zip_code, workOrderInfo?.location?.name, 'Kohde')}
            </ListItem>

            <br />

            <ListItem>
              {this.renderAddress(workOrderInfo?.purchaser?.street, workOrderInfo?.purchaser?.city, workOrderInfo?.purchaser?.zip_code, workOrderInfo?.purchaser?.name, 'Tilaaja')}
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions
          style={{
            // justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
            }}
            onClick={() => onClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default WorkOrderInfoDialog;
