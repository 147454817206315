import { action } from 'mobx';
import AccidentReport from '../models/safety/AccidentReport';

class AccidentReportStore {
  constructor(requests) {
    this.requests = requests;
  }

  @action create(item, resolve, reject) {
    console.log('creating: ', item);
    return this.requests.AccidentReports.create(item).then((result) => {
      console.log('RESULT: ', result);
      const newAccidentReport = AccidentReport.fromJsonProperties(result);

      console.log('GOT: ', newAccidentReport);

      resolve(newAccidentReport);
    }).catch((err) => reject(err));
  }

  @action update(item, resolve, reject) {
    return this.requests.AccidentReports.update(item).then((result) => {
      const updatedAccidentReport = AccidentReport.fromJsonProperties(result);

      resolve(updatedAccidentReport);
    }).catch((err) => reject(err));
  }

  @action get() {
    return this.requests.AccidentReports.get()
      .then((result) => {
        // const items = resp.map((item) => SalaryPeriodAttachment.fromJsonProperties(item));
        const accidentReports = result.map((accidentReport) => AccidentReport.fromJsonProperties(accidentReport));
        return accidentReports;
      });
  }
}

export default AccidentReportStore;
