import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import {
  CollapsibleList,
  List,
  ListItem,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  SimpleListItem,
} from '@rmwc/list';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import { IconButton, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PlaceIcon from '@material-ui/icons/Place';
import AddIcon from '@material-ui/icons/Add';
import { groupBy } from 'lodash';

import WorkTask from '../employer-work-orders/work-tasks/WorkTask';
import CalendarEntry from '../models/CalendarEntry';
import WorkTaskEmployeeCard from '../employer-work-orders/work-tasks/WorkTaskEmployeeCard';
import WorkTaskEmployeeDialog from '../employer-work-orders/work-tasks/WorkTaskEmployeeDialog';
import PhoneNumber from '../common/phonenumber';
import Linkify from '../common/Linkify';
import { renderWorkDayCategory } from './WorkOrderItem';
import WorkTaskEmployeeCreateDialog from '../employer-work-orders/work-tasks/WorkTaskEmployeeCreateDialog';

const formatInterval = (interval) => {
  const { end, start } = interval;
  return `${start.format('dd DD.MM.YYYY')} – ${end.format('dd DD.MM.YYYY')}`;
};

const getInstructions = (workOrder) => workOrder.attachments.sort((a, b) => a.title.localeCompare(b.title)).map(
  (woa) => (
    <CollapsibleList
      handle={(
        <SimpleListItem
          metaIcon="chevron_right"
          style={{
            padding: 0,
            fontSize: 'large',
            minHeight: '48px',
            height: 'fit-content',
          }}
          text={woa.title}
        />
      )}
      key={woa.id}
    >
      <div
        className="mdc-theme--secondary-bg"
        style={{
          borderRadius: '4px 4px 0 0',
          padding: '2px 0px 2px 0px',
          margin: '2px',
        }}
      >
        {woa.fileURL != null && (
          <a
            className="ml-1r"
            // download={woa.filename}
            href={woa.fileURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'var(--mdc-theme-primary, #6200ee)',
              fontSize: '12px',
              display: 'block',
              margin: '10px 0 10px 16px',
            }}
          >
            {woa.filename}
          </a>
        )}
        {woa.description != null && woa.description !== '' && <p className="ml-1r mdc-theme--text-primary-on-dark"><Linkify>{woa.description}</Linkify></p>}
      </div>
    </CollapsibleList>
  ),
);

const renderWorkTaskFiles = (workTask) => {
  if (workTask.files.length > 0) {
    const filesToBeRendered = [];
    workTask.files.forEach((file) => {
      if (file.fileURL) {
        filesToBeRendered.push(
          <a
            key={file.signedId}
            className="ml-1r"
            // download={file.filename}
            href={file.fileURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: 'var(--mdc-theme-primary, #6200ee)',
              fontSize: '12px',
              display: 'block',
              margin: '10px 0 10px 16px',
            }}
          >
            {file.filename}
          </a>,
        );
      }
    });

    return (
      <CollapsibleList
        handle={(
          <SimpleListItem
            metaIcon="chevron_right"
            style={{ padding: 0 }}
            text={workTask.identifierTwo ? `Työ: ${workTask.identifierOne} - ${workTask.identifierTwo}` : `Työ: ${workTask.identifierOne}`}
          />
        )}
        key={workTask.id}
      >
        {filesToBeRendered}
      </CollapsibleList>
    );
  }
  return null;
};

const renderAttachments = (workOrder) => (
  <>
    {getInstructions(workOrder)}
    {workOrder.workTasks.map((workTask) => renderWorkTaskFiles(workTask))}
  </>
);

const noPaddingsProps = (label = null) => (
  {
    style: {
      height: 'auto',
      padding: 0,
      whiteSpace: 'pre-wrap',
      textTransform: label === 'work_order.details.labels.when' ? 'capitalize' : '',
    },
  }
);

const labelTextProps = {
  style: {
    color: 'var(--mdc-theme-primary, #6200ee)',
    fontSize: '12px',
  },
};

const renderItem = (t, label, content) => (
  <ListItem key={`item-${label}`} {...noPaddingsProps(label)}>
    <ListItemText style={{ marginLeft: '10px' }}>
      <ListItemPrimaryText {...{
        style: {
          ...labelTextProps.style,
          ...noPaddingsProps(label).style,
        },
      }}
      >
        {t(label)}
      </ListItemPrimaryText>
      <ListItemSecondaryText
        {...noPaddingsProps(label)}
      >
        {content}
      </ListItemSecondaryText>
    </ListItemText>
  </ListItem>
);

const participants = (workOrder) => workOrder.participants.sort((a, b) => a.getFullname().localeCompare(b.getFullname())).map(
  (participant) => (
    <ListItem {...noPaddingsProps()} key={`participant-${participant.id}`}>
      <ListItemText>
        <ListItemPrimaryText {...{ style: { color: labelTextProps.style.color } }}>
          {participant.getFullname()}
        </ListItemPrimaryText>
        <PhoneNumber
          number={participant.phoneNumber}
        />
      </ListItemText>
    </ListItem>
  ),
);

const responsibleEmployers = (workOrder) => workOrder.responsibleEmployers.sort((a, b) => a.getFullname().localeCompare(b.getFullname())).map(
  (responsibleEmployer) => (
    <ListItem {...noPaddingsProps()} key={`participant-${responsibleEmployer.id}`}>
      <ListItemText>
        <ListItemPrimaryText {...{ style: { color: labelTextProps.style.color } }}>
          {responsibleEmployer.getFullname()}
        </ListItemPrimaryText>
        <PhoneNumber
          number={responsibleEmployer.phoneNumber}
        />
      </ListItemText>
    </ListItem>
  ),
);

const participantsSummary = (workOrder) => (
  <>
    {workOrder.responsibleEmployers && workOrder.responsibleEmployers.length > 0 && (
      <>
        <Typography
          className="text-label"
          use="headline10"
          style={{ marginTop: '30px' }}
        >
          Työnjohtajat
        </Typography>
        {responsibleEmployers(workOrder)}
      </>
    )}
    {!workOrder.participants || workOrder.participants.length !== 0 ? (
      <>
        <Typography
          className="text-label"
          use="headline10"
          style={{ marginTop: '30px' }}
        >
          Osallistujat
        </Typography>
        {participants(workOrder)}
      </>
    ) : (
      <Typography
        className="text-label"
        use="headline10"
        style={{ marginTop: '30px' }}
      >
        Vanhojen keikkojen osallistujat on piilotettu tietosuojan turvaamiseksi.
      </Typography>
    )}
  </>
);

const accomodationInfo = (workOrder) => {
  let mapsQuery = '';
  let searchQuery = '';

  if (workOrder.accomodationName) {
    let accomodationSearch = `${workOrder.accomodationName}`;

    if (workOrder.accomodationCity) {
      accomodationSearch = `${workOrder.accomodationName}%2C+${workOrder.accomodationCity}`;
    }
    const accomodationParams = accomodationSearch.replace(/ /g, '+');
    searchQuery = `http://google.com/search?q=${accomodationParams}`;
    console.log(searchQuery);
  }

  if (workOrder.accomodationStreet && workOrder.accomodationCity) {
    const mapsAddress = `${workOrder.accomodationStreet}%2C+${workOrder.accomodationCity}`;
    const mapsParams = mapsAddress.replace(/ /g, '+');

    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      mapsQuery = `http://maps.apple.com/?q=${mapsParams}`;
    } else {
      mapsQuery = `http://maps.google.com/?q=${mapsParams}`;
    }
  }

  return (
    <>
      <span>
        {workOrder.accomodationName ? workOrder.accomodationName : ''}
        {(workOrder.accomodationStreet || workOrder.accomodationZipCode || workOrder.accomodationCity) ? <br /> : ''}
        {workOrder.accomodationStreet ? workOrder.accomodationStreet : ''}
        {(workOrder.accomodationStreet && (workOrder.accomodationZipCode || workOrder.accomodationCity)) ? ', ' : ''}
        {workOrder.accomodationZipCode ? workOrder.accomodationZipCode : ''}
        {(workOrder.accomodationZipCode && workOrder.accomodationCity) ? ', ' : ''}
        {workOrder.accomodationCity ? workOrder.accomodationCity : ''}
      </span>
      {(mapsQuery !== '' || searchQuery !== '')
        && (
          <span style={{
            position: 'absolute',
            right: '2%',
            bottom: '0',
          }}
          >
            {searchQuery !== ''
              && (
                <a
                  href={searchQuery}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    aria-label="search"
                    style={{
                      border: '1px solid #FFC900',
                      borderRadius: '50%',
                      marginLeft: '5px',
                      width: '40px',
                      height: '40px',
                      padding: '0px',
                      fontSize: '20px',
                    }}
                  >
                    <SearchIcon
                      style={{
                        color: '#FFC900',
                      }}
                    />
                  </IconButton>
                </a>
              )}

            {mapsQuery !== ''
              && (
                <a
                  href={mapsQuery}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconButton
                    aria-label="location"
                    style={{
                      border: '1px solid #FFC900',
                      borderRadius: '50%',
                      marginLeft: '10px',
                      width: '40px',
                      height: '40px',
                      padding: '0px',
                      fontSize: '20px',
                    }}
                  >
                    <PlaceIcon
                      style={{
                        color: '#FFC900',
                      }}
                    />
                  </IconButton>
                </a>
              )}
          </span>
        )}
    </>
  );
};

const renderAccomodation = (t, workOrder) => (
  (workOrder.accomodationName || workOrder.accomodationStreet || workOrder.accomodationZipCode || workOrder.accomodationCity) && (
    <>
      <Typography
        className="text-label"
        use="headline10"
        style={{ marginTop: '30px' }}
      >
        Majoitustiedot
      </Typography>
      {renderItem(t, 'work_order.details.labels.accomodation_address', accomodationInfo(workOrder))}
    </>
  )
);

const locationAddress = (location) => {
  const mapsAddress = `${location.street}%2C+${location.city}`;
  const mapsParams = mapsAddress.replace(/ /g, '+');
  let mapsQuery = '';

  if (/iPhone|iPad/i.test(navigator.userAgent)) {
    mapsQuery = `http://maps.apple.com/?q=${mapsParams}`;
  } else {
    mapsQuery = `http://maps.google.com/?q=${mapsParams}`;
  }

  console.log(mapsQuery);

  return (
    <>
      <span>
        {location.street ? location.street : ''}
        {(location.street && (location.zip_code || location.city)) ? ', ' : ''}
        {location.zip_code ? location.zip_code : ''}
        {(location.zip_code && location.city) ? ', ' : ''}
        {location.city ? location.city : ''}
      </span>
      <span>
        <a
          href={mapsQuery}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'absolute',
            right: '2%',
            bottom: '0',
          }}
        >
          <IconButton
            aria-label="location"
            style={{
              border: '1px solid #FFC900',
              borderRadius: '50%',
              marginLeft: '5px',
              width: '40px',
              height: '40px',
              padding: '0px',
              fontSize: '20px',
            }}
          >
            <PlaceIcon
              style={{
                color: '#FFC900',
              }}
            />
          </IconButton>
        </a>
      </span>
    </>
  );
};

@inject('workOrderStore', 'uiStore', 'workTaskStore', 't')
@observer
class WorkOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
      workTaskDialogOpen: false,
      createWorkTaskDialogOpen: false,
      openWorkTask: new WorkTask(),
      newWorkTask: new WorkTask(),
    };
  }

  componentDidMount() {
    const { uiStore } = this.props;

    if (uiStore.clickedTab) {
      this.setState({
        activeTab: uiStore.clickedTab,
      });
      uiStore.emptyActiveTab();
    }
  }

  showWorkTaskDialog = (workTask) => {
    this.setState({
      workTaskDialogOpen: true,
      openWorkTask: workTask || new WorkTask(),
    });
  }

  showCreateWorkTaskDialog = () => {
    this.setState({
      createWorkTaskDialogOpen: true,
      newWorkTask: new WorkTask(),
    });
  }

  closeWorkTaskDialog = () => {
    this.setState({
      workTaskDialogOpen: false,
      openWorkTask: new WorkTask(),
    });
  }

  closeCreateWorkTaskDialog = () => {
    this.setState({
      createWorkTaskDialogOpen: false,
      newWorkTask: new WorkTask(),
    });
  }

  handleAttachmentAction = (workTask) => {
    const { workTaskDialogOpen } = this.state;
    const { workTaskStore } = this.props; // updateWorkTaskState

    // if (!workTask.id) {
    //   const createPromise = new Promise((resolve) => workTaskStore.createWorkTask(workTask, resolve));
    //   createPromise.then((newTask) => {
    //     updateWorkTaskState(newTask);
    //     // A hack to update the file list
    //     if (workTaskDialogOpen) {
    //       this.setState({ openWorkTask: newTask });
    //     }
    //     // if (newTask.workTaskUsers.find((user) => user.id === currentUser.id)) {
    //     //   console.log('SHOULD PUSH TO THE WORK ORDER TASKS');
    //     //   console.log('UPDATE WORK ORDER: ', currentUser.workOrders.find((wo) => wo.id === newTask.workOrderId));

    //     //   const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === newTask.workOrderId);
    //     //   if (foundEmployeeWorkOrder) {
    //     //     foundEmployeeWorkOrder.workTasks.push(newTask);
    //     //   }
    //     // } else {
    //     //   console.log('SHOULD REMOVE FROM WORK ORDER TASKS');
    //     //   console.log('UPDATE WORK ORDER: ', currentUser.workOrders.find((wo) => wo.id === newTask.workOrderId));

    //     //   const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === newTask.workOrderId);
    //     //   if (foundEmployeeWorkOrder) {
    //     //     foundEmployeeWorkOrder.removeWorkTask(newTask);
    //     //   }
    //     // }
    //   });
    //   // employerWorkOrderStore.createWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    // } else {
    const updatePromise = new Promise((resolve) => workTaskStore.updateWorkTask(workTask, resolve));
    updatePromise.then((updatedTask) => {
      // updateWorkTaskState(updatedTask);

      // A hack to update the file list
      if (workTaskDialogOpen) {
        this.setState({ openWorkTask: updatedTask });
      }
      // if (updatedTask.workTaskUsers.find((user) => user.id === currentUser.id)) {
      //   console.log('SHOULD PUSH TO THE WORK ORDER TASKS');
      //   console.log('UPDATE WORK ORDER: ', currentUser.workOrders.find((wo) => wo.id === updatedTask.workOrderId));

      //   const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === updatedTask.workOrderId);
      //   if (foundEmployeeWorkOrder) {
      //     foundEmployeeWorkOrder.workTasks.push(updatedTask);
      //   }
      // } else {
      //   console.log('SHOULD REMOVE FROM WORK ORDER TASKS');
      //   console.log('UPDATE WORK ORDER: ', currentUser.workOrders.find((wo) => wo.id === updatedTask.workOrderId));

      //   const foundEmployeeWorkOrder = currentUser.workOrders.find((wo) => wo.id === updatedTask.workOrderId);
      //   if (foundEmployeeWorkOrder) {
      //     foundEmployeeWorkOrder.removeWorkTask(updatedTask);
      //   }
      // }
    });
    // employerWorkOrderStore.updateWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    // }
  }

  createOrUpdateWorkTask = (workTask) => {
    const { createWorkTaskDialogOpen } = this.state;
    const { workTaskStore, uiStore, workOrderStore } = this.props; // updateWorkTaskState

    if (!workTask.id) {
      const createPromise = new Promise((resolve) => workTaskStore.createWorkTaskEmployee(workTask, resolve));
      createPromise.then((newTask) => {
        // updateWorkTaskState(newTask);
        // A hack to update the file list
        if (createWorkTaskDialogOpen) {
          this.setState({ newWorkTask: newTask });
        }
        const workOrder = workOrderStore.findById(uiStore.currentView.id);
        const foundTaskIndex = workOrder.workTasks.findIndex((task) => task.id === workTask.id);
        if (foundTaskIndex === -1) {
          workOrder.workTasks = [newTask, ...workOrder.workTasks];
        }
      });
    } else {
      const updatePromise = new Promise((resolve) => workTaskStore.updateWorkTask(workTask, resolve));
      updatePromise.then((updatedTask) => {
        // A hack to update the file list
        if (createWorkTaskDialogOpen) {
          this.setState({ newWorkTask: updatedTask });
        }
      });
    }
  }

  renderWorkTaskCards() {
    const { workOrderStore, uiStore } = this.props;
    const workOrder = workOrderStore.findById(uiStore.currentView.id);

    return (
      <>
        {workOrder.workTasks.map((task) => (
          <WorkTaskEmployeeCard
            mode="employee"
            key={`task-${task.id}`}
            handleCardClick={this.showWorkTaskDialog}
            // childTasks={task.childTasks}
            workTask={task}
          />
        ))}
      </>
    );
  }

  render() {
    const {
      workOrderStore,
      uiStore,
      uiStore: { currentUser: { accountInfo } },
      t,
    } = this.props;

    const {
      activeTab,
      workTaskDialogOpen,
      openWorkTask,
      createWorkTaskDialogOpen,
      newWorkTask,
    } = this.state;

    const workOrder = workOrderStore.findById(uiStore.currentView.id);
    const showAttachmentsTab = workOrder.hasEmployeeRelevantAttachments();
    const showWorkTasksTab = workOrder.workTasks.length > 0 && accountInfo.hourlyWorkOrdersEnabled;

    if (!workOrder) {
      return null;
    }

    const hasWorkPeriodsWithDescriptions = workOrder.workPeriods.find((workPeriod) => workPeriod.description);

    // Work order interval days
    const workOrderDaysCount = workOrder.to.diff(workOrder.from, 'days') + 1;
    // Actual user-specific working days (calendar entries)
    const workDaysCount = workOrder.workDays.length;
    let dateMismatch = false;
    if (workOrderDaysCount !== workDaysCount || workOrder.firstWorkDay.format('YYYY-MM-DD') !== workOrder.from.format('YYYY-MM-DD') || workOrder.lastWorkDay.format('YYYY-MM-DD') !== workOrder.to.format('YYYY-MM-DD')) {
      dateMismatch = true;
    }

    const workDaysSorted = workOrder.workDays.slice().sort((a, b) => {
      const dateA = a.from.toDate();
      const dateB = b.from.toDate();
      return dateB - dateA;
    });

    const workPeriodsWithDays = groupBy(workDaysSorted, 'workPeriodName');

    return (
      <>
        <WorkTaskEmployeeDialog
          open={workTaskDialogOpen}
          // styles={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' }}
          workOrderId={workOrder.id}
          handleAttachmentAction={this.handleAttachmentAction}
          // handleGeneratePDF={this.handleGeneratePDF}
          handleClose={this.closeWorkTaskDialog}
          workTask={openWorkTask}
          // updateWorkTaskState={updateWorkTaskState}
          // confirmDelete={this.confirmDelete}
          workOrderParticipants={workOrder.participants}
          // generatingPdf={generatingPdf}
          // deleteWorkTaskFileFromState={deleteWorkTaskFileFromState}
          workTaskEmployeeManagementEnabled={workOrder.workTaskEmployeeManagementEnabled}
          employeesCanAddFilesToWorkTasks={workOrder.settings.employeesCanAddFilesToWorkTasks}
        />

        <div className="work-order__list" style={{ maxWidth: '800px' }}>
          <Typography
            className="text-label"
            use="headline6"
            style={{
              color: 'var(--mdc-theme-primary)',
              padding: '5px 10px',
            }}
          >
            {workOrder.name}
          </Typography>

          {/* Note: this HTML structure is significantly different from the "main" navigation tabs in other views */}
          <div style={{ backgroundColor: '#0D121C' }}>
            <TabBar
              // style={{ maxWidth: '600px' }}
              activeTabIndex={activeTab}
              onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
            >
              <Tab>{t('work_order.tabs.details')}</Tab>
              <Tab>{t('work_order.tabs.participants')}</Tab>
              {showWorkTasksTab && <Tab>{t('work_order.tabs.work_tasks')}</Tab>}
              {showAttachmentsTab && <Tab>{t('work_order.tabs.attachments')}</Tab>}
            </TabBar>
          </div>

          {activeTab === 0 && (
            <List twoLine style={{ margin: '0 10px' }}>
              <Typography
                className="text-label"
                use="headline10"
                style={{ marginTop: '30px' }}
              >
                Perustiedot
              </Typography>
              {/* {renderItem(t, 'work_order.details.labels.when', formatInterval(workOrder.interval))} */}
              {/* We add the workOrder.workDays.length check here for archived work orders, where workDays aren't relayed through the archived_work_order view */}
              {dateMismatch && workOrder.workDays.length > 0 ? (
                renderItem(t, 'work_order.details.labels.when', `${formatInterval({ start: workOrder.firstWorkDay, end: workOrder.lastWorkDay })} (${workDaysCount} pv)`)
              ) : (
                renderItem(t, 'work_order.details.labels.when', formatInterval(workOrder.interval))
              )}
              {dateMismatch && workPeriodsWithDays && (
                Object.keys(workPeriodsWithDays).map((workPeriodName) => {
                  const workDayRanges = CalendarEntry.packageJsonIntoRangesMoment(workPeriodsWithDays[workPeriodName]);
                  // Sort work days descending (packageJsonIntoRangesMoment sorts them ascending)
                  const sortedWorkDayRanges = workDayRanges.slice().sort((a, b) => {
                    const dateA = a.from.toDate();
                    const dateB = b.from.toDate();
                    return dateB - dateA;
                  });

                  return (
                    <div style={{ marginLeft: '20px' }}>
                      {renderWorkDayCategory(workPeriodName, sortedWorkDayRanges, workOrder.interval, { marginTop: '5px' }, { fontSize: '14px' }, false, workOrder.workPeriodNames.size > 1)}
                    </div>
                  );
                })
              )}
              {workOrder.description && renderItem(t, 'work_order.details.labels.description', (<Linkify>{workOrder.description}</Linkify>))}

              {renderItem(t, 'work_order.details.labels.location_name', workOrder.location.name)}
              {renderItem(t, 'work_order.details.labels.location_company', workOrder.location.company)}
              {workOrder.location.location && renderItem(t, 'work_order.details.labels.location', (<Linkify>{workOrder.location.location}</Linkify>))}
              {renderItem(t, 'work_order.details.labels.address', locationAddress(workOrder.location))}
              {workOrder.location.additional_info && renderItem(t, 'work_order.details.labels.location_additionalinfo', (<Linkify>{workOrder.location.additional_info}</Linkify>))}

              {renderItem(t, 'work_order.details.labels.orderer', workOrder.purchaser.name)}

              {renderAccomodation(t, workOrder)}

              {(workOrder.accomodationDescription || hasWorkPeriodsWithDescriptions) && (
                <Typography
                  className="text-label"
                  use="headline10"
                  style={{ marginTop: '30px' }}
                >
                  Lisätietoja
                </Typography>
              )}
              {workOrder.accomodationDescription && renderItem(t, 'work_order.details.labels.accomodation_additionalinfo', (<Linkify>{workOrder.accomodationDescription}</Linkify>))}
              {workOrder.workPeriods.filter((workPeriod) => workPeriod.description).map((workPeriod) => (
                renderItem(t, `${workPeriod.name} lisätietoja`, (<Linkify>{workPeriod.description}</Linkify>))
              ))}
            </List>
          )}

          {activeTab === 1 && (
            <List
              style={{
                margin: '0 10px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {participantsSummary(workOrder)}
            </List>
          )}

          {showWorkTasksTab && activeTab === 2 && (
            <>
              {workOrder.settings.employeesCanCreateWorkTasks && (
                <>
                  <div style={{ textAlign: 'end' }}>
                    <Button
                      className="accept-button mui-button-hover-overwrite"
                      // className="employee-accept-button"
                      type="button"
                      style={{
                        borderRadius: '0px',
                        // minWidth: '100px',
                        // width: 'fit-content',
                        // height: 'fit-content',
                        backgroundColor: 'transparent',
                        // border: '1px solid #FFC900',
                        color: '#FFC900',
                        margin: '16px',
                      }}
                      onClick={() => this.showCreateWorkTaskDialog()}
                      startIcon={<AddIcon />}
                    >
                      Uusi työ
                    </Button>
                  </div>

                  <WorkTaskEmployeeCreateDialog
                    open={createWorkTaskDialogOpen}
                    workOrderId={workOrder.id}
                    handleAttachmentAction={this.createOrUpdateWorkTask}
                    // handleGeneratePDF={this.handleGeneratePDF}
                    handleClose={this.closeCreateWorkTaskDialog}
                    workTask={newWorkTask}
                    // updateWorkTaskState={updateWorkTaskState}
                    // deleteWorkTaskState={deleteWorkTaskState}
                    // confirmDelete={this.confirmDelete}
                    workOrderParticipants={workOrder.participants}
                    // generatingPdf={generatingPdf}
                    // deleteWorkTaskFileFromState={deleteWorkTaskFileFromState}
                    employeesCanAddFilesToWorkTasks={workOrder.settings.employeesCanAddFilesToWorkTasks}
                  />
                </>
              )}

              <List
                style={{
                  margin: '0 10px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {this.renderWorkTaskCards(workOrder)}
              </List>
            </>
          )}

          {((showAttachmentsTab && !showWorkTasksTab && activeTab === 2) || (showWorkTasksTab && activeTab === 3)) && (
            <List
              style={{
                margin: '0 10px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {renderAttachments(workOrder)}
            </List>
          )}
        </div>
      </>
    );
  }
}

export default WorkOrderDetail;
