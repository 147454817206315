/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle,
} from '@material-ui/core';
import ChatRoom from '../messages/stores/ChatRoom';
import LoadingSpinner from '../shared/LoadingSpinner';
import Chat from '../messages/Chat';
import Theme from '../Theme';
// import './employerTimelogViewDialog.css';

@inject('t', 'employerContextStore', 'timelogStore', 'chatStore')
@observer
class EmployerChatDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatRoom: null,
      showFullNestedDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { chatStore, open, chatId } = this.props;

    if (open && !prevProps.open) {
      // this.setState({
      //   chatRoom: null,
      // });

      chatStore.findChat(chatId).then((result) => {
        // this.handleContextQueryResult(result);

        this.setState({
          chatRoom: ChatRoom.fromJson(result),
        });
      });
    }
  }

  componentWillUnmount() {
    const { employerContextStore } = this.props;
    employerContextStore.setCurrentEmployeeId(null);
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      chatRoom: null,
    });

    onClose();
  }

  render() {
    // const { t } = this.props;
    const { open, employerContextStore, addToTotalChatMessagesCount } = this.props;
    const { showFullNestedDialog, chatRoom } = this.state;

    return (
      <Dialog
        className="modal-size employer-timelog-view-dialog"
        // className={mode !== 'employer' ? 'mui-dialog-background' : ''}
        open={open}
        onClose={(evt) => {
          evt.stopPropagation();
          // Null the employee ID on dialog close so that employer's own work hours/trips work
          employerContextStore.setCurrentEmployeeId(null);
          this.onClose();
        }}
      >
        <DialogContent
          style={{ fontSize: '16px', color: 'white', padding: 0 }}
        >
          {!chatRoom && (
            <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          )}

          {chatRoom && (
            <Theme>
              <div style={{ width: '100%', height: '100%', backgroundColor: '#1E232F' }}>
                <Chat chatRoom={chatRoom} addToTotalChatMessagesCount={addToTotalChatMessagesCount} employeeMode={true}  />
              </div>
            </Theme>
          )}
        </DialogContent>
        <DialogActions
          className="dialog-actions-less-padding"
          style={{
            display: showFullNestedDialog && 'none',
            // The closing button is moved to the left to prevent user from accidentally pressing the dialog close button when sending messages
            justifyContent: 'flex-end',
          }}
        >
          <Button
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
            }}
            onClick={() => this.onClose()}
          >
            Sulje
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EmployerChatDialog;
