/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import { IconButton } from '@rmwc/icon-button';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';
import {
  List,
  ListGroup,
  ListItem,
  ListItemMeta,
  ListDivider,
  ListGroupSubheader,
  ListItemText,
} from '@rmwc/list';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { isEqual } from 'lodash';

import { DirectUploadProvider } from 'react-activestorage-provider';

import { buttonColors } from '../../calendar/EmployeeCalendar';
import { handleProgress } from '../../models/user/user-ui-avatar';
import WorkTaskUser from './WorkTaskUser';
import './WorkTaskDialog.css';
import FilePreviewDialog from '../../shared/FilePreviewDialog';

// const handleEnter = (e) => {
//   if (e.key === 'Enter') {
//     document.activeElement.blur();
//   }
// };

const validateIdentifierOne = (identifierOne) => {
  if (!identifierOne) {
    return 'Tunniste 1 on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  identifierOne: (identifierOne) => validateIdentifierOne(identifierOne),
};

@inject('workTaskStore', 'loginStore', 't') // employerWorkOrderStore
@observer
class WorkTaskParticipantsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.workTask?.id,
      errors: {},
      inputChanged: false,
      // This is used to store the to-be-updated or deleted workTaskUsers
      updatingWorkTaskUsers: props.updatingWorkTaskUsers,
      // These are used to store files that were selected for PDF generation
      checkedFiles: [],
      // Kept separate from checkedFiles to prevent errors if there's multiple PDF generations going on at once
      generatingPdfFiles: [],
      // workTaskUsers: props.workTask?.workTaskUsers || [],
      workOrderParticipants: this.prunedWorkOrderParticipants(props.workTaskUsers),
      showFilePreviewDialog: false,
      // status: props.workTask?.status || { name: 'pending' },
    };
  }

  componentDidUpdate(prevProps) {
    const { open, updatingWorkTaskUsers, workTaskUsers } = this.props;
    if (open !== prevProps.open) {
      this.setState({
        updatingWorkTaskUsers: updatingWorkTaskUsers,
        workOrderParticipants: this.prunedWorkOrderParticipants(workTaskUsers),
      });
    }
  }

  prunedWorkOrderParticipants(workTaskUsers = []) {
    // We want to remove users already added to workTaskUsers from the workOrderParticipants so that they can't be added twice
    const { workOrderParticipants = [] } = this.props;
    // .filter((workTaskUser) => !workTaskUser._destroy)
    const workTaskUserIds = workTaskUsers.map((workTaskUser) => workTaskUser.user.id);

    const tempWorkOrderParticipants = [...workOrderParticipants].filter((participant) => !workTaskUserIds.includes(participant.id));

    this.setState({ workOrderParticipants: tempWorkOrderParticipants });
    return tempWorkOrderParticipants;
  }

  renderListGroup(category) {
    const { workOrderParticipants } = this.state;
    const participantIds = [];
    const uniqueParticipants = [];

    // Only one checkbox per user even though they might have multiple invitations
    workOrderParticipants.forEach((user) => {
      if (!participantIds.includes(user.id)) {
        participantIds.push(user.id);
        uniqueParticipants.push(user);
      }
    });

    return (
      <Elevation
        z={5}
        style={{ borderRadius: '0px 0px 3px 3px' }}
      >
        <List
          style={{
            marginTop: '10px',
            color: 'black',
            padding: '0px',
            // minHeight: '250px',
          }}
        >
          <ListGroup>
            <ListGroupSubheader
              style={{
                // backgroundColor: '#FFC900', // colors[category].background,
                // color: '#FFC900', // colors[category].font,
                color: 'white',
                margin: '0px',
                padding: '12px 16px',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              <span style={{ color: '#FFC900' }}>
                {/* {t(`work_order.availability.${category}`)} */}
                Lisää tekijöitä
              </span>
            </ListGroupSubheader>

            {uniqueParticipants?.map((user, index) => (
              <React.Fragment
                key={`${category}-${user.id}`}
              >
                {this.renderListItem(user, index)}
                {index !== workOrderParticipants.length - 1 && <ListDivider />}
              </React.Fragment>
            ))}
          </ListGroup>
        </List>
      </Elevation>
    );
  }

  renderWorkTaskUsers() {
    const { workTaskUsers } = this.props;
    const { updatingWorkTaskUsers } = this.state;

    // Filter workTaskUsers, we do not want to render items that are found in the updating list (going to be deleted)
    let filteredWorkTaskUsers = [...workTaskUsers];
    if (updatingWorkTaskUsers.length > 0) {
      const updatingWorkTaskUsersIds = [...updatingWorkTaskUsers].map((workTaskUser) => workTaskUser.user.id);
      filteredWorkTaskUsers = filteredWorkTaskUsers.filter((workTaskUser) => !updatingWorkTaskUsersIds.includes(workTaskUser.user.id));
    }

    if (filteredWorkTaskUsers.length === 0) {
      return (
        <div style={{ fontSize: '16px', color: 'white' }}>
          Työlle ei ole lisätty tekijöitä
        </div>
      );
    }

    if (filteredWorkTaskUsers.length > 0) {
      return (
        <Elevation
          z={5}
          style={{ borderRadius: '0px 0px 3px 3px' }}
        >
          <List
            twoLine
            style={{
              marginTop: '10px',
              color: 'black',
              paddingTop: '0px',
              // minHeight: '250px',
            }}
          >
            <ListGroup>
              {/* <ListGroupSubheader
                style={{
                  backgroundColor: '#7CD715',
                  color: '#000000',
                  margin: '0px',
                  padding: '12px 16px',
                  fontWeight: '500',
                  fontSize: '14px',
                }}
              >
                <span>
                  Työn tekijät
                </span>
              </ListGroupSubheader> */}

              {filteredWorkTaskUsers.map((workTaskUser, index) => (
                <React.Fragment
                  key={`workTaskUser-${workTaskUser.user.id}`}
                >
                  {this.renderWorkTaskUser(workTaskUser, index)}
                  {index !== workTaskUsers.length - 1 && <ListDivider />}
                </React.Fragment>
              ))}
            </ListGroup>
          </List>
        </Elevation>
      );
    }
    return null;
  }

  renderWorkTaskUser(workTaskUser, index) {
    // const { workTaskUsers } = this.state;
    // const foundWorkTaskUser = workTaskUsers.find((task) => task.user.id === workTaskUser.user.id);

    return (
      <div
        key={index}
        // className="availability-list-item"
        style={{
          overflow: 'visible',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {/* <Icon
          style={{
            width: '40px'
          }}
          icon={
            <div
              style={{
                background: 'limegreen',
                width: '24px',
                height: '24px',
                borderRadius: '100px'
              }}
            />
          }
        /> */}
        <div style={{ marginTop: 'auto', marginBottom: 'auto', color: 'white', padding: '10px' }}>
          {`${workTaskUser.user.name}`}
        </div>

        <div>
          <IconButton
            type='button'
            onClick={() => this.deleteWorkTaskUser(workTaskUser)}
            style={{ color: 'white' }}
            icon="delete_outlined"
          >
          </IconButton>
        </div>
      </div>
    );
  }

  deleteWorkTaskUser(workTaskUser) {
    const { workTaskUsers, updateWorkTaskUsers } = this.props;
    const { updatingWorkTaskUsers } = this.state;

    const newUpdatingWorkTaskUsers = [...updatingWorkTaskUsers];

    const foundWorkTaskUserIndex = newUpdatingWorkTaskUsers.findIndex((existingWorkTaskUser) => existingWorkTaskUser.user.id === workTaskUser.user.id);

    if (foundWorkTaskUserIndex !== -1) {
      newUpdatingWorkTaskUsers[foundWorkTaskUserIndex]._destroy = 1;
    } else {
      newUpdatingWorkTaskUsers.push({ ...workTaskUser, _destroy: 1 });
    }

    this.setState((prevState) => ({
      updatingWorkTaskUsers: newUpdatingWorkTaskUsers, // [...prevState.updatingWorkTaskUsers, { ...workTaskUser, _destroy: 1 }],
      inputChanged: true,
    }), () => {
      updateWorkTaskUsers(newUpdatingWorkTaskUsers);
    });
  }

  renderListItem(user, index) {
    const { updatingWorkTaskUsers } = this.state;

    const foundPerson = updatingWorkTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id);
    let checked = foundPerson ? true : false;

    return (
      <ListItem
        key={index}
        className="availability-list-item"
        style={{
          overflow: 'visible',
          height: 'initial',
          minHeight: '48px',
          backgroundColor: 'initial',
          color: 'white',
        }}
        onClick={() => {
          this.handleWorkTaskUserCheckbox(checked, user);
        }}
      >
        <Checkbox className="work-task-participants-dialog-checkbox" checked={checked} />
        {`${user.firstName} ${user.lastName}`}
      </ListItem>
    );
  }

  handleWorkTaskUserCheckbox(checked, user) {
    const { updateWorkTaskUsers } = this.props;
    const { updatingWorkTaskUsers } = this.state;
    const tempWorkTaskUsers = [...updatingWorkTaskUsers];

    if (!checked) {
      tempWorkTaskUsers.push(new WorkTaskUser({ user: { id: user.id } }));
      this.setState({
        updatingWorkTaskUsers: tempWorkTaskUsers,
        inputChanged: true,
      }, () => {
        updateWorkTaskUsers(tempWorkTaskUsers);
      });
    } else {
      const remainingWorkTaskUsers = tempWorkTaskUsers.filter((workTaskUser) => workTaskUser.user.id !== user.id);
      this.setState({
        updatingWorkTaskUsers: remainingWorkTaskUsers,
      }, () => {
        updateWorkTaskUsers(remainingWorkTaskUsers);
      });
    }
  }

  renderBottomButtons() {
    // const {
    //   inputChanged,
    //   id,
    //   identifierOne,
    // } = this.state;

    return(
      <DialogActions
        style={{
          justifyContent: 'end',
          padding: '15px',
        }}
      >
        <DialogButton
          type="button"
          className="employer-accept-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          action="accept"
        >
          Valmis
        </DialogButton>
      </DialogActions>
    );

    // if (inputChanged) {
    //   return (
    //     <DialogActions
    //       style={{
    //         justifyContent: 'space-between',
    //         padding: '15px',
    //       }}
    //     >
    //       <DialogButton
    //         type="button"
    //         className="employer-accept-button"
    //         style={{
    //           borderRadius: '0px',
    //           minWidth: '100px',
    //         }}
    //         action="accept"
    //       >
    //         Tallenna
    //       </DialogButton>

    //       <DialogButton
    //         type="button"
    //         className="employer-reject-button"
    //         style={{
    //           borderRadius: '0px',
    //           minWidth: '100px',
    //         }}
    //         action="close"
    //       >
    //         Peruuta
    //       </DialogButton>
    //     </DialogActions>
    //   );
    // } else {
    //   return (
    //     <DialogActions
    //       style={{
    //         justifyContent: 'space-between',
    //         padding: '15px',
    //       }}
    //     >
    //       <DialogButton
    //         type="button"
    //         className="employer-accept-button"
    //         style={{
    //           borderRadius: '0px',
    //           minWidth: '100px',
    //         }}
    //         action="close"
    //       >
    //         Sulje
    //       </DialogButton>
    //     </DialogActions>
    //   );
    // }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      fileUrl: null,
    })
  }

  setStatus(status) {
    this.setState({
      status: status,
    });
  }

  render() {
    const { open, onClose, mode } = this.props;
    const { updatingWorkTaskUsers, workOrderParticipants } = this.state;

    return (
        <Dialog
          className={mode === 'employer' ? "small-modal-size employer-modal employer-work-task-dialog" : "small-modal-size employee-work-task-dialog trip-route-dialog"}
          open={open}
          onClose={(evt) => {
            onClose();
          }}
        >
          <DialogTitle>
            Työn tekijät
          </DialogTitle>

          <DialogContent>
            <Grid>
              <GridCell span={12}>
                {/* Conditional rendering check is inside renderWorkTaskUsers */}
                {this.renderWorkTaskUsers()}
              </GridCell>
              <GridCell span={12}>
                {workOrderParticipants.length > 0 && this.renderListGroup()}
              </GridCell>
            </Grid>
          </DialogContent>
          {this.renderBottomButtons()}
        </Dialog>
    )
  }
}

export default WorkTaskParticipantsDialog;
