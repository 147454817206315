/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@rmwc/textfield';
// import MUITextField from '@material-ui/core/TextField';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import { IconButton } from '@rmwc/icon-button';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Checkbox } from '@rmwc/checkbox';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import {
  List,
  ListGroup,
  ListItem,
  ListItemMeta,
  ListDivider,
  ListGroupSubheader,
  ListItemText,
} from '@rmwc/list';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { isEqual } from 'lodash';

import { DirectUploadProvider } from 'react-activestorage-provider';

import { buttonColors } from '../../calendar/EmployeeCalendar';
import { handleProgress } from '../../models/user/user-ui-avatar';
import WorkTaskUser from './WorkTaskUser';
import './WorkTaskDialog.css';
import FilePreviewDialog from '../../shared/FilePreviewDialog';
// import TextArea from '../../shared/TextArea';
import moment from '../../utils/moment';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const validateIdentifierOne = (identifierOne) => {
  if (!identifierOne) {
    return 'Tunniste 1 on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  identifierOne: (identifierOne) => validateIdentifierOne(identifierOne),
};

@inject('workTaskStore', 'loginStore', 't', 'uiStore') // employerWorkOrderStore
@observer
class WorkTaskEmployeeCreateDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.workTask?.id,
      identifierOne: props.workTask?.identifierOne,
      identifierTwo: props.workTask?.identifierTwo,
      description: props.workTask?.description,
      files: props.workTask?.files || [],
      estimate: props.workTask?.estimate,
      errors: {},
      inputChanged: false,
      // This is used to store the to-be-updated or deleted workTaskUsers
      updatingWorkTaskUsers: [],
      // These are used to store files that were selected for PDF generation
      checkedFiles: [],
      // Kept separate from checkedFiles to prevent errors if there's multiple PDF generations going on at once
      generatingPdfFiles: [],
      parentId: props.workTask?.parentId,
      workTaskUsers: props.workTask?.workTaskUsers || [],
      workOrderParticipants: this.prunedWorkOrderParticipants(props.workTask?.workTaskUsers),
      showFilePreviewDialog: false,
      showHiddenInputs: false,
    };
  }

  // Is is probably an overcomplicated way of ensuring that the dialog's state (the opened work task) stays up-to-date
  // Figure out a better solution, preferrably from an older edit dialog implementation
  componentDidUpdate(prevProps) {
    const { workTask, uiStore: { currentUser } } = this.props;
    // Does this actually work? prevWorkTask is always undefined?
    const { prevWorkTask } = prevProps;
    const { id, files } = this.state;
    let userIsAlreadyOnWorkTask = false;
    if (workTask.workTaskUsers) {
      userIsAlreadyOnWorkTask = workTask.workTaskUsers.find((workTaskUser) => workTaskUser.user.id === currentUser.id);
    }

    if (!isEqual(workTask, prevWorkTask) && (workTask.id !== id || workTask?.files?.length !== files?.length)) {
      this.setState({
        id: workTask.id,
        identifierOne: workTask.identifierOne,
        identifierTwo: workTask.identifierTwo,
        description: workTask.description,
        parentId: workTask.parentId,
        files: workTask.files,
        estimate: workTask.estimate,
        inputChanged: false,
        workTaskUsers: workTask.workTaskUsers || [],
        workOrderParticipants: this.prunedWorkOrderParticipants(workTask.workTaskUsers),
        // Adding the user themselves to the work by default
        updatingWorkTaskUsers: userIsAlreadyOnWorkTask ? [] : [new WorkTaskUser({ user: { id: currentUser.id } })],
        errors: {},
      });
    }
  }

  prunedWorkOrderParticipants(workTaskUsers = []) {
    // We want to remove users already added to workTaskUsers from the workOrderParticipants so that they can't be added twice
    const { workOrderParticipants = [] } = this.props;
    const workTaskUserIds = workTaskUsers.map((workTaskUser) => workTaskUser.user.id);
    const tempWorkOrderParticipants = [...workOrderParticipants].filter((participant) => !workTaskUserIds.includes(participant.id));


    this.setState({ workOrderParticipants: tempWorkOrderParticipants });
    return tempWorkOrderParticipants;
  }

  handleValidate(identifierOne) {
    let errors = {};

    // Not properly dynamic, you have to define the validate object but also have the values are parameters
    // Could be made better somehow? Get values from state using keys from the validate object?
    Object.keys(validate).forEach((key) => {
      const error = validate[key](identifierOne);

      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    this.setState({
      errors: { ...errors },
    });

    return errors;
  }

  renderListGroup(category) {
    const { workOrderParticipants } = this.state;
    const participantIds = [];
    const uniqueParticipants = [];

    // Only one checkbox per user even though they might have multiple invitations
    workOrderParticipants.forEach((user) => {
      if (!participantIds.includes(user.id)) {
        participantIds.push(user.id);
        uniqueParticipants.push(user);
      }
    });

    return (
      <List
        style={{
          marginTop: '10px',
          // color: 'black',
          padding: '0px',
          // minHeight: '250px',
        }}
      >
        <ListGroup>
          <div
            style={{
              margin: '10px -10px 5px -10px',
              paddingBottom: '5px',
              borderBottom: '1px solid var(--mdc-theme-primary)'
            }}
          >
            <Typography
              use="subtitle1"
              style={{ paddingLeft: '10px' }}
            >
              Lisää tekijöitä
            </Typography>
          </div>

          {uniqueParticipants?.map((user, index) => (
            <React.Fragment
              key={`${category}-${user.id}`}
            >
              {this.renderListItem(user, index)}
              {index !== workOrderParticipants.length - 1 && <ListDivider />}
            </React.Fragment>
          ))}
        </ListGroup>
      </List>
    );
  }

  renderWorkTaskUsers() {
    const { workTaskUsers, updatingWorkTaskUsers } = this.state;

    // Filter workTaskUsers, we do not want to render items that are found in the updating list (going to be deleted)
    let filteredWorkTaskUsers = [...workTaskUsers];
    if (updatingWorkTaskUsers.length > 0) {
      const updatingWorkTaskUsersIds = [...updatingWorkTaskUsers].map((workTaskUser) => workTaskUser.user.id);
      filteredWorkTaskUsers = filteredWorkTaskUsers.filter((workTaskUser) => !updatingWorkTaskUsersIds.includes(workTaskUser.user.id));
    }

    if (filteredWorkTaskUsers.length > 0) {
      return (
        <List
          twoLine
          style={{
            marginTop: '10px',
            paddingTop: '0px',
          }}
        >
          <ListGroup>
            <div
              style={{
                margin: '10px -10px 5px -10px',
                paddingBottom: '5px',
                borderBottom: '1px solid var(--mdc-theme-primary)'
              }}
            >
              <Typography
                use="subtitle1"
                style={{ paddingLeft: '10px', color: 'white' }}
              >
                Työn tekijät
              </Typography>
            </div>

            {filteredWorkTaskUsers.map((workTaskUser, index) => (
              <React.Fragment
                key={`workTaskUser-${workTaskUser.user.id}`}
              >
                {this.renderWorkTaskUser(workTaskUser, index)}
                {index !== workTaskUsers.length - 1 && <ListDivider />}
              </React.Fragment>
            ))}
          </ListGroup>
        </List>
      );
    }
    return null;
  }

  renderWorkTaskUser(workTaskUser, index) {
    // const { workTaskUsers } = this.state;
    // const foundWorkTaskUser = workTaskUsers.find((task) => task.user.id === workTaskUser.user.id);

    return (
      <ListItem
        key={index}
        // className="availability-list-item"
        style={{
          overflow: 'visible',
        }}
      >
        <Icon
          style={{
            width: '40px'
          }}
          icon={
            <div
              style={{
                background: 'limegreen',
                width: '24px',
                height: '24px',
                borderRadius: '100px'
              }}
            />
          }
        />
        <ListItemText style={{ marginTop: 'auto', marginBottom: 'auto', color: 'white' }}>
          {`${workTaskUser.user.name}`}
        </ListItemText>
        <ListItemMeta>
          <IconButton
            type='button'
            onClick={() => this.deleteWorkTaskUser(workTaskUser)}
            style={{ color: 'white' }}
            icon="delete_outlined"
          >
          </IconButton>
        </ListItemMeta>
      </ListItem>
    );
  }

  deleteWorkTaskUser(workTaskUser) {
    this.setState((prevState) => ({
      updatingWorkTaskUsers: [...prevState.updatingWorkTaskUsers, { ...workTaskUser, _destroy: 1 }],
      inputChanged: true,
    }));
  }

  renderListItem(user, index) {
    const { updatingWorkTaskUsers } = this.state;

    const foundPerson = updatingWorkTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id);
    let checked = foundPerson ? true : false;

    return (
      <ListItem
        key={index}
        className="availability-list-item"
        style={{
          overflow: 'visible',
          height: 'initial',
          minHeight: '48px',
          color: 'white',
          paddingLeft: 0,
        }}
        onClick={() => {
          this.handleWorkTaskUserCheckbox(checked, user);
        }}
      >
        <Checkbox className="work-task-participants-dialog-checkbox" checked={checked} />
        {`${user.firstName} ${user.lastName}`}
      </ListItem>
    );
  }

  handleWorkTaskUserCheckbox(checked, user) {
    const { updatingWorkTaskUsers } = this.state;
    const tempWorkTaskUsers = [...updatingWorkTaskUsers];

    if (!checked) {
      tempWorkTaskUsers.push(new WorkTaskUser({ user: { id: user.id } }));
      this.setState({
        updatingWorkTaskUsers: tempWorkTaskUsers,
        inputChanged: true,
      });
    } else {
      const remainingWorkTaskUsers = tempWorkTaskUsers.filter((workTaskUser) => workTaskUser.user.id !== user.id);
      this.setState({
        updatingWorkTaskUsers: remainingWorkTaskUsers,
      });
    }
  }

  attachmentUpload(disabled, data, inputRef) {
    const { t, handleAttachmentAction, id } = this.props;
    const { files, checkedFiles } = this.state;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={true}
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;
            const body = {
              ...data,
              files: signedIds,
            };

            handleAttachmentAction(body);
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t)}

            <ListItemMeta
              style={{
                display: 'flex',
                justifyContent: checkedFiles.length !== 0 ? 'space-between' : 'end',
                marginTop: '20px',
              }}
            >
              <Button {...{
                disabled,
                type: 'button',
                label: 'Lisää tiedosto',
                icon: 'description',
                outlined: true,
                // theme: 'secondary',
                // className: 'ml-1',
                onClick: () => inputRef.current.click(),
                style: {
                  // color: buttonColors.green.color,
                  color: '#FFC900',
                  borderRadius: '0px',
                  fontSize: '.75rem',
                  textTransform: 'none',
                },
              }}
              />
            </ListItemMeta>
          </>
        )}

        required
      />
    );
  }

  addTextField(attr, label, required, styles = null, textarea) {
    const { mode } = this.props;
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        rows: 5,
        // invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState({
            [attr]: value,
            inputChanged: true,
          });
        },
        onBlur: (event) => {
          if (attr === 'identifierOne' && event.target.value) {
            this.setState({
              showHiddenInputs: true,
            });
          }
        },
        rootProps: { ripple: false },
        // onKeyPress: handleEnter,
        // theme: mode !== 'employer' && ['textPrimaryOnDark', 'secondaryBg'],
        style: { width: '100%' },
        className: mode !== 'employer' && 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
        value: this.state[attr] != null ? this.state[attr] : '',
      }}
      />
    );
  }

  // addTextArea(attr, label, required, styles = null) {
  //   return (
  //     <TextArea
  //       className="mui-textfield-employer"
  //       required={required}
  //       label={label}
  //       styles={styles}
  //       value={this.state[attr] != null ? this.state[attr] : ''}
  //       onChange={(event) => {
  //         const { value } = event.target;
  //         this.setState({
  //           [attr]: value,
  //           inputChanged: true,
  //         });
  //       }}
  //     />
  //   );
  // }

  addNumberField(attr, label, required, styles = null) {
    const { mode } = this.props;
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles, width: '100%' },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        type: 'number',
        step: 'any',
        onChange: (event) => {
          const { value } = event.target;
          delete errors[attr];
          this.setState({
            [attr]: value,
            inputChanged: true,
            errors: { ...errors }
          });
        },
        // onBlur: (event) => {
        //   required && this.handleBlur(attr, event)
        // },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: this.state[attr] != null ? this.state[attr] : '',
        // theme: mode !== 'employer' && ['textPrimaryOnDark', 'secondaryBg'],
        className: mode !== 'employer' && 'mui-textfield-dark mui-label-dark mdc-theme--secondary-bg mdc-theme--text-primary-on-dark',
      }}
      />
    );
  }

  updateChecked(signedId) {
    const { checkedFiles } = this.state;
    if (checkedFiles.includes(signedId)) {
      //
      this.setState((prevState) => ({
        checkedFiles: prevState.checkedFiles.filter((item) => item !== signedId),
      }));
    } else {
      this.setState((prevState) => ({
        checkedFiles: [...prevState.checkedFiles, signedId],
      }));
    }
  }

  renderUploadedFile(file) {
    const { confirmDelete, workOrderId } = this.props;
    const { generatingPdf, generatingPdfFiles, checkedFiles, parentId, id } = this.state;

    const filename = file.filename;
    const fileURL = file.fileURL;
    const signedId = file.signedId;
    const fileExtension = filename.split('.').pop();
    const extensionsForPDF = ['jpg', 'png', 'jpeg'];

    const createdAt = moment(file.createdAt).local().format('dd DD.MM. HH:mm');

    // We want to hide checked files during PDF generation
    // generating PDF = true
    // AND
    // generatingPdfFiles.includes
    if ((generatingPdfFiles.includes(file?.signedId) && generatingPdf) === false) {
      return (
        <GridInner key={fileURL}>
          <GridCell
            span={12}
            style={{
              display: 'flex',
              // flexDirection: 'column',
              // alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // alignSelf: 'center',
            }}
          >
            {/* {extensionsForPDF.includes(fileExtension.toLowerCase())
              ? (
                <Checkbox
                  checked={checkedFiles.includes(signedId)}
                  onChange={() => {
                    this.updateChecked(signedId);
                  }}
                />
              )
              : (
                <div style={{ height: '40px', width: '40px' }}></div>
              )
            } */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <a
                // href={fileURL}
                // download={filename}
                style={{
                  color: 'var(--mdc-theme-primary, #6200ee)',
                  cursor: 'pointer',
                  // fontSize: '14px'
                }}
                onClick={() => {
                  this.setState({
                    fileUrl: fileURL,
                    fileName: filename,
                    showFilePreviewDialog: true,
                  })
                }}
              >
                {filename}
              </a>

              <span
                style={{ color: 'white', textTransform: 'capitalize', fontSize: '12px', marginTop: '-5px' }}
              >
                {createdAt}
              </span>
            </div>
          </GridCell>
          {/* <GridCell
            desktop={3}
            phone={1}
            style={{
              justifySelf: 'flex-end',
            }}
          >
            {signedId && <IconButton
              type="button"
              style={{
                color: '#645F5F',
              }}
              icon="delete_outlined"
              onClick={() => {
                confirmDelete('file', id, workOrderId, filename, signedId, parentId);
              }}
            />}
            {!signedId && <div style={{ height: '48px' }} />}
          </GridCell> */}
        </GridInner>
      );
    } else {
      return null;
    }
  }

  renderBottomButtons() {
    const { mode } = this.props;
    const {
      inputChanged,
      id,
      identifierOne,
    } = this.state;

    if (inputChanged) {
      return (
        <DialogActions
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(identifierOne);
            // }}
            action="accept"
          >
            Tallenna
          </DialogButton>

          <DialogButton
            type="button"
            className={mode === 'employer' ? 'employer-reject-button' : 'cancel-button'}
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className={mode === 'employer' ? 'employer-reject-button' : 'cancel-button'}
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      fileUrl: null,
      fileName: null,
    })
  }

  confirmDeleteWorkTask() {
    this.setState({ showDeleteDialog: true });
  }

  closeDeleteDialog() {
    this.setState({
      showDeleteDialog: false,
      errorResponse: null,
    })
  }

  handleDeleteWorkTask() {
    const { workTaskStore, workOrderId, handleClose, deleteWorkTaskState } = this.props;
    const { id } = this.state;

    workTaskStore.delete(workOrderId, id).then((res) => {
      if (res === 'entries-error') {
        this.setState({
          errorResponse: 'Poisto epäonistui. Työllä on kirjauksia.',
        });
      } else {
        deleteWorkTaskState(id);
        this.closeDeleteDialog();
        handleClose();
      }
    });
  }

  renderDeleteWorkPeriodDialog() {
    const { showDeleteDialog, errorResponse } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeleteDialog}
        onClose={() => this.closeDeleteDialog()}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <p>Haluatko varmasti poistaa työn?</p>
          {errorResponse && (
            <p style={{ color: 'red' }}>
              {errorResponse}
            </p>
          )}
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => this.handleDeleteWorkTask()}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => this.closeDeleteDialog()}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { open, styles, workOrderId, handleAttachmentAction, handleClose, workTask, mode, employeesCanAddFilesToWorkTasks } = this.props;
    const { id, identifierOne, identifierTwo, description, files, estimate, parentId, workOrderParticipants, updatingWorkTaskUsers, generatingPdf, errors, showFilePreviewDialog, fileUrl, fileName, showHiddenInputs } = this.state;
    const inputRef = React.createRef();
    const sortedFiles = files?.sort(this.sortByCreatedAtAsc);

    return (
      <>
        <Dialog
          className={mode === 'employer' ? 'modal-size employer-modal' : 'modal-size trip-route-dialog'}
          open={open}
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              // const error = validate['identifierOne'];

              // A hack:
              // Errors are also set into the state but it is async and not fast enough, so we use the returned errors directly here
              const tempErrors = this.handleValidate(identifierOne);

              if (Object.keys(tempErrors).length === 0) {
                // this.handleSubmit({ ...this.state, parentId: workTask?.parentId, workOrderId: workOrderId });
                let signedIds = sortedFiles?.map((file) => {
                  return file['signedId'];
                });
                handleAttachmentAction({ ...this.state, parentId: workTask?.parentId, workOrderId: workOrderId, files: signedIds, workTaskUsers: updatingWorkTaskUsers });
                // Close the dialog through the parent component
                handleClose();
              }
            } else {
              // Close the dialog through the parent component
              handleClose();
            }
          }}
        >
          <DialogTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-25px' }}>
              <div
                style={{ color: mode !== 'employer' && '#FFC900' }}
              >
                {id ? 'Muokkaa työtä' : 'Uusi työ'}
              </div>
              {/* {id && (
                <IconButton
                  type='button'
                  onClick={() => this.confirmDeleteWorkTask()}
                  style={{ color: '#645F5F' }}
                  icon="delete_outlined"
                >
                </IconButton>
              )} */}
            </div>
          </DialogTitle>
          <DialogContent>
            <Grid style={{ padding: 0 }}>
              <GridCell
                className="employer-work-order"
                span={12}
              >
                {this.addTextField('identifierOne', 'Tunniste 1', true, styles, false)}
              </GridCell>

              {showHiddenInputs && (
                <>
                  <GridCell
                    span={12}
                  >
                    {this.addTextField('identifierTwo', 'Tunniste 2', false, styles, false)}
                  </GridCell>
                  <GridCell
                    span={12}
                  >
                    {this.addTextField('description', 'Kuvaus', false, styles, true)}
                  </GridCell>

                  <GridCell
                    span={12}
                  >
                    {this.addNumberField('estimate', 'Tuntiarvio', false, styles)}
                  </GridCell>

                  <GridCell
                    span={12}
                  >
                    {sortedFiles?.map((file) => {
                      return this.renderUploadedFile(file);
                    })}

                    {employeesCanAddFilesToWorkTasks && (
                      <div style={{ textAlign: 'end' }}>
                        {this.attachmentUpload(
                          (identifierOne === ''),
                          {
                            id,
                            identifierOne,
                            identifierTwo,
                            description,
                            workOrderId,
                            parentId,
                            workTaskUsers: updatingWorkTaskUsers,
                            estimate,
                          },
                          inputRef,
                        )}
                      </div>
                    )}
                    {/* {errors.attachments && (
                      <p
                        style={{
                          color: 'red',
                          padding: '0px 16px',
                          fontSize: '.75rem',
                        }}
                      >
                        Sisältö tai tiedosto on pakollinen
                      </p>
                    )} */}
                  </GridCell>
                </>
              )}

              <GridCell span={12}>
                {/* Conditional rendering check is inside renderWorkTaskUsers */}
                {this.renderWorkTaskUsers()}
              </GridCell>
              <GridCell span={12}>
                {workOrderParticipants.length > 0 && this.renderListGroup()}
              </GridCell>
            </Grid>
          </DialogContent>
          {this.renderBottomButtons()}
        </Dialog>

        <FilePreviewDialog
          open={showFilePreviewDialog}
          fileURL={fileUrl}
          fileName={fileName}
          onClose={this.closeFilePreviewDialog}
        />

        {this.renderDeleteWorkPeriodDialog()}
      </>
    )
  }
}

export default WorkTaskEmployeeCreateDialog;
