/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import { IconButton } from '@rmwc/icon-button';
import { Checkbox } from '@rmwc/checkbox';
import { Icon } from '@rmwc/icon';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import {
  List,
  ListGroup,
  ListItem,
  ListItemMeta,
  ListDivider,
  ListGroupSubheader,
  ListItemText,
} from '@rmwc/list';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { Typography } from '@rmwc/typography';
import { isEqual } from 'lodash';

import { DirectUploadProvider } from 'react-activestorage-provider';

import { buttonColors } from '../../calendar/EmployeeCalendar';
import { handleProgress } from '../../models/user/user-ui-avatar';
import WorkTaskUser from './WorkTaskUser';
import './WorkTaskDialog.css';
import FilePreviewDialog from '../../shared/FilePreviewDialog';
import WorkTaskParticipantsDialog from './WorkTaskParticipantsDialog';
import moment from '../../utils/moment';

// const handleEnter = (e) => {
//   if (e.key === 'Enter') {
//     document.activeElement.blur();
//   }
// };

const validateIdentifierOne = (identifierOne) => {
  if (!identifierOne) {
    return 'Tunniste 1 on pakollinen';
  }
  return null;
};

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  identifierOne: (identifierOne) => validateIdentifierOne(identifierOne),
};

@inject('workTaskStore', 'loginStore', 't') // employerWorkOrderStore
@observer
class WorkTaskEmployeeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.workTask?.id,
      identifierOne: props.workTask?.identifierOne,
      identifierTwo: props.workTask?.identifierTwo,
      description: props.workTask?.description,
      files: props.workTask?.files || [],
      errors: {},
      inputChanged: false,
      // This is used to store the to-be-updated or deleted workTaskUsers
      updatingWorkTaskUsers: [], // props.workTask?.workTaskUsers || [],
      // These are used to store files that were selected for PDF generation
      checkedFiles: [],
      // Kept separate from checkedFiles to prevent errors if there's multiple PDF generations going on at once
      generatingPdfFiles: [],
      parentId: props.workTask?.parentId,
      workTaskUsers: props.workTask?.workTaskUsers || [],
      workOrderParticipants: this.prunedWorkOrderParticipants(props.workTask?.workTaskUsers),
      showFilePreviewDialog: false,
      status: props.workTask?.status || { name: 'pending' },
      showWorkTaskParticipantsDialog: false,
    };
  }

  // Is is probably an overcomplicated way of ensuring that the dialog's state (the opened work task) stays up-to-date
  // Figure out a better solution, preferrably from an older edit dialog implementation
  componentDidUpdate(prevProps) {
    const { workTask } = this.props;
    // Does this actually work? prevWorkTask is always undefined?
    const { prevWorkTask } = prevProps;
    const { id, files } = this.state;

    if (!isEqual(workTask, prevWorkTask) && (workTask.id !== id || workTask?.files?.length !== files?.length)) {
      this.setState({
        id: workTask.id,
        identifierOne: workTask.identifierOne,
        identifierTwo: workTask.identifierTwo,
        description: workTask.description,
        parentId: workTask.parentId,
        files: workTask.files,
        inputChanged: false,
        workTaskUsers: workTask.workTaskUsers || [],
        workOrderParticipants: this.prunedWorkOrderParticipants(workTask.workTaskUsers),
        updatingWorkTaskUsers: [], // workTask.workTaskUsers || [],
        errors: {},
      });
    }
  }

  prunedWorkOrderParticipants(workTaskUsers = []) {
    // We want to remove users already added to workTaskUsers from the workOrderParticipants so that they can't be added twice
    const { workOrderParticipants = [] } = this.props;
    const workTaskUserIds = workTaskUsers.map((workTaskUser) => workTaskUser.user.id);
    const tempWorkOrderParticipants = [...workOrderParticipants].filter((participant) => !workTaskUserIds.includes(participant.id));


    this.setState({ workOrderParticipants: tempWorkOrderParticipants });
    return tempWorkOrderParticipants;
  }

  handleValidate(identifierOne) {
    let errors = {};

    // Not properly dynamic, you have to define the validate object but also have the values are parameters
    // Could be made better somehow? Get values from state using keys from the validate object?
    Object.keys(validate).forEach((key) => {
      const error = validate[key](identifierOne);

      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    this.setState({
      errors: { ...errors },
    });

    return errors;
  }

  // renderListGroup(category) {
  //   const { workOrderParticipants } = this.state;
  //   const participantIds = [];
  //   const uniqueParticipants = [];

  //   // Only one checkbox per user even though they might have multiple invitations
  //   workOrderParticipants.forEach((user) => {
  //     if (!participantIds.includes(user.id)) {
  //       participantIds.push(user.id);
  //       uniqueParticipants.push(user);
  //     }
  //   });

  //   return (
  //     <Elevation
  //       z={5}
  //       style={{ borderRadius: '0px 0px 3px 3px' }}
  //     >
  //       <List
  //         style={{
  //           marginTop: '10px',
  //           color: 'black',
  //           padding: '0px',
  //           // minHeight: '250px',
  //         }}
  //       >
  //         <ListGroup>
  //           <ListGroupSubheader
  //             style={{
  //               backgroundColor: '#FFC900', // colors[category].background,
  //               color: '#000000', // colors[category].font,
  //               margin: '0px',
  //               padding: '12px 16px',
  //               fontWeight: '500',
  //               fontSize: '14px',
  //             }}
  //           >
  //             <span>
  //               {/* {t(`work_order.availability.${category}`)} */}
  //               Lisää tekijöitä
  //             </span>
  //           </ListGroupSubheader>

  //           {uniqueParticipants?.map((user, index) => (
  //             <React.Fragment
  //               key={`${category}-${user.id}`}
  //             >
  //               {this.renderListItem(user, index)}
  //               {index !== workOrderParticipants.length - 1 && <ListDivider />}
  //             </React.Fragment>
  //           ))}
  //         </ListGroup>
  //       </List>
  //     </Elevation>
  //   );
  // }

  // renderWorkTaskUsers() {
  //   const { workTaskUsers, updatingWorkTaskUsers } = this.state;

  //   // Filter workTaskUsers, we do not want to render items that are found in the updating list (going to be deleted)
  //   let filteredWorkTaskUsers = [...workTaskUsers];
  //   if (updatingWorkTaskUsers.length > 0) {
  //     const updatingWorkTaskUsersIds = [...updatingWorkTaskUsers].map((workTaskUser) => workTaskUser.user.id);
  //     filteredWorkTaskUsers = filteredWorkTaskUsers.filter((workTaskUser) => !updatingWorkTaskUsersIds.includes(workTaskUser.user.id));
  //   }

  //   if (filteredWorkTaskUsers.length > 0) {
  //     return (
  //       <Elevation
  //         z={5}
  //         style={{ borderRadius: '0px 0px 3px 3px' }}
  //       >
  //         <List
  //           twoLine
  //           style={{
  //             marginTop: '10px',
  //             color: 'black',
  //             paddingTop: '0px',
  //             // minHeight: '250px',
  //           }}
  //         >
  //           <ListGroup>
  //             <ListGroupSubheader
  //               style={{
  //                 backgroundColor: '#7CD715',
  //                 color: '#000000',
  //                 margin: '0px',
  //                 padding: '12px 16px',
  //                 fontWeight: '500',
  //                 fontSize: '14px',
  //               }}
  //             >
  //               <span>
  //                 {/* {t(`work_order.availability.invited`)} */}
  //                 Työn tekijät
  //               </span>
  //             </ListGroupSubheader>

  //             {filteredWorkTaskUsers.map((workTaskUser, index) => (
  //               <React.Fragment
  //                 key={`workTaskUser-${workTaskUser.user.id}`}
  //               >
  //                 {this.renderWorkTaskUser(workTaskUser, index)}
  //                 {index !== workTaskUsers.length - 1 && <ListDivider />}
  //               </React.Fragment>
  //             ))}
  //           </ListGroup>
  //         </List>
  //       </Elevation>
  //     );
  //   }
  //   return null;
  // }

  closeWorkTaskParticipantsDialog = () => {
    this.setState({
      showWorkTaskParticipantsDialog: false,
    });
  }

  renderWorkTaskUser(workTaskUser, index) {
    // const { workTaskUsers } = this.state;
    // const foundWorkTaskUser = workTaskUsers.find((task) => task.user.id === workTaskUser.user.id);

    return (
      <ListItem
        key={index}
        // className="availability-list-item"
        style={{
          overflow: 'visible',
        }}
      >
        <Icon
          style={{
            width: '40px'
          }}
          icon={
            <div
              style={{
                background: 'limegreen',
                width: '24px',
                height: '24px',
                borderRadius: '100px'
              }}
            />
          }
        />
        <ListItemText style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          {`${workTaskUser.user.name}`}
        </ListItemText>
        <ListItemMeta>
          <IconButton
            type='button'
            onClick={() => this.deleteWorkTaskUser(workTaskUser)}
            style={{ color: '#645F5F' }}
            icon="delete_outlined"
          >
          </IconButton>
        </ListItemMeta>
      </ListItem>
    );
  }

  deleteWorkTaskUser(workTaskUser) {
    this.setState((prevState) => ({
      updatingWorkTaskUsers: [...prevState.updatingWorkTaskUsers, { ...workTaskUser, _destroy: 1 }],
      inputChanged: true,
    }));
  }

  // renderListItem(user, index) {
  //   const { updatingWorkTaskUsers } = this.state;

  //   const foundPerson = updatingWorkTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id);
  //   let checked = foundPerson ? true : false;

  //   return (
  //     <ListItem
  //       key={index}
  //       className="availability-list-item"
  //       style={{
  //         overflow: 'visible',
  //         height: 'initial',
  //         minHeight: '48px',
  //       }}
  //       onClick={() => {
  //         this.handleWorkTaskUserCheckbox(checked, user);
  //       }}
  //     >
  //       <Checkbox checked={checked} />
  //       {`${user.firstName} ${user.lastName}`}
  //     </ListItem>
  //   );
  // }

  // handleWorkTaskUserCheckbox(checked, user) {
  //   const { updatingWorkTaskUsers } = this.state;
  //   const tempWorkTaskUsers = [...updatingWorkTaskUsers];

  //   if (!checked) {
  //     tempWorkTaskUsers.push(new WorkTaskUser({ user: { id: user.id } }));
  //     this.setState({
  //       updatingWorkTaskUsers: tempWorkTaskUsers,
  //       inputChanged: true,
  //     });
  //   } else {
  //     const remainingWorkTaskUsers = tempWorkTaskUsers.filter((workTaskUser) => workTaskUser.user.id !== user.id);
  //     this.setState({
  //       updatingWorkTaskUsers: remainingWorkTaskUsers,
  //     });
  //   }
  // }

  handleGeneratePDF = (id, checkedFiles, workTask, callback = null) => {
    const { workTaskStore, deleteWorkTaskFileFromState } = this.props;
    this.setState({
      generatingPdf: true,
    });

    const generatePromise = fromPromise(new Promise((resolve, reject) => workTaskStore.generatePDF(workTask.id, workTask.parentId, workTask.workOrderId, checkedFiles, resolve, reject)));
    when(
      () => generatePromise.state !== "pending",
      () => {
        generatePromise.case({
          pending: () => {
          },
          rejected: (err) => {
            this.setState({
              generatingPdf: false,
            }, () => {
              if (callback) {
                callback(checkedFiles);
              }
            });
            // Render snackbar from a prop method here
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: () => {
            // Remove the deleted files used to generate the PDF from the state
            deleteWorkTaskFileFromState(workTask.id, workTask.parentId, checkedFiles, this.generatePdfComplete);
          }
        })
      }
    )
  }

  generatePdfComplete = () => {
    this.setState({
      generatingPdf: false,
    })
  }

  attachmentUpload(disabled, data, inputRef) {
    const { t, handleAttachmentAction, id } = this.props;
    const { files, checkedFiles } = this.state;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={true}
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;
            const body = {
              ...data,
              files: signedIds,
            };

            handleAttachmentAction(body);
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t, 'white')}

            <ListItemMeta
              style={{
                display: 'flex',
                justifyContent: checkedFiles.length !== 0 ? 'space-between' : 'end',
                marginTop: '20px',
              }}
            >
              {checkedFiles.length !== 0 && (
                <Button
                  {...{
                    type: 'button',
                    label: "Luo PDF",
                    icon: 'picture_as_pdf',
                    outlined: true,
                    theme: 'secondary',
                    // outlined: true,
                    onClick: () => {
                      this.handleGeneratePDF(id, checkedFiles, { ...data, files }, this.removeFromFiles);
                      this.setState((prevState) => ({
                        checkedFiles: [],
                        // Appropriate emptying of the generatingPdfFiles will happen in the handleGeneratePdf callback
                        generatingPdfFiles: [...prevState.generatingPdfFiles, ...prevState.checkedFiles],
                      }));
                    },
                    style: {
                      // color: buttonColors.green.color,
                      borderRadius: '0px',
                      fontSize: '.75rem',
                      textTransform: 'none',
                    },
                  }}
                />
              )}
              <Button {...{
                className: 'upload-attachment-button',
                disabled,
                type: 'button',
                label: 'Lisää kuva',
                icon: 'description',
                outlined: true,
                theme: 'secondary',
                // className: 'ml-1',
                onClick: () => inputRef.current.click(),
                style: {
                  borderRadius: '0px',
                  fontSize: '.75rem',
                  textTransform: 'none',
                },
              }}
              />
            </ListItemMeta>
          </>
        )}

        required
      />
    );
  }

  removeFromFiles = (signedIds) => {
    this.setState((prevState) => ({
      checkedFiles: prevState.checkedFiles.filter(item => signedIds.includes(item)),
      generatingPdfFiles: prevState.generatingPdfFiles.filter(item => signedIds.includes(item)),
    }));
  }

  addTextField(attr, label, required, styles = null, textarea) {
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        rows: 5,
        // invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState({
            [attr]: value,
            inputChanged: true,
          });
        },
        rootProps: { ripple: false },
        // onKeyPress: handleEnter,
        value: this.state[attr] != null ? this.state[attr] : '',
      }}
      />
    );
  }

  updateChecked(signedId) {
    const { checkedFiles } = this.state;
    if (checkedFiles.includes(signedId)) {
      //
      this.setState((prevState) => ({
        checkedFiles: prevState.checkedFiles.filter((item) => item !== signedId),
      }));
    } else {
      this.setState((prevState) => ({
        checkedFiles: [...prevState.checkedFiles, signedId],
      }));
    }
  }

  renderUploadedFile(file) {
    const { confirmDelete, workOrderId } = this.props;
    const { generatingPdf, generatingPdfFiles, checkedFiles, parentId, id } = this.state;

    const filename = file.filename;
    const fileURL = file.fileURL;
    // const signedId = file.signedId;
    // const fileExtension = filename.split('.').pop();
    // const extensionsForPDF = ['jpg', 'png', 'jpeg'];

    const createdAt = moment(file.createdAt).local().format('dd DD.MM. HH:mm');

    // We want to hide checked files during PDF generation
    // generating PDF = true
    // AND
    // generatingPdfFiles.includes
    if ((generatingPdfFiles.includes(file?.signedId) && generatingPdf) === false) {
      return (
        <GridInner key={fileURL}>
          <GridCell
            // desktop={8}
            // phone={3}
            span={12}
            style={{
              display: 'flex',
              flexDirection: 'column',
              // alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              // alignSelf: 'center',
            }}
          >
            {/* {extensionsForPDF.includes(fileExtension.toLowerCase())
              ? (
                <Checkbox
                  checked={checkedFiles.includes(signedId)}
                  onChange={() => {
                    this.updateChecked(signedId);
                  }}
                />
              )
              : (
                <div style={{ height: '40px', width: '40px' }}></div>
              )
            } */}
            <a
              // href={fileURL}
              // download={filename}
              style={{
                color: 'var(--mdc-theme-primary, #6200ee)',
                cursor: 'pointer',
                padding: '10px 0 0 0',
                // fontSize: '14px'
              }}
              onClick={() => {
                this.setState({
                  fileUrl: fileURL,
                  fileName: filename,
                  showFilePreviewDialog: true,
                })
              }}
            >
              {filename}
            </a>

            <span
              style={{ color: 'white', textTransform: 'capitalize', fontSize: '12px', marginLeft: '10px' }}
            >
              {createdAt}
            </span>
          </GridCell>
          {/* <GridCell
            desktop={3}
            phone={1}
            style={{
              justifySelf: 'flex-end',
            }}
          >
            {signedId && <IconButton
              type="button"
              style={{
                // color: '#645F5F',
                color: 'white',
              }}
              icon="delete_outlined"
              onClick={() => {
                confirmDelete('file', id, workOrderId, filename, signedId, parentId);
              }}
            />}
            {!signedId && <div style={{ height: '48px' }} />}
          </GridCell> */}
        </GridInner>
      );
    } else {
      return null;
    }
  }

  renderBottomButtons() {
    const { mode } = this.props;
    const {
      inputChanged,
      id,
      identifierOne,
    } = this.state;

    if (inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            // onClick={() => {
            //   this.handleValidate(identifierOne);
            // }}
            action="accept"
          >
            Tallenna
          </DialogButton>

          <DialogButton
            type="button"
            className={mode === 'employer' ? 'employer-reject-button' : 'cancel-button'}
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className={mode === 'employer' ? 'employer-reject-button' : 'cancel-button'}
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      fileUrl: null,
      fileName: null,
    })
  }

  setStatus(status) {
    this.setState((prevState) => ({
      selectedStatus: status,
      inputChanged: !isEqual(prevState.selectedStatus, status),
    }));
  }

  updateWorkTaskUsers = (updatingWorkTaskUsers) => {
    this.setState((prevState) => ({
      updatingWorkTaskUsers: updatingWorkTaskUsers,
      inputChanged: !isEqual(prevState.updatingWorkTaskUsers, updatingWorkTaskUsers),
    }));
  }

  renderWorkTaskUsers() {
    const { workTask, workOrderParticipants, workTaskEmployeeManagementEnabled } = this.props;
    const { updatingWorkTaskUsers } = this.state;

    const workTaskUsers = workTask?.workTaskUsers || [];

    // Match updatingWorkTaskUsers (user ids) with workOrderParticipants (actual user info)
    const workTaskParticipants = workOrderParticipants.filter((user) => workTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id));
    const workTaskPendingParticipants = workOrderParticipants.filter((user) => updatingWorkTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id));
    const totalParticipants = [...workTaskParticipants, ...workTaskPendingParticipants].sort((a, b) => a.firstName.toUpperCase() < b.firstName.toUpperCase());
    // Remove the deleted users from render (ugly code)
    const filteredTotalParticipants = totalParticipants.filter((user) => !updatingWorkTaskUsers.find((workTaskUser) => workTaskUser.user.id === user.id && workTaskUser._destroy === 1));

    if (filteredTotalParticipants.length === 0) {
      return (
        <span>{workTaskEmployeeManagementEnabled ? 'Lisää työn tekijöitä' : 'Ei tekijöitä' }</span>
      );
    }

    if (filteredTotalParticipants.length > 3) {
      return (
        <span>
          <span>{`${filteredTotalParticipants[0].getFullNameShort()}, `}</span>
          <span>{`${filteredTotalParticipants[1].getFullNameShort()}, `}</span>
          <span>{`${filteredTotalParticipants[2].getFullNameShort()}, `}</span>
          <span>{filteredTotalParticipants.length - 3} muuta</span>
        </span>
      );
    }

    return (
      <span>
        {filteredTotalParticipants.map((user, index) => (
          <span>{index + 1 === filteredTotalParticipants.length ? user.getFullNameShort() : `${user.getFullNameShort()}, `}</span>
        ))}
      </span>
    );
  }

  render() {
    const { open, styles, workOrderId, handleAttachmentAction, handleClose, workTask, mode, workOrderParticipants, workTaskEmployeeManagementEnabled, employeesCanAddFilesToWorkTasks } = this.props;
    const { id, identifierOne, identifierTwo, description, files, parentId, updatingWorkTaskUsers, generatingPdf, errors, showFilePreviewDialog, fileUrl, fileName, showWorkTaskParticipantsDialog, workTaskUsers, selectedStatus } = this.state;
    const inputRef = React.createRef();
    const sortedFiles = files?.sort(this.sortByCreatedAtAsc);

    return (
      <>
        <Dialog
          className={mode === 'employer' ? "modal-size employer-modal employer-work-task-dialog" : "modal-size employee-work-task-dialog trip-route-dialog"}
          open={open}
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              // const error = validate['identifierOne'];

              // A hack:
              // Errors are also set into the state but it is async and not fast enough, so we use the returned errors directly here
              const tempErrors = this.handleValidate(identifierOne);

              if (Object.keys(tempErrors).length === 0) {
                // this.handleSubmit({ ...this.state, parentId: workTask?.parentId, workOrderId: workOrderId });
                let signedIds = sortedFiles?.map((file) => {
                  return file['signedId'];
                });
                handleAttachmentAction({ ...this.state, parentId: workTask?.parentId, workOrderId: workOrderId, files: signedIds, workTaskUsers: updatingWorkTaskUsers, statusId: selectedStatus });
                // Close the dialog through the parent component
                handleClose();
              }
            } else {
              // Close the dialog through the parent component
              handleClose();
            }
          }}
        >
          {mode === 'employer' ? (
            <DialogTitle>
              {id ? 'Muokkaa työtä' : 'Uusi työ'}
            </DialogTitle>
          ) : (
            <DialogTitle>
              {identifierTwo ? `${identifierOne} - ${identifierTwo}` : identifierOne}
            </DialogTitle>
          )}
          <DialogContent>
            <Grid>
              {mode === 'employer' ? (
                <>
                  <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    {this.addTextField('identifierOne', 'Tunniste 1', true, styles, false)}
                  </GridCell>
                  <GridCell
                    span={12}
                  >
                    {this.addTextField('identifierTwo', 'Tunniste 2', false, styles, false)}
                  </GridCell>
                  <GridCell
                    span={12}
                  >
                    {this.addTextField('description', 'Kuvaus', false, styles, true)}
                  </GridCell>
                </>
              ) : (
                <GridCell
                  className="employer-work-order"
                  span={12}
                >
                  {/* <div style={{ fontSize: '16px' }}>
                    {identifierTwo ? `${identifierOne}-${identifierTwo}` : identifierOne}
                  </div> */}
                  <div style={{ color: 'white', whiteSpace: 'pre-wrap', fontSize: '14px' }}>
                    {description}
                  </div>
                </GridCell>
              )}

              {/*
              <GridCell
                span={12}
              >
                {mode === 'employer' ? (
                  <Typography
                    style={{
                      paddingLeft: '25px',
                      flexGrow: 1,
                    }}
                    use="headline6"
                    tag="h2"
                    className="headline"
                  >
                    Tila
                  </Typography>
                ) : (
                  <div
                    style={{
                      margin: '0px -10px 5px -10px',
                      paddingBottom: '5px',
                      borderBottom: '1px solid var(--mdc-theme-primary)',
                    }}
                  >
                    <Typography
                      use="subtitle1"
                      style={{
                        paddingLeft: '10px',
                        color: 'white',
                      }}
                    >
                      Tila
                    </Typography>
                  </div>
                )}
              </GridCell>

              <GridCell
                span={12}
              >
                <div className="status-buttons">
                  {workTask.statuses?.map((status) => (
                    <Button {...{
                      className: selectedStatus === status.id ? 'active-button upload-attachment-button' : 'upload-attachment-button',
                      type: 'button',
                      // label: status.name === 'started' ? 'Aloitettu' : 'Aloita työ',
                      label: status.name,
                      // icon: 'description',
                      outlined: true,
                      theme: 'secondary',
                      // className: 'ml-1',
                      onClick: () => this.setStatus(status.id),
                      style: {
                        borderRadius: '0px',
                        fontSize: '.75rem',
                        textTransform: 'none',
                      },
                    }}
                    />
                  ))}

                  <Button {...{
                    className: status.name === 'pending' ? 'active-button upload-attachment-button' : 'upload-attachment-button',
                    type: 'button',
                    label: status.name === 'pending' ? 'Työjonossa' : 'Siirrä työjonoon',
                    // icon: 'description',
                    outlined: true,
                    theme: 'secondary',
                    // className: 'ml-1',
                    onClick: () => this.setStatus({ name: 'pending' }),
                    style: {
                      borderRadius: '0px',
                      fontSize: '.75rem',
                      textTransform: 'none',
                    },
                  }}
                  />

                  <Button {...{
                    className: status.name === 'started' ? 'active-button upload-attachment-button' : 'upload-attachment-button',
                    type: 'button',
                    label: status.name === 'started' ? 'Aloitettu' : 'Aloita työ',
                    // icon: 'description',
                    outlined: true,
                    theme: 'secondary',
                    // className: 'ml-1',
                    onClick: () => this.setStatus({ name: 'started' }),
                    style: {
                      borderRadius: '0px',
                      fontSize: '.75rem',
                      textTransform: 'none',
                    },
                  }}
                  />

                  <Button {...{
                    className: status.name === 'finished' ? 'active-button upload-attachment-button' : 'upload-attachment-button',
                    type: 'button',
                    label: status.name === 'finished' ? 'Päätetty' : 'Päätä työ',
                    // icon: 'description',
                    outlined: true,
                    theme: 'secondary',
                    // className: 'ml-1',
                    onClick: () => this.setStatus({ name: 'finished' }),
                    style: {
                      borderRadius: '0px',
                      fontSize: '.75rem',
                      textTransform: 'none',
                    },
                  }}
                  />
                </div>
              </GridCell>
              */}

              <GridCell
                span={12}
              >
                <div
                  style={{
                    display: 'flex',
                    color: 'white',
                    backgroundColor: 'var(--mdc-theme-secondary)',
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                  onClick={() => {
                    if (workTaskEmployeeManagementEnabled) {
                      this.setState({
                        showWorkTaskParticipantsDialog: true,
                      });
                    }
                  }}
                >
                  <PeopleOutlineIcon
                    className="work-task-card-icon"
                    fontSize="small"
                    style={{
                      marginRight: '10px',
                    }}
                  />
                  <span>
                    {/* {updatingWorkTaskUsers.length} */}
                    {this.renderWorkTaskUsers()}
                  </span>
                </div>
              </GridCell>


              <GridCell
                span={12}
              >
                {sortedFiles?.map((file) => {
                  return this.renderUploadedFile(file);
                })}

                {/* TODO: Backwards compatibility thing, REMOVE LATER */}
                {/* false ==== generatingPdf = true AND checkedFiles.includes TRUE */}
                {/* {generatingPdf && <div>Luodaan PDF-tiedostoa...</div>} */}
                {/* {file && this.renderUploadedFile(file)} */}

                {employeesCanAddFilesToWorkTasks && this.attachmentUpload(
                  (identifierOne === ''),
                  {
                    id,
                    identifierOne,
                    identifierTwo,
                    description,
                    workOrderId,
                    parentId,
                    workTaskUsers: updatingWorkTaskUsers,
                  },
                  inputRef,
                )}

                {/* {errors.attachments && (
                <p
                  style={{
                    color: 'red',
                    padding: '0px 16px',
                    fontSize: '.75rem',
                  }}
                >
                  Sisältö tai tiedosto on pakollinen
                </p>
              )} */}
              </GridCell>

              {/* <GridCell span={12}>
                {this.renderWorkTaskUsers()}
              </GridCell>
              <GridCell span={12}>
                {workOrderParticipants.length > 0 && this.renderListGroup()}
              </GridCell> */}

              {/* {inputChanged && (
            <GridCell
                span={12}
              >
                {this.addCheckBox('sendAsSms', t('work_order.labels.sendAsSms'))}
              </GridCell>
            )} */}

            </Grid>
          </DialogContent>
          {this.renderBottomButtons()}
        </Dialog>

        <FilePreviewDialog
          open={showFilePreviewDialog}
          fileURL={fileUrl}
          fileName={fileName}
          onClose={this.closeFilePreviewDialog}
        />

        <WorkTaskParticipantsDialog
          mode={mode}
          onClose={this.closeWorkTaskParticipantsDialog}
          open={showWorkTaskParticipantsDialog}
          workTaskUsers={workTaskUsers}
          workOrderParticipants={workOrderParticipants}
          updateWorkTaskUsers={this.updateWorkTaskUsers}
          updatingWorkTaskUsers={updatingWorkTaskUsers}
        />
      </>
    )
  }
}

export default WorkTaskEmployeeDialog;
