import { observable, action } from 'mobx';

class GlobalSnackbarStore {
  @observable open = false;

  @observable message = '';

  @action openSnackbar = (message) => {
    this.open = true;
    this.message = message;
  }

  @action onClose = (event, reason) => {
    console.log('SNACK BAR ON CLOSE: ', reason);
    if (reason === 'clickaway') {
      return;
    }
    this.open = false;
  };
}

export default GlobalSnackbarStore;
