import { action, observable, set } from 'mobx';

export default class AutocompleteLocation {
  @observable street;

  @observable zipCode;

  @observable city;

  @observable count;

  @observable name;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      street: o.street,
      zipCode: o.zip_code,
      city: o.city,
      name: o.name,
      count: o.count,
      tempIndex: o.tempIndex != null ? o.tempIndex : null,
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const autocompleteLocation = new AutocompleteLocation({});
    autocompleteLocation.updatePropertiesFromJson(object);
    return autocompleteLocation;
  }

  static fromJson(json) {
    return AutocompleteLocation.fromJsonProperties(JSON.parse(json));
  }
}
