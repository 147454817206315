import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import {
  IconButton,
  Icon,
  Button,
} from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { onDownload } from '../utils/datatableUtils';
import LoadingSpinner from '../shared/LoadingSpinner';
import FilePreviewDialog from '../shared/FilePreviewDialog';
import ProductFormDialog from './ProductFormDialog';

@inject('uiStore', 'loginStore', 't', 'productStore')
@observer
class Products extends Component {
  constructor(props) {
    super(props);

    const { productStore, uiStore: { currentUser } } = this.props;

    this.state = {
      productFormDialogOpen: false,
      openProduct: null,
    };

    productStore.findProducts(currentUser);
  }

  componentDidMount() {
    const { productStore: { products } } = this.props;

    products.then((data) => {
      this.setState({
        data,
      });
    });
  }

  // setColumns = (updatedColumns) => {
  //   this.setState({
  //     columns: updatedColumns,
  //   });
  // }

  closeProductFormDialog = () => {
    this.setState({
      productFormDialogOpen: false,
      openProduct: null,
    });
  }

  openProductFormDialog(data) {
    console.log('Opening dialog: ', data);
    this.setState({
      productFormDialogOpen: true,
      openProduct: data,
    });
  }

  renderTable() {
    const {
      data,
      showImageDialog,
      imageUrl,
      imageName,
    } = this.state;
    const { t, productStore } = this.props;

    const options = {
      filter: false,
      // filter: activePreset === 'basic' || activePreset === 'clothing' ? true : 'disabled',
      onDownload: (buildHead, buildBody, columns, data) => onDownload(buildHead, buildBody, columns, data), // eslint-disable-line
      // onFilterChange: ((_changedColumn, filterList, _type, changedColumnIndex, _displayData) => {
      //   // We need to update the state columns manually so that the default filters defined there aren't re-applied on re-render
      //   const updatedColumns = [...columns];
      //   updatedColumns[changedColumnIndex].options.filterList = filterList[changedColumnIndex];
      //   this.setState({ columns: updatedColumns });
      // }),
      onViewColumnsChange: (changedColumn, action) => {
        const columnIndex = columns.findIndex((col) => col.name === changedColumn); // eslint-disable-line
        if (columnIndex !== -1) {
          if (action === 'remove') {
            productStore.setProductDisplayColumns(changedColumn, false);
          } else if (action === 'add') {
            productStore.setProductDisplayColumns(changedColumn, true);
          }
        }
      },
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
        filename: 'tuotteet', // `${t(`employer_table.columnPresets.${activePreset}`)}_${moment().format('DD-MM-YYYY')}`,
      },
      draggableColumns: {
        enabled: true,
        transitionTime: 300,
      },
      enableNestedDataAccess: '.',
      selectableRows: 'none',
      responsive: 'standard',
      tableBodyMaxHeight: '800px',
      textLabels: {
        body: {
          noMatch: 'Tuotteita ei löytynyt',
        },
        toolbar: {
          search: t('employer_table.toolbar.search'),
          downloadCsv: t('employer_table.toolbar.downloadCsv'),
          print: t('employer_table.toolbar.print'),
          viewColumns: t('employer_table.toolbar.viewColumns'),
          filterTable: t('employer_table.toolbar.filterTable'),
        },
        pagination: {
          next: t('employer_table.pagination.next'),
          previous: t('employer_table.pagination.previous'),
          rowsPerPage: t('employer_table.pagination.rowsPerPage'),
          displayRows: t('employer_table.pagination.displayRows'),
        },
        filter: {
          all: t('employer_table.filter.all'),
          title: t('employer_table.filter.title'),
          reset: t('employer_table.filter.reset'),
        },
        viewColumns: {
          title: t('employer_table.viewColumns.title'),
          titleAria: t('employer_table.viewColumns.titleAria'),
        },
        selectedRows: {
          text: t('employer_table.selectedRows.text'),
          delete: t('employer_table.selectedRows.delete'),
          deleteAria: t('employer_table.selectedRows.deleteAria'),
        },
      },
    };

    const columns = [
      {
        name: 'name',
        label: 'Nimi',
        options: {
          filter: false,
          sort: true,
          display: productStore.productDisplayColumns.name === undefined ? true : productStore.productDisplayColumns.name,
        },
      },
      {
        name: 'code',
        label: 'Tuotekoodi',
        options: {
          filter: false,
          sort: true,
          display: productStore.productDisplayColumns.code === undefined ? true : productStore.productDisplayColumns.code,
        },
      },
      {
        name: 'description',
        label: 'Kuvaus',
        options: {
          filter: false,
          sort: true,
          display: productStore.productDisplayColumns.description === undefined ? true : productStore.productDisplayColumns.description,
        },
      },
      {
        name: 'created',
        label: 'Luotu',
        options: {
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => {
            const product = data[dataIndex];
            return (
              <div>
                <div style={{ textTransform: 'capitalize' }}>
                  {product.createdAt.format('dd DD.MM.YY HH:mm')}
                </div>
                {product.createdBy && (
                  <div style={{ fontSize: '13px' }}>
                    {product.createdBy}
                  </div>
                )}
              </div>
            );
          },
        },
      },
      {
        name: 'edit',
        label: 'Toiminnot',
        options: {
          sort: false,
          filter: false,
          empty: true,
          viewColumns: false,
          display: true,
          download: false,
          print: false,
          customBodyRenderLite: (dataIndex) => { // eslint-disable-line
            return (
              <div>
                <IconButton
                  aria-label="edit"
                  onClick={() => {
                    this.openProductFormDialog(data[dataIndex]);
                  }}
                >
                  <Icon style={{ color: 'black' }}>edit</Icon>
                </IconButton>

                {/* <IconButton
                  aria-label="delete"
                  onClick={() => {
                    this.openProductDeleteDialog(data[dataIndex]);
                  }}
                >
                  <Icon style={{ color: 'black' }}>delete</Icon>
                </IconButton> */}
              </div>
            );
          },
        },
      },
    ];

    return (
      <div>
        {!data && (
          <GridCell style={{ textAlign: 'center' }}>
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          </GridCell>
        )}
        {data && (
          <>
            {/* <div style={{ display: 'inline-block', marginLeft: '20px' }}>
              {Object.keys(presets).map((key) =>  tableSelectionButton(t, key, activePreset, this.toggleColumnVisibilityPreset))}
            </div> */}
            <div style={{ margin: '0px 20px' }}>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </div>
          </>
        )}

        <FilePreviewDialog
          open={showImageDialog}
          fileURL={imageUrl}
          fileName={imageName}
          onClose={this.closeFilePreviewDialog}
        />
      </div>
    );
  }

  render() {
    // const { uiStore: { currentUser: { accountId } } } = this.props;
    const {
      productFormDialogOpen,
      openProduct,
      data,
    } = this.state;

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              <div className="view-heading">
                <div style={{ flexDirection: 'column', justifyContent: 'center' }}>Tuotteet</div>
                {data && (
                  <Button
                    onClick={() => this.openProductFormDialog()}
                    className="employer-accept-button mdc-button"
                    style={{
                      borderRadius: '0px',
                      width: '150px',
                    }}
                  >
                    Uusi tuote
                  </Button>
                )}
              </div>

              {!data && (
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <LoadingSpinner color="black" />
                </div>
              )}

              {data && (
                this.renderTable()
              )}
            </GridCell>
          </GridInner>
        </Grid>

        <ProductFormDialog
          open={productFormDialogOpen}
          product={openProduct}
          handleClose={this.closeProductFormDialog}
        />
      </>
    );
  }
}

export default Products;
