import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Button } from '@material-ui/core';

import {
  Transition,
  animated,
  config,
} from 'react-spring';
import { groupBy } from 'lodash';

import FilterListIcon from '@material-ui/icons/FilterList';

import EmployerWorkOrderTripCard from './employerWorkOrderTripCard';
import WorkOrderTripOrphanCard from './workOrderTripOrphanCard';

// import StartTrip from '../timelog/StartTrip';
// import EndTrip from '../timelog/EndTrip';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import FilterDialog, { customFilterComparison } from '../shared/FilterDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
import TimelogTripDialog from '../timelog/timelog-trip-dialog';
import moment, { dateRange } from '../utils/moment';
import RouteLocation from '../timelog/RouteLocation';
import EmployeeInfoDialog from '../employer-timelog/EmployeeInfoDialog';

const TransitionedCards = (props) => {
  const {
    currentWorkOrderTripsWithMeta,
    updateWorkOrderTrip,
    openTripDialog,
    openEmployeeInfoDialog,
    afterUpdate,
  } = props;

  return (
    <Transition
      items={currentWorkOrderTripsWithMeta}
      keys={(item) => `transition_${item.id}`}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      // delay={100}
      config={config.default}
    >
      {(styles, item) => (
        <animated.div style={styles}>
          <EmployerWorkOrderTripCard
            workOrderTrip={item}
            key={`trip-card-${item.id}`}
            updateWorkOrderTrip={updateWorkOrderTrip}
            openTripDialog={openTripDialog}
            openEmployeeInfoDialog={openEmployeeInfoDialog}
            afterUpdate={afterUpdate}
          />
        </animated.div>
      )}
    </Transition>
  );
};

const TransitionedOrphanCards = (props) => {
  const {
    orphans,
    updateOrphans,
    updateWorkOrderTrip,
    openTripDialog,
    openEmployeeInfoDialog,
    afterUpdate,
  } = props;

  return (
    <Transition
      items={orphans}
      keys={(item) => `transition_${item.userName}_${item.workOrderName}`}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
      // delay={100}
      config={config.default}
    >
      {(styles, item) => (
        <animated.div style={styles}>
          <WorkOrderTripOrphanCard
            orphanObject={item}
            key={`trip-card-${item.id}`}
            updateOrphans={updateOrphans}
            updateWorkOrderTrip={updateWorkOrderTrip}
            openTripDialog={openTripDialog}
            openEmployeeInfoDialog={openEmployeeInfoDialog}
            afterUpdate={afterUpdate}
          />
        </animated.div>
      )}
    </Transition>
  );
};

const groupOrphans = (taxExemptTripExpenses, tripRoutes) => {
  const results = [];

  taxExemptTripExpenses.forEach((expense) => {
    const foundObjIndex = results.findIndex((obj) => obj.userName === expense.user_name && obj.workOrderName === expense.work_order_name);
    if (foundObjIndex !== -1) {
      results[foundObjIndex].orphans.push(expense);
      results[foundObjIndex].taxExemptTripExpenseIds.push(expense.id);

      if (!results[foundObjIndex].workOrderIds.includes(expense.work_order_id)) {
        results[foundObjIndex].workOrderIds.push(expense.work_order_id);
      }
    } else {
      results.push({
        userName: expense.user_name,
        userId: expense.user_id,
        workOrderName: expense.work_order_name,
        orphans: [expense],
        workOrderIds: [expense.work_order_id],
        taxExemptTripExpenseIds: [expense.id],
        tripRouteIds: [],
      });
    }
  });

  tripRoutes.forEach((tripRoute) => {
    const foundObjIndex = results.findIndex((obj) => obj.userName === tripRoute.user_name && obj.workOrderName === tripRoute.work_order_name);
    if (foundObjIndex !== -1) {
      results[foundObjIndex].orphans.push(tripRoute);
      results[foundObjIndex].tripRouteIds.push(tripRoute.id);

      if (!results[foundObjIndex].workOrderIds.includes(tripRoute.work_order_id)) {
        results[foundObjIndex].workOrderIds.push(tripRoute.work_order_id);
      }
    } else {
      results.push({
        userName: tripRoute.user_name,
        userId: tripRoute.user_id,
        workOrderName: tripRoute.work_order_name,
        orphans: [tripRoute],
        workOrderIds: [tripRoute.work_order_id],
        taxExemptTripExpenseIds: [],
        tripRouteIds: [tripRoute.id],
      });
    }
  });

  // Group the orphans by date for and then order by date for easier and more logical UI rendering
  const finalResults = results.map((obj) => {
    const sortedOrphans = obj.orphans.sort((a, b) => {
      if (a.work_hour_date < b.work_hour_date) {
        return -1;
      }
      return 1;
    });

    const groupedByTopCategory = { ...obj, orphans: groupBy(sortedOrphans, 'category') };

    // Group each taxExemptExpenses orphan category by name (e.g. allowance), for card rendering
    // groupedByTopCategory.map((groupedObj) => {
    const newObj = { ...groupedByTopCategory };
    if (newObj.orphans.taxExemptTripExpenses) {
      newObj.orphans.taxExemptTripExpenses = groupBy(newObj.orphans.taxExemptTripExpenses, 'name');
    }

    return newObj;
    // });
    // const groupedByTaxExemptTripExpenseCategory = { ...obj, orphans: { ...obj.orphans, 'taxExemptTripExpenses': groupBy(obj.orphans.taxExemptTripExpenses, 'name') } }
    // return groupedByTopCategory;
  });

  return finalResults;
};

const ungroupOrphans = (trip) => {
  const ungroupedOrphans = {
    tripRoutes: [],
    taxExemptTripExpenses: [],
  };

  if (Object.keys(trip.taxExemptTripExpenses).length > 0) {
    Object.keys(trip.taxExemptTripExpenses).forEach((taxExemptTripExpenseType) => {
      Object.keys(trip.taxExemptTripExpenses[taxExemptTripExpenseType]).forEach((salaryPeriod) => {
        Object.keys(trip.taxExemptTripExpenses[taxExemptTripExpenseType][salaryPeriod]).forEach((workOrderName) => {
          trip.taxExemptTripExpenses[taxExemptTripExpenseType][salaryPeriod][workOrderName].forEach((expense) => {
            ungroupedOrphans.taxExemptTripExpenses.push({
              ...expense,
              work_order_name: workOrderName,
              user_name: trip.userName,
              category: 'taxExemptTripExpenses',
              work_hour_date: moment(expense.date, 'YYYY-MM-DD'),
            });
          });
        });
      });
    });
  }

  if (Object.keys(trip.tripRoutes).length > 0) {
    Object.keys(trip.tripRoutes).forEach((salaryPeriod) => {
      Object.keys(trip.tripRoutes[salaryPeriod]).forEach((workOrderName) => {
        trip.tripRoutes[salaryPeriod][workOrderName].forEach((tripRoute) => {
          ungroupedOrphans.tripRoutes.push({
            ...tripRoute,
            work_order_name: workOrderName,
            user_name: trip.userName,
            category: 'tripRoutes',
            work_hour_date: moment(tripRoute.date, 'YYYY-MM-DD'),
          });
        });
      });
    });
  }

  return ungroupedOrphans;
};

@inject('uiStore', 'timelogStore', 't', 'actionCableStore', 'workOrderTripStore', 'employerContextStore')
@observer
class EmployerWorkOrderTrips extends Component {
  // cableApp = {};

  constructor(props) {
    super(props);

    this.state = {
      originalWorkOrderTripsWithMeta: null,
      currentWorkOrderTripsWithMeta: null,
      originalOrphans: null,
      currentOrphans: null,
      filterDialogOpen: false,
      filterCount: 0,
      // Must contain the FilterDialog defaultFilters
      // currentFilters: ['start_trip.status.pending||end_trip.status.pending'],
      currentFilters: [
        {
          // key: 'startTrip.status||endTrip.status',
          key: 'statuses',
          value: 'pending',
        },
      ],
      viewMode: 'cards',
      timelogTripDialogOpen: false,
      timelogTrip: null,
      showEmployeeInfoDialog: false,
      // scrollTimelogTripDialogToBottom: false,
    };

    this.toggleFilterDialog = this.toggleFilterDialog.bind(this);
    // this.toggleFilterHours = this.toggleFilterHours.bind(this);
    this.toggleFilterTrips = this.toggleFilterTrips.bind(this);
    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    const { actionCableStore } = this.props;

    this.getWorkOrderTrips();

    this.cable = actionCableStore.cableOn
      ? actionCableStore.subscribe('EmployerWorkHoursChannel', this.processUpdateResponse)
      : null;
  }

  componentWillUnmount() {
    const { employerContextStore, actionCableStore } = this.props;
    // actionCableStore

    if (this.cable) {
      actionCableStore.unsubscribe(this.cable);
    }

    employerContextStore.setCurrentEmployeeId(null);

    // Old way to unsubscribe()
    // if (this.cableApp.hours) {
    //   this.cableApp.hours.unsubscribe();
    // }
  }

  getWorkOrderTrips() {
    const { timelogStore } = this.props;

    timelogStore.getResponsibleEmployerWorkOrderTrips().then((data) => {
      /* eslint-disable no-param-reassign */
      let workOrderTrips = [];
      let originalOrphans = [];
      let groupedOrphans = [];

      if (data) {
        // eslint-disable-next-line camelcase
        workOrderTrips = data?.work_order_trips_with_meta.map((trip) => WorkOrderTrip.fromJsonProperties(trip));
        // data.forEach((workOrderTrip) => {
        //   workOrderTrip.start_trip = StartTrip.fromJsonProperties(workOrderTrip.start_trip);

        //   if (workOrderTrip.old_start_trip) {
        //     workOrderTrip.old_start_trip = StartTrip.fromJsonProperties(workOrderTrip.old_start_trip);
        //   }

        //   workOrderTrip.end_trip = EndTrip.fromJsonProperties(workOrderTrip.end_trip);
        //   workOrderTrip.end_trip.time = workOrderTrip.end_trip.time ? workOrderTrip.end_trip.time : null;
        //   workOrderTrip.end_trip.toTime = workOrderTrip.end_trip.toTime ? workOrderTrip.end_trip.toTime : null;

        //   if (workOrderTrip.old_end_trip) {
        //     workOrderTrip.old_end_trip = EndTrip.fromJsonProperties(workOrderTrip.old_end_trip);
        //   }
        // });

        // NOTE: Orphans are tax exempt trip expenses and trip routes without an associated work order trip
        const expensesFromJson = data.orphan_tax_exempt_trip_expenses?.map((expense) => {
          expense.work_hour_date = moment(expense.work_hour_date, 'YYYY-MM-DD');
          return expense;
        });
        groupedOrphans = groupOrphans(expensesFromJson || [], data.orphan_trip_routes || []);
        originalOrphans = { taxExemptTripExpenses: expensesFromJson || [], tripRoutes: data.orphan_trip_routes || [] };
      }

      this.setState({
        originalWorkOrderTripsWithMeta: workOrderTrips,
        currentWorkOrderTripsWithMeta: workOrderTrips,
        salaryPeriod: data.salary_period,
        originalOrphans,
        currentOrphans: groupedOrphans,
      }); // this.filterWorkOrderTrips
    });
  }

  processUpdateResponse = (response) => {
    if (response.type === 'trips') {
      this.getWorkOrderTrip(response.body.id);
    } else if (response.type === 'work_hour' && response.method === 'get') {
      this.getWorkHours(response.body.ids);
    }

    if (response.body.deleted_ids) {
      this.deleteWorkHours(response.body.deleted_ids);
    }
  }

  afterTripUpdate = (response) => {
    // A hack since employer trip update returns an object with work_order_trip_with_meta, update + accept returns work_order_trip_with_meta directly
    // eslint-disable-next-line camelcase
    const processedTripWithMeta = WorkOrderTrip.fromJsonProperties(response?.work_order_trip_with_meta || response);
    this.updateWorkOrderTrip(processedTripWithMeta);
  }

  getWorkHours = (ids) => {
    const { timelogStore } = this.props;
    timelogStore.getResponsibleEmployerTimelogsById(ids).then((hoursWithMeta) => {
      this.updateWorkHours(hoursWithMeta);
    });
  }

  deleteTripCard = (tripId) => {
    const { originalWorkOrderTripsWithMeta, currentWorkOrderTripsWithMeta } = this.state;

    const updatedOriginalWorkOrderTripsWithMeta = [...originalWorkOrderTripsWithMeta];
    const updatedCurrentWorkOrderTripsWithMeta = [...currentWorkOrderTripsWithMeta];

    const foundOriginalWorkOrderTripIndex = updatedOriginalWorkOrderTripsWithMeta.findIndex((item) => item.id === tripId);
    const foundCurrentWorkOrderTripIndex = updatedCurrentWorkOrderTripsWithMeta.findIndex((item) => item.id === tripId);

    if (foundOriginalWorkOrderTripIndex !== -1) {
      updatedOriginalWorkOrderTripsWithMeta.splice(foundOriginalWorkOrderTripIndex, 1);
    }
    if (foundCurrentWorkOrderTripIndex !== -1) {
      updatedCurrentWorkOrderTripsWithMeta.splice(foundCurrentWorkOrderTripIndex, 1);
    }

    this.setState({
      originalWorkOrderTripsWithMeta: updatedOriginalWorkOrderTripsWithMeta,
      currentWorkOrderTripsWithMeta: updatedCurrentWorkOrderTripsWithMeta,
    });
  };

  setActiveFilters = (currentFilters) => {
    this.setState({ currentFilters });
  }

  getWorkOrderTrip = (id) => {
    const { workOrderTripStore, uiStore: { currentUser } } = this.props;
    workOrderTripStore.getEmployerWorkOrderTripById(currentUser, id).then((tripWithMeta) => {
      const processedTripWithMeta = WorkOrderTrip.fromJsonProperties(tripWithMeta);

      this.updateWorkOrderTrip(processedTripWithMeta);
    });
  }

  updateWorkOrderTrip = (trip) => {
    // NOTE: This might be more efficient by replacing the two findIndex calls with just one loop per updated + original sets, then checking each member against the parameter workHours
    // In most cases the amount of workHours received as a parameter should be around 14 (two weeks, the recommended salary period length)
    const { originalWorkOrderTripsWithMeta, currentWorkOrderTripsWithMeta, currentFilters } = this.state;

    const updatedOriginalWorkOrderTripsWithMeta = [...originalWorkOrderTripsWithMeta];
    const updatedCurrentWorkOrderTripsWithMeta = [...currentWorkOrderTripsWithMeta];

    // Is the trip already in the original data?
    const foundOriginalWorkOrderTripIndex = updatedOriginalWorkOrderTripsWithMeta.findIndex((item) => item.id === trip.id);
    let originalTrip = null;

    if (foundOriginalWorkOrderTripIndex !== -1) {
      originalTrip = updatedOriginalWorkOrderTripsWithMeta[foundOriginalWorkOrderTripIndex];
      // Found, updating
      updatedOriginalWorkOrderTripsWithMeta[foundOriginalWorkOrderTripIndex] = trip;
    } else {
      // Not found, pushing
      updatedOriginalWorkOrderTripsWithMeta.push(trip);
    }

    // Is the trip already in the currently visible, potentially filtered data?
    const foundCurrentWorkOrderTripIndex = updatedCurrentWorkOrderTripsWithMeta.findIndex((item) => item.id === trip.id);
    // Does trip match the current filters?
    const filtersMatch = customFilterComparison(trip, currentFilters);

    if (foundCurrentWorkOrderTripIndex !== -1 && !filtersMatch) {
      // Found but filters do not match, removing
      updatedCurrentWorkOrderTripsWithMeta.splice(foundCurrentWorkOrderTripIndex, 1);
    } else if (foundCurrentWorkOrderTripIndex !== -1) {
      // Found and either the filters match or there's no filters at all, updating
      updatedCurrentWorkOrderTripsWithMeta[foundCurrentWorkOrderTripIndex] = trip;
    } else if (filtersMatch || currentFilters.length === 0) {
      // Not found but filters match or there's no filters at all, adding
      updatedCurrentWorkOrderTripsWithMeta.push(trip);
    }

    this.deleteOrAddOrphans(originalTrip, trip);

    this.setState({
      originalWorkOrderTripsWithMeta: updatedOriginalWorkOrderTripsWithMeta,
      currentWorkOrderTripsWithMeta: updatedCurrentWorkOrderTripsWithMeta,
    });
  }

  closeEmployeeInfoDialog = () => {
    this.setState({
      showEmployeeInfoDialog: false,
      employeeInfoDialogData: null,
    });
  }

  openTripDialog = (trip, defaultTripDate, employerContextWorkOrderId = null) => {
    this.setState({
      timelogTripDialogOpen: true,
      timelogTrip: trip,
      defaultTripDate,
      // We use this to insert the context of the work order from the orphan card so that the new trips are created under the correct work order
      employerContextWorkOrderId,
      // scrollTimelogTripDialogToBottom: scrollToBottom,
    });
  }

  openEmployeeInfoDialog = (employee) => {
    this.setState({
      showEmployeeInfoDialog: true,
      employeeInfoDialogData: employee,
    });
  }

  toggleTripDialog = () => {
    this.setState((prevState) => ({
      timelogTripDialogOpen: !prevState.timelogTripDialogOpen,
      defaultTripDate: null,
      // scrollTimelogTripDialogToBottom: false,
    }));
  }

  updateOrphans = (newOrphans) => {
    const { originalOrphans } = this.state;

    const updatedOrphans = { ...originalOrphans };
    Object.keys(newOrphans).forEach((category) => {
      newOrphans[category].forEach((newOrphan) => {
        const foundOrphanIndex = updatedOrphans[category].findIndex((obj) => obj.userName === newOrphan.user_name && obj.workOrderName === newOrphan.work_order_name);
        if (foundOrphanIndex !== 1) {
          if (newOrphan.work_order_trip_context_status !== 'accepted' && !newOrphan.belongs_to_trip) {
            // Update at index
            updatedOrphans[category][foundOrphanIndex] = newOrphan;
          } else {
            // Delete at index
            updatedOrphans[category].splice(foundOrphanIndex, 1);
          }
        } else if (newOrphan.work_order_trip_context_status !== 'accepted') {
          // Add a new orphan
          updatedOrphans[category].push(newOrphan);
        }
      });
    });

    const expensesFromJson = updatedOrphans.taxExemptTripExpenses?.map((expense) => {
      expense.work_hour_date = moment(expense.work_hour_date, 'YYYY-MM-DD');
      return expense;
    });

    this.setState({
      originalOrphans: updatedOrphans,
      currentOrphans: groupOrphans(expensesFromJson || [], updatedOrphans.tripRoutes || []),
    });
  }


  updateOrphansAfterAccept = (newOrphans) => {
    const { originalOrphans } = this.state;
    const updatedOrphans = { ...originalOrphans };

    Object.keys(originalOrphans).forEach((category) => {
      originalOrphans[category].forEach((oldOrphan, index) => {
        const matchingNewOrphanIndex = newOrphans[category].findIndex((obj) => obj.id === oldOrphan.id);
        if (matchingNewOrphanIndex !== -1) {
          // New orphan is in the old set, update
          updatedOrphans[category][index] = newOrphans[category][matchingNewOrphanIndex];
        }
      });
    });

    Object.keys(newOrphans).forEach((category) => {
      newOrphans[category].forEach((newOrphan) => {
        // Search for a current orphan that matches with a new orphan
        const matchingOldOrphanIndex = originalOrphans[category].findIndex((obj) => obj.id === newOrphan.id);

        if (matchingOldOrphanIndex !== -1) {
          // Current orphan is already in the
          updatedOrphans[category].splice(matchingOldOrphanIndex, 1);
        } else if (newOrphan.work_order_trip_context_status !== 'accepted' && !newOrphan.belongs_to_trip) {
          // New orphan is not the set old set, push
          updatedOrphans[category].push(newOrphan);
        }
      });
    });

    const expensesFromJson = updatedOrphans.taxExemptTripExpenses?.map((expense) => {
      expense.work_hour_date = moment(expense.work_hour_date, 'YYYY-MM-DD');
      return expense;
    });

    this.setState({
      originalOrphans: updatedOrphans,
      currentOrphans: groupOrphans(expensesFromJson || [], updatedOrphans.tripRoutes || []),
    });
  }

  deleteWorkHours(deletedIds) {
    const { originalWorkOrderTripsWithMeta, originalOrphans } = this.state;
    const updatedWorkOrderTripsWithMeta = [...originalWorkOrderTripsWithMeta];
    const updatedOrphans = { ...originalOrphans };

    const taxExemptTripExpensesToBeDeleted = [];
    if (updatedOrphans.taxExemptTripExpenses) {
      updatedOrphans.taxExemptTripExpenses.forEach((orphanExpense, orphanIndex) => {
        const foundDeletedWorkHour = deletedIds.includes(orphanExpense.work_hour_id);
        if (foundDeletedWorkHour) {
          taxExemptTripExpensesToBeDeleted.push(orphanIndex);
        }
      });
    }

    const orphanTripRoutesToBeDeleted = [];
    if (updatedOrphans.tripRoutes) {
      updatedOrphans.tripRoutes.forEach((orphanTripRoute, orphanIndex) => {
        const foundDeletedWorkHour = deletedIds.includes(orphanTripRoute.work_hour_id);
        if (foundDeletedWorkHour) {
          // Matching work hour but trip route can't be found, assumed to be deleted
          orphanTripRoutesToBeDeleted.push(orphanIndex);
        }
      });
    }

    // Remove the deleted expenses from UI
    taxExemptTripExpensesToBeDeleted.forEach((orphanIndex) => {
      updatedOrphans.taxExemptTripExpenses.splice(orphanIndex, 1);
    });

    // Remove all the deleted trip routes from UI
    orphanTripRoutesToBeDeleted.forEach((orphanIndex) => {
      updatedOrphans.tripRoutes.splice(orphanIndex, 1);
    });

    const expensesFromJson = updatedOrphans.taxExemptTripExpenses?.map((expense) => {
      expense.work_hour_date = moment(expense.work_hour_date, 'YYYY-MM-DD');
      return expense;
    });

    this.setState({
      originalWorkOrderTripsWithMeta: updatedWorkOrderTripsWithMeta,
      originalOrphans: updatedOrphans,
      currentOrphans: groupOrphans(expensesFromJson || [], updatedOrphans.tripRoutes || []),
    });
  }

  updateWorkHours(newHours) {
    const { originalWorkOrderTripsWithMeta, originalOrphans } = this.state;
    const updatedWorkOrderTripsWithMeta = [...originalWorkOrderTripsWithMeta];
    const updatedOrphans = { ...originalOrphans };

    updatedWorkOrderTripsWithMeta.forEach((trip) => {
      // Update expenses (allowances) with the changed work hours
      Object.keys(trip.taxExemptTripExpenses).forEach((expenseCategory) => {
        Object.keys(trip.taxExemptTripExpenses[expenseCategory]).forEach((salaryPeriod) => {
          Object.keys(trip.taxExemptTripExpenses[expenseCategory][salaryPeriod]).forEach((workOrder) => {
            trip.taxExemptTripExpenses[expenseCategory][salaryPeriod][workOrder].forEach((expense) => {
              const matchingNewHour = newHours.find((newHour) => newHour.work_hour.id === expense.work_hour_id);
              if (matchingNewHour) {
                const newExpense = matchingNewHour.work_hour.tax_exempt_trip_expenses[0];
                if (newExpense) {
                  expense.value = newExpense.value;
                  expense.status = newExpense.status;
                  expense.work_order_trip_context_status = newExpense.work_order_trip_context_status;
                } else {
                  // Matching work hour was found but expense can't be found, assumed to be deleted
                  expense = {};
                }
              }
            });
          });
        });
      });

      // Update trip routes with the changed work hours
      Object.keys(trip.tripRoutes).forEach((salaryPeriod) => {
        Object.keys(trip.tripRoutes[salaryPeriod]).forEach((workOrder) => {
          trip.tripRoutes[salaryPeriod][workOrder].forEach((tripRoute) => {
            const matchingNewHour = newHours.find((newHour) => newHour.work_hour.id === tripRoute.workHourId);
            if (matchingNewHour) {
              const newTripRoute = matchingNewHour.work_hour.trip_routes.find((responseTripRoute) => responseTripRoute.id === tripRoute.id);
              if (newTripRoute) {
                tripRoute.routeLocations = newTripRoute.route_locations.map((routeLocationJson) => RouteLocation.fromJsonProperties(routeLocationJson));
                tripRoute.status = newTripRoute.status;
                tripRoute.kms = newTripRoute.kms;
                tripRoute.description = newTripRoute.description;
                // tripRoute.work_order_trip_context_status = newTripRoute.work_order_trip_context_status;
              } else {
                // Matching work hour was found but trip route can't be found, assumed to be deleted
                tripRoute = {};
              }
            }
          });
        });
      });
    });

    // Update orphan tax exempt trip expenses with the changed work hours
    const taxExemptTripExpensesToBeDeleted = [];
    if (updatedOrphans.taxExemptTripExpenses) {
      updatedOrphans.taxExemptTripExpenses.forEach((orphanExpense, orphanIndex) => {
        const matchingNewHour = newHours.find((newHour) => newHour.work_hour.id === orphanExpense.work_hour_id);
        if (matchingNewHour) {
          const newExpense = matchingNewHour.work_hour.tax_exempt_trip_expenses[0];
          if (newExpense?.value) {
            orphanExpense.value = newExpense.value;
            orphanExpense.status = newExpense.status;
            orphanExpense.work_order_trip_context_status = newExpense.work_order_trip_context_status;
          } else {
            // Matching work hour was found but expense can't be found, assumed to be deleted
            taxExemptTripExpensesToBeDeleted.push(orphanIndex);
          }
        }
      });
    }

    // Update orphan trip routes with the changed work hours
    const orphanTripRoutesToBeDeleted = [];
    if (updatedOrphans.tripRoutes) {
      updatedOrphans.tripRoutes.forEach((orphanTripRoute, orphanIndex) => {
        const matchingNewHour = newHours.find((newHour) => newHour.work_hour.id === orphanTripRoute.work_hour_id);
        if (matchingNewHour) {
          const newOrphanTripRoute = matchingNewHour.work_hour.trip_routes.find((responseTripRoute) => responseTripRoute.id === orphanTripRoute.id);
          if (newOrphanTripRoute?.kms) {
            orphanTripRoute.route_locations = newOrphanTripRoute.route_locations.map((routeLocationJson) => RouteLocation.fromJsonProperties(routeLocationJson));
            orphanTripRoute.status = newOrphanTripRoute.status;
            orphanTripRoute.kms = newOrphanTripRoute.kms;
            orphanTripRoute.description = newOrphanTripRoute.description;
          } else {
            // Matching work hour but trip route can't be found, assumed to be deleted
            orphanTripRoutesToBeDeleted.push(orphanIndex);
          }
        }
      });
    }

    // Remove the deleted expenses from UI
    taxExemptTripExpensesToBeDeleted.forEach((orphanIndex) => {
      updatedOrphans.taxExemptTripExpenses.splice(orphanIndex, 1);
    });

    // Remove all the deleted trip routes from UI
    orphanTripRoutesToBeDeleted.forEach((orphanIndex) => {
      updatedOrphans.tripRoutes.splice(orphanIndex, 1);
    });

    const expensesFromJson = updatedOrphans.taxExemptTripExpenses?.map((expense) => {
      expense.work_hour_date = moment(expense.work_hour_date, 'YYYY-MM-DD');
      return expense;
    });

    this.setState({
      originalWorkOrderTripsWithMeta: updatedWorkOrderTripsWithMeta,
      originalOrphans: updatedOrphans,
      currentOrphans: groupOrphans(expensesFromJson || [], updatedOrphans.tripRoutes || []),
    });
  }

  deleteOrAddOrphans(originalTrip, newTrip) {
    const { originalOrphans } = this.state;
    const updatedOrphans = { ...originalOrphans };

    const ungroupedTripExpensesNew = ungroupOrphans(newTrip);
    let ungroupedTripExpensesOld = {};
    if (originalTrip) {
      ungroupedTripExpensesOld = ungroupOrphans(originalTrip);
    }

    // If expense is in the new trip but not the original trip, we want to delete it from the orphans
    Object.keys(ungroupedTripExpensesNew).forEach((category) => {
      ungroupedTripExpensesNew[category].forEach((expense) => {
        const foundMatch = ungroupedTripExpensesOld[category] && ungroupedTripExpensesOld[category].find((oldExpense) => expense.id === oldExpense.id);
        if (!foundMatch) {
          const foundOrphanIndex = updatedOrphans[category].findIndex((obj) => obj.id === expense.id);
          updatedOrphans[category].splice(foundOrphanIndex, 1);
        }
      });
    });

    // If expense isn't in the new trip but is in the original trip, we want to add it to the orphans
    Object.keys(ungroupedTripExpensesOld).forEach((category) => {
      ungroupedTripExpensesOld[category].forEach((expense) => {
        const foundMatch = ungroupedTripExpensesNew[category] && ungroupedTripExpensesNew[category].find((oldExpense) => expense.id === oldExpense.id);
        if (!foundMatch) {
          updatedOrphans[category].push(expense);
        }
      });
    });

    this.updateOrphans(updatedOrphans);
  }

  toggleViewMode() {
    this.setState((prevState) => ({
      viewMode: prevState.viewMode === 'cards' ? 'table' : 'cards',
    }));
  }

  filterData(filteredData, filterCount, activeFilters) {
    const { timelogStore } = this.props;
    timelogStore.setTripFilters(activeFilters);

    this.setState({
      currentWorkOrderTripsWithMeta: filteredData,
      filterCount,
    });
  }

  toggleFilterDialog() {
    this.setState((prevState) => ({
      filterDialogOpen: !prevState.filterDialogOpen,
    }));
  }

  toggleFilterTrips() {
    this.setState((prevState) => ({
      filterTrips: !prevState.filterTrips,
    }));
  }

  togglefilterCollapsibleOpen() {
    this.setState((prevState) => ({
      filterCollapsibleOpen: !prevState.filterCollapsibleOpen,
    }));
  }

  render() {
    const {
      originalWorkOrderTripsWithMeta,
      // currentWorkHoursWithMeta,
      currentWorkOrderTripsWithMeta,
      // filterHours,
      filterTrips,
      filterDialogOpen,
      filterCount,
      viewMode,
      timelogTripDialogOpen,
      timelogTrip,
      currentOrphans,
      defaultTripDate,
      // scrollTimelogTripDialogToBottom,
      employerContextWorkOrderId,
      showEmployeeInfoDialog,
      employeeInfoDialogData,
      salaryPeriod,
    } = this.state;
    const { timelogStore: { workTripFilters }, uiStore, uiStore: { currentUser } } = this.props;

    return (
      <div className="employer-timelog-wrapper">
        <div className="employer-timelogs-header" style={{ padding: '10px' }}>
          <div className={uiStore.mobileMode ? 'employer-timelogs-title employer-timelogs-title-mobile' : 'employer-timelogs-title'}>
            {salaryPeriod && `Jakso: ${dateRange(salaryPeriod.from, salaryPeriod.to)}`}
          </div>

          <div>
            <Button
              type="button"
              onClick={() => this.toggleFilterDialog()}
              className="employer-reject-button mdc-button"
              startIcon={<FilterListIcon />}
              style={{
                width: '335px',
              }}
            >
              Suodata
              {filterCount !== 0 && (
                <span style={{ marginLeft: '5px' }}>
                  (
                  {filterCount}
                  )
                </span>
              )}
            </Button>
          </div>
        </div>

        <div>
          {/* Require originalWorkHoursWithMeta for the rendering here because otherwise FilterDialog renders with null originalWorkHoursWithMeta and would require some ComponentDidUpdate code */}
          {originalWorkOrderTripsWithMeta && (
            <FilterDialog
              open={filterDialogOpen}
              toggleDialog={this.toggleFilterDialog}
              originalData={originalWorkOrderTripsWithMeta}
              dataTitle="Suodata matkat"
              filterOptions={{
                // NOTE: Using lodash's get() let's you use nested attributes as a single string like so: 'work_order.date'
                // Multiple filters can be combined (OR operator) into a single selectable filter by separating the attrs with '||'
                filters: [
                  { title: 'Keikan kirjausten hyväksyjä', key: 'acceptingEmployers', translate: false },
                  // { title: 'Tila', key: 'startTrip.status||endTrip.status', translate: true },
                  { title: 'Tila', key: 'statuses', translate: true },
                  { title: 'Keikka', key: 'workOrderNames', translate: false },
                  { title: 'Työntekijä', key: 'userName', translate: false },
                ],
                defaultFilters: [
                  {
                    filter: {
                      // key: 'startTrip.status||endTrip.status',
                      key: 'statuses',
                      value: 'pending',
                    },
                    filterMeta: {
                      title: 'Tila',
                      // key: 'startTrip.status||endTrip.status',
                      key: 'statuses',
                      translate: true,
                    },
                  },
                  {
                    filter: {
                      key: 'acceptingEmployers',
                      value: `${currentUser.lastName}, ${currentUser.firstName}`,
                    },
                    filterMeta: {
                      title: 'Keikan kirjausten hyväksyjä',
                      key: 'acceptingEmployers',
                      translate: false,
                    },
                  },
                ],
              }}
              // We ignore all trip draft statuses because end_trips created by default in the background are drafts
              // Trying to filter them doesn't work properly at the moment because they're a unique case: draft end_trips aren't even rendered
              ignoredFilters={[
                {
                  // key: 'startTrip.status||endTrip.status',
                  key: 'statuses',
                  value: 'draft',
                },
              ]}
              filterData={this.filterData}
              translationPrefix="work_hour_filters"
              setActiveFilters={this.setActiveFilters}
              previousFilters={workTripFilters}
            />
          )}

          {!originalWorkOrderTripsWithMeta && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          )}

          {viewMode === 'cards' && (
            <div className="employer-card-wrapper">
              {
                // !filterTrips && currentWorkOrderTripsWithMeta?.map((workOrderTrip) => <EmployerWorkOrderTripCard workOrderTrip={workOrderTrip} key={workOrderTrip.start_trip.id} />)
                !filterTrips && currentWorkOrderTripsWithMeta && (
                  <TransitionedCards currentWorkOrderTripsWithMeta={currentWorkOrderTripsWithMeta} updateWorkOrderTrip={this.updateWorkOrderTrip} openTripDialog={this.openTripDialog} openEmployeeInfoDialog={this.openEmployeeInfoDialog} afterUpdate={this.processUpdateResponse} />
                )
              }
              {currentOrphans && (
                <TransitionedOrphanCards orphans={currentOrphans} updateOrphans={this.updateOrphansAfterAccept} updateWorkOrderTrip={this.updateWorkOrderTrip} openTripDialog={this.openTripDialog} openEmployeeInfoDialog={this.openEmployeeInfoDialog} afterUpdate={this.processUpdateResponse} />
              )}
            </div>
          )}

          {/* { viewMode === 'table' && (
            currentWorkOrderTripsWithMeta && <EmployerWorkOrderTripTable workOrderTrips={currentWorkOrderTripsWithMeta} />
          )} */}

          {currentWorkOrderTripsWithMeta?.length === 0 && currentOrphans?.length === 0 && (
            <div style={{ padding: '20px' }}>
              Matkoja ei löytynyt
            </div>
          )}
        </div>

        <EmployeeInfoDialog open={showEmployeeInfoDialog} employee={employeeInfoDialogData} onClose={this.closeEmployeeInfoDialog} />

        {timelogTripDialogOpen && (
          <TimelogTripDialog
            tripDialogOpen={timelogTripDialogOpen}
            toggleTripDialog={this.toggleTripDialog}
            workOrderTrip={timelogTrip}
            workOrder={{ id: employerContextWorkOrderId }}
            defaultTripDate={defaultTripDate}
            maxStartDate={null}
            maxStartTime={null}
            maxEndDate={null}
            maxEndTime={null}
            overlappingTrips={[]}
            // We do not give the onGoingTrip to the dialog for overlap checking if it is the one being opened
            onGoingTrip={null}
            tripToBeCloned={null}
            employerDeleteCallback={this.deleteTripCard}
            employerMode
            employerAfterUpdate={this.afterTripUpdate}
            // employerContextWorkOrderId={employerContextWorkOrderId}
            // scrollToBottom={scrollTimelogTripDialogToBottom}
          />
        )}
      </div>
    );
  }
}

export default EmployerWorkOrderTrips;
