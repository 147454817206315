/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Elevation } from '@rmwc/elevation';
import { IconButton } from '@rmwc/icon-button';
import { Checkbox } from '@rmwc/checkbox';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';

import { DirectUploadProvider } from 'react-activestorage-provider';

import { buttonColors } from '../calendar/EmployeeCalendar';
import { handleProgress } from '../models/user/user-ui-avatar';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

// TODO: MAKE THESE WITH PROPS
const descriptionOrFileValidation = (description, files) => {
  if (!description && !files) {
    return 'Vaadittu';
  // TODO: this kind of stupid !files and files.length speaks of a fundamental problem if files can be both falsy and empty array
  } else if (!description && files.length === 0) {
    return 'Vaadittu';
  }
  return null;
};

const nameValidate = (name) => {
  if (!name) {
    return 'Liitteen nimi on pakollinen';
  }
  return null;
};

const validate = {
  attachments: (description, files) => descriptionOrFileValidation(description, files),
  name: (name) => nameValidate(name),
};

@inject('employerWorkOrderStore', 'loginStore', 't')
@observer
class AttachmentDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      description: props.description,
      // fileURL: '',
      // filename: '',
      files: props.files || [],
      file: props.file,
      name: props.name,
      status: props.status,
      errors: {},
      inputChanged: false,
      checkedFiles: [],
      // These are used to store files that were selected for PDF generation
      // Kept separate from checkedFiles to prevent errors if there's multiple PDF generations going on at once
      generatingPdfFiles: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name || prevProps.description !== this.props.description || prevProps.files !== this.props.files) {
      this.setState({
        name: this.props.name,
        description: this.props.description,
        files: this.props.files,
        file: this.props.file,
      });
    }
  }

  handleValidate(name, description, files) {
    let errors = {};

    Object.keys(validate).forEach((key) => {
      if (key === 'attachments') {
        const error = validate[key](description, files);

        if (error) {
          errors = { ...errors, [key]: error };
        }
      } else if (key === 'name') {
        const error = validate[key](name);

        if (error) {
          errors = { ...errors, [key]: error };
        }
      }
    });

    this.setState({
      errors: { ...errors },
    });
  }

  attachmentUpload(disabled, data, inputRef) {
    const { t, handleAttachmentAction, id, handleGeneratePDF } = this.props;
    const { files, checkedFiles } = this.state;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={true}
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;
            const body = {
              ...data,
              files: signedIds,
            };

            handleAttachmentAction(body);
            // if (body.id == null || body.id == '') {
            //   const createPromise = new Promise((resolve) => employerWorkOrderStore.createWorkOrderAttachment(body, resolve));

            // } else {
            //   const updatePromise = new Promise((resolve) => employerWorkOrderStore.updateWorkOrderAttachment(body, resolve));
            //   console.log('upd vastaus', updatePromise)
            // }
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t)}

            <div
              style={{
                display: 'flex',
                justifyContent: checkedFiles.length !== 0 ? 'space-between' : 'end',
                marginTop: '20px',
              }}
            >
              {checkedFiles.length !== 0 && (
                <Button
                  {...{
                    type: 'button',
                    label: "Luo PDF",
                    icon: 'picture_as_pdf',
                    outlined: true,
                    theme: 'secondary',
                    // outlined: true,
                    onClick: () => {
                      handleGeneratePDF(id, checkedFiles, {...data, files }, this.removeFromFiles);
                      this.setState((prevState) => ({
                        checkedFiles: [],
                        // Appropriate emptying of the generatingPdfFiles will happen in the handleGeneratePdf callback
                        generatingPdfFiles: [...prevState.generatingPdfFiles, ...prevState.checkedFiles],
                      }));
                    },
                    style: {
                      // color: buttonColors.green.color,
                      borderRadius: '0px',
                      fontSize: '.75rem',
                      textTransform: 'none',
                    },
                  }}
                />
              )}
              <Button {...{
                disabled,
                type: 'button',
                label: 'Lisää tiedosto',
                icon: 'description',
                outlined: true,
                theme: 'secondary',
                // className: 'ml-1',
                onClick: () => inputRef.current.click(),
                style: {
                  // color: buttonColors.green.color,
                  borderRadius: '0px',
                  fontSize: '.75rem',
                  textTransform: 'none',
                },
              }}
              />
            </div>
          </>
        )}

        required
      />
    );
  }

  removeFromFiles = (signedIds) => {
    console.log('Removing files from checked states');
    this.setState((prevState) => ({
      checkedFiles: prevState.checkedFiles.filter(item => signedIds.includes(item)),
      generatingPdfFiles: prevState.generatingPdfFiles.filter(item => signedIds.includes(item)),
    }));
  }

  addTextField(attr, label, required, styles = null, textarea) {
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        label,
        required,
        name: attr,
        rows: 5,
        // invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState({
            [attr]: value,
            inputChanged: true,
          });

          if (attr === 'title') {
            delete errors[attr];
          } else {
            delete errors.attachments;
          }
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: this.state[attr] != null ? this.state[attr] : '',
      }}
      />
    );
  }

  updateChecked(signedId) {
    const { checkedFiles } = this.state;
    if (checkedFiles.includes(signedId)) {
      //
      this.setState((prevState) => ({
        checkedFiles: prevState.checkedFiles.filter((item) => item !== signedId),
      }));
    } else {
      this.setState((prevState) => ({
        checkedFiles: [...prevState.checkedFiles, signedId],
      }));
    }
  }

  renderUploadedFile(file) {
    const { confirmDelete, id, workOrderId, generatingPdf, openFilePreviewDialog } = this.props;
    const { generatingPdfFiles, checkedFiles } = this.state;

    const filename = file.filename;
    const fileURL = file.fileURL;
    const signedId = file.signedId;
    const fileExtension = filename.split('.').pop();
    const extensionsForPDF = ['jpg', 'png', 'jpeg'];

    // We want to hide checked files during PDF generation
    // generating PDF = true
    // AND
    // generatingPdfFiles.includes
    if ((generatingPdfFiles.includes(file?.signedId) && generatingPdf) === false) {
      return (
        <GridInner key={fileURL}>
          <GridCell
            desktop={3}
            phone={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              alignSelf: 'center',
            }}
          >
            {extensionsForPDF.includes(fileExtension.toLowerCase())
              ? (
                <Checkbox
                  checked={checkedFiles.includes(signedId)}
                  onChange={() => {
                    this.updateChecked(signedId);
                  }}
                />
              )
              : (
                <div style={{ height: '40px', width: '40px' }}></div>
              )
            }
            <a
              // href={fileURL}
              // download={filename}
              style={{
                color: 'var(--mdc-theme-primary, #6200ee)',
                // fontSize: '14px'
                cursor: 'pointer',
              }}
              onClick={() => openFilePreviewDialog(file)}
            >
              {filename}
            </a>
          </GridCell>
          <GridCell
            desktop={3}
            phone={1}
            style={{
            }}
          >
            {signedId && <IconButton
              type="button"
              style={{
                color: '#645F5F',
              }}
              icon="delete_outlined"
              onClick={() => {
                confirmDelete('file', id, workOrderId, filename, signedId);
              }}
            />}
            {!signedId && <div style={{ height: '48px' }}/>}
          </GridCell>
        </GridInner>
      );
    } else {
      return null;
    }
  }

  renderActionButtons() {
    const {
      inputChanged,
      name,
      description,
      files,
    } = this.state;
    const { id } = this.props;

    if (!id && !name && (!description || !files)) {
      return (
        <DialogActions
          style={{
            justifyContent: 'flex-end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    } else if (!id && (name || description || files)) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => {
              this.handleValidate(name, description, files);
            }}
            action={name && (description || files?.length) ? 'accept' : null}
          >
            Liitä
          </DialogButton>
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    } else if (id && inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => {
              this.handleValidate(name, description, files);
            }}
            action={name && (description || files?.length) ? 'accept' : null}
          >
            Tallenna
          </DialogButton>
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
          {/* <DialogButton
              type="button"
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
                minWidth: '100px',
              }}
              action="cancel"
            >
              Peruuta
            </DialogButton> */}
        </DialogActions>
      );
    } else if (id && !inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'flex-end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  render() {
    const { open, styles, id, workOrderId, handleAttachmentAction, handleClose, generatingPdf, attachmentType } = this.props;
    const { name, description, files, errors } = this.state;
    const inputRef = React.createRef();
    const sortedFiles = files.sort(this.sortByCreatedAtAsc);

    return (
      <Dialog
        className="modal-size employer-modal"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            const error = validate.attachments(description, sortedFiles);
            if (!error) {
              // We cannot send the whole file objects, only the signed IDs, for finding and attaching the files to the model
              let signedIds = sortedFiles.map((file) => {
                return file['signedId'];
              });
              handleAttachmentAction({...this.state, workOrderId: workOrderId, id: id, files: signedIds });
              handleClose();
            }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle>{id ? 'Muokkaa laskutuksen liitettä' : 'Uusi laskutuksen liite'}</DialogTitle>
        <DialogContent>
          <Grid>
            <GridCell
              className="employer-work-order"
              span={12}
            >
              {this.addTextField('name', 'Otsikko', true, styles, false)}
            </GridCell>
            <GridCell
              span={12}
            >
              {this.addTextField('description', 'Sisältö', false, styles, true)}
            </GridCell>
            <GridCell
              span={12}
            >
              {sortedFiles.map((file) => {
                return this.renderUploadedFile(file);
              })}

              {/* TODO: Backwards compatibility thing, REMOVE LATER */}
              {/* false ==== generatingPdf = true AND checkedFiles.includes TRUE */}
              {generatingPdf && <div>Luodaan PDF-tiedostoa...</div>}
              {/* {file && this.renderUploadedFile(file)} */}

              {this.attachmentUpload(
                (name === ''),
                {
                  id,
                  description,
                  name,
                  workOrderId,
                },
                inputRef,
              )}
              {/* {!filename
                ? this.attachmentUpload(
                  (name === ''),
                  {
                    id,
                    description,
                    name,
                    workOrderId,
                  },
                  t, inputRef,
                )
                : this.renderUploadedFile()} */}
              {errors.attachments && (
                <p
                  style={{
                    color: 'red',
                    padding: '0px 16px',
                    fontSize: '.75rem',
                  }}
                >
                  Sisältö tai tiedosto on pakollinen
                </p>
              )}
            </GridCell>
          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    )
  }
}

export default AttachmentDialog;
