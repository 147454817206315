/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import './LoadingSpinner.css';

@observer
class LoadingSpinner extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { color, size } = this.props;
    return (
      <>
        <div className={size + " lds-ring"}>
          <div className={ color }></div>
          <div className={ color }></div>
          <div className={ color }></div>
          <div className={ color }></div>
        </div>
      </>
    )
  }
}

export default LoadingSpinner;
