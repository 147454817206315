/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';
import { Card } from '@rmwc/card';
import { get, filter } from 'lodash';
import filterMatch from '../utils/filterMatch';

export const customFilterComparison = (item, filters) => {
  let foundMatch = true;
  // We "compress" active filters here by key: one key might have several values (clicked filter options)
  // E.g. filtering by both work_order.status.ongoing and work_order.status.archived
  const compressedFilters = {};
  filters.forEach((filter) => {
    let filterKey;
    let filterValue = filter.value;

    // A hardcoded hack to ignore end_trip.status.draft
    // This is because draft is the default status for end_trips created in the background, not directly by users
    // Therefore draft end trips aren't rendered in the WorkOrderTrip cards and filtering with end_trip.status.draft is useless
    if (filter.key === 'start_trip.status||end_trip.status' && filter.value === 'draft') {
      filterKey = 'start_trip.status';
    } else {
      filterKey = filter.key;
    }

    if (compressedFilters[filterKey] && !compressedFilters[filterKey].includes(filterValue)) {
      compressedFilters[filterKey].push(filterValue);
    } else {
      compressedFilters[filterKey] = [filterValue];
    }
  });

  Object.keys(compressedFilters).forEach((key) => {
    // Get the active values (clicked filter options) per key
    // At least one of these values has to match
    const values = compressedFilters[key];
    let keyMatch = false;

    if (key.includes('||')) {
      // OR key, split and compare each condition separately
      const splitKey = key.split('||');
      values.forEach((value) => {
        // Just one of the conditions needs to match
        const foundOrFilterMatch = splitKey.find((keyCondition) => (filterMatch(item, keyCondition, value)));

        if (foundOrFilterMatch) {
          // Match found for this key, quit loop
          keyMatch = true;
          return;
        }
      })
    } else {
      values.forEach((value) => {
        const foundFilterMatch = filterMatch(item, key, value);
        if (foundFilterMatch) {
          // Match found for this key, quit loop
          keyMatch = true;
          return;
        }
      });
    }
    if (!keyMatch) {
      foundMatch = false;
      return;
    }
  });

  return foundMatch;
}

@inject('uiStore', 'loginStore', 't')
@observer
class FilterDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterCollection: [],
      activeFilters: [],
      filteredData: props.originalData || null,
    };

    this.setFilterCollection = this.setFilterCollection.bind(this);
  }

  componentDidMount() {
    const { previousFilters } = this.props;

    // We want to re-apply old filters from the previous view e.g. after opening and closing a work order dialog
    if (previousFilters && Array.isArray(previousFilters)) {
      this.setState({
        activeFilters: previousFilters,
      }, () => {
        this.applyFilters();
      });
    } else {
      this.applyDefaultFilters();
      // Tried to emit the applied filters here but it caused problems, setting the default filters in the parent state directly on mount instead
    }
  }

  renderActionButtons() {
    const { filteredData } = this.state;
    return (
      <DialogActions
        style={{
          justifyContent: 'center',
          padding: '15px',
        }}
      >
        <DialogButton
          type="button"
          className="employer-accept-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
            width: '200px',
          }}
          action="close"
        >
          Näytä tulokset ({filteredData?.length || 0})
        </DialogButton>
      </DialogActions>
    );
  }

  renderFilters(filters) {
    const renderedFilters = [];
    filters.forEach((filter) => {
      renderedFilters.push(this.renderFilter(filter.title, filter.key, filter.options, filter.translate));
    });
    return renderedFilters;
  }

  emitCurrentFilters() {
    const { activeFilters } = this.state;
    const { setActiveFilters } = this.props;
    if (setActiveFilters) {
      setActiveFilters(activeFilters);
    }
  }

  toggleActiveFilterOption(clickedFilterOption) {
    const { activeFilters } = this.state;

    const foundActiveFilterIndex = activeFilters.findIndex((filter) => filter.key === clickedFilterOption.key && filter.value === clickedFilterOption.value);

    if (foundActiveFilterIndex !== -1) {
      const newActiveFilters = [...activeFilters];
      // Remove the clicked filter
      newActiveFilters.splice(foundActiveFilterIndex, 1);

      this.setState(({
        activeFilters: newActiveFilters,
      }), () => {
        this.applyFilters();
      });
    } else {
      // Clicked a different filter option with a different key
      // Pushed to active filters
      this.setState((prevState) => ({
        activeFilters: [...prevState.activeFilters, clickedFilterOption],
      }), () => {
        this.applyFilters();
      });
    }
  }

  // Render individual filter buttons
  renderFilter(title, key, options, translate) {
    const { t, translationPrefix } = this.props;
    const { activeFilters } = this.state;

    const renderedOptions = [];
    for (const [option, optionOccurrenceCount] of Object.entries(options)) {
      renderedOptions.push(
        <Card
          key={option}
          onClick={() => {
            // this.toggleActiveFilterOption(finalOption, 'activeFilters', 'filteredData', 'originalData', 'filterCollection', this.props.filterData, this.setFilterCollection);
            this.toggleActiveFilterOption({ key: key, value: option }, 'activeFilters', 'filteredData', 'originalData', 'filterCollection', this.props.filterData, this.setFilterCollection);
          }}
          style={{
            backgroundColor: activeFilters.find((filter) => filter.key === key && filter.value === option) ? 'rgb(194, 194, 194)' : 'white',
            // border: '1px solid black',
            // margin: '5px',
            minWidth: '40px',
            width: 'auto',
            height: 'fit-content',
            margin: '5px',
            padding: '5px',
            display: 'inline-block',
            textAlign: 'center',
          }}
        >
          <span>{translate ? t(`${translationPrefix}.${option}`) : option}</span>
          <span style={{ color: '#00000095', fontSize: '14px', marginLeft: '5px' }}>({optionOccurrenceCount})</span>
        </Card>
      );
    }

    return (
      <div key={title} style={{ color: 'black' }}>
          <Typography
            style={{
              paddingLeft: '15px',
              marginBottom: '5px',
            }}
            use="headline6"
            tag="h2"
          >
            {title}
          </Typography>
        <div
          style={{ padding: '0 10px' }}
        >
          {renderedOptions}
        </div>
      </div>
    );
  }

  addValuesToOrFilter(attr, option) {
    if (attr.includes('||')) {
      const splitAttr = attr.split('||');
      const splitAttrWithOptions = splitAttr.map((singleAttr) => {
        return singleAttr + '.' + option // + `.${option}`
      });
      const rejoinedAttr = splitAttrWithOptions.join('||');
      return rejoinedAttr;
    } else {
      return `${attr}.${option}`;
    }
  }

  applyDefaultFilters() {
    const { filterOptions: { defaultFilters, alternativeDefaultFilters }, originalData } = this.props;

    let defaultFiltersToBeApplied = [];

    defaultFilters.forEach((defaultFilter) => {
      defaultFiltersToBeApplied.push(defaultFilter.filter);
    });

    // Original data exists but the default filters provide zero results
    // We want to show something on the UI so we check the alternativeDefaultFilters
    if (originalData.length <= 10 && alternativeDefaultFilters) {
      alternativeDefaultFilters.forEach((altDefaultFilter) => {
        defaultFiltersToBeApplied.push(altDefaultFilter);
      });
    } else if (originalData.length > 0 && alternativeDefaultFilters) {
      const filteredData = filter(originalData, (item) => {
        return customFilterComparison(item, [...defaultFiltersToBeApplied]);
      });
      if (filteredData.length === 0) {
        const defaultFilterWithData = alternativeDefaultFilters.find((alternativeDefaultFilter) => {
          // Update the default filters by replacing the current one with the alternative filter
          const updatedFilters = [...defaultFilters].map((defaultFilter) => defaultFilter.filter.key === alternativeDefaultFilter.key ? alternativeDefaultFilter : defaultFilter.filter);
          // Check if there's data with these updated filters
          const newFilteredData = filter(originalData, (item) => {
            return customFilterComparison(item, updatedFilters);
          });
          if (newFilteredData.length > 0) {
            // Data found: return the filter responsible
            return alternativeDefaultFilter;
          } else {
            // No data: continue to the next alternativeDefaultFilter
            return null;
          }
        });

        if (defaultFilterWithData) {
          // Instead of empty data with the initial default filters, we use the alternative filter to show some data
          defaultFiltersToBeApplied = [...defaultFilters].map((defaultFilter) => defaultFilter.filter.key === defaultFilterWithData.key ? defaultFilterWithData : defaultFilter.filter);
        }
      }
    }

    this.setState((prevState) => ({
      activeFilters: [...prevState.activeFilters, ...defaultFiltersToBeApplied],
    }), () => {
      this.applyFilters();
      this.emitCurrentFilters();
    });
  }

  // Used to find all possible values from an item using a specific OR filter (contains '||')
  findByOrFilter(item, key) {
    const splitKey = key.split('||');
    let foundValues = [];
    // For each OR condition, check if a value exists in the item
    splitKey.forEach((key) => {
      const foundValue = get(item, key);
      if (foundValue) {
        foundValues.push(foundValue);
      }
    });
    return foundValues;
  }

  applyFilters() {
    const { activeFilters } = this.state;
    const { filterData, originalData } = this.props;

    // We do not want to return an empty array to the parent if the originalData hasn't even loaded yet
    if (originalData.length > 0) {
      if (activeFilters.length > 0) {
        // NOTE: If we need to optimize, we could add a positive/negative bool to the filter toggle function
        //    If positive: we are adding a new filter, so we can update the filteredData
        //    If negative: we need to use the originalData
        this.setState(() => ({
          // Using lodash's filter()
          // filteredData: filter(this.props.originalData, combinedFilters),
          // filteredData: filter(this.props.originalData, this.customFilterCheck(workHour, combinedFilters)),
          filteredData: filter(originalData, (item) => {
            return customFilterComparison(item, [...activeFilters]);
          }),
        }), () => {
          this.setFilterCollection(originalData, this.state.filteredData, 'filterCollection');
          filterData(this.state.filteredData, activeFilters.length, activeFilters);
          // Emit the applied filters to the parent component
          this.emitCurrentFilters();
        });
      } else {
        // No filters: reset to original data
        this.setState({
          filteredData: originalData,
        }, () => {
          // NOTE: If we do not want buttons to disappear, simply render buttons based on the original data but update occurrences
          this.setFilterCollection(originalData, this.state.filteredData, 'filterCollection');
          filterData(this.state.filteredData, activeFilters.length, activeFilters);
          // Emit the applied filters to the parent component
          this.emitCurrentFilters();
        });
      }
    } else {
      filterData([], activeFilters.length, activeFilters);
    }
  }

  constructFilterParameter(activeFilterString) {
    // Transforms an activeFilterString such as 'work_order.name.Huoltoseisakki' into { work_order: { name: 'Huoltoseisakki' }}
    // This is to make these strings work with lodash filter() + lodash get()

    // Split the string into an array of strings, e.g. ['work_order', 'name', 'Huoltoseisakki']
    let strings = activeFilterString.split(".");
    // These keys will represent the keys in the data that will be filtered
    // The last member is sliced because the last string is the value, not a key
    let keys = strings.slice(0, -1);
    let filterObject = {};

    // Reduce the array of strings into a single object
    keys.reduce((o, s, index) => {
      if (index !== (keys.length - 1)) {
        // Not the last member: set object as the value for the key (because we'll build a nested object), e.g. work_order: {}
        return o[s] = {};
      } else {
        // Last member: we want the final string (the value), e.g. 'Huoltoseisakki' as the deepest value instead of another object
        return o[s] = strings[index + 1];
      }
    }, filterObject);

    return filterObject;
  }

  setFilterCollection() {
    const { filterOptions, filterOptions: { defaultFilters }, originalData, ignoredFilters = [] } = this.props;
    // const { filteredData } = this.state;
    const newFilterCollection = [];

    // We want to have the default filter options to be always there, even if no data matches them
    // This allows turning off the default filters and puts them higher in the filter order
    defaultFilters.forEach((defaultFilter) => {
      newFilterCollection.push({
        title: defaultFilter.filterMeta.title,
        key: defaultFilter.filterMeta.key,
        options: {
          [defaultFilter.filter.value]: 0,
        },
        translate: defaultFilter.filterMeta.translate,
      });
    });

    const occurrenceCounts = {};

    // New version that renders the options according to original data but updates occurrenceCounts using filtered data
    originalData.forEach((item) => {
      filterOptions.filters.forEach((filter) => {
        const title = filter['title'];
        let key = filter['key'];
        const translate = filter['translate'];

        // Found value (such as employee name) from the data
        let foundValues = [];
        let value;

        if (key.includes('||')) {
          // Might contain multiple values since the filter has an OR operator '||', basically multiple attrs
          value = this.findByOrFilter(item, key);
          foundValues.push(value);
        } else {
          // Only one value because there's only one attr
          value = get(item, key);
          foundValues.push(value);
        }

        if (Array.isArray(value)) {
          // E.g. accepting_employers = ['Alatalo, Matti', 'Berg, Mika']
          // Need to be looped through, each array item (potential value to filter objects with) has its own filter
          if (key.includes('||')) {
            // If the filter is an OR filter, we want to remove duplicates. Only one match is enough
            // E.g. one trip card where both start_trip and end_trip are accepted should only count as 1 occurrence of accepted status
            value = [...new Set(value)];
          }
          value.forEach((actualValue) => {
            if (!occurrenceCounts[key]) {
              occurrenceCounts[key] = { [actualValue]: 1 };
            } else if (!occurrenceCounts[key][actualValue]) {
              occurrenceCounts[key][actualValue] = 1;
            } else {
              occurrenceCounts[key][actualValue] += 1;
            }
          });
        } else {
          // Not an array, assuming string
          if (!occurrenceCounts[key]) {
            occurrenceCounts[key] = { [value]: 1 };
          } else if (!occurrenceCounts[key][value]) {
            occurrenceCounts[key][value] = 1;
          } else {
            occurrenceCounts[key][value] += 1;
          }
        }

        if (foundValues.length > 0) {
          foundValues.forEach((foundValue) => {
            const foundFilter = newFilterCollection.find((filter) => filter.key === key);
            if (!foundFilter && Array.isArray(foundValue)) {
              // New filter, multiple values found
              newFilterCollection.push({
                title: title,
                key: key,
                options: {},
                translate: translate,
              });
            } else if (!foundFilter) {
              // New filter
              newFilterCollection.push({
                title: title,
                key: key,
                options: {},
                translate: translate,
              });
            }
          });
        }
      });
    });

    // Once the loop is over and calculating occurrences is complete, we set the occurrence counts to the filter objects
    for (const [key, value] of Object.entries(occurrenceCounts)) {
      const foundFilter = newFilterCollection.find((filter) => filter.key === key);
      if (foundFilter) {
        Object.keys(value).forEach((optionKey) => {
          // Check for ignored filters: do not add if there's a match
          const ignoredFilter = ignoredFilters.find((filter) => filter.key === key && filter.value === optionKey);
          if (!ignoredFilter) {
            foundFilter.options[optionKey] = value[optionKey];
          }
        });
      }
    }

    this.setState({ filterCollection: newFilterCollection });
  }

  renderTitle(titleText, top = false) {
    return (
      <Typography
        style={{
          marginTop: top ? '10px' : '16px',
          paddingLeft: '10px',
          color: 'black',
          fontWeight: '500',
        }}
        use="headline5"
        tag="h1"
        className="headline"
      >
        {titleText}
      </Typography>
    );
  }

  render() {
    const { open, toggleDialog, dataTitle } = this.props;
    const { filterCollection } = this.state;

    return (
      <Dialog
        className="modal-size limited-width-dialog employer-modal"
        open={open}
        onOpen={() => {
          this.setFilterCollection();
        }}
        onClose={() => {
          toggleDialog();
        }}
      >
        <DialogContent>
          {dataTitle && this.renderTitle(dataTitle, true)}
          {filterCollection && this.renderFilters(filterCollection)}
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    )
  }
}

export default FilterDialog;
