import { observable, action } from 'mobx';
import moment from 'moment';
// import WorkOrderInvitation from '../employer-work-orders/WorkOrderInvitation';
import AbsenceType from './AbsenceType';
import CalendarEntry from './CalendarEntry';

export default class AbsenceEntry {
  // t.references :absence_type, index: true
  // t.references :calendar_entry, index: true
  // t.references :absence_period, index: true
  // t.integer :status, index: true
  // t.jsonb :value

  @observable id;

  @observable calendarEntry;

  @observable absenceType;

  @observable status;

  @observable values;

  @observable deletedAt;

  // Used for updating singular AbsenceEntries in the UI (finding the period they're inside of)
  @observable absencePeriodId;

  @observable description;

  // @observable _destroy = false;

  constructor(object) {
    this.updateProperties(object);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      calendarEntry: CalendarEntry.fromJsonProperties(o.calendar_entry),
      absenceType: AbsenceType.fromJsonProperties(o.absence_type),
      values: o.values,
      status: o.status,
      deletedAt: o.deleted_at ? moment(o.deleted_at) : null,
      absencePeriodId: o.absence_period_id,
      description: o.description,
    });
  }

  static toJson(o) {
    return {
      id: o.id,
      absence_type_id: o.absenceType.id,
      calendar_entry_attributes: CalendarEntry.toJsonPreserveTimes(o.calendarEntry),
      status: o.status,
      values: o.values,
      deleted_at: o.deletedAt ? o.deletedAt.toISOString() : null,
      // _destroy: o._destroy,
      // absence_entries_attributes: absenceEntries mapped toJson
      description: o.description,
    };
  }


  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const absenceEntry = new AbsenceEntry({});
    absenceEntry.updatePropertiesFromJson(object);
    return absenceEntry;
  }

  static fromJson(json) {
    return AbsenceEntry.fromJsonProperties(JSON.parse(json));
  }
}
