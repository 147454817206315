/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';

import AccidentReportCard from './AccidentReportCard.jsx';
import NewAccidentReportDialog from './NewAccidentReportDialog.jsx';
import ShowAccidentReportDialog from './ShowAccidentReportDialog.jsx';
import LoadingSpinner from '../shared/LoadingSpinner.jsx';
import { appcontentsize } from '../styles/inline-styles';

const renderCloseCallReportCards = (accidentReports, openShowDialog, selectDraftReport) => {
  if (!accidentReports) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <LoadingSpinner color="white" />
      </div>
    );
  } else if (accidentReports.length === 0) {
    return (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <div>Lähetettyjä ilmoituksia ei löytynyt</div>
      </div>
    );
  }

  return (
    <GridInner
      style={{
        rowGap: '4px',
      }}
    >
      {accidentReports.map((accidentReportItem) => (
        <GridCell
          desktop={6}
          tablet={4}
          key={`woitem-${accidentReportItem.id}`}
        >
          <AccidentReportCard
            accidentReport={accidentReportItem}
            openShowDialog={openShowDialog}
            selectDraftReport={selectDraftReport} // Pass the selectDraftReport function here
          />
        </GridCell>
      ))}
    </GridInner>
  );
};

@inject('uiStore', 'loginStore', 't', 'accidentReportStore')
@observer
class AccidentReports extends Component {
  constructor(props) {
    super(props);

    this.state = {
      closeCallReports: [],
      openCloseCallReport: null,
      selectedDraftReport: null,
    };
  }

  componentDidMount() {
    const { uiStore: { currentView }, accidentReportStore } = this.props;

    accidentReportStore.get().then((accidentReports) => {
      this.setState({
        accidentReports: accidentReports,
      });
    });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    if (currentView.selectedFilter) {
      this.setState({
        selectedFilter: currentView.selectedFilter,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth });
  }

  newAccidentReport = (newReport) => {
    this.setState((prevState) => ({
      accidentReports: [newReport, ...prevState.accidentReports],
    }));
  }

  handleCloseNewDialog = () => {
    this.setState({
      newCloseCallReportDialogOpen: false,
    });
  }

  handleCloseShowDialog = () => {
    this.setState({
      showAccidentReportDialogOpen: false,
    });
  }

  openShowDialog = (accidentReport) => {
    this.setState({
      showAccidentReportDialogOpen: true,
      openCloseCallReport: accidentReport,
    });
  }

  // Function to set the selected draft report
  selectDraftReport = (accidentReport) => {
    this.setState({
      selectedDraftReport: accidentReport,
    });
    console.log(accidentReport);
  }

  render() {
    const {
      uiStore: { currentUser },
    } = this.props;
    const {
      newCloseCallReportDialogOpen,
      accidentReports,
      showAccidentReportDialogOpen,
      openCloseCallReport,
      selectedDraftReport,
    } = this.state;

    const draftAccidentReports = accidentReports?.filter((report) => report.status === 1 && report.reporter.id === currentUser.id);

    const regularAccidentReports = accidentReports?.filter((report) => report.status !== 1);

    return (
      <div style={appcontentsize}>
        <Grid
          style={{ width: '100%', height: '100%', padding: '16px 0px' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell span={6} style={{ marginLeft: '10px' }}>
              <Typography
                className="text-label"
                use="headline6"
                style={{ color: 'var(--mdc-theme-primary, #6200ee)' }}
              >
                {currentUser.role === 'employer' ? 'Vastaanotetut tapaturmailmoitukset' : 'Omat tapaturmailmoitukset'}
              </Typography>
            </GridCell>
            <GridCell
              span={6}
              style={{
                textAlign: 'end',
              }}
            >
              <Button
                type="button"
                icon="add"
                className="accept-button"
                style={{
                  margin: '10px',
                  borderRadius: '0px',
                }}
                onClick={() => this.setState({ newCloseCallReportDialogOpen: true })}
              >
                Tee ilmoitus
              </Button>
            </GridCell>

            {/* Render draft accident reports */}
            <GridCell style={{ padding: '0px 16px' }} desktop={12} tablet={8} phone={4}>
              {draftAccidentReports?.length > 0 && renderCloseCallReportCards(draftAccidentReports, this.openShowDialog, this.selectDraftReport)}
            </GridCell>

            {/* Render regular accident reports */}
            <GridCell style={{ padding: '0px 16px' }} desktop={12} tablet={8} phone={4}>
              {regularAccidentReports?.length > 0 && renderCloseCallReportCards(regularAccidentReports, this.openShowDialog)}
            </GridCell>

            {/* Pass the selectedDraftReport to the NewAccidentReportDialog */}
            {selectedDraftReport && (
              <NewAccidentReportDialog
                open={true} // Always open the dialog when there is a selectedDraftReport
                handleClose={() => this.setState({ selectedDraftReport: null })} // Clear the selectedDraftReport state when closing the dialog
                newAccidentReport={this.newAccidentReport}
                initialData={selectedDraftReport}
              />
            )}

            <NewAccidentReportDialog
              open={newCloseCallReportDialogOpen}
              handleClose={this.handleCloseNewDialog}
              newAccidentReport={this.newAccidentReport}
            />

            <ShowAccidentReportDialog
              open={showAccidentReportDialogOpen}
              handleClose={this.handleCloseShowDialog}
              accidentReport={openCloseCallReport || {}}
              employerView={false}
            />
          </GridInner>
        </Grid>
      </div>
    );
  }
}

export default AccidentReports;
