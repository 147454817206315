import React, { Component } from 'react'; // Fragment
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import groupArray from 'group-array';
// import { Grid, GridCell } from '@rmwc/grid';
import {
  IconButton,
  Icon,
  Grid,
  // TableRow,
  // TableCell,
  // TableFooter,
} from '@material-ui/core';

import {
  get,
  isEqual,
  sortBy,
  cloneDeep,
} from 'lodash';
import { CSVLink } from 'react-csv';
// import { createTheme } from '@material-ui/core/styles';
// import { Icon } from '@rmwc/icon';
// import MUIDataTable, { ExpandButton } from 'mui-datatables';
import MUIDataTable from 'mui-datatables';
// import TableFooter from '@material-ui/core/TableFooter';
import ReactToPrint from 'react-to-print';
import moment from '../utils/moment';
import ReportSettingsDialog from './ReportSettingsDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
import DeepViewHeader from '../shared/DeepViewHeader';
import normalizeFileName from '../utils/NormalizeFileName';
import './report.css';
import { buttonColors } from '../calendar/EmployeeCalendar';

const renderDateWithStatus = (date, status) => (
  <div style={{ position: 'relative' }}>
    <span style={{ textTransform: 'capitalize' }}>
      {moment(date).format('dd DD.MM.')}
    </span>
    <div className="hidden-print" style={{ position: 'absolute', top: '-10px', right: '-10px' }}>
      {status === 'pending' && (
        <Icon style={{ color: 'var(--mdc-theme-primary)', fontSize: '20px' }}>done</Icon>
      )}
      {status === 'accepted' && (
        <Icon style={{ color: buttonColors.green.color, fontSize: '20px' }}>done_all</Icon>
      )}
      {status === 'rejected' && (
        <Icon style={{ color: 'var(--mdc-theme-primary)', fontSize: '20px' }}>info</Icon>
      )}
    </div>
  </div>
);

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore', 'reportStore')
@observer
class BillingReportsHourly extends Component {
  // Values that contain 2 in 1 (allowances). Used both here and in BillingReports
  // Should be moved to TaxExemptTripExpense model once it is in-use
  combinationValues = ['full_half', 'full_plus_10', 'two_meals'];

  // These are a hacky way to keep track of which rows are currently rendered in the table to calculate the total sum in response to changes
  visibleRows = [];

  previousVisibleRows = [];

  constructor(props) {
    super(props);

    this._dateRangePicker = React.createRef();

    this.state = {
      reportData: null,
      settingsDialogOpen: false,
      // columns: [],
      mainColumns: [],
      totalSumRow: null,
      // visibleRows: [],
      // previousVisibleRows: [],
      filterLists: {},
      firstTimeFilterComplete: false,
    };
  }

  componentDidMount() {
    const {
      uiStore,
      mode,
      rawReportData,
    } = this.props;
    const workOrderId = uiStore.currentView.id;

    if (mode !== 'tabReport') {
      this.getWorkOrderReportByUser(workOrderId);
    } else if (rawReportData) {
      this.setupTable(rawReportData);
    }
  }

  // generatePDF = () => {
  //   const report = new JsPDF('landscape', 'pt', 'a4');
  //   report.html(document.querySelector('#report')).then(() => {
  //     report.save('report.pdf');
  //   });
  // }

  componentDidUpdate(prevProps) {
    const { mode, rawReportData } = this.props;
    // This is triggered on the initial report tab, when the index has finished the DB query
    if (mode === 'tabReport' && !isEqual(rawReportData, prevProps.rawReportData)) {
      this.setupTable(rawReportData);
    }
  }

  setupTable(rawReportData) {
    const processedData = this.processDataForTableV2(rawReportData);
    const { groupedData, newTotalSumRow } = processedData;
    const mainColumns = this.getMainColumnsV2(groupedData);

    // Update the component's state with the mainColumns, groupedEntries, and reportWorkOrder.
    this.setState({
      mainColumns,
      reportData: groupedData,
      reportWorkOrder: rawReportData.workOrder,
      totalSumRow: newTotalSumRow,
      originalData: rawReportData,
    }, () => {
      // Hide any empty columns in the table.
      // this.hideEmptyColumnsV2(groupedData);
    });
  }

  getMainColumnsV2(finalData) {
    // const { uiStore, t } = this.props;
    const { hideUserCol, summaryOnly } = this.props;

    const defaultColumns = [
      {
        name: 'isGroup',
        label: 'isGroup',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'workTaskEntry.workTask.identifiers',
        label: 'Työ',
        sort: false,
        filter: true,
        options: {
          display: true,
          customBodyRenderLite: (dataIndex) => {
            // if visibleRows does not contain row - push
            if (!finalData[dataIndex].isGroup && !this.visibleRows.find((row) => row.workTaskEntry.id === finalData[dataIndex].workTaskEntry.id)) {
              this.visibleRows.push(finalData[dataIndex]);
            }

            if (!finalData[dataIndex].isGroup) {
              return null;
            }

            return finalData[dataIndex]?.workTaskEntry.workTask.identifiers;
          },
          sort: false,
          filter: true,
          // filterOptions: {
          //   renderValue: (v) => (v === 'Kaikki yhteensä' ? null : v),
          // },
        },
      },
      {
        name: 'user.fullName',
        label: 'Työntekijä',
        options: {
          display: !hideUserCol,
          customBodyRenderLite: (dataIndex) => {
            if (!finalData[dataIndex].isGroup) {
              return finalData[dataIndex]?.user.fullName;
            }

            // return finalData[dataIndex]?.user.fullName;
            return null;
          },
          sort: false,
          filter: true,
        },
      },
      {
        name: 'workTaskEntry.date',
        label: 'Pvm',
        options: {
          display: !summaryOnly,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const workTaskEntry = finalData[dataIndex]?.workTaskEntry;
            if (finalData[dataIndex].isGroup) {
              return null;
            }

            const result = this.renderCustomTooltip(
              `${workTaskEntry?.id}-statusHover`,
              renderDateWithStatus(workTaskEntry?.date, workTaskEntry?.status),
              this.renderStatusTextWithComment(workTaskEntry?.status, workTaskEntry?.employerComment),
              null,
              '65px',
            );

            return (
              result
            );
          },
          setCellHeaderProps: () => ({ style: { maxWidth: '40px', minWidth: '40px', width: '40px' } }),
          sortDirection: 'asc',
        },
      },
      {
        name: 'time',
        label: 'Klo',
        options: {
          display: !summaryOnly,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];

            // Is group or not
            if (row.isGroup) {
              return null;
            }
            return (
              // <span>{`${reportData[dataIndex].user.firstName} ${reportData[dataIndex].user.lastName}`}</span>
              <span style={{ whiteSpace: 'nowrap' }}>
                {row.workTaskEntry.from || '?'}
                -
                {row.workTaskEntry.to || '?'}
              </span>
            );
          },
          setCellHeaderProps: () => ({ style: { maxWidth: '70px', minWidth: '70px', width: '70px' } }),
        },
      },
      {
        name: 'workTaskEntry.timeNormal',
        label: 'Normaali',
        headerWidth: '90.5px',
        sumType: 'add',
        options: {
          display: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workTaskEntry.timeNormal');
          },
        },
        // exclude / disable according to account id here?
      },
      {
        name: 'workTaskEntry.time50',
        label: '+50%',
        headerWidth: '66.6px',
        sumType: 'add',
        options: {
          display: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workTaskEntry.time50');
          },
        },
      },
      {
        name: 'workTaskEntry.time100',
        label: '+100%',
        headerWidth: '74.7px',
        sumType: 'add',
        options: {
          display: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workTaskEntry.time100');
          },
        },
      },
      {
        name: 'workTaskEntry.timeTotal',
        label: 'Työ\u00ADtunnit',
        headerWidth: '75px',
        sumType: 'add',
        options: {
          display: true,
          filter: false,
          customBodyRenderLite: (dataIndex) => {
            const row = finalData[dataIndex];
            return this.renderSumRowValue(row, 'workTaskEntry.timeTotal');
          },
        },
      },
      {
        name: 'workTaskEntry.description',
        label: 'Selite',
        headerWidth: '75px',
        sumType: 'none',
        options: {
          display: true,
          filter: false,
        },
      },
    ];

    return defaultColumns;
  }

  getColumnCountValue(columnName, workHour) {
    let value = 0;
    if (workHour[columnName] && columnName === 'allowance' && this.combinationValues.includes(workHour[columnName])) {
      value = 2;
    } else if (workHour[columnName]) {
      value = 1;
    }
    return value;
  }

  getWorkOrderReportByUser = (workOrderId) => {
    const { reportStore } = this.props;
    const reportPromise = fromPromise(new Promise((resolve, reject) => reportStore.getWorkOrderReportByUser(workOrderId, resolve, reject)));

    this.setState({
      isLoading: true,
    });

    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          pending: () => {
          },
          rejected: (e) => {
            console.log('REJECTED: ', e);
            this.setState({
              isLoading: false,
            });
          },
          fulfilled: (data) => {
            this.setupTable(data);
            this.setState({
              isLoading: false,
            });
          },
        });
      },
    );
  }

  handleSettingsDialogClose = () => {
    this.setState({
      settingsDialogOpen: false,
    });
  }

  toggleColumnVisibility = (columnName) => {
    const { mode, setIndexState } = this.props;
    const { mainColumns } = this.state;

    const columnIndex = mainColumns.findIndex((column) => column.name === columnName);
    const foundColumn = columnIndex !== -1 ? mainColumns[columnIndex] : null;

    const updatedColumns = [...mainColumns];

    if (foundColumn) {
      // Toggle visibility
      updatedColumns[columnIndex].options = { ...mainColumns[columnIndex].options, display: !foundColumn.options.display };
      this.setState({
        mainColumns: updatedColumns,
      }, () => {
        if (mode === 'tabReport' && setIndexState) {
          // Update the state to index so that the column visibility will remain even when changing report tabs
          setIndexState({ ...this.state, settingsDialogOpen: false });
        }
      });
    }
  }

  calculateSumWorkHour(data, key) {
    const sumTypes = {
      // allowance: 'countSeparate',
      date: 'none',
      time: 'none',
      timeNormal: 'add',
      description: 'none',
      latestEmployeeVersion: 'none',
      tripRoutes: 'none',
      workTask: 'doNotSum',
    };

    // let fullAllowanceCount = 0;
    // let halfAllowanceCount = 0;
    // let mealAllowancesCount = 0;
    // let abroadAllowanceCount = 0;
    // let unknownAllowanceCount = 0;

    let summingData = data;
    if (key) {
      // summingData = data.filter((row) => row.user.fullName === key);
      summingData = data[key];
    } else {
      summingData = [].concat(...Object.values(data));
    }

    const sumWorkHour = summingData.reduce((acc, dataRow) => {
      Object.keys(dataRow.workTaskEntry).forEach((attr) => {
        // acc[attr] = (acc[attr] || 0) + dataRow.workHour[attr];

        if (sumTypes[attr] === 'none') {
          acc[attr] = null;
          return acc;
        }
        if (sumTypes[attr] === 'doNotSum') {
          acc[attr] = dataRow[attr];
          return acc;
        }

        // Add the values together
        if (sumTypes[attr] === 'add' || !sumTypes[attr]) {
          const newValue = (acc[attr] || 0) + (dataRow.workTaskEntry[attr] || 0);
          acc[attr] = newValue === 0 ? null : newValue;
        }

        if (sumTypes[attr] === 'count') {
          if (dataRow.workTaskEntry[attr] && attr === 'allowance' && this.combinationValues.includes(dataRow.workTaskEntry[attr])) {
            // acc[attr] += 2;
            acc[attr] = (acc[attr] || 0) + 2;
          } else {
            // acc[attr] += dataRow.workHour[attr] ? 1 : 0;
            acc[attr] = (acc[attr] || 0) + (dataRow.workTaskEntry[attr] ? 1 : 0);
          }
        }

        return null;
      });

      return acc;
    }, {});

    return sumWorkHour;
  }

  processDataForTableV2(data) {
    const { summaryOnly } = this.props;
    // Grouping data by 'GroupingColumn'
    const groupedData = {};

    data.workHours.forEach((item) => {
      item.workHour.workTaskEntries.forEach((workTaskEntry) => {
        const workTaskEntryWithExtra = {
          ...workTaskEntry,
          status: item.workHour.status,
          date: item.workHour.date,
          employerComment: item.workHour.employerComment,
        };
        groupedData[workTaskEntryWithExtra.workTask.identifiers] = groupedData[workTaskEntryWithExtra.workTask.identifiers] || [];
        groupedData[workTaskEntryWithExtra.workTask.identifiers].push({ user: item.user, workTaskEntry: workTaskEntryWithExtra });
      });
    });

    // Flattening the grouped data to be compatible with MUIDataTable
    const finalData = [];
    Object.keys(groupedData).sort().forEach((key) => {
      const sumWorkHour = this.calculateSumWorkHour(groupedData, key);

      finalData.push({
        isGroup: true,
        user: {
          fullName: 'Työ yhteensä',
        },
        workTaskEntry: {
          ...sumWorkHour,
          id: null,
          workTask: {
            identifiers: key,
          },
        },
      });

      if (!summaryOnly) {
        // Insert isGroup: false here as the first column to get the sorting to work
        finalData.push(...groupedData[key].map((row) => ({ ...row, isGroup: false })));
      }
    });

    const finalSum = this.calculateSumWorkHour(groupedData);
    const newTotalSumRow = {
      isGroup: true,
      user: {
        fullName: 'Kaikki yhteensä',
      },
      // workTaskEntry: finalSum,
      workTaskEntry: {
        ...finalSum,
        workTask: {
          ...finalSum.workTask,
          identifiers: 'Kaikki yhteensä',
        },
      },
    };
    finalData.push(newTotalSumRow);

    return { groupedData: finalData, newTotalSumRow };
  }

  processDataForTable(data) {
    const constructedWorkTaskEntries = [];

    // eslint-disable-next-line no-unused-expressions
    data?.workHours?.forEach((workHourObj) => {
      workHourObj.workHour.workTaskEntries.forEach((workTaskEntry) => {
        constructedWorkTaskEntries.push({
          user: workHourObj.user.fullName,
          date: workHourObj.workHour.date,
          from: workTaskEntry.from,
          to: workTaskEntry.to,
          identifiers: workTaskEntry.identifiers,
          // workTaskIdentifiers: `${workTaskEntry.workTask.identifierOne} - ${workTaskEntry.workTask.identifierTwo}`,
          timeNormal: workTaskEntry.timeNormal,
          time50: workTaskEntry.time50,
          time100: workTaskEntry.time100,
          timeTotal: workTaskEntry.timeTotal,
          status: workHourObj.workHour.status,
          id: workTaskEntry.id,
          // status: workHourObj.workHour.status,
          // identifierOne: workTaskEntry.workTask.identifierOne,
          // identifierTwo: workTaskEntry.workTask.identifierTwo,
        });
      });
    });

    const groupedEntries = this.categorizeRows(constructedWorkTaskEntries, 'workTaskIdentifiers');

    return groupedEntries;
  }

  // hideEmptyColumns(groupedWorkHours) {
  //   const { mode, setIndexState, indexState } = this.props;
  //   const { mainColumns } = this.state;

  //   const columnsWithValues = [];
  //   Object.keys(groupedWorkHours).forEach((objKey) => {
  //     // Filter mainColumns with columnsWithValues to prevent looping over keys that we know are needed
  //     // Quit the whole loop if the mainColumns.length is the same as columnsWithValues.length
  //     const filteredMainColumns = [...mainColumns].filter((col) => !columnsWithValues.includes(col.name));
  //     const workHourGroup = groupedWorkHours[objKey];

  //     workHourGroup.forEach((groupWorkHour) => {
  //       filteredMainColumns.forEach((col) => {
  //         const key = col.name.split('.').pop();
  //         const value = groupWorkHour[key];
  //         if (value && !columnsWithValues.includes(col.name)) {
  //           columnsWithValues.push(col.name);
  //         }
  //       });
  //     });
  //   });

  //   const hiddenEmptyMainColumns = [...mainColumns].map((col) => ({ ...col, display: columnsWithValues.includes(col.name) }));
  //   console.log('hiddenEmptyMainColumns: ', hiddenEmptyMainColumns);
  //   // hideUserCol

  //   this.setState({
  //     mainColumns: hiddenEmptyMainColumns,
  //   }, () => {
  //     if (mode === 'tabReport' && setIndexState && !indexState) {
  //       // We save the state to the upper index component here because this should be the final setState when the component is loaded
  //       setIndexState({ ...this.state, settingsDialogOpen: false });
  //     } else if (mode === 'tabReport' && setIndexState && indexState) {
  //       // Use previous indexState, stored in the index
  //       if (indexState) {
  //         this.setState({
  //           ...indexState,
  //         });
  //       }
  //     }
  //   });
  // }

  // eslint-disable-next-line class-methods-use-this
  categorizeRows(data, category) {
    const groupedWorkHours = groupArray(data, category);

    // The column being grouped by is the first
    return groupedWorkHours;
  }

  // eslint-disable-next-line class-methods-use-this
  findVisibleColumn(columns, columnName) {
    // true or false
    return columns.some((column) => column.name === columnName && column.options?.display !== false);
  }

  // eslint-disable-next-line class-methods-use-this
  customSumColumnBodyRenderLite(groupedReportData, dataIndex, columnName, sumType) {
    const key = Object.keys(groupedReportData)[dataIndex];
    const rows = groupedReportData[key];

    if (rows) {
      let sum = 0;

      // Add the values together
      if (sumType === 'add') {
        rows.forEach((row) => {
          sum += row[columnName] || 0;
        });
        return sum > 0 ? <span>{sum}</span> : null;
      }

      // Count the number of values
      if (sumType === 'count') {
        rows.forEach((row) => {
          if (columnName === 'allowance' && this.combinationValues.includes(row[columnName])) {
            sum += 2;
          } else {
            sum += row[columnName] ? 1 : 0;
          }
        });
        return sum > 0 ? <span>{`${sum} kpl`}</span> : null;
      }
    }
    return <span />;
  }

  // eslint-disable-next-line class-methods-use-this
  calculateColumnTotal(groupedReportData, col) {
    const keys = Object.keys(groupedReportData);

    let total = 0;

    if (col.name === 'allowance' || col.name === 'emergencyWork') {
      keys.forEach((key) => {
        const workHours = groupedReportData[key].map((row) => row.workHour);

        const sum = workHours.reduce((acc, workHour) => acc + this.getColumnCountValue(col.name, workHour), 0);
        total += sum;
      });

      if (total > 0) {
        return `${total} kpl`;
      }
      return null;
    }

    keys.forEach((key) => {
      // const workHours = groupedReportData[key].map((row) => row.workHour);
      const sum = groupedReportData[key].reduce((acc, row) => acc + (row[col.name] || 0), 0);
      total += sum;
    });

    if (total > 0) {
      return total;
    }
    return null;
  }

  renderSumRowValue(row, key) {
    if (row.isGroup && row.workTaskEntry.workTask.identifiers === 'Kaikki yhteensä') {
      const { totalSumRow } = this.state;
      return get(totalSumRow, key, null);
    }
    if (row.isGroup) {
      const { reportData } = this.state;
      const foundTrueSumRow = reportData.find((stateRow) => stateRow.isGroup && row.user.fullName === stateRow.user.fullName && row.workTaskEntry.id === stateRow.workTaskEntry.id && row.workTaskEntry.workTask.identifiers === stateRow.workTaskEntry.workTask.identifiers);
      return get(foundTrueSumRow, key, null);
    }
    return get(row, key, null);
  }

  renderActionButtons(csvData, headers) {
    const { reportWorkOrder } = this.state;
    const { uiStore: { currentUser, mobileMode } } = this.props;

    const filename = `${currentUser?.accountInfo?.name}_${reportWorkOrder?.name}`;
    const normalizedFileName = normalizeFileName(filename);

    return (
      <div style={{ textAlign: 'right', marginBottom: mobileMode ? 0 : '-70px' }}>
        <button type="button" className="small MuiButtonBase-root MuiIconButton-root">
          <CSVLink
            data={csvData}
            headers={headers}
            style={{ color: 'black' }}
            filename={`${normalizedFileName}.csv`}
            className="material-icons MuiIcon-root MuiIcon-fontSizeLarge"
            // Separator needs to be explicitly set or else software will interpret the CSV differently based on things like user locale and operating system
            separator=","
          >
            download
          </CSVLink>
        </button>

        <ReactToPrint
          // NOTE: Do NOT pass an `onClick` prop to the root node of the returned component as it will be overwritten.
          trigger={() => (
            <IconButton
              aria-label="settings"
              className="small"
            >
              <Icon style={{ color: 'black' }} fontSize="large">print</Icon>
            </IconButton>
          )}
          content={() => this.componentRef}
        />

        <IconButton
          aria-label="settings"
          onClick={() => this.setState({ settingsDialogOpen: true })}
        >
          <Icon style={{ color: 'black' }} fontSize="large">settings</Icon>
        </IconButton>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderActionButtonsNoHeader() {
    // const { mobileMode } = this.props;

    // return (
    //   <div className="hidden-print" style={{ textAlign: 'right', marginTop: mobileMode ? 0 : '-45px' }}>
    //     <IconButton
    //       aria-label="settings"
    //       onClick={() => this.setState({ settingsDialogOpen: true })}
    //     >
    //       <Icon style={{ color: 'black' }} fontSize="large">settings</Icon>
    //     </IconButton>
    //   </div>
    // );

    return null;
  }

  renderCustomTooltip(visibilityIdentifier, tooltippedNode, tooltipContent, customBottom = null, customWidth = null) {
    const { [visibilityIdentifier]: visibilityIdentifierState } = this.state;

    return (
      <div
        className="custom-tooltip-wrapper"
        key={`tooltip-${visibilityIdentifier}`}
        style={{ width: customWidth, minWidth: customWidth }}
      >
        <div
          className={
            visibilityIdentifierState ? 'custom-tooltip custom-tooltip-open' : 'custom-tooltip'
          }
          style={{
            bottom: customBottom,
          }}
        >
          {tooltipContent}
        </div>

        <div
          onMouseEnter={() => this.setState({ [visibilityIdentifier]: true })}
          onMouseLeave={() => this.setState({ [visibilityIdentifier]: false })}
          className="custom-tooltip-underline"
        >
          {/* {moment(workHour.work_hour.date).format('DD.MM.')} */}
          {tooltippedNode}
        </div>
      </div>
    );
  }

  renderStatusTextWithComment(status, employerComment) {
    const { t } = this.props;
    return (
      <div>
        <div>{t(`timelog.attributes.statuses.${status}`)}</div>
        {status === 'rejected' && (
          <>
            <br />
            <div className="tooltip-employer-comment">
              {`"${employerComment}"`}
            </div>
          </>
        )}
      </div>
    );
  }

  renderTableV2() {
    // const { uiStore, t } = this.props;
    // const columns = ["name", "title", "groupingColumn"];
    const { reportData, mainColumns, filterLists } = this.state;

    // This is a hack to force the table to apply the filters correctly when we update them in the state (onFilterChange)
    // The previous version accessed the state when the mainColumns are first constructed (getMainColumnsV2) but this was unreliable
    const updatedMainColumns = cloneDeep(mainColumns);
    updatedMainColumns[1].options.filterList = filterLists['workTaskEntry.workTask.identifiers'] || [];
    updatedMainColumns[2].options.filterList = filterLists['user.fullName'] || [];

    const options = {
      filter: true,
      filterType: 'multiselect',
      responsive: 'standard',
      search: false,
      viewColumns: false,
      selectableRows: 'none',
      fixedHeader: true,
      sort: true,
      download: false,
      print: false,
      pagination: false,
      enableNestedDataAccess: '.',
      setRowProps: (row) => {
        // isGroup === true
        if (row[0] === true) {
          return {
            style: {
              backgroundColor: '#cecece',
            },
          };
        }
        return null;
      },
      customSort: (data, colIndex, order) => {
        // ChatGPT 6.11.2023
        // The table is already initially grouped and sorted by user.fullName (row where isGroup = true)
        // The idea is to only sort the normal rows where isGroup = false while keeping the group row order same
        let result = [];
        let currentGroup = [];

        data.forEach((item, index) => {
          const isGroup = item.data[0];

          if (isGroup) {
            if (currentGroup.length > 0) {
              // Sort the previous group by the specified key, skip the first item (isGroup: true)
              currentGroup = [
                currentGroup[0],
                ...currentGroup.slice(1).sort((a, b) => (
                  order === 'asc' ? a.data[colIndex] - b.data[colIndex] : b.data[colIndex] - a.data[colIndex]
                ))];
              result = result.concat(currentGroup);
              currentGroup = [];
            }
            // Start a new group
            currentGroup.push(item);
          } else {
            currentGroup.push(item);
          }

          // If it's the last item, sort and add the last group
          if (index === data.length - 1) {
            currentGroup = [
              currentGroup[0],
              ...currentGroup.slice(1).sort((a, b) => (
                order === 'asc' ? a.data[colIndex] - b.data[colIndex] : b.data[colIndex] - a.data[colIndex]
              ))];
            result = result.concat(currentGroup);
          }
        });

        return result;
      },
      onFilterChange: (changedColumn, filterList, type, changedColumnIndex, displayData) => {
        const { filterLists: oldFilterLists, firstTimeFilterComplete } = this.state;

        let newChangedFilters;
        if (!displayData) {
          // Reset
          newChangedFilters = {};
        } else {
          newChangedFilters = {
            'workTaskEntry.workTask.identifiers': ['Kaikki yhteensä'],
            'user.fullName': ['Kaikki yhteensä', 'Työ yhteensä'],
          };
        }

        // Hacking the group row filters to make them visible when filtering by user, but also making them removable later through UI
        if (firstTimeFilterComplete && !filterList[1].includes('Kaikki yhteensä') && filterList[1].length > 0 && Object.keys(oldFilterLists).length > 0) {
          // newChangedFilters['workTaskEntry.workTask.identifiers'].push('Kaikki yhteensä');
          const foundFilterIndex = newChangedFilters['workTaskEntry.workTask.identifiers'].findIndex((filter) => filter === 'Kaikki yhteensä');
          if (foundFilterIndex !== -1) {
            newChangedFilters['workTaskEntry.workTask.identifiers'].splice(foundFilterIndex, 1);
          }
        }
        if (firstTimeFilterComplete && !filterList[2].includes('Kaikki yhteensä') && filterList[2].length > 0 && Object.keys(oldFilterLists).length > 0) {
          // newChangedFilters['user.fullName'].push('Kaikki yhteensä');
          const foundFilterIndex = newChangedFilters['user.fullName'].findIndex((filter) => filter === 'Kaikki yhteensä');
          if (foundFilterIndex !== -1) {
            newChangedFilters['user.fullName'].splice(foundFilterIndex, 1);
          }
        }

        if (firstTimeFilterComplete && !filterList[2].includes('Työ yhteensä') && filterList[2].length > 0 && Object.keys(oldFilterLists).length > 0) {
          // newChangedFilters['user.fullName'].push('Työ yhteensä');
          const foundFilterIndex = newChangedFilters['user.fullName'].findIndex((filter) => filter === 'Työ yhteensä');
          if (foundFilterIndex !== -1) {
            newChangedFilters['user.fullName'].splice(foundFilterIndex, 1);
          }
        }

        // These sets are used to calculate the total sum row
        // We have to use them to manually get visible rows out of the reportData, displayData isn't accurate due to some update issues
        const relevantTasks = new Set(filterList[1]);
        const relevantUsers = new Set(filterList[2]);

        if (type === 'reset') {
          // noop
        } else if (changedColumn === 'user.fullName') {
          // When the user is updated, we ensure that the user's work tasks are made visible
          // To do this, we find the work task identifiers that should be included in the filter strings from the non-filtered OG data
          reportData.forEach((row) => {
            if (filterList[changedColumnIndex].includes(row.user.fullName) && !row.isGroup) {
              // Only take the task into account if it is added in the task filters, otherwise we only care about user, otherwise we only care about the task for summary row calculation
              if (newChangedFilters['workTaskEntry.workTask.identifiers'].includes(row.workTaskEntry.workTask.identifiers) || filterList[1].length === 0 || !firstTimeFilterComplete) {
                relevantTasks.add(row.workTaskEntry.workTask.identifiers);
              }
              relevantUsers.add(row.user.fullName);
            }
          });

          filterList[changedColumnIndex].forEach((userFullName) => {
            if (!newChangedFilters[changedColumn].includes(userFullName)) {
              newChangedFilters[changedColumn].push(userFullName);
            }
          });
        } else {
          // Assuming filtering by work task identifiers
          reportData.forEach((row) => {
            if (filterList[changedColumnIndex].includes(row.workTaskEntry.workTask.identifiers) && !row.isGroup) {
              // Only take the user into account if it is added in the user filters, otherwise we only care about the task for summary row calculation
              if (newChangedFilters['user.fullName'].includes(row.user.fullName) || filterList[2].length === 0 || !firstTimeFilterComplete) {
                relevantUsers.add(row.user.fullName);
              }
              relevantTasks.add(row.workTaskEntry.workTask.identifiers);
            }
          });

          filterList[changedColumnIndex].forEach((identifiers) => {
            if (!newChangedFilters[changedColumn].includes(identifiers)) {
              newChangedFilters[changedColumn].push(identifiers);
            }
          });

          // eslint-disable-next-line prefer-destructuring
          newChangedFilters['user.fullName'] = filterList[2];
        }

        relevantTasks.forEach((identifier) => {
          if (!newChangedFilters['workTaskEntry.workTask.identifiers'].includes(identifier)) {
            newChangedFilters['workTaskEntry.workTask.identifiers'].push(identifier);
          }
        });

        let visibleReportData;
        if (type === 'reset') {
          visibleReportData = reportData.filter((row) => !row.isGroup);
        } else {
          visibleReportData = reportData.filter((row) => !row.isGroup && (relevantUsers.has(row.user.fullName) && relevantTasks.has(row.workTaskEntry.workTask.identifiers)));
        }

        // Take only non-grouped (non-sum) rows from those visible
        // const filteredVisibleReportData = visibleReportData.filter((row) => !row.isGroup);

        // Group by work task identifiers for the sum calculation method
        const groupedData = {};
        visibleReportData.forEach((row) => {
          const { workTaskEntry } = row;

          const workTaskEntryWithExtra = {
            ...workTaskEntry,
            // status: row.workHour.status,
            date: workTaskEntry.date,
          };
          groupedData[workTaskEntryWithExtra.workTask.identifiers] = groupedData[workTaskEntryWithExtra.workTask.identifiers] || [];
          groupedData[workTaskEntryWithExtra.workTask.identifiers].push({ user: row.user, workTaskEntry: workTaskEntryWithExtra });
        });

        // We have to recalculate all group rows (work task summaries) when the content changes
        const recalculatedWorkSums = [];
        Object.keys(groupedData).sort().forEach((key) => {
          const sumWorkHour = this.calculateSumWorkHour(groupedData, key);

          recalculatedWorkSums.push({
            isGroup: true,
            user: {
              fullName: 'Työ yhteensä',
            },
            workTaskEntry: {
              ...sumWorkHour,
              id: null,
              workTask: {
                identifiers: key,
              },
            },
          });

          // Insert isGroup: false here as the first column to get the sorting to work
          recalculatedWorkSums.push(...groupedData[key].map((row) => ({ ...row, isGroup: false })));
        });

        // Calculate the absolute total sum (Kaikki yhteensä)
        // A lite version of setupTable()
        const finalSum = this.calculateSumWorkHour(groupedData);

        const newTotalSumRow = {
          isGroup: true,
          user: {
            fullName: 'Kaikki yhteensä',
          },
          workTaskEntry: {
            ...finalSum,
            id: null,
            workTask: {
              ...finalSum.workTask,
              identifiers: 'Kaikki yhteensä',
            },
          },
        };

        const newReportData = [...reportData];
        recalculatedWorkSums.forEach((workSumRow) => {
          const foundIndex = newReportData.findIndex((row) => row.isGroup === workSumRow.isGroup && row.user.fullName === workSumRow.user.fullName && row.workTaskEntry.id === workSumRow.workTaskEntry.id && row.workTaskEntry.workTask.identifiers === workSumRow.workTaskEntry.workTask.identifiers);
          if (foundIndex !== -1) {
            newReportData[foundIndex] = workSumRow;
          }
        });

        let resetFirstTimeFilterComplete = false;
        if (type === 'reset') {
          resetFirstTimeFilterComplete = true;
        }

        // A hack to reset the filters if the one invisible filter chip (work task 'Kaikki yhteensä') is the only one remaining
        if (type === 'reset' || (filterList[1].length === 1 && filterList[2].length === 0 && filterList[1][0] === 'Kaikki yhteensä')) {
          newChangedFilters = {};
        }

        this.setState((prevState) => ({
          reportData: newReportData,
          totalSumRow: newTotalSumRow,
          // filterLists: { ...newChangedFilters },
          filterLists: newChangedFilters,
          firstTimeFilterComplete: resetFirstTimeFilterComplete ? false : prevState.firstTimeFilterComplete,
        }));
      },
      setFilterChipProps: (colIndex, colName, data) => {
        if (
          (colName === 'workTaskEntry.workTask.identifiers' && data === 'Kaikki yhteensä')
          // || (colName === 'user.fullName' && data === 'Kaikki yhteensä')
          // || (colName === 'user.fullName' && data === 'Työ yhteensä')
        ) {
          return { style: { display: 'none' } };
        }
        return null;
      },
      // A stupid hack to fix the issue where selecting two employees in the filters in a row breaks the automatic work filtering, but closing the dialog between the employees in-between works
      onFilterDialogClose: () => { this.setState({ firstTimeFilterComplete: true }); },
    };

    // Works the first time but not the second time?

    // return <MUIDataTable data={finalData} columns={columns} options={options} />;
    return <MUIDataTable data={[...reportData]} columns={updatedMainColumns} options={options} />;
  }

  render() {
    const { inputValue, isLoading } = this.props;
    const {
      reportData,
      reportWorkOrder,
      settingsDialogOpen,
      mainColumns,
      isLoading: stateIsLoading,
    } = this.state;

    const {
      mode,
      uiStore,
      uiStore: { currentUser },
      height,
      hideHeader,
      renderDateRangePickerHeading,
    } = this.props;

    const headers = [...mainColumns].reduce((accumulator, column) => {
      if (column.display) {
        accumulator.push(
          {
            label: column.label,
            // remove the workHour prefix (used in the table as a nested accessor)
            key: column.name.split('.').pop(),
          },
        );
      }
      return accumulator;
    }, []);

    // Add the user's fullName column as the first one
    headers.unshift(
      {
        label: 'Työ',
        key: 'workTask.identifiers',
      },
      {
        label: 'Työntekijä',
        key: 'userFullName',
      },
      {
        label: 'Pvm',
        key: 'date',
      },
      {
        label: 'Alku',
        key: 'from',
      },
      {
        label: 'Loppu',
        key: 'to',
      },
    );

    let csvData = [];

    const grid = 6;

    if (reportData) {
      reportData.forEach((row) => {
        if (!row.isGroup) {
          const updatedRow = { ...row.workTaskEntry, date: row.workTaskEntry.date.format('DD.MM.YYYY'), userFullName: row.user.fullName };
          csvData.push(updatedRow);
        }
      });

      csvData = sortBy([...csvData], ['workTask.identifiers', 'date'], ['desc', 'desc']);
    }

    // this.getWorkHourReport('2022-09-01', '2022-09-15');
    return (
      <div className="action-buttons" style={{ height: (height || '100%'), width: '100%' }}>
        {mode !== 'tabReport' ? (
          <DeepViewHeader
            content={reportWorkOrder?.name}
            showPreviousView={uiStore.showEmployerBilling}
          />
        ) : (
          <div style={{ textAlign: 'right' }}>
            {hideHeader ? this.renderActionButtonsNoHeader() : this.renderActionButtons(csvData, headers)}
          </div>
        )}

        <ReportSettingsDialog columns={mainColumns?.filter((col) => col.options.display !== 'excluded')} toggleColumnVisibility={this.toggleColumnVisibility} open={settingsDialogOpen} handleClose={this.handleSettingsDialogClose} />
        {reportWorkOrder ? (
          <>
            {!hideHeader ? (
              /* eslint-disable-next-line no-return-assign */
              <div className="printable" id="report" ref={(el) => (this.componentRef = el)}>
                <>
                  <Grid
                    container
                    spacing={2}
                    className="billing-report-header pdf-header"
                    justifyContent="space-between"
                  >
                    <Grid item xs={4}>
                      <span>{moment().format('DD/MM/YYYY')}</span>
                    </Grid>
                    <Grid item xs={4}>
                      <span style={{ textTransform: 'uppercase' }}>Tuntiraportit</span>
                      <span>{` - ${currentUser?.accountInfo?.officialName}`}</span>
                    </Grid>
                    <Grid item xs={4}>
                      {/* <span>{currentUser?.accountInfo?.accountPicture}</span> */}
                      <img style={{ maxWidth: '100%' }} src={currentUser?.accountInfo?.accountPicture} alt="company logo" />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} style={{ width: '100%', height: 'fit-content', padding: '16px 0px' }} className="billing-report-header">
                    <Grid item md={grid} xs={12} className="report-purchaser">
                      <div className="header-label">Tilaaja</div>
                      <div className="header-value">{reportWorkOrder?.purchaser}</div>
                    </Grid>

                    <Grid item md={grid} xs={12} className="daypicker-input-value show-print">
                      <div className="header-label">Ajalta</div>
                      <div className="header-value capitalized">{inputValue}</div>
                    </Grid>
                    <Grid item md={grid} xs={12} className="report-duration hidden-print">
                      <div className="header-label" style={{ lineHeight: 1 }}>Ajalta</div>
                      <div
                        className="header-value daypicker-value"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {renderDateRangePickerHeading && renderDateRangePickerHeading()}
                      </div>
                    </Grid>

                    <Grid item md={grid} xs={12} className="report-location">
                      <div className="header-label">Kohde</div>
                      <div className="header-value">{reportWorkOrder?.location}</div>
                    </Grid>
                    <Grid item md={grid} xs={12} className="report-name">
                      <div className="header-label">Nimi</div>
                      <div className="header-value">{reportWorkOrder?.name}</div>
                    </Grid>
                  </Grid>
                </>
                <div className="billing-report-table">
                  {!isLoading && !stateIsLoading ? (
                    this.renderTableV2()
                  ) : (
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <LoadingSpinner color="black" />
                    </div>
                  )}
                  {/* {this.renderTableV2()} */}
                </div>
              </div>
            ) : (
              <div className="billing-report-table">
                {!isLoading && !stateIsLoading ? (
                  this.renderTableV2()
                ) : (
                  <div style={{ textAlign: 'center', padding: '20px' }}>
                    <LoadingSpinner color="black" />
                  </div>
                )}
                {/* {this.renderTableV2()} */}
              </div>
            )}

            {/* <div className="billing-report-table">
              {this.renderTableV2()}
            </div> */}
          </>
        ) : (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <LoadingSpinner color="black" />
          </div>
        )}
      </div>
    );
  }
}

export default BillingReportsHourly;
