/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import {
  CollapsibleList,
  SimpleListItem,
} from '@rmwc/list';

import { Icon } from '@rmwc/icon';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';


import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';

import '@material/select/dist/mdc.select.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/list/dist/mdc.list.css';
import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';

import HourlyTimelogForm from './hourly-timelog-form';

@inject('timelogStore', 't', 'uiStore')
@observer
class HourlyTimelogRowEditable extends Component {
  constructor(props) {
    super(props);
    this.open = false;
    this.state = {
      showDeleteDialog: false,
      rowToDelete: {},
    };
  }

  componentDidMount() {
    this.setState({
      ...this.props,
      collapsibleOpen: false,
    });
  }

  toggleCollapsible = () => {
    this.setState((prevState) => ({
      collapsibleOpen: !prevState.collapsibleOpen,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  parseLogRowsForUI(logRows) {
    const parsedRows = [];
    logRows.forEach((row) => {
      const parsedRow = row;
      if (row.timeNormal) {
        parsedRow.tempTimeType = 'time_normal';
        parsedRow.tempDuration = row.timeNormal;
        parsedRow.tempId = `${row.timeFrom}_${row.timeTo}_${row.tempTimeType}_${row.workTask.identifierOne}`;
      } else if (row.time50) {
        parsedRow.tempTimeType = 'time_50';
        parsedRow.tempDuration = row.time50;
        parsedRow.tempId = `${row.timeFrom}_${row.timeTo}_${row.tempTimeType}_${row.workTask.identifierOne}`;
      } else if (row.time100) {
        parsedRow.tempTimeType = 'time_100';
        parsedRow.tempDuration = row.time100;
        parsedRow.tempId = `${row.timeFrom}_${row.timeTo}_${row.tempTimeType}_${row.workTask.identifierOne}`;
      }
      parsedRows.push(parsedRow);
    });
    return parsedRows;
  }

  render() {
    const {
      hourlyRow,
      editHourlyRow,
      deleteHourlyRow,
      workTasks,
      date,
      closeDialog,
      oldTimeNormal,
      oldTime50,
    } = this.props;
    const { showDeleteDialog, rowToDelete, collapsibleOpen } = this.state;

    return (
      <>
        <CollapsibleList
          key={`${hourlyRow.date}_${hourlyRow.from}_${hourlyRow.to}_${hourlyRow.workTask.identifierOne}`}
          className="hourly-timelog-list"
          open={collapsibleOpen}
          style={{
            backgroundColor: '#0a131e',
            // marginTop: '1rem',
            // width: '100%',
            // border: '1px #FFC900',
            // borderStyle: 'solid none',
          }}
          handle={(
            <SimpleListItem
              metaIcon="chevron_right"
              onClick={this.toggleCollapsible}
              style={{
                backgroundColor: 'var(--mdc-theme-surface, #fff)',
                flexDirection: 'row-reverse',
                height: 'fit-content',
                padding: '0px 5px',
                borderBottom: collapsibleOpen ? '' : '1px #FFC900 solid',
                borderTop: 'none',
                // borderStyle: 'solid none',
              }}
            >
              <div
                style={{
                  color: 'white',
                  width: '100%',
                  // borderTop: index === 0 ? 'gray 1px solid' : '0',
                  // borderBottom: 'gray 1px solid',
                }}
              >
                <div style={{
                  color: 'white',
                  width: '100%',
                  display: 'flex',
                  padding: '10px 10px 5px 10px',
                  // borderBottom: 'gray 1px solid',
                  // paddingTop: '10px',
                  // paddingBottom: '10px',
                }}
                >
                  <div
                    style={{
                      flexGrow: '1',
                      textAlign: 'start',
                      fontSize: '14px',
                      placeSelf: 'center',
                      width: '100%',
                    }}
                  >
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <div
                        style={{
                          color: '#FFC900',
                          width: '48vw',
                          // Without this maxWidth the viewport width becomes too large on larger screens, because the app's max content width is restricted
                          maxWidth: '600px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        {`Työ: ${hourlyRow.workTask.identifierOne}`}
                      </div>

                      <div style={{ fontSize: '13px', marginRight: '10px' }}>
                        {`${hourlyRow.from || '?'} - ${hourlyRow.to || '?'}`}
                      </div>
                    </div>

                    {hourlyRow.description && (
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          width: '70vw',
                          // Without this maxWidth the viewport width becomes too large on larger screens, because the app's max content width is restricted
                          maxWidth: '700px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {hourlyRow.description}
                      </div>
                    )}

                  </div>

                  {collapsibleOpen ? (
                    <div
                      role="button"
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '10px 5px',
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        this.setState({
                          showDeleteDialog: true,
                          rowToDelete: hourlyRow,
                        });
                      }}
                    >
                      <Icon
                        icon="delete_outline"
                        style={{ color: '#c7c9cc' }}
                      />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '34px',
                        height: '44px',
                      }}
                    />
                  )}
                </div>
              </div>
            </SimpleListItem>
          )}
        >
          <div style={{ backgroundColor: 'var(--mdc-theme-surface)', borderTop: '2px white transparent', padding: '5px 10px' }}>
            {/* {this.hourlyTimelogForm(workTask.identifierOnes, hourlyRow)} */}
            <HourlyTimelogForm
              workTasks={workTasks}
              hourlyRow={hourlyRow}
              editHourlyRow={editHourlyRow}
              date={date}
              mode="edit"
              oldTimeNormal={oldTimeNormal}
              oldTime50={oldTime50}
              afterSave={() => this.setState({ collapsibleOpen: false })}
            />
          </div>
        </CollapsibleList>

        {showDeleteDialog && (
          <Dialog
            open={showDeleteDialog}
            className="hourly-timelog-confirm-delete"
            onClose={(evt) => {
              if (evt.detail.action === 'accept') {
                deleteHourlyRow(rowToDelete);
              }
              evt.stopPropagation();
              closeDialog(false);
              this.setState({
                showDeleteDialog: false,
                rowToDelete: {},
              });
            }}
          >
            <DialogContent style={{ color: 'white' }}>Haluatko varmasti poistaa kirjauksen?</DialogContent>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                padding: '10px',
                flexDirection: 'row',
              }}
            >
              <DialogButton
                type="button"
                className="accept-button"
                style={{
                  borderRadius: '0px',
                }}
                action="accept"
                isDefaultAction
              >
                Kyllä
              </DialogButton>
              <DialogButton
                type="button"
                className="cancel-button"
                action="close"
              >
                Peruuta
              </DialogButton>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default HourlyTimelogRowEditable;
