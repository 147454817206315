import { observable, action } from 'mobx';
import moment from 'moment';
import CertificateValidity from './CertificateValidity';
import CertificateType from './CertificateType';

// ChatGPT 15.3.2024
const validityWithGreatestExpirationDate = (validities) => (
  validities.reduce((acc, obj) => {
    if (!acc.expirationDate || obj.expirationDate.isAfter(acc.expirationDate)) {
      return obj;
    }
    return acc;
  }, {})
);

export default class Certificate {
  @observable id;

  @observable identifier;

  @observable description;

  @observable userId;

  @observable user;

  @observable currentExam;

  @observable material;

  @observable methodology;

  @observable currentValidityCheck;

  @observable certificateValidities = [];

  // @observable isWatched = false;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    const methodologyTypeJson = o.certificate_types?.find((type) => type.type_category === 'methodology');
    const materialTypeJson = o.certificate_types?.find((type) => type.type_category === 'material');

    this.updateProperties({
      id: o.id,
      identifier: o.identifier,
      description: o.description,
      // files: o.files?.map((file) => ({
      //   filename: file.filename,
      //   fileURL: file.url,
      //   signedId: file.signed_id,
      //   createdAt: file.created_at,
      // })),
      certificateValidities: o.certificate_validities ? o.certificate_validities.map(CertificateValidity.fromJsonProperties) : [],
      user: o.user,
      userId: o.user.id,
      createdAt: moment(o.created_at),
      // categoryOne: o.category_one,
      // categoryTwo: o.category_two,
      methodology: methodologyTypeJson ? CertificateType.fromJsonProperties(methodologyTypeJson) : null,
      material: materialTypeJson ? CertificateType.fromJsonProperties(materialTypeJson) : null,
      // certificateTypes: o.certificate_types,
    });

    // Get the current (latest) exam and the current (latest) validity check for table rendering
    const exams = this.certificateValidities.filter((validity) => validity.validityType === 'exam');
    const validityChecks = this.certificateValidities.filter((validity) => validity.validityType === 'validity_check');
    this.currentExam = validityWithGreatestExpirationDate(exams);
    this.currentValidityCheck = validityWithGreatestExpirationDate(validityChecks);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const certificate = new Certificate({});
    certificate.updatePropertiesFromJson(object);
    return certificate;
  }

  static fromJson(json) {
    return Certificate.fromJsonProperties(JSON.parse(json));
  }

  static toJson(o) {
    console.log('TO JSON: ', o);

    const certificateValiditiesJson = o.certificateValidities?.map(CertificateValidity.toJson) || [];
    if (o.newExamIssueDate || o.newExamExpirationDate) {
      const newExam = new CertificateValidity({
        certificateId: o.id,
        expirationDate: o.newExamExpirationDate,
        issueDate: o.newExamIssueDate,
        validityType: 'exam',
        fileSignedId: o.newExamFile,
      });

      certificateValiditiesJson.push(CertificateValidity.toJson(newExam));
    }

    if (o.newValidityCheckExpirationDate || o.newValidityCheckIssueDate) {
      const newValidityCheck = new CertificateValidity({
        certificateId: o.id,
        expirationDate: o.newValidityCheckExpirationDate,
        issueDate: o.newValidityCheckIssueDate,
        validityType: 'validity_check',
        fileSignedId: o.newValidityCheckFile,
      });
      certificateValiditiesJson.push(CertificateValidity.toJson(newValidityCheck));
    }

    return {
      id: o.id,
      identifier: o.identifier,
      description: o.description,
      user_id: o.userId,
      certificate_validities_attributes: certificateValiditiesJson,
      // category_one: o.category_one?.id,
      // category_two: o.category_two?.id,
      certificate_type_ids: [o.methodology?.id, o.material?.id],
    };
  }

  // static toJson(o) {
  //   console.log('TO JSON: ', o);

  //   const certificateValiditiesJson = o.certificateValidities?.map(CertificateValidity.toJson) || [];
  //   if (o.newExamIssueDate || o.newExamExpirationDate) {
  //     const newExam = new CertificateValidity({
  //       certificateId: o.id,
  //       expirationDate: o.newExamExpirationDate,
  //       issueDate: o.newExamIssueDate,
  //       validityType: 'exam',
  //       file: o.newExamFile,
  //     });

  //     certificateValiditiesJson.push(CertificateValidity.toJson(newExam));
  //   }

  //   if (o.newValidityCheckExpirationDate || o.newValidityCheckIssueDate) {
  //     const newValidityCheck = new CertificateValidity({
  //       certificateId: o.id,
  //       expirationDate: o.newValidityCheckExpirationDate,
  //       issueDate: o.newValidityCheckIssueDate,
  //       validityType: 'validity_check',
  //       file: o.newValidityCheckFile,
  //     });
  //     certificateValiditiesJson.push(CertificateValidity.toJson(newValidityCheck));
  //   }

  //   const formData = new FormData();
  //   formData.append('identifier', o.identifier);
  //   formData.append('id', o.id);
  //   formData.append('user_id', o.userId);
  //   // null is saved as a string so we need to set it as undefined instead
  //   formData.append('description', o.description || undefined);
  //   formData.append('certificate_validities_attributes', certificateValiditiesJson);

  //   return formData;

  //   // return {
  //   //   name: o.name,
  //   //   id: o.id,
  //   //   valid_through_date: o.validThroughDate,
  //   //   admittance_date: o.admittanceDate,
  //   //   location: o.location,
  //   //   work_order: o.workOrder,
  //   //   company: o.company,
  //   //   number: o.number,
  //   //   description: o.description,
  //   //   file: o.file,
  //   //   // file_test: o.fileTest,
  //   // };
  // }
}
