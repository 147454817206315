import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: '#FFFFFF',
        '&:hover': {
          backgroundColor: '#f5f5f5', // light grey background on hover
        },
        '&.Mui-focused': {
          backgroundColor: 'white',
          borderColor: '#fec009', // orange border when focused
        },
      },
      underline: {
        '&:before': {
          borderBottomColor: 'grey', // grey border when not focused
        },
        '&:hover:before': {
          borderBottomColor: 'grey', // grey border on hover
        },
        '&:after': {
          borderBottomColor: '#fec009', // orange border when focused
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: 'grey', // default color of input label
        '&$focused': {
          color: '#651ee3', // purple color when focused
        },
      },
      focused: {},
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-required': {
          color: 'red',
        },
      },
    },
  },
});

export default theme;
