/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';
import LoadingSpinner from '../../shared/LoadingSpinner';

import TimeField from 'react-simple-timefield';

import {
  ListItemMeta,
} from '@rmwc/list';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
// import SearchableDropdown from '../../shared/SearchableDropdown';
import Validity from '../../models/Validity';
import './userValidities.css';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import { DirectUploadProvider } from 'react-activestorage-provider';

// import MomentLocaleUtils, {
//   formatDate,
//   parseDate,
// } from 'react-day-picker/moment';
// import 'moment/locale/fi';
// import moment from '../utils/moment';

// import { buttonColors } from '../calendar/EmployeeCalendar';
// import { handleProgress } from '../models/user/user-ui-avatar';
// import AccidentReport from '../models/safety/AccidentReport';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

// TODO: MAKE THESE WITH PROPS
// const descriptionOrFileValidation = (description, files) => {
//   if (!description && !files) {
//     return 'Vaadittu';
//   // TODO: this kind of stupid !files and files.length speaks of a fundamental problem if files can be both falsy and empty array
//   } else if (!description && files.length === 0) {
//     return 'Vaadittu';
//   }
//   return null;
// };

// const nameValidate = (name) => {
//   if (!name) {
//     return 'Liitteen nimi on pakollinen';
//   }
//   return null;
// };

const validateName = (name) => {
  if (!name) {
    return 'Nimi on pakollinen';
  }
  return null;
};

const validateValidThroughDate = (validThroughDate) => {
  if (!validThroughDate) {
    return 'Voimassaolo on pakollinen';
  }
  return null;
};

const validateFile = (file) => {
  if (!file) {
    return 'Tiedosto on pakollinen';
  }
  return null;
};

//

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  // name: (name) => nameValidate(name),
  name: (name) => validateName(name),
  // time: (time) => validateTime(time),
  // workSteps: (workSteps) => validateWorkSteps(workSteps),
  // eventSteps: (eventSteps) => validateEventSteps(eventSteps),
  // causes: (causes) => validateCauses(causes),
  // fixSuggestions: (fixSuggestions) => validateFixSuggestions(fixSuggestions),
  // relatedObject: (relatedObject) => validateRelatedObject(relatedObject),
  validThroughDate: (validThroughDate) => validateValidThroughDate(validThroughDate),
  validateFile: (file) => validateFile(file),
};

@inject('uiStore', 'loginStore', 't', 'validityStore')
@observer
class ValidityDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validity: this.props.validity || {},
      errors: {},
      uploading: false,
    };
  }


  renderActionButtons() {
    const { validity, errors, uploading } = this.state;

    return (
      <DialogActions
        style={{
          justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <DialogButton
          type="button"
          className="accept-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          // onClick={() => {
          //   this.handleValidate();
          // }}
          action="submit"
          disabled={uploading || (!validity.name || !validity.validThroughDate || Object.keys(errors).length > 0)}
        >
          {uploading ? <LoadingSpinner color="black" size="mini" /> : 'Tallenna'}
        </DialogButton>
        <DialogButton
            type="button"
            style={{
              color: '#c7c9cc',
              border: '1px solid #c7c9cc',
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="cancel"
          >
            Peruuta
          </DialogButton>
      </DialogActions>
    );
  }

  handleValidate() {
    let errors = {};

    console.log('Validating');

    Object.keys(validate).forEach((key) => {
      const error = validate[key](this.state.accidentReport[key]);
      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    this.setState({
      errors: { ...errors },
    });
  }

  validateDate(date) {
    if (date) {
      const valid = date.match('^((0[1-9]|1[0-2]))/([2][0-9]{3})$');
      // regex match returns array of matches or null
      if (valid) {
        return valid.length > 0;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  validateAssociations() {
    const { validity } = this.state;
    return !validity.company && !validity.workOrder && !validity.location
  }

  handleSelectChange = (attr, value) => {
    this.setState((prevState) => ({
      accidentReport: {...prevState.accidentReport, [attr]: value}
    }))
  }

  textareaResize(evt) {
    // Need to reset the height to 1px since the minimum scrollHeight is the element height,
    // meaning we need to reset the height to recalculate the scrollHeight or it won't stop growing
    evt.target.style.height = '1px';
    evt.target.style.height = `${evt.target.scrollHeight}px`;
  }

  addTextField(attr, label, required, title, invalidText, textarea = false, extraOnChange = null) {
    const { validity, errors } = this.state;
    // Old pattern parameter (mm/yyyy): '^((0[1-9]|1[0-2]))/([2][0-9]{3})$'
    return (
      <TextField
        {...{
          required,
          // className: 'profile-input-95-view-width left-aligned-helptext',
          className: textarea ? 'textarea-hack profile-input-95-view-width left-aligned-helptext' : 'profile-input-95-view-width left-aligned-helptext',
          label,
          textarea,
          maxLength: textarea ? 50 : 100,
          // onBlur: (event) => this.save(event.target.value),
          onChange: (event) => {
            this.doOnChange(event, attr);
            if (extraOnChange) {
              extraOnChange(event);
            }
          },
          title,
          // pattern,
          invalid: errors[attr],
          helpText: errors[attr] && {
            persistent: true,
            validationMsg: true,
            children: invalidText,
          },
          rootProps: { ripple: false },
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          value: validity[attr] || '',
          // We remove a margin-bottom 1px that creates a gap between the input's lower edge and the highlight border
          style: { marginBottom: '0' },
        }}
      />
    );
  }

  addDateTextField(attr, label, required, title, invalidText) {
    const { validity, errors } = this.state;
    // Old pattern parameter (mm/yyyy): '^((0[1-9]|1[0-2]))/([2][0-9]{3})$'
    return (
      <TextField
        {...{
          required,
          className: 'profile-input-95-view-width left-aligned-helptext',
          label,
          // onBlur: (event) => this.save(event.target.value),
          onChange: (event) => {
            // Reset this attr's error (may be added again in doOnDateBlur)
            const resetedErrors = { ...errors };
            delete resetedErrors[attr];
            this.setState({
              validity: {
                ...validity,
                [`${attr}Temp`]: event.target.value
              },
              errors: resetedErrors,
            });
          },
          onBlur: (event) => {
            this.doOnDateBlur(event, attr);
          },
          title,
          // pattern: '^((0[1-9]|1[0-2])|([0-9]))/?([0-9]{4}|[0-9]{2})$',
          // /\A((0[1-9]|1[0-2]))\/([2][0-9]{3})\z/
          // This pattern is basically the same as user.rb's validation pattern, only slightly different to make it work in js
          // pattern: '^((0[1-9]|1[0-2]))/([2][0-9]{3})$',
          // pattern,
          invalid: errors[attr],
          helpText: errors[attr] && {
            persistent: true,
            validationMsg: true,
            children: invalidText,
          },
          rootProps: { ripple: false },
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          value: validity[`${attr}Temp`] || '',
          // style: { height: 'auto' },
        }}
      />
    );
  }

  doOnChange(event, attr) {
    const { validity } = this.state;

    this.setState({ validity: { ...validity, [attr]: event.target.value } });
  }

  // Use this instead of doOnChange for date text fields
  doOnDateBlur(event, attr) {
    const { validity, errors } = this.state;
    const tempValidity = new Validity({...validity});

    if (event.target.value) {
      const convertedDate = Validity.convertDate(event.target.value);
      // Cancel saving if the date is invalid
      if (!convertedDate.isValid) {
        this.setState({ errors: {...errors, [attr]: 'invalid' } });
      } else {
        // We want to set the converted dateObject as the real value while also preserving the user input
        tempValidity.changeAttribute(attr, convertedDate.dateObject);
        tempValidity.changeAttribute(`${attr}Format`, convertedDate.formatIndex);
        this.setState({
          validity: {
            ...tempValidity,
            // Update the temp so that it doesn't suddenly change to the user after conversion
            [`${attr}Temp`]: Validity.convertDateToOriginalInput(convertedDate.dateObject, convertedDate.formatIndex),
          },
          errors,
        });
      }
    }
  }

  save(cb) {
    const { validityStore, uiStore: { currentUser } } = this.props;
    const { validity } = this.state;

    this.setState({ uploading: true });

    const updatePromise = fromPromise(new Promise((resolve, reject) => validityStore.updateValidity(validity, resolve, reject)));
    when(
      () => updatePromise.state !== "pending",
      () => {
        updatePromise.case({
          pending: () => {},
          rejected: (err) => {
            console.log('Tallennus epäonnistui', err);
            this.setState({ uploading: false });
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (newValidity) => {
            // This kind of filtering is necessary, a simple push will for some reason duplicate the element in the UI
            const filteredValidities = currentUser.validities.filter((validity) => validity.id !== newValidity.id);
            currentUser.validities = [...filteredValidities, newValidity];
            // Reset the fields
            this.setState({ validity: {}, uploading: false });
            cb();
            // validity.updateProperties(newValidity);

            // this.setState({
            //   userProductCollection: newUserProductCollection,
            //   selectedProducts: newUserProductCollection.userProducts,
            // });
          }
        });
      }
    )
  }

  setFile(file) {
    this.setState((prevState) => ({
      validity: { ...prevState.validity, file: file }
    }))
  }

  updateAssociationById = (attr, item) => {
    const { validity } = this.state;
    this.setState({ validity: { ...validity, [attr]: item } });
  }

  afterSave = () => {
    const { handleClose } = this.props;
    this.setState({
      validity: {},
      uploading: false,
    });
    handleClose();
  }

  render() {
    const { t, open, handleClose, uiStore: { currentUser: { validityTargets }} } = this.props;
    const { errors, validity } = this.state;

    const invalidDateMessage = 'Syötä päivämäärä muodossa pv/kk/vvvv, kk/vvvv, kk/vv tai pv.kk.vvvv';

    return (
      <Dialog
        className="modal-size trip-route-dialog red-helper-texts"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'submit') {
            this.save(this.afterSave);
            // const error = validate.attachments(description, files);
            // if (!error) {xr
            // const sendPromise = fromPromise(new Promise((resolve, reject) => accidentReportStore.create(accidentReport, resolve, reject)));

            // when(
            //   () => sendPromise.state !== "pending",
            //   () => {
            //     sendPromise.case({
            //       pending: () => {
            //       },
            //       rejected: (err) => {
            //         console.log('Tallennus epäonnistui', err);
            //       },
            //       fulfilled: (accidentReport) => {
            //         this.props.newAccidentReport(accidentReport);
            //         this.setState({
            //           accidentReport: {
            //             disablementDurationEstimate: 'unknown',
            //             treatmentStartImmediacy: 'now',
            //             victimStoppedWorkingImmediacy: 'no',
            //           },
            //         })
            //         handleClose();
            //       }
            //     });
            //   }
            // )
            // }
            // handleClose();
          } else {
            this.setState({ uploading: false });
            handleClose();
          }
        }}
      >
        <DialogTitle style={{ color: 'var(--mdc-theme-primary, #6200ee)' }}>Uusi voimassaolo</DialogTitle>
        <DialogContent>
          <Grid
            style={{
              // padding: '16px 16px 0px 16px',
              paddingTop: '0',
              maxWidth: '800px',
            }}
          >
            <GridCell span={12}>
              { this.addTextField('name', 'Nimi', true, 'Syötä voimassaolon nimi', null, true, this.textareaResize) }
            </GridCell>
            <GridCell span={12}>
              { this.addDateTextField('validThroughDate', `${t('cards.validThrough')}`, true, 'Syötä voimassaolo', invalidDateMessage) }
            </GridCell>
            <GridCell span={12}>
              { this.addTextField('number', 'Numero', false, 'Syötä voimassaolon numero', null) }
            </GridCell>
            <GridCell span={12}>
              { this.addDateTextField('admittanceDate', `Suorittamisaika`, false, 'Syötä suorittamisaika', invalidDateMessage) }
            </GridCell>
            <GridCell span={12} style={{ marginLeft: '5px', marginTop: '5px' }}>
              <label
                htmlFor="file-upload"
                className="validity-file-upload accept-button mdc-button"
              >
                <Icon
                  alt="camera"
                  icon="photo_camera"
                />
                <span style={{ marginLeft: '10px' }}>Lisää tiedosto</span>
              </label>
              {validity.file && <span style={{ color: 'white', fontSize: '12px' }}>{validity.file.name}</span>}
              <input
                id="file-upload"
                type="file"
                onChange={(e) => this.setFile(e.target.files[0])}
                accept="image/png, image/jpeg, image/jpg, application/pdf"
                style={{ display: 'none' }}
              />
            </GridCell>
          </Grid>
          {/* <SearchableDropdown selectedItem={validity.company} attr="company" label="Yritys" items={validityTargets.companies} updateAttr={this.updateAssociationById} handlePlaceholder="Valitse yritys" searchPlaceholder="Hae yritystä nimellä" />
          <SearchableDropdown selectedItem={validity.workOrder} attr="workOrder" label="Keikka" items={validityTargets.workOrders} updateAttr={this.updateAssociationById} handlePlaceholder="Valitse keikka" searchPlaceholder="Hae keikkaa nimellä" />
          <SearchableDropdown selectedItem={validity.location} attr="location" label="Kohde" items={validityTargets.locations} updateAttr={this.updateAssociationById} handlePlaceholder="Valitse kohde" searchPlaceholder="Hae kohdetta nimellä" /> */}
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    )
  }
}

export default ValidityDialog;
