import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import {
  Card,
  CardPrimaryAction,
} from '@rmwc/card';
import { groupBy } from 'lodash';
import moment, { asDate } from '../utils/moment';
import CalendarEntry from '../models/CalendarEntry';
import './WorkOrderItem.css';

// Should be cleaned up somehow, this many params suggests an overcomplicated function. Maybe split
export const renderWorkDayCategory = (title, calendarEntries, workOrderInterval, titleStyles = {}, entryStyles = {}, showAll = false, moreThanOnePeriod = true, view = null) => {
  const today = new Date();
  const isCurrent = !calendarEntries.find((entry) => {
    let entryFrom;
    let entryTo;
    if (entry.datetime_range) {
      const splitDateTimeRange = entry.datetime_range.split('..');
      // Try to find an entry that is older than current day
      entryFrom = moment(splitDateTimeRange[0]).toDate(); // Date.parse(entry.datetime_range.split('..')[0]);
      entryTo = moment(splitDateTimeRange[1]).toDate(); // Date.parse(entry.datetime_range.split('..')[1]);
    } else {
      entryFrom = entry.from.toDate();
      entryTo = entry.to.toDate();
    }

    return entryFrom < today && entryTo < today;
  });

  if (view === 'timelogview') {
    return null;
  }

  return (
    <>
      {calendarEntries && calendarEntries.length > 0 && (
        <div style={{ fontSize: '16px', color: '#FFFFFF80', fontStyle: isCurrent ? 'normal' : 'italic' }}>
          {moreThanOnePeriod && (
            <div key={title} style={{ fontSize: '14px', ...titleStyles }}>
              {title}
            </div>
          )}
          <div style={{ marginLeft: '10px' }}>
            {/* {item.calendarEntries.map((entry) => { */}
            {calendarEntries.map((entry, index) => {
              // Assuming single days, only using the first date
              // Might be moment or a date time range string depending if invitation or work order is rendered, should be cleaned
              const splitDateRange = entry.datetime_range?.split('..');
              const shortFormat = 'dd DD.MM.';

              const startDate = splitDateRange ? moment(splitDateRange[0], 'YYYY-MM-DD HH:mm:ss UTC') : entry.from;
              const startDateFormatted = startDate.format(shortFormat);

              const endDate = splitDateRange ? moment(splitDateRange[1], 'YYYY-MM-DD HH:mm:ss UTC') : entry.to;
              const endDateFormatted = endDate.format(shortFormat);

              if (!showAll && calendarEntries.length === 1 && startDateFormatted === workOrderInterval.start.format(shortFormat) && endDateFormatted === workOrderInterval.end.format(shortFormat)) {
                return null;
              }

              const numberOfDays = endDate.diff(startDate, 'days') + 1;

              if (isCurrent) {
                // Render as stacked divs
                return (
                  <div key={entry.id} style={{ textTransform: 'capitalize', ...entryStyles }}>
                    {startDateFormatted === endDateFormatted ? startDateFormatted : `${startDateFormatted} - ${endDateFormatted} (${numberOfDays} pv)`}
                  </div>
                );
              }
              // Render as spans
              return (
                <span key={entry.id} style={{ textTransform: 'capitalize', color: 'rgba(255, 255, 255, 0.5)', ...entryStyles }}>
                  {startDateFormatted === endDateFormatted ? startDateFormatted : `${startDateFormatted} - ${endDateFormatted} (${numberOfDays} pv)`}
                  {index + 1 !== calendarEntries.length && ', '}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

@inject('workOrderStore', 'uiStore', 't')
@observer
class WorkOrderItem extends Component {
  constructor(props) {
    super(props);

    // this.workDaysCount = null;

    this.workOrderDaysCount = null;

    this.groupedCurrentAndUpcomingDays = {};

    this.foundPeriodVsWorkOrderDiscrepancy = false;

    this.dateMismatch = false;

    this.state = {
      // workDaysCount: null,
      groupedWorkDays: {},
      foundPeriodVsWorkOrderDiscrepancy: false,
      dateMismatch: false,
    };
  }

  componentDidMount() {
    const { item } = this.props;

    if (item.workDays) {
      // Work order interval days
      const workOrderDaysCount = item.to.diff(item.from, 'days') + 1;
      // Actual user-specific working days (calendar entries)
      const workDaysCount = item.workDays.length;
      let dateMismatch = false;
      let groupedWorkDays = {};
      let foundPeriodVsWorkOrderDiscrepancy = false;

      // Check calendar entries to see if they match the work order or not
      // Note: archived work orders do not have work days
      if (workDaysCount > 0 && (workOrderDaysCount !== workDaysCount || item.firstWorkDay.format('YYYY-MM-DD') !== item.from.format('YYYY-MM-DD') || item.lastWorkDay.format('YYYY-MM-DD') !== item.to.format('YYYY-MM-DD'))) {
        dateMismatch = true;
      }

      // Sort descending
      const workDaysSorted = item.workDays.slice().sort((a, b) => {
        const dateA = a.from.toDate();
        const dateB = b.from.toDate();
        return dateB - dateA;
      });

      groupedWorkDays = groupBy(workDaysSorted, 'workPeriodName');

      const totalWorkDayRanges = CalendarEntry.packageJsonIntoRangesMoment(item.workDays);
      foundPeriodVsWorkOrderDiscrepancy = totalWorkDayRanges.find((range) => range.from.format('YYYY-MM-DD') !== item.interval.start.format('YYYY-MM-DD') || range.to.format('YYYY-MM-DD') !== item.interval.end.format('YYYY-MM-DD'));

      this.setState({
        // workDaysCount,
        groupedWorkDays,
        foundPeriodVsWorkOrderDiscrepancy,
        dateMismatch,
      });
    }
  }

  render() {
    const {
      actionButtons, item, onClick, shortDescription, showArrow, skipDescription, tripRejected, workHourRejected, useGap, view, uiStore,
    } = this.props;
    const {
      // workDaysCount,
      groupedWorkDays,
      foundPeriodVsWorkOrderDiscrepancy,
      dateMismatch,
    } = this.state;

    const cancelledEntries = item.calendarEntries?.filter((entry) => entry.status === 'cancelled') || [];
    const notCancelledEntries = item.calendarEntries?.filter((entry) => entry.status !== 'cancelled') || [];

    // Move this logic out of the render method so that it isn't repeated pointlessly
    // Ideally should be done in the js model/class fromJson-type method
    const cancelledCalendarEntries = CalendarEntry.packageJsonIntoRanges(cancelledEntries);
    const calendarEntries = CalendarEntry.packageJsonIntoRanges(notCancelledEntries);
    const previousCalendarEntries = CalendarEntry.packageJsonIntoRanges(item.previousCalendarEntries);

    // We need to check if the invitation matches the work order or not (e.g. just a few days in a week-long work order)
    // We compare the number of dates and look for any calendar entries that happen to be outside the work period dates for a complete check
    const intervalDaysCount = item.interval.end.diff(item.interval.start, 'days') + 1;
    // This mismatch checking shouldn't really be needed because it checks for entries that are outside the work order dates
    // However, this is technically possible to do, so we keep this here to make the check complete when also using the number of dates for comparison
    const entryOutsideWorkOrder = notCancelledEntries.find((entry) => {
      const entryStart = moment(entry.datetime_range.split('..')[0], 'YYYY-MM-DD HH:mm:ss UTC');
      const mismatch = !entryStart.isBetween(item.interval.start, item.interval.end, null, '[]');
      return mismatch;
    });

    return (
      <Card
        onClick={onClick}
        style={{
          boxShadow: 'none',
          marginTop: useGap && item._addGap === true ? '1em' : '',
        }}
      >
        <CardPrimaryAction>
          <div style={{ padding: (item.workHourType === 'hourly' && view === 'calendar') ? '0 1rem 0 1rem' : '0 1rem 1rem 1rem' }}>
            <div {...{
              style: {
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
            }}
            >
              <Typography {...{
                style: {
                  color: 'white !important',
                  lineHeight: '1.25rem',
                  marginBottom: '1em',
                  marginTop: '0.5em',
                },
                tag: 'h6',
                use: 'subtitle1',
              }}
              >
                {item.name}
                {(tripRejected || workHourRejected) && <Icon icon="info" className="project-rejection-icon" />}
              </Typography>
              {showArrow === true && (
                <Icon {...{
                  icon: 'navigate_next',
                  style: {
                    paddingTop: '0.8em',
                  },
                }}
                />
              )}
            </div>

            <Typography
              style={{ marginTop: '-1rem' }}
              tag="div"
              theme="textHintOnDark"
              use="body2"
            >
              {(dateMismatch || view === 'timelogview') ? (
                <>
                  {/* {asDate(moment.min([item.interval.start, item.firstWorkDay]), 'DD.MM.')}
                  –
                  {asDate(moment.max([item.interval.end, item.lastWorkDay]))}
                  {` (${workDaysCount} pv) `} */}
                  {item.location?.name && ` ${item.location.name}`}
                </>
              ) : (
                <>
                  {asDate(item.interval.start, 'DD.MM.')}
                  –
                  {asDate(item.interval.end)}
                  {item.location?.name && ` ${item.location.name}`}
                </>
              )}
            </Typography>

            {foundPeriodVsWorkOrderDiscrepancy && Object.keys(groupedWorkDays).map((periodName) => {
              // const numberOfPeriods = Object.keys(groupedCurrentAndUpcomingDays).length;
              const workDayRanges = CalendarEntry.packageJsonIntoRangesMoment(groupedWorkDays[periodName]);
              // Sort work day ranges ascending
              const sortedWorkDayRanges = workDayRanges.slice().sort((a, b) => {
                const dateA = a.from.toDate();
                const dateB = b.from.toDate();
                return dateA - dateB;
              });

              return (
                // className={Object.keys(groupedCurrentAndUpcomingDays).length === index + 1 && Object.keys(groupedPastDays).length > 0 && 'current-work-days-when-past-exist'}
                <div>
                  {renderWorkDayCategory(periodName, sortedWorkDayRanges, item.interval, { marginTop: '5px' }, { fontSize: '14px' }, false, item.workPeriodNames.size > 1, view)}
                </div>
              );
            })}

            {(entryOutsideWorkOrder || notCancelledEntries.length !== intervalDaysCount) && renderWorkDayCategory('Odottaa hyväksyntääsi', calendarEntries, item.interval, { color: 'var(--mdc-theme-primary)', margin: '5px 0 5px 0' }, { fontWeight: 'bold', color: 'var(--mdc-theme-primary)' })}
            {renderWorkDayCategory('Peruttu', cancelledCalendarEntries, item.interval, { margin: '15px 0 5px 0' }, { color: 'red' })}
            {renderWorkDayCategory('Aiemmin hyväksymäsi', previousCalendarEntries, item.interval, { margin: '15px 0 5px 0' }, { fontSize: '14px', color: 'white' }, true)}

            {!skipDescription && (
              <Typography
                style={{ marginTop: '1em', whiteSpace: 'pre-wrap' }}
                tag="div"
                theme="textSecondaryOnDark"
                use="body2"
              >
                {shortDescription === true ? item.description.split('\n')[0] : item.description}
              </Typography>
            )}
          </div>

          {item.workHourType === 'hourly' && view === 'calendar' && (
            <div
              style={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'row',
                padding: '5px 20px',
                fontSize: '12px',
              }}
            >
              <span
                role="button"
                className="span-button"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '5px',
                }}
                onClick={() => uiStore.setActiveTabAndShowEmployeeWorkOrder(item.id, 2)}
              >
                <FormatListBulletedIcon
                  className="work-task-card-icon"
                  fontSize="small"
                />
                <span className="invitations-neutral" style={{ paddingRight: 0, color: 'white' }}>
                  {item.workTasks.length === 0 ? '-' : item.workTasks.length}
                </span>
              </span>
            </div>
          )}
        </CardPrimaryAction>
        {actionButtons}
      </Card>
    );
  }
}

export default WorkOrderItem;
