import { observable } from 'mobx';

import WorkOrder from '../../calendar/WorkOrder';
import ChatMessage from './ChatMessage';

export default class ChatRoom {
  @observable id;

  @observable title;

  @observable workorder;

  @observable messages = [];

  @observable emailNotification;

  constructor({
    id, title, workorder, messages, emailNotification,
  }) {
    this.id = id;
    this.workorder = workorder;
    this.messages = messages || [];
    this.title = title;
    this.emailNotification = emailNotification;
  }

  getChatTitle() {
    return this.title;
  }

  get latestMessage() {
    return this.messages.length > 0 ? this.messages[this.messages.length - 1] : null;
  }

  static fromJson(jsonProperies) {
    const messages = jsonProperies.messages.map((item) => ChatMessage.fromJson(item));
    const workorder = WorkOrder.fromJsonProperties(jsonProperies.work_order);

    const properties = {
      id: jsonProperies.id,
      messages,
      title: workorder.name,
      workorder,
      emailNotification: jsonProperies.email_notification,
    };

    return new ChatRoom(properties);
  }
}
