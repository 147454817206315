/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { fromPromise } from 'mobx-utils';
// import { when } from 'mobx';
import {
  List,
  ListItem,
} from '@rmwc/list';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
import { Checkbox } from '@rmwc/checkbox';
import '@rmwc/avatar/avatar.css';
import { Typography } from '@rmwc/typography';
import {
  Dialog,
  DialogContent,
  DialogButton,
  DialogActions,
} from '@rmwc/dialog';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';

import UserValidities from './validities/userValidities';
import { addAttachedCard, addCollapsibleCard } from './cards/card';
import { addProfilePicture } from '../models/user/user-ui-avatar';
import { appcontentsize, w100 } from '../styles/inline-styles';
import { mandatoryCardTypes } from '../models/User';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import FilePreviewDialog from '../shared/FilePreviewDialog';
// import { fileInput } from '../registration';

@inject('uiStore', 'loginStore', 'validityStore', 't')
@observer
class Profile extends Component {
  constructor() {
    super();
    this.form = React.createRef();
    this.profileInputRef = React.createRef();
    this.state = {
      activeTab: 0,
      deletingFile: null,
      showDeleteDialog: false,
      showCardDialog: false,
      cardDialogUrl: null,
      cardDialogName: null,
    };
  }

  componentDidMount() {
    this.hackSaveOriginalData();
  }

  confirmDelete = (entity) => {
    this.setState({ showDeleteDialog: true, deletingFile: entity });
  }

  openCard = (imageUrl, imageName) => {
    this.setState({
      showCardDialog: true,
      cardDialogUrl: imageUrl,
      cardDialogName: imageName,
    });
  }

  deleteFile = () => {
    const { uiStore } = this.props;
    const { deletingFile } = this.state;
    switch (deletingFile) {
      // Attached
      case 'taxIncomeCardUrl':
        uiStore.purgeTaxIncomeCard();
        this.save();
        break;
      case 'helmetImageUrl':
        uiStore.purgeHelmetImage();
        this.save();
        break;
      // Detail cards
      // case 'firstAidTraining':
      //   uiStore.purgeFirstAidTrainingImage();
      //   this.save();
      default:
        /* Assuming a JSONified card (unattached blob) */
        uiStore.purgeCard(deletingFile).then(() => {
          // this.save();
          uiStore.currentUser.purgeDetailCardProp(deletingFile);
        });
    }
  }

  closeConfirmDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  }

  save() {
    const { uiStore } = this.props;
    uiStore.updateUser()
      .then(() => this.hackSaveOriginalData())
      .catch(() => {
        /* noop */
      });
  }

  // HACK: suboptimal solution to allow evaluation whether to update user data
  hackSaveOriginalData() {
    const { props: { uiStore: { currentUser } } } = this;
    this.__original__ = JSON.parse(JSON.stringify(currentUser));
  }

  renderTextField(attr, props = {}) {
    const { t, uiStore } = this.props;
    const { currentUser } = uiStore;

    return (
      <TextField
        {...props}
        key={attr}
        label={t(`profile.labels.${attr}`)}
        onBlur={() => {
          if (this.__original__[attr] !== currentUser[attr]) {
            this.save();
          }
        }}
        onChange={(event) => {
          currentUser[attr] = event.target.value; // eslint-disable-line no-param-reassign
        }}
        rootProps={{ ripple: false }} // HACK: to avoid ripple causing synthetic event console warnings
        theme={['textPrimaryOnDark', 'secondaryBg']}
        value={currentUser[attr] || ''}
      />
    );
  }


  // showCardDialog: false in the initial state
  // setState cardDialogUrl on card click
  // make sure that the zooming and whatever works

  closeFilePreviewDialog = () => {
    this.setState({
      showCardDialog: false,
      cardDialogUrl: null,
    });
  }

  showFilePreviewDialog = (file) => {
    console.log('OPENING FILE: ', file);
    this.setState({
      showCardDialog: true,
      cardDialogUrl: file.fileURL,
      cardDialogName: file.fileName,
    });
  }

  renderCardDialog() {
    const { showCardDialog, cardDialogUrl, cardDialogName } = this.state;

    return (
      <FilePreviewDialog open={showCardDialog} fileName={cardDialogName} fileURL={cardDialogUrl} onClose={this.closeFilePreviewDialog} />
    );
  }

  renderPersonalInfo() {
    const { form, t, uiStore: { currentUser } } = this.props;

    return (
      <form {...{
        className: 'profile__details theme-dark',
        onSubmit: (e) => e.preventDefault(),
        ref: form,
        style: {
          ...appcontentsize,
          marginBottom: '1em',
          width: '25wv',
        },
      }}
      >
        <List>
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {/* A quick and dirty way to position the pic slightly to the right without affecting the name input widths */}
            <div style={{ width: '10vw', margin: 'auto' }}>
              {addProfilePicture(currentUser, this.profileInputRef, currentUser.personalPictureUrl, t)}
            </div>
            {this.renderTextField('firstName', { className: 'ml-1 profile-input-75-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('lastName', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('socialSecurityNumber', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('email', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('phoneNumber', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('street', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('zipCode', { type: 'number', className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('city', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('taxNumber', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('taxPercent', {
              type: 'number', step: 'any', min: 0, max: 100, className: 'ml-1 profile-input-95-view-width',
            })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('iceName', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('iceNumber', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>
          {/* eslint-disable react/jsx-wrap-multilines */}

          {addAttachedCard(
            'taxIncomeCardUrl', t, currentUser,
            (event, entity) => {
              if ((event == null || event.currentTarget.validity.valid) && this.__original__[entity] !== currentUser[entity]) {
                this.save();
              }
            },
            {
              attribute: 'taxation_income_card',
              method: 'PUT',
              model: 'User',
              multiple: false,
              path: `/api/users/${currentUser.id}`,
            },
            this.confirmDelete,
            this.openCard,
          )}


          {/* BANK NUMBER */}
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('bankNumber', { className: 'ml-1 profile-input-95-view-width' })}
          </ListItem>

          {/* BANK NAME */}
          {/* <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            <TextField
              {...{
                label: t('profile.labels.bankName'),
                onBlur: () => {
                  if (this.__original__.details.bankName !== currentUser.details.bankName) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.bankName = event.target.value; // eslint-disable-line no-param-reassign
                },
                rootProps: { ripple: false },
                className: 'ml-1 profile-input-95-view-width',
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                value: currentUser.details.bankName || '',
              }}
            />
          </ListItem> */}
        </List>

        {/* CLOTHING INFORMATION */}
        <Typography
          style={w100}
          use="headline6"
          tag="h2"
          className="padding-left headline"
        >
          {t('profile.labels.clothing_details')}
        </Typography>

        {/* HAS HELMET */}
        <List
          style={{ display: 'flex' }}
        >
          <Checkbox {...{
            checked: currentUser.details.clothing.hasHelmet,
            className: 'border-on-dark',
            label: t('profile.labels.clothing.has_helmet'),
            onChange: (event) => {
              currentUser.details.clothing.hasHelmet = event.target.checked;
              this.save();
            },
          }}
          />
        </List>

        {/* PICTURE OF THE HELMET */}
        {currentUser.details.clothing.hasHelmet
          ? addAttachedCard(
            'helmetImageUrl', t, currentUser,
            (event, entity) => {
              if ((event == null || event.currentTarget.validity.valid) && this.__original__[entity] !== currentUser[entity]) {
                this.save();
              }
            },
            {
              attribute: 'helmet_image',
              method: 'PUT',
              model: 'User',
              multiple: false,
              path: `/api/users/${currentUser.id}`,
            },
            this.confirmDelete,
            this.openCard,
          ) : null }

        <List>
          <ListItem>
            {/* HELMET SIZE */}
            <Select
              {...{
                className: 'ml-1 mdc-theme--text-primary-on-dark pk-select profile-input-95-view-width',
                label: t('profile.labels.clothing.helmet_size'),
                onBlur: () => {
                  if (this.__original__.details.clothing.helmetSize !== currentUser.details.clothing.helmetSize) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.clothing.helmetSize = event.target.value; // eslint-disable-line no-param-reassign
                },
                options: {
                  XXS: '51-52: XXS', XS: '53-54: XS', S: '55-56: S', M: '57-58: M', L: '59-60: L', XL: '61-62: XL', XXL: '63-64: XXL', XXXL: '65-66: XXXL',
                },
                placeholder: t('common.placeholder_select'),
                rootProps: { ripple: false },
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                value: currentUser.details.clothing.helmetSize || '',
              }}
            />
          </ListItem>
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            <Select
              {...{
                className: 'pk-select mdc-theme--text-primary-on-dark profile-input-45-view-width',
                label: t('profile.labels.clothing.size'),
                onBlur: () => {
                  if (this.__original__.details.clothing.size !== currentUser.details.clothing.size) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.clothing.size = event.target.value; // eslint-disable-line no-param-reassign
                },
                options: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
                placeholder: t('common.placeholder_select'),
                rootProps: { ripple: false },
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                value: currentUser.details.clothing.size || '',
              }}
            />
            <TextField
              style={{ height: 'auto' }}
              {...{
                label: t('profile.labels.clothing.number'),
                className: 'profile-input-45-view-width',
                max: 100,
                min: 30,
                onBlur: () => {
                  if (this.__original__.details.clothing.number !== currentUser.details.clothing.number) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.clothing.number = event.target.value; // eslint-disable-line no-param-reassign
                },
                rootProps: { ripple: false },
                step: 'any',
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                type: 'number',
                value: currentUser.details.clothing.number || '',
              }}
            />
          </ListItem>
          <ListItem
            style={{ marginTop: '1em', height: 'auto' }}
          >
            <TextField
              {...{
                className: 'profile-input-95-view-width',
                label: t('profile.labels.clothing.shoe_size'),
                max: 60,
                min: 30,
                onBlur: () => {
                  if (this.__original__.details.clothing.shoeSize !== currentUser.details.clothing.shoeSize) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.clothing.shoeSize = event.target.value; // eslint-disable-line no-param-reassign
                },
                rootProps: { ripple: false },
                step: 'any',
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                type: 'number',
                value: currentUser.details.clothing.shoeSize || '',
              }}
            />
          </ListItem>
          <Typography
            style={w100}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            {t('cards.details.drivingLicense.name')}
          </Typography>

          <ListItem>
            <Select
              {...{
                className: 'ml-1 mdc-theme--text-primary-on-dark pk-select profile-input-95-view-width',
                label: t('cards.details.drivingLicense.class'),
                onBlur: () => {
                  if (this.__original__.details.cards.drivingLicense.class !== currentUser.details.cards.drivingLicense.class) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.cards.drivingLicense.class = event.target.value; // eslint-disable-line no-param-reassign
                },
                onInput: (event) => {
                  if (this.__original__.details.cards.drivingLicense.class !== event.target.value) {
                    currentUser.details.cards.drivingLicense.class = event.target.value; // eslint-disable-line no-param-reassign
                    this.save();
                  }
                },
                defaultChecked: currentUser.details.cards.drivingLicense.class || '-',
                options: {
                  '-': t('cards.details.drivingLicense.noLicense'), B: 'B', C1: 'C1', C: 'C', D1: 'D1', D: 'D', BE: 'BE', BEC: 'BEC', C1E: 'C1E', CE: 'CE', D1E: 'D1E', DE: 'DE',
                },
                rootProps: { ripple: false },
                value: currentUser.details.cards.drivingLicense.class || '-',
              }}
            />
          </ListItem>
        </List>
      </form>
    );
  }

  renderValidities() {
    const { t, uiStore: { currentUser } } = this.props;

    return (
      <div
        className="profile__details theme-dark custom-validities"
        style={{
          ...appcontentsize,
          marginBottom: '1em',
          width: '25wv',
        }}
      >
        {/* <Typography
          style={w100}
          use="headline6"
          tag="h2"
          className="padding-left headline"
        >
          Kansalliset voimassaolot
        </Typography> */}

        {
          mandatoryCardTypes.map((cardType) => addCollapsibleCard(
            currentUser.accountId, t, cardType, currentUser.details.cards,
            (event, entity, attr) => {
              if ((event == null || event.currentTarget.validity.valid) && this.__original__.details.cards[entity][attr] !== currentUser.details.cards[entity][attr]) {
                this.save();
              }
            },
            (event, entity, attr) => {
              // if (attr === 'validThrough') {
              //   const convertedDate = Validity.convertDate(event.target.value);
              //   console.log('Converted: ', convertedDate);
              //   if (!convertedDate.isValid) {
              //     return false;
              //   } else {

              //   }
              // } else
              if (currentUser.details.cards[entity][attr] !== event.target.value) {
                currentUser.details.cards[entity][attr] = event.target.value;
                return true;
              }

              return false;
            },
            this.openCard,
            this.confirmDelete,
          ))
        }

        <Typography
          style={{ ...w100, marginTop: '30px' }}
          use="headline6"
          tag="h2"
          className="padding-left headline"
        >
          Muut voimassaolot
        </Typography>

        {/* {
          // currentUser.validities.map((validity) => addCollapsibleValidity(t, validity))
          currentUser.validities.map((validity) => <CollapsibleValidity validity={validity} />)
        } */}
        <UserValidities openCard={this.openCard} />
      </div>
    );
  }

  renderConfirmDeleteDialog() {
    const { t } = this.props;
    const { showDeleteDialog, deletingFile } = this.state;

    return (
      <Dialog
        className="dialog-background"
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.deleteFile();
          }
          this.setState({ showDeleteDialog: false });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{t(`profile.dialog.deleteBody_${deletingFile}`, t('profile.dialog.deleteBody_validity'))}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            className="accept-button"
            style={{
              borderRadius: '0px',
            }}
            action="accept"
          >
            Kyllä
          </DialogButton>
          <DialogButton
            style={{
              color: '#c7c9cc',
              border: '1px solid #c7c9cc',
              borderRadius: '0px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { activeTab, deletingFile, showDeleteDialog } = this.state;
    const { t } = this.props;
    return (
      <>
        {/* <SimpleDialog
          title={t('profile.dialog.deleteTitle')}
          body={t(`profile.dialog.deleteBody_${deletingFile}`)}
          acceptLabel={t('profile.dialog.deleteButtonAccept')}
          cancelLabel={t('profile.dialog.deleteButtonCancel')}
          open={showDeleteDialog}
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              this.deleteFile();
            }
            this.setState({ showDeleteDialog: false });
          }}
        /> */}

        {/* {this.renderConfirmDeleteDialog()} */}
        <ConfirmDeleteDialog
          open={showDeleteDialog}
          onConfirm={this.deleteFile}
          onClose={this.closeConfirmDeleteDialog}
          message={t(`profile.dialog.deleteBody_${deletingFile}`, t('profile.dialog.deleteBody_validity'))}
        />

        {this.renderCardDialog()}

        <TabBar
          activeTabIndex={activeTab}
          onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
          style={{
            ...appcontentsize,
            marginBottom: '1em',
            width: '25wv',
          }}
        >
          <Tab icon="fact_check">Voimassaolot</Tab>
          <Tab icon="account_circle">Tiedot</Tab>
        </TabBar>

        {activeTab === 0 && this.renderValidities()}
        {activeTab === 1 && this.renderPersonalInfo()}
      </>
    );
  }
}

export default Profile;
