import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import EmployeeCalendar from './EmployeeCalendar';

@inject('uiStore')
@observer
class Calendar extends Component {
  render() {
    const { uiStore: { currentUser: { workOrders } } } = this.props;
    return (
      <EmployeeCalendar workOrders={workOrders} />
    );
  }
}

export default Calendar;
