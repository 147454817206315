/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';
import {
  SimpleListItem,
  ListItem,
  CollapsibleList,
  ListItemMeta,
} from '@rmwc/list';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { TextField } from '@rmwc/textfield';
import { IconButton } from '@rmwc/icon-button';
import { Document, Page } from 'react-pdf';

import { Typography } from '@rmwc/typography';
import { DirectUploadProvider } from 'react-activestorage-provider';
import {
  Card,
  CardPrimaryAction,
  CardMedia,
  CardMediaContent,
} from '@rmwc/card';
import { Icon } from '@rmwc/icon';

import { buttonColors } from '../../calendar/EmployeeCalendar';
import { handleProgress } from '../../models/user/user-ui-avatar';
import ValidityStore from '../../stores/ValidityStore';
import Validity from '../../models/Validity';
import { isEqual } from 'lodash';

import noCard from '../../assets/images/pixel.png';
// import SearchableDropdown from '../../shared/SearchableDropdown';
import moment from '../../utils/moment';
import truncate from '../../utils/truncate';
import './userValidities.css';
import ConfirmDeleteDialog from '../../shared/ConfirmDeleteDialog';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const nameValidation = (name) => {
  if (!name) {
    return 'Voimassaolon nimi on pakollinen';
  }
  return null;
};

const validThroughDateValidation = (validThroughDate) => {
  if (!validThroughDate) {
    return 'Voimassaolo on pakollinen';
  }
  return null;
};

const validate = {
  name: (name) => nameValidation(name),
  validThroughDate: (validThroughDate) => validThroughDateValidation(validThroughDate),
};

@inject('validityStore', 'uiStore', 't')
@observer
class CollapsibleValidity extends Component {
  constructor(props) {
    super(props);
    // An old failed attempt to set textarea height to match the content on mount
    // this.textareaRef = null;

    // this.setTextareaRef = element => {
    //   this.textareaRef = element;
    // };

    this.state = {
      validity: props.validity,
      errors: {},
      showDeleteFileDialog: false,
    };
  }

  // We need this to re-render the component when we delete the file
  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.validity, prevProps.validity)) {
      this.setState({ validity: this.props.validity });
    }
  }

  // An old failed attempt to set textarea height to match the content on mount
  // componentDidMount() {
  //   if (this.textareaRef) {
  //     console.log('textareaRef: ', this.textareaRef);
  //     console.log('textareaRef scrollheight: ', this.textareaRef.scrollHeight);

  //     this.textareaRef.style.height = '1px';
  //     this.textareaRef.style.height = `${this.textareaRef.scrollHeight}px`;
  //   };
  // }

  textareaResize(evt) {
    // Need to reset the height to 1px since the minimum scrollHeight is the element height,
    // meaning we need to reset the height to recalculate the scrollHeight or it won't stop growing
    evt.target.style.height = '1px';
    evt.target.style.height = `${evt.target.scrollHeight}px`;
  }

  handleValidate() {
    const { validity } = this.state;
    let errors = {};

    Object.keys(validate).forEach((key) => {
      const error = validate[key](validity[key]);
      if (error) {
        errors = { ...errors, [key]: error };
      }
    });

    // this.setState({
    //   errors: { ...errors },
    // });
    return errors;
  }

  save() {
    const { validityStore } = this.props;
    const { validity } = this.state;

    const tempValidity = new Validity({...validity});
    const errors = this.handleValidate();

    // NOTE: This error handling is different due to the date conversions, compare to handleValidate above
    if (tempValidity.admittanceDate) {
      const convertedAdmittanceDate = Validity.convertDate(tempValidity.admittanceDate);
      // Cancel saving if the date is invalid
      if (!convertedAdmittanceDate.isValid) {
        errors['admittanceDate'] = 'Invalid';
      } else {
        tempValidity.changeAttribute('admittanceDate', convertedAdmittanceDate.dateObject);
        tempValidity.changeAttribute('admittanceDateFormat', convertedAdmittanceDate.formatIndex);
      }
    }

    if (tempValidity.validThroughDate) {
      const convertedValidThroughDate = Validity.convertDate(tempValidity.validThroughDate);
      // Cancel saving if the date is invalid
      if (!convertedValidThroughDate.isValid) {
        errors['validThroughDate'] = 'Invalid';
      } else {
        tempValidity.changeAttribute('validThroughDate', convertedValidThroughDate.dateObject);
        tempValidity.changeAttribute('validThroughDateFormat', convertedValidThroughDate.formatIndex);
      }
    }

    if (Object.keys(errors).length > 0) {
      // Set new errors and cancel saving
      this.setState({ errors: errors });
      return false;
    } else {
      // No errors, reset old errors and continue saving
      this.setState({ errors: {} });
    }

    const updatePromise = fromPromise(new Promise((resolve, reject) => validityStore.updateValidity(tempValidity, resolve, reject)));
    when(
      () => updatePromise.state !== "pending",
      () => {
        updatePromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.log('Tallennus epäonnistui', err);
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (newValidity) => {
            console.log('Tallennus onnistui: ', newValidity);
            this.setState({ validity: newValidity });
          }
        });
      }
    )
  }

  doOnChange(event, attr) {
    const { validity } = this.state;

    this.setState({ validity: { ...validity, [attr]: event.target.value } });
  }

  // updateAssociationById = (attr, item) => {
  //   const { validity } = this.state;
  //   this.setState({ validity: { ...validity, [attr]: item } }, () => this.save());
  // }

  deleteValidityFileBySignedId(validity, fileURL) {
    const { validityStore } = this.props;
    // validityStore.purgeFile(validity, fileURL);
    const purgePromise = fromPromise(new Promise((resolve, reject) => validityStore.purgeFile(validity, fileURL, resolve, reject)));
    when(
      () => purgePromise.state !== 'pending',
      () => {
        purgePromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.log('Poistaminen epäonnistui', err);
            // this.setState({
            //   openSnackbar: true,
            //   saveStatusMessage: 'Tallennus epäonnistui'
            // }, () => this.updateEmployeeList())
          },
          fulfilled: (newValidity) => {
            console.log('Poistaminen onnistui');
          },
        });
      },
    );
  }

  addTextField(attr, label, title, invalidText, required, className, textarea = false, extraOnChange = null) {
    const { disabled } = this.props;
    const { validity, errors } = this.state;
    // An old failed attempt to set textarea height to match the content on mount
    // const textareaRef = React.createRef();

    // Old pattern parameter (mm/yyyy): '^((0[1-9]|1[0-2]))/([2][0-9]{3})$'
    return (
      <TextField
        {...{
          required,
          // className: 'profile-input-95-view-width left-aligned-helptext',
          className: textarea ? 'textarea-hack profile-input-95-view-width left-aligned-helptext' + ` ${className}` : 'profile-input-95-view-width left-aligned-helptext' + ` ${className}`,
          label,
          textarea,
          maxLength: textarea ? 50 : 100,
          onBlur: (event) => this.save(event.target.value),
          onChange: (event) => {
            this.doOnChange(event, attr);
            if (extraOnChange) {
              extraOnChange(event);
            }
          },
          title,
          // pattern,
          invalid: errors[attr],
          helpText: errors[attr] && invalidText && {
            persistent: true,
            validationMsg: true,
            children: invalidText,
          },
          rootProps: { ripple: false },
          theme: ['textPrimaryOnDark', 'secondaryBg'],
          type: 'text',
          value: validity[attr] || '',
          // We remove a margin-bottom 1px that creates a gap between the input's lower edge and the highlight border
          style: { marginBottom: '0', whiteSpace: textarea ? 'pre' : '' },
          onKeyPress: handleEnter,
          // An old failed attempt to set textarea height to match the content on mount
          // ref: this.setTextareaRef,
          disabled,
        }}
      />
    );
  }

  addCardImage(inputRef, openCard, onBlur, onChange) {
    const { t, disabled } = this.props;
    const { validity } = this.state;
    let fileName;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"
        multiple={false}
        onSuccess={(newData) => {
          if (Array.isArray(newData)) {
            const [value] = newData;
            // Signed id
            validity.file = value;
            validity.fileURL = `/rails/active_storage/blobs/${value}/${fileName}`.split(' ').join('_');
            this.save();
          }
        }}
        render={({ handleUpload, uploads, ready }) => {
          const imageURL = validity.fileURL;
          return (
            <>
              <input
                className="inputfile"
                disabled={disabled || !ready}
                id={`validity-${validity.id}-file-uploader`}
                multiple={false}
                onChange={(e) => {
                  if (e.currentTarget.files) {
                    [{ name: fileName }] = e.currentTarget.files;
                    handleUpload(e.currentTarget.files);
                  }
                }}
                type="file"
                ref={inputRef}
              />

              {validity.fileURL && !disabled && (
                <div style={{ width: '100%', textAlign: 'end', position: 'absolute', zIndex: '1', top: '35px', right: '5px' }}>
                  <IconButton
                    type="button"
                    style={{
                      color: 'white',
                      backgroundColor: 'rgb(128 128 128 / 75%)',
                      borderRadius: '30px',
                    }}
                    icon="delete_outlined"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({ showDeleteFileDialog: true });
                    }}
                  />
                </div>
              )}

              {imageURL && imageURL.endsWith('.pdf') ? (
                <CardMedia
                  className="cursor-pointer pdf-margin"
                  disabled={disabled || !ready}
                  onClick={() => {
                    if (imageURL && openCard) {
                      openCard(imageURL, validity.fileName);
                    } else {
                      inputRef.current.click();
                    }
                  }}
                  sixteenByNine
                  style={{
                    backgroundImage: 'none',
                    margin: '5vh 0',
                    width: '100%',
                    padding: '0',
                    borderRadius: 0,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Document
                      file={imageURL}
                      loading="Avataan PDF..."
                      noData="Tiedostoa ei löytynyt"
                      error="Tiedoston avaus epäonnistui"
                    >
                      <Page pageNumber={1} />
                    </Document>
                  </div>
                </CardMedia>
              ) : (
                <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}> {/* This wrapper div with display: flex was made to have the upload progress text go beneath CardMedia instead of side */}
                  <CardMedia
                    className="cursor-pointer"
                    disabled={disabled || !ready}
                    htmlFor={`validity-${validity.id}-file-uploader`}
                    onClick={() => {
                      if (validity.fileURL && openCard) {
                        openCard(validity.fileURL, validity.fileName);
                      } else {
                        inputRef.current.click();
                      }
                    }}
                    sixteenByNine
                    style={{
                      backgroundImage: `url(${validity.fileURL || noCard})`,
                      backgroundPosition: 'center',
                      backgroundSize: 'contain',
                      backgroundRepeat: 'no-repeat',
                      margin: '5vh 0',
                      width: '100%',
                      padding: '0',
                    }}
                  >
                    {validity.fileURL == null && (
                      <CardMediaContent>
                        <Typography
                          use="overline"
                          theme="textPrimaryOnDark"
                          style={{
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                            justifyContent: 'center',
                            right: '0',
                            width: '100%',
                            padding: '0',
                          }}
                        >
                          {t('cards.addValidityImage')}
                        </Typography>
                      </CardMediaContent>
                    )}
                  </CardMedia>
                  {handleProgress(uploads, t)}
                </div>
              )}
            </>
          );
        }}
        required
      />
    );
  };

  confirmDelete(target, id, workOrderId, name) {
    let deleteMessage;

    if (target === 'file') {
      deleteMessage = `Haluatko varmasti poistaa tiedoston ${name}?`
    } else if (target === 'attachment') {
      deleteMessage = `Haluatko varmasti poistaa liitteen ${name}?`
    }

    this.setState({
      showDeleteDialog: true,
      deleteInfo: { target, id, workOrderId, deleteMessage },
    })
  }

  renderDeleteInviteDialog() {
    const { showDeleteDialog, deleteInfo } = this.state;

    return (
      <Dialog
        className='employer-delete-modal'
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.handleDeletion(deleteInfo)
          }
          this.setState({
            showDeleteDialog: false,
            deleteInfo: {},
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{deleteInfo.deleteMessage}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px'
          }}
        >
          <DialogButton
            type="button"
            className="accept-button"
            style={{
              borderRadius: '0px',
            }}
            action='accept'
          >
            Kyllä
              </DialogButton>
          <DialogButton
            type="button"
            style={{
              color: '#645F5F',
              border: '1px solid #c7c9cc',
              borderRadius: '0px',
            }}
            action="close">
            Peruuta
            </DialogButton>
        </DialogActions>
      </Dialog>
    )
  }

  handleDeletion(deleteInfo) {
    const { employerWorkOrderStore, deleteFileFromState, deleteAttachmentFromState } = this.props;

    if (deleteInfo.target === 'file') {
      employerWorkOrderStore.deleteAttachmentFile(deleteInfo.id, deleteInfo.workOrderId)
        .then(() => {
          deleteFileFromState(deleteInfo.id)
          this.setState({
            filename: null,
            fileURL: null,
          })
        });
    } else if (deleteInfo.target === 'attachment') {
      employerWorkOrderStore.deleteWorkOrderAttachment(deleteInfo.id, deleteInfo.workOrderId)
        .then(deleteAttachmentFromState(deleteInfo.id));
    }
  }

  render() {
    const { t, openCard, confirmDelete, disabled } = this.props;
    const { validity, showDeleteFileDialog } = this.state;

    return (
      <CollapsibleList
        // NOTE: Due to a RMWC bug we can't assign a className to CollapsibleList
        key={validity.id}
        handle={(
          <SimpleListItem
            className="collapsible-card-handle mdc-typography--headline6 mdc-card"
            metaIcon="chevron_right"
            style={{ flexDirection: 'row-reverse', margin: '4px 0' }}
          >
            <div style={{ color: 'white', width: '100%' }}>
              <div style={{ color: 'white', width: '100%', display: 'flex' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                  {/* Render validity's name on the first row */}
                  {/* Tried using text-overflow: ellipsis CSS here instead of truncate for better big screen compatibility but couldn't get it to work */}
                  <div className="collapsible-card-handle-title">{truncate(validity.name, 33)}</div>
                  {/* Render validity's dates and calculated duration in months and days on the second row (copies SimpleListItem's secondaryText's styles) */}
                  <div style={{ display: 'flex', paddingRight: '5px', marginTop: '-5px' }}>
                    <span className="mdc-list-item__secondary-text">{Validity.renderValidityDate(validity.validThroughDate)}</span>
                    <span className="mdc-list-item__secondary-text" style={{ marginLeft: '20px' }}>{Validity.renderValidityDuration(validity.validThroughDate)}</span>
                  </div>
                </div>
              </div>
            </div>
            {/* {locked && <Icon icon="lock_outline" style={{ color: buttonColors.orange.color }} />} */}
          </SimpleListItem>
        )}
        onOpen={() => console.log('open')}
        onClose={() => console.log('close')}
      >
        <ListItem style={{ marginTop: '2vh', height: 'auto', paddingTop: '10px' }}>
          <div className="input-with-delete-button-wrapper">
            { this.addTextField('name', 'Nimi', 'Syötä voimassaolon nimi', null, true, 'input-with-delete-button', true, this.textareaResize) }
            {!disabled && (
              <Icon
                icon="delete_outline"
                style={{
                  fontSize: '25px',
                  width: '35px',
                  height: '25px',
                  // margin: 'auto 0',
                  marginTop: '24px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  confirmDelete(validity);
                }}
                role="button"
              />
            )}
          </div>
        </ListItem>

        {/* Display flex column is needed here to position the helpText correctly, because apparently RMWC doesn't provide a wrapper for input + helpText */}
        {/* Compare to GridCell use in e.g. NewCloseCallReportDialog */}
        <ListItem style={{ marginTop: '2vh', height: 'auto', display: 'flex', flexDirection: 'column' }}>
           { this.addTextField('validThroughDate', `${t('cards.validThrough')}`, 'Syötä voimassaolo muodossa kk/vvvv', 'Syötä päivämäärä muodossa pv/kk/vvvv, kk/vvvv, kk/vv tai pv.kk.vvvv', true) }
        </ListItem>

        <ListItem style={{ marginTop: '2vh', height: 'auto' }}>
          { this.addTextField('number', 'Numero', 'Syötä voimassaolon numero', null) }
        </ListItem>

        {/* Display flex column is needed here to position the helpText correctly, because apparently RMWC doesn't provide a wrapper for input + helpText */}
        {/* Compare to GridCell use in e.g. NewCloseCallReportDialog */}
        <ListItem style={{ marginTop: '2vh', height: 'auto', display: 'flex', flexDirection: 'column' }}>
          { this.addTextField('admittanceDate', `Suorittamisaika`, 'Syötä suorittamisaika muodossa kk/vvvv', 'Syötä päivämäärä muodossa pv/kk/vvvv, kk/vvvv, kk/vv tai pv.kk.vvvv') }
        </ListItem>

        <ListItem style={{ height: 'auto' }} className="collapsible-card-image-wrapper">
          { this.addCardImage(React.createRef(), openCard,
            // (event) => {
            //   if (event == null || event.currentTarget.validity.valid) {
            //     console.log('SAVING');
            //     this.save();
            //   }
            // },
            // (event) => {
            //   validity.imageURL = event.target.value; // eslint-disable-line no-param-reassign
            // },
          ) }
        </ListItem>

        <ConfirmDeleteDialog
          onConfirm={() => this.deleteValidityFileBySignedId(validity, validity.fileURL)}
          onClose={() => this.setState({ showDeleteFileDialog: false })}
          open={showDeleteFileDialog}
          message={t('profile.dialog.deleteBody_picture')}
        />

        {/* <SearchableDropdown selectedItem={validity.company} attr="company" label="Yritys" items={validityTargets.companies} updateAttr={this.updateAssociationById} handlePlaceholder="Valitse yritys" searchPlaceholder="Hae yritystä nimellä" />
        <SearchableDropdown selectedItem={validity.workOrder} attr="workOrder" label="Keikka" items={validityTargets.workOrders} updateAttr={this.updateAssociationById} handlePlaceholder="Valitse keikka" searchPlaceholder="Hae keikkaa nimellä" />
        <SearchableDropdown selectedItem={validity.location} attr="location" label="Kohde" items={validityTargets.locations} updateAttr={this.updateAssociationById} handlePlaceholder="Valitse kohde" searchPlaceholder="Hae kohdetta nimellä" /> */}
      </CollapsibleList>
    );
  }
}

export default CollapsibleValidity;
