import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
// import { Button } from '@material-ui/core';
import { IconButton } from '@rmwc/icon-button';
// import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { Select } from '@rmwc/select';
// import { Typography } from '@rmwc/typography';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import MomentLocaleUtils, {
//   formatDate,
//   parseDate,
// } from 'react-day-picker/moment';
import 'moment/locale/fi';
// import { isEqual } from 'lodash';

// import AddIcon from '@material-ui/icons/Add';

import { isEqual, cloneDeep } from 'lodash';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/typography/dist/mdc.typography.css';

import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';

import moment from '../utils/moment';
import CustomTimeField from '../shared/CustomTimeField';
// import CalendarEntry from '../models/CalendarEntry';
// import AbsenceEntry from '../models/AbsenceEntry';
// import AbsencePeriod from '../models/AbsencePeriod';

import './absence-period-dialog.css';
import LoadingSpinner from '../shared/LoadingSpinner';

// import EmployeeCalendar from '../calendar/EmployeeCalendar';

// import RouteLocation from './RouteLocation';
// import TripRoute from './TripRoute';
// import AutocompletableLocation from '../shared/AutocompletableLocation';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('absenceStore', 't', 'uiStore')
@observer
class AbsenceEntryDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // routeAddresses: [],
      showDeleteDialog: false,
      absencePeriod: { absenceEntries: [] },
      saving: false,
      errors: {},
      // allCalendarEntries: { workingCurrentPeriod: [] },
    };
  }

  componentDidUpdate(prevProps) {
    const { absenceEntry } = this.props;
    const { absenceEntry: prevAbsenceEntry } = prevProps;

    if (!isEqual(absenceEntry, prevAbsenceEntry)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        // We don't want to update the prop directly
        absenceEntry: cloneDeep(absenceEntry),
        inputChanged: false,
        errors: {},
        saving: false,
      });
    }
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      saving: false,
      absencePeriod: { absenceEntries: [] },
      // allCalendarEntries: { workingCurrentPeriod: [] },
    }, () => {
      onClose();
    });
  }

  startSave() {
    // const { absencePeriod } = this.state;
    this.setState({
      saving: true,
    }, () => {
      // if (absencePeriod.id) {
      //   this.update();
      // } else {
      //   this.save();
      // }
      this.save();
    });
  }

  save() {
    const { absenceStore, employerMode, employerUpdateCallback } = this.props;
    const { absenceEntry } = this.state; // selectedAbsenceDays, toBeDeletedAbsenceDays
    const updatedAbsenceEntry = cloneDeep(absenceEntry);

    if (!employerMode) {
      // Set the status as draft in the employee edit (note: backend also sets the status as draft if non-employer triggers the update)
      updatedAbsenceEntry.status = 'draft';
    }

    // if (updatedAbsencePeriod.id) {
    if (employerMode) {
      absenceStore.updateAbsenceEntryEmployer(updatedAbsenceEntry).then((res) => {
        // Update success
        if (employerUpdateCallback) {
          employerUpdateCallback(res);
        }
        this.onClose();
      }).catch((err) => {
        // Failure
        console.error(err);
        this.setState({
          saving: false,
        });
      });
    } else {
      absenceStore.updateAbsenceEntry(updatedAbsenceEntry).then(() => {
        // Update success
        this.onClose();
      }).catch((err) => {
        // Failure
        console.error(err);
        this.setState({
          saving: false,
        });
      });
    }

    // }
    // else if (employerMode) {
    //   absenceStore.employerCreateAbsencePeriod(updatedAbsencePeriod).then((res) => {
    //     // Create success
    //     updateAbsencePeriodState(res);
    //     this.onClose();
    //   }).catch((err) => {
    //     // Failure
    //     console.error(err);
    //     this.setState({
    //       saving: false,
    //     });
    //   });
    // } else {
    //   absenceStore.createAbsencePeriod(updatedAbsencePeriod).then((res) => {
    //     // Create success
    //     if (employerMode && updateAbsencePeriodState) {
    //       updateAbsencePeriodState(res);
    //     }
    //     this.onClose();
    //   }).catch((err) => {
    //     // Failure
    //     console.error(err);
    //     this.setState({
    //       saving: false,
    //     });
    //   });
    // }
  }

  doOnChange(attr, value) {
    if (attr.includes('values.')) {
      // Handle nested values (jsonb column)
      const { absenceEntry: { values } } = this.state;
      const valueName = attr.split('.')[1];
      const updatedValues = [...values];
      const foundValueIndex = updatedValues.findIndex((oldValue) => oldValue.name === valueName);
      if (foundValueIndex !== -1) {
        updatedValues[foundValueIndex].value = value;
      } else {
        updatedValues.push({
          name: valueName,
          value,
        });
      }

      this.setState((prevState) => ({
        absenceEntry: {
          ...prevState.absenceEntry,
          values: updatedValues,
        },
        inputChanged: true,
      }));
    } else {
      // let defaultValues;
      // if (attr === 'absenceType') {
      //   defaultValues = value.valueTemplates.map((template) => ({ name: template.name, value: template.default_value }));
      // }
      // Handle absencePeriod's own info
      this.setState((prevState) => ({
        absenceEntry: {
          ...prevState.absenceEntry,
          // values: defaultValues || prevState.absencePeriod.defaultValues,
          [attr]: value,
        },
        inputChanged: true,
      }));
    }
  }

  updateHours(attr, value) {
    const { absenceEntry } = this.state;
    const hours = value.split(':')[0];
    const minutes = value.split(':')[1];

    if (attr === 'fromTime') {
      absenceEntry.calendarEntry.from.set({ hour: hours, minute: minutes, seconds: 0 });
    } else if (attr === 'toTime') {
      absenceEntry.calendarEntry.to.set({ hour: hours, minute: minutes, seconds: 0 });
    }
  }

  addHourField(attr, label) {
    const { absenceEntry } = this.state;
    const { disabled } = this.props;

    let valueFormatted;
    if (attr === 'fromTime') {
      valueFormatted = absenceEntry.calendarEntry.from.format('HH:mm');
    } else if (attr === 'toTime') {
      valueFormatted = absenceEntry.calendarEntry.to.format('HH:mm');
    }
    // const valueFormatted = absenceEntry.calendarEntry.

    return (

      <div
        className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
        style={{
          // height: '44px',
          // width: '45px',
          // margin: 0,
          // padding: 0,
          width: '100%',
          backgroundColor: 'var(--mdc-theme-secondary)',
          color: 'white',
          flexDirection: 'column',
        }}
      >
        <div style={{ fontSize: '12px', paddingLeft: '5px' }}>
          {label}
        </div>
        <CustomTimeField
          // eslint-disable-next-line react/destructuring-assignment
          value={valueFormatted || '--:--'}
          attr={attr}
          doOnChange={(event, value) => {
            // this.doOnChange(attr, value, event);
            // this.doOnChange(attr, value);
            this.updateHours(attr, value);
          }}
          doOnBlur={() => {
            if (absenceEntry?.calendarEntry.from && absenceEntry?.calendarEntry.to) {
              const start = absenceEntry.calendarEntry.from;
              const end = absenceEntry.calendarEntry.to;
              const duration = moment.duration(end.diff(start)).asHours();

              this.doOnChange('values.duration', duration);
            }
          }}
          handleEnter={handleEnter}
          disabled={disabled}
        />
      </div>
    );
  }

  addDescriptionField(attr, label, styles = null, className = null, textarea = true) {
    const { absenceEntry } = this.state;
    const { disabled } = this.props;

    return (
      <TextField {...{
        style: { ...styles },
        label,
        // required: true,
        disabled,
        // onBlur: (event) => {
        //   this.doOnBlur(attr, event.target.value, event, null, 'tripRoute');
        // },
        onChange: (event) => {
          const { value } = event.target;
          // this.doOnChangeRoute(attr, value, event, routeIndex);
          this.doOnChange(attr, value);
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        // onKeyPress: handleEnter,
        className,
        value: absenceEntry[attr] != null ? absenceEntry[attr] : '',
        textarea,
      }}
      />
    );
  }

  // addNumberField(attr, label, required, styles = null) {
  //   const { absencePeriod, errors } = this.state;
  //   const value = attr.includes('values.') ? absencePeriod.values[attr] : absencePeriod[attr];

  //   return (
  //     <TextField {...{
  //       style: { ...styles },
  //       // className: 'employer-work-order',
  //       label,
  //       required,
  //       name: attr,
  //       invalid: errors[attr],
  //       helpText: errors[attr] && errors[attr],
  //       type: 'number',
  //       onChange: (event) => {
  //         const { value: newValue } = event.target;
  //         delete errors[attr];
  //         this.doOnChange(attr, newValue);
  //       },
  //       // onBlur: (event) => {
  //       //   required && this.handleBlur(attr, event)
  //       // },
  //       rootProps: { ripple: false },
  //       onKeyPress: handleEnter,
  //       value: value != null ? value : '',
  //     }}
  //     />
  //   );
  // }

  addDecimalField(attr, label, styles = null, className = null) {
    const { absenceEntry, errors } = this.state;

    // This absencePeriod.values makes no sense
    // AbsencePeriod has absenceEntries but no values in itself
    // It should be the type default values, if anything

    let value;
    if (attr.includes('values.')) {
      const valueName = attr.split('.')[1];
      value = absenceEntry.values.find((oldValue) => oldValue.name === valueName)?.value || '';
    } else {
      value = absenceEntry[attr] || '';
    }

    const handleKeyDown = (event) => {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);

      // Check if the pressed key is a letter and prevent its input
      if (/[a-zA-Z]/.test(keyValue)) {
        event.preventDefault();
      }
    };

    return (
      <TextField {...{
        style: { ...styles },
        label,
        type: 'text',
        autoFocus: true,
        // required: true,
        // disabled,
        pattern: '[0-9.,]*',
        // onBlur: (event) => {
        //   // this.doOnBlur(attr, event.target.value, event);
        //   this.doOnBlur(attr, event.target.value, event, routeIndex, null, 'tripRoute');
        // },
        onChange: (event) => {
          const { value: newValue } = event.target;
          delete errors[attr];
          this.doOnChange(attr, newValue);
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        onKeyPress: handleEnter,
        onKeyDown: handleKeyDown,
        className,
        value: (absenceEntry && !Number.isNaN(value)) ? value : '',
      }}
      />
    );
  }

  handleChangeForDate(attr, value) {
    this.setState((prevState) => ({
      absencePeriod: {
        ...prevState.absencePeriod,
        [attr]: moment(value),
      },
    }));
  }

  // addDatePicker(attr, label) {
  //   const { absencePeriod } = this.state;

  //   return (
  //     <DayPickerInput
  //       component={
  //         (props) => (
  //           <TextField
  //             {...props}
  //             {...{
  //               label,
  //               theme: ['textPrimaryOnLight', 'secondaryBg'],
  //               placeholder: '',
  //               style: { width: '100%' },
  //             }}
  //           />
  //         )
  //       }
  //       inputProps={{
  //         ref: null,
  //         className: '',
  //         // style: { padding: '0 15px 0 15px' },
  //         readOnly: true,
  //         // disabled,
  //       }}
  //       dayPickerProps={{
  //         locale: 'fi',
  //         localeUtils: MomentLocaleUtils,
  //       }}
  //       formatDate={formatDate}
  //       parseDate={parseDate}
  //       keepFocus={false}
  //       format="dd DD.MM.YYYY"
  //       // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
  //       onDayChange={(value) => this.handleChangeForDate(attr, value)}
  //       value={absencePeriod[attr] ? moment(absencePeriod[attr], 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
  //       classNames={{
  //         container: '',
  //         overlayWrapper: 'DayPickerInput-OverlayWrapper',
  //         overlay: 'DayPickerInput-Overlay',
  //       }}
  //     />
  //   );
  // }

  renderUserSelect() {
    const { usersWithAbsencesEnabled } = this.props;
    const { absencePeriod: { userId } } = this.state;

    return (
      // Note: check hourly-timelog-form.js renderSelect() for Material UI conversion attempt
      <Select {...{
        disabled: true,
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        className: 'white-text-rmwc-select',
        style: {
          backgroundColor: '#2d323e',
          // borderBottom: '2px solid #4A515C',
          // borderRadius: 0,
          textOverflow: 'ellipsis',
          padding: '0 35px 0 20px',
          marginBottom: '15px',
        },
        rootProps: { style: { width: '100%', backgroundColor: 'transparent' } },
        placeholder: 'Valitse työntekijä *',
        value: userId,
        onChange: (evt) => {
          this.doOnChange('userId', evt.target.value);
        },
      }}
      >
        {usersWithAbsencesEnabled?.map((user) => (
          <option
            key={user.id}
            value={user.id}
          >
            {user.name}
          </option>
        ))}
      </Select>
    );
  }

  renderAbsenceTypeSelect() {
    const { uiStore: { currentUser: { absenceTypes } } } = this.props;
    const { absencePeriod: { absenceType } } = this.state;

    return (
      // Note: check hourly-timelog-form.js renderSelect() for Material UI conversion attempt
      <Select {...{
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        className: 'white-text-rmwc-select',
        // label: 'Valitse työ',
        style: {
          backgroundColor: '#2d323e',
          // borderBottom: '2px solid #4A515C',
          // borderRadius: 0,
          textOverflow: 'ellipsis',
          padding: '0 35px 0 20px',
          marginBottom: '5px',
        },
        rootProps: { style: { width: '100%', backgroundColor: 'transparent' } },
        placeholder: 'Valitse poissaololaji *',
        value: absenceType?.id,
        onChange: (evt) => {
          const foundAbsenceType = absenceTypes.find((type) => type.id === Number(evt.target.value));
          this.doOnChange('absenceType', foundAbsenceType);
          this.setDefaultAbsenceEntryValues(foundAbsenceType);
        },
      }}
      >
        {absenceTypes?.map((optionAbsenceType) => (
          <option
            key={optionAbsenceType.id}
            value={optionAbsenceType.id}
          >
            {optionAbsenceType.nickname}
          </option>
        ))}
      </Select>
    );
  }

  renderValueTemplateInput(valueTemplate) {
    const { t } = this.props;

    const attr = `values.${valueTemplate.name}`;
    const label = valueTemplate.unit ? `${t(`absence_types.${valueTemplate.name}`, valueTemplate.name)} (${valueTemplate.unit})` : t(`absence_types.${valueTemplate.name}`, valueTemplate.name);

    if (valueTemplate.data_type === 'number') {
      return this.addDecimalField(attr, label, { width: '100%' });
    }
    if (valueTemplate.data_type === 'string') {
      return this.addDescriptionField(attr, label);
    }
    if (valueTemplate.data_type === 'boolean') {
      return null;
    }
    return null;
  }

  renderValueTemplateInputs() {
    const { absencePeriod } = this.state;
    if (absencePeriod && absencePeriod?.absenceType.valueTemplates) {
      const inputs = [];
      absencePeriod.absenceType.valueTemplates.forEach((valueTemplate) => {
        inputs.push(
          <GridCell span={valueTemplate.grid_span || 12}>
            {this.renderValueTemplateInput(valueTemplate)}
          </GridCell>,
        );
      });

      return inputs;
    }

    return null;
  }

  render() {
    const {
      open,
      employerUpdateMode,
      handleDeleteAbsenceEntry,
      employerMode,
    } = this.props;
    const {
      showDeleteDialog,
      deleteMessage,
      // absencePeriod,
      absenceEntry,
      saving,
      // allCalendarEntries,
      inputChanged,
    } = this.state;

    return (
      <>
        <Dialog
          className={employerUpdateMode ? 'employer-trip-route-dialog trip-route-dialg' : 'trip-route-dialog'}
          open={open}
          preventOutsideDismiss
        // onClose={() => this.onClose()}
        >
          <DialogTitle
            style={{
              color: 'var(--mdc-theme-primary)',
              padding: '5px 10px 0px',
              marginTop: '5px',
              display: 'flex',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <span style={{ flex: 1, margin: 'auto 0' }}>
              {absenceEntry?.id ? `Muokkaa poissaoloa ${absenceEntry.calendarEntry.from.format('dd DD.MM.YY')}` : 'Uusi poissaolo'}
            </span>
            {/* hack to remove autofocus on trash can */}
            <input style={{ width: '1px', height: '0px', opacity: '0' }} />
            {absenceEntry?.id && (
              <IconButton
                icon="delete_outlined"
                onClick={() => this.setState({
                  showDeleteDialog: true,
                  deleteMessage: `Haluatko varmasti poistaa poissaolon päivältä ${absenceEntry?.calendarEntry.from.format('dd DD.MM.YY')}?`,
                })}
                style={{ padding: 0, alignSelf: 'end', color: '#c7c9cc' }}
              />
            )}
          </DialogTitle>
          <DialogContent
            style={{ color: 'white' }}
          >
            {/* {this.renderDescription()} */}
            {/* <h3>Lisää kilometrimäärä, selite ja reitti {t(`timelog.attributes.${kmsType}`)}</h3> */}
            {/* {this.renderRoutes()} */}

            <Grid
              // key={routeIndex}
              className="route-grid"
            >
              <GridInner>
                <GridCell span={12}>
                  <GridInner
                    style={{ rowGap: '0px' }}
                  >
                    <>
                      <>
                        {employerMode && (
                          <GridCell span={12}>
                            {/* {this.renderUserSelect()} */}
                            {/* eslint-disable-next-line camelcase */}
                            <div>{absenceEntry?.user.full_name}</div>
                          </GridCell>
                        )}

                        {!absenceEntry?.id ? (
                          <GridCell span={12} style={{ marginBottom: '5px' }}>
                            {this.renderAbsenceTypeSelect()}
                          </GridCell>
                        ) : (
                          <GridCell span={12}>
                            <div>{absenceEntry?.absenceType.officialName}</div>
                          </GridCell>
                        )}
                        {absenceEntry?.absenceType && (
                          <>
                            {absenceEntry?.absenceType.hasTimes && (
                              <GridCell span={12}>
                                <GridInner style={{ rowGap: 0 }}>
                                  <GridCell
                                    desktop={4}
                                    tablet={3}
                                    phone={2}
                                  >
                                    {this.addHourField('fromTime', 'Alkamisajankohta')}
                                  </GridCell>

                                  <GridCell
                                    desktop={4}
                                    tablet={3}
                                    phone={2}
                                  >
                                    {this.addHourField('toTime', 'Päättymisajankohta')}
                                  </GridCell>

                                  <GridCell
                                    desktop={4}
                                    tablet={2}
                                    phone={4}
                                  >
                                    {this.renderValueTemplateInput({
                                      data_type: 'number',
                                      name: 'duration',
                                      unit: 'h',
                                    })}
                                  </GridCell>
                                </GridInner>
                              </GridCell>
                            )}

                            <GridCell span={12}>
                              {this.addDescriptionField('description', 'Lisätietoa', { width: '100%', marginBottom: 0, color: 'white' }, null, false)}
                            </GridCell>
                          </>
                        )}
                      </>
                      {/* )} */}
                    </>
                  </GridInner>
                </GridCell>
              </GridInner>
            </Grid>

          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <DialogButton
              className="accept-button"
              style={{
                borderRadius: '0px',
              }}
              onClick={() => {
                this.startSave();
                // this.cleanUp();
              }}
              // disabled={!absencePeriod.fromDate || !absencePeriod.toDate || !absencePeriod.absenceType || saving}
              disabled={saving || !inputChanged}
            // action="close"
            >
              {saving ? <LoadingSpinner color="black" size="mini" /> : 'Tallenna'}
            </DialogButton>

            <DialogButton
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
              }}
              // action="cancel"
              onClick={() => {
                // this.cleanUp();
                // toggleRouteDialog();
                this.onClose();
              }}
            >
              {inputChanged ? 'Peruuta' : 'Sulje'}
            </DialogButton>
          </DialogActions>
        </Dialog>

        {showDeleteDialog && (
          <Dialog
            className="dialog-background mini-dialog employer-trip-route-dialog"
            open={showDeleteDialog}
            preventOutsideDismiss
          >
            <DialogContent
              style={{ color: 'white' }}
            >
              <p>{deleteMessage}</p>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                padding: '15px',
              }}
            >
              <DialogButton
                className="accept-button"
                style={{
                  borderRadius: '0px',
                }}
                // action="accept"
                onClick={() => {
                  handleDeleteAbsenceEntry(absenceEntry);
                  this.setState({
                    showDeleteDialog: false,
                    deleteMessage: '',
                  });
                }}
              >
                Kyllä
              </DialogButton>
              <DialogButton
                style={{
                  color: '#c7c9cc',
                  border: '1px solid #c7c9cc',
                  borderRadius: '0px',
                }}
                // action="close"
                onClick={() => {
                  this.setState({
                    showDeleteDialog: false,
                    deleteMessage: '',
                  });
                }}
              >
                Peruuta
              </DialogButton>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default AbsenceEntryDialog;
