import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import Locations from './Locations';
import Purchasers from './Purchasers';
import './locations-purchasers.css';

@inject('uiStore', 'loginStore', 't')
@observer
class LocationsPurchasers extends Component {
  constructor(props) {
    super(props);
    // const { locationStore, uiStore: { currentUser } } = this.props;

    this.state = {
      activeTabIndex: 0,

      // Normally array, initializing as null here so that we can tell if the db query is complete or not
      // currentWorkOrdersWithMeta: null,
      // filterCount: 0,
      // offset: 0,
    };
  }

  selectTab(index) {
    this.setState({ activeTabIndex: index });
  }

  render() {
    const { activeTabIndex } = this.state;

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              <div
                className="view-heading"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <span
                    role="button"
                    onClick={() => this.selectTab(0)}
                    className={`heading-tab ${activeTabIndex === 0 ? 'active' : 'inactive'}`}
                    style={{ marginLeft: 0 }}
                  >
                    Kohteet
                  </span>
                  <span role="button" onClick={() => this.selectTab(1)} className={`heading-tab ${activeTabIndex === 1 ? 'active' : 'inactive'}`}>
                    Tilaajat
                  </span>
                </div>
              </div>
            </GridCell>
            <GridCell
              span={12}
            >
              {activeTabIndex === 0 && <Locations />}
              {activeTabIndex === 1 && <Purchasers />}
            </GridCell>
          </GridInner>
        </Grid>
      </>
    );
  }
}

export default LocationsPurchasers;
