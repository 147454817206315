import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { when } from 'mobx';
// import {
//   Dialog, DialogActions, Button, DialogContent, DialogTitle, IconButton,
// } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
// import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
// import DayPickerInput from 'react-day-picker/DayPickerInput';
// import { TextField } from '@rmwc/textfield';
// import { Grid, GridCell, GridInner } from '@rmwc/grid';
// import { Checkbox } from '@rmwc/checkbox';
// import { Elevation } from '@rmwc/elevation';
// import { MenuSurfaceAnchor, Menu, MenuItem, MenuSurface } from '@rmwc/menu';
import { Icon } from '@rmwc/icon';
// import { Snackbar } from '@rmwc/snackbar';
// import { fromPromise } from 'mobx-utils';
import {
  // CollapsibleList,
  // List,
  // ListGroup,
  ListItem,
  ListItemMeta,
  // ListDivider,
  // ListGroupSubheader,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  // SimpleListItem,
} from '@rmwc/list';
// import { Switch } from '@rmwc/switch';
// import WorkPeriod from '../models/WorkPeriod';
// import LoadingSpinner from '../shared/LoadingSpinner';
// import EmployeeCalendar from '../calendar/EmployeeCalendar';
// import WorkOrderInvitationDialog from './WorkOrderInvitationDialog';

// import MomentLocaleUtils, {
//   formatDate,
//   parseDate,
// } from 'react-day-picker/moment';
// import 'moment/locale/fi';
// import moment, { cleanDate } from '../utils/moment';
// import Theme from '../Theme';
// import { renderOverlapRows } from '../resource-availability/ResourceAvailability';
// import { isEqual } from 'lodash';
// import WorkOrderInvitation from './WorkOrderInvitation';
// import CalendarEntry from '../models/CalendarEntry';

// import ChatRoom from '../messages/stores/ChatRoom';
// import LoadingSpinner from '../shared/LoadingSpinner';
// import Chat from '../messages/Chat';
// import Theme from '../Theme';
// import './employerTimelogViewDialog.css';

@inject('t', 'employerWorkOrderStore', 'uiStore')
@observer
class WorkOrderInvitationListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovering: {},
    };
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     workPeriod,
  //     // workOrder,
  //     // employerWorkOrderStore: {
  //     //   getAvailabilities,
  //     // },
  //   } = this.props;

  //   // && workPeriod.from && workPeriod.to
  //   if (workPeriod && !isEqual(workPeriod, prevProps.workPeriod)) {
  //     // this.setState({
  //     //   workOrdersWithSalaryPeriod: null,
  //     //   workOrderTrips: null,
  //     // });

  //     // const availabilities = getAvailabilities(workOrder.id, workPeriod?.from?.format('YYYY-MM-DD'), workPeriod?.to?.format('YYYY-MM-DD'));
  //     // this.setState({
  //     //   workPeriod,
  //     //   availabilities,
  //     // });

  //     this.setState({
  //       workPeriod,
  //     }, () => {
  //       if (workPeriod.from && workPeriod.to && (prevProps.workPeriod.from !== workPeriod.from || prevProps.workPeriod.to !== workPeriod.to)) {
  //         this.getPeriodAvailabilities();
  //       }
  //     });
  //   }
  // }

  handleMouseEnter(invitationId) {
    this.setState((prevState) => ({
      hovering: { ...prevState.hovering, [invitationId]: true },
    }));
  }

  handleMouseLeave(invitationId) {
    this.setState((prevState) => ({
      hovering: { ...prevState.hovering, [invitationId]: false },
    }));
  }

  render() {
    const {
      t,
      userName,
      invitations,
      openInvitationDialog,
      editMode,
      uiStore: {
        mobileMode,
        currentUser: { accountInfo },
      },
      editCalendarEntries,
      confirmDeleteInvitation,
    } = this.props;

    // const {
    //   t,
    //   uiStore,
    //   mobileMode,
    //   currentUser: { accountInfo },
    // } = uiStore;

    const { hovering } = this.state;

    const colors = {
      accepted: 'limegreen',
      rejected: 'red',
      noAnswer: '#c7c7c7',
    };

    return (
      <ListItem
        // key={`${invitation.userId}-${invitation.id}`}
        key={`${userName}-invitations`}
        className="availability-list-item"
        style={{
          // overflow: 'visible',
          height: 'fit-content',
          cursor: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          // paddingLeft: !editMode && '16px',
          padding: '0 14px',
        }}
      >
        <ListItemText style={{ alignSelf: 'start' }}>
          <ListItemPrimaryText
            style={{ marginTop: '-5px', marginBottom: '-15px', fontSize: '16px' }}
          >
            {userName}
          </ListItemPrimaryText>
        </ListItemText>

        <div style={{ width: '100%' }}>
          {invitations.map((invitation, index) => {
            if (invitation._destroy) {
              return null;
            }

            let invitationStatus;

            if (invitation.canParticipate) {
              invitationStatus = 'accepted';
            } else if (invitation.canParticipate === false) {
              invitationStatus = 'rejected';
            } else {
              invitationStatus = 'noAnswer';
            }

            let smallestFromDate;
            let largestToDate;

            let relevantEntries = [];
            if (accountInfo.workPeriodsEnabled) {
              relevantEntries = invitation.calendarEntries.filter((entry) => !entry._destroy && entry.status !== 'cancelled');
              relevantEntries.forEach((entry) => {
                if (!smallestFromDate || entry.from < smallestFromDate) {
                  smallestFromDate = entry.from;
                }

                if (!largestToDate || entry.from > largestToDate) {
                  largestToDate = entry.to;
                }
              });
            }

            return (
              <div
                // There shouldn't be more than one new invitation at a time (id undefined)
                key={`${userName}-invitation-${invitation.id}`}
                style={{ display: 'flex', marginBottom: (index + 1) === invitations.length ? '' : '5px' }}
              >
                {/* REMOVED EMPTY WRAPPER HERE */}
                <Icon
                  style={{
                    width: '35px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                  icon={(
                    <div
                      style={{
                        // background: 'limegreen',
                        background: colors[invitationStatus],
                        width: '24px',
                        height: '24px',
                        borderRadius: '100px',
                      }}
                    />
                  )}
                />
                <ListItemText style={{ margin: 'auto 0' }}>
                  <div
                    role="button"
                    style={{
                      display: 'flex',
                      cursor: 'pointer',
                      minHeight: !accountInfo.workPeriodsEnabled && '30px',
                    }}
                    onMouseEnter={() => editMode && this.handleMouseEnter(invitation.id)}
                    onMouseLeave={() => editMode && this.handleMouseLeave(invitation.id)}
                    onClick={() => editMode && invitation.id && openInvitationDialog(invitation)}
                  >
                    <div>
                      {accountInfo.workPeriodsEnabled && (
                        <ListItemSecondaryText
                          style={{
                            fontSize: '0.8rem',
                            color: 'gray',
                            textTransform: 'capitalize',
                          }}
                        >
                          {smallestFromDate ? smallestFromDate.format('dd DD.MM.YY') : null}
                          {' - '}
                          {largestToDate ? largestToDate.format('dd DD.MM.YY') : null}
                          &nbsp;
                          |
                          &nbsp;
                          {`${relevantEntries.length} pv`}
                        </ListItemSecondaryText>
                      )}

                      {invitation.id ? (
                        <ListItemSecondaryText
                          style={{
                            fontSize: '0.8rem',
                            color: 'gray',
                            marginBottom: '5px',
                          }}
                        >
                          {invitationStatus === 'noAnswer' ? (
                            `Kutsu voimassa ${invitation?.inviteDeadline.format('D.M.YYYY HH:mm')} asti`
                          ) : (
                            t(`work_order.availability.${invitationStatus}`)
                          )}
                        </ListItemSecondaryText>
                      ) : (
                        <ListItemSecondaryText
                          style={{
                            fontSize: '0.8rem',
                            color: 'gray',
                          }}
                        >
                          Kutsu lähetetään
                        </ListItemSecondaryText>
                      )}
                    </div>
                    {editMode && (
                      <div
                        style={{
                          display: (invitation.id && (mobileMode || hovering[invitation.id])) ? 'flex' : 'none',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          marginLeft: '5px',
                          color: '#808080',
                        }}
                      >
                        <MoreVertIcon />
                      </div>
                    )}
                  </div>
                </ListItemText>

                {editMode && (
                  <ListItemMeta style={{ whiteSpace: 'nowrap' }}>
                    {accountInfo.workPeriodsEnabled && (
                      <IconButton
                        type="button"
                        onClick={() => {
                          editCalendarEntries(invitation);
                          // const user = {
                          //   id: invitation.userId,
                          //   firstName: invitation.userFirstName,
                          //   lastName: invitation.userLastName,
                          // };
                          // const processedCalendarEntries = this.flattenCalendarEntriesByUser(invitation);
                          // this.setState({
                          //   showEmployeeCalendarDialog: true,
                          //   allCalendarEntries: processedCalendarEntries,
                          //   selectedWorkDaysUser: user,
                          //   currentEditingInvitationIndex: invitationIndex,
                          // });
                        }}
                        style={{ color: '#645F5F', padding: '12px 5px', marginLeft: '10px' }}
                      >
                        <CalendarTodayOutlinedIcon />
                      </IconButton>
                    )}

                    <IconButton
                      type="button"
                      onClick={() => confirmDeleteInvitation(invitation)}
                      style={{ color: '#645F5F', padding: '12px 5px', marginLeft: '10px' }}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  </ListItemMeta>
                )}
              </div>
            );
          })}
        </div>
      </ListItem>
    );
  }

  // getAllDaysInRange(firstDate, lastDate) {
  //   let currentDate = firstDate;
  //   const cleanedDates = [cleanDate(firstDate)];
  //   while (currentDate.format('YYYY-MM-DD') < lastDate.format('YYYY-MM-DD')) {
  //     currentDate = currentDate.add(1, 'days');
  //     cleanedDates.push(cleanDate(currentDate));
  //   }
  //   return cleanedDates;
  // }

  // flattenCalendarEntriesByUser(invitation) {
  //   // const { availabilities } = this.state;

  //   const processedUserAvailabilityInfo = {};
  //   // const user = {
  //   //   id: invitation.userId,
  //   // }

  //   if (invitation.calendarEntries) {
  //     processedUserAvailabilityInfo.workingCurrentPeriod = invitation.calendarEntries.map((obj) => {
  //       if (obj.status === 'cancelled') {
  //         return null;
  //       }

  //       const firstDate = obj.from;
  //       const lastDate = obj.to;
  //       let currentDate = firstDate;
  //       const cleanedDates = [cleanDate(firstDate)];

  //       while (currentDate.format('DD.MM.YY') < lastDate.format('DD.MM.YY')) {
  //         cleanedDates.push(cleanDate(currentDate));
  //         currentDate = currentDate.add(1, 'days');
  //       }
  //       return cleanedDates;
  //     }).flat();
  //   } else {
  //     processedUserAvailabilityInfo.workingCurrentPeriod = [];
  //   }

  //   // //   const processedUserAvailabilityInfo = {};
  //   // Object.keys(availabilities).forEach((category) => {
  //   //   const foundUserData = availabilities[category].filter((userAvailability) => userAvailability.user.id === invitation.userId);

  //   //   // working
  //   //   // available
  //   //   // busy

  //   //   if (category === 'negativeOverlaps') {
  //   //     // foundUserData.forEach(())
  //   //     // push to
  //   //   }

  //   //   // foundUserData.forEach(())
  //   // });

  //   // // processedUserAvailabilityInfo.working = userAvailabilityInfo.overlappings?.alreadyWorking.reduce((allDates, woObj) => allDates.concat(woObj.dates)) || [];
  //   // // processedUserAvailabilityInfo.working = userAvailabilityInfo.overlappings?.alreadyWorking.map((obj) => {
  //   // //   const firstDate = moment(obj.dates[0]);
  //   // //   const lastDate = moment(obj.dates[1]);
  //   // //   return this.getAllDaysInRange(firstDate, lastDate);
  //   // // }).flat() || [];

  //   // // if (calendarEntriesFlattened) {
  //   // //   processedUserAvailabilityInfo.workingCurrentPeriod = calendarEntriesFlattened.map((obj) => {
  //   // //     if (obj.user_id === userAvailabilityInfo.user.id) {
  //   // //       const firstDate = obj.from;
  //   // //       const lastDate = obj.to;
  //   // //       return this.getAllDaysInRange(firstDate, lastDate);
  //   // //     }
  //   // //   }).flat();
  //   // // } else {
  //   // //   processedUserAvailabilityInfo.workingCurrentPeriod = [];
  //   // // }

  //   return processedUserAvailabilityInfo;
  // }
}

export default WorkOrderInvitationListItem;
