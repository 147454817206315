import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';

import ChatRoom from './ChatRoom';
import ChatMessage from './ChatMessage';
import moment from '../../utils/moment';


const sortWorkOrders = (a, b) => {
  if (a.workorder.interval.start.valueOf() > b.workorder.interval.end.valueOf()) {
    return -1;
  }
  return 1;
};

export default class ChatStore {
  @observable rooms = fromPromise.resolve([]);

  @observable totalUnreadMessages = 0;

  @observable unreadMessages = {};

  constructor(uiStore, requests) {
    this.uiStore = uiStore;
    this.requests = requests;
  }

  async unreadCounts() {
    const { currentUser } = this.uiStore;
    const { total, ...unread } = await this.requests.Messages.unreadMessages(currentUser);

    this.totalUnreadMessages = total;
    this.unreadMessages = unread;
  }

  async markAsRead(room) {
    const unread = this.unreadMessages[room.id];
    if (unread != null) {
      await this.requests.Messages.markAsRead(room, unread);
    }
  }

  // Note: due to how the def get_new_message is coded in messages_controller.rb, only work order participants will get the result
  getNewMessage(chatId, messageId) {
    this.requests.Messages.getNewMessage(chatId, messageId).then((msg) => {
      if (msg) {
        const foundRoom = this.rooms.value.find((room) => room.id === chatId);
        foundRoom.messages.push(ChatMessage.fromJson(msg));
      }
    });
  }

  async markNewAsRead(room, msgId) {
    await this.requests.Messages.markAsRead(room, msgId);
  }

  async deleteMessage(room, message) {
    await this.requests.Messages.del(room, message);

    const messages = room.messages.reduce(
      (acc, msg) => {
        if (message.id !== msg.id) {
          acc.push(msg);
        }
        return acc;
      },
      [],
    );
    room.messages.replace(messages);
  }

  async sendSMSMessage(room, message) {
    const updatedMessage = await this.requests.Messages.sendSMS(message);

    const messages = room.messages.map((msg) => {
      if (message.id === msg.id) {
        return ChatMessage.fromJson(updatedMessage);
      }
      return msg;
    });
    room.messages.replace(messages);
  }

  sendMessage(room, message, cb) {
    const { currentUser } = this.uiStore;

    const newMessage = new ChatMessage({
      sender: currentUser,
      content: message,
      sentAt: moment(),
    });

    this.requests.Messages.send(room, newMessage).then(
      action((response) => {
        room.messages.push(ChatMessage.fromJson(response));
        if (cb) {
          cb(room.workorder.id);
        }
      }),
    );
  }

  findById(id) {
    const idAsInt = parseInt(id, 10);

    let foundRoom;
    if (this.rooms.value != null) {
      foundRoom = this.rooms.value.find((room) => room.id === idAsInt);
    }

    return foundRoom;
  }

  @action addEmailNotification(chatId) {
    this.requests.Messages.addEmailNotification(chatId).then((emailNotification) => {
      const foundRoom = this.rooms.value.find((room) => room.id === emailNotification.chat_id);

      foundRoom.emailNotification = emailNotification;
    });
  }

  @action deleteEmailNotification(emailNotification) {
    this.requests.Messages.deleteEmailNotification(emailNotification).then(() => {
      const foundRoom = this.rooms.value.find((room) => room.id === emailNotification.chat_id);

      foundRoom.emailNotification = null;
    });
  }

  @action refreshRooms() {
    const { currentUser } = this.uiStore;

    // no need to update, if an update is already running
    if (this.rooms.state === 'pending') {
      return;
    }

    const promise = this.requests.Messages.findChats(currentUser)
      .then((resp) => {
        const newRooms = resp.map((room) => ChatRoom.fromJson(room));

        const now = moment().startOf('day');
        const onGoing = newRooms.filter((room) => now.isBetween(room.workorder.interval.start, room.workorder.interval.end, 'day', '[]'));
        let past = newRooms.filter((room) => !now.isBetween(room.workorder.interval.start, room.workorder.interval.end, 'day', '[]'));
        const future = past.filter((room) => room.workorder.interval.start.valueOf() > now.valueOf());
        past = past.filter((room) => room.workorder.interval.end.valueOf() < now.valueOf());

        onGoing.sort(sortWorkOrders);
        future.sort(sortWorkOrders);
        past.sort(sortWorkOrders);

        return [
          ...onGoing,
          ...future,
          ...past,
        ];
      })
      .catch((err) => console.log('kekeke', err));

    this.rooms = fromPromise(promise);
  }

  @action findChat(id) {
    const { currentUser } = this.uiStore;

    const promise = this.requests.Messages.findChat(currentUser, id)
      .then((res) => res)
      .catch((err) => console.error('Error: ', err));

    return promise;
  }
}
