import { action } from 'mobx';
import CloseCallReport from '../models/safety/CloseCallReport';

class CloseCallReportStore {
  constructor(requests) {
    this.requests = requests;
  }

  @action send(item, resolve, reject) {
    return this.requests.CloseCallReports.send(item).then((result) => {
      const newCloseCallReport = CloseCallReport.fromJsonProperties(result);

      resolve(newCloseCallReport);
    }).catch((err) => reject(err));
  }

  @action respond(id, response, resolve, reject) {
    return this.requests.CloseCallReports.respond(id, response).then((result) => {
      const newCloseCallReport = CloseCallReport.fromJsonProperties(result);

      resolve(newCloseCallReport);
    }).catch((err) => reject(err));
  }

  @action get() {
    return this.requests.CloseCallReports.get()
      .then((result) => {
        // const items = resp.map((item) => SalaryPeriodAttachment.fromJsonProperties(item));
        const closeCallReports = result.map((closeCallReport) => CloseCallReport.fromJsonProperties(closeCallReport));
        return closeCallReports;
      });
  }

  @action getEmployer() {
    return this.requests.CloseCallReports.getEmployer()
      .then((result) => {
        // const items = resp.map((item) => SalaryPeriodAttachment.fromJsonProperties(item));
        const closeCallReports = result.map((closeCallReport) => CloseCallReport.fromJsonProperties(closeCallReport));
        return closeCallReports;
      });
  }
}

export default CloseCallReportStore;
