import { action, observable } from 'mobx';
import { dateWithoutTime } from '../utils/moment';

const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class SalaryPeriod {
  @observable from;

  @observable id;

  @observable status;

  @observable to;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      from: o.from,
      status: o.status,
      to: o.to,
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      from: getDate(o.from),
      id: o.id,
      isCurrent: o.isCurrent,
      status: o.status,
      to: getDate(o.to),
    };
    this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const salaryPeriod = new SalaryPeriod({});
    salaryPeriod.updatePropertiesFromJson(object);
    return salaryPeriod;
  }

  static fromJson(json) {
    return SalaryPeriod.fromJsonProperties(JSON.parse(json));
  }

  toString() {
    return `${this.from.format('YYYY-MM-DD')} – ${this.to.format('YYYY-MM-DD')}`;
  }
}
