import React from 'react';

export const TripStartEnd = (props) => {
  const { color } = props;

  return (
    <svg style={{ width: '15px', height: '24px' }} viewBox="0 0 15 16">
      <title>Group 3</title>
      <g id="työmatkat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Yhden-päivän-työmatka" transform="translate(-286.000000, -287.000000)">
          <g id="Group-3" transform="translate(287.000000, 289.000000)">
            <circle id="Oval-Copy-2" stroke={color} strokeWidth="2" cx="6.5" cy="6.5" r="6.5" />
            <circle id="Oval" fill={color} cx="6.5" cy="6.5" r="3.43146882" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TripStart = (props) => {
  const { color } = props;

  return (
    <svg style={{ width: '15px', height: '24px' }} viewBox="0 0 15 24">
      <title>Group 2</title>
      <g id="työmatkat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Päivä-ei-osa-keikkaa" transform="translate(-286.000000, -287.000000)">
          <g id="Group-2" transform="translate(287.000000, 288.574273)">
            <circle id="Oval-Copy-2" stroke={color} strokeWidth="2" cx="6.5" cy="6.5" r="6.5" />
            <line x1="6.5" y1="13" x2="6.5" y2="22" id="Path-2" stroke={color} strokeWidth="2" />
            <circle id="Oval" fill={color} cx="6.5" cy="6.5" r="3.43146882" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TripMiddle = (props) => {
  const { color } = props;

  return (
    <svg style={{ width: '15px', height: '24px' }} viewBox="0 0 15 33">
      <title>Group 3</title>
      <g id="työmatkat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Yksi-työmatka" transform="translate(-286.000000, -321.000000)" stroke={color} strokeWidth="2">
          <g id="Group-3" transform="translate(287.000000, 321.478324)">
            <line x1="6.5" y1="0.0216763006" x2="6.5" y2="9.0216763" id="Path-2-Copy-2" />
            <line x1="6.5" y1="22.1785919" x2="6.5" y2="31.1785919" id="Path-2-Copy-3" />
            <circle id="Oval-Copy-3" cx="6.5" cy="15.5216763" r="6.5" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TripEndMiddle = (props) => {
  const { color } = props;

  return (
    <svg style={{ width: '15px', height: '24px' }} viewBox="0 0 15 32">
      <title>Group 4</title>
      <g id="työmatkat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Työmatka-alkaa-ja-päättyy-saman-päivänä" transform="translate(-286.000000, -362.000000)">
          <g id="Group-4" transform="translate(287.000000, 362.887497)">
            <circle id="Oval-Copy" stroke={color} strokeWidth="2" cx="6.5" cy="15.5" r="6.5" />
            <line x1="6.5" y1="-1.33226763e-14" x2="6.5" y2="9" id="Path-2-Copy" stroke={color} strokeWidth="2" />
            <line x1="6.5" y1="22" x2="6.5" y2="31" id="Path-2-Copy-4" stroke={color} strokeWidth="2" />
            <circle id="Oval-Copy-4" fill={color} cx="6.5" cy="15.4289265" r="3.43146882" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const TripEnd = (props) => {
  const { color } = props;

  return (
    <svg style={{ width: '15px', height: '24px' }} viewBox="0 0 15 23">
      <title>Group 5</title>
      <g id="työmatkat" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Yksi-työmatka" transform="translate(-286.000000, -362.000000)">
          <g id="Group-5" transform="translate(287.000000, 362.003199)">
            <circle id="Oval-Copy-5" stroke={color} strokeWidth="2" cx="6.5" cy="15.5" r="6.5" />
            <line x1="6.5" y1="2.57571742e-14" x2="6.5" y2="9" id="Path-2-Copy-5" stroke={color} strokeWidth="2" />
            <circle id="Oval-Copy-6" fill={color} cx="6.5" cy="15.4289265" r="3.43146882" />
          </g>
        </g>
      </g>
    </svg>
  );
};
