/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { when } from 'mobx';
import {
  Dialog, DialogActions, Button, DialogContent, DialogTitle, IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Checkbox } from '@rmwc/checkbox';
import { Elevation } from '@rmwc/elevation';
import { MenuSurfaceAnchor, Menu, MenuItem, MenuSurface } from '@rmwc/menu';
import { Icon } from '@rmwc/icon';
import { Snackbar } from '@rmwc/snackbar';
import { fromPromise } from 'mobx-utils';
import {
  CollapsibleList,
  List,
  ListGroup,
  ListItem,
  ListItemMeta,
  ListDivider,
  ListGroupSubheader,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  SimpleListItem,
} from '@rmwc/list';
import { Switch } from '@rmwc/switch';
import WorkPeriod from '../models/WorkPeriod';
import LoadingSpinner from '../shared/LoadingSpinner';
import EmployeeCalendar from '../calendar/EmployeeCalendar';
import WorkOrderInvitationDialog from './WorkOrderInvitationDialog';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';
import moment, { cleanDate } from '../utils/moment';
import Theme from '../Theme';
import { renderOverlapRows } from '../resource-availability/ResourceAvailability';
import { isEqual } from 'lodash';
import WorkOrderInvitation from './WorkOrderInvitation';
import CalendarEntry from '../models/CalendarEntry';
import WorkOrderInvitationListItem from './WorkOrderInvitationListItem';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

const textareaResize = (evt) => {
  // Need to reset the height to 1px since the minimum scrollHeight is the element height,
  // meaning we need to reset the height to recalculate the scrollHeight or it won't stop growing
  evt.target.style.height = '1px';
  evt.target.style.height = `${evt.target.scrollHeight}px`;
}

// import ChatRoom from '../messages/stores/ChatRoom';
// import LoadingSpinner from '../shared/LoadingSpinner';
// import Chat from '../messages/Chat';
// import Theme from '../Theme';
// import './employerTimelogViewDialog.css';

@inject('t', 'employerWorkOrderStore', 'uiStore')
@observer
class WorkPeriodForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workPeriod: props.workPeriod ? props.workPeriod : new WorkPeriod(),
      errors: {},
      showDeleteDialog: false,
      showEmployeeCalendarDialog: false,
      showDeleteInvitationDialog: false,
      showInvitationDialog: false,
      openInvitation: null,
      // showFullNestedDialog: false,
      allCalendarEntries: {},
      toBeDeletedWorkDays: [],
    };

    if (props.workPeriod.from && props.workPeriod.to) {
      this.getPeriodAvailabilities();
    }
  }

  editCalendarEntries = (invitation) => {
    const { workPeriod: { invitations } } = this.state;
    const foundInvitationIndex = invitations.findIndex((oldInvitation) => isEqual(oldInvitation, invitation));

    const user = {
      id: invitation.userId,
      firstName: invitation.userFirstName,
      lastName: invitation.userLastName,
    };
    const processedCalendarEntries = this.flattenCalendarEntriesByUser(invitation);
    this.setState({
      showEmployeeCalendarDialog: true,
      allCalendarEntries: processedCalendarEntries,
      selectedWorkDaysUser: user,
      currentEditingInvitationIndex: foundInvitationIndex,
    });
  }

  componentDidUpdate(prevProps) {
    const {
      workPeriod,
      // workOrder,
      // employerWorkOrderStore: {
      //   getAvailabilities,
      // },
    } = this.props;

    // && workPeriod.from && workPeriod.to
    if (workPeriod && !isEqual(workPeriod, prevProps.workPeriod)) {
      // this.setState({
      //   workOrdersWithSalaryPeriod: null,
      //   workOrderTrips: null,
      // });

      // const availabilities = getAvailabilities(workOrder.id, workPeriod?.from?.format('YYYY-MM-DD'), workPeriod?.to?.format('YYYY-MM-DD'));
      // this.setState({
      //   workPeriod,
      //   availabilities,
      // });

      this.setState({
        workPeriod,
      }, () => {
        if (workPeriod.from && workPeriod.to && (prevProps.workPeriod.from !== workPeriod.from || prevProps.workPeriod.to !== workPeriod.to)) {
          this.getPeriodAvailabilities();
        }
      });
    }
  }

  openInvitationDialog = (invitation) => {
    this.setState({
      showInvitationDialog: true,
      openInvitation: invitation,
    });
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      workPeriod: new WorkPeriod(),
      availabilities: null,
    }, () => {
      this.updateWorkPeriodProp();
    });

    onClose();
  }

  onSave() {
    const { onSave } = this.props;
    const { workPeriod } = this.state;

    onSave(workPeriod);
  }

  participantsSummary() {
    const { t } = this.props;
    const deadlineStyles = { backgroundColor: '#FFFFFF', width: '100%', margin: '10px 0' };

    return (
      <>
        <GridCell
          span={12}
        >
          {/* <GridInner style={{ gridGap: '10px' }}>
            <GridCell
              className='employer-work-order'
              desktop={6}
              tablet={4}
              phone={4}
            >
              {this.addNumberField('invitationsCount', t('work_order.labels.invitationsCount'), false, deadlineStyles)}
            </GridCell>
          </GridInner> */}

          {/* RENDER CALENDAR ENTRIES GROUPED BY USERS HERE */}
          {this.renderInvitedGroup()}
          {this.renderListGroup('fullyAvailable')}
          {this.renderListGroup('maybeAvailable')}
          {this.renderListGroup('negativeOverlaps')}
          {/* {this.renderListGroup('subcontractors')} */}
        </GridCell>
      </>
    );
  }

  addNumberField(attr, label, required, styles = null) {
    const { workPeriod, errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        type: 'number',
        onChange: (event) => {
          const { value } = event.target;
          delete errors[attr]
          this.setState({
            workPeriod: { ...workPeriod, [attr]: value },
            inputChanged: true,
            errors: { ...errors }
          }, () => {
            this.updateWorkPeriodProp();
          });
        },
        // onBlur: (event) => {
        //   required && this.handleBlur(attr, event)
        // },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: workPeriod?.[attr] != null ? workPeriod?.[attr] : '',
      }}
      />
    );
  }

  getPeriodAvailabilities() {
    const {
      workOrder,
      employerWorkOrderStore: {
        getAvailabilitiesPromise,
      },
    } = this.props;
    const { workPeriod: { id, from, to } } = this.state;

    if (from && to) {
      const getPromise = fromPromise(new Promise((resolve, reject) => getAvailabilitiesPromise(workOrder.id, moment(from).format('YYYY-MM-DD'), moment(to).format('YYYY-MM-DD'), id, resolve, reject)));
      when(
        () => getPromise.state !== 'pending',
        () => {
          getPromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Haku epäonnistui', err);
            },
            fulfilled: (availabilities) => {
              this.setState({ availabilities });
            },
          });
        },
      );
    }
  }

  handleChangeForDate = (attr, value) => {
    this.setState((prevState) => ({
      workPeriod: { ...prevState.workPeriod, [attr]: value }
    }), () => {
      this.updateWorkPeriodProp();
      this.getPeriodAvailabilities();
    });
  }

  updateWorkPeriodProp() {
    const { updateWorkPeriod, dialogMode } = this.props;
    const { workPeriod } = this.state;

    // if (!dialogMode) {
    //   this.onSave();
    // }
    updateWorkPeriod(workPeriod);
  }

  renderListGroup(category) {
    const { availabilities, workPeriod: { invitations } } = this.state;
    const { t } = this.props;

    const colors = {
      maybeAvailable: { background: '#6A707D', font: '#FFFFFFF' },
      fullyAvailable: { background: '#FFC900', font: '#000000' },
      negativeOverlaps: { background: '#C2C2C2', font: '#000000' },
      // alreadyWorking: { background: '#C2C2C2', font: '#000000' },
      accepted: { background: '#7CD715', font: '#000000' },
      rejected: { background: '#C2C2C2', font: '#000000' },
      noAnswer: { background: '#C2C2C2', font: '#000000' },
      // someAvailable: { background: '#C2C2C2', font: '#000000' },
      // subcontractors: { background: '#6A707D', font: '#FFFFFFF' },
    };

    return (
      <Elevation
        z={5}
        style={{ borderRadius: '0px 0px 3px 3px' }}
      >
        <List
          style={{
            marginTop: '10px',
            color: 'black',
            padding: '0px',
            // minHeight: '250px',
          }}
        >
          <ListGroup>
            <ListGroupSubheader
              style={{
                backgroundColor: colors[category].background,
                color: colors[category].font,
                margin: '0px',
                padding: '12px 16px',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              <span>
                {t(`work_order.availability.${category}`)}
              </span>
            </ListGroupSubheader>

            {availabilities[category]?.map((uAvailability, index) => {
              if (!invitations.find((inv) => inv.userId === uAvailability.user.id)) {
                return (
                  <React.Fragment
                    key={`${category}-${uAvailability.user.id}`}
                  >
                    {this.renderListItem(category, uAvailability, index)}
                    {index !== availabilities[category].length - 1 && <ListDivider />}
                  </React.Fragment>
                );
              }
            })}
            {availabilities[category] && availabilities[category].length === 0 && (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: '30px',
                marginLeft: '16px',
              }}>
                Ei tuloksia
              </div>
            )}
          </ListGroup>
        </List>
      </Elevation>
    );
  }

  renderInvitedGroup() {
    // const { availabilities } = this.state;
    const { workPeriod: { invitations } } = this.state;
    const { t } = this.props;

    const groupedInvitations = {};
    const sortedInvitations = [...invitations].sort((a, b) => a.userFullName.localeCompare(b.userFullName));

    sortedInvitations.filter((inv) => !inv._destroy).forEach((invitation) => {
      if (!groupedInvitations[invitation.userFullName]) {
        groupedInvitations[invitation.userFullName] = [invitation];
      } else {
        groupedInvitations[invitation.userFullName].push(invitation);
      }
    });

    const colors = {
      accepted: { background: '#7CD715', font: '#000000' },
      //rejected: { background: '#C2C2C2', font: '#000000' },
      rejected: { background: 'red', font: '#000000' },
      noAnswer: { background: '#C2C2C2', font: '#000000' },
    };

    return (
      <Elevation
        z={5}
        style={{ borderRadius: '0px 0px 3px 3px' }}
      >
        <List
          twoLine
          style={{
            marginTop: '10px',
            color: 'black',
            paddingTop: '0px',
            // minHeight: '250px',
          }}
        >
          <ListGroup>
            <ListGroupSubheader
              style={{
                backgroundColor: colors['accepted'].background,
                color: colors['accepted'].font,
                margin: '0px',
                padding: '12px 16px',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              <span>
                {t(`work_order.availability.invited`)}
              </span>
            </ListGroupSubheader>
            {invitations && Object.keys(groupedInvitations).map((userName) => (
              <WorkOrderInvitationListItem
                key={`work-order-invitation-item-${userName}`}
                userName={userName}
                invitations={groupedInvitations[userName]}
                openInvitationDialog={this.openInvitationDialog}
                editCalendarEntries={this.editCalendarEntries}
                confirmDeleteInvitation={this.confirmDeleteInvitation}
                editMode
              />
            ))}
          </ListGroup>
        </List>
      </Elevation>
    );
  }

  addDayPicker(attr, label, required, styles, extraOverlayClassName = null) {
    const { customDateRangePickerOverlay } = this.props;
    const { workPeriod, errors } = this.state;

    return (
      <DayPickerInput
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                required,
                style: { ...styles },
              }}
            />
          )
        }
        inputProps={{
          ref: null,
          className: '',
          style: { ...styles, width: '100%' },
          name: attr,
          invalid: errors[attr],
          helpText: errors[attr] && errors[attr],
          // onBlur: (event) => required && this.handleBlur(attr, event),
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker',
          showWeekNumbers: true,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        keepFocus={false}
        format="dd DD.MM.YYYY"
        onDayChange={(value) => {
          delete errors[attr]
          this.handleChangeForDate(attr, value)
        }}
        // This is is almost certainly overcomplicating things but it works
        value={workPeriod && workPeriod?.[attr] ? moment(workPeriod?.[attr], 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          // overlay: 'DayPickerInput-Overlay',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
        overlayComponent={customDateRangePickerOverlay}
      />
    );
  }

  confirmDeleteInvitation = (invitation) => {
    const { workPeriod: { invitations } } = this.state;
    const foundInvitationIndex = invitations.findIndex((oldInvitation) => isEqual(oldInvitation, invitation));

    if (invitation != null) {
      this.setState({
        showDeleteInvitationDialog: true,
        invitationToDelete: invitation,
        invitationToDeleteIndex: foundInvitationIndex,
        deleteMessage: `Haluatko varmasti poistaa henkilön ${invitation.userFirstName} ${invitation.userLastName} kutsun?`
      })
    }
  }

  renderDeleteInvitationDialog() {
    const { showDeleteInvitationDialog } = this.state;

    return (
      <Dialog
        className='employer-delete-modal'
        open={showDeleteInvitationDialog}
        onClose={() => {
          this.setState({
            showDeleteInvitationDialog: false,
            invitationToDelete: null,
            invitationToDeleteIndex: null,
            deleteMessage: '',
          });
        }}
      >
        {this.renderDeleteDialogContent()}
      </Dialog>
    )
  }

  renderDeleteDialogContent() {
    const { deleteMessage, invitationToDelete, invitationToDeleteIndex } = this.state;

    return (
      <>
        <DialogContent style={{ fontSize: '16px' }}>
          {deleteMessage}
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px'
          }}
        >
          <Button
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
            }}
            onClick={() => {
              this.handleDeleteInvitation(invitationToDelete, invitationToDeleteIndex);
            }}
          >
            Kyllä
          </Button>

          <Button
            className="employer-reject-button"
            onClick={() => {
              this.setState({
                showDeleteInvitationDialog: false,
                invitationToDelete: null,
                invitationToDeleteIndex: null,
                deleteMessage: '',
              });
            }}
          >
            Peruuta
          </Button>
        </DialogActions>
      </>
    );
  }

  handleDeleteInvitation = (invitation) => {
    const { workPeriod } = this.state;
    const updatedWorkPeriod = { ...workPeriod };

    // We can't use the invitationIndex as it comes from grouped invitations and doesn't reflect the actual indexes of the workPeriod.invitations
    const trueIndex = updatedWorkPeriod.invitations.findIndex((oldInvitation) => invitation.id === oldInvitation.id && invitation.userId === oldInvitation.userId && isEqual(invitation.calendarEntries, oldInvitation.calendarEntries));
    updatedWorkPeriod.invitations[trueIndex]._destroy = true;

    this.setState({
      workPeriod: updatedWorkPeriod,
      showDeleteInvitationDialog: false,
      invitationToDelete: null,
      invitationToDeleteIndex: null,
      deleteMessage: '',
    }, () => {
      this.updateWorkPeriodProp();
    });
  }

  getAllDaysInRange(firstDate, lastDate) {
    let currentDate = firstDate;
    const cleanedDates = [cleanDate(firstDate)];
    while (currentDate.format('YYYY-MM-DD') < lastDate.format('YYYY-MM-DD')) {
      currentDate = currentDate.add(1, 'days');
      cleanedDates.push(cleanDate(currentDate));
    }
    return cleanedDates;
  }

  // processUserAvailabilities(userAvailabilityInfo) {
  //   const { workPeriod: { calendarEntries } } = this.state;

  //   const calendarEntriesFlattened = calendarEntries ? calendarEntries.map((collection) => (collection.calendarEntries)).flat() : [];

  //   const processedUserAvailabilityInfo = {};
  //   // processedUserAvailabilityInfo.working = userAvailabilityInfo.overlappings?.alreadyWorking.reduce((allDates, woObj) => allDates.concat(woObj.dates)) || [];
  //   processedUserAvailabilityInfo.working = userAvailabilityInfo.overlappings?.alreadyWorking.map((obj) => {
  //     const firstDate = moment(obj.dates[0]);
  //     const lastDate = moment(obj.dates[1]);
  //     return this.getAllDaysInRange(firstDate, lastDate);
  //   }).flat() || [];

  //   if (calendarEntriesFlattened) {
  //     processedUserAvailabilityInfo.workingCurrentPeriod = calendarEntriesFlattened.map((obj) => {
  //       if (obj.user_id === userAvailabilityInfo.user.id) {
  //         const firstDate = obj.from;
  //         const lastDate = obj.to;
  //         return this.getAllDaysInRange(firstDate, lastDate);
  //       }
  //     }).flat();
  //   } else {
  //     processedUserAvailabilityInfo.workingCurrentPeriod = [];
  //   }

  //   return processedUserAvailabilityInfo;
  // }

  flattenCalendarEntriesByUser(invitation) {
    // const { availabilities } = this.state;

    const processedUserAvailabilityInfo = {};
    // const user = {
    //   id: invitation.userId,
    // }

    if (invitation.calendarEntries) {
      processedUserAvailabilityInfo.workingCurrentPeriod = invitation.calendarEntries.map((obj) => {
        if (obj.status === 'cancelled') {
          return null;
        }

        const firstDate = obj.from;
        const lastDate = obj.to;
        let currentDate = firstDate;
        const cleanedDates = [cleanDate(firstDate)];

        while (currentDate.format('DD.MM.YY') < lastDate.format('DD.MM.YY')) {
          cleanedDates.push(cleanDate(currentDate));
          currentDate = currentDate.add(1, 'days');
        }
        return cleanedDates;
      }).flat();
    } else {
      processedUserAvailabilityInfo.workingCurrentPeriod = [];
    }

    // //   const processedUserAvailabilityInfo = {};
    // Object.keys(availabilities).forEach((category) => {
    //   const foundUserData = availabilities[category].filter((userAvailability) => userAvailability.user.id === invitation.userId);

    //   // working
    //   // available
    //   // busy

    //   if (category === 'negativeOverlaps') {
    //     // foundUserData.forEach(())
    //     // push to
    //   }

    //   // foundUserData.forEach(())
    // });

    // // processedUserAvailabilityInfo.working = userAvailabilityInfo.overlappings?.alreadyWorking.reduce((allDates, woObj) => allDates.concat(woObj.dates)) || [];
    // // processedUserAvailabilityInfo.working = userAvailabilityInfo.overlappings?.alreadyWorking.map((obj) => {
    // //   const firstDate = moment(obj.dates[0]);
    // //   const lastDate = moment(obj.dates[1]);
    // //   return this.getAllDaysInRange(firstDate, lastDate);
    // // }).flat() || [];

    // // if (calendarEntriesFlattened) {
    // //   processedUserAvailabilityInfo.workingCurrentPeriod = calendarEntriesFlattened.map((obj) => {
    // //     if (obj.user_id === userAvailabilityInfo.user.id) {
    // //       const firstDate = obj.from;
    // //       const lastDate = obj.to;
    // //       return this.getAllDaysInRange(firstDate, lastDate);
    // //     }
    // //   }).flat();
    // // } else {
    // //   processedUserAvailabilityInfo.workingCurrentPeriod = [];
    // // }

    return processedUserAvailabilityInfo;
  }

  renderListItem(category, uAvailability, index) {
    const { t, uiStore: { mobileMode } } = this.props;
    const { workPeriod: { from, to } } = this.state;
    const canBeInvited = ['maybeAvailable', 'fullyAvailable', 'negativeOverlaps', 'subcontractors'];

    return (
      <>
        <ListItem
          key={index}
          className="availability-list-item"
          style={{
            marginLeft: !canBeInvited.includes(category) ? '35px' : '0px',
            overflow: 'visible',
            height: 'initial',
            minHeight: '48px',
          }}
        >
          {/* {canBeInvited.includes(category) && (
            <Checkbox
              checked={false}
              readOnly
            />
          )} */}
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ display: 'flex', flexGrow: 1 }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div>
                  {`${uAvailability.user.lastName}, ${uAvailability.user.firstName}`}
                </div>
                {uAvailability.user.subcontractor && (
                  <div style={{ fontSize: '11px', textTransform: 'uppercase', color: '#6A707D', lineHeight: 1, marginLeft: '10px' }}>
                    {uAvailability.user.subcontractorAccountName || 'Alihankkija'}
                  </div>
                )}
              </div>
            </div>
            <div>
              <IconButton
                type='button'
                onClick={() => {
                  const periodDays = this.getAllDaysInRange(moment(from), moment(to));

                  this.setState({
                    selectedWorkDaysUser: uAvailability.user,
                    selectedWorkDays: periodDays,
                  }, () => {
                    this.processSelectedWorkDays();
                  });
                }}
                style={{ color: '#645F5F', padding: '12px 5px' }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </ListItem>
        <div style={{ marginLeft: '26px' }}>
          {renderOverlapRows(t, mobileMode, uAvailability.overlappings)}
        </div>
      </>
    );
  }

  confirmDeleteWorkPeriod() {
    this.setState({ showDeleteDialog: true });
  }

  deleteWorkPeriod() {
    // Set work order period _destroy true here
    this.setState({ showDeleteDialog: false });
  }

  renderDeleteWorkPeriodDialog() {
    const { showDeleteDialog, closeDeleteDialog, handleDeleteWorkPeriod } = this.props;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeleteDialog}
        onClose={() => closeDeleteDialog()}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <p>Haluatko varmasti poistaa työjakson?</p>
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => handleDeleteWorkPeriod()}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => closeDeleteDialog()}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  setSelectedWorkDays = (updatedSelectedWorkDays, updatedToBeDeletedWorkDays) => {
    this.setState({
      selectedWorkDays: updatedSelectedWorkDays,
      toBeDeletedWorkDays: updatedToBeDeletedWorkDays,
    });
  }

  processSelectedWorkDays() {
    const {
      workPeriod,
      workPeriod: { invitations },
      selectedWorkDays,
      toBeDeletedWorkDays,
      selectedWorkDaysUser,
      currentEditingInvitationIndex,
    } = this.state;

    let updatedInvitations = invitations ? [...invitations] : [];
    let invitation = null;

    const currentEditingInvitation = updatedInvitations[currentEditingInvitationIndex];
    let foundUnsavedInvitation;

    selectedWorkDays.forEach((day) => {
      const foundExistingDay = currentEditingInvitation?.calendarEntries.find((entry) => entry.from.format('DD.MM.YY') === moment(day).format('DD.MM.YY'));

      if (day && !foundExistingDay) {
        foundUnsavedInvitation = updatedInvitations.find((invitation) => !invitation.id && invitation.userId === selectedWorkDaysUser.id);
        if (foundUnsavedInvitation) {
          // Found unsaved invitation, add to it
          foundUnsavedInvitation.calendarEntries.push({
            userId: selectedWorkDaysUser.id,
            from: moment(day),
            to: moment(day),
          });
        } else {
          // Create a new invitation waiting for saving
          invitation = new WorkOrderInvitation({
            userId: selectedWorkDaysUser.id,
            userFirstName: selectedWorkDaysUser.firstName,
            userLastName: selectedWorkDaysUser.lastName,
            userFullName: `${selectedWorkDaysUser.lastName}, ${selectedWorkDaysUser.firstName}`,
            calendarEntries: [
              new CalendarEntry({
                userId: selectedWorkDaysUser.id,
                from: moment(day),
                to: moment(day),
              }),
            ],
          });
          updatedInvitations.push(invitation);
        }
      } else if (day && foundExistingDay.status === 'cancelled') {
        // Already existing in a saved invitation as a cancelled day, set status back to original
        const dateFormat = 'YYYY-MM-DD';
        const formattedDay = moment(day).format(dateFormat);
        const calendarEntryIndex = currentEditingInvitation.calendarEntries.findIndex((entry) => entry.from.format(dateFormat) === formattedDay && entry.to.format(dateFormat) === formattedDay);
        if (calendarEntryIndex !== -1) {
          // Should be the OG status, whatever it was
          currentEditingInvitation.calendarEntries[calendarEntryIndex].status = 'draft';
        }
      } else {
        // Already existing in a saved invitation, skip
        return;
      }


      // if (foundExistingDay.status === 'cancelled') {
      //   // Already existing in a saved invitation as a cancelled day, set status back to original
      //   const dateFormat = 'YYYY-MM-DD';
      //   const formattedDay = moment(day).format(dateFormat);
      //   const calendarEntryIndex = currentEditingInvitation.calendarEntries.findIndex((entry) => entry.from.format(dateFormat) === formattedDay && entry.to.format(dateFormat) === formattedDay);
      //   if (calendarEntryIndex !== -1) {
      //     currentEditingInvitation.calendarEntries[calendarEntryIndex].status = ;
      //   }
      // }

      // foundSavedInvitation = updatedInvitations.find((invitation) => invitation.id && invitation.userId === selectedWorkDaysUser.id && invitation.calendarEntries.find((entry) => entry.from.format('DD.MM.YY') === moment(day).format('DD.MM.YY')));

      // if (foundSavedInvitation) {
      //   // Already existing in a saved invitation, skip
      //   return;
      // } else {
      //   foundUnsavedInvitation = updatedInvitations.find((invitation) => !invitation.id && invitation.userId === selectedWorkDaysUser.id);
      //   if (foundUnsavedInvitation) {
      //     // Found unsaved invitation, add to it
      //     foundUnsavedInvitation.calendarEntries.push({
      //       userId: selectedWorkDaysUser.id,
      //       from: moment(day),
      //       to: moment(day),
      //     });
      //   } else {
      //     // Create a new invitation waiting for saving
      //     invitation = new WorkOrderInvitation({
      //       userId: selectedWorkDaysUser.id,
      //       userFirstName: selectedWorkDaysUser.firstName,
      //       userLastName: selectedWorkDaysUser.lastName,
      //       calendarEntries: [
      //         {
      //           user_id: selectedWorkDaysUser.id,
      //           from: moment(day),
      //           to: moment(day),
      //         }
      //       ],
      //     });
      //     updatedInvitations.push(invitation);
      //   }
      // }
    });

    // const foundInvitation = foundSavedInvitation || foundUnsavedInvitation;
    toBeDeletedWorkDays.forEach((day) => {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDay = moment(day).format(dateFormat);

      const calendarEntryIndex = currentEditingInvitation.calendarEntries.findIndex((entry) => entry.from.format(dateFormat) === formattedDay && entry.to.format(dateFormat) === formattedDay);
      if (calendarEntryIndex !== -1) {
        currentEditingInvitation.calendarEntries[calendarEntryIndex].status = 'cancelled';
      }
    });

    this.setState({
      workPeriod: { ...workPeriod, invitations: updatedInvitations },
      showEmployeeCalendarDialog: false
    }, () => {
      this.updateWorkPeriodProp();
    });
  }

  renderEmployeeCalendarDialog() {
    const { showEmployeeCalendarDialog, allCalendarEntries, selectedWorkDaysUser } = this.state;

    return (
      <Dialog
        className="employer-delete-modal marginless-material-dialog"
        open={showEmployeeCalendarDialog}
        onClose={() => {
          this.setState({ showEmployeeCalendarDialog: false, selectedWorkDaysUser: false, });
        }}
      >
        <DialogTitle>Valitse työntekijän työpäivät</DialogTitle>
        <DialogContent
          className="work-period-form-dialog-content"
          style={{ fontSize: '16px' }}
        >
          <div style={{ margin: '-10px 0 10px 0' }}>
            {`${selectedWorkDaysUser?.lastName}, ${selectedWorkDaysUser?.firstName}`}
          </div>
          <Theme>
            {/* workOrders={workOrders} */}
            <EmployeeCalendar employerWorkDayMode employeeCalendarEntries={allCalendarEntries} setSelectedWorkDays={this.setSelectedWorkDays} />
          </Theme>
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => this.processSelectedWorkDays()}
          >
            Valmis
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => { this.setState({ showEmployeeCalendarDialog: false }); }}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  closeInvitationDialog = () => {
    this.setState({
      showInvitationDialog: false,
      openInvitation: null,
    });
  }

  addTextField(attr, label, required, styles = null, textarea = false, extraOnChange = null) {
    const { workPeriod, errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        textarea,
        label,
        required,
        name: attr,
        invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        type: 'text',
        onChange: (event) => {
          const { value } = event.target;
          delete errors[attr]
          this.setState({
            workPeriod: { ...workPeriod, [attr]: value },
            inputChanged: true,
            // errors: { ...errors, [attr]: null }
            errors: { ...errors }
          }, () => {
            this.updateWorkPeriodProp();
          });
          if (extraOnChange) {
            extraOnChange(event);
          }
          // workOrder.changeAttribute(attr, value);
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: workPeriod?.[attr] != null ? workPeriod?.[attr] : '',
      }}
      />
    );
  }

  updateInvitation = (invitation) => {
    const { workPeriod } = this.state;
    const updatedInvitations = [...workPeriod.invitations];
    const foundInvitationIndex = updatedInvitations.findIndex((inv) => inv.id === invitation.id);
    if (foundInvitationIndex !== -1) {
      updatedInvitations[foundInvitationIndex].canParticipate = invitation.can_participate;
      this.setState({
        workPeriod: { ...workPeriod, invitations: updatedInvitations }
      });
    }
  }

  render() {
    const { t, singleWorkPeriod, uiStore: { mobileMode, currentUser: { accountInfo: { workPeriodsEnabled } } }, editingWorkPeriods, dialogMode, openWorkPeriodDialog } = this.props;
    const { workPeriod, availabilities, showInvitationDialog, openInvitation } = this.state;

    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      color: 'black',
    };

    const textareaStyles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      color: 'black',
      whiteSpace: 'pre',
      borderBottom: 'rgba(0,0,0,.42) 1px solid',
    };

    return (
      <>
        {(dialogMode || (workPeriodsEnabled && editingWorkPeriods)) && (
          <GridCell
            span={12}
          >
            <GridInner>
              <GridCell
                className='employer-work-order work-period-date-picker-wrapper'
                desktop={6}
                tablet={4}
                phone={4}
              >
                <Theme>
                  {this.addDayPicker('from', t('work_order.labels.from'), true, styles)}
                </Theme>
              </GridCell>

              <GridCell
                className='employer-work-order work-period-date-picker-wrapper'
                desktop={6}
                tablet={4}
                phone={4}
              >
                <Theme>
                  {this.addDayPicker('to', t('work_order.labels.to'), true, styles, 'DayPickerInput-Overlay-Right')}
                </Theme>
              </GridCell>
            </GridInner>
          </GridCell>
        )}

        <GridCell
          style={{ marginTop: '10px' }}
          span={12}
        >
          <GridInner>
            <GridCell span={workPeriodsEnabled || !singleWorkPeriod ? 4 : 12}>
              {this.addNumberField('invitationsCount', t('work_order.labels.invitationsCount'), false, styles)}
            </GridCell>

            {!singleWorkPeriod && workPeriodsEnabled && (
              <GridCell span={8}>
                {this.addTextField('name', 'Jakson nimi', false, styles)}
              </GridCell>
            )}

            {!singleWorkPeriod && workPeriodsEnabled && (
              <GridCell
                className='employer-work-order'
                span={12}
              >
                {this.addTextField('description', 'Jakson lisätiedot', false, textareaStyles, true, textareaResize)}
              </GridCell>
            )}
          </GridInner>
        </GridCell>

        {/* {workPeriodsEnabled && (
          <GridInner>
            <GridCell
              className='employer-work-order'
              span={12}
            >
              {this.addTextField('description', 'Jakson lisätiedot', false, textareaStyles, true, textareaResize)}
            </GridCell>
          </GridInner>
        )} */}

        {((!workPeriod.from || !workPeriod.to) && !workPeriodsEnabled) && (
          <GridInner>
            <GridCell span={12}>
              <p style={{ fontSize: '16px' }}>
                Syötä keikan päivämäärät kutsuaksesi työntekijöitä.
              </p>
            </GridCell>
          </GridInner>
        )}

        {availabilities ? (
          this.participantsSummary()
        ) : (
          workPeriod.from && workPeriod.to && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          )
        )}

        {this.renderDeleteWorkPeriodDialog()}
        {this.renderEmployeeCalendarDialog()}
        {this.renderDeleteInvitationDialog()}
        <WorkOrderInvitationDialog
          open={showInvitationDialog}
          onClose={this.closeInvitationDialog}
          invitation={openInvitation}
          updateInvitation={this.updateInvitation}
        />
      </>
    );
  }
}

export default WorkPeriodForm;
