/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import {
  List,
  ListGroup,
  ListItem,
  ListDivider,
  ListItemText,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemMeta,
} from '@rmwc/list';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Button } from '@rmwc/button';
import { IconButton, TextField, Box, ThemeProvider } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { DirectUploadProvider } from 'react-activestorage-provider';

import { buttonColors } from '../calendar/EmployeeCalendar';
import { handleProgress } from '../models/user/user-ui-avatar';

import theme from '../muiTheme';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

const descriptionOrFileValidation = (description, file) => {
  if (!description && !file) {
    return 'Vaadittu';
  }
  return null;
};

const titleValidate = (title) => {
  if (!title) {
    return 'Otsikko on vaadittu kenttä';
  }
  return null;
};

const validate = {
  attachments: (description, file) => descriptionOrFileValidation(description, file),
  title: (title) => titleValidate(title),
};

@inject('employerWorkOrderStore', 'uiStore', 't')
@observer
class EmployerWorkOrderAttachment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInstructionDialog: false,
      description: '',
      fileURL: '',
      filename: '',
      id: null,
      title: '',
      errors: {},
      inputChanged: false,
      showDeleteDialog: false,
      deleteInfo: {},
    };
  }

  componentDidMount() {
    const { workOrderId } = this.props;

    this.setState({
      workOrderId,
    });
  }

 /* textareaResize(evt) {
    // Need to reset the height to 1px since the minimum scrollHeight is the element height,
    // meaning we need to reset the height to recalculate the scrollHeight or it won't stop growing
    evt.target.style.height = '1px';
    evt.target.style.height = `${evt.target.scrollHeight}px`;
  } */


addTextField(attr, label, required, styles, textarea = false) {
  const { errors } = this.state;

  return (
    <ThemeProvider theme={theme}>
      <TextField {...{
        variant: 'filled',
        style: { ...styles },
        label,
        required,
        name: attr,
        error: Boolean(errors[attr]),
        helperText: errors[attr] && errors[attr],
        multiline: textarea,
        type: "text",
        onChange: (event) => {
          const { value } = event.target;
          delete errors[attr];
          this.setState({
            [attr]: value,
            inputChanged: true,
            errors: { ...errors },
          });
        },
        onKeyPress: this.handleEnter,
        value: this.state[attr] != null ? this.state[attr] : '',
        fullWidth: true,
        inputProps: {
           style: {
            minHeight: "19px"
        }},
      }}
      />
    </ThemeProvider>
  );
}

  attachmentUpload(disabled, data, t, inputRef) {
    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={false}
        // onSubmit={response => console.log('response', response)}
        onSuccess={(newData) => {
          if (Array.isArray(newData)) {
            const [value] = newData;
            const body = {
              ...data,
              file: value,
            };

            this.handleAttachmentAction(body);
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={false}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button {...{
                disabled,
                type: 'button',
                label: 'Lisää tiedosto',
                icon: 'description',
                outlined: true,
                theme: 'secondary',
                // className: 'ml-1',
                onClick: () => inputRef.current.click(),
                style: {
                  // color: buttonColors.green.color,
                  borderRadius: '0px',
                  fontSize: '.75rem',
                  textTransform: 'none',
                },
              }}
              />
            </div>

            {handleProgress(uploads, t)}
          </>
        )}

        required
      />
    );
  }

  handleAttachmentAction(attachment) {
    const { employerWorkOrderStore, updateAttachmentState } = this.props;

    if (attachment.id == null || attachment.id == '') {
      const createPromise = new Promise((resolve) => employerWorkOrderStore.createWorkOrderAttachment(attachment, resolve));
      createPromise.then((newAttachment) => {
        updateAttachmentState(newAttachment);

        this.setState({
          ...newAttachment,
        });
      });
      // employerWorkOrderStore.createWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    } else {
      const updatePromise = new Promise((resolve) => employerWorkOrderStore.updateWorkOrderAttachment(attachment, resolve));
      updatePromise.then((updatedAttachment) => {
        updateAttachmentState(updatedAttachment);

        this.setState({
          ...updatedAttachment,
        });
      });
      // employerWorkOrderStore.updateWorkOrderAttachment(attachment).then((newAttachment) => updateWorkOrderAttachments(newAttachment));
    }
  }

  handleValidate(title, description, filename) {
    let errors = {};

    Object.keys(validate).forEach((key) => {
      if (key === 'attachments') {
        const error = validate[key](description, filename);

        if (error) {
          errors = { ...errors, [key]: error };
        }
      } else if (key === 'title') {
        const error = validate[key](title);

        if (error) {
          errors = { ...errors, [key]: error };
        }
      }
    });

    this.setState({
      errors: { ...errors },
    });
  }

  renderUploadedFile() {
    const { employerWorkOrderStore, workOrderId, deleteFileFromState } = this.props;
    const { id, filename, fileURL } = this.state;

    return (
      <GridInner>
        <GridCell
          desktop={2}
          phone={1}
          style={{
            alignItems: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            alignSelf: 'center',
          }}
        >
          <a
            href={fileURL}
            download={filename}
            style={{
              color: 'var(--mdc-theme-primary, #6200ee)',
              // fontSize: '14px'
            }}
          >
            {filename}
          </a>
        </GridCell>
        <GridCell
          desktop={2}
          phone={1}
        >
          <IconButton
            style={{
              color: '#645F5F',
            }}
            aria-label="delete"
            onClick={() => {
              this.confirmDelete('file', id, workOrderId, filename)
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </GridCell>
      </GridInner>
    );
  }

  renderActionButtons() {
    const { inputChanged, id, title, description, filename } = this.state;

    if (!id && !title && !description && !filename) {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action={"close"}
          >
            Sulje
          </DialogButton>
        </DialogActions>
      )
    } else if (!id && (title || description || filename)) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => {
              this.handleValidate(title, description, filename);
            }}
            action={!title || (!description && !filename) ? null : 'accept'}
          >
            Liitä
          </DialogButton>
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action={"close"}
          >
            Sulje
          </DialogButton>
        </DialogActions>
      )
    } else if (id && inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => {
              this.handleValidate(title, description, filename);
            }}
            action={!title || (!description && !filename) ? null : 'accept'}
          >
            Tallenna
            </DialogButton>
        </DialogActions>
      )
    } else if (id && !inputChanged) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action={"close"}
          >
            Sulje
            </DialogButton>
        </DialogActions>
      )
    }
  }

  confirmDelete(target, id, workOrderId, name) {

    let deleteMessage;

    if (target === 'file') {
      deleteMessage = `Haluatko varmasti poistaa tiedoston ${name}?`
    } else if (target === 'attachment') {
      deleteMessage = `Haluatko varmasti poistaa liitteen ${name}?`
    }

    this.setState({
      showDeleteDialog: true,
      deleteInfo: { target, id, workOrderId, deleteMessage },
    })
  }

  renderDeleteInviteDialog() {
    const { showDeleteDialog, deleteInfo } = this.state;

    return (
      <Dialog
        className='employer-delete-modal'
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.handleDeletion(deleteInfo)
          }
          this.setState({
            showDeleteDialog: false,
            deleteInfo: {},
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>
            {deleteInfo.deleteMessage}
          </p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px'
          }}
        >
          <DialogButton
            type="button"
            className="employer-accept-button"
            action='accept'
          >
            Kyllä
          </DialogButton>
          <DialogButton
            type="button"
            className="employer-reject-button"
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    )
  }

  handleDeletion(deleteInfo) {
    const { employerWorkOrderStore, deleteFileFromState, deleteAttachmentFromState } = this.props;

    if (deleteInfo.target === 'file') {
      employerWorkOrderStore.deleteAttachmentFile(deleteInfo.id, deleteInfo.workOrderId)
        .then(() => {
          deleteFileFromState(deleteInfo.id)
          this.setState({
            filename: null,
            fileURL: null,
          })
        });
    } else if (deleteInfo.target === 'attachment') {
      employerWorkOrderStore.deleteWorkOrderAttachment(deleteInfo.id, deleteInfo.workOrderId)
        .then(deleteAttachmentFromState(deleteInfo.id));
    }
  }

  // Function resets the form after attachment is uploaded to make sure old information is not visible
  // If in the future you want to save state changes to the form after it's closed this function can't be used

  resetFormFields() {
    this.setState({
      id: null,
      title: '',
      filename: '',
      fileURL: '',
      description: '',
      errors: {},
      inputChanged: false,
    });
  }

  render() {
    const {
      workOrderId, attachments, t, inputRef,
    } = this.props;
    const {
      showInstructionDialog, id, description, title, filename, errors, inputChanged
    } = this.state;

    const styles = { backgroundColor: '#FFFFFF', width: '100%', margin: '0.3em 0' };

    return (
      <>
        <GridCell
          span={12}
        >
          <List
            twoLine
          >
            <ListGroup>
              {attachments?.map((woa) => (
                <React.Fragment
                  key={woa.id}
                >
                  <ListItem
                    style={{
                      color: 'black',
                    }}
                    className="availability-list-item"
                  >
                    {/* <ListItemGraphic
                      icon={
                        "file"
                      }
                    /> */}
                    <ListItemText
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      onClick={() => {
                        this.setState({
                          description: woa.description,
                          fileURL: woa.fileURL,
                          filename: woa.filename,
                          id: woa.id,
                          title: woa.title,
                          showInstructionDialog: true,
                        });
                      }}
                    >
                      <ListItemPrimaryText>
                        {woa.title}
                      </ListItemPrimaryText>
                      <ListItemSecondaryText
                        style={{
                          fontSize: '0.8rem',
                          color: 'gray',
                        }}
                      >
                        {woa.description}
                      </ListItemSecondaryText>
                    </ListItemText>
                    <ListItemMeta>
                      <IconButton
                        aria-label="delete"
                        style={{
                          color: '#6A707D',
                        }}
                        onClick={() => {
                          this.confirmDelete('attachment', woa.id, woa.workOrderId, woa.title)
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </ListItemMeta>
                  </ListItem>
                  <ListDivider />
                </React.Fragment>
              ))}
            </ListGroup>
          </List>
        </GridCell>

        <GridCell
          span={12}
          style={{
            textAlign: 'center',
          }}
        >
          <Button
            type="button"
            icon="add"
            className="employer-accept-button"
            style={{ width: 'fit-content' }}
            onClick={() => {
              this.resetFormFields();
              this.setState({
                showInstructionDialog: true,
              });
            }}
          >
            Lisää liite
          </Button>
        </GridCell>
        <Dialog
          className="modal-size employer-modal"
          open={showInstructionDialog}
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              const error = validate.attachments(description, filename);

              if (!error) {
                this.handleAttachmentAction(this.state);
              }
            }
            this.resetFormFields();
            this.setState({
              showInstructionDialog: false,
            });
          }}
        >
          <DialogTitle>{id ? 'Muokkaa liitettä' : 'Uusi liite'}</DialogTitle>
          <DialogContent>
            <Grid>
              <GridCell
                className="employer-work-order"
                span={12}
              >
                {this.addTextField('title', 'Otsikko', true, styles, false)}
              </GridCell>
              <GridCell
                span={12}
              >
                {this.addTextField('description', 'Sisältö', false, styles, true)}
              </GridCell>
              <GridCell
                span={12}
              >
                {!filename
                  ? this.attachmentUpload(
                    (title === ''),
                    {
                      id,
                      description,
                      title,
                      workOrderId,
                    },
                    t, inputRef,
                  )
                  : this.renderUploadedFile()}
                {errors.attachments && (
                  <p
                    style={{
                      color: 'red',
                      padding: '0px 16px',
                      fontSize: '.75rem',
                    }}>Sisältö tai tiedosto on pakollinen</p>
                )}
              </GridCell>
            </Grid>
          </DialogContent>
          {this.renderActionButtons()}
        </Dialog>
        {this.renderDeleteInviteDialog()}
      </>
    );
  }
}

export default EmployerWorkOrderAttachment;
