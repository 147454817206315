/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import MUIDataTable from "mui-datatables";
import {
  Button,
  Link,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import LoadingSpinner from '../../shared/LoadingSpinner';
// import BillingStatusDialog from './BillingStatusDialog';
import WorkTaskStatusDialog from './WorkTaskStatusDialog';

// import './billing.css';
import '../../employer-billing/billing.css';

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore')
@observer
class WorkTaskTable extends Component {
  constructor(props) {
    super(props);
    // const { employerWorkOrderStore, uiStore: { currentUser } } = this.props;

    this.defaultOffset = 0;
    this.defaultWorkTasksPerPage = 100;
    // this.defaultSort = 'work_order.from-desc,bill.status-asc';
    // this.defaultSort = 'work_order.from-desc,work_order.to-desc';

    this.state = {
      // showAddNewWorkOrder: false,
      // billingStatusDialogOpen: false,
      // openBilling: null,
      workTaskStatusDialogOpen: false,
      openWorkTask: null,
      workTasksPerPage: this.defaultWorkTasksPerPage,
    };

    // employerWorkOrderStore.getPurchasers(currentUser);
  }

  openWorkTaskStatusDialog(workTask) {
    this.setState({
      openWorkTask: workTask,
      workTaskStatusDialogOpen: true,
    });
  }

  handleWorkTaskStatusDialogClose = () => {
    this.setState({
      openWorkTask: null,
      workTaskStatusDialogOpen: false,
    });
  }

  // Modified from employerTimelogTable renderCustomTooltip
  // renderEmployeeNamesTooltip(visibilityIdentifier, tooltippedNode, employeeNames, customBottom = null, customWidth = null) {
  //   return (
  //     <div
  //       className="custom-tooltip-wrapper"
  //       key={`tooltip-${visibilityIdentifier}`}
  //       style={{ width: customWidth ? customWidth : null, minWidth: customWidth ? customWidth : null }}
  //     >
  //       <div
  //         className={
  //           this.state[visibilityIdentifier] ? 'custom-tooltip custom-tooltip-open' : 'custom-tooltip'
  //         }
  //         style={{
  //           bottom: customBottom,
  //         }}
  //       >
  //         {employeeNames.map((employeeName) => <div>{employeeName}</div>)}
  //       </div>

  //       <div
  //         onMouseEnter={() => employeeNames.length > 0 && this.setState({ [visibilityIdentifier]: true })}
  //         onMouseLeave={() => this.setState({ [visibilityIdentifier]: false })}
  //       >
  //         {tooltippedNode}
  //       </div>
  //     </div>
  //   );
  // }

  render() {
    const { t, uiStore, uiStore: { mobileMode }, workTasks, editWorkTask, handleAttachmentAction } = this.props;
    const { isLoading, workTasksCount, offset, workTasksPerPage, workTaskStatusDialogOpen, openWorkTask } = this.state;

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      // {
      //   name: 'timeStatus',
      //   label: t('work_order.time_status'),
      //   options: {
      //     // display: employerWorkOrderStore.billingDisplayColumns.timeStatus === undefined ? true : employerWorkOrderStore.billingDisplayColumns.timeStatus,
      //     customBodyRender: (value) => {
      //       let colorClass;
      //       if (value === 'upcoming') {
      //         colorClass = 'invitations-warning';
      //       } else if (value === 'ongoing') {
      //         colorClass = 'invitations-ok';
      //       }

      //       return (
      //         <div className={colorClass} style={{ width: 'fit-content' }}>
      //           {t(`work_order.time_statuses.${value}`)}
      //         </div>
      //       );
      //     },
      //     sort: false,
      //     // filter: true,
      //     // filterOptions: {
      //     //   renderValue: (v) => v ? t(`work_order.time_statuses.${v}`) : ''
      //     // },
      //     // customFilterListOptions: {
      //     //   render: (v) => v ? `${t('work_order.time_status')}: ${t(`work_order.time_statuses.${v}`)}` : ''
      //     // },
      //     // filterList: employerWorkOrderStore.billingFilters.timeStatus,
      //     filterType: 'checkbox',
      //   },
      // },
      {
        name: 'identifiers',
        label: 'Tunnisteet',
        options: {
          filter: false,
          // display: employerWorkOrderStore.billingDisplayColumns.name === undefined ? true : employerWorkOrderStore.billingDisplayColumns.name,
        },
      },
      {
        name: 'status.name',
        label: 'Tila',
        options: {
          customBodyRender: (value, tableMeta) => {
            const workTaskId = tableMeta.rowData[0];
            const workTaskObject = workTasks?.find((workTask) => workTask.id === workTaskId);
            let colorClass = null;
            switch (workTaskObject.status?.color_class) {
              case 'green':
                colorClass = 'billing-status-button-billed';
                break;
              case 'orange':
                colorClass = 'billing-status-button-billable';
                break;
              case 'red':
                colorClass = 'billing-status-button-not_billed';
                break;
            }

            // const woName = tableMeta.rowData[2];
            // return <div onClick={() => this.openBillingStatusDialog(billObject, woName)} className={`bill-status status-${value}`}>{value ? t(`bill.status.${value}`) : 'Aseta tila'}</div>
            return (
              <Button
                onClick={() => this.openWorkTaskStatusDialog(workTaskObject)}
                // className={value ? `billing-status-button billing-status-button-${value}` : `billing-status-button employer-reject-button`}
                className={colorClass ? `billing-status-button ${colorClass}` : 'billing-status-button employer-reject-button'}
              >
                {/* {value ? t(`work_task.statuses.${value}`) : 'Aseta tila'} */}
                {value ? value : 'Aseta tila'}
              </Button>
            );
          },
          // filter: true,
          // filterOptions: {
          //   display: 'block',
          //   renderValue: (value) => value ? t(`bill.statuses.${value}`) : t('bill.statuses.not_set')
          // },
          // customFilterListOptions: {
          //   render: (value) => value ? `${t('bill.status')}: ${t(`bill.statuses.${value}`)}` : `${t('bill.status')}: ${t(`bill.statuses.not_set`)}`
          // },
          filterType: 'checkbox',
          sort: true,
          customFilterListOptions: {
            render: v => {
              if (v === null || v === '' || v === undefined) {
                return `Tila: Ei asetettu`
              } else {
                return `Tila: ${v}`
              }
            }
          },
          // filterList: employerWorkOrderStore.billingFilters['bill.status'],
          // display: employerWorkOrderStore.billingDisplayColumns['bill.status'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['bill.status'],
        },
      },
      {
        name: 'description',
        label: 'Kuvaus',
        options: {
          filter: false,
          display: false,
          customBodyRender: (value, _tableMeta) => {
            return (
              <span style={{ whiteSpace: 'pre' }}>
                {value}
              </span>
            );
          }
          // display: employerWorkOrderStore.billingDisplayColumns.name === undefined ? true : employerWorkOrderStore.billingDisplayColumns.name,
        },
      },
      {
        name: 'workTaskUsers',
        label: 'Työn tekijät',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => (
            <span
              role="button"
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                width: 'fit-content',
                cursor: 'pointer',
              }}
            >
              <PeopleOutlineIcon
                fontSize="small"
                style={{
                  marginRight: '3px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  editWorkTask(workTasks[dataIndex]);
                }}
              />
              <span>
                {workTasks[dataIndex].workTaskUsers?.length || 0}
              </span>
            </span>
          ),
          sortCompare: (order) => {
            return (obj1, obj2) => {
              let val1 = obj1.data.length;
              let val2 = obj2.data.length;
              return (val1 - val2) * (order === 'asc' ? 1 : -1);
            };
          }
        },
      },
      {
        name: 'workTaskUsers.user.name',
        label: 'Tekijöiden nimet',
        options: {
          filter: false,
          sort: false,
          display: false,
          customBodyRenderLite: (dataIndex) => {
            const workTask = workTasks[dataIndex];
            const employeeNames = workTask.workTaskUsers.map((workTaskUser) => workTaskUser.user.name).sort();
            return (
              employeeNames.map((name) => <div style={{ whiteSpace: 'nowrap' }}>{name}</div>)
            );
          },
        },
      },
      {
        name: 'totalHours',
        label: 'Työtunnit',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => (
            <span
              role="button"
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                width: 'fit-content',
              }}
            >
              <AccessTimeIcon
                fontSize="small"
                style={{
                  marginRight: '3px',
                }}
              />
              <span className={(workTasks[dataIndex].estimate && workTasks[dataIndex].estimate < workTasks[dataIndex].totalHours) ? 'invitations-warning' : 'invitations-neutral'}>
                {workTasks[dataIndex].estimate ? `${workTasks[dataIndex].totalHours}/${workTasks[dataIndex].estimate}` : workTasks[dataIndex].totalHours}
              </span>
            </span>
          )
        },
      },
      {
        name: 'files',
        label: 'Liitteet',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.files === undefined ? true : employerWorkOrderStore.billingDisplayColumns.files,
          customBodyRenderLite: (dataIndex) => {
            return (
              <span
                role="button"
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  editWorkTask(workTasks[dataIndex]);
                }}
              >
                <AttachFileIcon
                  fontSize="small"
                  style={{
                    color: 'black',
                    opacity: '1 !imporant',
                  }}
                />
                <span className="invitations-neutral" style={{ paddingRight: 0 }}>
                  {workTasks[dataIndex].files.length === 0 ? '-' : workTasks[dataIndex].files.length}
                </span>
              </span>

              // <div className="billing-report-link">
              //   <Link
              //     component="button"
              //     // onClick={() => uiStore.showBillingAttachments(workTasks[dataIndex].id)}
              //   >
              //     {(workTasks[dataIndex].files.length + workTasks[dataIndex].files.length) > 0 ? `${workTasks[dataIndex].files.length + workTasks[dataIndex].files.length} kpl` : 'Lisää liite'}
              //   </Link>
              // </div>
            )
          }
        },
      },
      {
        name: 'createdAt',
        label: 'Luotu',
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => (
            <span style={{ textTransform: 'capitalize' }}>
              {workTasks[dataIndex].createdAt.local().format('dd DD.MM. HH:mm')}
            </span>
          ),
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.report === undefined ? true : employerWorkOrderStore.billingDisplayColumns.report,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Link
                // component="a"
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  editWorkTask(workTasks[dataIndex]);
                }}
              >
                Muokkaa
              </Link>
            )
          }
        },
      },
    ];

    const options = {
      // page: Math.floor((offset + 1) / workTasksPerPage),
      // serverSide: true,
      count: workTasksCount,
      filter: true,
      sort: true,
      filterType: 'checkbox',
      // responsive: 'vertical',
      responsive: 'standard',
      search: false,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: true,
      download: false,
      print: false,
      enableNestedDataAccess: '.',
      rowsPerPage: workTasksPerPage,
      rowsPerPageOptions: [25, 50, 100],
      setTableProps: () => ({ className: 'mui-data-table-billing' }),
      textLabels: {
        body: {
          noMatch: (
            isLoading ? (
              <div style={{ textAlign: uiStore.mobileMode ? 'left' : 'center', padding: '20px', fontSize: '14px' }}>
                <LoadingSpinner color="black" />
              </div>
            ) : (
              'Töitä ei löytynyt'
            )
          )
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        pagination: {
          next: "Seuraava sivu",
          previous: "Edellinen",
          rowsPerPage: "Rivejä sivulla:",
          displayRows: "/",
        },
        filter: {
          all: "Kaikki",
          title: "SUODATIN",
          reset: "Tyhjennä",
        },
        viewColumns: {
          title: "Näytä sarakkeet",
          titleAria: "Näytä/piilota sarakkeet",
        },
        selectedRows: {
          text: "rivi(ä) valittu",
          delete: "Poista",
          deleteAria: "Poista valitut rivit",
        },
      },
      // onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
      //   if (changedColumnIndex) {
      //     employerWorkOrderStore.setBillingFilters(changedColumn, filterList[changedColumnIndex]);
      //   } else {
      //     employerWorkOrderStore.emptyFilters();
      //     employerWorkOrderStore.setBillingFilters('bill.status', [])
      //     employerWorkOrderStore.setBillingFilters('timeStatus', [])
      //   }
      // },
      // onViewColumnsChange: (changedColumn, action) => {
      //   const columnIndex = columns.findIndex((col) => col.name === changedColumn);
      //   if (columnIndex !== -1) {
      //     if (action === 'remove') {
      //       employerWorkOrderStore.setBillingDisplayColumns(changedColumn, false);
      //     } else if (action === 'add') {
      //       employerWorkOrderStore.setBillingDisplayColumns(changedColumn, true);
      //     }
      //   }
      // },
      // onTableChange: (action, tableState) => {
      //   switch (action) {
      //     case 'changePage':
      //       this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
      //       break;
      //     case 'changeRowsPerPage':
      //       this.changeRowsPerPage(tableState.page, tableState.rowsPerPage);
      //     case 'sort':
      //       this.changeSorting(tableState.sortOrder.name, tableState.sortOrder.direction);
      //       break;
      //     default:
      //       console.log('action not handled.');
      //   }
      // },
    };

    return (
      <div
        style={{
          width: '100%',
          padding: '20px',
          // display: mobileMode ? 'none' : 'initial',
        }}
        className={`mui-billing-table ${mobileMode ? 'mobile-billing-table' : null}`}
      >
        <MUIDataTable
          data={workTasks}
          columns={columns}
          options={options}
        />
        <WorkTaskStatusDialog
          handleClose={this.handleWorkTaskStatusDialogClose}
          open={workTaskStatusDialogOpen}
          workTask={openWorkTask}
          handleAttachmentAction={handleAttachmentAction}
        />
      </div>
    );
  }

  // render() {
  //   return (
  //     <>
  //       <div className="view-heading">
  //         <span>Laskutus</span>
  //       </div>
  //       {this.renderBillingTable()}
  //     </>
  //   );
  // }
}

export default WorkTaskTable;
