import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Icon,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
} from '@material-ui/core';
// import { MuiAccordionSummary } from '@material-ui/core/AccordionSummary';
// import { styled } from '@material-ui/styles';

// React-PDF Webpack 4 import
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import moment from '../utils/moment';
import './SalaryPeriodAttachmentDialog.css';

// An attempt to make the open icon rotate 90 degrees instead of the default 180
// See: https://mui.com/material-ui/react-accordion/
// const AccordionSummary = styled((props) => (
//   <MuiAccordionSummary
//     expandIcon={<Icon>chevron_right</Icon>}
//     {...props}
//   />
// ))(() => ({
//   '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
//     transform: 'rotate(90deg)',
//   },
// }));

@inject('employerWorkOrderStore', 'loginStore', 't')
@observer
class SalaryPeriodAttachmentDialog extends Component {
  toggleAccordion = (id) => {
    this.setState((prevState) => ({
      [`${id}-expanded`]: !prevState?.[`${id}-expanded`],
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  renderActionButtons() {
    const { toggleDialog } = this.props;
    return (
      <DialogActions
        style={{
          // justifyContent: 'space-between',
          padding: '15px',
        }}
      >
        <Button
          type="button"
          className="employer-reject-button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          onClick={toggleDialog}
        >
          Sulje
        </Button>
      </DialogActions>
    );
  }

  renderFile(file) {
    /* eslint-disable-next-line react/destructuring-assignment */
    const expanded = this.state?.[`${file.id}-expanded`];

    return (
      <Accordion
        key={file.id}
        TransitionProps={{ unmountOnExit: true }}
        expanded={expanded || false}
        onChange={() => this.toggleAccordion(file.id)}
        className="attachment-accordion"
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
          expandIcon={<Icon>expand_more</Icon>}
        >
          <div className="attachment-handle-wrapper">
            <Typography>{file.filename}</Typography>
            {file.created_at && (
              <Typography className="attachment-timestamp">
                {moment(file.created_at).local().format('dd DD.MM.YYYY HH:mm')}
              </Typography>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ position: 'relative', width: '100%' }}>
            <div className="image-overlay-button-wrapper">
              <IconButton
                style={{
                  color: 'white',
                  // padding: '10px',
                  backgroundColor: 'rgb(128 128 128 / 75%)',
                  borderRadius: '30px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  // Create a temporary anchor tag that is used to download the file
                  const link = document.createElement('a');
                  link.download = file.file_url;
                  link.href = file.file_url;
                  link.click();
                }}
              >
                <Icon>get_app</Icon>
              </IconButton>
            </div>
            <div style={{ width: '100%', minHeight: '100px', fontSize: '16px' }}>
              {file.filename.split('.').pop().includes('pdf') ? (
                // onLoadSuccess={onDocumentLoadSuccess}
                <Document
                  loading="Avataan PDF..."
                  noData="Tiedostoa ei löytynyt"
                  error="Tiedoston avaus epäonnistui"
                  file={file.file_url}
                >
                  <Page pageNumber={1} />
                </Document>
                // Does not work in Safari, presumably because Rails does a 304 redirect when loading PDF
                // <object
                //   aria-label={file.filename}
                //   width="100%"
                //   height="800"
                //   // data={file.file_url}
                //   data="http://www.africau.edu/images/default/sample.pdf"
                //   type="application/pdf"
                // />
              ) : (
                <img style={{ maxWidth: '100%' }} src={file.file_url} alt={file.filename} />
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    const {
      open,
      title,
      files,
      toggleDialog,
    } = this.props;
    const sortedFiles = files.sort(this.sortByCreatedAtAsc);

    return (
      <Dialog
        open={open}
        onClose={toggleDialog}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className="attachment-dialog-title">{title}</DialogTitle>
        <DialogContent>
          {sortedFiles.map((file) => (
            this.renderFile(file)
          ))}
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    );
  }
}

export default SalaryPeriodAttachmentDialog;
