import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { TextField } from '@rmwc/textfield';
import { Button } from '@rmwc/button';

import { appcontentsize as styles } from '../styles/inline-styles';

@inject('uiStore', 'registrationStore', 't')
@observer
class Invitation extends Component {
  render() {
    const { t, registrationStore, uiStore } = this.props;
    const registrationToken = uiStore.currentView.token;

    let passwordConfirmationLabel;
    if (registrationStore.password == null || registrationStore.passwordConfirmation == null) {
      passwordConfirmationLabel = t('invitations.labels.password_confirm');
    } else if (registrationStore.passwordsMatch) {
      passwordConfirmationLabel = t('invitations.labels.password_ok');
    } else {
      passwordConfirmationLabel = t('invitations.labels.password_no_match');
    }

    return (
      <>
        <h3>{t('invitations.title')}</h3>
        <TextField
          invalid={registrationStore.hasErrors}
          label={registrationStore.errors == null ? t('invitations.labels.password') : registrationStore.errors}
          onChange={registrationStore.updatePassword}
          style={styles}
          type="password"
          value={registrationStore.password || ''}
        />
        <TextField
          invalid={(registrationStore.password == null || registrationStore.passwordConfirmation == null) ? false : !registrationStore.passwordsMatch}
          label={passwordConfirmationLabel}
          onChange={registrationStore.updatePasswordConfirmation}
          style={styles}
          type="password"
          value={registrationStore.passwordConfirmation}
        />
        <Button
          disabled={!registrationStore.canSubmit}
          onClick={() => registrationStore.register(registrationToken)}
          // raised
          // theme="onSecondary"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
            marginTop: '20px',
          }}
          className="accept-button"
        >
          {t('invitations.labels.register_account')}
        </Button>
      </>
    );
  }
}

export default Invitation;
