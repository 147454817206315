import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import ChatRoomRow from './ChatRoomRow';
import { appcontentsize } from '../styles/inline-styles';

const rearrangeRooms = (currentUser, rooms) => [
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] != null ? room : null)),
  ...rooms.map((room) => (currentUser.invitationsByWorkOrderId[room.workorder.id] == null ? room : null)),
].filter((item) => item != null);

const renderChatrooms = (currentUser, rooms, unreadMessages) => {
  const chatRooms = rearrangeRooms(currentUser, rooms || []);
  return (
    <div {...{
      style: appcontentsize,
    }}
    >
      {
      chatRooms.length > 0 ? (
        chatRooms.map((room) => (
          <ChatRoomRow
            key={`chat-room-${room.id}`}
            room={room}
            unreadMessages={unreadMessages[room.id] != null ? unreadMessages[room.id].length : 0}
          />
        ))) : (
          <p
            style={{ textAlign: 'center' }}
          >
            Viestiryhmiä ei löytynyt
          </p>
      )
      }
    </div>
  );
};

@inject('uiStore', 'chatStore', 't')
@observer
class Chats extends Component {
  constructor(props) {
    super(props);

    const { chatStore } = this.props;
    chatStore.refreshRooms();
  }

  render() {
    const { chatStore, uiStore: { currentUser } } = this.props;
    const { rooms, unreadMessages } = chatStore;

    return (
      <>
        {rooms.case({
          pending: () => <p>Ladataan viestejä...</p>,
          rejected: () => <p>Viestejä ei ole ladattu, yritä kohta uudelleen!</p>,
          fulfilled: () => renderChatrooms(currentUser, rooms.value, unreadMessages),
        })}
        <p
          style={{ fontSize: '12px', margin: 'auto 0 20px 0' }}
        >
          Viestiryhmät piilotetaan 15 päivän päästä keikan päättymisestä
        </p>
      </>
    );
  }
}

export default Chats;
