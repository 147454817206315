import React, { useState } from 'react';
import { inject, observer } from 'mobx-react';
import {
  TextField,
  Button,
  Typography,
  ThemeProvider,
} from '@material-ui/core';
import LoadingSpinner from '../shared/LoadingSpinner';
import theme from '../muiTheme';

const Footer = () => (
  <div style={{
    position: 'absolute',
    bottom: '0',
    width: '100%',
    height: '50px',
    textAlign: 'center',
    backgroundColor: '#0C131D, 0.6',
  }}
  >
    <Typography
      use="body2"
      theme="primary"
      style={{
        textAlign: 'center',
        marginTop: '1em',
        fontSize: '13px',
        color: 'var(--mdc-theme-primary)',
      }}
    >
      Ongelmatilanteissa ota yhteyttä numeroon 0447718202
    </Typography>
  </div>
);

const ChangePassword = inject('uiStore', 'loginStore', 't')(observer(({ loginStore, token }) => {
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const { feedbackText, isChangingPassword } = loginStore;

  const changePassword = async () => {
    await loginStore.changePassword(
      token,
      password,
      passwordConfirmation,
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        minHeight: '230px', textAlign: 'center', width: '100%', maxWidth: '800px',
      }}
      >
        <div style={{ height: '40px' }}>
          {feedbackText && (
            <Typography
              use="body2"
              theme="primary"
              style={{
                textAlign: 'center',
                marginTop: '1em',
                fontSize: '20px',
                color: 'var(--mdc-theme-primary)',
              }}
            >
              {feedbackText}
            </Typography>
          )}
        </div>
        <TextField
          label="Salasana"
          type="password"
          variant="filled"
          onChange={(e) => setPassword(e.target.value)}
          style={{ marginTop: '1em', width: '100%', backgroundColor: '#FFFFFF' }}
        />
        <TextField
          label="Salasana uudelleen"
          type="password"
          variant="filled"
          onChange={(e) => setPasswordConfirmation(e.target.value)}
          style={{ marginTop: '0.8em', width: '100%', backgroundColor: '#FFFFFF' }}
        />
        <Button
          onClick={changePassword}
          style={{ marginTop: '1em', width: '95%' }}
          className="employee-accept-button"
          disabled={isChangingPassword}
          variant="filled"
        >
          {isChangingPassword ? (
            <LoadingSpinner color="black" size="mini" />
          ) : (
            'Vaihda salasana'
          )}
        </Button>
      </div>
      <Footer />
    </ThemeProvider>
  );
}));

export default ChangePassword;
