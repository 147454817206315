/* eslint-disable */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { CellUnit } from '../config/default';

function HeaderView({ schedulerData, nonAgendaCellHeaderTemplateResolver }) {
  const { headers, cellUnit, config, localeDayjs } = schedulerData;
  const headerHeight = schedulerData.getTableHeaderHeight();
  const cellWidth = schedulerData.getContentCellWidth();
  const minuteStepsInHour = schedulerData.getMinuteStepsInHour();

  let headerList = [];
  let style;

  // TEST
  // let headerList2 = [
  //   <th key="test-header" className="header3-text" colspan="7">
  //     <div>VIIKKO</div>
  //   </th>
  // ];
  let headerList2 = [];

  if (cellUnit === CellUnit.Hour) {
    headers.forEach((item, index) => {
      if (index % minuteStepsInHour === 0) {
        const datetime = localeDayjs(new Date(item.time));

        style = item.nonWorkingTime
          ? {
            width: cellWidth * minuteStepsInHour,
            color: config.nonWorkingTimeHeadColor,
            backgroundColor: config.nonWorkingTimeHeadBgColor,
          }
          : {
            width: cellWidth * minuteStepsInHour,
          };

        if (index === headers.length - minuteStepsInHour) {
          style = item.nonWorkingTime ? { color: config.nonWorkingTimeHeadColor, backgroundColor: config.nonWorkingTimeHeadBgColor } : {};
        }

        const pFormattedList = config.nonAgendaDayCellHeaderFormat.split('|').map(pitem => datetime.format(pitem));
        let element;

        if (typeof nonAgendaCellHeaderTemplateResolver === 'function') {
          element = nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style);
        } else {
          const pList = pFormattedList.map((formattedItem, pIndex) => <div key={pIndex}>{formattedItem}</div>);
          // console.log('View type: ', schedulerData.viewType);
          // style.visibility = 'hidden';
          element = (
            <th key={`header-${item.time}`} className="header3-text" style={style}>
              <div>{pList}</div>
            </th>
          );
        }
        headerList.push(element);
      }
    });
  } else {
    headerList = headers.map((item, index) => {
      const datetime = localeDayjs(new Date(item.time));
      style = item.nonWorkingTime
        ? {
          width: cellWidth,
          color: config.nonWorkingTimeHeadColor,
          backgroundColor: config.nonWorkingTimeHeadBgColor,
        }
        : { width: cellWidth };
      if (index === headers.length - 1) style = item.nonWorkingTime ? { color: config.nonWorkingTimeHeadColor, backgroundColor: config.nonWorkingTimeHeadBgColor } : {};
      const cellFormat = cellUnit === CellUnit.Week
        ? config.nonAgendaWeekCellHeaderFormat
        : cellUnit === CellUnit.Month
          ? config.nonAgendaMonthCellHeaderFormat
          : cellUnit === CellUnit.Year
            ? config.nonAgendaYearCellHeaderFormat
            : config.nonAgendaOtherCellHeaderFormat;
      const pFormattedList = cellFormat.split('|').map(dateFormatPart => datetime.format(dateFormatPart));

      if (typeof nonAgendaCellHeaderTemplateResolver === 'function') {
        return nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style);
      }

      const pList = pFormattedList.map((formattedItem, pIndex) => <div key={pIndex}>{formattedItem}</div>);

      // style.visibility = 'hidden';

      return (
        <>
          <th key={`header-${item.time}`} className="header3-text" style={style}>
            <div>{pList}</div>
          </th>
        </>
      );
    });

    const weekColspans = {};

    // PROKALENTERI CUSTOM START
    // Grab mondays, then per monday add a colspan 7 header?
    headerList2 = headers.map((item, index) => {
      const datetime = localeDayjs(new Date(item.time));
      // if (datetime.day() !== 0) {
      //   return null;
      // }

      // Add +1 to colspan with each consecutive day of the same week
      // If next date is not the same week = add the element with the calculated colspan

      const weekNumber = datetime.week();
      if (weekColspans[weekNumber]) {
        weekColspans[weekNumber] += 1;
      } else {
        weekColspans[weekNumber] = 1;
      }

      // style = item.nonWorkingTime
      //   ? {
      //     width: cellWidth,
      //     color: config.nonWorkingTimeHeadColor,
      //     backgroundColor: config.nonWorkingTimeHeadBgColor,
      //   }
      //   : { width: cellWidth };
      // if (index === headers.length - 1) style = item.nonWorkingTime ? { color: config.nonWorkingTimeHeadColor, backgroundColor: config.nonWorkingTimeHeadBgColor } : {};


      const cellFormat = cellUnit === CellUnit.Week
        ? config.nonAgendaWeekCellHeaderFormat
        : cellUnit === CellUnit.Month
          ? config.nonAgendaMonthCellHeaderFormat
          : cellUnit === CellUnit.Year
            ? config.nonAgendaYearCellHeaderFormat
            : config.nonAgendaOtherCellHeaderFormat;
      const pFormattedList = cellFormat.split('|').map(dateFormatPart => datetime.format(dateFormatPart)); // datetime.format(dateFormatPart)

      // const pFormattedList = [datetime.weekOfYear];

      // What is this?
      if (typeof nonAgendaCellHeaderTemplateResolver === 'function') {
        return nonAgendaCellHeaderTemplateResolver(schedulerData, item, pFormattedList, style);
      }

      // const pList = pFormattedList.map((formattedItem, pIndex) => <div key={pIndex}>{formattedItem}</div>);
      const nextItem = headers[index + 1];
      let nextWeekNumber;
      if (nextItem) {
        nextWeekNumber = localeDayjs(new Date(nextItem.time)).week();
      }

      if (!nextWeekNumber || nextWeekNumber !== weekNumber) {
        return (
          <>
            {/* style={style} */}
            <th key={`header-${item.time}`} className="header3-text" colSpan={weekColspans[weekNumber]}>
              <div>{`Vk ${datetime.week()}`}</div>
            </th>
          </>
        );
      } else {
        return null;
      }
    });
    // PROKALENTERI CUSTOM END
  }

  return (
    <thead>
      {/* PROKALENTERI 20.8.24: added the custom week headerList2 as tr */}
      {/* Removed style={{ height: headerHeight }} */}
      {/* 28.8.24: Added style={{ textTransform: 'capitalize' }} */}
      <tr key="weeks" style={{ height: schedulerData.viewType === 1 ? '20px' : '40px' }}>
        {headerList2}
      </tr>
      <tr key="days" style={{ textTransform: 'capitalize' }}>
        {headerList}
      </tr>
    </thead>
  );
}

HeaderView.propTypes = {
  schedulerData: PropTypes.object.isRequired,
  nonAgendaCellHeaderTemplateResolver: PropTypes.func,
};

HeaderView.defaultProps = {
  nonAgendaCellHeaderTemplateResolver: null,
};

export default HeaderView;
