/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Select } from '@rmwc/select';
import { Button } from '@rmwc/button';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import { Typography } from '@rmwc/typography';
// import { Button } from '@rmwc/button';
// import { Elevation } from '@rmwc/elevation';
// import { IconButton } from '@rmwc/icon-button';
// import { Checkbox } from '@rmwc/checkbox';
// import { Icon } from '@rmwc/icon';
// import {
//   List,
//   ListGroup,
//   ListItem,
//   ListItemMeta,
//   ListDivider,
//   ListGroupSubheader,
//   ListItemText,
// } from '@rmwc/list';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { isEqual } from 'lodash';

import { DirectUploadProvider } from 'react-activestorage-provider';
import { handleProgress } from '../models/user/user-ui-avatar';
// import { buttonColors } from '../../calendar/EmployeeCalendar';
// import { handleProgress } from '../../models/user/user-ui-avatar';
import './CertificateDialog.css';
import FilePreviewDialog from '../shared/FilePreviewDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
// import moment from '../../utils/moment';
import moment, { dateWithoutTime } from '../utils/moment';
import CertificateTypeDialog from './CertificateTypeDialog';

// const handleEnter = (e) => {
//   if (e.key === 'Enter') {
//     document.activeElement.blur();
//   }
// };

const validateUserId = (userId) => {
  if (!userId) {
    return 'Työntekijä on pakollinen';
  }
  return null;
};

const customDateRangePickerOverlay = ({ classNames, children, ...props }) => (
  <div
    className={classNames.overlayWrapper}
    {...props}
  >
    <div style={{ display: 'flex', flexDirection: 'column' }} className={classNames.overlay}>
      {children}
      {/* <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
        <button type="button" className="accept-button mdc-dialog__button mdc-button" onClick={() => this.closeDateRangePicker()}>Valmis</button>
      </div> */}
    </div>
  </div>
);

const validate = {
  // attachments: (description, files) => descriptionOrFileValidation(description, files),
  userId: (userId) => validateUserId(userId),
};

@inject('certificateStore', 'employerUserStore', 'loginStore', 't') // employerWorkOrderStore
@observer
class CertificateDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certificate: {},
      errors: {},
      inputChanged: false,
      showFilePreviewDialog: false,
      showCertificateTypeDialog: false,
      newCertificateType: {},
      saving: false,
    };
  }

  // Is is probably an overcomplicated way of ensuring that the dialog's state (the opened work task) stays up-to-date
  // Figure out a better solution, preferrably from an older edit dialog implementation
  componentDidUpdate(prevProps) {
    const { certificate } = this.props;
    const { certificate: prevCertificate } = prevProps;
    const { id } = this.state;

    if (!isEqual(certificate, prevCertificate) && certificate?.id !== id) {
      this.setState({
        certificate,
        inputChanged: false,
        errors: {},
        saving: false,
      });
    }
  }

  handleChangeForDate(attr, value) {
    const valueAsDate = moment(value).format('YYYY-MM-DD');
    const date = dateWithoutTime(valueAsDate);

    this.setState((prevState) => ({
      certificate: { ...prevState.certificate, [attr]: date },
      inputChanged: true,
    }));
  }

  // handleValidate(identifierOne) {
  //   let errors = {};

  //   // Not properly dynamic, you have to define the validate object but also have the values are parameters
  //   // Could be made better somehow? Get values from state using keys from the validate object?
  //   Object.keys(validate).forEach((key) => {
  //     const error = validate[key](identifierOne);

  //     if (error) {
  //       errors = { ...errors, [key]: error };
  //     }
  //   });

  //   this.setState({
  //     errors: { ...errors },
  //   });

  //   return errors;
  // }

  // handleGeneratePDF = (id, checkedFiles, certificate, callback = null) => {
  //   const { certificateStore, deleteWorkTaskFileFromState } = this.props;
  //   this.setState({
  //     generatingPdf: true,
  //   });

  //   const generatePromise = fromPromise(new Promise((resolve, reject) => certificateStore.generatePDF(certificate.id, certificate.parentId, certificate.workOrderId, checkedFiles, resolve, reject)));
  //   when(
  //     () => generatePromise.state !== "pending",
  //     () => {
  //       generatePromise.case({
  //         pending: () => {
  //         },
  //         rejected: (err) => {
  //           this.setState({
  //             generatingPdf: false,
  //           }, () => {
  //             if (callback) {
  //               callback(checkedFiles);
  //             }
  //           });
  //           // Render snackbar from a prop method here
  //           // this.setState({
  //           //   openSnackbar: true,
  //           //   saveStatusMessage: 'Tallennus epäonnistui'
  //           // }, () => this.updateEmployeeList())
  //         },
  //         fulfilled: () => {
  //           // Remove the deleted files used to generate the PDF from the state
  //           deleteWorkTaskFileFromState(certificate.id, certificate.parentId, checkedFiles, this.generatePdfComplete);
  //         }
  //       })
  //     }
  //   )
  // }

  // generatePdfComplete = () => {
  //   this.setState({
  //     generatingPdf: false,
  //   })
  // }

  attachmentUpload(disabled, inputRef, attr) {
    const { t } = this.props;
    const { certificate } = this.state;

    return (
      <DirectUploadProvider
        directUploadsPath="/rails/active_storage/direct_uploads"

        multiple={true}
        onSuccess={(signedIds) => {
          if (Array.isArray(signedIds)) {
            // const [value] = newData;

            this.setState((prevState) => ({
              certificate: { ...prevState.certificate, [attr]: signedIds[0] },
              inputChanged: true,
            }), () => {
              // handleAttachmentAction
              this.saveCertificate();
            });
          }
        }}

        render={({ handleUpload, uploads, ready }) => (
          <>
            <input
              className="inputfile"
              disabled={!ready}
              multiple={true}
              onChange={(e) => {
                handleUpload(e.currentTarget.files);
              }}
              ref={inputRef}
              type="file"
            />

            {handleProgress(uploads, t)}

            <Button {...{
              disabled,
              type: 'button',
              label: 'Lisää tiedosto',
              icon: 'description',
              outlined: true,
              theme: 'secondary',
              // className: 'ml-1',
              onClick: () => inputRef.current.click(),
              style: {
                // color: buttonColors.green.color,
                borderRadius: '0px',
                fontSize: '.75rem',
                textTransform: 'none',
              },
            }}
            />
          </>
        )}

        required
      />
    );
  }

  setFile(attr, file) {
    this.setState((prevState) => ({
      certificate: { ...prevState.certificate, [attr]: file }
    }));
  }

  renderNonImmediateAttachmentUpload(attr) {
    const { certificate } = this.state;

    return (
      <>
        <label
          htmlFor={`${attr}-file-upload`}
          className="validity-file-upload accept-button mdc-button"
        >
          {/* <Icon
            alt="camera"
            icon="photo_camera"
          /> */}
          <span style={{ marginLeft: '10px' }}>Lisää tiedosto</span>
        </label>
        {/* <Button {...{
          // disabled,
          type: 'button',
          label: 'Lisää tiedosto',
          icon: 'description',
          outlined: true,
          theme: 'secondary',
          htmlFor: `${attr}-file-upload`,
          // className: 'ml-1',
          // onClick: () => inputRef.current.click(),
          style: {
            // color: buttonColors.green.color,
            borderRadius: '0px',
            fontSize: '.75rem',
            textTransform: 'none',
          },
        }}
        /> */}

        <span style={{ color: 'black', fontSize: '12px' }}>
          {certificate[attr]?.name}
        </span>
        <input
          id={`${attr}-file-upload`}
          type="file"
          onChange={(e) => this.setFile(attr, e.target.files[0])}
          accept="image/png, image/jpeg, image/jpg, application/pdf"
          style={{ display: 'none' }}
        />
      </>
    )
  }

  // removeFromFiles = (signedIds) => {
  //   this.setState((prevState) => ({
  //     checkedFiles: prevState.checkedFiles.filter(item => signedIds.includes(item)),
  //     generatingPdfFiles: prevState.generatingPdfFiles.filter(item => signedIds.includes(item)),
  //   }));
  // }

  addTextField(attr, label, required, styles = null, textarea) {
    const { certificate, errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        rows: 5,
        // invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState((prevProps) => ({
            certificate: { ...prevProps.certificate, [attr]: value },
            inputChanged: true,
          }));
        },
        rootProps: { ripple: false },
        // onKeyPress: handleEnter,
        value: certificate?.[attr] != null ? certificate[attr] : '',
      }}
      />
    );
  }

  // updateChecked(signedId) {
  //   const { checkedFiles } = this.state;
  //   if (checkedFiles.includes(signedId)) {
  //     //
  //     this.setState((prevState) => ({
  //       checkedFiles: prevState.checkedFiles.filter((item) => item !== signedId),
  //     }));
  //   } else {
  //     this.setState((prevState) => ({
  //       checkedFiles: [...prevState.checkedFiles, signedId],
  //     }));
  //   }
  // }

  renderUploadedFileLink(file) {
    const filename = file.filename;
    const fileURL = file.fileURL;
    // const signedId = file.signedId;
    // const fileExtension = filename.split('.').pop();
    // const extensionsForPDF = ['jpg', 'png', 'jpeg'];

    return (
      <a
        // href={fileURL}
        // download={filename}
        style={{
          color: 'var(--mdc-theme-primary, #6200ee)',
          cursor: 'pointer',
          // fontSize: '14px'
        }}
        onClick={() => {
          this.setState({
            fileUrl: fileURL,
            filename: filename,
            showFilePreviewDialog: true,
          });
        }}
      >
        {filename}
      </a>
    )
  }

  // renderUploadedFile(file) {
  //   const { confirmDelete, workOrderId } = this.props;
  //   const { generatingPdf, generatingPdfFiles, checkedFiles, parentId, id } = this.state;

  //   const filename = file.filename;
  //   const fileURL = file.fileURL;
  //   const signedId = file.signedId;
  //   const fileExtension = filename.split('.').pop();
  //   const extensionsForPDF = ['jpg', 'png', 'jpeg'];

  //   const createdAt = moment(file.createdAt).local().format('dd DD.MM. HH:mm');

  //   // We want to hide checked files during PDF generation
  //   // generating PDF = true
  //   // AND
  //   // generatingPdfFiles.includes
  //   if ((generatingPdfFiles.includes(file?.signedId) && generatingPdf) === false) {
  //     return (
  //       <GridInner key={fileURL}>
  //         <GridCell
  //           desktop={8}
  //           phone={3}
  //           style={{
  //             display: 'flex',
  //             // flexDirection: 'column',
  //             // alignItems: 'center',
  //             whiteSpace: 'nowrap',
  //             overflow: 'hidden',
  //             textOverflow: 'ellipsis',
  //             // alignSelf: 'center',
  //           }}
  //         >
  //           {extensionsForPDF.includes(fileExtension.toLowerCase())
  //             ? (
  //               <Checkbox
  //                 checked={checkedFiles.includes(signedId)}
  //                 onChange={() => {
  //                   this.updateChecked(signedId);
  //                 }}
  //               />
  //             )
  //             : (
  //               <div style={{ height: '40px', width: '40px' }}></div>
  //             )
  //           }
  //           <div
  //             style={{
  //               display: 'flex',
  //               flexDirection: 'column',
  //             }}
  //           >
  //             <a
  //               // href={fileURL}
  //               // download={filename}
  //               style={{
  //                 color: 'var(--mdc-theme-primary, #6200ee)',
  //                 cursor: 'pointer',
  //                 // fontSize: '14px'
  //               }}
  //               onClick={() => {
  //                 this.setState({
  //                   fileUrl: fileURL,
  //                   showFilePreviewDialog: true,
  //                 })
  //               }}
  //             >
  //               {filename}
  //             </a>

  //             <span
  //               style={{ color: 'black', textTransform: 'capitalize', fontSize: '12px', marginTop: '-5px' }}
  //             >
  //               {createdAt}
  //             </span>
  //           </div>
  //         </GridCell>
  //         <GridCell
  //           desktop={3}
  //           phone={1}
  //           style={{
  //             justifySelf: 'flex-end',
  //           }}
  //         >
  //           {signedId && <IconButton
  //             type="button"
  //             style={{
  //               color: '#645F5F',
  //             }}
  //             icon="delete_outlined"
  //             onClick={() => {
  //               confirmDelete('file', id, workOrderId, filename, signedId, parentId);
  //             }}
  //           />}
  //           {!signedId && <div style={{ height: '48px' }} />}
  //         </GridCell>
  //       </GridInner>
  //     );
  //   } else {
  //     return null;
  //   }
  // }

  renderCertificateValidities(validities) {
    const sortedValidities = validities.slice().sort((a, b) => {
      const dateA = a.issueDate?.toDate();
      const dateB = b.issueDate?.toDate();
      return dateA - dateB;
    });
    // this.renderUploadedFileLink
    return (
      <div>
        {sortedValidities.map((validity) => {
          const fileLink = this.renderUploadedFileLink(validity.file);
          const typeText = validity.type === 'exam' ? 'Koe' : 'Voimassaolon jatkuminen';
          const nameText = validity.issueDate ? typeText : `${typeText} | ${validity.issueDate.format('dd DD.MM.YY')}`;
          return (
            <div>
              <div>{nameText}</div>
              <div style={{ fontSize: '12px' }}>
                {validity.expirationDate && <span>{`Vanhenee ${validity.expirationDate.format('dd DD.MM.YY')}`}</span>}
                {fileLink}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  renderBottomButtons() {
    const {
      inputChanged,
      certificate,
      saving,
    } = this.state;

    if (inputChanged && certificate.identifier && certificate.userId) {
      return (
        <DialogActions
          style={{
            justifyContent: certificate.id ? 'space-between' : 'end',
            padding: '15px',
          }}
        >
          {certificate.id && (
            <DialogButton
              type="button"
              className="employer-accept-button"
              style={{
                borderRadius: '0px',
                minWidth: '100px',
              }}
              // onClick={() => {
              //   this.handleValidate(identifierOne);
              // }}
              action="accept"
            // action="submit"
            // disabled={uploading || (!validity.name || !validity.validThroughDate || Object.keys(errors).length > 0)}
            >
              {saving ? <LoadingSpinner color="black" size="mini" /> : 'Tallenna'}
              {/* Tallenna */}
            </DialogButton>
          )}

          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            action="close"
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  sortByCreatedAtAsc(a, b) {
    // Assuming ISO 8601 format, which is sortable like any string
    if (a.createdAt < b.createdAt) return -1;
    if (a.createdAt > b.createdAt) return 1;
    return 0;
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showFilePreviewDialog: false,
      fileUrl: null,
    })
  }

  handleError(err) {
    const { showSnackbarMessage } = this.props;
    console.error(err);
    this.setState({
      saving: false,
    });
    showSnackbarMessage('Tallennus epäonnistui');
  }

  handleSuccess(res) {
    const { handleClose } = this.props;

    this.setState({
      certificate: res,
      inputChanged: false,
      saving: false,
      errors: {},
    }, () => {
      handleClose('saveSuccess');
    });
  }

  saveCertificate() {
    const {
      certificateStore,
    } = this.props;
    const { certificate } = this.state;

    // this.setState({ uploading: true });

    const errors = {};

    if (Object.keys(errors).length > 0) {
      // this.setState({ errors });
    } else {
      // const updatedUser = uiStore.updateOtherUser(new User({ ...this.state, id: employee?.id }));
      this.setState({
        saving: true,
      }, () => {
        if (certificate.id) {
          certificateStore.update({ ...certificate }).then((res) => {
            // Update success
            this.handleSuccess(res);
          }).catch((err) => {
            // Failure
            this.handleError(err);
          });
        } else {
          certificateStore.create({ ...certificate }).then((res) => {
            // Create success
            this.handleSuccess(res);
          }).catch((err) => {
            // Failure
            this.handleError(err);
          });
        }
      });
      // Update the dialog's certificate using a callback here

      // this.handleClose();
    }
  }

  handleBlur(attr, evt) {
    const { errors } = this.state;
    const { t } = this.props;

    const { name, value } = evt.target;
    const error = null; // validate[name](value);

    if (error) {
      this.setState({
        errors: {
          ...errors,
          [name]: t(error, { label: t(`work_order.labels.${attr}`) })
        },
      });
    }
  }

  addDropDown(attr, label, required, styles, addNew) {
    const { certificate, errors } = this.state;
    const { certificateStore: { certificateTypes }, employerUserStore: { users } } = this.props;

    const dropdownTypes = certificateTypes?.filter((cat) => cat.typeCategory === attr) || [];
    let currentValue = null;
    if (attr === 'material' || attr === 'methodology') {
      currentValue = (this.state != null && certificate?.[attr] != null) ? certificate[attr].id : ''
    } else {
      currentValue = (this.state != null && certificate?.[attr] != null) ? certificate[attr] : '';
      // value: (this.state != null && certificate?.[attr] != null) ? certificate[attr] : '',
    }

    let relevantUsers = [];
    if (attr === 'userId') {
      relevantUsers = users?.filter((user) => user.hasCompletedRegistration && user.active).sort((a, b) => {
        const nameA = a.getFullname().toUpperCase(); // convert name to uppercase to ensure case-insensitive comparison
        const nameB = b.getFullname().toUpperCase(); // convert name to uppercase to ensure case-insensitive comparison

        if (nameA < nameB) {
          return -1; // a comes first
        }
        if (nameA > nameB) {
          return 1; // b comes first
        }
        // names must be equal
        return 0;
      });
    }

    return (
      <Select
        {...{
          label,
          rootProps: {
            style: { ...styles },
          },
          required,
          name: attr,
          invalid: errors[attr],
          helpText: errors[attr] && errors[attr],
          placeholder: `Valitse ${label.toLowerCase()}`,
          // className: 'mdc-theme--text-primary-on-dark pk-select',
          // disabled: this.props.disabled,
          onBlur: (event) => {
            required && this.handleBlur(attr, event)
          },
          onChange: (event) => {
            delete errors[attr];
            if (event.target.value === '') {
              return;
            }

            if (event.target.value === `${attr}-new`) {
              // We are trying to add a new attr, toggle the relevant dialog
              this.openCertificateTypeDialog(attr);
              return;
            }
            if (certificate?.[attr] !== event.target.value && event.target.validity.valid) {
              if (attr === 'material' || attr === 'methodology') {
                const value = certificateTypes.find((category) => category.id == event.target.value);
                this.setState({
                  certificate: { ...certificate, [attr]: value },
                  inputChanged: true,
                });
              } else {
                this.setState({
                  certificate: { ...certificate, [attr]: event.target.value },
                  inputChanged: true,
                });
              }
            }
            event.target.blur();
          },
          // value: (this.state != null && certificate?.[attr] != null) ? certificate[attr] : '',
          value: currentValue,
        }}
      >
        {attr === 'userId' && relevantUsers?.map((user) => (
          <option
            key={user.id}
            value={user.id}
          >
            {user.getFullname()}
          </option>
        ))}
        {(attr === 'material' || attr === 'methodology') && dropdownTypes?.map((type) => (
          <option
            key={type.id}
            value={type.id}
          >
            {type.identifierOne}
          </option>
        ))}
        {/* {attr === 'method' && methodCategories?.map((category) => (
          <option
            key={category.id}
            value={category.id}
          >
            {category.identifier_one}
          </option>
        ))} */}
        {addNew &&
          <option
            key={`${attr}-new`}
            value={`${attr}-new`}
          >
            + Lisää uusi
          </option>
        }
      </Select>
    );
  }

  addDayPicker(attr, label, required, styles, extraOverlayClassName = null) {
    const { certificate, errors } = this.state;

    return (
      <DayPickerInput
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                required,
                style: { ...styles },
              }}
            />
          )
        }
        inputProps={{
          ref: null,
          className: '',
          style: { ...styles },
          name: attr,
          invalid: errors[attr],
          helpText: errors[attr] && errors[attr],
          // onBlur: (event) => required && this.handleBlur(attr, event),
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker',
          showWeekNumbers: true,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        keepFocus={false}
        format="dd DD.MM.YYYY"
        onDayChange={(value) => {
          delete errors[attr]
          this.handleChangeForDate(attr, value)
        }}
        // This is is almost certainly overcomplicating things but it works
        value={certificate?.[attr] ? moment(certificate?.[attr], 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          // overlay: 'DayPickerInput-Overlay',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
        overlayComponent={customDateRangePickerOverlay}
      />
    );
  }

  saveCertificateType = (certificateType) => {
    const { certificateStore } = this.props;

    certificateStore.createCertificateType(certificateType).then((res) => {
      // Set the save response as the chosen methodology or material
      if (res.typeCategory === 'methodology') {
        this.setState((prevState) => ({
          certificate: { ...prevState.certificate, methodology: res },
          inputChanged: true,
        }));
      } else if (res.typeCategory === 'material') {
        this.setState((prevState) => ({
          certificate: { ...prevState.certificate, material: res },
          inputChanged: true,
        }));
      }
    });

  }

  openCertificateTypeDialog(type) {
    this.setState({
      showCertificateTypeDialog: true,
      newCertificateType: { typeCategory: type },
    });
  }

  closeCertificateTypeDialog = () => {
    this.setState({
      showCertificateTypeDialog: false,
      newCertificateType: {},
    });
  }

  setNumberOfPages = ({ numPages }) => {
    if (numPages) {
      this.setState({
        numberOfPages: numPages,
      });
    }
  }

  render() {
    const { open, styles, handleClose } = this.props;
    const { certificate, errors, showFilePreviewDialog, fileUrl, filename, newCertificateType, showCertificateTypeDialog } = this.state;
    const inputRefExam = React.createRef();
    const inputRefValidityCheck = React.createRef();
    // const sortedFiles = files?.sort(this.sortByCreatedAtAsc);

    const sortedValidities = certificate.certificateValidities?.slice().sort((a, b) => {
      if (a.createdAt.isBefore(b.createdAt)) {
        return -1;
      }
      if (a.createdAt.isAfter(b.createdAt)) {
        return 1;
      }
      return 0;
    });

    const latestImage = certificate.currentValidityCheck?.file || certificate.currentExam?.file;


    return (
      <>
        <Dialog
          className="modal-size employer-modal"
          open={open}
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              // const error = validate['identifierOne'];

              // A hack:
              // Errors are also set into the state but it is async and not fast enough, so we use the returned errors directly here
              // const tempErrors = this.handleValidate(identifierOne);

              this.saveCertificate();

              // if (Object.keys(tempErrors).length === 0) {
              //   // this.handleSubmit({ ...this.state, parentId: certificate?.parentId, workOrderId: workOrderId });
              //   let signedIds = sortedFiles?.map((file) => {
              //     return file['signedId'];
              //   });
              //   handleAttachmentAction({ ...this.state, parentId: certificate?.parentId, files: signedIds });
              //   // Close the dialog through the parent component
              //   handleClose();
              // }
            } else {
              this.setState({
                inputChanged: false,
                saving: false,
              });
              // Close the dialog through the parent component
              handleClose();
            }
          }}
        >
          <DialogTitle>
            {certificate?.id ? 'Muokkaa hitsauspätevyyttä' : 'Uusi hitsauspätevyys'}
          </DialogTitle>
          <DialogContent>
            <Grid>
              <GridCell
                className="employer-work-order"
                span={12}
              >
                <Typography
                  style={{
                    paddingLeft: '10px',
                    color: 'black',
                  }}
                  use="headline6"
                  tag="h2"
                  className="headline"
                >
                  Perustiedot
                </Typography>
              </GridCell>

              {certificate?.id && (
                <GridCell span={12}>
                  {certificate?.currentValidityCheck?.expirationDate && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px', textTransform: 'capitalize', marginLeft: '20px', marginTop: '-15px' }}>
                      <div>Jatkettavissa:</div>
                      <div>{certificate?.currentValidityCheck.expirationDate.format('dd DD.MM.YYYY')}</div>
                    </div>
                  )}
                  {certificate?.currentExam?.expirationDate && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '300px', textTransform: 'capitalize', marginLeft: '20px' }}>
                      <div>Kokeen voimassaolo:</div>
                      <div>{certificate?.currentExam.expirationDate.format('dd DD.MM.YYYY')}</div>
                    </div>
                  )}
                </GridCell>
              )}

              <GridCell
                className='employer-work-order'
                span={12}
              >
                {this.addDropDown('userId', 'Työntekijä', true, styles, false)}
              </GridCell>

              <GridCell
                className='employer-work-order'
                span={12}
              >
                {this.addDropDown('material', 'Perusaine', true, styles, true)}
              </GridCell>

              <GridCell
                className='employer-work-order'
                span={12}
              >
                {this.addDropDown('methodology', 'Menetelmä', true, styles, true)}
              </GridCell>

              <GridCell
                className="employer-work-order"
                span={12}
              >
                {this.addTextField('identifier', 'Kokeen merkintä', true, styles, false)}
              </GridCell>

              <GridCell
                span={12}
              >
                {this.addTextField('description', 'Kuvaus', false, styles, true)}
              </GridCell>

              {!certificate?.id && (
                <>
                  <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newExamIssueDate', 'Myöntämispvm.', true, styles)}
                  </GridCell>

                  <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newExamExpirationDate', 'Vanhentumispvm.', true, styles)}
                  </GridCell>

                  <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newValidityCheckExpirationDate', 'Seuraava jatkaminen', true, styles)}
                  </GridCell>

                  <GridCell span={12} style={{ marginLeft: '5px', marginTop: '5px' }}>
                    {/* {this.renderNonImmediateAttachmentUpload('newExamFile')} */}
                    {this.attachmentUpload((!certificate?.identifier || !certificate?.newExamIssueDate || !certificate?.newExamExpirationDate), inputRefExam, 'newExamFile')}
                  </GridCell>
                </>
              )}

              {certificate?.id && (
                <>
                  <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    <Typography
                      style={{
                        paddingLeft: '10px',
                        color: 'black',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Historia
                    </Typography>
                  </GridCell>

                  <GridCell span={12}>
                    {sortedValidities?.map((validity, index) => {
                      return (
                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '250px', textTransform: 'capitalize', marginLeft: '20px', marginTop: index === 0 && '-15px' }}>
                          {validity.issueDate && (
                            <>
                              <div>{validity.validityType === 'exam' ? 'Myönnetty:' : 'Jatkettu:'}</div>
                              <div>{validity.issueDate.format('dd DD.MM.YYYY')}</div>
                            </>
                          )}
                          {/* <div>{validity.file.name}</div> */}
                        </div>
                      )
                    })}
                  </GridCell>
                </>
              )}

              {certificate?.id && (
                <>
                  {/* NEW VALIDITY CHECK */}
                  <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    <Typography
                      style={{
                        paddingLeft: '10px',
                        color: 'black',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Jatka voimassaoloa
                    </Typography>
                  </GridCell>

                  <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newValidityCheckIssueDate', 'Myöntämispvm.', true, styles)}
                  </GridCell>

                  <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newValidityCheckExpirationDate', 'Vanhentumispvm.', true, styles)}
                  </GridCell>

                  <GridCell
                    className="employer-work-order"
                    desktop={12}
                    tablet={8}
                    phone={4}
                  >
                    {this.attachmentUpload((!certificate.id || !certificate.newValidityCheckIssueDate || !certificate.newValidityCheckExpirationDate), inputRefValidityCheck, 'newValidityCheckFile')}
                  </GridCell>

                  {/* <GridCell span={12} style={{ marginLeft: '5px', marginTop: '5px' }}>
                    {this.renderNonImmediateAttachmentUpload()}
                  </GridCell> */}



                  {/* NEW EXAM */}
                  {/* <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    <Typography
                      style={{
                        paddingLeft: '10px',
                        color: 'black',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Uusi koe
                    </Typography>
                  </GridCell> */}

                  {/* <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newExamIssueDate', 'Myöntämispvm.', true, styles)}
                  </GridCell>

                  <GridCell
                    className='employer-work-order'
                    desktop={6}
                    tablet={4}
                    phone={2}
                  >
                    {this.addDayPicker('newExamExpirationDate', 'Vanhentumispvm.', true, styles)}
                  </GridCell> */}

                  {/* <GridCell
                    className="employer-work-order"
                    desktop={12}
                    tablet={8}
                    phone={4}
                  >
                    {this.attachmentUpload((!certificate.id || !certificate.newExamIssueDate || !certificate.newExamExpirationDate), inputRefExam, 'newExamFile')}
                  </GridCell> */}
                </>
              )}

              {latestImage && Object.keys(latestImage).length > 0 && (
                <>
                  <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    <Typography
                      style={{
                        paddingLeft: '10px',
                        color: 'black',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Viimeisin pätevyystodistus
                    </Typography>
                  </GridCell>

                  <GridCell
                    span={12}
                  >
                    <div
                      style={{ width: 'fit-content', minHeight: '100px', fontSize: '16px', cursor: 'pointer' }}
                      onClick={() => {
                        this.setState({
                          fileUrl: latestImage.url,
                          filename: latestImage.filename,
                          showFilePreviewDialog: true,
                        });
                      }}
                    >
                      {latestImage.url?.split('.').pop().includes('pdf') ? (
                        // onLoadSuccess={onDocumentLoadSuccess}
                        <Document
                          loading="Avataan PDF..."
                          noData="Tiedostoa ei löytynyt"
                          error="Tiedoston avaus epäonnistui"
                          file={latestImage.url}
                          onLoadSuccess={this.setNumberOfPages}
                        >
                          {/* Render each page */}
                          {fakePageArray.map((_, index) => (
                            <Page pageNumber={index + 1} />
                          ))}
                        </Document>
                        // Does not work in Safari, presumably because Rails does a 304 redirect when loading PDF
                        // <object
                        //   aria-label={file.filename}
                        //   width="100%"
                        //   height="800"
                        //   // data={file.file_url}
                        //   data="http://www.africau.edu/images/default/sample.pdf"
                        //   type="application/pdf"
                        // />
                      ) : (
                        <img style={{ maxWidth: '100%' }} src={latestImage.url} alt={latestImage.filename} />
                      )}
                    </div>
                  </GridCell>
                </>
              )}

              {/* {certificate.certificateValidities && (
                <>
                  <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    <Typography
                      style={{
                        paddingLeft: '10px',
                        color: 'black',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Historia
                    </Typography>
                  </GridCell>
                  <GridCell
                    className="employer-work-order"
                    span={12}
                  >
                    {this.renderCertificateValidities()}
                  </GridCell>
                </>
              )} */}
            </Grid>
          </DialogContent>
          {this.renderBottomButtons()}
        </Dialog>

        <FilePreviewDialog
          open={showFilePreviewDialog}
          fileURL={fileUrl}
          fileName={filename}
          onClose={this.closeFilePreviewDialog}
        />

        <CertificateTypeDialog
          open={showCertificateTypeDialog}
          certificateType={newCertificateType}
          saveCertificateType={this.saveCertificateType}
          handleClose={this.closeCertificateTypeDialog}
        />
      </>
    )
  }
}

export default CertificateDialog;
