/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Grid, GridCell } from '@rmwc/grid';
import { Elevation } from '@rmwc/elevation';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import { TextField } from '@rmwc/textfield';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import {
  List,
  ListGroup,
  ListGroupSubheader,
  ListDivider,
  ListItem,
} from '@rmwc/list';
import { Grid as MUIGrid } from '@material-ui/core';
import 'moment/locale/fi';
import dayjs from 'dayjs';
import * as dayjsLocaleFi from 'dayjs/locale/fi';
// import { cloneDeep } from 'lodash';
import truncate from '../utils/truncate';
import moment from '../utils/moment';
import LoadingSpinner from '../shared/LoadingSpinner';
import './ResourceAvailability.css';
import CalendarEntry from '../models/CalendarEntry';
// import { SchedulerData, ViewType, DATE_FORMAT } from '../components/react-big-scheduler/src';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';

import { SchedulerData } from '../components/react-big-schedule/src/components';
import { ViewType, DATE_FORMAT } from '../components/react-big-schedule/src/config/default';

import BasicResourceCalendarExample from './BasicResourceCalendarExample';

export const renderOverlapRows = (t, mobileMode, overlappings) => {
  // We need to reconstruct the overlappings. This is because there's a problem in the data structure
  // Each overlapping has one "real" or "final" descriptor/name, but depending on the case, it is sometimes a key, sometimes a value
  // For example:
  //  alreadyWorking: [ { name: WorkOrder, dates: [] } ]
  // Here we want to use "WorkOrder" as the name
  // But here:
  // unavailable: [ { dates: [] } ]
  // We want to use "unavailable" as the name
  // And so this loop flattes the structure and puts all the names in the same place

  const reconstructedOverlappings = [];

  if (overlappings) {
    Object.entries(overlappings).forEach((overlappingCategory) => {
      if ((overlappingCategory[0] === 'alreadyWorking' && overlappingCategory[1].length !== 0) || (overlappingCategory[0] === 'absent' && overlappingCategory[1].length !== 0)) {
        // Process overlapping calendar entries, turn them into ranges for UI rendering
        overlappingCategory[1].forEach((overlappingData) => {
          const name = overlappingData.work_order_name || overlappingData.absence_type_official_name;
          const entriesAsRanges = CalendarEntry.packageJsonIntoRanges(overlappingData.calendar_entries);

          reconstructedOverlappings.push({
            name,
            calendarEntries: entriesAsRanges,
          });
        });
      } else if (overlappingCategory[1].length !== 0) {
        overlappingCategory[1].forEach((overlappingData) => {
          const name = overlappingData?.name || t(`work_order.availability.${overlappingCategory[0]}`);
          const startDate = overlappingData.dates[0] ? moment(overlappingData.dates[0]).format('dd DD.MM.') : null;
          const endDate = overlappingData.dates[1] ? moment(overlappingData.dates[1]).format('dd DD.MM.') : null;

          reconstructedOverlappings.push({
            name,
            startDate,
            endDate,
          });
        });
      }
    });
  }

  return (
    <div style={{ fontSize: '14px' }}>
      {reconstructedOverlappings.map((overlap, index) => (
        // <React.Fragment
        //   key={index}
        // >
        //   <div
        //     key={index}
        //     style={{
        //       display: 'flex',
        //       justifyContent: 'space-between',
        //       margin: '0 5px 0 20px',
        //       padding: '2px 0',
        //       borderBottom: (index + 1 === reconstructedOverlappings.length) ? '' : '1px solid lightgray',
        //       gap: '10px',
        //     }}
        //   >
        //     {/* Overlapping name/descriptor */}
        //     <div style={{ fontSize: '13px', maxWidth: '235px', width: '100%' }}>
        //       {`${truncate(overlap.name, 40)}`}
        //     </div>

        //     {/* Overlapping dates */}
        //     {/* The CSS gap here is used to fake a space between the dates and the hyphen.
        //     We keep them as separate spans so that the hyphen stays with the startDate when the spans are wrapped onto two lines */}
        //     <div style={{ display: 'flex', width: '170px', height: 'fit-content', flexWrap: 'wrap', gap: '3px' }}>
        //       <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
        //         {overlap.endDate ? `${overlap.startDate} -` : overlap.startDate}
        //       </span>
        //       {overlap.endDate && (
        //         <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
        //           {overlap.endDate}
        //         </span>
        //       )}
        //     </div>
        //   </div>
        // </React.Fragment>

        // <Grid
        //   container
        //   key={index}
        //   style={{ padding: 0 }}
        // >
        //   <GridCell span={12}>
        //     <GridInner>
        //       <GridCell span={8}>
        //         {`${truncate(overlap.name, 40)}`}
        //       </GridCell>
        //       <GridCell span={4}>
        //         <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
        //             {overlap.endDate ? `${overlap.startDate} - ` : overlap.startDate}
        //           </span>
        //           {overlap.endDate && (
        //             <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
        //               {overlap.endDate}
        //             </span>
        //           )}
        //       </GridCell>
        //     </GridInner>
        //   </GridCell>
        // </Grid>
        <MUIGrid
          key={`overlap-${overlap.name}`}
          container
          justifyContent="space-between"
          style={{ borderBottom: (index !== reconstructedOverlappings.length - 1) ? '1px solid lightgray' : null }}
        >
          <MUIGrid xs={6} md={9} item>
            {mobileMode ? truncate(overlap.name, 40) : truncate(overlap.name, 70)}
          </MUIGrid>
          <MUIGrid xs={6} md={3} item style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '5px' }}>
            {/* whiteSpace: 'nowrap' */}
            {overlap.calendarEntries ? (
              overlap.calendarEntries.map((entry, entryIndex) => {
                if (entry) {
                  const startDate = entry.datetime_range.split('..')[0];
                  const endDate = entry.datetime_range.split('..')[1];
                  const startDateFormatted = moment(startDate, 'YYYY-MM-DD HH:mm:ss UTC').format('dd DD.MM.');
                  const endDateFormatted = moment(endDate, 'YYYY-MM-DD HH:mm:ss UTC').format('dd DD.MM.');

                  return (
                    <span
                      key={`entry-${entry.id}-${startDate}-${startDate}`}
                      style={{ textTransform: 'capitalize', whiteSpace: 'nowrap', marginRight: overlap.calendarEntries.length !== (entryIndex + 1) && '5px' }}
                    >
                      {startDateFormatted !== endDateFormatted ? `${startDateFormatted} - ${endDateFormatted}` : startDateFormatted}
                      {overlap.calendarEntries.length !== (entryIndex + 1) ? ' | ' : null}
                    </span>
                  );
                }
                return null;
              })
            ) : (
              <>
                <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                  {overlap.endDate ? `${overlap.startDate} - ` : overlap.startDate}
                </span>
                &nbsp;
                {overlap.endDate && (
                  <span style={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                    {overlap.endDate}
                  </span>
                )}
              </>
            )}
          </MUIGrid>
        </MUIGrid>
      ))}
    </div>
  );
};

// This is a placeholder method, for quick demo of the calendar by using the old getAvailabilities implementation
// Ideally this kind of restructuring isn't needed at all and the data comes in a usable format from the API
// Users and their availabilities directly instead of multiple categories where the users are sorted into
const constructAvailabilitiesForScheduler = (availabilitiesObj, t) => {
  const resources = [];
  const events = [];

  if (availabilitiesObj.availabilities.negativeOverlaps) {
    availabilitiesObj.availabilities.negativeOverlaps.forEach((userAvailability) => {
      resources.push({
        id: userAvailability.user.id,
        name: `${userAvailability.user.lastName}, ${userAvailability.user.firstName}`,
      });

      if (userAvailability.overlappings.alreadyWorking) {
        userAvailability.overlappings.alreadyWorking.forEach((overlap) => {
          const calendarEntryRanges = CalendarEntry.packageJsonIntoRanges(overlap.calendar_entries);
          calendarEntryRanges.forEach((calendarEntryRange) => {
            const splitDateTimeRange = calendarEntryRange.datetime_range.split('..');
            const rangeFrom = moment(splitDateTimeRange[0]).toDate();
            const rangeTo = moment(splitDateTimeRange[1]).toDate();

            events.push({
              id: overlap.work_order_name,
              resourceId: userAvailability.user.id,
              start: rangeFrom,
              end: rangeTo,
              title: overlap.work_order_name,
              // bgColor: '#FEE1C4',
              // bgColor: '#C2C2C2',
            });
          });
        });
      }


      // Available + unavailable is handled differently from the other resource view
      // Start + end?

      // Before reconstruct vs. after reconstruct?

      if (userAvailability.overlappings.unavailable) {
        userAvailability.overlappings.unavailable.forEach((overlap) => {
          const startDate = moment(overlap.dates[0], 'YYYY-MM-DD').startOf('day').toDate();
          // If there's only one date, we use startDate's end of day
          const endDate = overlap.dates[1] ? moment(overlap.dates[1], 'YYYY-MM-DD').endOf('day').toDate() : moment(overlap.dates[0], 'YYYY-MM-DD').endOf('day').toDate();

          const newEvent = {
            id: 'unavailable',
            resourceId: userAvailability.user.id,
            start: startDate,
            end: endDate,
            title: t('work_order.availability.unavailable'),
            // bgColor: '#C2C2C2', // '#FF6A00',
            // Striped background
            bgImage: 'repeating-linear-gradient(135deg, rgba(246, 237, 198, 0.7), rgba(246, 237, 198, 0.7) 10px, rgba(230, 230, 230, 0.7) 10px, rgba(230, 230, 230, 0.7) 20px)',
            bgSize: 'cover',
          };

          events.push(newEvent);
        });
      }

      if (userAvailability.overlappings.available) {
        userAvailability.overlappings.available.forEach((overlap) => {
          const startDate = moment(overlap.dates[0], 'YYYY-MM-DD').startOf('day').toDate();
          // If there's only one date, we use startDate's end of day
          const endDate = overlap.dates[1] ? moment(overlap.dates[1], 'YYYY-MM-DD').endOf('day').toDate() : moment(overlap.dates[0], 'YYYY-MM-DD').endOf('day').toDate();

          const newEvent = {
            id: 'available',
            resourceId: userAvailability.user.id,
            // startOf + endOf is required to make ranges like 8.29. - 8.30. actually two days instead of one
            // Without startOf + endOf the duration is 24 hours (both are 00:00+3) which is presumably why one day is rendered
            start: startDate,
            end: endDate,
            title: t('work_order.availability.available'),
            bgColor: '#FFC900',
          };

          events.push(newEvent);
        });
      }

      if (userAvailability.overlappings.absent) {
        userAvailability.overlappings.absent.forEach((overlap) => {
          const name = overlap.absence_type_official_name;
          const entriesAsRanges = CalendarEntry.packageJsonIntoRanges(overlap.calendar_entries);

          entriesAsRanges.forEach((range) => {
            const rangeSplit = range.datetime_range.split('..');
            const rangeStart = moment(rangeSplit[0]).toDate();
            const rangeEnd = moment(rangeSplit[1]).toDate();

            const newEvent = {
              id: `${userAvailability.user.id}-absent`,
              resourceId: userAvailability.user.id,
              start: rangeStart,
              end: rangeEnd,
              title: name,
              // Striped background
              bgImage: 'repeating-linear-gradient(135deg, rgba(246, 237, 198, 0.7), rgba(246, 237, 198, 0.7) 10px, rgba(230, 230, 230, 0.7) 10px, rgba(230, 230, 230, 0.7) 20px)',
              bgSize: 'cover',
            };

            events.push(newEvent);
          });
        });
      }
    });
  }

  return { resources, events };
};

const constructWorkOrdersForScheduler = (availabilitiesObj) => {
  const resources = [];
  const events = [];

  if (availabilitiesObj.availabilities.negativeOverlaps) {
    availabilitiesObj.availabilities.negativeOverlaps.forEach((userAvailability) => {
      // Resources: push work order
      // Events: push user + dates
      if (userAvailability.overlappings.alreadyWorking) {
        userAvailability.overlappings.alreadyWorking.forEach((overlap) => {
          const foundExistingWorkOrder = resources.find((resource) => resource.name === overlap.work_order_name);
          if (!foundExistingWorkOrder) {
            resources.push({
              id: overlap.work_order_id,
              name: overlap.work_order_name,
            });
          }

          const calendarEntryRanges = CalendarEntry.packageJsonIntoRanges(overlap.calendar_entries);
          calendarEntryRanges.forEach((calendarEntryRange) => {
            const splitDateTimeRange = calendarEntryRange.datetime_range.split('..');
            // We need to convert the date string into ISO 8601 format
            // This is needed for Safari, which apparently has more strict date parsing compared to something like Chrome
            // Otherwise it produces invalid dates on Safari and scheduler events do not render
            const rangeFromISO = splitDateTimeRange[0].replace(' UTC', '').replace(' ', 'T') + 'Z';
            const rangeToISO = splitDateTimeRange[1].replace(' UTC', '').replace(' ', 'T') + 'Z';
            const rangeFrom = dayjs(rangeFromISO).toDate();
            const rangeTo = dayjs(rangeToISO).toDate();

            events.push({
              id: `${userAvailability.user.id}_${overlap.work_order_name}_${rangeFrom}-${rangeTo}`,
              resourceId: overlap.work_order_id,
              start: rangeFrom,
              end: rangeTo,
              // title: overlap.work_order_name,
              title: `${userAvailability.user.lastName}, ${userAvailability.user.firstName}`,
              // bgColor: '#FEE1C4',
              // bgColor: '#C2C2C2',
            });
          });
        });
      }
    });
  }

  return { resources, events };
};


@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore')
@observer
class ResourceAvailability extends Component {
  constructor(props) {
    super(props);
    // const { employerWorkOrderStore, uiStore: { currentUser } } = this.props;
    this._dateRangePicker = React.createRef();

    const showCalendar = new URLSearchParams(window.location.search).get('calendar');

    this.state = {
      inputValue: '',
      availabilities: null,
      from: null,
      to: null,
      activeTabIndex: 0,
      showCalendar,
    };

    // this.handleDayChange = this.handleDayChange.bind(this);
  }

  componentDidMount() {
    const nextWeek = moment().add(1, 'weeks');
    const nextWeekStart = nextWeek.startOf('isoWeek').toDate();
    // The subtract hours is a stupid hack to get rid of the GMT+0200 that causes the last day to go past midnight (01:59:59) and actually return the incorrect date
    const nextWeekEnd = nextWeek.endOf('isoWeek').subtract(3, 'hours').toDate();
    // To make the default selection from Monday to Friday
    // const nextWeekEnd = nextWeek.day(5).toDate();
    const nextWeekNumber = nextWeek.isoWeek();

    this.setRangeAndWeek({ from: nextWeekStart, to: nextWeekEnd }, nextWeekNumber);
  }

  getAvailabilities(from, to) {
    const { showCalendar } = this.state;

    this.setState({
      isLoading: true,
    });

    // Need the loading spinner, but for that we need to think about promise handling. Compare with employerWorkOrderDetail.jsx invitation tab
    const { employerWorkOrderStore } = this.props;
    const availabilitiesPromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.getAvailabilitiesMultipleWeeks(null, from, to, resolve, reject)));
    when(
      () => availabilitiesPromise.state !== 'pending',
      () => {
        availabilitiesPromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.error('ERROR: ', err);
            this.setState({
              isLoading: false,
            });
          },
          fulfilled: (result) => {
            this.setState({
              availabilities: result,
            }, () => {
              // showCalendar
              if (true) {
                // Loading must be set to false in this method
                this.setSchedulerData();
              }
            });
          },
        });
      },
    );
  }

  getAvailabilitiesScheduler = (from, to, rejectedCb, fulfilledCb) => {
    const { t } = this.props;
    // Need the loading spinner, but for that we need to think about promise handling. Compare with employerWorkOrderDetail.jsx invitation tab
    const { employerWorkOrderStore } = this.props;
    const { currentResourceMode, schedulerData } = this.state;

    this.setState({
      isLoading: true,
    });

    const availabilitiesPromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.getAvailabilitiesMultipleWeeks(null, from, to, resolve, reject)));
    when(
      () => availabilitiesPromise.state !== 'pending',
      () => {
        availabilitiesPromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.error('ERROR: ', err);
            this.setState({
              isLoading: true,
            });
            if (rejectedCb) {
              rejectedCb();
            }
          },
          fulfilled: (result) => {
            console.log('FULFILLED');
            let reconstructedResults;
            // if (currentResourceMode === 'workOrders') {
            //   // We use the zero index here because the employerWorkOrderStore.getAvailabilitiesMultipleWeeks return the queried period + two next weeks
            //   // And we are only interested in the first period in the current demo
            //   reconstructedResults = constructWorkOrdersForScheduler(result[0]);
            // }

            if (currentResourceMode === 'users') {
              reconstructedResults = constructAvailabilitiesForScheduler(result[0], t);
              schedulerData.config.resourceName = 'Työntekijä';
            } else if (currentResourceMode === 'workOrders') {
              reconstructedResults = constructWorkOrdersForScheduler(result[0]);
              schedulerData.config.resourceName = 'Työ';
            }

            this.setState({
              isLoading: false,
            });
            if (fulfilledCb) {
              fulfilledCb(reconstructedResults);
            }
            return reconstructedResults;
          },
        });
      },
    );
  }

  setSchedulerResourceMode = (value) => {
    let leftCustomHeader;
    if (value === 'users') {
      leftCustomHeader = (
        <div style={{ cursor: 'pointer', padding: '10px', position: 'absolute', left: 0 }}>
          <WorkOutlineIcon
            onClick={() => this.setSchedulerResourceMode('workOrders')}
            className="MuiSvgIcon-root"
          />
        </div>
      );
    } else if (value === 'workOrders') {
      leftCustomHeader = (
        <div style={{ cursor: 'pointer', padding: '10px', position: 'absolute', left: 0 }}>
          <PeopleAltOutlinedIcon
            onClick={() => this.setSchedulerResourceMode('users')}
            className="MuiSvgIcon-root"
          />
        </div>
      );
    }

    this.setState({
      currentResourceMode: value,
      leftCustomHeader: leftCustomHeader,
    }, () => {
      this.changeSchedulerMode(value);
    });
  }

  changeSchedulerMode = (newResourceMode) => {
    const { t } = this.props;
    const { availabilities, schedulerData } = this.state;
    const firstWeekAvailabilities = availabilities[0];
    // const newSchedulerData = cloneDeep(schedulerData);

    let schedulerAvailabilities;
    if (newResourceMode === 'users') {
      schedulerAvailabilities = constructAvailabilitiesForScheduler(firstWeekAvailabilities, t);
      schedulerData.config.resourceName = 'Työntekijä';
    } else if (newResourceMode === 'workOrders') {
      schedulerAvailabilities = constructWorkOrdersForScheduler(firstWeekAvailabilities);
      schedulerData.config.resourceName = 'Työ';
    }

    // schedulerData.setResources(schedulerAvailabilities.resources);
    // schedulerData.setEvents(schedulerAvailabilities.events);
    schedulerData.setEventsAndResources(schedulerAvailabilities.events, schedulerAvailabilities.resources);

    // This is required to make the Scheduler update its content properly
    this.setState({
      schedulerData: schedulerData,
    });
  }

  customSetDate = (date, currentViewType) => {
    const { schedulerData } = this.state;
    console.log('TRYING TO SET DATE: ', date);
    console.log('Current view: ', currentViewType);

    if (currentViewType === 1) {
      // first day of week
      // last day of week
      // getAvailabilitiesScheduler
      // schedulerData.setEventsAndResources(res.events, res.resources);
      const firstDayOfWeek = date.weekday(1).format('YYYY-MM-DD');
      const lastDayOfWeek = date.weekday(7).format('YYYY-MM-DD');
      console.log('FIRST DAY OF WEEK: ', firstDayOfWeek);
      console.log('LAST DAY OF WEEK: ', lastDayOfWeek);
      this.getAvailabilitiesScheduler(firstDayOfWeek, lastDayOfWeek, null, (res) => {
        schedulerData.setDate(date);
        schedulerData.setEventsAndResources(res.events, res.resources);
      });
    } else if (currentViewType === 2) {
      // first day of month
      // last day of month
      // getAvailabilitiesScheduler
      // schedulerData.setEventsAndResources(res.events, res.resources);
      const firstDayOfMonth = date.startOf('month').format('YYYY-MM-DD');
      const lastDayOfMonth = date.endOf('month').format('YYYY-MM-DD');
      console.log('FIRST DAY OF MONTH: ', firstDayOfMonth);
      console.log('LAST DAY OF MONTH: ', lastDayOfMonth);
      this.getAvailabilitiesScheduler(firstDayOfMonth, lastDayOfMonth, null, (res) => {
        schedulerData.setDate(date);
        schedulerData.setEventsAndResources(res.events, res.resources);
      });
    }

    // This is required to make the Scheduler update its content properly
    this.setState({
      schedulerData: schedulerData,
    });
  }

  setSchedulerData() {
    const { t } = this.props;
    const { availabilities } = this.state;

    const firstWeekAvailabilities = availabilities[0];

    // eslint-disable-next-line new-cap
    const schedulerData = new SchedulerData(new dayjs(firstWeekAvailabilities.from).format(DATE_FORMAT), ViewType.Week);

    // This version uses Dayjs
    // schedulerData.setLocaleMoment(moment);
    // schedulerData.setSchedulerLocale('fi-fi');
    // schedulerData.setCalendarPopoverLocale('fi_FI');
    // schedulerData.localeDayjs = dayjs;
    schedulerData.setSchedulerLocale(dayjsLocaleFi);

    schedulerData.customSetDate = this.customSetDate;

    // This schedulerWidth is hacked into responsiveness in the Scheduler wrapper element (parent div ref.current.offsetWidth, updated on window resize)
    // schedulerData.config.schedulerWidth = '400';
    // schedulerData.config.schedulerWidth = '100%';
    // schedulerData.config.responsiveByParent = true;

    schedulerData.config.schedulerContentHeight = '100%';
    schedulerData.config.schedulerMaxHeight = 'calc(100vh - 200px)';
    schedulerData.config.besidesWidth = '255';
    schedulerData.config.nonAgendaDayCellHeaderFormat = 'HH:mm';
    schedulerData.config.nonAgendaOtherCellHeaderFormat = 'dd DD.MM.';
    schedulerData.config.resourceName = 'Työntekijä';
    schedulerData.config.nonWorkingTimeHeadColor = 'black';
    schedulerData.config.nonWorkingTimeHeadBgColor = 'inherit';
    schedulerData.config.nonWorkingTimeBodyBgColor = 'inherit';

    // At the time of writing, the popover doesn't work without an antd style import
    // However, only one imported antd stylesheet appears to be applied correctly (e.g. only 'antd/es/radio/style/css' or 'antd/es/popover/style/css', but not both?)
    schedulerData.config.eventItemPopoverEnabled = true;
    schedulerData.config.defaultEventBgColor = '#F6EDC6';
    schedulerData.config.calendarPopoverEnabled = false;
    const cellWidth = 40;
    schedulerData.config.monthCellWidth = cellWidth;
    schedulerData.config.quarterCellWidth = cellWidth;
    schedulerData.config.yearCellWidth = cellWidth;

    // Does not adjust the height of the calendar rows themselves, causing overlap
    // schedulerData.config.eventItemHeight = '45';
    schedulerData.config.views = [
      /* eslint-disable object-curly-newline */
      // { viewName: 'Päivä', viewType: ViewType.Day, showAgenda: false, isEventPerspective: false },
      {},
      { viewName: 'Viikko', viewType: ViewType.Week, showAgenda: false, isEventPerspective: false },
      { viewName: 'Kuukausi', viewType: ViewType.Month, showAgenda: false, isEventPerspective: false },
      // { viewName: 'Vuosineljännes', viewType: ViewType.Quarter, showAgenda: false, isEventPerspective: false },
      // { viewName: 'Vuosi', viewType: ViewType.Year, showAgenda: false, isEventPerspective: false },
      /* eslint-disable object-curly-newline */
    ];
    // schedulerData.config.minuteStep = 1;

    const schedulerAvailabilities = constructAvailabilitiesForScheduler(firstWeekAvailabilities, t);
    // const schedulerWorkOrders = constructWorkOrdersForScheduler(firstWeekAvailabilities, t);
    // console.log('Scheduler work orders: ', schedulerWorkOrders);
    // const schedulerAvailabilities = constructWorkOrdersForScheduler(firstWeekAvailabilities);

    // schedulerData.setResources(schedulerAvailabilities.resources);
    // schedulerData.setEvents(schedulerAvailabilities.events);
    schedulerData.setEventsAndResources(schedulerAvailabilities.events, schedulerAvailabilities.resources);

    const leftCustomHeader = (
      <div style={{ cursor: 'pointer', padding: '10px', position: 'absolute', left: 0 }}>
        <WorkOutlineIcon
          onClick={() => this.setSchedulerResourceMode('workOrders')}
          className="MuiSvgIcon-root"
        />
      </div>
    );

    this.setState({
      schedulerData,
      originalData: schedulerAvailabilities,
      leftCustomHeader,
      currentResourceMode: 'users',
      isLoading: false,
    });
  }

  closeDateRangePicker = () => {
    this._dateRangePicker.current.hideDayPicker();
  }

  customDateRangePickerOverlay = ({ classNames, children, ...props }) => (
    <div
      className={classNames.overlayWrapper}
      {...props}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }} className={classNames.overlay}>
        {children}
        <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
          <button type="button" className="accept-button mdc-dialog__button mdc-button" onClick={() => this.closeDateRangePicker()}>Valmis</button>
        </div>
      </div>
    </div>
  );

  setRangeAndWeek = (range, weekNumber = null, callback = null) => {
    const { t } = this.props;
    let finalValue = '';

    if (range.from && range.to) {
      const rangeFrom = moment(range.from);
      const rangeTo = moment(range.to);

      let weekNumberString = '';
      if (!weekNumber) {
        // User selected a single day, we need to find the week numbers manually
        const fromWeekNumber = rangeFrom.isoWeek();
        const toWeekNumber = rangeTo.isoWeek();
        // We do not want to add the '-' if there's no second different week number
        const toWeekNumberString = toWeekNumber && toWeekNumber !== fromWeekNumber ? `-${toWeekNumber}` : '';
        weekNumberString = `${t('resource_availability.week_prefix')}${fromWeekNumber + toWeekNumberString}`;
      } else {
        // User selected a week by clicking on the number
        weekNumberString = `${t('resource_availability.week_prefix')}${weekNumber}`;
      }

      const dayRange = `${rangeFrom.format('dd DD.MM.YYYY')} - ${rangeTo.format('dd DD.MM.YYYY')}`;
      finalValue = `${weekNumberString} | ${dayRange}`;
    } else {
      finalValue = 'Valitse päivät';
    }

    this.setState({
      inputValue: finalValue,
      from: range.from,
      to: range.to,
    }, () => {
      if (range.from && range.to) {
        const formattedFrom = moment(range.from).format('YYYY-MM-DD');
        const formattedTo = moment(range.to).format('YYYY-MM-DD');
        this.getAvailabilities(formattedFrom, formattedTo);
        if (callback) {
          callback();
        }
      }
    });
  }

  handleDayClick = (day) => {
    const range = DateUtils.addDayToRange(day, this.state);
    // this.setState(range);
    this.setRangeAndWeek(range);
  }

  handleWeekClick = (weekNumber, days) => {
    this.setRangeAndWeek({ from: days[0], to: days[6] }, weekNumber, this.closeDateRangePicker);
  }

  selectTab(index) {
    this.setState({ activeTabIndex: index });
  }

  renderListItem(category, uAvailability, index) {
    const { t, uiStore: { mobileMode } } = this.props;

    return (
      <>
        <ListItem
          key={index}
          className="availability-list-item sublist-heading"
          style={{
            overflow: 'visible',
          }}
        // onClick={() => {
        //   checked = !checked;
        //   this.handleInviteCheckbox(checked, uAvailability.user.id);
        // }}
        >
          {/* {canBeInvited.includes(category) && (
            <Checkbox
              checked={checked}
              readOnly
            />
          )} */}
          <div
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div>
              {`${uAvailability.user.lastName}, ${uAvailability.user.firstName}`}
            </div>
            {uAvailability.user.subcontractor && (
              <div
                style={{
                  fontSize: '11px',
                  textTransform: 'uppercase',
                  color: '#6A707D',
                  lineHeight: 1,
                  marginLeft: '10px',
                }}
              >
                {uAvailability.user.subcontractorAccountName || 'Alihankkija'}
              </div>
            )}
          </div>
        </ListItem>
        {uAvailability.overlappings && (
          <div style={{ margin: '0 5px 0 20px' }}>{renderOverlapRows(t, mobileMode, uAvailability.overlappings)}</div>
        )}
      </>
    );
  }

  renderDateRangePicker(label, required, styles, extraOverlayClassName = null) {
    const { from, to, inputValue } = this.state;
    const modifiers = { start: from, end: to };

    return (
      <DayPickerInput
        ref={this._dateRangePicker}
        value={inputValue}
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                required,
                style: { ...styles, textTransform: 'capitalize' },
                // onBlur: (event) => { updateStartTrip(directionTrip) }
              }}
            />
          )
        }
        inputProps={{
          ref: null, // This is needed to remove a "Function components cannot be given refs" warning
          className: '',
          style: { ...styles },
          name: 'date-range',
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker range-daypicker',
          showWeekNumbers: true,
          selectedDays: [from, { from, to }],
          modifiers,
          onDayClick: this.handleDayClick,
          onWeekClick: this.handleWeekClick,
          showOutsideDays: true,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        overlayComponent={this.customDateRangePickerOverlay}
        keepFocus={false}
        hideOnDayClick={false}
        format="dd DD.MM.YYYY"
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
        }}
      />
    );
  }

  renderListGroup(availabilityCollectionIndex, category) {
    const { availabilities } = this.state;
    const { t } = this.props;

    // negativeOverlaps.overlappings.available, date only has 1

    const colors = {
      maybeAvailable: { background: '#6A707D', font: '#FFFFFFF' },
      fullyAvailable: { background: '#FFC900', font: '#000000' },
      negativeOverlaps: { background: '#C2C2C2', font: '#000000' },
      accepted: { background: '#7CD715', font: '#000000' },
      rejected: { background: '#C2C2C2', font: '#000000' },
      noAnswer: { background: '#C2C2C2', font: '#000000' },
      unavailable: { background: 'red', font: '#000000' },
    };

    const availabilitiesByCategory = availabilities[availabilityCollectionIndex].availabilities[category];

    return (
      <Elevation
        z={5}
        style={{ borderRadius: '0px 0px 3px 3px' }}
      >
        <List
          style={{
            marginTop: '10px',
            color: 'black',
            padding: '0px',
            marginBottom: '20px',
            // minHeight: '250px',
          }}
        >
          <ListGroup>
            <ListGroupSubheader
              style={{
                backgroundColor: colors[category].background,
                color: colors[category].font,
                margin: '0px',
                padding: '12px 16px',
                fontWeight: '500',
                fontSize: '14px',
              }}
            >
              <span>
                {t(`work_order.availability.${category}`)}
              </span>
            </ListGroupSubheader>

            {availabilitiesByCategory.map((uAvailability, index) => (
              <React.Fragment
                key={`${category}-${uAvailability.user.id}`}
              >
                {this.renderListItem(category, uAvailability, index)}
                {/* <ListDivider /> */}
                {index !== availabilitiesByCategory.length - 1 && <ListDivider />}
              </React.Fragment>
            ))}
            {availabilitiesByCategory.length === 0 && (
              <div
                className="sublist-heading"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Ei tuloksia
              </div>
            )}
          </ListGroup>
        </List>
      </Elevation>
    );
  }

  renderAvailabilityCollection(collectionIndex) {
    const { uiStore: { mobileMode } } = this.props;
    const { availabilities } = this.state;
    return (
      availabilities && (
        <GridCell
          desktop={4}
          tablet={8}
          phone={4}
        >
          {!mobileMode && (
            <div
              style={{
                fontSize: '22px',
                fontWeight: 'bold',
                marginBottom: '20px',
                textTransform: 'capitalize',
              }}
            >
              {`VK${availabilities[collectionIndex].week_numbers} | ${moment(availabilities[collectionIndex].from).format('dd DD.MM.YYYY')} - ${moment(availabilities[collectionIndex].to).format('dd DD.MM.YYYY')}`}
            </div>
          )}
          {this.renderListGroup(collectionIndex, 'fullyAvailable')}
          {this.renderListGroup(collectionIndex, 'maybeAvailable')}
          {this.renderListGroup(collectionIndex, 'negativeOverlaps')}

          {/* MAYBE ADD THESE */}
          {/* {this.renderListGroup('rejected', { styles })}
          {this.renderListGroup('noAnswer', { styles })} */}
        </GridCell>
      )
    );
  }

  renderResourcesView() {
    const { t, uiStore: { mobileMode } } = this.props;
    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      maxWidth: '400px',
    };

    return (
      <>
        <Grid
          style={{
            padding: '16px',
            width: '100%',
          }}
        >
          <GridCell
            desktop={12}
            tablet={8}
            phone={4}
          >
            {this.renderDateRangePicker(t('resource_availability.date_range'), true, styles)}
          </GridCell>

          {this.renderAvailabilityCollection(0)}
          {!mobileMode && this.renderAvailabilityCollection(1)}
          {!mobileMode && this.renderAvailabilityCollection(2)}
        </Grid>
      </>
    );
  }

  renderResourceCalendarView() {
    const { schedulerData, originalData, isLoading, leftCustomHeader } = this.state;

    return (
      // Overflow: hidden is used to prevent view's horizontal scrolling, caused by the schedule to be too wide
      <div style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
        {schedulerData && (
          <BasicResourceCalendarExample
            originalData={originalData}
            schedulerData={schedulerData}
            queryData={this.getAvailabilitiesScheduler}
            isLoading={isLoading}
            loadingSpinner={(
              <div style={{ position: 'relative', padding: '20px', textAlign: 'center', zIndex: '99' }}>
                <LoadingSpinner color="black" />
              </div>
            )}
            leftCustomHeader={leftCustomHeader}
          />
        )}
        {/* {leftCustomHeader} */}
      </div>
    );
  }

  render() {
    const { activeTabIndex, showCalendar } = this.state;

    return (
      <>
        {showCalendar ? (
          <>
            <div
              className="view-heading"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <span
                  role="button"
                  onClick={() => this.selectTab(0)}
                  className={`heading-tab ${activeTabIndex === 0 ? 'active' : 'inactive'}`}
                  style={{ marginLeft: 0 }}
                >
                  Resurssit
                </span>
                <span role="button" onClick={() => this.selectTab(1)} className={`heading-tab ${activeTabIndex === 1 ? 'active' : 'inactive'}`}>
                  Kalenteri
                </span>
              </div>
            </div>

            {activeTabIndex === 0 && (
              this.renderResourcesView()
            )}

            {activeTabIndex === 1 && (
              this.renderResourceCalendarView()
            )}
          </>
        ) : (
          <>
            <div className="view-heading">
              <span>Resurssit</span>
            </div>
            {this.renderResourcesView()}
          </>
        )}
      </>
    );
  }
}

export default ResourceAvailability;
