/* eslint-disable react/destructuring-assignment */
/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import actionCable from 'actioncable';
import {
  CollapsibleList,
  SimpleListItem,
} from '@rmwc/list';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Card,
  CardPrimaryAction,
  CardActionButton,
  CardActions,
  CardActionButtons,
} from '@rmwc/card';
import { Typography } from '@rmwc/typography';
import { Switch } from '@rmwc/switch';
import { ThemeProvider } from '@rmwc/theme';
import { Icon } from '@rmwc/icon';
import { IconButton as RMWCIconButton } from '@rmwc/icon-button';
import { TextField } from '@rmwc/textfield';
import { isEqual } from 'lodash';

import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import '@material/list/dist/mdc.list.css';
import '@material/checkbox/dist/mdc.checkbox.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/switch/dist/mdc.switch.css';
import '@material/tab-bar/dist/mdc.tab-bar.css';
import '@material/tab-indicator/dist/mdc.tab-indicator.css';
import '@material/tab-scroller/dist/mdc.tab-scroller.css';
import '@material/tab/dist/mdc.tab.css';
import '@rmwc/data-table/data-table.css';
import '@rmwc/icon/icon.css';
import '@rmwc/list/collapsible-list.css';

// import TimelogRow from './timelog-row';
// import WorkOrderItem from '../calendar/WorkOrderItem.jsx';
import { buttonColors, buttonStyle } from '../calendar/EmployeeCalendar.js';
import moment, { combinedDateTime, getInterValDays } from '../utils/moment.js';
import TimelogViewStore from '../timelog/TimelogViewStore.js';
import AbsenceEntryRow from './absence-entry-row.js';
// import iconDailyAllowance from '../assets/images/daily_allowance.svg';
// import iconDailyAllowanceActive from '../assets/images/daily_allowance_active.svg';
// import iconExtras from '../assets/images/extra_allowance.svg';
// import iconExtrasActive from '../assets/images/extra_allowance_active.svg';
// import Attachments from '../timelog/TimelogAttachments';
// import TimelogRoute from './timelog-route';
// import TimelogTripDialog from './timelog-trip-dialog.js'
import AbsencePeriod from '../models/AbsencePeriod.js';
import AbsenceEntryDialog from './absence-entry-dialog.js';

const columnWidths = { poissaolo: '15%' };
const defaultHeaderStyle = { width: '16.5%', color: 'var(--mdc-theme-primary)' };

@inject('uiStore', 'absenceStore', 't')
@observer
class AbsencePeriodView extends Component {
  cableApp = {};

  constructor(props) {
    super(props);

    this.state = {
      activeTabById: {},
      dialogOpen: false,
      // workOrderTrip: foundWorkOrderTripForThisWorkOrder || [],
      routeDialogOpen: false,
      tripDialogOpen: false,
      cloneTripDialogOpen: false,
      routeItem: '',
      tripItem: '',
      currentKmsType: null,
      // onGoingTrip: foundOnGoingTrip || null,
      // endedTrips: foundEndedTrips || [],
      maxEndDate: null,
      tripDisabledDialogOpen: false,
      tripDisabledDialogOverlappingTrips: [],
      collapsiblesOpen: {},
      currentAbsenceEntry: null,
      absenceEntryDialogOpen: false,
    };
    this.store = new TimelogViewStore();

    // if (props.initialTabIndex) {
    //   this.state.activeTabById[props.workOrder.id] = props.initialTabIndex;
    // }
    // const activeTab = initialTabIndex ? initialTabIndex : (activeTabById[workOrder.id] || 0);
  }

  getHeader = (columns, tab = null) => columns.map((text) => this.getHeaderTitle(
    text,
    text,
    {
      borderBottomWidth: '1px',
      borderTopWidth: 0,
      fontSize: '10px',
      lineHeight: '1rem',
      width: columnWidths[text] ? columnWidths[text] : '16.5%',
      // width: '17.5%',
    },
    tab,
  ));

  getFirstColumn = (text, ctx, tab) => {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    return (
      <div
        className={`pk-multiline-header sticky-left ${((tab === 1 && (accountId === 43 || accountId === 1)) || tab === 3) ? "mobile-column" : ""}`}
        style={{
          // color: 'var(--mdc-theme-primary)',
          fontSize: '10px',
          lineHeight: '1rem',
          padding: 0,
          textAlign: 'center',
          textTransform: 'uppercase',
          ...ctx,
        }}
      >
        {text}
      </div>
    );
  };

  getHeaderTitle = (text, name = text, styles = {}, tab = null) => {
    const { uiStore: { currentUser: { accountId } } } = this.props;
    return (
      <div
        className={
          `pk-multiline-header
          ${((tab === 1 && (accountId === 43 || accountId === 1)) || tab === 3) ? 'mobile-column' : ''}
          ${text === 'päiväraha' ? 'allowance-column' : ''}
          ${text === 'työmatka' ? 'work-order-trip-column' : ''}`
        }
        key={`tbheader-${name}`}
        style={{
          borderBottomWidth: 0,
          // color: 'var(--mdc-theme-primary)',
          fontSize: '1rem',
          lineHeight: '2rem',
          padding: accountId === 1 ? '0 1px' : 0,
          textAlign: 'center',
          textTransform: 'uppercase',
          ...styles,
        }}
      >
        {text}
      </div>
    );
  };

  renderTopActions() {
    const { absenceStore, absencePeriod } = this.props;
    const buttonStyles = { ...buttonStyle, ...buttonColors.yellow };

    let buttons = (
      <>
        <CardActionButtons
          style={{
            flexDirection: 'column',
          }}
        >
          <CardActionButton
            {...{
              onClick: () => {
                // timelogStore.accept(workOrder, uiStore.currentUser, from, to);
                // store.setShowDraftsOnly(false);
                absenceStore.employeeAcceptAbsencePeriod(absencePeriod.id);
              },
              outlined: true,
              style: buttonStyles,
            }}
          >
            {/* {t('timelog.acceptance.accept')} */}
            Merkitse valmiiksi
          </CardActionButton>
        </CardActionButtons>

        {/* <CardActionButtons
          style={{
            flexDirection: 'column',
            marginLeft: '20px',
          }}
        >
          <IconButton onClick={() => openEditDialog(absencePeriod)}>
            <Icon icon="edit" role="button" style={{ color: 'white' }} />
          </IconButton>
        </CardActionButtons> */}
      </>
    );

    return (
      <Card>
        {/* <Typography {...{
          use: 'body1',
          tag: 'p',
          style: {
            margin: '0 1rem 0 1rem',
            padding: '0 0 0 1rem',
          },
        }}
        >
          Hyväksy poissaolot
        </Typography> */}
        <CardPrimaryAction
          style={{
            flexDirection: 'row',
            // justifyContent: 'space-between',
            justifyContent: 'end',
            margin: '0 1rem 0 1.5rem',
          }}
        >
          {/* <CardActions
            style={{
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <ThemeProvider
              options={{
                primaryOnBackground: 'white',
                secondary: buttonColors.yellow.color,
                textPrimaryOnBackground: 'var(--mdc-theme-text-hint-on-dark, rgba(255, 255, 255, 0.5)) !important',
              }}
            >
              <Switch
                className="pk-switch"
                checked={store.showDraftsOnly}
                onChange={(evt) => store.setShowDraftsOnly(evt.currentTarget.checked)}
                label={t('timelog.acceptance.showDraftsOnly')}
                use="text-hint-on-dark"
              />
            </ThemeProvider>

          </CardActions> */}
          <CardActions>{buttons}</CardActions>
        </CardPrimaryAction>
      </Card>
    );
  }

  openAbsenceEntryDialog = (entry) => {
    this.setState({
      currentAbsenceEntry: entry,
      absenceEntryDialogOpen: true,
    });
  }

  closeAbsenceEntryDialog = () => {
    this.setState({
      currentAbsenceEntry: null,
      absenceEntryDialogOpen: false,
    });
  }

  handleDeleteAbsenceEntry = (absenceEntry) => {
    const { absenceStore } = this.props;

    absenceStore.deleteAbsenceEntry(absenceEntry).then(() => {
      this.closeAbsenceEntryDialog();
    }).catch((err) => {
      console.error(err);
    });
  }

  render() {
    const { absencePeriod, disabled, periodId } = this.props;
    const { currentAbsenceEntry, absenceEntryDialogOpen } = this.state;

    const flatWorkDays = new Set();
    absencePeriod.absenceEntries.forEach((absenceEntry) => {
      // Assuming a single day calendarEntry
      flatWorkDays.add(absenceEntry.calendarEntry.from.format('DD.MM.YY'));
    });

    const {
      uiStore: { currentUser, currentUser: { accountId, accountInfo, absenceTypes } },
      // productStore,
      // allowanceAutofillReminder,
      // employerUpdateMode = false,
      // initialTabIndex,
      // employerContextTripFrom,
      // employerContextTripTo,
      // employerContextTripId,
      // highlightWorkHourId,
      openEditDialog,
    } = this.props;
    const { collapsiblesOpen } = this.state;

    // let activeWorkHours = workOrder.workHours.filter((item) => (
    //   // Filter by salary period + trip in employer context
    //   // ((employerUpdateMode && employerContextTripFrom && employerContextTripTo) ? item.date.isBetween(employerContextTripFrom,  employerContextTripTo, null, '[]') : item.date.isBetween(from, to, null, '[]'))
    //   item.date.isBetween(from, to, null, '[]')
    //   &&
    //   flatWorkDays.has(item.date.format('DD.MM.YY'))
    //   &&
    //   (store.showDraftsOnly ? item.isDraft() : true)
    //   &&
    //   ((employerUpdateMode && store.showEmployerUpdatedOnly) ? item.updatedByEmployer : true)
    // ));

    // const employerUpdatedWorkHours = employerUpdateMode ? activeWorkHours.filter((hour) => hour.updatedByEmployer) : [];

    // const activeTab = initialTabIndex ? initialTabIndex : (activeTabById[workOrder.id] || 0);
    // const activeTab = activeTabById[workOrder.id] || 0;
    const activeTab = 0;
    // const summary = getSummary(workOrder.workHourType, activeWorkHours, accountId);
    // const summary = {};

    const sortedAndFilteredAbsenceEntries = absencePeriod.absenceEntries.filter((entry) => !entry.deletedAt).sort((a, b) => (a.calendarEntry.from.valueOf() - b.calendarEntry.from.valueOf()));

    const smallestEntryDate = sortedAndFilteredAbsenceEntries.reduce((min, absenceEntry) => {
      return moment.min(min, absenceEntry.calendarEntry.from);
    }, moment().add(100, 'years'));  // Initializing with a far future date

    const greatestEntryDate = sortedAndFilteredAbsenceEntries.reduce((max, absenceEntry) => {
      return moment.max(max, absenceEntry.calendarEntry.from);
    }, moment().subtract(100, 'years'));  // Initializing with a far past date

    const numberOfDays = sortedAndFilteredAbsenceEntries.length; // getInterValDays(moment.interval(smallestEntryDate, greatestEntryDate)).length;

    // Here, we want to loop through the value templates and get the labels
    // Attempt to translate: if not found, use the string itself
    const columnHeaders = ['poissaolo'];
    const hasDrafts = absencePeriod.absenceEntries.find((absenceEntry) => absenceEntry.status === 'draft');

    return (
      <>
        <CollapsibleList
          key={absencePeriod.id}
          style={{
            backgroundColor: '#0a131e',
            marginTop: '1rem',
            maxWidth: '100vw',
          }}
          onOpen={() => {
            this.setState((prevState) => ({
              collapsiblesOpen: { ...prevState.collapsiblesOpen, [absencePeriod.id]: 'open' },
            }));
          }}
          onClose={() => {
            this.setState((prevState) => ({
              collapsiblesOpen: { ...prevState.collapsiblesOpen, [absencePeriod.id]: 'closed' },
            }));
          }}
          handle={(
            <SimpleListItem
              // metaIcon="chevron_right"
              style={{
                backgroundColor: 'var(--mdc-theme-surface, #fff)',
                flexDirection: 'row-reverse',
                height: 'fit-content',
                // borderLeft: '1px solid #FFC900',
                // marginLeft: '20px',
              }}
            >
              {/* <WorkOrderItem {...{
                item: workOrder,
                key: `woi-${workOrder.id}-${workOrder.updatedAt.valueOf()}`,
                skipDescription: true,
                tripRejected: tripRejected,
                workHourRejected: workHourRejected,
                useGap: false,
                timelogViewTab: activeTab,
                view: 'timelogview',
              }}
              /> */}
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div
                    style={{ fontSize: '16px', padding: '20px 20px 10px 0' }}
                  >
                    <span>{absencePeriod.absenceType.nickname}</span>
                    <span
                      style={{
                        display: 'flex',
                        fontSize: '14px',
                        color: '#FFFFFF80',
                      }}
                    >
                      <span style={{ textTransform: 'capitalize' }}>
                        {`${smallestEntryDate.format('dd DD.MM.YY')} - ${greatestEntryDate.format('dd DD.MM.YY')}`}
                      </span>
                      &nbsp;
                      <span>
                        {`(${numberOfDays} pv)`}
                      </span>
                    </span>
                  </div>

                  {collapsiblesOpen[absencePeriod.id] === 'open' && (
                    <IconButton
                    style={{ paddingBottom: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openEditDialog(absencePeriod);
                      }}
                    >
                      <Icon icon="edit" role="button" style={{ color: 'white' }} />
                    </IconButton>
                  )}
                </div>

                <div style={{ width: '100%', padding: '0 20px 0 0', textAlign: 'center' }}>
                  {collapsiblesOpen[absencePeriod.id] === 'closed' ? (
                    <Icon icon="keyboard_arrow_down" style={{ color: '#FFFFFF80' }} />
                  ) : (
                    <Icon icon="keyboard_arrow_up" style={{ color: '#FFFFFF80' }} />
                  )}
                </div>
              </div>
              {/* {locked && <Icon icon="lock_outline" style={{ color: buttonColors.orange.color }} />} */}
            </SimpleListItem>
          )}
        // open={employerUpdateMode || undefined}
        >
          {hasDrafts && this.renderTopActions()}
          {/* {(!employerUpdateMode && disabled !== true && summary.hasUnacceptedHours) && this.renderAcceptance(workOrder)}
          {(employerUpdateMode && summary.hasUpdatedByEmployer) && this.renderAcceptanceEmployer()} */}
          {/* <TabBar
            activeTabIndex={activeTab}
            onActivate={(evt) => {
              // Load the products when changing to products tab
              if (evt.detail.index === 5 && currentUser.accountInfo.productsEnabled) {
                productStore.findProducts();
                productStore.findUserProductCollections(currentUser);
              }
              this.setState({
                activeTabById: {
                  ...activeTabById,
                  [workOrder.id]: evt.detail.index,
                },
              });
            }}
          >
            <Tab icon="access_time" />
            <Tab icon="directions_car" />
            <Tab className="pro-icon" icon={activeTab === 2 ? iconDailyAllowanceActive : iconDailyAllowance} />
            <Tab className="pro-icon" icon={activeTab === 3 ? iconExtrasActive : iconExtras} />
            <Tab icon="description" />
            {accountInfo.productsEnabled && <Tab icon="list_alt" />}
          </TabBar> */}

          <div>
            <div
              style={{ backgroundColor: 'var(--mdc-theme-surface, #fff)', width: '100%' }}
              theme="textPrimaryOnDark"
            >
              {/* {activeTab === 4 && (
                <Attachments
                  disabled={disabled}
                  data={attachments}
                  inputRef={React.createRef()}
                  periodId={periodId}
                  t={t}
                  timelogStore={timelogStore}
                  userId={uiStore.currentUser.id}
                  workOrder={workOrder}
                />
              )} */}

              <div
                className="timelog-fake-table"
                style={{ paddingTop: '10px', paddingBottom: '10px' }}
              >
                <div
                  className={`${((activeTab === 1 && (accountId === 43 || accountId === 1)) || activeTab === 3) ? "mobile-fit-content" : ""}`}
                  style={{
                    display: 'flex', marginTop: '5px', marginBottom: '5px', fontWeight: '600',
                  }}
                >
                  {this.getFirstColumn(' \npäivä', { borderTop: 0, width: '17.5%' }, activeTab)}
                  {activeTab === 0 && this.getHeader(columnHeaders)}
                  {/* {activeTab === 1 && this.getHeader(this.getTabOneHeaders(), activeTab)}
                  {activeTab === 2 && this.getHeader(this.getTabTwoHeaders())}
                  {activeTab === 3 && this.getHeader(this.getTabThreeHeaders(), activeTab)}
                  {activeTab === 4 && this.getHeader(['lisätieto'])} */}
                  {/* DEACTIVATED PRODUCTS */}
                  {/* TODO: Hardcoded to account ids 10 and 3 (test accounts) */}
                  {/* {activeTab === 5 && accountInfo.productsEnabled && this.getHeader(['tuotteet'])} */}
                </div>

                <div
                  style={{
                    width: '100%',
                  }}
                >
                  {sortedAndFilteredAbsenceEntries.map((absenceEntry, _index) => {
                    return (
                      <AbsenceEntryRow
                        activeTab={activeTab}
                        disabled={disabled}
                        item={absenceEntry}
                        key={`absence-row-${absenceEntry.id}`}
                        // employerAcceptance={workOrder.employerAcceptance}
                        absencePeriod={absencePeriod}
                        absenceTypes={absenceTypes}
                        openAbsenceEntryDialog={this.openAbsenceEntryDialog}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </CollapsibleList>

        <AbsenceEntryDialog
          open={absenceEntryDialogOpen}
          absenceEntry={currentAbsenceEntry}
          onClose={this.closeAbsenceEntryDialog}
          handleDeleteAbsenceEntry={this.handleDeleteAbsenceEntry}
        />
      </>
    );
  }
}

export default AbsencePeriodView;
