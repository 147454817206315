import { action, observable, set } from 'mobx';
import moment from '../utils/moment';
// import TripRoute from './TripRoute';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

// const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class TaxExemptTripExpense {
  @observable id;

  // @observable createdAt;

  // @observable updatedAt;

  @observable status;

  @observable name;

  @observable value;

  @observable employerComment;

  // @observable tripRoutes;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      name: o.name,
      value: o.value,
      // trip_routes_attributes: o.tripRoutes?.map((tripRoute) => TripRoute.toJson(tripRoute)),
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  //
  // NOTE: Shouldn't need to be to be used now, the tax exempt trip expenses are packaged into the work hour/timelog
  //
  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      name: o.name,
      value: o.value,
      status: o.status,
      employerComment: o.employer_comment,
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const taxExemptTripExpense = new TaxExemptTripExpense({});
    taxExemptTripExpense.updatePropertiesFromJson(object);
    return taxExemptTripExpense;
  }

  static fromJson(json) {
    return TaxExemptTripExpense.fromJsonProperties(JSON.parse(json));
  }
}
