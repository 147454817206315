import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { fromPromise } from 'mobx-utils';
// import { when } from 'mobx';
import {
  List,
  ListItem,
} from '@rmwc/list';
import { Select } from '@rmwc/select';
import { TextField } from '@rmwc/textfield';
// import { Checkbox } from '@rmwc/checkbox';
import '@rmwc/avatar/avatar.css';
import { Typography } from '@rmwc/typography';
import {
  Dialog,
  DialogContent,
  DialogButton,
  DialogActions,
} from '@rmwc/dialog';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';

import { Checkbox, FormControlLabel } from '@material-ui/core';

import { isEqual, get } from 'lodash';

import UserValidities from '../profile/validities/userValidities';
import { addAttachedCard, addCollapsibleCard } from '../profile/cards/card';
import { addProfilePicture } from '../models/user/user-ui-avatar';
import { appcontentsize, w100 } from '../styles/inline-styles';
import User, { mandatoryCardTypes } from '../models/User';
import ConfirmDeleteDialog from '../shared/ConfirmDeleteDialog';
import FilePreviewDialog from '../shared/FilePreviewDialog';
import '../profile/profile.css';
import './EmployeeDialog.css';
// import { fileInput } from '../registration';

@inject('uiStore', 'employerUserStore', 'loginStore', 'validityStore', 't')
@observer
class EmployeeProfile extends Component {
  constructor(props) {
    super();
    this.form = React.createRef();
    this.profileInputRef = React.createRef();
    const { employee } = props;

    this.state = {
      employee: new User({ details: { clothing: { hasHelmet: false } }, ...employee }),
      activeTab: 0,
      deletingFile: null,
      showDeleteDialog: false,
      showCardDialog: false,
      cardDialogUrl: null,
    };
  }

  componentDidMount() {
    this.hackSaveOriginalData();
  }

  componentDidUpdate(prevProps) {
    const { employee } = this.props;
    const prevEmployee = prevProps.employee;

    if (!isEqual(employee, prevEmployee)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        employee: new User({ details: { clothing: { hasHelmet: false } }, ...employee }),
      });
    }
  }

  confirmDelete = (entity) => {
    this.setState({ showDeleteDialog: true, deletingFile: entity });
  }

  openCard = (imageUrl) => {
    this.setState({
      showCardDialog: true,
      cardDialogUrl: imageUrl,
    });
  }

  deleteFile = () => {
    const { employerUserStore, employee: employeeProp } = this.props;
    const { deletingFile, employee } = this.state;

    switch (deletingFile) {
      // Attached
      case 'taxIncomeCardUrl':
        employerUserStore.purgeOtherTaxIncomeCard(employee);
        this.save();
        break;
      case 'helmetImageUrl':
        employerUserStore.purgeOtherHelmetImage(employee);
        this.save();
        break;
      // Detail cards
      // case 'firstAidTraining':
      //   uiStore.purgeFirstAidTrainingImage();
      //   this.save();
      default:
        /* Assuming a JSONified card (unattached blob) */
        employerUserStore.purgeOtherCard(employee, deletingFile).then(() => {
          // this.save();
          employeeProp.purgeDetailCardProp(deletingFile);
        });
    }
  }

  closeConfirmDeleteDialog = () => {
    this.setState({ showDeleteDialog: false });
  }

  closeFilePreviewDialog = () => {
    this.setState({
      showCardDialog: false,
      cardDialogUrl: null,
    });
  }

  save() {
    const { employerUserStore } = this.props;
    const { employee } = this.state;

    employerUserStore.updateOtherUser(employee)
      .then((res) => {
        console.log('Updated user: ', res);
      });
    // .then(() => this.hackSaveOriginalData())
    // .catch(() => {
    //   /* noop */
    // });
  }

  // HACK: suboptimal solution to allow evaluation whether to update user data
  hackSaveOriginalData() {
    // const { props: { uiStore: { currentUser } } } = this;
    const { employee } = this.state;
    this.__original__ = JSON.parse(JSON.stringify(employee));
  }

  renderTextField(attr, props = {}) {
    const {
      t,
      inputStyles,
      mode,
      setUserChanged,
      errors,
    } = this.props;
    const { employee } = this.state;

    return (
      <div style={{ width: '100%' }} className={props.required && 'required-text-field-wrapper'}>
        <TextField
          {...props}
          // A hack to nullify the prop.classNames in employer-dialog mode
          className={mode === 'employer-dialog' ? null : props.className}
          style={inputStyles}
          key={attr}
          label={t(`profile.labels.${attr}`)}
          onChange={(event) => {
            // currentUser[attr] = event.target.value; // eslint-disable-line no-param-reassign
            employee.changeAttribute(attr, event.target.value);
            setUserChanged(employee);
          }}
          rootProps={{ ripple: false }} // HACK: to avoid ripple causing synthetic event console warnings
          // Supports nested attrs, e.g. "currentAccount.extid"
          // get() has a parameter for default value but it is only used if the result is undefined. Null is returned normally
          value={get(employee, attr) || ''}
          helpText={{
            persistent: true,
            validationMsg: true,
            children: errors && errors[attr],
          }}
        />
      </div>
    );
  }

  // renderCardDialog() {
  //   const { t } = this.props;
  //   const { showCardDialog, cardDialogUrl } = this.state;

  //   return (
  //     <Dialog
  //       open={showCardDialog}
  //       onClose={() => {
  //         this.setState({ showCardDialog: false, cardDialogUrl: null });
  //         // switch (evt.detail.action) {
  //         //   case 'delete': {
  //         //     this.setState({ showDeleteDialog: true });
  //         //     break;
  //         //   }
  //         //   case 'sendsms': {
  //         //     this.sendSMSMessage();
  //         //     break;
  //         //   }
  //         //   default: {
  //         //     /* noop */
  //         //   }
  //         // }
  //       }}
  //       className="trip-route-dialog"
  //     >
  //       {/* <DialogTitle></DialogTitle> */}
  //       <DialogContent className="xl-buttons">
  //         <img
  //           style={{ width: '100%' }}
  //           src={cardDialogUrl}
  //           alt="card"
  //         />
  //       </DialogContent>
  //       <DialogActions>
  //         <DialogButton
  //           action="close"
  //           className="cancel-button"
  //         >
  //           {t('messages.dialogDeleteButtonCancel')}
  //         </DialogButton>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // }

  renderCardDialog() {
    const { employee, showCardDialog, cardDialogUrl } = this.state;
    const fileName = `${employee.lastName?.toLowerCase()}_${employee.firstName?.toLowerCase()}`;
    // if (cardDialogUrl) {
    //   const fileNameSplit = cardDialogUrl?.split('.');
    //   fileName = fileNameSplit[fileNameSplit.length - 2];
    // }

    return (
      <FilePreviewDialog
        open={showCardDialog}
        fileURL={cardDialogUrl}
        fileName={fileName}
        onClose={this.closeFilePreviewDialog}
      />
    );
  }

  renderPersonalInfo() {
    const {
      form,
      t,
      setUserChanged,
      uiStore: { currentUser, currentUser: { accountInfo: { salaryPeriodCategoriesEnabled, salaryPeriodCategories } } },
      readOnlyMode,
    } = this.props;
    const { employee } = this.state;

    return (
      <form {...{
        className: 'employee-profile no-list-item-ripple-anim', // theme-dark
        onSubmit: (e) => e.preventDefault(),
        ref: form,
        style: {
          ...appcontentsize,
          marginBottom: '1em',
          width: '25wv',
        },
      }}
      >
        <List>
          <Typography
            style={w100}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            Perustiedot
          </Typography>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {/* A quick and dirty way to position the pic slightly to the right without affecting the name input widths */}
            <div style={{ margin: 'auto', marginRight: '10px' }}>
              {addProfilePicture(employee, this.profileInputRef, employee?.personalPictureUrl, t, 'employee-profile')}
            </div>
            {this.renderTextField('firstName', { className: 'ml-1 profile-input-75-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('lastName', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('email', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('socialSecurityNumber', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('phoneNumber', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          {!employee.id && (
            <>
              <ListItem style={{ marginTop: '1em', height: 'auto' }}>
                {this.renderTextField(
                  'password',
                  {
                    className: 'ml-1 profile-input-95-view-width disabled-profile-input',
                    disabled: readOnlyMode,
                    type: 'password',
                    required: true,
                  },
                )}
              </ListItem>

              <ListItem style={{ marginTop: '1em', height: 'auto' }}>
                {this.renderTextField(
                  'passwordConfirmation',
                  {
                    className: 'ml-1 profile-input-95-view-width disabled-profile-input',
                    disabled: readOnlyMode,
                    type: 'password',
                    required: true,
                  },
                )}
              </ListItem>
            </>
          )}

          <Typography
            style={w100}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            Käyttöoikeudet
          </Typography>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {/* NOTE: Not yet compatible with multi-account employers */}
            <Select
              style={{ top: 'auto !important' }}
              {...{
                className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom label-style', // mdc-theme--text-primary-on-dark
                label: 'Käyttöoikeudet',
                onBlur: () => {
                  if (this.__original__.role !== employee.role) {
                    setUserChanged(employee);
                  }
                },
                onChange: (event) => {
                  employee.role = event.target.value; // eslint-disable-line no-param-reassign
                },
                onInput: (event) => {
                  if (this.__original__.role !== event.target.value) {
                    employee.role = event.target.value; // eslint-disable-line no-param-reassign
                    setUserChanged(employee);
                  }
                },
                // defaultChecked: this.__original__?.role || '-',
                options: {
                  employee: 'Työntekijä', employer: 'Työnjohtaja',
                },
                rootProps: { ripple: false },
                value: employee.role,
                disabled: !readOnlyMode ? !currentUser.hasPermission('user', 'set_employership') || employee?.currentAccount.subcontractor : readOnlyMode,
              }}
            />
          </ListItem>

          {/* ACTIVE */}
          <ListItem
            style={{ display: 'flex', margin: '15px' }}
          >
            <FormControlLabel
              label={t('profile.labels.active')}
              control={(
                <Checkbox {...{
                  className: 'employer-checkbox',
                  disabled: !readOnlyMode ? !currentUser.hasPermission('user', 'set_active') || employee?.id === currentUser?.id : readOnlyMode,
                  checked: employee?.currentAccount.active || false,
                  onChange: (event) => {
                    employee.currentAccount.active = event.target.checked;
                    setUserChanged(employee);
                  },
                }}
                />
              )}
            />
          </ListItem>

          <Typography
            style={w100}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            Yhteystiedot
          </Typography>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('street', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('zipCode', { type: 'number', className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('city', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('iceName', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('iceNumber', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>
          {/* eslint-disable react/jsx-wrap-multilines */}

          <Typography
            style={w100}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            Hallinto
          </Typography>

          {/* SUBCONTRACTOR BOOLEAN */}
          <ListItem
            style={{ display: 'flex', margin: '15px' }}
          >
            <FormControlLabel
              label={t('profile.labels.subcontractor')}
              control={(
                <Checkbox {...{
                  className: 'employer-checkbox',
                  disabled: !readOnlyMode ? !currentUser.hasPermission('user', 'set_subcontractor') : readOnlyMode,
                  checked: employee?.currentAccount.subcontractor || false,
                  onChange: (event) => {
                    employee.currentAccount.subcontractor = event.target.checked;
                    setUserChanged(employee);
                  },
                }}
                />
              )}
            />
          </ListItem>

          {/* SUBCONTRACTOR COMPANY */}
          {employee?.currentAccount.subcontractor && (
            <ListItem
              style={{ display: 'flex' }}
            >
              <Select
                {...{
                  className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom', // mdc-theme--text-primary-on-dark
                  label: 'Alihankkijayritys',
                  onBlur: () => {
                    if (this.__original__.role !== employee.role) {
                      setUserChanged(employee);
                    }
                  },
                  onChange: (event) => {
                    employee.currentAccount.subcontractor_account_id = event.target.value; // eslint-disable-line no-param-reassign
                  },
                  onInput: (event) => {
                    if (this.__original__.role !== event.target.value) {
                      employee.currentAccount.subcontractor_account_id = event.target.value; // eslint-disable-line no-param-reassign
                      setUserChanged(employee);
                    }
                  },
                  // eslint-disable-next-line camelcase
                  defaultChecked: employee?.currentAccount.subcontractor_account_id || '-',
                  rootProps: { ripple: false },
                  // eslint-disable-next-line camelcase
                  value: employee?.currentAccount.subcontractor_account_id || '-',
                  disabled: !readOnlyMode ? !currentUser.hasPermission('user', 'set_subcontractor') : readOnlyMode,
                  placeholder: 'Valitse yritys',
                }}
              >
                {currentUser.accountInfo.subcontractorAccounts?.map((subcontractorAccount) => (
                  <option value={subcontractorAccount.id} label={subcontractorAccount.name} />
                ))}
              </Select>
            </ListItem>
          )}

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {/* externalId */}
            {this.renderTextField('currentAccount.extid', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {/* externalGroup */}
            {this.renderTextField('currentAccount.extgroup', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode })}
          </ListItem>

          {salaryPeriodCategoriesEnabled && (
            <ListItem style={{ marginTop: '1em', height: 'auto' }}>
              {/* NOTE: Not yet compatible with multi-account employers */}
              <Select
                style={{ top: 'auto !important' }}
                {...{
                  className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom label-style', // mdc-theme--text-primary-on-dark
                  label: t('profile.labels.salaryPeriodCategory'),
                  onBlur: () => {
                    if (this.__original__.salaryPeriodCategory !== employee.salaryPeriodCategory) {
                      setUserChanged(employee);
                    }
                  },
                  onChange: (event) => {
                    employee.salaryPeriodCategory = salaryPeriodCategories.find((category) => category.id === Number(event.target.value)); // eslint-disable-line no-param-reassign
                  },
                  onInput: (event) => {
                    if (this.__original__.salaryPeriodCategory.id !== event.target.value) {
                      employee.salaryPeriodCategory = salaryPeriodCategories.find((category) => category.id === Number(event.target.value)); // eslint-disable-line no-param-reassign
                      setUserChanged(employee);
                    }
                  },
                  rootProps: { ripple: false },
                  value: employee.salaryPeriodCategory.id,
                  disabled: employee.hasCompletedRegistration,
                }}
              >
                {salaryPeriodCategories?.map((category) => (
                  <option value={category.id} label={category.name} />
                ))}
              </Select>
            </ListItem>
          )}

          <Typography
            style={w100}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            Maksutiedot
          </Typography>

          {/* BANK NUMBER */}
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('bankNumber', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('taxNumber', { className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode, required: true })}
          </ListItem>

          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            {this.renderTextField('taxPercent', {
              type: 'number', step: 'any', min: 0, max: 100, className: 'ml-1 profile-input-95-view-width disabled-profile-input', disabled: readOnlyMode,
            })}
          </ListItem>

          {addAttachedCard(
            'taxIncomeCardUrl', t, employee,
            (event, entity) => {
              if ((event == null || event.currentTarget.validity.valid) && this.__original__[entity] !== employee[entity]) {
                this.save();
              }
            },
            {
              attribute: 'taxation_income_card',
              method: 'PUT',
              model: 'User',
              multiple: false,
              path: `/api/users/${employee?.id}`,
            },
            this.confirmDelete,
            this.openCard,
          )}

          {/* BANK NAME */}
          {/* <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            <TextField
              {...{
                label: t('profile.labels.bankName'),
                onBlur: () => {
                  if (this.__original__.details.bankName !== currentUser.details.bankName) {
                    this.save();
                  }
                },
                onChange: (event) => {
                  currentUser.details.bankName = event.target.value; // eslint-disable-line no-param-reassign
                },
                rootProps: { ripple: false },
                className: 'ml-1 profile-input-95-view-width',
                theme: ['textPrimaryOnDark', 'secondaryBg'],
                value: currentUser.details.bankName || '',
              }}
            />
          </ListItem> */}
        </List>

        {/* CLOTHING INFORMATION */}
        <Typography
          style={w100}
          use="headline6"
          tag="h2"
          className="padding-left headline"
        >
          {t('profile.labels.clothing_details')}
        </Typography>

        {/* HAS HELMET */}
        <List
          style={{ display: 'flex' }}
        >
          <FormControlLabel
            style={{ margin: '0 10px' }}
            label={t('profile.labels.clothing.has_helmet_employer')}
            control={
              <Checkbox {...{
                className: 'employer-checkbox',
                disabled: readOnlyMode,
                checked: employee.details?.clothing?.hasHelmet || false,
                onChange: (event) => {
                  employee.details.clothing.hasHelmet = event.target.checked;
                  // this.save();
                  setUserChanged(employee);
                },
              }}
              />
            }
          />
        </List>

        {/* PICTURE OF THE HELMET */}
        {employee?.details?.clothing.hasHelmet
          ? addAttachedCard(
            'helmetImageUrl', t, employee,
            (event, entity) => {
              if ((event == null || event.currentTarget.validity.valid) && this.__original__[entity] !== employee[entity]) {
                this.save();
              }
            },
            {
              attribute: 'helmet_image',
              method: 'PUT',
              model: 'User',
              multiple: false,
              path: `/api/users/${employee?.id}`,
            },
            this.confirmDelete,
            this.openCard,
          ) : null}

        <List>
          <ListItem>
            {/* HELMET SIZE */}
            <Select
              {...{
                className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom disabled-profile-input-select', // mdc-theme--text-primary-on-dark
                label: t('profile.labels.clothing.helmet_size'),
                onBlur: () => {
                  if (this.__original__.details.clothing.helmetSize !== employee?.details.clothing.helmetSize) {
                    setUserChanged(employee);
                  }
                },
                onChange: (event) => {
                  employee.details.clothing.helmetSize = event.target.value; // eslint-disable-line no-param-reassign
                },
                options: {
                  XXS: '51-52: XXS', XS: '53-54: XS', S: '55-56: S', M: '57-58: M', L: '59-60: L', XL: '61-62: XL', XXL: '63-64: XXL', XXXL: '65-66: XXXL',
                },
                placeholder: t('common.placeholder_select'),
                rootProps: { ripple: false },
                // theme: ['textPrimaryOnDark', 'secondaryBg'],
                value: employee?.details?.clothing.helmetSize || '',
                disabled: readOnlyMode,
              }}
            />
          </ListItem>
          <ListItem style={{ marginTop: '1em', height: 'auto' }}>
            <Select
              {...{
                className: 'pk-select profile-input-45-view-width no-border-bottom disabled-profile-input-select', // mdc-theme--text-primary-on-dark
                label: t('profile.labels.clothing.size'),
                onBlur: () => {
                  if (this.__original__.details.clothing.size !== employee.details.clothing.size) {
                    setUserChanged(employee);
                  }
                },
                onChange: (event) => {
                  employee.details.clothing.size = event.target.value; // eslint-disable-line no-param-reassign
                },
                options: ['S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
                placeholder: t('common.placeholder_select'),
                rootProps: { ripple: false },
                // theme: ['textPrimaryOnDark', 'secondaryBg'],
                value: employee?.details?.clothing.size || '',
                disabled: readOnlyMode,
              }}
            />
            <TextField
              style={{ height: 'auto' }}
              {...{
                label: t('profile.labels.clothing.number'),
                className: 'profile-input-45-view-width no-border-bottom disabled-profile-input-select',
                max: 100,
                min: 30,
                onBlur: () => {
                  if (this.__original__.details.clothing.number !== employee.details.clothing.number) {
                    setUserChanged(employee);
                  }
                },
                onChange: (event) => {
                  employee.details.clothing.number = event.target.value; // eslint-disable-line no-param-reassign
                },
                rootProps: { ripple: false },
                step: 'any',
                // theme: ['textPrimaryOnDark', 'secondaryBg'],
                type: 'number',
                value: employee?.details?.clothing.number || '',
                disabled: readOnlyMode,
              }}
            />
          </ListItem>
          <ListItem
            style={{ marginTop: '1em', height: 'auto' }}
          >
            <TextField
              {...{
                className: 'profile-input-95-view-width no-border-bottom disabled-profile-input-select',
                label: t('profile.labels.clothing.shoe_size'),
                max: 60,
                min: 30,
                onBlur: () => {
                  if (this.__original__.details.clothing.shoeSize !== employee.details.clothing.shoeSize) {
                    setUserChanged(employee);
                  }
                },
                onChange: (event) => {
                  employee.details.clothing.shoeSize = event.target.value; // eslint-disable-line no-param-reassign
                },
                rootProps: { ripple: false },
                step: 'any',
                // theme: ['textPrimaryOnDark', 'secondaryBg'],
                type: 'number',
                value: employee?.details?.clothing.shoeSize || '',
                disabled: readOnlyMode,
              }}
            />
          </ListItem>
          <Typography
            style={{ ...w100, marginTop: '32px' }}
            use="headline6"
            tag="h2"
            className="padding-left headline"
          >
            {t('cards.details.drivingLicense.name')}
          </Typography>

          <ListItem>
            <Select
              {...{
                className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom disabled-profile-input-select', // mdc-theme--text-primary-on-dark
                label: t('cards.details.drivingLicense.class'),
                onBlur: () => {
                  if (this.__original__.details.cards.drivingLicense.class !== employee.details.cards.drivingLicense.class) {
                    setUserChanged(employee);
                  }
                },
                onChange: (event) => {
                  employee.details.cards.drivingLicense.class = event.target.value; // eslint-disable-line no-param-reassign
                },
                onInput: (event) => {
                  if (this.__original__.details.cards.drivingLicense.class !== event.target.value) {
                    employee.details.cards.drivingLicense.class = event.target.value; // eslint-disable-line no-param-reassign
                    setUserChanged(employee);
                  }
                },
                defaultChecked: employee?.details?.cards?.drivingLicense.class || '-',
                options: {
                  '-': t('cards.details.drivingLicense.noLicense'), B: 'B', C1: 'C1', C: 'C', D1: 'D1', D: 'D', BE: 'BE', BEC: 'BEC', C1E: 'C1E', CE: 'CE', D1E: 'D1E', DE: 'DE',
                },
                rootProps: { ripple: false },
                value: employee?.details?.cards?.drivingLicense.class || '-',
                disabled: readOnlyMode,
              }}
            />
          </ListItem>
        </List>
      </form>
    );
  }

  renderValidities() {
    const { t, uiStore: { currentUser: { accountId } } } = this.props;
    const { employee } = this.state;

    return (
      <div
        className="custom-validities-employer no-list-item-ripple-anim profile__details" // theme-dark custom-validities
        style={{
          ...appcontentsize,
          marginBottom: '1em',
          width: '25wv',
        }}
      >
        {/* <Typography
          style={w100}
          use="headline6"
          tag="h2"
          className="padding-left headline"
        >
          Kansalliset voimassaolot
        </Typography> */}

        {
          employee?.details?.cards && mandatoryCardTypes.map((cardType) => addCollapsibleCard(
            employee?.accountId, t, cardType, employee.details.cards,
            (event, entity, attr) => {
              if ((event == null || event.currentTarget.validity.valid) && this.__original__.details.cards[entity][attr] !== employee?.details.cards[entity][attr]) {
                this.save();
              }
            },
            (event, entity, attr) => {
              // if (attr === 'validThrough') {
              //   const convertedDate = Validity.convertDate(event.target.value);
              //   console.log('Converted: ', convertedDate);
              //   if (!convertedDate.isValid) {
              //     return false;
              //   } else {

              //   }
              // } else
              if (employee?.details.cards[entity][attr] !== event.target.value) {
                employee.details.cards[entity][attr] = event.target.value;
                return true;
              }

              return false;
            },
            this.openCard,
            this.confirmDelete,
            'employer',
          ))
        }

        {(accountId === 49 || accountId === 10) && (
          <>
            <Typography
              style={{ ...w100, marginTop: '30px' }}
              use="headline6"
              tag="h2"
              className="padding-left headline"
            >
              Muut voimassaolot
            </Typography>

            <UserValidities className="employer-user-validities" openCard={this.openCard} employee={employee} />
          </>
        )}
      </div>
    );
  }

  renderConfirmDeleteDialog() {
    const { t } = this.props;
    const { showDeleteDialog, deletingFile } = this.state;

    return (
      <Dialog
        className="dialog-background"
        open={showDeleteDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.deleteFile();
          }
          this.setState({ showDeleteDialog: false });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <div>{t(`profile.dialog.deleteBody_${deletingFile}`, t('profile.dialog.deleteBody_validity'))}</div>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            className="accept-button"
            style={{
              borderRadius: '0px',
            }}
            action="accept"
          >
            Kyllä
          </DialogButton>
          <DialogButton
            style={{
              color: '#c7c9cc',
              border: '1px solid #c7c9cc',
              borderRadius: '0px',
            }}
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { activeTab, deletingFile, showDeleteDialog } = this.state;
    const { t, mode } = this.props;

    return (
      <div className={mode === 'employer-dialog-read-only' && 'employer-read-only-employee-dialog'}>
        <ConfirmDeleteDialog
          mode="employer"
          open={showDeleteDialog}
          onConfirm={this.deleteFile}
          onClose={this.closeConfirmDeleteDialog}
          message={t(`profile.dialog.deleteBody_${deletingFile}`, t('profile.dialog.deleteBody_validity'))}
        />

        {this.renderCardDialog()}

        <TabBar
          className="employee-profile-tabs"
          activeTabIndex={activeTab}
          onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
          style={{
            ...appcontentsize,
            // marginBottom: '1em',
            width: '25wv',
          }}
        >
          <Tab icon="account_circle">Tiedot</Tab>
          <Tab icon="fact_check">Voimassaolot</Tab>
        </TabBar>

        {/* Note: this HTML structure is significantly different from the "main" navigation tabs in other views */}
        {/* <div style={{ backgroundColor: '#0D121C' }}>
          <TabBar
            style={{ maxWidth: '700px' }}
            activeTabIndex={activeTab}
            onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
          >
            <Tab icon="account_circle">Tiedot</Tab>
            <Tab icon="fact_check">Voimassaolot</Tab>
          </TabBar>
        </div> */}

        {activeTab === 0 && this.renderPersonalInfo()}
        {activeTab === 1 && this.renderValidities()}
      </div>
    );
  }
}

export default EmployeeProfile;
