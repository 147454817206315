/* eslint-disable */
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
// import actionCable from 'actioncable';

import moment, { dateWithoutTime } from '../utils/moment';
import TimelogEntry from './TimelogEntry';
import SalaryPeriod from './SalaryPeriod';
import SalaryPeriodAttachment from './SalaryPeriodAttachment';
import TaxExemptTripExpense from './TaxExemptTripExpense';
// import { get } from 'lodash';

const statusToStages = {
  closed: 'archived',
  locked: 'current',
  opened: 'next',
};

const isBetweenPeriod = (ref, target) => moment(ref).isBetween(target.from, target.to, null, '[]');

export default class TimelogStore {
  attachments = observable(new Map());
  cable = null;
  intervalId = null;

  @observable workOrdersWithMeta = fromPromise.resolve(new Map());

  @observable.shallow periods = observable([]);

  // Not sure if this is the best place for these
  // Save filters used in Work hour and Work trip views for employer
  @observable workHourFilters = null;

  @observable workTripFilters = null;

  // Not used because the data might theoretically change and the visibility is derived from the data each time
  @observable timelogReportColumnVisibility = null;

  @observable timelogReportSorting = null;

  @observable timelogReportFilters = null;

  @observable timelogReportFrom = null;

  @observable timelogReportTo = null;

  // Function called in employer-time-log/index.jsx
  @action setHourFilters(filters) {
    this.workHourFilters = filters
  }

  // Function called in employer-work-order-trips/index.jsx
  @action setTripFilters(filters) {
    this.workTripFilters = filters
  }

  @action setTimelogReportDates(from, to) {
    this.timelogReportFrom = from;
    this.timelogReportTo = to;
  }

  @action setTimelogReportSorting(sorting) {
    this.timelogReportSorting = sorting;
  }

  @action setTimelogReportFilters(filters) {
    this.timelogReportFilters = filters;
  }

  @action setTimelogReportColumnVisibility(columnVisibility) {
    this.timelogReportColumnVisibility = columnVisibility;
  }

  cableApp = {};

  constructor(uiStore, actionCableStore, employerContextStore, requests) {
    this.uiStore = uiStore;
    this.actionCableStore = actionCableStore;
    this.employerContextStore = employerContextStore;
    this.requests = requests;

    // Note: also called in App.js afterLogin()
    // If we do not subscribe here, refreshing the page will cut the cable (user already logged in so afterLogin doesn't run)
    if (!this.cable) {
      this.subscribe();
    }

    // Update the work hours in real time in employer acceptance system
    // if (process.env.NODE_ENV === 'development') {
    //   this.cableApp.cable = actionCable.createConsumer('ws://localhost:3001/api/cable');
    // } else {
    //   this.cableApp.cable = actionCable.createConsumer('/api/cable');
    // }
    // this.cableApp.hours = this.cableApp.cable.subscriptions.create(
    //   'EmployeeWorkHoursChannel',
    //   {
    //     received: (response) => {
    //       /* console.log('Response: ', response);

    //       if (!("Notification" in window)) {
    //         alert('NO NOTIFICATION SUPPORT');
    //       }
    //       // Let's check whether notification permissions have already been granted
    //       else if (Notification.permission === 'granted') {
    //         // If it's okay let's create a notification
    //         if (response.method === 'accept') {
    //           new Notification(`Tuntikirjaus ${moment(response.body.date).format('dd DD.MM.YYYY')} hyväksytty!`);
    //         } else if (response.method === 'reject') {
    //           new Notification(`Tuntikirjaus ${moment(response.body.date).format('dd DD.MM.YYYY')} lähetetty korjattavaksi!`);
    //         }
    //       }
    //       // Otherwise, we need to ask the user for permission
    //       else if (Notification.permission !== 'denied') {
    //         Notification.requestPermission().then((permission) => {
    //           // If the user accepts, let's create a notification
    //           if (response.method === 'accept') {
    //             new Notification(`Tuntikirjaus ${moment(response.body.date).format('dd DD.MM.YYYY')} hyväksytty!`);
    //           } else if (response.method === 'reject') {
    //             new Notification(`Tuntikirjaus ${moment(response.body.date).format('dd DD.MM.YYYY')} lähetetty korjattavaksi!`);
    //           }
    //         });
    //       } */
    //     },
    //   },
    // );
  }

  @action async getWorkHoursByIds(ids) {
    const { currentUser } = this.uiStore;
    return this.requests.TimeLogs.getWorkHoursByIds(currentUser, ids).then((res) => {
      const workHours = res.map((workHour) => TimelogEntry.fromJsonProperties(workHour));
      return workHours;
    });
  }

  subscribe = () => {
    this.cable = this.actionCableStore.cableOn
      ? this.actionCableStore.subscribe('EmployeeWorkHoursChannel', this.handleResponse)
      : null;

    // Ping-ponging once every 30 seconds to keep heroku from closing the connection
    this.intervalId = setInterval(() => {
      if (this.uiStore.currentUser && this.cable) {
        this.cable.ping();
      }
    }, 30000);
  }

  unsubscribe = () => {
    this.actionCableStore.unsubscribe(this.cable);
    clearInterval(this.intervalId);
  }

  handleResponse = (response)  => {
    if (response.method !== 'pong') {
      // Assuming a new timelog from employer
      const newTimelog = TimelogEntry.fromJsonProperties(response.body);
      // Find the correct work order + its work hours, then update the work hour with the new version
      if (this.workOrdersWithMeta && this.workOrdersWithMeta.value.current) {
        const foundWo = this.workOrdersWithMeta.value.current.filter((item) => item.workOrder.id === newTimelog.workOrderId)[0];
        if (foundWo) {
          const foundWhIndex = foundWo.workOrder.workHours.findIndex((item) => item.id === newTimelog.id);
          if (foundWhIndex !== -1) {
            foundWo.workOrder.workHours[foundWhIndex] = newTimelog;
          }
        }
      }
    }
  }

  // TODO: This and timelogEntry.allowance vs. taxExemptTripExpense logic should be cleaned
  // Currently it is a hybrid of the old timelogEntry.allowance and taxExemptTripExpense management
  // UI (TimelogRow.js) uses timelogEntry.allowance but in the database the allowances are saved in taxExemptTripExpenses
  updateTimelogAllowances(timelogs) {
    if (this.workOrdersWithMeta) {
      const user = this.uiStore.currentUser;

      timelogs.forEach((timelog) => {
        const wo = user.workOrders.find((item) => item.id === timelog.work_order_id);
        const timelogIndex = wo.workHours.findIndex((tl) => tl.date.isSame(timelog.date));

        // Find the timelog entry and its tax exempt trip expense (allowance)
        // If not found, create new ones
        if (timelogIndex !== -1) {
          const newTimelogEntry = new TimelogEntry({...wo.workHours[timelogIndex]});
          const taxExemptTripExpenseIndex = newTimelogEntry.taxExemptTripExpenses.findIndex((expense) => expense.name === 'allowance');

          if (taxExemptTripExpenseIndex !== -1) {
            if (timelog.allowance) {
              newTimelogEntry.taxExemptTripExpenses[taxExemptTripExpenseIndex].changeAttribute('value', timelog.allowance);
              newTimelogEntry.changeAttribute('allowance', timelog.allowance);
              newTimelogEntry.changeAttribute('status', timelog.status);
            } else {
              newTimelogEntry.taxExemptTripExpenses.splice(taxExemptTripExpenseIndex, 1);
              newTimelogEntry.changeAttribute('allowance', null);
            }
          } else {
            const newTaxExemptTripExpense = new TaxExemptTripExpense({ name: 'allowance', value: timelog.allowance });
            newTimelogEntry.taxExemptTripExpenses.push(newTaxExemptTripExpense);
            newTimelogEntry.changeAttribute('allowance', timelog.allowance);
            newTimelogEntry.changeAttribute('status', timelog.status);
          }
          wo.workHours[timelogIndex] = newTimelogEntry;
        }
      });

      this.updateForWorkOrder.bind(this)(user.workOrders, (result) => {
        this.workOrdersWithMeta.value = result;
      });
    }
  }

  removeWorkOrderTripEmployerComments(workHourIds) {
    if (this.workOrdersWithMeta) {
      const user = this.uiStore.currentUser;

      workHourIds.forEach((workHourId) => {
        let foundWorkHour;
        user.workOrders.some((workOrder) => {
          foundWorkHour = workOrder.workHours.find((workHour) => workHour.id === workHourId);
          if (foundWorkHour) {
            return true;
          }
          return false;
        });

        if (foundWorkHour) {
          foundWorkHour.workOrderTripEmployerComment = null;
        }
      });

      // this.updateForWorkOrder.bind(this)(user.workOrders, (result) => {
      //   this.workOrdersWithMeta.value = result;
      // });
    }
  }

  @action async accept(workOrder, user, from, to) {
    const currentUser = this.uiStore.currentUser;
    const currentEmployeeId = this.employerContextStore.currentEmployeeId;

    if (workOrder != null && workOrder.workHours.length !== 0) {
      let timelogs;

      if (workOrder.employerAcceptance) {
        timelogs = await this.requests.TimeLogs.sendToEmployers(workOrder, from, to);
      } else {
        timelogs = await this.requests.TimeLogs.accept(workOrder, from, to);
      }

      if (!currentEmployeeId || currentUser.role !== 'employer') {
        const wo = user.workOrders.find((item) => item.id === workOrder.id);
        let index;
        timelogs.forEach((item) => {
          index = wo.workHours.findIndex((tl) => tl.date.isSame(item.date));
          // found same date, replace content
          if (index != null) {
            wo.workHours[index] = TimelogEntry.fromJsonProperties(item);
          }
        });

        // Is this here just to trigger a re-render? Isn't there a better way?
        this.updateForWorkOrder.bind(this)(user.workOrders, (result) => {
          this.workOrdersWithMeta.value = result;
        });
      }
    }
  }

  // TODO: Optimize
  // Currently this is potentially used to update several "bulk update packages" (work orders) in trip contexts
  // This would result in multiple real time update calls through action cable
  // Should optimally do all updates with one API call and result in one action cable update call
  @action async employerUpdate(workOrder, workHours, employerContextTripId, callback) {
    const workHoursJson = workHours.map((workHour) => TimelogEntry.toJson(workHour));
    this.requests.TimeLogs.employerUpdate(workOrder, workHoursJson, employerContextTripId).then((res) => {
      // const updatedWorkHours = [...workHours].forEach((updatedWorkHour) => { updatedWorkHour.updatedByEmployer = false; });
      if (callback) {
        callback(res);
      }
    });
  }

  // TODO: Optimize
  // Currently this is potentially used to update several "bulk update packages" (work orders) in trip contexts
  // This would result in multiple real time update calls through action cable
  // Should optimally do all updates with one API call and result in one action cable update call
  @action async employerUpdateAndAccept(workOrder, workHours, employerContextTripId, callback) {
    const workHoursJson = workHours.map((workHour) => TimelogEntry.toJson(workHour));
    this.requests.TimeLogs.employerUpdateAndAccept(workOrder, workHoursJson, employerContextTripId).then((res) => {
      if (callback) {
        callback(res);
      }
    });
  }

  // TODO: "employerAccept" and "employerAcceptMultiple" should be fused but there's no time to risk breaking this
  // @action async employerAccept(user, workOrderId, workHourId) {
  //   await this.requests.TimeLogs.employerAccept(user, workOrderId, workHourId);
  // }
  @action async employerAccept(user, workOrderId, workHourId, resolve, reject) {
    this.requests.TimeLogs.employerAccept(user, workOrderId, workHourId).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerAcceptMultiple(workHourData) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.employerAcceptMultiple(ctxUser, workHourData);
  }

  // @action async employerReject(user, workOrderId, workHourId, employerComment, sendSMS) {
  //   await this.requests.TimeLogs.employerReject(user, workOrderId, workHourId, employerComment, sendSMS);
  // }
  @action async employerReject(user, workOrderId, workHourId, employerComment, sendSMS, resolve, reject) {
    this.requests.TimeLogs.employerReject(user, workOrderId, workHourId, employerComment, sendSMS).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerRejectEmpty(user, workHour, employerComment, sendSMS, resolve, reject) {
    this.requests.TimeLogs.employerRejectEmpty(user, workHour, employerComment, sendSMS).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async missingWorkOrderTripReject(earliestOrphanWorkHourId, employerComment, sendSMS, resolve, reject) {
    this.requests.TimeLogs.missingWorkOrderTripReject(earliestOrphanWorkHourId, employerComment, sendSMS).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerRejectMultiple(workHourData, employerComment, sendSMS) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.employerRejectMultiple(ctxUser, workHourData, employerComment, sendSMS);
  }

  // TODO: Move to WorkOrderTripStore.js
  // @action async employerStartTripAccept(id) {
  //   return this.requests.StartTrips.employerAccept(id);
  // }

  // @action async employerStartTripAccept(id, resolve, reject) {
  //   this.requests.StartTrips.employerAccept(id).then((response) => {
  //     resolve(response);
  //   }).catch((err) => reject(err));
  // }

  // // TODO: Move to WorkOrderTripStore.js
  // // @action async employerStartTripReject(id, employerComment, sendSMS) {
  // //   return this.requests.StartTrips.employerReject(id, employerComment, sendSMS);
  // // }
  @action async employerStartTripReject(id, employerComment, sendSMS, resolve, reject) {
    this.requests.StartTrips.employerReject(id, employerComment, sendSMS).then((response) => {
      // console.log('Response?: ', response);
      resolve(response);
    }).catch((err) => reject(err));
  }

  // // TODO: Move to WorkOrderTripStore.js
  // // @action async employerEndTripAccept(id) {
  // //   return this.requests.EndTrips.employerAccept(id);
  // // }

  // @action async employerEndTripAccept(id, resolve, reject) {
  //   this.requests.EndTrips.employerAccept(id).then((response) => {
  //     resolve(response);
  //   }).catch((err) => reject(err));
  // }

  // // TODO: Move to WorkOrderTripStore.js
  // @action async employerEndTripReject(id, employerComment, sendSMS) {
  //   return this.requests.EndTrips.employerReject(id, employerComment, sendSMS);
  // }

  @action async employerEndTripReject(id, employerComment, sendSMS, resolve, reject) {
    this.requests.EndTrips.employerReject(id, employerComment, sendSMS).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerAcceptTrip(tripId, expenseIds, tripRouteIds, resolve, reject) {
    this.requests.WorkOrderTrips.employerAccept(tripId, expenseIds, tripRouteIds).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerAcceptTripExpenses(expenseIds, tripRouteIds, resolve, reject) {
    this.requests.WorkOrderTrips.employerAcceptTripExpenses(expenseIds, tripRouteIds).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerRejectTripExpenses(expenses, sendSMS, resolve, reject) {
    this.requests.TaxExemptTripExpenses.employerReject(expenses, sendSMS).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action async employerRejectTripRoutes(tripRoutes, sendSMS, resolve, reject) {
    this.requests.TripRoutes.employerReject(tripRoutes, sendSMS).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  @action updateTimelogEntry(item, allowanceAutofill) {
    const currentUser = this.uiStore.currentUser;
    const currentEmployeeId = this.employerContextStore.currentEmployeeId;

    // If we're editing as an employer (EmployerTimelogView), we inject the employee's ID here to be used in place of the employer's ID
    if (currentUser.role === 'employer' && currentEmployeeId) {
      item.userId = currentEmployeeId;
      item.status = 'pending';
      item.updatedByEmployer = true;
      // Prevent saving onBlur in EmployerTimelogView
      return Promise.resolve(null);
    }

    return this.requests.TimeLogs[item.id == null ? 'create' : 'update'](item, allowanceAutofill).then((res) => {
      if (res.message === 'Deleted') {
        let foundWorkOrderWithMeta = this.workOrdersWithMeta.value.current.find((workOrderWithMeta) => workOrderWithMeta.workOrder.id === item.workOrderId);
        if (!foundWorkOrderWithMeta) {
          // If the work order isn't found from the current ones, also check the upcoming work orders
          foundWorkOrderWithMeta = this.workOrdersWithMeta.value.next.find((workOrderWithMeta) => workOrderWithMeta.workOrder.id === item.workOrderId);
        }

        if (foundWorkOrderWithMeta) {
          const foundWorkHourIndex = foundWorkOrderWithMeta.workOrder.workHours.findIndex((workHour) => workHour.id === item.id);
          if (foundWorkHourIndex !== -1) {
            foundWorkOrderWithMeta.workOrder.workHours[foundWorkHourIndex] = new TimelogEntry({
              date: item.date,
              userId: item.userId,
              workOrderId: item.workOrderId,
            });
          }
        }
      } else {
        item.updatePropertiesFromJson(res);
      }
    });
  }

  // @action updateHourlyTimelogEntry(item) {
  //   return this.requests.TimeLogs[item.id == null ? 'createHourly' : 'update'](item).then((timelog) => item.updatePropertiesFromJson(timelog));
  // }

  @action updateWorkTaskEntry(item, updatedWorkTaskEntry) {
    const updatedWorkTaskEntries = [...item.workTaskEntries];
    const entryIndex = updatedWorkTaskEntries.findIndex((workTaskEntry) => workTaskEntry.id === updatedWorkTaskEntry.id);
    if (entryIndex !== -1) {
      updatedWorkTaskEntries[entryIndex] = updatedWorkTaskEntry;
      item.changeAttribute('workTaskEntries', updatedWorkTaskEntries);
      this.updateTimelogEntry(item);
    }
  }

  // TODO: Move to WorkOrderTripStore.js
  @action updateWorkOrderTrip(item) {
    return this.requests.WorkOrderTrips[item.id == null ? 'create' : 'update'](item).then((workOrderTrip) => item.updatePropertiesFromJson(workOrderTrip));
  }

  // TODO: Move to WorkOrderTripStore.js
  @action updateWorkOrderTripProp(item, workOrderId) {
    const foundWoObj = this.workOrdersWithMeta.value.current.find((woObj) => woObj.workOrder.id === workOrderId);
    if (foundWoObj?.workOrder?.workOrderTrips) {
      const foundTripIndex = foundWoObj.workOrder.workOrderTrips.findIndex((tripObj) => tripObj.id === item.id);

      if (foundTripIndex === -1) {
        // console.log('TIMELOGSTORE: TRIP NOT FOUND', item);
        foundWoObj.workOrder.workOrderTrips.push(item);
      } else {
        // console.log('TIMELOGSTORE: FOUND AND UPDATING TRIP', item);
        foundWoObj.workOrder.workOrderTrips[foundTripIndex] = item;
      }
    }
  }

  // TODO: Move to WorkOrderTripStore.js
  @action updateStartTrip(item, workOrderId) {
    // We need to manipulate the props directly because otherwise the UI won't properly update e.g. after changing tabs
    const foundWoObj = this.workOrdersWithMeta.value.current.find((woObj) => woObj.workOrder.id === workOrderId);
    let foundUiStartTrip;
    if (foundWoObj.workOrder.uiStartTrip) {
      foundUiStartTrip = foundWoObj.workOrder.uiStartTrip;
    }
    // const foundUiStartTrip = foundWoObj.workOrder.uiStartTrip[0];
    if (foundUiStartTrip) {
      foundUiStartTrip.date = item.date?.format('YYYY-MM-DD');
      foundUiStartTrip.time = moment(item.time, 'HH:mm');
      foundUiStartTrip.status = item.status;
    } else {
      item.date = item.date?.format('YYYY-MM-DD');
      item.time = moment(item.time, 'HH:mm');
      if (!foundWoObj.workOrder.uiStartTrip) {
        foundWoObj.workOrder.uiStartTrip = [];
      }
      foundWoObj.workOrder.uiStartTrip = item;
    }

    return this.requests.StartTrips[item.id == null ? 'create' : 'update'](item).then((startTrip) => item.updatePropertiesFromJson(startTrip));
  }

  // TODO: Move to WorkOrderTripStore.js
  @action updateEndTrip(item, workOrderId) {
    // We need to manipulate the props directly because otherwise the UI won't properly update e.g. after changing tabs
    const foundWoObj = this.workOrdersWithMeta.value.current.find((woObj) => woObj.workOrder.id === workOrderId);
    let foundUiEndTrip;
    if (foundWoObj.workOrder.uiEndTrip) {
      foundUiEndTrip = foundWoObj.workOrder.uiEndTrip;
    }

    if (foundUiEndTrip) {
      foundUiEndTrip.date = item.date?.format('YYYY-MM-DD');
      foundUiEndTrip.time = moment(item.time, 'HH:mm');
      foundUiEndTrip.to_date = item.toDate?.format('YYYY-MM-DD');
      foundUiEndTrip.to_time = moment(item.toTime, 'HH:mm');
      foundUiEndTrip.status = item.status;
    } else {
      item.date = item.date?.format('YYYY-MM-DD');
      item.time = moment(item.time, 'HH:mm');
      item.to_date = item.toDate?.format('YYYY-MM-DD');
      item.to_time = moment(item.toTime, 'HH:mm');
      if (!foundWoObj.workOrder.uiEndTrip) {
        foundWoObj.workOrder.uiEndTrip = [];
      }
      foundWoObj.workOrder.uiEndTrip[0] = item;
    }

    return this.requests.EndTrips[item.id == null ? 'create' : 'update'](item).then((endTrip) => item.updatePropertiesFromJson(endTrip));
    // UPDATE PROPS HERE
  }

  // TODO: Shoud probably move all these route things to their own store
  @action updateTripRoute(item, workOrderId, tripType, workHourId = null, routeIndex = null) {
    // We need to manipulate the props directly because otherwise the UI won't properly update e.g. after changing tabs
    const foundWoObj = this.workOrdersWithMeta.value.current.find((woObj) => woObj.workOrder.id === workOrderId);
    // const startTripRouteArray = foundWoObj.workOrder.uiStartTrip[0]?.trip_route;
    // const endTripRouteArray = foundWoObj.workOrder.uiEndTrip[0]?.trip_route;

      if (tripType === 'start') {
        const startTripRouteArray = foundWoObj.workOrder.uiStartTrip?.trip_route;
        if (startTripRouteArray?.length > 0) {
          startTripRouteArray[0].id = item.id;
          startTripRouteArray[0].kms = item.kms;
          startTripRouteArray[0].status = item.status;
        }
      } else if (tripType === 'end') {
        const endTripRouteArray = foundWoObj.workOrder.uiEndTrip?.trip_route;
        if (endTripRouteArray?.length > 0) {
          endTripRouteArray[0].id = item.id;
          endTripRouteArray[0].kms = item.kms;
          endTripRouteArray[0].status = item.status;
        }
      } else if (tripType === 'other') {
        const foundWorkHour = foundWoObj.workOrder.workHours.find((workHour) => workHour.id === workHourId);
        if (foundWorkHour.tripRoutes.length > 0) {
          const foundTripRoute = foundWorkHour.tripRoutes[routeIndex];
          if (foundTripRoute) {
            // console.log('Found trip route from props: ', foundTripRoute);
            foundTripRoute.id = item.id;
            foundTripRoute.kms = item.kms;
            foundTripRoute.status = item.status;
          } else {
            // console.log('Trip route not found, pushing');
            foundWorkHour.tripRoutes.push(item);
          }
        } else {
          // Add an array with item as its first only member into foundWorkHour.tripRoutes because the array is empty
          foundWorkHour.changeAttribute('tripRoutes', [item]);
          // console.log('New tripRoutes: ', foundWorkHour.tripRoutes);
        }
      }
    return this.requests.TripRoutes[item.id == null ? 'create' : 'update'](item).then((route) => item.updatePropertiesFromJson(route));
  }

  @action updateTripRoutes(workHour, tripRoutes, employerContextTripId) {
    workHour.tripRoutes = tripRoutes;
    // Note: slightly different logic for checking create/update than usual, because id can be undefined instead of null
    return this.requests.TimeLogs[!workHour.id ? 'create' : 'update'](workHour, employerContextTripId).then((timelog) => workHour.updatePropertiesFromJson(timelog));
  }

  async deleteTripRoute(item) {
    // const ctxUser = this.uiStore.currentUser;
    this.requests.TripRoutes.del(item).then(() => {
      // console.log('Trip route deleted');
    });
  }

  async deleteRouteLocation(item) {
    // const ctxUser = this.uiStore.currentUser;
    this.requests.RouteLocations.del(item).then(() => {
      // console.log('Route location deleted');
    });
  }

  async deleteWorkTaskEntry(dailyTimelogEntry, workTaskEntry) {
    this.requests.TimeLogs.deleteWorkTaskEntry(dailyTimelogEntry, workTaskEntry).then((res) => {
      if (res.message === 'Deleted') {
        const foundWoObj = this.workOrdersWithMeta.value.current?.find((woObj) => woObj.workOrder.id === dailyTimelogEntry.workOrderId);
        if (!foundWoObj) {
          // If the work order isn't found from the current ones, also check the upcoming work orders
          foundWoObj = this.workOrdersWithMeta.value.next.find((woObj) => woObj.workOrder.id === dailyTimelogEntry.workOrderId);
        }

        // For whatever reason whs.date._d is the only actually functioning date,
        // all other values return the first day of the work order regardless of day so can't be used for comparison
        const timelogIndex = foundWoObj.workOrder.workHours.findIndex((workHour) => workHour.id === dailyTimelogEntry.id);
        if (timelogIndex !== -1) {
          // "Delete" or reset the work hour in the UI
          foundWoObj.workOrder.workHours[timelogIndex] = new TimelogEntry({
            date: dailyTimelogEntry.date,
            userId: dailyTimelogEntry.userId,
            workOrderId: dailyTimelogEntry.workOrderId,
          });
        }
      } else {
        dailyTimelogEntry.status = res.status;
        dailyTimelogEntry.workTaskEntries = dailyTimelogEntry.workTaskEntries.filter((item) => item.id !== workTaskEntry.id);
      }
    });
  }

  /* getTimelogs(workOrder) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.getAll(ctxUser, workOrder);
  } */

  // Used to get the currently pending timelogs for the responsible employers to accept/reject them
  // Relevant when opening the page, since the actioncable API only returns new changes to the data
  @action async getResponsibleEmployerTimelogs() {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.getEmployerHours(ctxUser, ['pending', 'accepted']);
  }

  @action async getEmployerTimelogsByDates(salaryPeriod, status = null) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.getEmployerHoursByDates(ctxUser, salaryPeriod.from, salaryPeriod.to, salaryPeriod.salary_period_category_id, status);
  }

  @action async getEmployerTimelogReportByDates(from, to) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.getEmployerTimelogReportByDates(ctxUser, from, to);
  }

  // Assuming an array of IDs as the parameter
  @action async getResponsibleEmployerTimelogsById(ids) {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.TimeLogs.getEmployerHoursById(ctxUser, ids);
  }

  @action async getResponsibleEmployerTimelogsByIdPromise(ids, resolve, reject) {
    const ctxUser = this.uiStore.currentUser;
    this.requests.TimeLogs.getEmployerHoursById(ctxUser, ids).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  // TODO: Move to WorkOrderTripStore.js
  // Used to get the currently pending work order trips for the responsible employers to accept/reject their start & end trips
  // Relevant when opening the page, since the actioncable API only returns new changes to the data
  @action async getResponsibleEmployerWorkOrderTrips() {
    const ctxUser = this.uiStore.currentUser;
    return this.requests.WorkOrderTrips.getEmployerWorkOrderTrips(ctxUser, ['pending', 'accepted']);
  }

  // Assuming a single id as the parameter
  @action async getResponsibleEmployerWorkOrderTripsById(id, resolve, reject) {
    const ctxUser = this.uiStore.currentUser;
    this.requests.WorkOrderTrips.getEmployerWorkOrderTripsById(ctxUser, id).then((response) => {
      resolve(response);
    }).catch((err) => reject(err));
  }

  // The main idea of this method is to chop and categorize WorkOrders per SalaryPeriod
  // For example, a Work Order 15.12. - 31.01. would be contained in all following salary periods:
  // Archived: 15.12. - 31.12. period
  // Current: 01.01. - 15.01. period
  // Next: 16.01. - 31.01. period
  // These work periods act as "frames" for different lengths of time within a single work order
  // Extra logic has been implemented so that only the most relevant upcoming salary period is used so that the "next" category doesn't contain ridiculously long work order segments
  updateForWorkOrder = (workOrders, cb) => {
    const result = {
      // Fill with work orders depending on their relation to the current salary period
      archived: [],
      current: [],
      next: [],
      // Fill with work order trips depedning on their relation to the current salary period to be their separate elements
      archived_trips: [],
      next_trips: [],
    };

    // let currentPeriod;
    let startPeriod;
    let period;
    let endPeriod;
    let startdate;
    let enddate;
    let newItem;

    const currentSalaryPeriodIndex = this.periods.findIndex((period) => period.isCurrent);
    let nextSalaryPeriod;
    if (currentSalaryPeriodIndex !== -1) {
      // Assuming that the salary periods are sorted by date (ascending)
      // As of the time of writing, the sorting is done in the backend (def salary_periods)
      nextSalaryPeriod = this.periods[currentSalaryPeriodIndex + 1];
    }

    workOrders.forEach((wo) => {
      // NOTE: startPeriod and endPeriod contain the periods that overlap with the work order's start and end date, respectively
      // They are crucial for correctly rendering the work order: for example, if no overlapping start period is found, the work order isn't rendered at all
      startPeriod = null;
      endPeriod = null;
      startdate = wo.interval.start;
      enddate = null;

      // A variable used to track if the salary period that overlaps with work order's ending is found or not
      let foundFinalSalaryPeriod = false;
      let lastUsedEndDate = wo.interval.end;

      let alreadyPushedToNextWorkOrders = false;

      for (let i = 0; i < this.periods.length;) {
        period = this.periods[i];

        if (isBetweenPeriod(startdate, period)) {
          // The salary period that overlaps with the work order's start
          startPeriod = period;
        }

        if (startPeriod != null) {
          // When the starting salary period has been found, check for the salary period that overlaps with the work order's end
          if (isBetweenPeriod(wo.interval.end, period)) {
            endPeriod = period;
            enddate = moment.min(wo.interval.end, dateWithoutTime(period.to));
          }

          // stage changed need to split OR this is the last period
          if (startPeriod.status !== period.status || startPeriod.isCurrent) {
            // Allowance autofill reminder for current salary period
            const startPeriodAllowanceAutofillReminder = wo.allowanceAutofillReminders.find((reminder) => reminder.salary_period_id == startPeriod.id)?.enabled;
            newItem = {
              disabled: startPeriod.status !== 'opened',
              from: startdate,
              locked: startPeriod.status === 'locked',
              periodId: startPeriod.id,
              to: moment.min(startPeriod.to, enddate || wo.interval.end),
              updatedAt: moment(),
              workOrder: wo,
              periodFrom: startPeriod.from,
              periodTo: startPeriod.to,
              // A stupid hack to turn null/undefined reminders into "true" because user specifically creates reminders with enabled: false
              allowanceAutofillReminder: startPeriodAllowanceAutofillReminder !== false ? true : false,
            };
            lastUsedEndDate = moment.min(startPeriod.to, enddate || wo.interval.end);
            newItem.key = `${wo.id}-${newItem.to}`;
            result[startPeriod.isCurrent ? 'current' : statusToStages[startPeriod.status]].push(newItem);
            // if startPeriod overlaps with wo.interval.end, foundFinalSalaryPeriod = true
            if (wo.interval.end.isBetween(startPeriod.from, startPeriod.to, null, '[]')) {
              foundFinalSalaryPeriod = true;
            }

            startdate = dateWithoutTime(newItem.to).add(1, 'days').startOf('day');
          }
          startPeriod = period;

          if (endPeriod != null && !startdate.isAfter(enddate || wo.interval.end)) {
            // if endPeriod overlaps with wo.interval.end, foundFinalSalaryPeriod = true
            if (wo.interval.end.isBetween(endPeriod.from, endPeriod.to, null, '[]')) {
              foundFinalSalaryPeriod = true;
            }

            if (!endPeriod.isCurrent && statusToStages[endPeriod.status] !== 'archived' && nextSalaryPeriod) {
              endPeriod = nextSalaryPeriod;
              enddate = moment.min(wo.interval.end, dateWithoutTime(nextSalaryPeriod.to));
              // We do not want to push the exact same item more than once and create duplicates
              // This can happen e.g. when we're replacing endPeriod with nextSalaryPeriod (compared to current) for multiple upcoming salary periods
              if (alreadyPushedToNextWorkOrders) {
                return;
              } else {
                alreadyPushedToNextWorkOrders = true;
              }
            }

            const endPeriodAllowanceAutofillReminder = wo.allowanceAutofillReminders?.find((reminder) => reminder.salary_period_id == endPeriod.id)?.enabled;

            newItem = {
              disabled: endPeriod.status !== 'opened',
              from: startdate,
              locked: endPeriod.status === 'locked',
              periodId: endPeriod.id,
              to: enddate,
              updatedAt: moment(),
              workOrder: wo,
              periodFrom: endPeriod.from,
              periodTo: endPeriod.to,
              // A stupid hack to turn null/undefined reminders into "true" because user specifically creates reminders with enabled: false
              allowanceAutofillReminder: endPeriodAllowanceAutofillReminder !== false ? true : false,
            };
            lastUsedEndDate = enddate;
            newItem.key = `${wo.id}-${newItem.to}`;

            result[endPeriod.isCurrent ? 'current' : statusToStages[endPeriod.status]].push(newItem);
          }
        }

        // end of work order period
        if (newItem != null && newItem.to === wo.interval.end) {
          break;
        }

        i += 1;
      }

      // There's no salary period that matches the ending of the work order, or a salary period that comes after the current period
      // We push the "orphaned" work order segment it into the "next" category, starting from the last used end date and ending with the work order's actual ending
      if (!foundFinalSalaryPeriod) {
        if (nextSalaryPeriod) {
          // const endPeriod = nextSalaryPeriod;
          enddate = moment.min(wo.interval.end, dateWithoutTime(nextSalaryPeriod.to));
        } else {
          enddate = wo.interval.end;
        }

        newItem = {
          disabled: false,
          from: moment(lastUsedEndDate).add(1, 'day'),
          locked: false,
          periodId: null,
          to: enddate,
          updatedAt: moment(),
          workOrder: wo,
          periodFrom: moment(lastUsedEndDate).add(1, 'day'),
          periodTo: enddate,
          // A stupid hack to turn null/undefined reminders into "true" because user specifically creates reminders with enabled: false
          allowanceAutofillReminder: false,
          key: `${wo.id}-${enddate}`,
        };
        result.next.push(newItem);
      }
    });

    // As of the time of writing, this callback is used to resolve a promise or update the state with the result
    if (cb) {
      cb(result);
    }
  }

  // Only called in the employer timelog tab
  async doGetSalaryPeriods(workOrders, cb) {
    this.periods = (await this.requests.SalaryPeriods.getAll()).map((item) => SalaryPeriod.fromJsonProperties(item));
    this.updateForWorkOrder.bind(this)(workOrders, cb);
  }

  @action.bound getSalaryPeriods(workOrders) {
    this.workOrdersWithMeta = fromPromise(new Promise((resolve) => this.doGetSalaryPeriods(workOrders, resolve)));
  }

  @action getSalaryPeriodAttachments() {
    const { attachments } = this;
    attachments.clear();
    this.requests.SalaryPeriodAttachments.getAll()
      .then((resp) => {
        const items = resp.map((item) => SalaryPeriodAttachment.fromJsonProperties(item));
        let key;
        return items.forEach((item) => {
          key = `${item.salaryPeriodId}-${item.workOrderId}`;
          if (!attachments.has(key)) {
            attachments.set(key, []);
          }

          if (item != null) {
            attachments.get(key).push(item);
          }
        });
      });
  }


  @action createSalaryPeriodAttachment(data) {
    this.requests.SalaryPeriodAttachments.create(data)
      .then(() => {
        this.refresh();
      })
      .catch((e) => {
        console.error(e);
      });
  }

  @action deleteSalaryPeriodAttachment(id, periodId, workOrderId) {
    this.requests.SalaryPeriodAttachments.del(id)
      .then(() => {
        this.attachments.set(
          `${periodId}-${workOrderId}`,
          this.attachments.get(`${periodId}-${workOrderId}`).filter((item) => item.id !== id),
        );
      })
      .catch((e) => {
        console.error(e);
      });
  }

  @action createAllowanceAutofillReminder(periodId, workOrderId, enabled) {
    this.requests.AllowanceAutofillReminders.create(periodId, workOrderId, enabled).then(() => {
      const foundWorkOrderWithMetaIndexCurrent = this.workOrdersWithMeta.value.current.findIndex((workOrderWithMeta) => workOrderWithMeta.workOrder.id === workOrderId);
      let foundWorkOrderWithMetaIndexNext = -1;

      if (foundWorkOrderWithMetaIndexCurrent === -1) {
        // Not found in the current work orders, search from the next orders
        foundWorkOrderWithMetaIndexNext = this.workOrdersWithMeta.value.next.findIndex((workOrderWithMeta) => workOrderWithMeta.workOrder.id === workOrderId);

        if (foundWorkOrderWithMetaIndexNext !== -1) {
          // Found from next work orders instead of current work orders
          const workOrderWithMetaNext = [...this.workOrdersWithMeta.value.next];
          const foundWorkOrderWithMeta = {...this.workOrdersWithMeta.value.next[foundWorkOrderWithMetaIndexNext], allowanceAutofillReminder: enabled };
          workOrderWithMetaNext[foundWorkOrderWithMetaIndexNext] = foundWorkOrderWithMeta;

          // Update the work order listing with the new allowanceAutofillReminder
          this.workOrdersWithMeta.value = { ...this.workOrdersWithMeta.value, next: workOrderWithMetaNext };
        }
      } else {
        // Found from current work orders
        const workOrderWithMetaCurrent = [...this.workOrdersWithMeta.value.current];
        const foundWorkOrderWithMeta = {...this.workOrdersWithMeta.value.current[foundWorkOrderWithMetaIndexCurrent], allowanceAutofillReminder: enabled };
        workOrderWithMetaCurrent[foundWorkOrderWithMetaIndexCurrent] = foundWorkOrderWithMeta;

        // Update the work order listing with the new allowanceAutofillReminder
        this.workOrdersWithMeta.value = { ...this.workOrdersWithMeta.value, current: workOrderWithMetaCurrent };
      }
    })
    .catch((e) => {
      console.error(e);
    });
  }

  async refresh() {
    this.getSalaryPeriodAttachments();
  }
}
