
import { observable, action } from 'mobx';
import { fromPromise } from 'mobx-utils';
import Purchaser from '../models/Purchaser';

export default class PurchaserStore {
  @observable purchasers = fromPromise.resolve([]);

  @observable purchaserFilters = []

  @observable purchaserDisplayColumns = [];

  constructor(uiStore, requests) {
    this.uiStore = uiStore;
    this.requests = requests;
  }

  @action setPurchaserFilters(column, filters) {
    this.purchaserFilters[column] = filters;
  }

  @action emptyFilters() {
    this.purchaserFilters = [];
  }

  @action setPurchaserDisplayColumns(column, display) {
    this.purchaserDisplayColumns[column] = display;
  }

  @action createPurchaser(item, resolve, reject) {
    return this.requests.Purchasers.create(item).then((purchaser) => {
      const newPurchaser = Purchaser.fromJsonProperties(purchaser);
      // const allWorkOrders = [].concat(this.uiStore.currentUser.workOrders, newWo);
      // this.purchasers.value = [...this.purchasers.value, newPurchaser];
      this.purchasers.value = [].concat(newPurchaser, this.purchasers.value);

      resolve(newPurchaser);
    }).catch((err) => reject(err));
  }

  @action updatePurchaser(item, resolve, reject) {
    this.requests.Purchasers.update(item).then((updatedPurchaser) => {
      const foundPurchaser = this.purchasers.value.find((purchaser) => purchaser.id === updatedPurchaser.id);
      if (foundPurchaser) {
        foundPurchaser.updatePropertiesFromJson(updatedPurchaser);
      }

      resolve(foundPurchaser);
    }).catch((err) => reject(err));
  }

  // @action async getPurchasers(user) {
  //   this.purchasers = await this.requests.Purchasers.getAll(user);
  // }

  @action async getPurchasers(user) {
    this.purchasers = fromPromise(new Promise((resolve) => this.requests.Purchasers.getAll(user).then((json) => {
      const purchaserObjects = json.map((purchaser) => Purchaser.fromJsonProperties(purchaser));
      resolve(purchaserObjects);
    })));
  }
}
