import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  // TextField,
  // Button,
  FormControl,
  // FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { Radio as RMWCRadio } from '@rmwc/radio';
import { isEqual } from 'lodash';
import './WorkTaskStatusDialog.css';
// import moment from 'moment';
// import NativeSelect from '@material-ui/core/NativeSelect';

const sortByCreatedAtAsc = (a, b) => {
  // Assuming ISO 8601 format, which is sortable like any string
  if (a.createdAt < b.createdAt) return -1;
  if (a.createdAt > b.createdAt) return 1;
  return 0;
};

@inject('uiStore', 't', 'employerWorkOrderStore')
@observer
class WorkTaskStatusDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStatus: null,
      statusChanged: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { workTask } = this.props;
    const { workTask: prevWorkTask } = prevProps;

    if (!isEqual(workTask, prevWorkTask)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedStatus: workTask?.status.id || null,
      });
    }
  }

  handleStatusChange = (event) => {
    this.setState({
      selectedStatus: event.target.value,
      statusChanged: true,
    });
  };

  saveWorkTaskStatus() {
    const { workTask, handleAttachmentAction, handleClose } = this.props;
    const { selectedStatus } = this.state;
    // Need to convert the files to signedIds on update or else the saving fails on server
    const sortedFiles = workTask.files?.sort(sortByCreatedAtAsc);
    const signedIds = sortedFiles?.map((file) => file.signedId);
    handleAttachmentAction({ ...workTask, statusId: selectedStatus, files: signedIds });

    handleClose();
  }

  // This is broken for some reason, which is why we use RMWC radio buttons instead
  renderRadioGroup() {
    const { workTask: { statuses } } = this.props;
    return (
      <FormControl>
        {statuses && (
          <RadioGroup
            aria-labelledby="billing-status-radio-buttons-group-label"
            name="radio-buttons-group"
            // defaultValue={workTask?.status}
            // defaultValue={1}
            // onClick={this.handleStatusChange}
            // value={selectedStatus}
            onChange={(evt) => this.setState({
              selectedStatus: Number(evt.currentTarget.value),
              statusChanged: true,
            })}
          >
            {statuses.map((status, index) => (
              <FormControlLabel key={status.id} value={index} control={<Radio style={{ color: 'black' }} />} label={status.name} />
            ))}
          </RadioGroup>
        )}
      </FormControl>
    );
  }

  renderRadio(status) {
    const { selectedStatus } = this.state;
    return (
      <RMWCRadio
        key={status.id}
        value={status.id}
        checked={selectedStatus === status.id}
        onChange={(evt) => this.setState({
          selectedStatus: Number(evt.currentTarget.value),
          statusChanged: true,
        })}
        style={{ color: '#FFC900' }}
      >
        {/* Työkohtaiset kirjaukset (voit lisätä töitä ja työnumeroita) */}
        {status.name}
      </RMWCRadio>
    );
  }

  render() {
    const {
      // t,
      open,
      handleClose,
      workTask,
    } = this.props;
    const { statusChanged } = this.state;

    return (
      <Dialog
        onClose={() => {
          this.setState({
            statusChanged: false,
          }, () => {
            handleClose();
          });
        }}
        open={open}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>
          {workTask?.identifiers ? `Tila: ${workTask.identifiers}` : 'Tila'}
        </DialogTitle>

        <DialogContent>
          {workTask?.statuses && (
            <div className="rmwc-radio-button-wrapper">
              {workTask.statuses.map((status) => (
                this.renderRadio(status)
              ))}
            </div>
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: statusChanged && 'space-between' }}>
          {statusChanged && (
            <Button onClick={() => this.saveWorkTaskStatus()} className="employer-accept-button">
              Tallenna
            </Button>
          )}
          <Button
            onClick={() => {
              this.setState({
                statusChanged: false,
              }, () => {
                handleClose();
              });
            }}
            className="employer-reject-button"
          >
            {statusChanged ? 'Peruuta' : 'Sulje'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default WorkTaskStatusDialog;
