import { observable } from 'mobx';

export default class UserRegistrationStore {
  @observable currentStage = 0;

  @observable currentStageStatus = 'wait';

  @observable errors = {};

  @observable user;

  constructor(user) {
    this.user = user;
  }
}
