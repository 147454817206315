import { action, observable, set } from 'mobx';
import moment from '../../utils/moment';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

// const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class Product {
  @observable createdAt;

  @observable createdBy;

  @observable updatedAt;

  @observable name;

  @observable hvacNumber;

  @observable code;

  @observable label;

  @observable price;

  @observable description;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      name: o.name,
      code: o.code,
      description: o.description,
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      name: o.name,
      hvacNumber: o.hvac_number,
      code: o.code,
      label: o.label,
      price: o.price,
      description: o.description,
      createdAt: moment(o.created_at).local(),
      updatedAt: moment(o.updated_at).local(),
      createdBy: o.created_by,
    };
    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const product = new Product({});
    product.updatePropertiesFromJson(object);
    return product;
  }

  static fromJson(json) {
    return Product.fromJsonProperties(JSON.parse(json));
  }
}
