/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import MessageIcon from '@material-ui/icons/Message';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import CalendarViewDayIcon from '@material-ui/icons/CalendarViewDay';
import { IconButton, Button, Link } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { Elevation } from '@rmwc/elevation';
import { Snackbar } from '@rmwc/snackbar';
import LoadingSpinner from '../shared/LoadingSpinner';
import moment from '../utils/moment';

import FilterListIcon from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';

import CalendarEntry from '../models/CalendarEntry';
import BasicResourceCalendarExample from '../resource-availability/BasicResourceCalendarExample';

import EmployerWorkOrderCard from './employerWorkOrderCard';
import FilterDialog from '../shared/FilterDialog';
import EmployerChatDialog from './EmployerChatDialog';
import './index.css';

const indexToStatus = [
  'ongoing',
  'past',
  'upcomingAll',
  'upcomingShort',
];

// This is a placeholder method, for quick demo of the calendar by using the old getAvailabilities implementation
// Ideally this kind of restructuring isn't needed at all and the data comes in a usable format from the API
// Users and their availabilities directly instead of multiple categories where the users are sorted into
const constructAvailabilitiesForScheduler = (availabilitiesObj) => {
  const resources = [];
  const events = [];

  if (availabilitiesObj.availabilities.negativeOverlaps) {
    const alreadyPushedResourceNames = [];

    availabilitiesObj.availabilities.negativeOverlaps.forEach((userAvailability) => {
      // resources.push({
      //   id: userAvailability.user.id,
      //   name: `${userAvailability.user.lastName}, ${userAvailability.user.firstName}`,
      // });

      if (userAvailability.overlappings.alreadyWorking) {
        userAvailability.overlappings.alreadyWorking.forEach((overlap) => {
          const calendarEntryRanges = CalendarEntry.packageJsonIntoRanges(overlap.calendar_entries);
          calendarEntryRanges.forEach((calendarEntryRange) => {
            const splitDateTimeRange = calendarEntryRange.datetime_range.split('..');
            const rangeFrom = moment(splitDateTimeRange[0]).toDate();
            const rangeTo = moment(splitDateTimeRange[1]).toDate();

            // const workOrderResourceId = `${overlap.work_order_name}-${resources.length}`;
            if (!alreadyPushedResourceNames.includes(overlap.work_order_name)) {
              resources.push({
                // We use the resources.length to make the id unique (same resource may appear twice in the same context)
                id: overlap.work_order_name,
                name: overlap.work_order_name,
              });

              alreadyPushedResourceNames.push(overlap.work_order_name);
            }

            events.push({
              id: `${overlap.work_order_name}-${userAvailability.user.id}-${rangeFrom}`,
              resourceId: overlap.work_order_name,
              start: rangeFrom,
              end: rangeTo,
              title: `${userAvailability.user.lastName}, ${userAvailability.user.firstName}`,
            });

            // events.push({
            //   id: overlap.work_order_name,
            //   resourceId: userAvailability.user.id,
            //   start: rangeFrom,
            //   end: rangeTo,
            //   title: overlap.work_order_name,
            // });
          });
        });
      }
    });
  }

  return { resources, events };
};

// Consider using mobx observer + fromPromise with the render() instead of this
// See: https://github.com/mobxjs/mobx-utils#frompromise
const renderWorkOrderCards = (items, noDataText, activeFilters, openEmployerChatDialog) => {
  if (!items || items.length === 0) {
    // DB query not yet complete
    return (
      <div style={{ textAlign: 'center', padding: '20px' }}>
        <LoadingSpinner color="black" />
      </div>
    )
  }
  // else if (items.length === 0) {
  //   return <p>{noDataText}</p>;
  // }

  return (
    items.map((woItem) => (
      <EmployerWorkOrderCard
        from={woItem.from}
        key={`woitem-${woItem.id}`}
        to={woItem.to}
        workOrder={woItem}
        // selectedFilter={selectedFilter}
        currentFilters={activeFilters}
        openEmployerChatDialog={openEmployerChatDialog}
      // addToWorkOrderCardList={addToWorkOrderCardList}
      />
    ))
  );
};

@inject('uiStore', 'loginStore', 't', 'employerWorkOrderStore')
@observer
class EmployerWorkOrders extends Component {
  constructor(props) {
    super(props);
    // const { employerWorkOrderStore, uiStore: { currentUser } } = this.props;

    // const showCalendar = new URLSearchParams(window.location.search).get('calendar');

    this.defaultWorkOrdersPerPage = 25;
    this.defaultOffset = 0;

    this.state = {
      showAddNewWorkOrder: false,
      filterDialogOpen: false,
      // currentWorkOrdersWithMeta: [] || workOrdersWithMeta,

      // Normally array, initializing as null here so that we can tell if the db query is complete or not
      currentWorkOrdersWithMeta: null,
      filterCount: 0,
      offset: 0,
      employerChatDialogOpen: false,
      chatId: null,

      workOrders: [],
      workOrdersCount: 0,
      workOrdersPerPage: this.defaultWorkOrdersPerPage,
      viewMode: props.uiStore.mobileMode ? 'mobile' : 'desktop',
      // showCalendar,
      // calendarMode: false,
    };

    this.toggleFilterDialog = this.toggleFilterDialog.bind(this);
    // this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    const { employerWorkOrderStore, uiStore, uiStore: { currentUser } } = this.props;
    // currentUser: { accountInfo: { thirdPartyApiIntegrations: { procountor } } }
    employerWorkOrderStore.getPurchasers(currentUser);
    employerWorkOrderStore.getLocations(currentUser);

    // if (procountor && procountor.active) {
    //   employerWorkOrderStore.getProcountorDimensions(currentUser);
    // }

    if (employerWorkOrderStore.workOrderCache && employerWorkOrderStore.workOrderCache.workOrders) {
      this.setState({
        workOrders: employerWorkOrderStore.workOrderCache.workOrders,
        workOrdersCount: employerWorkOrderStore.workOrderCache.count,
        offset: employerWorkOrderStore.workOrderCache.offset,
        workOrdersPerPage: employerWorkOrderStore.workOrderCache.limit,
      });
    } else if (employerWorkOrderStore.workOrderCache && !employerWorkOrderStore.workOrderCache.workOrders) {
      this.setState({
        offset: employerWorkOrderStore.workOrderCache.offset,
        workOrdersPerPage: employerWorkOrderStore.workOrderCache.limit,
      }, () => {
        this.queryWorkOrders(employerWorkOrderStore.workOrderCache.offset, employerWorkOrderStore.workOrderCache.limit, employerWorkOrderStore.workOrderCache.sort);
      });
    } else {
      this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage);
    }

    if (uiStore.currentView.snackbarMessage) {
      this.setState({
        snackbarMessage: uiStore.currentView.snackbarMessage,
        openSnackbar: true,
      })
    }
  }

  toggleAddNewWorkOrder = () => {
    const { showAddNewWorkOrder } = this.state;

    if (!showAddNewWorkOrder) {
      this.setState({
        showAddNewWorkOrder: true,
      });
    } else {
      this.setState({
        showAddNewWorkOrder: false,
      });
    }
  }

  addToTotalChatMessagesCount = (workOrderId) => {
    const { workOrders } = this.state;

    const foundWorkOrder = workOrders.find((wo) => wo.id === workOrderId);
    foundWorkOrder.chat.totalMessagesCount = (foundWorkOrder.chat.totalMessagesCount || 0) + 1;
  }

  // getMoreWorkOrders() {
  //   const { employerWorkOrderStore } = this.props;
  //   employerWorkOrderStore.getMoreEmployerWorkOrders().then((newWorkOrders) => {
  //     this.setState((prevState) => ({
  //       currentWorkOrdersWithMeta: [...prevState.currentWorkOrdersWithMeta, ...newWorkOrders],
  //     }));
  //   })
  // }

  toggleFilterDialog() {
    this.setState((prevState) => ({
      filterDialogOpen: !prevState.filterDialogOpen,
    }));
  }

  openEmployerChatDialog = (chatId) => {
    this.setState({
      employerChatDialogOpen: true,
      chatId: chatId,
    });
  }

  closeEmployerChatDialog = () => {
    this.setState({
      employerChatDialogOpen: false,
      chatId: null,
    });
  }

  // filterData(filteredData, filterCount, activeFilters) {
  //   const { employerWorkOrderStore } = this.props;
  //   employerWorkOrderStore.setFilters(activeFilters);

  //   this.setState({
  //     currentWorkOrdersWithMeta: filteredData,
  //     filterCount,
  //     // activeFilters,
  //   });
  // }

  queryWorkOrders(offset, defaultWorkOrdersPerPage = null, sort = null) {
    const { employerWorkOrderStore } = this.props;
    const { workOrdersPerPage } = this.state;

    const limit = workOrdersPerPage || defaultWorkOrdersPerPage;

    this.setState({
      isLoading: true,
      offset,
      workOrders: [],
      // workOrdersPerPage: limit,
    }, () => {
      employerWorkOrderStore.getEmployerWorkOrdersPaginated(offset, limit, sort).then((res) => {
        this.setState({
          isLoading: false,
          // page: res.page,
          // sortOrder,
          sort,
          workOrders: res.workOrders,
          workOrdersCount: res.count,
        }, () => {
          employerWorkOrderStore.cacheWorkOrders(res.workOrders, res.count, limit, offset, sort);
        });
      });
    });
  }

  convertSortOrderObjectToString = (sortObject) => {
    const { name, direction } = sortObject;
    if (!name || !direction) {
      return '';
    }

    let sort;
    if (!name.includes('.')) {
      // Assuming a work order column by default, we need the 'dot notation' here for postgreSQL query generation
      sort = `work_order.${name}-${direction}`;
    } else {
      sort = `${name}-${direction}`;
    }
    return sort;
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    const sort = this.convertSortOrderObjectToString(sortOrder);
    const offset = page * rowsPerPage;
    this.queryWorkOrders(offset, null, sort);
  };

  changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
    const sort = this.convertSortOrderObjectToString(sortOrder);

    this.setState({
      workOrdersPerPage: rowsPerPage,
    }, () => {
      const offset = page * rowsPerPage;
      this.queryWorkOrders(offset, null, sort);
    })
  }

  changeSorting = (column, direction) => {
    let sort = this.convertSortOrderObjectToString({ name: column, direction: direction });

    this.setState({
      sort,
    }, () => {
      // Re-query using the new sorting, starting at offset 0
      this.queryWorkOrders(0, null, sort);
    });
  }

  // renderResourceCalendarView() {
  //   const { schedulerData, originalData } = this.state;

  //   return (
  //     <div style={{ width: '100%' }}>
  //       {schedulerData && (
  //         <BasicResourceCalendarExample originalData={originalData} schedulerData={schedulerData} queryData={this.getAvailabilitiesScheduler} />
  //       )}
  //     </div>
  //   );
  // }

  renderWorkOrderTable() {
    const { uiStore, uiStore: { mobileMode, currentUser: { accountInfo } }, t, employerWorkOrderStore } = this.props;
    const { workOrders, workOrdersCount, isLoading, workOrdersPerPage, offset } = this.state;

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'timeStatus',
        label: t('work_order.time_status'),
        options: {
          display: employerWorkOrderStore.billingDisplayColumns.timeStatus === undefined ? true : employerWorkOrderStore.billingDisplayColumns.timeStatus,
          customBodyRender: (value) => {
            let colorClass;
            if (value === 'upcoming') {
              colorClass = 'invitations-warning';
            } else if (value === 'ongoing') {
              colorClass = 'invitations-ok';
            }

            return (
              <div className={colorClass} style={{ width: 'fit-content' }}>
                {t(`work_order.time_statuses.${value}`)}
              </div>
            );
          },
          filter: true,
          sort: true,
          filterOptions: {
            renderValue: (v) => v ? t(`work_order.time_statuses.${v}`) : ''
          },
          customFilterListOptions: {
            render: (v) => v ? `${t('work_order.time_status')}: ${t(`work_order.time_statuses.${v}`)}` : ''
          },
          filterList: employerWorkOrderStore.billingFilters.timeStatus,
          filterType: 'checkbox',
        },
      },
      {
        name: 'name',
        label: 'Nimi',
        options: {
          customBodyRenderLite: (dataIndex) => {
            if (accountInfo.billingIdsEnabled && workOrders[dataIndex].billingId) {
              return (
                <div>
                  <span>{workOrders[dataIndex].name}</span>
                  <span className="work-order-billing-id">
                    {` ${workOrders[dataIndex].billingId}`}
                  </span>
                </div>
              );
            } else {
              return workOrders[dataIndex].name;
            }
          },
          filter: false,
          display: employerWorkOrderStore.billingDisplayColumns.name === undefined ? true : employerWorkOrderStore.billingDisplayColumns.name,
        },
      },
      {
        name: 'invitationsCount',
        label: 'Henkilöstö',
        options: {
          customBodyRenderLite: (dataIndex) => {
            const invitationsCount = workOrders[dataIndex].invitationsCount;
            const participantsCount = workOrders[dataIndex].participants.length;
            let invitationsClass = 'invitations-neutral';
            if (invitationsCount && invitationsCount > participantsCount) {
              invitationsClass = 'invitations-warning';
            } else if (invitationsCount && invitationsCount <= participantsCount) {
              invitationsClass = 'invitations-ok';
            }

            return (
              <span
                role="button"
                className={invitationsClass}
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  // color: 'white',
                  width: 'fit-content',
                  cursor: 'pointer',
                }}
              onClick={(e) => {
                e.stopPropagation();
                uiStore.setActiveTabAndShowWorkOrder('edit', workOrders[dataIndex].id, 1);
              }}
              >
                <PeopleOutlineIcon
                  fontSize="small"
                  style={{
                    color: 'black',
                    opacity: '1 !imporant',
                  }}
                />
                <span>
                  {invitationsCount && invitationsCount !== 0 ? `${participantsCount}/${invitationsCount}` : participantsCount}
                </span>
              </span>
            );
          },
          sort: false,
        }
      },
      {
        name: 'from',
        label: 'Alkaa',
        options: {
          customBodyRender: (value) => (
            <span>{value.format('D.M.YY')}</span>
          ),
          filter: false,
          display: employerWorkOrderStore.billingDisplayColumns.from === undefined ? true : employerWorkOrderStore.billingDisplayColumns.from,
        },
      },
      {
        name: 'to',
        label: 'Päättyy',
        options: {
          customBodyRender: (value) => (
            <span>{value.format('D.M.YY')}</span>
          ),
          filter: false,
          display: employerWorkOrderStore.billingDisplayColumns.to === undefined ? true : employerWorkOrderStore.billingDisplayColumns.to,
        },
      },
      {
        name: 'location.name',
        label: 'Kohde',
        options: {
          filter: false,
          display: employerWorkOrderStore.billingDisplayColumns['location.name'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['location.name'],
        },
      },
      {
        name: 'purchaser.name',
        label: 'Tilaaja',
        options: {
          filter: false,
          display: employerWorkOrderStore.billingDisplayColumns['purchaser.name'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['purchaser.name'],
        },
      },
      {
        name: 'chat.totalMessagesCount',
        label: 'Viestit',
        options: {
          customBodyRenderLite: (dataIndex) => {
            return (
              <span
                role="button"
                style={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              onClick={(e) => {
                e.stopPropagation();
                this.openEmployerChatDialog(workOrders[dataIndex].chat.id);
              }}
              >
                {workOrders[dataIndex].chat.totalMessagesCount !== 0 && (
                  <MessageIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                )}
                <div className="invitations-neutral" style={{ minWidth: '18px', textAlign: 'center' }}>
                  {/* {workOrders[dataIndex].chat.totalMessagesCount} */}
                  {workOrders[dataIndex].chat.totalMessagesCount === 0 ? '-' : workOrders[dataIndex].chat.totalMessagesCount}
                </div>
              </span>
            );
          },
          sort: false,
          filter: true,
          filterOptions: {
            display: 'block',
            renderValue: (value) => value ? t(`bill.statuses.${value}`) : t('bill.statuses.not_set')
          },
          customFilterListOptions: {
            render: (value) => value ? `${t('bill.status')}: ${t(`bill.statuses.${value}`)}` : `${t('bill.status')}: ${t(`bill.statuses.not_set`)}`
          },
          filterType: 'checkbox',
          filterList: employerWorkOrderStore.billingFilters['bill.status'],
          display: employerWorkOrderStore.billingDisplayColumns['bill.status'] === undefined ? true : employerWorkOrderStore.billingDisplayColumns['bill.status'],
        },
      },
      {
        name: 'workTasks',
        label: 'Työt',
        options: {
          sort: false,
          filter: false,
          empty: true,
          display: accountInfo.hourlyWorkOrdersEnabled ? true : 'excluded',
          customBodyRenderLite: (dataIndex) => {
            return (
              workOrders[dataIndex].workHourType === 'hourly' ? (
                <span
                  role="button"
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => uiStore.setActiveTabAndShowWorkOrder('edit', workOrders[dataIndex].id, 4)}
                >
                  <FormatListBulletedIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                  <span className="invitations-neutral">
                    {workOrders[dataIndex].workTasksCount === 0 ? '-' : workOrders[dataIndex].workTasksCount}
                  </span>
                </span>
              ) : (
                <span>-</span>
              )
            );
          }
        },
      },
      {
        name: 'attachments',
        label: 'Liitteet',
        options: {
          sort: false,
          filter: false,
          empty: true,
          display: employerWorkOrderStore.billingDisplayColumns.attachments === undefined ? true : employerWorkOrderStore.billingDisplayColumns.attachments,
          customBodyRenderLite: (dataIndex) => {
            return (
              <span
                role="button"
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => uiStore.setActiveTabAndShowWorkOrder('edit', workOrders[dataIndex].id, 3)}
              >
                {workOrders[dataIndex].attachments.length !== 0 && (
                  <AttachFileIcon
                    fontSize="small"
                    style={{
                      color: 'black',
                      opacity: '1 !imporant',
                    }}
                  />
                )}
                <div className="invitations-neutral" style={{ minWidth: '18px', textAlign: 'center' }}>
                  {workOrders[dataIndex].attachments.length === 0 ? '-' : workOrders[dataIndex].attachments.length}
                </div>
              </span>
            )
          }
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          // display: employerWorkOrderStore.billingDisplayColumns.report === undefined ? true : employerWorkOrderStore.billingDisplayColumns.report,
          customBodyRenderLite: (dataIndex) => {
            return (
              <Link
                component="button"
                onClick={() => uiStore.showEmployerWorkOrder('edit', workOrders[dataIndex].id)}
              >
                Muokkaa
              </Link>
            )
          }
        },
      },
    ];

    const options = {
      page: Math.floor((offset + 1) / workOrdersPerPage),
      serverSide: true,
      count: workOrdersCount,
      filter: false,
      sort: true,
      filterType: 'checkbox',
      responsive: 'standard',
      search: false,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: false,
      download: false,
      print: false,
      enableNestedDataAccess: '.',
      rowsPerPage: workOrdersPerPage,
      rowsPerPageOptions: [25, 50, 100],
      setTableProps: () => ({ className: 'mui-data-table-billing' }),
      setRowProps: () => {
        return (
          {
            style: {
              cursor: 'pointer',
            },
          }
        );
      },
      onRowClick: this.handleRowClick,
      textLabels: {
        body: {
          noMatch: (
            isLoading ? (
              <div style={{ textAlign: uiStore.mobileMode ? 'left' : 'center', padding: '20px', fontSize: '14px' }}>
                <LoadingSpinner color="black" />
              </div>
            ) : (
              'Keikkoja ei löytynyt'
            )
          )
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        pagination: {
          next: "Seuraava sivu",
          previous: "Edellinen",
          rowsPerPage: "Rivejä sivulla:",
          displayRows: "/",
        },
        filter: {
          all: "Kaikki",
          title: "SUODATIN",
          reset: "Tyhjennä",
        },
        viewColumns: {
          title: "Näytä sarakkeet",
          titleAria: "Näytä/piilota sarakkeet",
        },
        selectedRows: {
          text: "rivi(ä) valittu",
          delete: "Poista",
          deleteAria: "Poista valitut rivit",
        },
      },
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
            break;
          case 'changeRowsPerPage':
            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
          case 'sort':
            if (tableState.sortOrder.name) {
              this.changeSorting(tableState.sortOrder.name, tableState.sortOrder.direction);
            }
            break;
          default:
            console.log('action not handled.');
        }
      },
    };

    return (
      <div
        style={{
          width: '100%',
          padding: '20px',
          // display: mobileMode ? 'none' : 'initial',
        }}
        className={`mui-billing-table ${mobileMode ? 'mobile-billing-table' : null}`}
      >
        <MUIDataTable
          // title={
          //   isLoading && (
          //     <div style={{ textAlign: 'center', padding: '20px', fontSize: '14px' }}>
          //       Ladataan
          //       <LoadingSpinner color="black" />
          //     </div>
          //   )
          // }
          data={workOrders}
          columns={columns}
          options={options}
        />
      </div>
    );
  }

  renderWorkOrderCardSubview() {
    const { employerWorkOrderStore: { workOrderFilters }, t, uiStore } = this.props;
    const { workOrders, offset, workOrdersCount, workOrdersPerPage } = this.state;

    const previousPageDisabled = (offset - workOrdersPerPage) < 0;
    const nextPageDisabled = (offset + workOrdersPerPage) >= workOrdersCount;

    return (
      <>
        {workOrders && (
          <>
            <GridCell
              className="employer-work-order-cards"
              desktop={12}
              tablet={8}
              mobile={4}
            >
              {renderWorkOrderCards(workOrders, 'Valinnalla ei löytynyt keikkoja', workOrderFilters ? workOrderFilters : null, this.openEmployerChatDialog)}
            </GridCell>

            {/* {(workOrderFilters?.length === 0 || workOrderFilters?.find((filter) => filter.key === 'status' && filter.value === 'past')) && (
              <GridCell
                span={12}
                style={{ textAlign: 'end', margin: '16px' }}
              >
                <Button {...{
                  type: 'button',
                  style: { color: 'black' },
                  className: 'mdc-button',
                  onClick: () => this.getMoreWorkOrders(),
                }}
                >
                  {t('calendar.employee.show_more')}
                </Button>
              </GridCell>
            )} */}

            <GridCell
              span={12}
            >
              <div
                style={{ width: '100%', fontSize: '14px', display: 'flex', justifyContent: 'space-between', padding: '0 10px' }}
              >
                <div
                  style={{ textAlign: 'left', cursor: 'pointer' }}
                  onClick={() => {
                    if (!previousPageDisabled) {
                      this.queryWorkOrders(offset - workOrdersPerPage);
                    } else {
                      console.log('DISABLED');
                    }
                  }}
                >
                  <ArrowLeftIcon style={{ fontSize: '42px' }} />
                </div>
                <div>
                  <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    {`${offset + 1} - ${offset + 25} (${workOrdersCount})`}
                  </div>
                </div>
                <div
                  style={{ textAlign: 'right', cursor: 'pointer' }}
                  onClick={() => {
                    if (!nextPageDisabled) {
                      this.queryWorkOrders(offset + workOrdersPerPage);
                    } else {
                      console.log('DISABLED');
                    }
                  }}
                >
                  <ArrowRightIcon style={{ fontSize: '42px' }} />
                </div>
              </div>
            </GridCell>

            <GridCell
              span={12}
              className="employer-work-order-sticky-bottom"
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0)',
              }}
            >
              <Elevation
                z={5}
                style={{
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}
              >
                <Button
                  startIcon={<AddIcon />}
                  className="employer-accept-button"
                  style={{
                    margin: '10px',
                    width: 'fit-content',
                  }}
                  onClick={() => uiStore.showEmployerWorkOrder('new')}
                >
                  Uusi keikka
                </Button>
              </Elevation>
            </GridCell>
          </>
        )}
      </>
    );
  }

  toggleViewMode() {
    this.setState((prevState) => ({
      viewMode: prevState.viewMode === 'mobile' ? 'desktop' : 'mobile',
    }));
  }

  // toggleCalendarMode() {
  //   this.setState((prevState) => ({
  //     calendarMode: !prevState.calendarMode,
  //   }), () => {
  //     console.log('Should query data here');
  //     // const nextWeek = moment().add(1, 'weeks');
  //     // const nextWeekStart = nextWeek.startOf('isoWeek').local() // .toDate();
  //     // // The subtract hours is a stupid hack to get rid of the GMT+0200 that causes the last day to go past midnight (01:59:59) and actually return the incorrect date
  //     // const nextWeekEnd = nextWeek.endOf('isoWeek').local() //.subtract(3, 'hours') // .toDate();
  //     // const formattedFrom = nextWeekStart.format('YYYY-MM-DD');
  //     // const formattedTo = nextWeekEnd.format('YYYY-MM-DD');

  //     const thisWeekStart = moment().startOf('isoWeek').local();
  //     const thisWeekEnd = moment().endOf('isoWeek').local();
  //     const formattedFrom = thisWeekStart.format('YYYY-MM-DD');
  //     const formattedTo = thisWeekEnd.format('YYYY-MM-DD');

  //     this.getAvailabilities(formattedFrom, formattedTo);
  //   });
  // }

  getAvailabilities(from, to) {
    // Need the loading spinner, but for that we need to think about promise handling. Compare with employerWorkOrderDetail.jsx invitation tab
    const { employerWorkOrderStore } = this.props;
    const availabilitiesPromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.getAvailabilitiesMultipleWeeks(null, from, to, resolve, reject)));
    when(
      () => availabilitiesPromise.state !== 'pending',
      () => {
        availabilitiesPromise.case({
          pending: () => {
          },
          rejected: (err) => {
            console.error('ERROR: ', err);
          },
          fulfilled: (result) => {
            this.setState({
              availabilities: result,
            }, () => {
              // this.setSchedulerData();
            });
          },
        });
      },
    );
  }

  // setSchedulerData() {
  //   const { availabilities } = this.state;
  //   const firstWeekAvailabilities = availabilities[0];

  //   // eslint-disable-next-line new-cap
  //   const schedulerData = new SchedulerData(new moment(firstWeekAvailabilities.from).format(DATE_FORMAT), ViewTypes.Week);
  //   schedulerData.setLocaleMoment(moment);
  //   // schedulerData.config.schedulerWidth = '400';
  //   schedulerData.config.schedulerWidth = '100%';
  //   schedulerData.config.besidesWidth = '255';
  //   // const resourceTableWidth = '500px';
  //   // schedulerData.config.weekResourceTableWidth = resourceTableWidth;
  //   // schedulerData.config.monthResourceTableWidth = resourceTableWidth;
  //   // schedulerData.config.quarterResourceTableWidth = resourceTableWidth;
  //   // schedulerData.config.yearResourceTableWidth = resourceTableWidth;

  //   schedulerData.config.nonAgendaDayCellHeaderFormat = 'HH:mm';
  //   schedulerData.config.nonAgendaOtherCellHeaderFormat = 'dd DD.MM.';
  //   schedulerData.config.resourceName = 'Keikka';
  //   schedulerData.config.nonWorkingTimeHeadBgColor = 'white';
  //   schedulerData.config.nonWorkingTimeBodyBgColor = 'white';

  //   // At the time of writing, the popover doesn't work without an antd style import
  //   // However, only one imported antd stylesheet appears to be applied correctly (e.g. only 'antd/es/radio/style/css' or 'antd/es/popover/style/css', but not both?)
  //   schedulerData.config.eventItemPopoverEnabled = false;
  //   schedulerData.config.defaultEventBgColor = '#F6EDC6';
  //   schedulerData.config.calendarPopoverEnabled = false;
  //   // Does not adjust the height of the calendar rows themselves, causing overlap
  //   // schedulerData.config.eventItemHeight = '45';
  //   schedulerData.config.views = [
  //     /* eslint-disable object-curly-newline */
  //     // { viewName: 'Päivä', viewType: ViewTypes.Day, showAgenda: false, isEventPerspective: false },
  //     {},
  //     { viewName: 'Viikko', viewType: ViewTypes.Week, showAgenda: false, isEventPerspective: false },
  //     { viewName: 'Kuukausi', viewType: ViewTypes.Month, showAgenda: false, isEventPerspective: false },
  //     { viewName: 'Vuosineljännes', viewType: ViewTypes.Quarter, showAgenda: false, isEventPerspective: false },
  //     { viewName: 'Vuosi', viewType: ViewTypes.Year, showAgenda: false, isEventPerspective: false },
  //     /* eslint-disable object-curly-newline */
  //   ];

  //   const schedulerAvailabilities = constructAvailabilitiesForScheduler(firstWeekAvailabilities);
  //   schedulerData.setResources(schedulerAvailabilities.resources);
  //   schedulerData.setEvents(schedulerAvailabilities.events);

  //   this.setState({
  //     schedulerData,
  //     originalData: schedulerAvailabilities,
  //   });
  // }

  // getAvailabilitiesScheduler = (from, to, rejectedCb, fulfilledCb) => {
  //   // Need the loading spinner, but for that we need to think about promise handling. Compare with employerWorkOrderDetail.jsx invitation tab
  //   const { employerWorkOrderStore } = this.props;
  //   const availabilitiesPromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.getAvailabilitiesMultipleWeeks(null, from, to, resolve, reject)));
  //   when(
  //     () => availabilitiesPromise.state !== 'pending',
  //     () => {
  //       availabilitiesPromise.case({
  //         pending: () => {
  //         },
  //         rejected: (err) => {
  //           console.error('ERROR: ', err);
  //           if (rejectedCb) {
  //             rejectedCb();
  //           }
  //         },
  //         fulfilled: (result) => {
  //           // We use the zero index here because the employerWorkOrderStore.getAvailabilitiesMultipleWeeks return the queried period + two next weeks
  //           // And we are only interested in the first period in the current demo
  //           const reconstructedResults = constructAvailabilitiesForScheduler(result[0]);
  //           if (fulfilledCb) {
  //             fulfilledCb(reconstructedResults);
  //           }
  //           return reconstructedResults;
  //         },
  //       });
  //     },
  //   );
  // }

  handleRowClick = (rowData, rowMeta) => {
    const { uiStore } = this.props;
    const { workOrders } = this.state;

    const selectedRowIndex = rowMeta.dataIndex;
    const clickedWorkOrder = workOrders[selectedRowIndex];

    uiStore.showEmployerWorkOrder('edit', clickedWorkOrder.id);
  }

  renderSnackbar() {
    const { openSnackbar, snackbarMessage } = this.state;

    return (
      <Snackbar
        open={openSnackbar}
        style={{
          textAlign: 'center',
          top: '0'
        }}
        onClose={() => {
          this.setState({
            openSnackbar: false,
            saveStatusMessage: '',
          });
        }}
        message={snackbarMessage}
        timeout={2000}
      />
    )
  }

  render() {
    const { employerWorkOrderStore: { workOrderFilters }, t, uiStore } = this.props;
    const {
      filterDialogOpen,
      filterCount,
      currentWorkOrdersWithMeta,
      employerChatDialogOpen,
      chatId,
      workOrders,
      offset,
      workOrdersCount,
      workOrdersPerPage,
      viewMode,
      // showCalendar,
      // calendarMode,
      // schedulerData,
      // originalData,
    } = this.state; // activeFilters

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              <div
                style={{
                  padding: '10px',
                  width: '100%',
                  // textAlign: 'end',
                  // maxWidth: '350px',
                  display: 'flex',
                }}
                className="filter-button"
              >
                {viewMode === 'desktop' && (
                  <>
                    <Button
                      startIcon={<AddIcon />}
                      className="employer-accept-button"
                      style={{
                        marginRight: '10px',
                        width: 'fit-content',
                      }}
                      onClick={() => uiStore.showEmployerWorkOrder('new')}
                    >
                      {/* {uiStore.mobileMode ? 'Uusi' : 'Uusi keikka'} */}
                      Uusi keikka
                    </Button>

                    {/* {showCalendar && (
                      // <Button onClick={() => this.toggleCalendarMode()}>

                      // </Button>
                      <IconButton
                        style={{
                          // display: uiStore.mobileMode ? 'none' : 'initial',
                          padding: 0,
                          width: '60px',
                          marginLeft: '20px',
                          height: '36px',
                        }}
                        className="employer-reject-button"
                        onClick={() => this.toggleCalendarMode()}
                        size="small"
                      >
                        {calendarMode ? <FormatListBulletedIcon fontSize="inherit" /> : <CalendarViewDayIcon fontSize="inherit" />}
                      </IconButton>
                    )} */}
                  </>
                )}

                {/* <Button
                  type="button"
                  onClick={() => this.toggleFilterDialog()}
                  className="employer-reject-button"
                  startIcon={<FilterListIcon />}
                  style={{
                    width: '100%',
                    maxWidth: '335px',
                    height: '36px',
                  }}
                >
                  Suodata
                  {filterCount !== 0 && (
                    <span style={{ marginLeft: '5px' }}>
                      (
                      {filterCount}
                      )
                    </span>
                  )}
                </Button> */}

                {/* {uiStore.mobileMode && (
                  <IconButton
                    style={{
                      // display: uiStore.mobileMode ? 'none' : 'initial',
                      padding: 0,
                      width: '60px',
                      marginLeft: '20px',
                      height: '36px',
                    }}
                    className="employer-reject-button"
                    onClick={() => this.toggleViewMode()}
                    size="small"
                  >
                    {viewMode === 'table' ? <DashboardIcon fontSize="inherit" /> : <FormatListBulletedIcon fontSize="inherit" />}
                  </IconButton>
                )} */}
              </div>
            </GridCell>

            {/* {calendarMode && (
              <GridCell
                span={12}
              >
                <div style={{ width: '100%' }}>
                  {schedulerData && (
                    <BasicResourceCalendarExample originalData={originalData} schedulerData={schedulerData} queryData={this.getAvailabilitiesScheduler} />
                  )}
                </div>
              </GridCell>
            )} */}

            {/* Clean this if structure */}
            {viewMode === 'mobile' && (
              this.renderWorkOrderCardSubview(workOrders)
            )}

            {/* Clean this if structure */}
            {viewMode === 'desktop' && (
              <GridCell span={12}>
                {this.renderWorkOrderTable(workOrders, 'Ei keikkoja')}
              </GridCell>
            )}
          </GridInner>
        </Grid>

        {/* {workOrdersWithMeta && (
          <FilterDialog
            open={filterDialogOpen}
            toggleDialog={this.toggleFilterDialog}
            // filterHours={filterHours}
            originalData={workOrdersWithMeta}
            filterOptions={{
              // NOTE: Using lodash's get() let's you use nested attributes as a single string like so: 'work_order.date'
              filters: [
                { title: 'Tila', key: 'status', translate: true },
                { title: 'Tilaaja', key: 'purchaser.name', translate: false },
                { title: 'Kohde', key: 'location.name', translate: false },
                { title: 'Vastaava työnjohtaja', key: 'responsibleEmployerNames' },
                { title: 'Tuntikirjausten hyväksyjä', key: 'workHourAccepterNames' },
              ],
              defaultFilters: [
                {
                  filter: {
                    key: 'status',
                    value: 'ongoing',
                  },
                  // Required for generating buttons for these default filters so that users can turn them off when there's no matching data
                  // IMPORTANT: Needs to match the filterOptions.filters object
                  filterMeta: {
                    title: 'Tila',
                    key: 'status',
                    translate: true,
                  },
                },
              ],
              // If the default filter returns no data, we try these instead so that we always have something in the view
              alternativeDefaultFilters: [
                { key: 'status', value: 'upcomingShort' },
                { key: 'status', value: 'upcomingAll' },
                { key: 'status', value: 'past' },
              ],
            }}
            dataTitle="Suodata keikat"
            filterData={this.filterData}
            translationPrefix="work_order_filters"
            previousFilters={workOrderFilters}
          />
        )} */}

        <EmployerChatDialog chatId={chatId} open={employerChatDialogOpen} onClose={this.closeEmployerChatDialog} addToTotalChatMessagesCount={this.addToTotalChatMessagesCount} />
        {this.renderSnackbar()}
      </>
    );
  }
}

export default EmployerWorkOrders;
