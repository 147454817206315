import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog, DialogActions, DialogButton, DialogContent, DialogTitle,
} from '@rmwc/dialog';
import { Checkbox } from '@rmwc/checkbox';
import { Typography } from '@rmwc/typography';
import { Radio } from '@rmwc/radio';
import { Grid, GridCell } from '@rmwc/grid';
import { TextField } from '@rmwc/textfield';
import { Button } from '@material-ui/core';

// import { buttonColors } from '../calendar/EmployeeCalendar';
// import { handleProgress } from '../models/user/user-ui-avatar';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('employerWorkOrderStore', 'uiStore', 't')
@observer
class WorkOrderSettingsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      // errors: {},
      inputChanged: false,
      confirmDialogOpen: false,
      confirmDeleteDialogOpen: false,
    };
  }

  componentDidMount() {
    const { workOrderId } = this.props;

    this.setState({
      workOrderId,
    });
  }

  addTextField(attr, label, required, styles = null, textarea) {
    const { errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        label,
        required,
        name: attr,
        rows: 5,
        // invalid: errors[attr],
        helpText: errors[attr] && errors[attr],
        textarea,
        onChange: (event) => {
          const { value } = event.target;
          this.setState({
            [attr]: value,
            inputChanged: true,
          });

          if (attr === 'title') {
            delete errors[attr];
          } else {
            delete errors.attachments;
          }
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: this.state[attr] != null ? this.state[attr] : '', // eslint-disable-line
      }}
      />
    );
  }

  addCheckBox(attr, label, shouldUpdateSettings = false) {
    // shouldUpdateSettings = we update workOrder.settings.attr instead of workOrder.attr
    const { workOrder, updateAttr, updateSetting } = this.props;
    let checked;
    if (shouldUpdateSettings) {
      checked = workOrder?.settings?.[attr] != null ? workOrder?.settings?.[attr] : false;
    } else {
      checked = workOrder?.[attr] != null ? workOrder[attr] : false;
    }

    return (
      <Checkbox
        checked={checked}
        // className: 'border-on-dark',
        // disabled: this.props.disabled,
        label={label}
        style={{
          marginTop: '10px',
          marginLeft: 0,
          marginRight: 0,
          height: '44px',
          margin: 0,
          minWidth: '40px',
          padding: 0,
          color: 'black',
        }}
        onChange={(event) => {
          const value = event.target.checked;
          if (shouldUpdateSettings) {
            updateSetting(attr, value);
          } else {
            updateAttr(attr, value);
          }
        }}
      />
    );
  }

  addSettingCheckbox(attr, label) {
    const { workOrder, updateSetting } = this.props;

    return (
      <Checkbox
        checked={workOrder?.settings?.[attr] != null ? workOrder.settings[attr] : false}
        // className: 'border-on-dark',
        // disabled: this.props.disabled,
        label={label}
        style={{
          marginTop: '10px',
          marginLeft: 0,
          marginRight: 0,
          height: '44px',
          margin: 0,
          minWidth: '40px',
          padding: 0,
          color: 'black',
        }}
        onChange={(event) => {
          const value = event.target.checked;
          updateSetting(attr, value);
        }}
      />
    );
  }

  renderActionButtons() { // eslint-disable-line
    const { handleClose } = this.props;
    const {
      id,
      title,
      description,
      filename,
    } = this.state;

    if (!id && !title && !description && !filename) {
      return (
        <DialogActions
          style={{
            justifyContent: 'end',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="employer-reject-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => handleClose()}
          >
            Sulje
          </DialogButton>
        </DialogActions>
      );
    }
  }

  renderTitle(titleText) { // eslint-disable-line
    return (
      <GridCell
        span={12}
        style={{
          margin: '10px -16px 0 -16px',
        }}
      >
        <Typography
          style={{
            paddingLeft: '25px',
            color: 'black',
            fontSize: '16px',
            margin: 0,
          }}
          use="headline6"
          tag="h2"
          className="headline"
        >
          {titleText}
        </Typography>
      </GridCell>
    );
  }

  render() {
    const {
      uiStore: { currentUser },
      open,
      workOrder,
      updateAttr,
      t,
      cloneWorkOrder,
      deleteWorkOrder,
      handleClose,
      updateSetting,
    } = this.props;
    const { confirmDialogOpen, confirmDeleteDialogOpen } = this.state;

    return (
      <>
        <Dialog
          className="modal-size employer-modal"
          open={open}
          onClose={() => handleClose()}
        >
          <DialogTitle
            style={{ paddingLeft: '20px' }}
          >
            Keikan asetukset
          </DialogTitle>
          <DialogContent>
            <Grid>
              {currentUser.accountInfo.hourlyWorkOrdersEnabled && // eslint-disable-line
                (
                  <>
                    {this.renderTitle('Tuntikirjausasetukset')}
                    <GridCell span={12}>
                      <Radio
                        value="daily"
                        checked={workOrder?.workHourType === 'daily' || (!workOrder?.workHourType ? 'daily' : '')}
                        onChange={(evt) => updateAttr('workHourType', evt.currentTarget.value)}
                        style={{ color: 'black' }}
                      >
                        Päiväkohtaiset kirjaukset
                      </Radio>

                      <br />

                      <Radio
                        value="hourly"
                        checked={workOrder?.workHourType === 'hourly'}
                        onChange={(evt) => {
                          updateAttr('workHourType', evt.currentTarget.value);
                          if (evt.currentTarget.value && !workOrder.settings) {
                            // True by default
                            updateSetting('employeesCanAddFilesToWorkTasks', true);
                          }
                        }}
                        style={{ color: 'black' }}
                      >
                        {/* Työkohtaiset kirjaukset (voit lisätä töitä ja työnumeroita) */}
                        Työkohtaiset kirjaukset
                      </Radio>
                      <div
                        style={{
                          fontSize: '12px',
                          color: 'gray',
                          marginLeft: '45px',
                          lineHeight: '15px',
                        }}
                      >
                        Työkohtaiset kirjaukset käyttävät keikalle lisättyjä töitä
                      </div>
                    </GridCell>

                    {workOrder?.workHourType === 'hourly' && (
                      <GridCell span={12} style={{ paddingLeft: '35px', display: 'flex', flexDirection: 'column' }}>
                        {/* Enabled by default */}
                        {this.addSettingCheckbox('employeesCanAddFilesToWorkTasks', 'Työntekijät voivat lisätä kuvia töille')}
                        <br />
                        <Radio
                          value={false}
                          checked={workOrder?.settings?.employeesCanViewAllTasks === false || !workOrder?.settings?.employeesCanViewAllTasks}
                          onChange={() => updateSetting('employeesCanViewAllTasks', false)}
                          style={{ color: 'black' }}
                        >
                          Työntekijät näkevät vain omat työt
                        </Radio>
                        <Radio
                          // eslint-disable-next-line react/jsx-boolean-value
                          value={true}
                          checked={workOrder?.settings?.employeesCanViewAllTasks}
                          onChange={() => updateSetting('employeesCanViewAllTasks', true)}
                          style={{ color: 'black' }}
                        >
                          Työntekijät näkevät kaikki työt
                        </Radio>
                        <br />
                        {workOrder?.settings?.employeesCanViewAllTasks && this.addCheckBox('workTaskEmployeeManagementEnabled', 'Työntekijät voivat lisätä itsensä töille')}
                        {this.addCheckBox('employeesCanCreateWorkTasks', 'Työntekijät voivat luoda uusia töitä', true)}
                      </GridCell>
                    )}
                  </>
                )}

              {this.renderTitle('Muut asetukset')}
              <GridCell
                span={12}
              >
                {this.addCheckBox('isNotBusy', t('work_order.labels.isNotBusy'))}
              </GridCell>

              {this.renderTitle('Toiminnot')}
              <GridCell span={12}>
                <Button
                  {...{
                    type: 'button',
                    variant: 'outlined',
                    className: 'mdc-button',
                    onClick: () => {
                      this.setState({
                        confirmDialogOpen: true,
                      });
                    },
                    style: {
                      borderRadius: '0px',
                      letterSpacing: '1.25px',
                      color: 'black',
                      borderColor: 'rgb(255,201,0)',
                    },
                  }}
                >
                  Kopioi uuden keikan pohjaksi
                </Button>
              </GridCell>

              {workOrder?.id && (
                <GridCell span={12}>
                  <Button
                    {...{
                      type: 'button',
                      variant: 'outlined',
                      className: 'mdc-button',
                      onClick: () => {
                        this.setState({
                          confirmDeleteDialogOpen: true,
                        });
                      },
                      style: {
                        borderRadius: '0px',
                        letterSpacing: '1.25px',
                        color: 'black',
                        borderColor: 'red', // 'rgb(255,201,0)',
                      },
                    }}
                  >
                    Poista keikka
                  </Button>
                </GridCell>
              )}
            </Grid>
          </DialogContent>
          {this.renderActionButtons()}
        </Dialog>

        <Dialog
          open={confirmDialogOpen}
          className="hourly-timelog-confirm-delete"
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              cloneWorkOrder();
              handleClose();
            }
            this.setState({
              confirmDialogOpen: false,
            });
          }}
        >
          <DialogContent style={{ color: 'white' }}>Haluatko varmasti käyttää tätä keikkaa uuden keikan pohjana?</DialogContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              padding: '10px',
              flexDirection: 'row',
            }}
          >
            <DialogButton
              type="button"
              className="accept-button"
              style={{
                borderRadius: '0px',
              }}
              action="accept"
              isDefaultAction
            >
              Kyllä
            </DialogButton>
            <DialogButton
              type="button"
              className="cancel-button"
              // style={{
              //   color: '#ffc900',
              //   border: '1px solid #ffc900',
              //   borderRadius: '0px',
              // }}
              action="close"
            >
              Peruuta
            </DialogButton>
          </DialogActions>
        </Dialog>

        <Dialog
          open={confirmDeleteDialogOpen}
          className="hourly-timelog-confirm-delete"
          onClose={(evt) => {
            if (evt.detail.action === 'accept') {
              deleteWorkOrder();
              handleClose();
            }
            this.setState({
              confirmDeleteDialogOpen: false,
            });
          }}
        >
          <DialogContent style={{ color: 'white' }}>Haluatko varmasti poistaa keikan?</DialogContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              padding: '10px',
              flexDirection: 'row',
            }}
          >
            <DialogButton
              type="button"
              className="accept-button"
              style={{
                borderRadius: '0px',
              }}
              action="accept"
              isDefaultAction
            >
              Kyllä
            </DialogButton>
            <DialogButton
              type="button"
              className="cancel-button"
              // style={{
              //   color: '#ffc900',
              //   border: '1px solid #ffc900',
              //   borderRadius: '0px',
              // }}
              action="close"
            >
              Peruuta
            </DialogButton>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default WorkOrderSettingsDialog;
