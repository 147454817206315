//
//
// This store is used when editing employee stuff as an employer
//
//
import { observable, action } from 'mobx';
import moment from '../utils/moment';

export default class EmployerContextStore {
  // Used to store the employee context when editing work hours for them without having to pass a user id prop to all the components
  // The id is required for saving the work hours and work order trips under that employee instead of the employer
  @observable currentEmployeeId;

  // Function called in employer-time-log/index.jsx
  @action setCurrentEmployeeId(employeeId) {
    this.currentEmployeeId = employeeId;
  }

  constructor(requests) {
    this.requests = requests;
  }

  @action async getWorkOrdersContext(userId, workOrderIds) {
    return this.requests.Users.getWorkOrdersContext(userId, workOrderIds);
  }

  @action async getWorkOrderTripContext(userId, workOrderTripId) {
    return this.requests.Users.getWorkOrderTripContext(userId, workOrderTripId);
  }

  setSalaryPeriodToWorkOrder = (workOrder, salaryPeriod) => ({
    disabled: false,
    from: moment.max(salaryPeriod.from, workOrder.interval.start),
    locked: false,
    periodId: salaryPeriod.id,
    to: moment.min(salaryPeriod.to, workOrder.interval.end),
    // updatedAt: moment(),
    workOrder,
    periodFrom: salaryPeriod.from,
    periodTo: salaryPeriod.to,
    // A stupid hack to turn null/undefined reminders into "true" because user specifically creates reminders with enabled: false
    // allowanceAutofillReminder: startPeriodAllowanceAutofillReminder !== false ? true : false,
    allowanceAutofillReminder: false,
  })
}
