import moment from 'moment-timezone';

moment.tz.setDefault('Etc/GMT');

class Interval {
  constructor(start, end) {
    this.start = start;
    this.end = end;
  }

  toString() {
    return `${this.start.format('YYYY-MM-DD')} – ${this.end.format('YYYY-MM-DD')}`;
  }
}

moment.interval = (start, end) => new Interval(moment.utc(start).startOf('day'), moment.utc(end).startOf('day'));

export const asDate = (date, format = 'DD.MM.YYYY') => (moment.isMoment(date) ? date : moment.utc(date)).format(format);
export const asTime = (date, format = 'HH:mm') => {
  if (date == null) {
    return date;
  }
  return (moment.isMoment(date) ? date : moment.utc(date)).format(format);
};

export const combinedDateTime = (date, time) => {
  if (date && time) {
    const hoursAndMinutes = time.split(':');
    const dateWithTime = date.clone();
    dateWithTime.hours(hoursAndMinutes[0]);
    dateWithTime.minutes(hoursAndMinutes[1]);
    return dateWithTime;
  }
  return null;
};

export const isBetween = (from, to) => moment.isBetween(from, to);

export const dateWithoutTime = (date, ...args) => moment.utc(date).startOf('day', ...args);

export const getInterValDays = (interval) => {
  const array = [];
  let date;
  const startDate = interval.start.startOf('day');
  const numberOfDays = interval.end.startOf('day').diff(startDate, 'days');
  for (let i = 0; i <= numberOfDays;) {
    date = moment.utc(startDate).add(i, 'days').startOf('day');
    array.push(date);
    i += 1;
  }
  return array;
};

export const getTripDuration = (startTrip, endTrip) => {
  const startDate = startTrip?.date.format('YYYY-MM-DD');
  const startTime = startTrip?.time;
  const endDate = (endTrip?.toDate ? endTrip.toDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
  const endTime = endTrip?.toTime ? endTrip.toTime : moment().format('HH:mm');
  const startDateWithTime = moment(`${startDate} ${startTime}`);
  const endDateWithTime = moment(`${endDate} ${endTime}`);
  const startDateToSeconds = new Date(startDateWithTime).getTime() / 1000;
  const endDateToSeconds = new Date(endDateWithTime).getTime() / 1000;
  const tripLengthInSeconds = endDateToSeconds - startDateToSeconds;

  const weeks = Math.floor(tripLengthInSeconds / 604800);
  let days = Math.floor(tripLengthInSeconds / 86400);
  let hours = Math.floor(tripLengthInSeconds / 3600);
  let mins = Math.floor(tripLengthInSeconds / 60);
  days %= 7;
  hours %= 24;
  mins %= 60;

  const durationString = `${weeks !== 0 ? `${weeks} vk` : ''} ${days !== 0 ? `${days} vrk` : ''} ${hours !== 0 ? `${hours} h` : ''} ${mins !== 0 ? `${mins} min` : ''}`;
  return durationString;
};

export const getTripDurationWeekless = (startTrip, endTrip) => {
  const startDate = startTrip?.date.format('YYYY-MM-DD');
  const startTime = startTrip?.time;
  const endDate = (endTrip?.toDate ? endTrip.toDate.format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'));
  const endTime = endTrip?.toTime ? endTrip.toTime : moment().format('HH:mm');
  const startDateWithTime = moment(`${startDate} ${startTime}`);
  const endDateWithTime = moment(`${endDate} ${endTime}`);
  const startDateToSeconds = new Date(startDateWithTime).getTime() / 1000;
  const endDateToSeconds = new Date(endDateWithTime).getTime() / 1000;
  const tripLengthInSeconds = endDateToSeconds - startDateToSeconds;

  // const weeks = Math.floor(tripLengthInSeconds / 604800);
  const days = Math.floor(tripLengthInSeconds / 86400);
  let hours = Math.floor(tripLengthInSeconds / 3600);
  let mins = Math.floor(tripLengthInSeconds / 60);
  // days %= 7;
  hours %= 24;
  mins %= 60;

  const durationString = `${days !== 0 ? `${days} vrk` : ''} ${hours !== 0 ? `${hours} h` : ''} ${mins !== 0 ? `${mins} min` : ''}`;
  return durationString;
};

export const cleanDate = (day) => {
  const date = day.toDate();
  date.setHours(12, 0, 0, 0);
  return date;
};

export const shortDateRange = (startDate, endDate) => {
  const start = startDate ? moment(startDate) : null;
  const end = endDate ? moment(endDate) : null;
  let startFormat = 'DD.MM.YY';
  const endFormat = 'DD.MM.YY';

  if (start?.year() === end?.year() && start?.month() === end?.month()) {
    // Both year and month match, render only the day for the first date
    startFormat = 'DD.';
  } else if (start?.year() === end?.year()) {
    // Only years match, render day + month for the first date
    startFormat = 'DD.MM.';
  }
  return `${start?.format(startFormat) || '?'}-${end?.format(endFormat) || '?'}`;
};

export const dateRange = (startDate, endDate) => {
  const start = startDate ? moment(startDate) : null;
  const end = endDate ? moment(endDate) : null;
  const startFormat = 'dd DD.MM.YY';
  const endFormat = 'dd DD.MM.YY';

  return `${start?.format(startFormat) || '?'} - ${end?.format(endFormat) || '?'}`;
};

export const shortDateRangeWithDay = (startDate, endDate) => {
  const start = startDate ? moment(startDate) : null;
  const end = endDate ? moment(endDate) : null;
  let startFormat = 'dd D.M.YY';
  const endFormat = 'dd D.M.YY';

  if (start?.year() === end?.year() && start?.month() === end?.month()) {
    // Both year and month match, render only the day for the first date
    startFormat = 'dd D.';
  } else if (start?.year() === end?.year()) {
    // Only years match, render day + month for the first date
    startFormat = 'dd D.M.';
  }
  return `${start?.format(startFormat) || '?'} - ${end?.format(endFormat) || '?'}`;
};

export const longDateRange = (startDate, startTime, endDate, endTime) => {
  const start = combinedDateTime(startDate, startTime);
  const end = combinedDateTime(endDate, endTime);
  const startFormat = 'dd DD.MM.YY HH:mm';
  let endFormat = 'dd DD.MM.YY HH:mm';
  // NOTE: This still renders if both start and end are null

  if (start?.day() === end?.day() && start?.year() === end?.year() && start?.month() === end?.month()) {
    // Day, month and year all match, we only need the time for the end
    endFormat = 'HH:mm';
  } else if (start?.year() === end?.year() && start?.month() === end?.month()) {
    // Both year and month match, render only the day for the end
    endFormat = 'dd DD. HH:mm';
  } else if (start?.year() === end?.year()) {
    // Only years match, render day + month for the end
    endFormat = 'dd DD.MM. HH:mm';
  }
  return `${start?.format(startFormat) || '?'} - ${end?.format(endFormat) || '?'}`;
};

export const periodOverlapInHours = (baseStart, baseEnd, start, end) => {
  // period overlap in days
  let hourDiff = 0;
  if (start.isBetween(baseStart, baseEnd, null, '[]') && end.isBetween(baseStart, baseEnd, null, '[]')) {
    // Both start and end are contained by baseStart and baseEnd, so we count all hours between start and end
    hourDiff = end.diff(start, 'hours', true);
  } else if (baseStart.isBetween(start, end, null, '[]')) {
    // Overlap with baseStart
    // Calculate the hours between the smallest end and baseStart, e.g. base 06:00-12:00 and start-end 04:00-10:00 -> 06:00-10:00 = 4 hours
    const smallerEnd = moment.min([end, baseEnd]);
    hourDiff = smallerEnd.diff(baseStart, 'hours', true);
  } else if (baseEnd.isBetween(start, end, null, '[]')) {
    // Overlap with baseEnd
    // Calculate the hours between the greatest start and baseEnd, e.g. base 06:00-12:00 and start-end 10:00-16:00 -> 10:00-12:00 = 2 hours
    const greaterStart = moment.max([start, baseStart]);
    hourDiff = baseEnd.diff(greaterStart, 'hours', true);
  } else if (start < end && start.isBetween(moment(baseStart).subtract(1, 'days'), moment(baseEnd).subtract(1, 'days'), null, '[]')) {
    const greaterStart = moment.max([start, baseStart.subtract(1, 'days')]);
    hourDiff = baseEnd.subtract(1, 'days').diff(greaterStart, 'hours', true);
  }

  // Round to 2 decimals
  const hourDiffRounded = Math.round(hourDiff * 100) / 100;

  return hourDiffRounded;
};

export default moment;
