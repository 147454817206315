/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spin, Radio, Space, Popover, Calendar } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import { DATE_FORMAT } from '../config/default';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

function SchedulerHeader({ onViewChange, goNext, goBack, onSelectDate, schedulerData, leftCustomHeader, rightCustomHeader }) {
  const [viewSpinning, setViewSpinning] = useState(false);
  const [dateSpinning, setDateSpinning] = useState(false);
  const [visible, setVisible] = useState(false);

  const { viewType, showAgenda, isEventPerspective, config } = schedulerData;
  const dateLabel = schedulerData.getDateLabel();
  const selectDate = schedulerData.getSelectedDate();
  const calendarLocale = schedulerData.getCalendarPopoverLocale()?.default?.Calendar;
  const defaultValue = `${viewType}${showAgenda ? 1 : 0}${isEventPerspective ? 1 : 0}`;

  const handleEvents = (func, isViewSpinning, funcArg = undefined) => {
    if (isViewSpinning) {
      if (config.viewChangeSpinEnabled) setViewSpinning(true);
    } else if (config.dateChangeSpinEnabled) setDateSpinning(true);

    const coreFunc = () => {
      if (funcArg !== undefined) func(funcArg);
      else func();

      if (isViewSpinning) {
        if (config.viewChangeSpinEnabled) setViewSpinning(false);
      } else if (config.dateChangeSpinEnabled) setDateSpinning(false);
    };

    if (config.viewChangeSpinEnabled || config.dateChangeSpinEnabled) {
      setTimeout(coreFunc, config.schedulerHeaderEventsFuncsTimeoutMs); // 100ms
    } else {
      coreFunc();
    }
  };

  const popover = (
    <div className="popover-calendar">
      <Calendar
        locale={calendarLocale}
        defaultValue={dayjs(selectDate)}
        fullscreen={false}
        onSelect={date => {
          setVisible(false);
          handleEvents(onSelectDate, false, date.format(DATE_FORMAT));
        }}
      />
    </div>
  );

  // const radioButtonList = config.views.map(item => (
  //   <RadioButton
  //     key={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}
  //     value={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}
  //   >
  //     <span style={{ margin: '0px 8px' }}>{item.viewName}</span>
  //   </RadioButton>
  // ));

  // PROKALENTERI 20.8.24: Use tabs instead of antd RadioButtons for view selection
  const tabList = config.views.map((item) => {
    // tabIndexes[item.viewName] = index;
    return (
      <Tab
        key={`${item.viewType}${item.showAgenda ? 1 : 0}${item.isEventPerspective ? 1 : 0}`}
        label={item.viewName}
      />
    );
  });

  return (
    <Row
      // PROKALENTERI 20.8.24: changed alignment to space-between and removed -5 margin left and margin right
      // PROKALENTERI 29.8.24: removed marginBottom: '24px'
      gutter={[10, 10]}
      type="flex"
      align="middle"
      justify="space-between"
      style={{
        marginLeft: 0,
        marginRight: 0,
        display: 'flex',
        justifyContent: 'center',
        textTransform: 'capitalize',
        // marginBottom: '24px',
        backgroundColor: '#0a131e',
        color: 'white',
        // PROKalenteri 23.8.24: Added position: relative to position leftCustomHeader using position: absolute
        position: 'relative',
      }}
    >
      {leftCustomHeader}
      <Col>
        <div className="header2-text">
          <Space>
            {/* PROKalenteri 28.8.24: Added a button to return to the current day */}
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => schedulerData.customSetDate(dayjs(new Date()), viewType)}
            >
              Tänään
            </div>
            <div>
              <LeftOutlined type="left" style={{ marginRight: '8px' }} className="icon-nav" onClick={() => handleEvents(goBack, false)} />
              {config.calendarPopoverEnabled ? (
                <Popover content={popover} placement="bottomLeft" trigger="click" open={visible} onOpenChange={setVisible} overlayClassName="scheduler-header-popover">
                  <span className="header2-text-label" style={{ cursor: 'pointer' }}>
                    {dateLabel}
                  </span>
                </Popover>
              ) : (
                <span className="header2-text-label">{dateLabel}</span>
              )}
              <RightOutlined type="right" style={{ marginLeft: '8px' }} className="icon-nav" onClick={() => handleEvents(goNext, false)} />
            </div>
            <Spin spinning={dateSpinning} />
          </Space>
        </div>
      </Col>
      <Col>
        <Space>
          <Spin spinning={viewSpinning} />
          {/* <RadioGroup buttonStyle="solid" defaultValue={defaultValue} size="default" onChange={event => handleEvents(onViewChange, true, event)}>
            {radioButtonList}
          </RadioGroup> */}

          {/* PROKALENTERI 20.8.24: changed the antd RadioGroup into rmwc Tabs */}
          <TabBar
            activeTabIndex={viewType}
            // defaultValue={defaultValue}
            onActivate={(event) => handleEvents(onViewChange, true, event)}
            style={{ width: '400px' }}
          >
            {tabList}
          </TabBar>

          {/* WIP mui Tabs */}
          {/* <Tabs
            value={viewType}
            // defaultValue={defaultValue}
            onChange={(event) => handleEvents(onViewChange, true, event)}
            style={{ width: '400px' }}
          >
            {tabList}
          </Tabs> */}
        </Space>
      </Col>
      {rightCustomHeader}
    </Row>
  );

  // 27.5.2024 PROKalenteri change START
      // Custom header
      // schedulerHeader = (
      //   <div
      //     style={{
      //       display: 'flex',
      //       justifyContent: 'space-between',
      //       marginBottom: '24px',
      //       backgroundColor: '#0a131e',
      //       color: 'white',
      //     }}
      //   >
      //     {leftCustomHeader || <div />}

      //     <div style={{ display: 'flex' }}>
      //       <div className="header2-text" style={{ whiteSpace: 'nowrap', margin: 'auto', marginRight: '15px' }}>
      //         <Icon type="left" style={{ marginRight: "8px" }} className="icon-nav" onClick={this.goBack} />
      //         {
      //           calendarPopoverEnabled ? (
      //             <Popover content={popover} placement="bottom" trigger="click"
      //               visible={this.state.visible}
      //               onVisibleChange={this.handleVisibleChange}>
      //               <span className="header2-text-label" style={{ cursor: 'pointer', textTransform: 'capitalize' }}>
      //                 {dateLabel}
      //               </span>
      //             </Popover>
      //           ) : (
      //             <span className="header2-text-label" style={{ textTransform: 'capitalize' }}>
      //               {dateLabel}
      //             </span>
      //           )
      //         }
      //         <Icon type="right" style={{ marginLeft: "8px" }} className="icon-nav" onClick={this.goNext} />
      //       </div>

      //       <TabBar
      //         activeTabIndex={viewType}
      //         // defaultValue={defaultValue}
      //         onActivate={this.onViewChange}
      //         style={{ width: '400px' }}
      //       >
      //         {tabList}
      //       </TabBar>

      //       {/* <div>Täbit tähän</div> */}
      //     </div>
      //     {rightCustomHeader || <div />}
      //   </div>
      // )
      // 27.5.2024 PROKalenteri change END
}

SchedulerHeader.propTypes = {
  onViewChange: PropTypes.func.isRequired,
  goNext: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onSelectDate: PropTypes.func.isRequired,
  schedulerData: PropTypes.object.isRequired,
  leftCustomHeader: PropTypes.object,
  rightCustomHeader: PropTypes.object,
};

SchedulerHeader.defaultProps = {
  leftCustomHeader: null,
  rightCustomHeader: null,
};

export default SchedulerHeader;
