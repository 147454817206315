import { action, observable } from 'mobx';
import User from '../User';
import moment from '../../utils/moment';

export default class CloseCallReport {
  @observable id;

  @observable createdAt;

  @observable reporter;

  @observable date;

  @observable time;

  @observable location;

  @observable workSteps;

  @observable eventSteps;

  @observable causes;

  @observable fixSuggestions;

  @observable relatedObject;

  @observable respondedAt;

  @observable responder;

  @observable response;

  // t.date :date
  // t.time :time
  // t.string :location
  // t.text :work_steps
  // t.text :event_steps
  // t.text :causes
  // t.text :fix_suggestions
  // t.string :related_object
  // t.text :response
  // t.datetime :responded_at
  // t.references :account, index: true
  // t.references :reporter, index: true, foreign_key: { to_table: :users }
  // t.references :responder, foreign_key: { to_table: :users }

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  static toJson(o) {
    return {
      id: o.id,
      date: o.date,
      time: o.time,
      location: o.location,
      work_steps: o.workSteps,
      event_steps: o.eventSteps,
      causes: o.causes,
      fix_suggestions: o.fixSuggestions,
      related_object: o.relatedObject,
      response: o.response,
    };
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      id: o.id,
      respondedAt: o.responded_at ? moment(o.responded_at).local() : null,
      createdAt: moment(o.created_at).local(),
      reporter: User.fromJsonProperties(o.reporter),
      date: moment(o.date),
      time: moment(o.time),
      location: o.location,
      workSteps: o.work_steps,
      eventSteps: o.event_steps,
      causes: o.causes,
      fixSuggestions: o.fix_suggestions,
      relatedObject: o.related_object,
      response: o.response,
    };

    if (o.responder != null) {
      values.responder = User.fromJsonProperties(o.responder);
    }

    return this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const closeCallReport = new CloseCallReport({});
    closeCallReport.updatePropertiesFromJson(o);
    return closeCallReport;
  }
}
