import { observable, action } from 'mobx';
// import User from '../models/User';

export default class UserAvailability {
  @observable user;

  @observable overlappings;

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      // user: User.fromJsonProperties(o.user),
      user: o.user,
    };

    if (o.overlappings != null && o.overlappings.length !== 0) {
      const overlappings = {
        available: o.overlappings.available ? o.overlappings.available : [],
        unavailable: o.overlappings.unavailable ? o.overlappings.unavailable : [],
        alreadyWorking: o.overlappings.already_working ? o.overlappings.already_working : [],
        absent: o.overlappings.absent ? o.overlappings.absent : [],
      };

      values.overlappings = overlappings;
    }

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const userAvailability = new UserAvailability({});
    userAvailability.updatePropertiesFromJson(object);
    return userAvailability;
  }

  static fromJson(json) {
    return UserAvailability.fromJsonProperties(JSON.parse(json));
  }
}
