import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
// import User from '../models/User';
import EmployeeProfile from './EmployeeProfile';
import './EmployeeDialog.css';

@inject('t', 'uiStore', 'employerUserStore')
@observer
class EmployeeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Need to set the radio button attribute here to make the input controlled
      // role: null,
      // ...props.employee,
      changedUser: null,
      errors: {},
    };
  }

  componentDidMount() {
    setTimeout(() => {
      const textareas = [...document.getElementsByTagName('textarea')];
      textareas.forEach((textarea) => {
        /* eslint-disable-next-line no-param-reassign */
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    }, 0);
  }

  componentDidUpdate(prevProps) {
    const { employee } = this.props;

    if (prevProps.employee?.id !== employee?.id) {
      /* eslint-disable-next-line react/no-did-update-set-state */
      this.setState({
        ...employee,
      });
    }
  }

  setUserChanged = (user) => {
    this.setState({
      userChanged: true,
      changedUser: user,
    });
  }

  setErrors(errors) {
    //  Convert the error object's keys from snake case to camel case and set it into the state
    // The errors are then rendered in the textfield helper text
    const errorObj = {};
    Object.keys(errors).forEach((key) => {
      const splitKey = key.split('_');
      let capitalizedSplitKey = splitKey;
      if (splitKey.length > 1) {
        capitalizedSplitKey = splitKey.map((keyPart, index) => {
          if (index !== 0) {
            const capitalizedKeyPart = keyPart.charAt(0).toUpperCase() + keyPart.slice(1);
            return capitalizedKeyPart;
          }
          return keyPart;
        });
      }
      const recombinedKey = capitalizedSplitKey.join('');
      // eslint-disable-next-line prefer-destructuring
      errorObj[recombinedKey] = errors[key][0];
    });

    this.setState({
      errors: errorObj,
    });
  }

  preSaveCheck() {
    const { changedUser } = this.state;
    if (!changedUser.id && changedUser.password !== changedUser.passwordConfirmation) {
      this.setState({
        errors: { passwordConfirmation: 'Salasanat eivät täsmää' },
      });
    } else {
      this.setState({
        errors: {},
      }, () => {
        this.updateUser();
      });
    }
  }

  updateUser() {
    const {
      handleClose,
      employerUserStore,
    } = this.props;
    const { changedUser, errors } = this.state;

    if (Object.keys(errors).length > 0) {
      // this.setState({ errors });
    } else if (changedUser.id) {
      // const updatedUser = uiStore.updateOtherUser(new User({ ...this.state, id: employee?.id }));
      employerUserStore.updateOtherUser(changedUser).then((res) => {
        if (res && res.errors) {
          this.setErrors(res.errors);
        } else {
          handleClose();
        }
      });
    } else {
      employerUserStore.createUser(changedUser).then((res) => {
        if (res && res.errors) {
          this.setErrors(res.errors);
        } else {
          handleClose();
        }
      });
    }
  }

  renderActionButtons() {
    const { handleClose, readOnlyMode, switchToEdit } = this.props;
    const { userChanged } = this.state;
    // const { id } = this.props;
    return (
      <DialogActions
        style={{
          justifyContent: (userChanged || readOnlyMode) ? 'space-between' : 'end',
          padding: '15px',
        }}
      >
        {userChanged ? (
          <>
            <Button
              type="button"
              className="employer-accept-button"
              style={{
                borderRadius: '0px',
                minWidth: '100px',
              }}
              // action="submit"
              onClick={() => this.preSaveCheck()}
            >
              Tallenna
            </Button>

            <Button
              type="button"
              className="employer-reject-button"
              style={{
                borderRadius: '0px',
                minWidth: '100px',
              }}
              // action="close"
              onClick={() => {
                handleClose();
                this.setState({ userChanged: false });
              }}
            >
              Peruuta
            </Button>
          </>
        ) : (
          <>
            {readOnlyMode && (
              <Button
                type="button"
                className="employer-accept-button"
                style={{
                  borderRadius: '0px',
                  minWidth: '100px',
                }}
                // action="submit"
                onClick={() => switchToEdit()}
              >
                Muokkaa
              </Button>
            )}

            <Button
              type="button"
              className="employer-reject-button"
              style={{
                borderRadius: '0px',
                minWidth: '100px',
              }}
              onClick={() => {
                handleClose();
                this.setState({ userChanged: false });
              }}
            >
              Sulje
            </Button>
          </>
        )}
      </DialogActions>
    );
  }

  render() {
    const {
      open,
      handleClose,
      employee,
      readOnlyMode,
    } = this.props;
    const { errors } = this.state;

    const inputStyles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
    };
    const inputStylesReadOnly = {
      backgroundColor: '#FFFFFF',
      width: '100%',
    };

    return (
      <div>
        {readOnlyMode ? (
          <Dialog
            className="employee-dialog employer-modal"
            open={open}
            onClose={() => {
              this.setState({ userChanged: false });
              handleClose();
            }}
          >
            <DialogTitle>
              {employee?.id ? `${employee.lastName}, ${employee.firstName}` : 'Uusi työntekijä'}
            </DialogTitle>
            <DialogContent style={{ color: 'black' }}>
              <EmployeeProfile
                setUserChanged={this.setUserChanged}
                mode="employer-dialog-read-only"
                employee={employee}
                inputStyles={inputStylesReadOnly}
                readOnlyMode={readOnlyMode}
              />
            </DialogContent>
            {this.renderActionButtons()}
          </Dialog>
        ) : (
          <Dialog
            className="employee-dialog employer-modal"
            open={open}
            onClose={() => {
              this.setState({ userChanged: false });
              handleClose();
            }}
          >
            <DialogTitle>
              {employee?.id ? `Muokkaa työntekijää: ${(employee.firstName && employee.lastName) ? (`${employee.lastName}, ${employee.firstName}`) : employee.email}` : 'Uusi työntekijä'}
            </DialogTitle>
            <DialogContent style={{ color: 'black' }}>
              <EmployeeProfile
                setUserChanged={this.setUserChanged}
                mode="employer-dialog"
                employee={employee}
                inputStyles={inputStyles}
                errors={errors}
              />
            </DialogContent>
            {this.renderActionButtons()}
          </Dialog>
        )}
      </div>
    );
  }
}

export default EmployeeDialog;
