import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Button } from '@material-ui/core';
import { Icon } from '@rmwc/icon';
import MUIDataTable from 'mui-datatables';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
import LoadingSpinner from '../shared/LoadingSpinner';
// import FilterDialog from '../shared/FilterDialog';
import LocationDialog from './LocationDialog';
import './locations-purchasers.css';


@inject('uiStore', 'loginStore', 't', 'locationStore')
@observer
class Locations extends Component {
  constructor(props) {
    super(props);
    const { locationStore, uiStore: { currentUser } } = this.props;

    this.state = {
      showAddNewWorkOrder: false,
      locationDialogOpen: false,
      selectedLocation: {},
    };

    locationStore.getLocations(currentUser);
  }

  toggleAddNewWorkOrder = () => {
    const { showAddNewWorkOrder } = this.state;

    if (!showAddNewWorkOrder) {
      this.setState({
        showAddNewWorkOrder: true,
      });
    } else {
      this.setState({
        showAddNewWorkOrder: false,
      });
    }
  }

  toggleLocationDialog = (location) => {
    this.setState((prevProps) => ({
      selectedLocation: location || {},
      locationDialogOpen: !prevProps.locationDialogOpen,
    }));
  }

  saveLocation = (location) => {
    const { locationStore } = this.props;

    if (location.id == null || location.id === '') {
      const createPromise = fromPromise(new Promise((resolve, reject) => locationStore.createLocation(location, resolve, reject)));
      when(
        () => createPromise.state !== 'pending',
        () => {
          createPromise.case({
            pending: () => {},
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: () => {
              this.setState({
                selectedLocation: {},
              });
            },
          });
        },
      );
    } else {
      const updatePromise = fromPromise(new Promise((resolve, reject) => locationStore.updateLocation(location, resolve, reject)));
      when(
        () => updatePromise.state !== 'pending',
        () => {
          updatePromise.case({
            pending: () => {},
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: () => {
              this.setState({
                selectedLocation: {},
              });
            },
          });
        },
      );
    }
  }

  renderLocationsTable(locations, noDataText) {
    const { locationStore } = this.props;

    if (locations == null) {
      // DB query not yet complete
      return (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <LoadingSpinner color="black" />
        </div>
      );
    }

    if (locations.length === 0) {
      return <p>{noDataText}</p>;
    }

    const columns = [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
        },
      },
      {
        name: 'name',
        label: 'Nimi',
        options: {
          filter: false,
          display: locationStore.locationDisplayColumns.name === undefined ? true : locationStore.locationDisplayColumns.name,
        },
      },
      {
        name: 'company',
        label: 'Yritys',
        options: {
          filter: true,
          filterType: 'multiselect',
          filterList: locationStore.locationFilters.company,
          display: locationStore.locationDisplayColumns.company === undefined ? true : locationStore.locationDisplayColumns.company,
          filterOptions: {
            fullWidth: true,
          },
        },
      },
      {
        name: 'location',
        label: 'Kuvaus',
        options: {
          filter: false,
          display: locationStore.locationDisplayColumns.location === undefined ? false : locationStore.locationDisplayColumns.location,
        },
      },
      {
        name: 'street',
        label: 'Katuosoite',
        options: {
          filter: false,
          display: locationStore.locationDisplayColumns.street === undefined ? true : locationStore.locationDisplayColumns.street,
        },
      },
      {
        name: 'zipCode',
        label: 'Postinumero',
        options: {
          display: locationStore.locationDisplayColumns.zipCode === undefined ? false : locationStore.locationDisplayColumns.zipCode,
          filter: false,
        },
      },
      {
        name: 'city',
        label: 'Paikkakunta',
        options: {
          filter: true,
          filterType: 'multiselect',
          filterList: locationStore.locationFilters.city,
          display: locationStore.locationDisplayColumns.city === undefined ? true : locationStore.locationDisplayColumns.city,
          filterOptions: {
            fullWidth: true,
          },
        },
      },
      {
        name: 'additionalInfo',
        label: 'Lisätietoja',
        options: {
          display: locationStore.locationDisplayColumns.additionalInfo === undefined ? false : locationStore.locationDisplayColumns.additionalInfo,
          filter: false,
        },
      },
      {
        name: 'edit',
        label: 'Muokkaa',
        options: {
          sort: false,
          filter: false,
          empty: true,
          viewColumns: false,
          display: true,
          download: false,
          print: false,
          customBodyRenderLite: (dataIndex) => (
            <Icon
              icon="edit"
              style={{
                cursor: 'pointer',
                padding: '0 10px',
              }}
              onClick={() => {
                this.toggleLocationDialog(data[dataIndex]); // eslint-disable-line
              }}
            />
          ),
        },
      },
    ];

    const data = locations.value;

    const options = {
      filter: true,
      filterType: 'dropdown',
      responsive: 'vertical',
      search: true,
      viewColumns: true,
      selectableRows: 'none',
      fixedHeader: false,
      sort: false,
      download: false,
      print: false,
      onFilterChange: (changedColumn, filterList, type, changedColumnIndex) => {
        if (changedColumnIndex) {
          locationStore.setLocationFilters(changedColumn, filterList[changedColumnIndex]);
        } else {
          locationStore.emptyFilters();
        }
      },
      onViewColumnsChange: (changedColumn, action) => {
        const columnIndex = columns.findIndex(col => col.name === changedColumn); // eslint-disable-line
        if (columnIndex !== -1) {
          if (action === 'remove') {
            locationStore.setLocationDisplayColumns(changedColumn, false);
          } else if (action === 'add') {
            locationStore.setLocationDisplayColumns(changedColumn, true);
          }
        }
      },
      textLabels: {
        body: {
          noMatch: 'Kohteita ei löytynyt',
        },
        toolbar: {
          search: 'Haku',
          viewColumns: 'Näytä sarakkeet',
          filterTable: 'Suodatin',
        },
        pagination: {
          next: 'Seuraava sivu',
          previous: 'Edellinen',
          rowsPerPage: 'Rivejä sivulla:',
          displayRows: '/',
        },
        filter: {
          all: 'Kaikki',
          title: 'SUODATIN',
          reset: 'Tyhjennä',
        },
        viewColumns: {
          title: 'Näytä sarakkeet',
          titleAria: 'Näytä/piilota sarakkeet',
        },
        selectedRows: {
          text: 'rivi(ä) valittu',
          delete: 'Poista',
          deleteAria: 'Poista valitut rivit',
        },
      },
    };

    return (
      <div className="table-wrapper">
        <MUIDataTable
          data={data}
          columns={columns}
          options={options}
        />
      </div>
    );
  }

  render() {
    const { locationStore: { locations } } = this.props;
    const {
      locationDialogOpen,
      selectedLocation,
    } = this.state;

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                // A margin to match the table below
                margin: '0 20px',
              }}
            >
              <Button
                onClick={() => this.toggleLocationDialog({})}
                className="employer-accept-button mdc-button"
                style={{
                  margin: '10px',
                  borderRadius: '0px',
                }}
              >
                Uusi kohde
              </Button>
            </GridCell>
            <GridCell span={12}>
              {locations.case({
                pending: () => (
                  <GridCell style={{ textAlign: 'center' }}>
                    <div style={{ textAlign: 'center', padding: '20px' }}>
                      <LoadingSpinner color="black" />
                    </div>
                  </GridCell>
                ),
                rejected: () => <GridCell style={{ textAlign: 'center' }}><p>Kohteita ei juuri nyt pystytty lataamaan.</p></GridCell>,
                fulfilled: () => (
                  <>
                    {/* <div>Kohteet ladattu</div> */}
                    {this.renderLocationsTable(locations, 'Kohteita ei löytynyt')}
                  </>
                ),
              })}
            </GridCell>
          </GridInner>
        </Grid>

        <LocationDialog
          open={locationDialogOpen}
          handleClose={this.toggleLocationDialog}
          location={selectedLocation}
          saveLocation={this.saveLocation}
        />
      </>
    );
  }
}

export default Locations;
