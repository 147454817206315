import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
// import { Grid, GridInner, GridCell } from '@rmwc/grid';
import '../locations-purchasers/locations-purchasers.css';
// import DeepViewHeader from '../shared/DeepViewHeader';
// import BillingReportsHourly from './BillingReportsHourly';
import BillingReports from './BillingReports';

@inject('uiStore', 'loginStore', 'reportStore', 't')
@observer
class BillingReportsDailyIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rawReportData: null,
      reportData: null,
      // reportWorkOrder: null,
      // hourlyState: null,
      // dailyState: null,
    };
  }

  // componentDidMount() {
  //   const { uiStore } = this.props;
  //   const workOrderId = uiStore.currentView.id;
  //   this.getWorkOrderReportByUser(workOrderId);
  // }

  // getWorkOrderReportByUser = (workOrderId) => {
  //   const { reportStore } = this.props;
  //   const reportPromise = fromPromise(new Promise((resolve, reject) => reportStore.getWorkOrderReportByUser(workOrderId, resolve, reject)));

  //   when(
  //     () => reportPromise.state !== 'pending',
  //     () => {
  //       reportPromise.case({
  //         pending: () => {
  //         },
  //         rejected: (e) => {
  //           console.log('REJECTED: ', e);
  //         },
  //         fulfilled: (data) => {
  //           // const groupedReportData = this.categorizeRows(data.workHours, 'user.fullName');
  //           // this.hideEmptyColumns(groupedReportData);
  //           // console.log('FULFILLED: ', data);
  //           this.setState({
  //             rawReportData: data,
  //             reportWorkOrder: data.workOrder,
  //           });
  //         },
  //       });
  //     },
  //   );
  // }

  getWorkOrderReportByUserFiltered = (workOrderId, from, to) => {
    const { reportStore } = this.props;

    // Create a promise to retrieve the work order report.
    const reportPromise = fromPromise(new Promise((resolve, reject) => {
      // Call the reportStore method to get the work order report.
      reportStore.getWorkOrderReportByUserFiltered(workOrderId, from, to, resolve, reject);
    }));

    this.setState({
      isLoading: true,
    });

    // Wait for the promise to be fulfilled or rejected.
    when(
      () => reportPromise.state !== 'pending',
      () => {
        reportPromise.case({
          rejected: (e) => {
            console.log('REJECTED: ', e); // Log the error if the promise is rejected.
            this.setState({
              isLoading: false,
            });
          },
          fulfilled: (data) => {
            // Process the data.
            this.setState({
              rawReportData: data,
              isLoading: false,
              // reportWorkOrder: data.workOrder,
            });
          },
        });
      },
    );
  }

  render() {
    // const { uiStore } = this.props;
    const {
      rawReportData,
      // reportWorkOrder,
      reportData,
      isLoading,
    } = this.state;

    return (
      <BillingReports
        getWorkOrderReportByUserFiltered={this.getWorkOrderReportByUserFiltered}
        // mode="tabReport"
        rawReportData={rawReportData}
        reportData={reportData}
        // setIndexState={this.setDailyState}
        // indexState={dailyState}
        isLoading={isLoading}
      />
    );
  }
}

export default BillingReportsDailyIndex;
