export default class WorkOrderStore {
  constructor(uiStore) {
    this.uiStore = uiStore;
  }

  findById(id) {
    const idAsInt = parseInt(id, 10);
    const { currentUser } = this.uiStore;

    return currentUser.workOrders.find((workOrder) => workOrder.id === idAsInt);
  }
}
