/* eslint-disable indent */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card, CardPrimaryAction,
} from '@rmwc/card';
// import { Grid, GridInner, GridCell } from '@rmwc/grid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { Typography } from '@rmwc/typography';
import { Icon } from '@rmwc/icon';
import '@rmwc/icon/icon.css';

import '@material/card/dist/mdc.card.css';
import '@material/button/dist/mdc.button.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import './WorkTaskCard.css';

@inject('timelogStore', 'uiStore', 't')
@observer
class WorkTaskEmployeeCard extends Component {
  render() {
    const {
      handleCardClick,
      workTask,
      // mode,
    } = this.props;

    // const cardClass = mode === 'employee' ? 'employee-work-task-card' : 'employer-work-task-card';

    return (
      // <Card
      //   className={cardClass}
      //   style={{ width: '100%', cursor: 'pointer' }}
      //   // onClick={() => uiStore.showWorkTaskDialog(id, identifierOne, identifierTwo, users)}
      //   onClick={(e) => {
      //     e.stopPropagation();
      //     handleCardClick(workTask);
      //   }}
      // >
      //   <Grid
      //     style={{ width: '100%' }}
      //   >
      //     <GridCell
      //       span={12}
      //     >
      //       <GridInner>
      //         <GridCell
      //           style={{ fontSize: '14px' }}
      //           desktop={9}
      //           tablet={6}
      //           phone={3}
      //         >
      //           <span>{workTask.identifiers}</span>
      //         </GridCell>
      //         <GridCell
      //           className="work-task-card-users-cell"
      //           desktop={3}
      //           tablet={2}
      //           phone={1}
      //         >
      //           <span
      //             role="button"
      //             className="span-button"
      //             style={{ width: 'fit-content' }}
      //           >
      //             <PeopleOutlineIcon
      //               className="work-task-card-icon"
      //               fontSize="small"
      //               style={{
      //                 marginRight: '3px',
      //               }}
      //             />
      //             <span>
      //               {workTask.workTaskUsers?.length || 0}
      //             </span>
      //           </span>
      //         </GridCell>
      //       </GridInner>
      //     </GridCell>

      //     <GridCell
      //       className="employer-work-order-text-gray-sm"
      //       span={12}
      //     >
      //       <GridInner>
      //         <GridCell
      //           desktop={6}
      //           tablet={4}
      //           phone={2}
      //         >
      //           <span
      //             role="button"
      //             className="span-button"
      //             style={{ width: 'fit-content' }}
      //           >
      //             <AccessTimeIcon
      //               className="work-task-card-icon"
      //               fontSize="small"
      //               style={{
      //                 marginRight: '3px',
      //               }}
      //             />
      //             <span className="invitations-neutral">
      //               {workTask.totalHours}
      //             </span>
      //           </span>
      //         </GridCell>

      //         <GridCell
      //           style={{ textAlign: 'right' }}
      //           desktop={6}
      //           tablet={4}
      //           phone={2}
      //         >
      //           <span
      //             role="button"
      //             className="span-button"
      //           >
      //             <AttachFileIcon
      //               className="work-task-card-icon"
      //               fontSize="small"
      //               style={{
      //                 opacity: '1 !imporant',
      //               }}
      //             />
      //             <span className="invitations-neutral" style={{ paddingRight: 0 }}>
      //               {workTask.files.length === 0 ? '-' : workTask.files.length}
      //             </span>
      //           </span>
      //         </GridCell>
      //       </GridInner>
      //     </GridCell>
      //   </Grid>
      // </Card>

      <Card
        onClick={(e) => {
          e.stopPropagation();
          handleCardClick(workTask);
        }}
        style={{
          boxShadow: 'none',
          // marginTop: useGap && item._addGap === true ? '1em' : '',
        }}
      >
        <CardPrimaryAction>
          <div style={{ display: 'flex' }}>
            <div style={{ padding: '0 0 1rem 1rem', flexGrow: 1 }}>
              <div
                style={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Typography
                  style={{
                    color: 'white !important',
                    lineHeight: '1.25rem',
                    marginBottom: '8px',
                    marginTop: '8px',
                  }}
                  tag="h6"
                  use="subtitle1"
                >
                  {workTask.identifiers}
                </Typography>

                {/* <Icon
                  icon="navigate_next"
                  style={{
                    paddingTop: '8px',
                  }}
                /> */}
              </div>

              <div
                style={{
                  display: 'flex',
                  flex: '1 1 auto',
                  flexDirection: 'row',
                  // justifyContent: 'space-between',
                }}
              >
                <span
                  role="button"
                  className="span-button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <AttachFileIcon
                    className="work-task-card-icon"
                    fontSize="small"
                  />
                  <span className="invitations-neutral" style={{ paddingRight: 0, color: 'white' }}>
                    {workTask.files.length === 0 ? '-' : workTask.files.length}
                  </span>
                </span>

                <span
                  role="button"
                  className="span-button"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: '30px',
                  }}
                >
                  <PeopleOutlineIcon
                    className="work-task-card-icon"
                    fontSize="small"
                    style={{
                      marginRight: '3px',
                    }}
                  />
                  <span>
                    {workTask.workTaskUsers?.length || 0}
                  </span>
                </span>
              </div>

              {/* <Typography
                style={{ marginTop: '-1rem' }}
                tag="div"
                theme="textHintOnDark"
                use="body2"
              >
                {dateMismatch ? (
                  <>
                    {asDate(moment.min([item.interval.start, earliestWorkDay]), 'DD.MM.')}
                    –
                    {asDate(moment.max([item.interval.end, latestWorkDay]))}
                    {` (${workDaysCount} pv) `}
                    {item.location?.name && ` ${item.location.name}`}
                  </>
                ) : (
                  <>
                    {asDate(item.interval.start, 'DD.MM.')}
                    –
                    {asDate(item.interval.end)}
                    {item.location?.name && ` ${item.location.name}`}
                  </>
                )}
              </Typography> */}

              {/* {!skipDescription && (
                <Typography
                  style={{ marginTop: '1em', whiteSpace: 'pre-wrap' }}
                  tag="div"
                  theme="textSecondaryOnDark"
                  use="body2"
                >
                  {shortDescription === true ? item.description.split('\n')[0] : item.description}
                </Typography>
              )} */}
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                paddingRight: '1rem',
              }}
            >
              <Icon icon="navigate_next" />
            </div>
          </div>
        </CardPrimaryAction>
      </Card>
    );
  }
}

export default WorkTaskEmployeeCard;
