import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogButton,
  DialogContent,
} from '@rmwc/dialog';
import { TextField } from '@rmwc/textfield';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
// import { Button } from '@material-ui/core';
import { IconButton } from '@rmwc/icon-button';
// import { MenuSurfaceAnchor, Menu, MenuItem } from '@rmwc/menu';
import { Select } from '@rmwc/select';
// import { Typography } from '@rmwc/typography';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';
// import { isEqual } from 'lodash';

// import AddIcon from '@material-ui/icons/Add';

import { cloneDeep } from 'lodash';

import '@material/dialog/dist/mdc.dialog.css';
import '@material/button/dist/mdc.button.css';
import '@material/form-field/dist/mdc.form-field.css';
import '@material/floating-label/dist/mdc.floating-label.css';
import '@material/notched-outline/dist/mdc.notched-outline.css';
import '@material/line-ripple/dist/mdc.line-ripple.css';
import '@material/layout-grid/dist/mdc.layout-grid.css';
import '@material/icon-button/dist/mdc.icon-button.css';
import '@material/typography/dist/mdc.typography.css';

import '@material/menu/dist/mdc.menu.css';
import '@material/menu-surface/dist/mdc.menu-surface.css';
import '@material/list/dist/mdc.list.css';

import moment, { cleanDate } from '../utils/moment';
import dayjs from '../utils/dayjs';
import CustomTimeField from '../shared/CustomTimeField';
import CalendarEntry from '../models/CalendarEntry';
import AbsenceEntry from '../models/AbsenceEntry';
import AbsencePeriod from '../models/AbsencePeriod';

import './absence-period-dialog.css';
import LoadingSpinner from '../shared/LoadingSpinner';

import EmployeeCalendar from '../calendar/EmployeeCalendar';

// import RouteLocation from './RouteLocation';
// import TripRoute from './TripRoute';
// import AutocompletableLocation from '../shared/AutocompletableLocation';

const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

const flattenCalendarEntries = (absenceEntries) => {
  // const { availabilities } = this.state;
  const processedUserAvailabilityInfo = {};

  if (absenceEntries) {
    processedUserAvailabilityInfo.workingCurrentPeriod = absenceEntries.map((absenceEntry) => {
      if (absenceEntry.deletedAt) {
        return null;
      }
      const { calendarEntry } = absenceEntry;

      const firstDate = calendarEntry.from;
      const lastDate = calendarEntry.to;
      let currentDate = firstDate;
      const cleanedDates = [cleanDate(firstDate)];

      while (currentDate.format('DD.MM.YY') < lastDate.format('DD.MM.YY')) {
        cleanedDates.push(cleanDate(currentDate));
        currentDate = currentDate.add(1, 'days');
      }
      return cleanedDates;
    }).flat();
  } else {
    processedUserAvailabilityInfo.workingCurrentPeriod = [];
  }

  return processedUserAvailabilityInfo;
};

@inject('absenceStore', 't', 'uiStore')
@observer
class AbsencePeriodDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // routeAddresses: [],
      showDeleteDialog: false,
      absencePeriod: { absenceEntries: [] },
      saving: false,
      errors: {},
      allCalendarEntries: { workingCurrentPeriod: [] },
    };
  }

  componentDidUpdate() {
    const { absencePeriod: propAbsencePeriod } = this.props;
    const { absencePeriod: stateAbsencePeriod } = this.state;
    if (propAbsencePeriod?.id !== stateAbsencePeriod?.id) {
      const absencePeriod = new AbsencePeriod({ ...propAbsencePeriod });

      const smallestEntryDate = absencePeriod.absenceEntries?.reduce((min, absenceEntry) => (
        moment.min(min, absenceEntry.calendarEntry.from)
      ), moment().add(100, 'years')); // Initializing with a far future date

      const greatestEntryDate = absencePeriod.absenceEntries?.reduce((max, absenceEntry) => (
        moment.max(max, absenceEntry.calendarEntry.to)
      ), moment().subtract(100, 'years')); // Initializing with a far past date

      if (smallestEntryDate) {
        absencePeriod.fromDate = smallestEntryDate;
        if (smallestEntryDate.format('HH:mm') !== '00:00') {
          absencePeriod.fromTime = smallestEntryDate.format('HH:mm');
        }
      }

      if (greatestEntryDate) {
        // const greatestEntryDateMinusDay = greatestEntryDate.clone().subtract(1, 'day');
        absencePeriod.toDate = greatestEntryDate;
        if (greatestEntryDate.format('HH:mm') !== '23:59') {
          absencePeriod.toTime = greatestEntryDate.format('HH:mm');
        }
      }

      // if (absencePeriod.absenceType) {
      //   absencePeriod.values = absencePeriod.absenceType.valueTemplates.map((template) => (
      //     {
      //       name: template.name,
      //       value: template.default_value,
      //       unit: template.unit,
      //       order: template.order,
      //       data_type: template.data_type,
      //     }
      //   ));
      // }

      if (!absencePeriod.absenceEntries) {
        absencePeriod.absenceEntries = [];
      }

      const processedCalendarEntries = flattenCalendarEntries(absencePeriod.absenceEntries);

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        absencePeriod,
        allCalendarEntries: processedCalendarEntries,
      });
    }
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      saving: false,
      absencePeriod: { absenceEntries: [] },
      allCalendarEntries: { workingCurrentPeriod: [] },
    }, () => {
      onClose();
    });
  }

  setSelectedWorkDays = (updatedSelectedAbsenceDays, updatedToBeDeletedAbsenceDays) => {
    const { absencePeriod } = this.state;

    const newAbsenceEntries = absencePeriod.absenceEntries ? [...absencePeriod.absenceEntries] : [];

    // For each selected day, try to find an existing absenceEntry
    // If not existing, create a new one
    updatedSelectedAbsenceDays.forEach((day) => {
      // const foundEntry = absencePeriod.absenceEntries.find(())
      const foundExistingDay = newAbsenceEntries.find((entry) => entry.calendarEntry.from.format('DD.MM.YY') === moment(day).format('DD.MM.YY'));
      if (day && !foundExistingDay) {
        // create new absence entry
        const calendarEntry = new CalendarEntry({
          from: moment(day).startOf('day'),
          to: moment(day).endOf('day'),
        });

        const absenceEntry = new AbsenceEntry({
          absenceType: absencePeriod.absenceType,
          calendarEntry,
          status: 'draft',
          // value: { value: absencePeriod.absenceType.default_value },

          // values: absencePeriod.absenceType.valueTemplates.map((template) => ({ name: template.name, value: template.default_value })),
          // values: absencePeriod.values.map((dialogValue) => ({ name: dialogValue.name, value: dialogValue.value })),
          // values: absencePeriod.absenceType.valueTemplates.map((template) => {
          //   const valueObj = {
          //     name: template.name,
          //     unit: template.unit,
          //     order: template.order,
          //     data_type: template.data_type,
          //   };
          //   if (template.default_value) {
          //     valueObj.value = template.default_value;
          //   }
          //   return valueObj;
          // }),
        });

        newAbsenceEntries.push(absenceEntry);
      }
    });

    // For each deleting day, try to find an absence entry and set deletedAt
    updatedToBeDeletedAbsenceDays.forEach((day) => {
      const dateFormat = 'YYYY-MM-DD';
      const formattedDay = moment(day).format(dateFormat);

      const deleteAtIndex = newAbsenceEntries.findIndex((entry) => entry.calendarEntry.from.format(dateFormat) === formattedDay && entry.calendarEntry.to.format(dateFormat) === formattedDay);
      if (deleteAtIndex !== -1) {
        newAbsenceEntries[deleteAtIndex].deletedAt = moment();
      }
    });

    const processedCalendarEntries = flattenCalendarEntries(newAbsenceEntries);

    this.setState((prevState) => ({
      // selectedAbsenceDays: updatedSelectedAbsenceDays,
      // toBeDeletedAbsenceDays: updatedToBeDeletedAbsenceDays,
      absencePeriod: { ...prevState.absencePeriod, absenceEntries: newAbsenceEntries },
      allCalendarEntries: processedCalendarEntries,
    }));
  }

  setDefaultAbsenceEntryValues(foundAbsenceType) {
    const newValues = [];
    if (!foundAbsenceType) {
      return;
    }

    foundAbsenceType.valueTemplates.forEach((valueTemplate) => {
      if (valueTemplate.default_value) {
        newValues.push({
          name: valueTemplate.name,
          value: valueTemplate.default_value,
        });
      }
    });

    this.setState((prevState) => ({
      absencePeriod: {
        ...prevState.absencePeriod,
        values: newValues,
      },
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  capitalizeString(str) {
    const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalizedStr;
  }

  startSave() {
    // const { absencePeriod } = this.state;
    this.setState({
      saving: true,
    }, () => {
      // if (absencePeriod.id) {
      //   this.update();
      // } else {
      //   this.save();
      // }
      this.save();
    });
  }

  // Uses from date and to date fields to autogenerate absence entries
  // oldSave() {
  //   const { absenceStore } = this.props;
  //   const { absencePeriod } = this.state;

  //   // Modified from ChatGPT 4 23.4.2024 START
  //   const startMoment = moment(`${absencePeriod.fromDate.format('YYYY-MM-DD')} ${absencePeriod.fromTime || '23:59'}`);
  //   const endMoment = moment(`${absencePeriod.toDate.format('YYYY-MM-DD')} ${absencePeriod.toTime || '23:59'}`);

  //   const startDate = absencePeriod.fromDate;
  //   const endDate = absencePeriod.toDate;
  //   let currentDay = startDate.clone().startOf('day');
  //   const targetDate = endDate.clone().add(1, 'day').startOf('day');

  //   const generatedAbsenceEntries = [];

  //   while (currentDay.isBefore(targetDate, 'day')) {
  //     const toDay = currentDay.clone().endOf('day');

  //     const calendarEntry = new CalendarEntry({
  //       from: currentDay,
  //       to: toDay,
  //     });

  //     const absenceEntry = new AbsenceEntry({
  //       absenceType: absencePeriod.absenceType,
  //       calendarEntry,
  //       status: 'draft',
  //       // value: { value: absencePeriod.absenceType.default_value },

  //       // values: absencePeriod.absenceType.valueTemplates.map((template) => ({ name: template.name, value: template.default_value })),
  //       values: absencePeriod.values.map((dialogValue) => ({ name: dialogValue.name, value: dialogValue.value })),
  //     });

  //     generatedAbsenceEntries.push(absenceEntry);
  //     // Add 1 minute to move to the day from 23:59 to the next day's 00:00
  //     currentDay = toDay.clone().add(1, 'minute');
  //   }

  //   // Adjust the first and last periods to start and end times
  //   if (generatedAbsenceEntries[0] && absencePeriod.absenceType.hasTimes) {
  //     generatedAbsenceEntries[0].calendarEntry.from = startMoment; // Set actual start time on the first day
  //   }

  //   if (generatedAbsenceEntries[generatedAbsenceEntries.length - 1] && absencePeriod.absenceType.hasTimes) {
  //     generatedAbsenceEntries[generatedAbsenceEntries.length - 1].calendarEntry.to = endMoment; // Set actual end time on the last day
  //   }
  //   // Modified from ChatGPT 4 23.4.2024 END

  //   // If updating instead of creating:
  //   //    Extending dates: we do the same loop, but only with new dates
  //   //    Shortening dates: set isDeleted timestamp to dates that were left out
  //   let newAbsencePeriod;
  //   if (absencePeriod.id) {
  //     // Editing
  //     newAbsencePeriod = new AbsencePeriod({ absenceEntries: [], ...absencePeriod });

  //     generatedAbsenceEntries.forEach((generatedEntry) => {
  //       // Try to find an existing absence entry on the date
  //       // If found, we skip it
  //       // If not found, we create it
  //       const foundExistingEntry = absencePeriod.absenceEntries.find((entry) => (entry.calendarEntry.from.format('YYYY-MM-DD') === generatedEntry.calendarEntry.from.format('YYYY-MM-DD')));
  //       if (!foundExistingEntry) {
  //         newAbsencePeriod.absenceEntries.push(generatedEntry);
  //       }
  //     });

  //     // For each old entry that isn't included in the existing entries (no longer inside the absence period), we set the deleted_at timestamp
  //     newAbsencePeriod.absenceEntries.forEach((existingEntry, index) => {
  //       const foundGeneratedEntry = generatedAbsenceEntries.find((entry) => (entry.calendarEntry.from.format('YYYY-MM-DD') === existingEntry.calendarEntry.from.format('YYYY-MM-DD')));
  //       if (!foundGeneratedEntry) {
  //         // Soft deletion
  //         newAbsencePeriod.absenceEntries[index].deletedAt = moment();
  //       }
  //     });
  //   } else {
  //     newAbsencePeriod = new AbsencePeriod({
  //       ...absencePeriod,
  //       absenceEntries: generatedAbsenceEntries,
  //     });
  //   }

  //   absenceStore.createAbsencePeriod(newAbsencePeriod).then(() => {
  //     // Create success
  //     this.onClose();
  //   }).catch((err) => {
  //     // Failure
  //     console.error(err);
  //     this.setState({
  //       saving: false,
  //     });
  //   });

  //   // if (newAbsencePeriod.id) {
  //   //   absenceStore.updateAbsencePeriod(newAbsencePeriod).then(() => {
  //   //     // Update success
  //   //     this.onClose();
  //   //   }).catch((err) => {
  //   //     // Failure
  //   //     console.error(err);
  //   //     this.setState({
  //   //       saving: false,
  //   //     });
  //   //   });
  //   // } else {
  //   //   absenceStore.createAbsencePeriod(newAbsencePeriod).then(() => {
  //   //     // Create success
  //   //     this.onClose();
  //   //   }).catch((err) => {
  //   //     // Failure
  //   //     console.error(err);
  //   //     this.setState({
  //   //       saving: false,
  //   //     });
  //   //   });
  //   // }
  // }

  save() {
    const { absenceStore, employerMode, updateAbsencePeriodState } = this.props;
    const { absencePeriod } = this.state; // selectedAbsenceDays, toBeDeletedAbsenceDays
    const updatedAbsencePeriod = cloneDeep(absencePeriod);

    updatedAbsencePeriod.absenceEntries.forEach((absenceEntry) => {
      if (updatedAbsencePeriod.absenceType.hasTimes) {
        const duration = updatedAbsencePeriod.values.find((val) => val.name === 'duration');

        if (!absenceEntry.values) {
          // Initialize the values
          // eslint-disable-next-line no-param-reassign
          absenceEntry.values = [];
        }

        if (updatedAbsencePeriod.fromTime) {
          // entry.values.push({ name: 'fromTime', value: updatedAbsencePeriod.fromTime });
          const fromTimeSplit = updatedAbsencePeriod.fromTime.split(':');
          const fromHour = fromTimeSplit[0];
          const fromMinute = fromTimeSplit[1];
          absenceEntry.calendarEntry.from.set({ hour: fromHour, minute: fromMinute });
        }
        if (updatedAbsencePeriod.toTime) {
          // absenceEntry.values.push({ name: 'toTime', value: updatedAbsencePeriod.toTime });
          const toTimeSplit = updatedAbsencePeriod.toTime.split(':');
          const toHour = toTimeSplit[0];
          const toMinute = toTimeSplit[1];
          absenceEntry.calendarEntry.to.set({ hour: toHour, minute: toMinute, second: 0 });
        }
        if (duration) {
          absenceEntry.values.push({ ...duration, unit: 'h' });
        }
      }

      if (absencePeriod.description) {
        // eslint-disable-next-line no-param-reassign
        absenceEntry.description = absencePeriod.description;
      }
    });

    // // For each selected day, try to find an existing absenceEntry
    // // If not existing, create a new one
    // selectedAbsenceDays.forEach((day) => {
    //   // const foundEntry = absencePeriod.absenceEntries.find(())
    //   const foundExistingDay = absencePeriod.absenceEntries.find((entry) => entry.calendarEntry.from.format('DD.MM.YY') === moment(day).format('DD.MM.YY'));
    //   if (day && !foundExistingDay) {
    //     // create new absence entry
    //     const calendarEntry = new CalendarEntry({
    //       from: moment(day).startOf('day'),
    //       to: moment(day).endOf('day'),
    //     });

    //     const absenceEntry = new AbsenceEntry({
    //       absenceType: absencePeriod.absenceType,
    //       calendarEntry,
    //       status: 'draft',
    //       // value: { value: absencePeriod.absenceType.default_value },

    //       values: absencePeriod.absenceType.valueTemplates.map((template) => ({ name: template.name, value: template.default_value })),
    //       // values: absencePeriod.values.map((dialogValue) => ({ name: dialogValue.name, value: dialogValue.value })),
    //     });

    //     updatedAbsencePeriod.absenceEntries.push(absenceEntry);
    //   }
    // });

    // // For each deleting day, try to find an absence entry and set deletedAt
    // toBeDeletedAbsenceDays.forEach((day) => {
    //   const dateFormat = 'YYYY-MM-DD';
    //   const formattedDay = moment(day).format(dateFormat);

    //   const deleteAtIndex = updatedAbsencePeriod.absenceEntries.findIndex((entry) => entry.calendarEntry.from.format(dateFormat) === formattedDay && entry.calendarEntry.to.format(dateFormat) === formattedDay);
    //   if (deleteAtIndex !== -1) {
    //     updatedAbsencePeriod.absenceEntries[deleteAtIndex].deletedAt = moment();
    //   }
    // });

    if (updatedAbsencePeriod.id) {
      absenceStore.updateAbsencePeriod(updatedAbsencePeriod).then(() => {
        // Update success
        this.onClose();
      }).catch((err) => {
        // Failure
        console.error(err);
        this.setState({
          saving: false,
        });
      });
    } else if (employerMode) {
      absenceStore.employerCreateAbsencePeriod(updatedAbsencePeriod).then((res) => {
        // Create success
        updateAbsencePeriodState(res);
        this.onClose();
      }).catch((err) => {
        // Failure
        console.error(err);
        this.setState({
          saving: false,
        });
      });
    } else {
      absenceStore.createAbsencePeriod(updatedAbsencePeriod).then((res) => {
        // Create success
        if (employerMode && updateAbsencePeriodState) {
          updateAbsencePeriodState(res);
        }
        this.onClose();
      }).catch((err) => {
        // Failure
        console.error(err);
        this.setState({
          saving: false,
        });
      });
    }
  }

  doOnChange(attr, value) {
    if (attr.includes('values.')) {
      // Handle nested values (jsonb column)
      const { absencePeriod: { values } } = this.state;
      const valueName = attr.split('.')[1];
      const updatedValues = [...values];
      const foundValueIndex = updatedValues.findIndex((oldValue) => oldValue.name === valueName);
      if (foundValueIndex !== -1) {
        updatedValues[foundValueIndex].value = value;
      } else {
        updatedValues.push({
          name: valueName,
          value,
        });
      }

      this.setState((prevState) => ({
        absencePeriod: {
          ...prevState.absencePeriod,
          values: updatedValues,
        },
      }));
    } else {
      // let defaultValues;
      // if (attr === 'absenceType') {
      //   defaultValues = value.valueTemplates.map((template) => ({ name: template.name, value: template.default_value }));
      // }
      // Handle absencePeriod's own info
      this.setState((prevState) => ({
        absencePeriod: {
          ...prevState.absencePeriod,
          // values: defaultValues || prevState.absencePeriod.defaultValues,
          [attr]: value,
        },
      }));
    }
  }

  addHourField(attr, label) {
    const { absencePeriod } = this.state;
    const { disabled } = this.props;

    return (

      <div
        className="pk-time-field mdc-text-field mdc-text-field--upgraded mdc-text-field--no-label"
        style={{
          // height: '44px',
          // width: '45px',
          // margin: 0,
          // padding: 0,
          width: '100%',
          backgroundColor: 'var(--mdc-theme-secondary)',
          color: 'white',
          flexDirection: 'column',
        }}
      >
        <div style={{ fontSize: '12px', paddingLeft: '5px' }}>
          {label}
        </div>
        <CustomTimeField
          // eslint-disable-next-line react/destructuring-assignment
          value={absencePeriod[attr] || '--:--'}
          attr={attr}
          doOnChange={(event, value) => {
            // this.doOnChange(attr, value, event);
            this.doOnChange(attr, value);
          }}
          doOnBlur={() => {
            if (absencePeriod?.fromTime && absencePeriod?.toTime) {
              const start = dayjs(absencePeriod.fromTime, 'HH:mm');
              const end = dayjs(absencePeriod.toTime, 'HH:mm');

              const duration = dayjs.duration(end.diff(start)).asHours();

              this.doOnChange('values.duration', duration);
            }
          }}
          handleEnter={handleEnter}
          disabled={disabled}
        />
      </div>
    );
  }

  addDescriptionField(attr, label, styles = null, className = null, textarea = true) {
    const { absencePeriod } = this.state;
    const { disabled } = this.props;

    return (
      <TextField {...{
        style: { ...styles },
        label,
        // required: true,
        disabled,
        // onBlur: (event) => {
        //   this.doOnBlur(attr, event.target.value, event, null, 'tripRoute');
        // },
        onChange: (event) => {
          const { value } = event.target;
          // this.doOnChangeRoute(attr, value, event, routeIndex);
          this.doOnChange(attr, value);
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        // onKeyPress: handleEnter,
        className,
        value: absencePeriod[attr] != null ? absencePeriod[attr] : '',
        textarea,
      }}
      />
    );
  }

  // addNumberField(attr, label, required, styles = null) {
  //   const { absencePeriod, errors } = this.state;
  //   const value = attr.includes('values.') ? absencePeriod.values[attr] : absencePeriod[attr];

  //   return (
  //     <TextField {...{
  //       style: { ...styles },
  //       // className: 'employer-work-order',
  //       label,
  //       required,
  //       name: attr,
  //       invalid: errors[attr],
  //       helpText: errors[attr] && errors[attr],
  //       type: 'number',
  //       onChange: (event) => {
  //         const { value: newValue } = event.target;
  //         delete errors[attr];
  //         this.doOnChange(attr, newValue);
  //       },
  //       // onBlur: (event) => {
  //       //   required && this.handleBlur(attr, event)
  //       // },
  //       rootProps: { ripple: false },
  //       onKeyPress: handleEnter,
  //       value: value != null ? value : '',
  //     }}
  //     />
  //   );
  // }

  addDecimalField(attr, label, styles = null, className = null) {
    const { absencePeriod, errors } = this.state;

    // This absencePeriod.values makes no sense
    // AbsencePeriod has absenceEntries but no values in itself
    // It should be the type default values, if anything

    let value;
    if (attr.includes('values.')) {
      const valueName = attr.split('.')[1];
      value = absencePeriod.values.find((oldValue) => oldValue.name === valueName)?.value || '';
    } else {
      value = absencePeriod[attr] || '';
    }

    const handleKeyDown = (event) => {
      const keyCode = event.keyCode || event.which;
      const keyValue = String.fromCharCode(keyCode);

      // Check if the pressed key is a letter and prevent its input
      if (/[a-zA-Z]/.test(keyValue)) {
        event.preventDefault();
      }
    };

    return (
      <TextField {...{
        style: { ...styles },
        label,
        type: 'text',
        autoFocus: true,
        // required: true,
        // disabled,
        pattern: '[0-9.,]*',
        // onBlur: (event) => {
        //   // this.doOnBlur(attr, event.target.value, event);
        //   this.doOnBlur(attr, event.target.value, event, routeIndex, null, 'tripRoute');
        // },
        onChange: (event) => {
          const { value: newValue } = event.target;
          delete errors[attr];
          this.doOnChange(attr, newValue);
        },
        rootProps: { ripple: false },
        theme: ['textPrimaryOnLight', 'secondaryBg'],
        onKeyPress: handleEnter,
        onKeyDown: handleKeyDown,
        className,
        value: (absencePeriod && !Number.isNaN(value)) ? value : '',
      }}
      />
    );
  }

  handleChangeForDate(attr, value) {
    this.setState((prevState) => ({
      absencePeriod: {
        ...prevState.absencePeriod,
        [attr]: moment(value),
      },
    }));
  }

  addDatePicker(attr, label) {
    const { absencePeriod } = this.state;

    return (
      <DayPickerInput
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                theme: ['textPrimaryOnLight', 'secondaryBg'],
                placeholder: '',
                style: { width: '100%' },
              }}
            />
          )
        }
        inputProps={{
          ref: null,
          className: '',
          // style: { padding: '0 15px 0 15px' },
          readOnly: true,
          // disabled,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        keepFocus={false}
        format="dd DD.MM.YYYY"
        // placeholder={`${formatDate(date, 'dd DD.MM.YYYY', 'fi')}`}
        onDayChange={(value) => this.handleChangeForDate(attr, value)}
        value={absencePeriod[attr] ? moment(absencePeriod[attr], 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay',
        }}
      />
    );
  }

  renderUserSelect() {
    const { usersWithAbsencesEnabled } = this.props;
    const { absencePeriod: { userId } } = this.state;

    return (
      // Note: check hourly-timelog-form.js renderSelect() for Material UI conversion attempt
      <Select {...{
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        className: 'white-text-rmwc-select',
        // label: 'Valitse työ',
        style: {
          backgroundColor: '#2d323e',
          // borderBottom: '2px solid #4A515C',
          // borderRadius: 0,
          textOverflow: 'ellipsis',
          padding: '0 35px 0 20px',
          marginBottom: '15px',

        },
        rootProps: { style: { width: '100%', backgroundColor: 'transparent' } },
        placeholder: 'Valitse työntekijä *',
        value: userId,
        onChange: (evt) => {
          this.doOnChange('userId', evt.target.value);
        },
      }}
      >
        {usersWithAbsencesEnabled?.map((user) => (
          <option
            key={user.id}
            value={user.id}
          >
            {user.name}
          </option>
        ))}
      </Select>
    );
  }

  renderAbsenceTypeSelect() {
    const { uiStore: { currentUser: { absenceTypes } } } = this.props;
    const { absencePeriod: { absenceType } } = this.state;

    return (
      // Note: check hourly-timelog-form.js renderSelect() for Material UI conversion attempt
      <Select {...{
        theme: ['textPrimaryOnDark', 'secondaryBg'],
        className: 'white-text-rmwc-select',
        // label: 'Valitse työ',
        style: {
          backgroundColor: '#2d323e',
          // borderBottom: '2px solid #4A515C',
          // borderRadius: 0,
          textOverflow: 'ellipsis',
          padding: '0 35px 0 20px',
          marginBottom: '5px',

        },
        rootProps: { style: { width: '100%', backgroundColor: 'transparent' } },
        placeholder: 'Valitse poissaololaji *',
        value: absenceType?.id,
        onChange: (evt) => {
          const foundAbsenceType = absenceTypes.find((type) => type.id === Number(evt.target.value));
          this.doOnChange('absenceType', foundAbsenceType);
          this.setDefaultAbsenceEntryValues(foundAbsenceType);
        },
      }}
      >
        {absenceTypes?.map((optionAbsenceType) => (
          <option
            key={optionAbsenceType.id}
            value={optionAbsenceType.id}
          >
            {optionAbsenceType.nickname}
          </option>
        ))}
      </Select>
    );
  }

  renderValueTemplateInput(valueTemplate) {
    const { t } = this.props;

    const attr = `values.${valueTemplate.name}`;
    const label = valueTemplate.unit ? `${t(`absence_types.${valueTemplate.name}`, valueTemplate.name)} (${valueTemplate.unit})` : t(`absence_types.${valueTemplate.name}`, valueTemplate.name);

    if (valueTemplate.data_type === 'number') {
      return this.addDecimalField(attr, label, { width: '100%' });
    }
    if (valueTemplate.data_type === 'string') {
      return this.addDescriptionField(attr, label);
    }
    if (valueTemplate.data_type === 'boolean') {
      return null;
    }
    return null;
  }

  renderValueTemplateInputs() {
    const { absencePeriod } = this.state;
    if (absencePeriod && absencePeriod?.absenceType.valueTemplates) {
      const inputs = [];
      absencePeriod.absenceType.valueTemplates.forEach((valueTemplate) => {
        inputs.push(
          <GridCell span={valueTemplate.grid_span || 12}>
            {this.renderValueTemplateInput(valueTemplate)}
          </GridCell>,
        );
      });

      return inputs;
    }

    return null;
  }

  render() {
    const {
      open,
      employerUpdateMode,
      handleDeleteAbsencePeriod,
      employerMode,
    } = this.props;
    const {
      showDeleteDialog,
      deleteMessage,
      absencePeriod,
      saving,
      allCalendarEntries,
    } = this.state;

    return (
      <>
        <Dialog
          className={employerUpdateMode ? 'employer-trip-route-dialog trip-route-dialg' : 'trip-route-dialog'}
          open={open}
          preventOutsideDismiss
        // onClose={() => this.onClose()}
        >
          <DialogTitle
            style={{
              color: 'var(--mdc-theme-primary)',
              padding: '5px 10px 0px',
              marginTop: '5px',
              display: 'flex',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <span style={{ flex: 1, margin: 'auto 0' }}>
              {absencePeriod.id ? 'Muokkaa poissaoloa' : 'Uusi poissaolo'}
            </span>
            {/* hack to remove autofocus on trash can */}
            <input style={{ width: '1px', height: '0px', opacity: '0' }} />
            {absencePeriod?.id && (
              <IconButton
                icon="delete_outlined"
                onClick={() => this.setState({
                  showDeleteDialog: true,
                  deleteMessage: 'Haluatko varmasti poistaa poissaolon?',
                })}
                style={{ padding: 0, alignSelf: 'end', color: '#c7c9cc' }}
              />
            )}
          </DialogTitle>
          <DialogContent
            style={{ color: 'white' }}
          >
            {/* {this.renderDescription()} */}
            {/* <h3>Lisää kilometrimäärä, selite ja reitti {t(`timelog.attributes.${kmsType}`)}</h3> */}
            {/* {this.renderRoutes()} */}

            <Grid
              // key={routeIndex}
              className="route-grid"
            >
              <GridInner>
                <GridCell span={12}>
                  <GridInner
                    style={{ rowGap: '0px' }}
                  >
                    <>
                      {/* {!absencePeriod.id ? (
                        <>
                          <GridCell span={12} style={{ marginBottom: '5px' }}>
                            {this.renderSelect()}
                          </GridCell>

                          {absencePeriod.absenceType && (
                            <>
                              <GridCell span={12}>
                                <GridInner>
                                  <GridCell
                                    desktop={absencePeriod.absenceType.hasTimes ? 9 : 12}
                                    tablet={absencePeriod.absenceType.hasTimes ? 6 : 8}
                                    phone={absencePeriod.absenceType.hasTimes ? 3 : 4}
                                  >
                                    {this.addDatePicker('fromDate', 'Alkamispäivä *')}
                                  </GridCell>
                                  {absencePeriod.absenceType.hasTimes && (
                                    <GridCell
                                      desktop={3}
                                      tablet={2}
                                      phone={1}
                                    >
                                      {this.addHourField('fromTime')}
                                    </GridCell>
                                  )}
                                </GridInner>
                              </GridCell>

                              <GridCell span={12}>
                                <GridInner>
                                  <GridCell
                                    desktop={absencePeriod.absenceType.hasTimes ? 9 : 12}
                                    tablet={absencePeriod.absenceType.hasTimes ? 6 : 8}
                                    phone={absencePeriod.absenceType.hasTimes ? 3 : 4}
                                  >
                                    {this.addDatePicker('toDate', 'Päättymispäivä *')}
                                  </GridCell>
                                  {absencePeriod.absenceType.hasTimes && (
                                    <GridCell
                                      desktop={3}
                                      tablet={2}
                                      phone={1}
                                    >
                                      {this.addHourField('toTime')}
                                    </GridCell>
                                  )}
                                </GridInner>
                              </GridCell>

                              {this.renderValueTemplateInputs()}

                              <GridCell span={12}>
                                {this.addDescriptionField('description', 'Lisätietoa', { width: '100%', marginBottom: '1rem', color: 'white' }, null, false)}
                              </GridCell>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <GridCell span={12}>
                            <div>{absencePeriod.absenceType.officialName}</div>
                            <div style={{ textTransform: 'capitalize', marginLeft: '5px', marginBottom: '15px' }}>
                              <span>{`${absencePeriod.fromDate.format('dd DD.MM.YYYY')} ${absencePeriod.fromTime || ''}`}</span>
                              &nbsp;
                              <span>-</span>
                              &nbsp;
                              <span>{`${absencePeriod.toDate.format('dd DD.MM.YYYY')} ${absencePeriod.toTime || ''}`}</span>
                            </div>
                          </GridCell>

                          {console.error('RENDERING IN ABSENCE PERIOD DIALOG: ', allCalendarEntries)}
                          {console.error('RENDERING IN ABSENCE PERIOD DIALOG: ', Object.keys(allCalendarEntries))}
                          {Object.keys(allCalendarEntries).length > 0 && (
                            <GridCell span={12}>
                              <EmployeeCalendar employerWorkDayMode employeeCalendarEntries={allCalendarEntries} setSelectedWorkDays={this.setSelectedWorkDays} />
                            </GridCell>
                          )}

                          <GridCell span={12}>
                            {this.addDescriptionField('description', 'Lisätietoa', { width: '100%', marginBottom: '1rem', color: 'white' }, null, false)}
                          </GridCell>
                        </>
                      )} */}

                      {/* {!absencePeriod.id ? (
                        <>
                          <GridCell span={12} style={{ marginBottom: '5px' }}>
                            {this.renderSelect()}
                          </GridCell> */}

                      {/* {absencePeriod.absenceType && (
                            <>
                              <GridCell span={12}>
                                <GridInner>
                                  <GridCell
                                    desktop={absencePeriod.absenceType.hasTimes ? 9 : 12}
                                    tablet={absencePeriod.absenceType.hasTimes ? 6 : 8}
                                    phone={absencePeriod.absenceType.hasTimes ? 3 : 4}
                                  >
                                    {this.addDatePicker('fromDate', 'Alkamispäivä *')}
                                  </GridCell>
                                  {absencePeriod.absenceType.hasTimes && (
                                    <GridCell
                                      desktop={3}
                                      tablet={2}
                                      phone={1}
                                    >
                                      {this.addHourField('fromTime')}
                                    </GridCell>
                                  )}
                                </GridInner>
                              </GridCell>

                              <GridCell span={12}>
                                <GridInner>
                                  <GridCell
                                    desktop={absencePeriod.absenceType.hasTimes ? 9 : 12}
                                    tablet={absencePeriod.absenceType.hasTimes ? 6 : 8}
                                    phone={absencePeriod.absenceType.hasTimes ? 3 : 4}
                                  >
                                    {this.addDatePicker('toDate', 'Päättymispäivä *')}
                                  </GridCell>
                                  {absencePeriod.absenceType.hasTimes && (
                                    <GridCell
                                      desktop={3}
                                      tablet={2}
                                      phone={1}
                                    >
                                      {this.addHourField('toTime')}
                                    </GridCell>
                                  )}
                                </GridInner>
                              </GridCell>

                              {this.renderValueTemplateInputs()}

                              <GridCell span={12}>
                                {this.addDescriptionField('description', 'Lisätietoa', { width: '100%', marginBottom: '1rem', color: 'white' }, null, false)}
                              </GridCell>
                            </>
                          )}
                        </>
                      ) : ( */}
                      <>
                        {employerMode && (
                          <GridCell span={12} style={{ marginBottom: '15px' }}>
                            {this.renderUserSelect()}
                          </GridCell>
                        )}

                        {!absencePeriod.id ? (
                          <GridCell span={12} style={{ marginBottom: '5px' }}>
                            {this.renderAbsenceTypeSelect()}
                          </GridCell>
                        ) : (
                          <GridCell span={12}>
                            <div>{absencePeriod.absenceType.officialName}</div>
                          </GridCell>
                        )}
                        {/* <GridCell span={12}>
                          {absencePeriod.id && (
                            <div>{absencePeriod.absenceType.officialName}</div>
                          )}
                          <div style={{ textTransform: 'capitalize', marginLeft: '5px', marginBottom: '15px' }}>
                            {absencePeriod.fromDate && absencePeriod.toDate ? (
                              <>
                                <span>{`${absencePeriod.fromDate.format('dd DD.MM.YYYY')} ${absencePeriod.fromTime || ''}`}</span>
                                &nbsp;
                                <span>-</span>
                                &nbsp;
                                <span>{`${absencePeriod.toDate.format('dd DD.MM.YYYY')} ${absencePeriod.toTime || ''}`}</span>
                              </>
                            ) : (
                              <span>Valitse päivämäärät</span>
                            )}
                          </div>
                        </GridCell> */}
                        {absencePeriod.absenceType && (
                          <>
                            {Object.keys(allCalendarEntries).length > 0 && (
                              <GridCell span={12} style={{ marginTop: '20px' }}>
                                <EmployeeCalendar employerWorkDayMode employeeCalendarEntries={allCalendarEntries} setSelectedWorkDays={this.setSelectedWorkDays} />
                              </GridCell>
                            )}

                            {absencePeriod.absenceType.hasTimes && (
                              <GridCell span={12}>
                                <GridInner style={{ rowGap: 0 }}>
                                  <GridCell
                                    desktop={4}
                                    tablet={3}
                                    phone={2}
                                  >
                                    {this.addHourField('fromTime', 'Alkamisajankohta')}
                                  </GridCell>

                                  <GridCell
                                    desktop={4}
                                    tablet={3}
                                    phone={2}
                                  >
                                    {this.addHourField('toTime', 'Päättymisajankohta')}
                                  </GridCell>

                                  <GridCell
                                    desktop={4}
                                    tablet={2}
                                    phone={4}
                                  >
                                    {this.renderValueTemplateInput({
                                      data_type: 'number',
                                      name: 'duration',
                                      unit: 'h',
                                    })}
                                  </GridCell>
                                </GridInner>
                              </GridCell>
                            )}

                            <GridCell span={12}>
                              {this.addDescriptionField('description', 'Lisätietoa', { width: '100%', marginBottom: 0, color: 'white' }, null, false)}
                            </GridCell>
                          </>
                        )}
                      </>
                      {/* )} */}
                    </>
                  </GridInner>
                </GridCell>
              </GridInner>
            </Grid>

          </DialogContent>
          <DialogActions
            style={{
              justifyContent: 'space-between',
              padding: '15px',
            }}
          >
            <DialogButton
              className="accept-button"
              style={{
                borderRadius: '0px',
              }}
              onClick={() => {
                this.startSave();
                // this.cleanUp();
              }}
              // disabled={!absencePeriod.fromDate || !absencePeriod.toDate || !absencePeriod.absenceType || saving}
              disabled={(absencePeriod.absenceEntries && absencePeriod.absenceEntries?.length === 0) || saving}
            // action="close"
            >
              {saving ? <LoadingSpinner color="black" size="mini" /> : 'Tallenna'}
            </DialogButton>

            <DialogButton
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
              }}
              // action="cancel"
              onClick={() => {
                // this.cleanUp();
                // toggleRouteDialog();
                this.onClose();
              }}
            >
              Peruuta
            </DialogButton>
          </DialogActions>
        </Dialog>

        {showDeleteDialog && (
          <Dialog
            className="dialog-background mini-dialog employer-trip-route-dialog"
            open={showDeleteDialog}
            preventOutsideDismiss
          >
            <DialogContent
              style={{ color: 'white' }}
            >
              <p>{deleteMessage}</p>
            </DialogContent>
            <DialogActions
              style={{
                justifyContent: 'space-between',
                padding: '15px',
              }}
            >
              <DialogButton
                className="accept-button"
                style={{
                  borderRadius: '0px',
                }}
                // action="accept"
                onClick={() => {
                  handleDeleteAbsencePeriod(absencePeriod.id);
                  this.setState({
                    showDeleteDialog: false,
                    deleteMessage: '',
                  });
                }}
              >
                Kyllä
              </DialogButton>
              <DialogButton
                style={{
                  color: '#c7c9cc',
                  border: '1px solid #c7c9cc',
                  borderRadius: '0px',
                }}
                // action="close"
                onClick={() => {
                  this.setState({
                    showDeleteDialog: false,
                    deleteMessage: '',
                  });
                }}
              >
                Peruuta
              </DialogButton>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

export default AbsencePeriodDialog;
