/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridCell } from '@rmwc/grid';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from '@rmwc/dialog';

import 'moment/locale/fi';
import moment from '../utils/moment';


const handleEnter = (e) => {
  if (e.key === 'Enter') {
    document.activeElement.blur();
  }
};

@inject('uiStore', 'loginStore', 't', 'closeCallReportStore')
@observer
class ShowAccidentReportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id,
      description: props.description,
      // fileURL: '',
      // filename: '',
      files: props.files || [],
      file: props.file,
      name: props.name,
      status: props.status,
      errors: {},
      inputChanged: false,
      accidentReport: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name || prevProps.description !== this.props.description || prevProps.files !== this.props.files) {
      this.setState({
        name: this.props.name,
        description: this.props.description,
        files: this.props.files,
        file: this.props.file,
      });
    }
  }

  renderActionButtons() {
    const {
      name,
      description,
      files,
    } = this.state;
    const { employerView, accidentReport: { response } } = this.props;

    if (employerView && !response) {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            type="button"
            className="accept-button"
            style={{
              borderRadius: '0px',
              minWidth: '100px',
            }}
            onClick={() => {
              this.handleValidate(name, description, files);
            }}
            action="submit"
          >
            Vastaa
          </DialogButton>
          <DialogButton
              type="button"
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
                minWidth: '100px',
              }}
              action="cancel"
            >
              Peruuta
            </DialogButton>
        </DialogActions>
      );
    } else {
      return (
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
              type="button"
              style={{
                color: '#c7c9cc',
                border: '1px solid #c7c9cc',
                borderRadius: '0px',
                minWidth: '100px',
              }}
              action="cancel"
            >
              Sulje
            </DialogButton>
        </DialogActions>
      );
    }
  }

  renderAttr(attr, content) {
    const { t, employerView } = this.props;
    return (
      <div>
        <div
          style={{
            color: !employerView ? 'var(--mdc-theme-primary, #6200ee)' : 'black',
            fontWeight: employerView ? '500' : '',
          }}
        >
          {t(`accident_reports.${attr}`)}
        </div>
        <div
          style={{
            color: employerView ? 'black' : '',
            fontSize: '14px',
          }}
        >
          {content && content !== 'Invalid date' ? content : '-'}
        </div>
      </div>
    )
  }

  render() {
    const {
      t,
      open,
      handleClose,
      employerView,
      accidentReport: {
        reporter,
        createdAt,
        date,
        time,
        location,
        workSteps,
        relatedObject,
        eventSteps,
        causes,
        fixSuggestions,
        victimName,
        victimUnit,
        victimTask,
        workFrom,
        workTo,
        victimStoppedWorkingImmediacy,
        victimStoppedWorkingDate,
        victimStoppedWorkingTime,
        disablementDurationEstimate,
        treatmentStartImmediacy,
        treatmentStartDate,
        treatmentStartTime,
        treatmentPlace,
        employerInformedDate,
        employerInformedTime,
        investigatorsInformedDate,
        investigatorsInformedTime,
        authoritiesInformedDate,
        authoritiesInformedTime,
        injuryType,
        injuredBodyPart,
        leftBodyPart,
        rightBodyPart,
      },
      accidentReport,
      styles,
    } = this.props;

    console.log('RENDERING SHOW: ', accidentReport);

    return (
      <Dialog
        className={`modal-size close-call-report-dialog ${employerView ? 'employer-modal': 'trip-route-dialog'}`} // "modal-size employer-modal"
        open={open}
        onClose={(evt) => {
          if (evt.detail.action === 'submit') {
            this.respond();
            // DO NOT PROCEED IF RESPOND FAILS?
            // handleClose();

            // const error = validate.attachments(description, files);
            // if (!error) {
            //   // We cannot send the whole file objects, only the signed IDs, for finding and attaching the files to the model
            //   let signedIds = files.map((file) => {
            //     return file['signedId'];
            //   });
            //   handleAttachmentAction({...this.state, workOrderId: workOrderId, id: id, files: signedIds });
            //   handleClose();
            // }
          } else {
            handleClose();
          }
        }}
      >
        <DialogTitle
          style={{
            padding: '0 20px',
          }}
        >
          <div>
            <span
              style={{ color: 'var(--mdc-theme-primary)' }}
            >
              Tapaturmailmoitus
            </span>
            <br />
            <span
              style={{
                color: 'white',
                fontSize: '14px',
              }}
            >
              {reporter?.firstName} {reporter?.lastName} - {createdAt?.format('dd DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </DialogTitle>
        <DialogContent>
          <Grid style={{ color: 'white' }}>
            <GridCell span={6}>
              <div
                style={{
                  color: !employerView ? 'var(--mdc-theme-primary, #6200ee)' : 'black',
                  fontWeight: employerView ? '500' : '',
                }}
              >
                {t(`close_call_reports.eventTime`)}
              </div>
              <div
                style={{
                  color: !employerView ? 'white' : 'black',
                  fontSize: '14px',
                }}
              >
                {date?.format('dd DD.MM.YYYY')} {time?.format('HH:mm')}
              </div>
            </GridCell>

            <GridCell span={6}>
              {/* {location} */}
              {this.renderAttr('location', location)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('workSteps', workSteps)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('relatedObject', relatedObject)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('eventSteps', eventSteps)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('causes', causes)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('fixSuggestions', fixSuggestions)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('victimName', victimName)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('victimUnit', victimUnit)}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('victimTask', victimTask)}
            </GridCell>

            <GridCell span={12} style={{ marginBottom: '-20px' }}>Työn piti tapahtumapäivänä</GridCell>
            <GridCell span={6}>
              {this.renderAttr('workFrom', moment(workFrom).format('HH:mm'))}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('workTo', moment(workTo).format('HH:mm'))}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('victimStoppedWorkingImmediacy', t(`accident_reports.victimStoppedWorkingImmediacys.${victimStoppedWorkingImmediacy}`))}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('victimStoppedWorkingDate', moment(victimStoppedWorkingDate).format('dd DD.MM.YYYY'))}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('victimStoppedWorkingTime', moment(victimStoppedWorkingTime).format('HH:mm'))}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('disablementDurationEstimate', t(`accident_reports.disablementDurationEstimates.${disablementDurationEstimate}`))}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('treatmentStartImmediacy', t(`accident_reports.treatmentStartImmediacys.${treatmentStartImmediacy}`))}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('treatmentStartDate', moment(treatmentStartDate).format('dd DD.MM.YYYY'))}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('treatmentStartTime', moment(treatmentStartTime).format('HH:mm'))}
            </GridCell>

            <GridCell span={12}>
              {this.renderAttr('treatmentPlace', treatmentPlace)}
            </GridCell>

            <GridCell span={12}>
              <div
                style={{
                  color: !employerView ? 'var(--mdc-theme-primary, #6200ee)' : 'black',
                  fontWeight: employerView ? '500' : '',
                }}
              >
                Esimiehelle ilmoitettu
              </div>
              <div
                style={{
                  color: !employerView ? 'white' : 'black',
                  fontSize: '14px',
                }}
              >
                {moment(employerInformedDate).format('dd DD.MM.YYYY') === 'Invalid date' ? '-' : moment(employerInformedDate).format('dd DD.MM.YYYY')} {moment(employerInformedTime).format('HH:mm') === 'Invalid date' ? '-' : moment(employerInformedTime).format('HH:mm')}
              </div>
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('injuryType', injuryType)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('injuredBodyPart', injuredBodyPart)}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('leftBodyPart', leftBodyPart ? 'Kyllä' : 'Ei')}
            </GridCell>

            <GridCell span={6}>
              {this.renderAttr('rightBodyPart', rightBodyPart ? 'Kyllä' : 'Ei')}
            </GridCell>

          </Grid>
        </DialogContent>
        {this.renderActionButtons()}
      </Dialog>
    )
  }
}

export default ShowAccidentReportDialog;
