import { action, observable, set } from 'mobx';
import moment, { dateWithoutTime } from '../utils/moment';
import RouteLocation from './RouteLocation';

const getFloat = (value) => {
  let newValue;
  if (typeof value === 'string') {
    newValue = Math.round(parseFloat(value || '0.0') * 100) / 100;
  }

  if (newValue === '' || newValue == null || Number.isNaN(newValue) || newValue === 0) {
    newValue = '';
  }

  return newValue;
};

const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class TripRoute {
  @observable id;

  @observable createdAt;

  @observable description;

  @observable kmsType;

  @observable kms;

  @observable routeType;

  @observable status;

  @observable updatedAt;

  @observable workHourId;

  @observable startTripId;

  @observable endTripId;

  @observable date;

  @observable workOrderName;

  @observable routeLocations = [];

  @observable employerComment;

  @observable _destroy;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      work_hour_id: o.workHourId,
      start_trip_id: o.startTripId,
      end_trip_id: o.endTripId,
      kms: o.kms,
      kms_type: o.kmsType,
      description: o.description,
      route_type: o.routeType,
      status: (o.status == null) ? 'draft' : o.status,
      route_locations_attributes: o.routeLocations.map((routeLocation) => RouteLocation.toJson(routeLocation)),
      _destroy: (o._destroy == null) ? 0 : o._destroy,
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeFloatAttr(attr, value) {
    set(this, attr, getFloat(value));
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      workHourId: o.work_hour_id,
      startTripId: o.start_trip_id,
      endTripId: o.end_trip_id,
      kms: o.kms,
      kmsType: o.kms_type,
      description: o.description,
      routeType: o.route_type,
      status: o.status == null ? 'draft' : o.status,
      date: getDate(o.date),
      workOrdername: o.work_order_name,
      employerComment: o.employer_comment,
    };

    let routeLocations = [];
    if (o.route_locations != null && o.route_locations.length > 0) {
      routeLocations = o.route_locations.map((item) => RouteLocation.fromJsonProperties(item));
    }
    // else {
    //   routeLocations.push(new RouteLocation({}));
    //   routeLocations.push(new RouteLocation({}));
    // }
    values.routeLocations = routeLocations;

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const tripRoute = new TripRoute({});
    tripRoute.updatePropertiesFromJson(object);
    return tripRoute;
  }

  static fromJson(json) {
    return TripRoute.fromJsonProperties(JSON.parse(json));
  }
}
