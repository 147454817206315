import { observable, action } from 'mobx';
import moment from 'moment';
import RouteLocation from '../timelog/RouteLocation';

export default class TripDistanceAutofill {
  @observable id;

  @observable acceptedCount;

  @observable kmsSource;

  @observable departureLocation;

  @observable destinationLocation;

  @observable kms;

  @observable createdAt;

  @observable updatedAt;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(o) {
    this.updateProperties({
      id: o.id,
      kmsSource: o.kms_source,
      acceptedCount: o.accepted_count,
      departureLocation: RouteLocation.fromJsonProperties(o.departure_location),
      destinationLocation: RouteLocation.fromJsonProperties(o.destination_location),
      createdAt: moment(o.created_at),
      updatedAt: moment(o.updated_at),
      kms: o.kms,
    });
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static fromJsonProperties(object) {
    const tripDistanceAutofill = new TripDistanceAutofill({});
    tripDistanceAutofill.updatePropertiesFromJson(object);
    return tripDistanceAutofill;
  }

  static fromJson(json) {
    return TripDistanceAutofill.fromJsonProperties(JSON.parse(json));
  }

  // static toJson(o) {
  //   return {
  //     id: o.id,
  //     identifier: o.identifier,
  //     description: o.description,
  //     user_id: o.userId,
  //     certificate_validities_attributes: certificateValiditiesJson,
  //     category_one: o.category_one?.id,
  //     category_two: o.category_two?.id,
  //   };
  // }
}
