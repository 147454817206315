/* eslint-disable */
import React, { Component } from 'react';
import { when } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Typography } from '@rmwc/typography';
import {
  TextField, ThemeProvider, Button, Icon,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { Grid, GridCell, GridInner } from '@rmwc/grid';
import { Checkbox } from '@rmwc/checkbox';
import { Elevation } from '@rmwc/elevation';
import { IconButton } from '@rmwc/icon-button';
import { Switch } from '@rmwc/switch';
// import { Icon } from '@rmwc/icon';
import { Snackbar } from '@rmwc/snackbar';
import { Card } from '@rmwc/card';
import { fromPromise } from 'mobx-utils';
import {
  CollapsibleList,
  List,
  ListGroup,
  ListItem,
  ListItemMeta,
  ListDivider,
  SimpleListItem,
} from '@rmwc/list';
import {
  Tab,
  TabBar,
} from '@rmwc/tabs';
import {
  Dialog, DialogActions, DialogButton, DialogContent,
} from '@rmwc/dialog';


import AddIcon from '@material-ui/icons/Add';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';

import { v4 as uuidv4 } from 'uuid';

import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/fi';
import { groupBy } from 'lodash';
import moment, { shortDateRangeWithDay, dateWithoutTime } from '../utils/moment';
// import WorkOrderInvitation from './WorkOrderInvitation';
import CalendarEntry from '../models/CalendarEntry';
import WorkOrder from '../calendar/WorkOrder';

// import Linkify from '../common/Linkify';
import EmployerWorkOrderAttachment from './EmployerWorkOrderAttachment';
import AutocompletableAddress from '../shared/AutocompletableAddress';
import LocationDialog from '../locations-purchasers/LocationDialog';
import PurchaserDialog from '../locations-purchasers/PurchaserDialog';
import WorkTasks from './work-tasks';
import WorkOrderSettingsDialog from './WorkOrderSettingsDialog';
import EmployeesTable from '../employees/EmployeesTable';
import CustomTimeField from '../shared/CustomTimeField';
import WorkPeriodDialog from './WorkPeriodDialog';
import WorkPeriod from '../models/WorkPeriod';
import WorkPeriodForm from './WorkPeriodForm';
import LoadingSpinner from '../shared/LoadingSpinner';
import WorkOrderInvitationListItem from './WorkOrderInvitationListItem';

import theme from '../muiTheme';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

const getDurationColorClasses = (from, to) => {
  const today = moment().startOf('day');

  if (today.isBetween(from, to)) {
    return 'work-order-card-border ongoing-color';
  }

  if (today.format('YYYY-MM-DD') < from.format('YYYY-MM-DD')) {
    return 'work-order-card-border upcoming-color';
  }
  // return 'work-order-date-range';
  return 'work-order-card-border archived-color';
};

const customDateRangePickerOverlay = ({ classNames, children, ...props }) => (
  <div
    className={classNames.overlayWrapper}
    {...props}
  >
    <div style={{ display: 'flex', flexDirection: 'column' }} className={classNames.overlay}>
      {children}
      {/* <div style={{ paddingBottom: '10px', textAlign: 'center' }}>
        <button type="button" className="accept-button mdc-dialog__button mdc-button" onClick={() => this.closeDateRangePicker()}>Valmis</button>
      </div> */}
    </div>
  </div>
);

const isEmptyValidation = (fieldValue) => {
  if (!fieldValue) {
    return 'work_order.errors.isEmpty';
  }
  if (fieldValue.trim() === '') {
    // return `${label} on vaadittu kenttä`
    return 'work_order.errors.isEmpty';
  }
  return null;
};

const textInputValidation = (fieldValue) => {
  if (!fieldValue) {
    return 'work_order.errors.isEmpty';
  }
  const isEmptyError = isEmptyValidation(fieldValue);
  if (isEmptyError) {
    return isEmptyError;
  }
  return null;
};

const selectValidation = (fieldValue) => {
  if (!fieldValue) {
    return 'work_order.errors.isEmpty';
  }
  const isEmptyError = isEmptyValidation(fieldValue.toString());
  if (isEmptyError) {
    return isEmptyError;
  }
  return null;
};

const dateValidation = (fieldValue) => {
  if (!fieldValue) {
    return 'work_order.errors.isEmpty';
  }
  if (fieldValue === 'Syötä päivämäärä') {
    return 'work_order.errors.isEmpty';
  }
  return null;
};

const validate = {
  name: (name) => textInputValidation(name),
  purchaser: (purchaser) => selectValidation(purchaser),
  location: (location) => selectValidation(location),
  from: (from) => dateValidation(from),
  to: (to) => dateValidation(to),
  inviteDeadline: (inviteDeadline) => dateValidation(inviteDeadline),
  // accomodationName: (accomodationName) => textInputValidation(accomodationName),
};

@inject('employerWorkOrderStore', 'uiStore', 't', 'loginStore', 'locationStore', 'purchaserStore')
@observer
class EmployerWorkOrderDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0,
      errors: {},
      inputChanged: false,
      openSnackbar: false,
      saveStatusMessage: '',

      showDeletePrivilegeDialog: false,
      privilegeToDelete: null,

      showDeleteInviteDialog: false,
      inviteToDelete: null,

      deleteMessage: '',
      showInstructionDialog: false,
      // showOverlappings: false,
      optionsOpen: false,
      privilegesToBeCreated: [],

      showLocationDialog: false,
      showPurchaserDialog: false,
      tempLocation: {},
      tempPurchaser: {},

      showSettingsDialog: false,
      // Default invite deadline time
      inviteDeadlineTime: '23:59',
      showWorkPeriodDialog: false,
      showDateChangeNotificationDialog: false,
      editingWorkPeriods: false,
    };

    this.updateAddress = this.updateAddress.bind(this);
  }

  componentDidMount() {
    const {
      employerWorkOrderStore: {
        workOrderCache, // getAvailabilities, getWorkPeriods,
      },
      uiStore,
      uiStore: {
        clickedTab,
      },
      loginStore,
    } = this.props;

    loginStore.getAutocompleteAccomodations(uiStore.currentUser).then((result) => {
      this.setState({
        autocompleteAccomodations: result,
      });
    });

    if (uiStore.currentView.mode === 'edit') {
      const foundWo = workOrderCache.workOrders.find((wo) => (
        uiStore.currentView.id === wo.id
      ));

      // const availabilities = getAvailabilities(foundWo.id, foundWo.from.format('YYYY-MM-DD'), foundWo.to.format('YYYY-MM-DD'));
      const availabilities = [];

      this.getWorkPeriods(foundWo);

      // Setting default invite deadline
      // If work order has ended (to date is in the past), we want to use tomorrow's date
      // Invitations shouldn't have a deadline that is in the past
      const tomorrow = moment().add('1', 'days').format('YYYY-MM-DD 23:59:00');
      const workOrderEndDay = foundWo.to.format('YYYY-MM-DD 23:59:00');
      const inviteDeadline = workOrderEndDay < tomorrow ? tomorrow : workOrderEndDay;

      this.setState({
        originalWorkOrder: { ...foundWo },
        workOrder: { ...foundWo, invitations: [] },
        address: {
          name: foundWo.accomodationName,
          street: foundWo.accomodationStreet,
          city: foundWo.accomodationCity,
          zipCode: foundWo.accomodationZipCode,
        },
        inviteDeadline,
        availabilities,
        woName: foundWo.name,
      }, () => {
        this.getEmployers();
      });
    } else if (uiStore.currentView.mode === 'new') {
      this.setState({
        workOrder: {
          invitations: [],
          workPeriods: [new WorkPeriod({ name: 'Jakso 1', tempId: uuidv4() })],
          settings: WorkOrder.defaultSettings,
        },
        address: {
          name: null,
          street: null,
          city: null,
          zipCode: null,
        },
        inviteDeadline: moment().add('1', 'days').format('YYYY-MM-DD 23:59:00'),
      }, () => {
        this.getEmployers();
      });
    }

    if (clickedTab) {
      this.setState({
        activeTab: clickedTab,
      });
      uiStore.emptyActiveTab();
    }
  }

  handleSubmit = (evt) => {
    const { employerWorkOrderStore } = this.props;
    const { workOrder, privilegesToBeCreated, inviteDeadline } = this.state;
    const { t } = this.props;

    let errors = {};

    evt.preventDefault();
    Object.keys(validate).forEach((key) => {
      if (key === 'inviteDeadline') {
        const error = validate[key](this.state[key]);

        if (error) {
          errors = { ...errors, [key]: t(error, { label: t(`work_order.labels.${key}`) }) };
        }
      } else if (key === 'purchaser' || key === 'location') {
        const error = validate[key](workOrder[key]?.id);

        if (error) {
          errors = { ...errors, [key]: t(error, { label: t(`work_order.labels.${key}`) }) };
        }
      } else {
        const error = validate[key](workOrder[key]);

        if (error) {
          errors = { ...errors, [key]: t(error, { label: t(`work_order.labels.${key}`) }) };
        }
      }
    });

    if (Object.keys(errors).length > 0) {
      this.setState({
        errors,
      });
    } else {
      this.setState({
        isSaving: true,
      });

      // Adding temp privileges (checkbox values) into the object so that they are saved as nested_params
      if (workOrder.userPrivileges) {
        // Merge with existing privileges (update work order)
        workOrder.userPrivileges = workOrder.userPrivileges.concat(privilegesToBeCreated);
      } else {
        // New work order, no existing userPrivileges so cannot merge
        workOrder.userPrivileges = privilegesToBeCreated;
      }

      workOrder.workPeriods.forEach((workPeriod) => {
        workPeriod.invitations.forEach((invitation) => {
          if (!invitation.inviteDeadline) {
            invitation.inviteDeadline = inviteDeadline;
          }
        });
      });

      if (workOrder.id != null) {
        const savePromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.updateWorkOrder(workOrder, resolve, reject)))

        when(
          () => savePromise.state !== "pending",
          () => {
            savePromise.case({
              pending: () => {
              },
              rejected: (e) => {
                console.error('Tallennus epäonnistui: ', e);

                this.setState({
                  openSnackbar: true,
                  saveStatusMessage: 'Tallennus epäonnistui',
                  isSaving: false,
                }, () => this.getWorkPeriods())
              },
              fulfilled: (updatedWorkOrder) => {
                this.setState({
                  openSnackbar: true,
                  saveStatusMessage: 'Muutoksesi tallennettiin',
                  inputChanged: false,
                  workOrder: { ...updatedWorkOrder, sendAsSms: false, invitations: [] },
                  privilegesToBeCreated: [],
                  woName: updatedWorkOrder.name,
                  isSaving: false,
                }, () => this.getWorkPeriods())
              }
            })
          }
        )
      } else {
        const savePromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.createWorkOrder(workOrder, resolve, reject)))

        when(
          () => savePromise.state !== "pending",
          () => {
            savePromise.case({
              pending: () => {
              },
              rejected: (e) => {
                console.error('Tallennus epäonnistui: ', e);
                this.setState({
                  openSnackbar: true,
                  saveStatusMessage: 'Tallennus epäonnistui',
                  isSaving: false,
                }, () => this.getWorkPeriods())
              },
              fulfilled: (newWorkOrder) => {
                this.setState({
                  openSnackbar: true,
                  saveStatusMessage: 'Keikka on tallennettu',
                  inputChanged: false,
                  // workOrder: { ...workOrder, id: newWorkOrder.id, sendAsSms: false, invitations: [] }
                  workOrder: { ...newWorkOrder, sendAsSms: false, invitations: [] },
                  privilegesToBeCreated: [],
                  isSaving: false,
                }, () => this.getWorkPeriods())
              }
            })
          }
        )
      }
    }
  }

  openLocationDialog(value) {
    this.setState({
      showLocationDialog: true,
      dialogValue: value,
    });
  }

  openPurchaserDialog(value) {
    this.setState({
      showPurchaserDialog: true,
      dialogValue: value,
    });
  }

  addTextField(attr, label, required, styles = null, textarea = false, disabled = false) {
    const { workOrder, errors } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <TextField {...{
          variant: 'filled',
          style: { ...styles },
          className: disabled ? 'disabled-employer-text-field' : null,
          label,
          required,
          name: attr,
          invalid: errors[attr],
          helperText: errors[attr] && errors[attr],
          type: 'text',
          disabled,
          multiline: textarea,
          onChange: (event) => {
            const { value } = event.target;
            delete errors[attr];
            this.setState({
              workOrder: { ...workOrder, [attr]: value },
              inputChanged: true,
              // errors: { ...errors, [attr]: null }
              errors: { ...errors },
            });
            // workOrder.changeAttribute(attr, value);
          },
          // onBlur: (event) => {
          //   this.doOnRouteLocationBlur(attr, event.target.value, event, routeIndex, locationIndex);
          // },
          onBlur: (event) => required ? this.handleBlur(attr, event) : null,
          onKeyPress: handleEnter,
          value: workOrder?.[attr] != null ? workOrder?.[attr] : '',
        }}
        />
      </ThemeProvider>
    );
  }


  addNumberField(attr, label, required, styles = null) {
    const { workOrder, errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        // className: 'employer-work-order',
        label,
        required,
        name: attr,
        invalid: errors[attr],
        helperText: errors[attr] && errors[attr],
        type: 'number',
        onChange: (event) => {
          const { value } = event.target;
          delete errors[attr];
          this.setState({
            workOrder: { ...workOrder, [attr]: value },
            inputChanged: true,
            errors: { ...errors },
          });
        },
        onBlur: (event) => {
          required && this.handleBlur(attr, event);
        },
        // rootProps: { ripple: false },
        onKeyPress: handleEnter,
        value: workOrder?.[attr] != null ? workOrder?.[attr] : '',
      }}
      />
    );
  }

  /*
  addDropDown(attr, label, required, styles, addNew) {
    const { workOrder, errors } = this.state;
    const { employerWorkOrderStore: { purchasers, locations } } = this.props;

    // Sort the purchasers and locations alphabetically
    const sortedPurchasers = purchasers?.slice().sort((a, b) => a.name.localeCompare(b.name));
    const sortedLocations = locations?.slice().sort((a, b) => a.name.localeCompare(b.name));

    return (
      <Select
        {...{
          label,
          rootProps: {
            style: { ...styles },
          },
          required,
          name: attr,
          invalid: errors[attr],
          helpText: errors[attr] && errors[attr],
          placeholder: `Valitse ${label.toLowerCase()}`,
          // className: 'mdc-theme--text-primary-on-dark pk-select',
          // disabled: this.props.disabled,
          onBlur: (event) => {
            required && this.handleBlur(attr, event);
          },
          onChange: (event) => {
            delete errors[attr];
            if (event.target.value === '') {
              return;
            }
            if (event.target.value === 'location-add-new') {
              // We are trying to add a new attr, toggle the relevant dialog
              this.openLocationDialog();
              return;
            }
            if (event.target.value === 'purchaser-add-new') {
              // We are trying to add a new attr, toggle the relevant dialog
              this.openPurchaserDialog();
              return;
            }
            if (workOrder?.[attr] !== event.target.value && event.target.validity.valid) {
              if (attr === 'location') {
                const value = locations.find((location) => location.id === event.target.value);
                this.setState({
                  workOrder: { ...workOrder, [attr]: value },
                  inputChanged: true,
                });
              }

              if (attr === 'purchaser') {
                const value = purchasers.find((location) => location.id === event.target.value);
                this.setState({
                  workOrder: { ...workOrder, [attr]: value },
                  inputChanged: true,
                });
                // workOrder.changeAttribute(attr, value);
              }
            }
            event.target.blur();
          },
          value: (this.state != null && workOrder?.[attr] != null) ? workOrder[attr].id : '',
        }}
      >
        {attr === 'purchaser' && sortedPurchasers?.map((purchaser) => (
          <option
            key={purchaser.id}
            value={purchaser.id}
          >
            {purchaser.name}
          </option>
        ))}
        {attr === 'location' && sortedLocations?.map((location) => (
          <option
            key={location.id}
            value={location.id}
          >
            {location.name}
          </option>
        ))}
        {addNew
          && (
          <option
            key={`${attr}-add-new`}
            value={`${attr}-add-new`}
          >
            + Lisää uusi
          </option>
          )}
      </Select>
    );
  }
  */

  inputDropDown(attr, label, required, styles) {
    const { workOrder, errors } = this.state;
    const { employerWorkOrderStore: { purchasers, locations } } = this.props;

    // Sort the purchasers and locations alphabetically
    const sortedPurchasers = purchasers?.slice().sort((a, b) => a.name.localeCompare(b.name, 'fi'));
    const sortedLocations = locations?.slice().sort((a, b) => a.name.localeCompare(b.name, 'fi'));

    const options = attr === 'purchaser' ? sortedPurchasers : sortedLocations;

    return (
      <ThemeProvider theme={theme}>
        <Autocomplete
          freeSolo
          options={options || []}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
          value={workOrder?.[attr] || null}
          inputValue={this.state[`inputValue_${attr}`] || ''}
          onInputChange={(event, newInputValue) => {
            this.setState({ [`inputValue_${attr}`]: newInputValue });
          }}
          onChange={(event, newValue) => {
            delete errors[attr];

            if (typeof newValue === 'string') {
              if (attr === 'location') {
                this.openLocationDialog(newValue);
              } else if (attr === 'purchaser') {
                this.openPurchaserDialog(newValue);
              }
              this.setState({ [`inputValue_${attr}`]: '' }); // Clear the input after opening dialog
            } else if (newValue && newValue.inputValue) {
              if (attr === 'location') {
                this.openLocationDialog(newValue.inputValue);
              } else if (attr === 'purchaser') {
                this.openPurchaserDialog(newValue.inputValue);
              }
              this.setState({ [`inputValue_${attr}`]: '' }); // Clear the input after opening dialog
            } else if (newValue) {
              this.setState({
                workOrder: { ...workOrder, [attr]: newValue },
                [`inputValue_${attr}`]: newValue.name,
                inputChanged: true,
              });
            }
          }}
          filterOptions={(options, params) => {
            const filtered = options.filter((option) =>
              option.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );

            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: `Lisää "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          renderInput={(params) => (
            <TextField
              variant="filled"
              {...params}
              label={label}
              required={required}
              error={!!errors[attr]}
              helperText={errors[attr] && errors[attr]}
              style={styles}
            />
          )}
        />
      </ThemeProvider>
    );
  }



  addDayPicker(attr, label, required, styles, extraOverlayClassName = null) {
    const { workOrder, errors } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <DayPickerInput
          component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                required,
                variant: 'filled',
                // We overwrite the prop backgroundColor here in order to make the helperText background not white
                style: { ...styles, backgroundColor: 'inherit' },
              }}
              // This "InputProps" key is required for TextField, but wrapping the current DayPickerInput inputProps causes issues (different styling, error messages stop working, etc.)
              InputProps={{
                readOnly: true,
              }}
            />
          )
        }
        // These may not be correctly applied to the TextField, which requires an "InputProps" prop
          inputProps={{
            ref: null,
            className: '',
            style: { ...styles },
            name: attr,
            invalid: errors[attr],
            helperText: errors[attr] && errors[attr],
            // onBlur: (event) => required && this.handleBlur(attr, event),
            readOnly: true,
          }}
          dayPickerProps={{
            locale: 'fi',
            localeUtils: MomentLocaleUtils,
            className: 'employer-daypicker',
            showWeekNumbers: true,
          }}
          formatDate={formatDate}
          parseDate={parseDate}
          keepFocus={false}
          format="dd DD.MM.YYYY"
          onDayChange={(value) => {
            delete errors[attr];
            this.handleChangeForDate(attr, value);
          }}
        // This is is almost certainly overcomplicating things but it works
          value={workOrder?.[attr] ? moment(workOrder?.[attr], 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
          classNames={{
            container: '',
            overlayWrapper: 'DayPickerInput-OverlayWrapper',
            // overlay: 'DayPickerInput-Overlay',
            overlay: extraOverlayClassName ? `DayPickerInput-Overlay ${extraOverlayClassName}` : 'DayPickerInput-Overlay',
          }}
          overlayComponent={customDateRangePickerOverlay}
        />
      </ThemeProvider>
    );
  }

  addDeadline(attr, label, required, styles) {
    const { inviteDeadline, errors } = this.state;

    return (
      <DayPickerInput
        component={
          (props) => (
            <TextField
              {...props}
              {...{
                label,
                required,
                style: { ...styles },
              }}
            />
          )
        }
        inputProps={{
          ref: null,
          className: '',
          style: { ...styles },
          name: attr,
          invalid: errors[attr],
          helpText: errors[attr] && errors[attr],
          readOnly: true,
        }}
        dayPickerProps={{
          locale: 'fi',
          localeUtils: MomentLocaleUtils,
          className: 'employer-daypicker',
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        keepFocus={false}
        format="dd DD.MM.YYYY"
        onDayChange={(value) => {
          delete errors[attr];

          this.setState({
            inviteDeadline: moment(value).format('YYYY-MM-DD hh:mm:ss'),
            inputChanged: true,
          }, () => {
            this.handleChangeDeadline();
          });
        }}
        // This is is almost certainly overcomplicating things but it works
        value={inviteDeadline ? moment(inviteDeadline, 'YYYY-MM-DD', 'fi').toDate() : 'Syötä päivämäärä'}
        classNames={{
          container: '',
          overlayWrapper: 'DayPickerInput-OverlayWrapper',
          overlay: 'DayPickerInput-Overlay DayPickerInput-Overlay-Top',
        }}
      />
    );
  }

  updateAddress(newAddress) {
    const { workOrder, address } = this.state;
    // A terrible solution to the fact that city & zipCode autocomplete don't update all 3 fields
    if (newAddress.street) {
      this.setState({
        workOrder: {
          ...workOrder,
          accomodationName: newAddress.name,
          accomodationCity: newAddress.city,
          accomodationStreet: newAddress.street,
          accomodationZipCode: newAddress.zipCode,
        },
        address: {
          ...address,
          ...newAddress,
        },
        inputChanged: true,
        // For validation
        accomodationName: newAddress.name,
      });
      // A hack for validation since the required accomodationName is in different component
      // this.handleBlur('accomodationName', {target: {name: 'accomodationName', value: newAddress.name}});
    } else if (newAddress.city) {
      this.setState({
        workOrder: {
          ...workOrder,
          accomodationCity: newAddress.city,
        },
        address: {
          ...address,
          city: newAddress.city,
        },
        inputChanged: true,
      });
    } else if (newAddress.zipCode) {
      this.setState({
        workOrder: {
          ...workOrder,
          accomodationZipCode: newAddress.zipCode,
        },
        address: {
          ...address,
          zipCode: newAddress.zipCode,
        },
        inputChanged: true,
      });
    } else if (newAddress.name || newAddress.name === '') {
      this.setState({
        workOrder: {
          ...workOrder,
          accomodationName: newAddress.name,
        },
        address: {
          ...address,
          name: newAddress.name,
        },
        inputChanged: true,
        // For validation
        accomodationName: newAddress.name,
      });
      // A hack for validation since the required accomodationName is in different component
      // this.handleBlur('accomodationName', {target: {name: 'accomodationName', value: newAddress.name}});
    }
  }

  // This whole discrepancy checking is a mess, needs analyzing and cleaning
  // Only check when needed (dates have changed), async vs. sync?
  checkWorkPeriodDateDiscrepancy() {
    const { workOrder } = this.state;

    let workPeriodDateDiscrepancy = false;
    if (workOrder && workOrder.workPeriods.length === 1) {
      // Check if the single existing period matches the work order dates or not
      const dateFormat = 'YYYY-MM-DD';
      const workPeriod = workOrder.workPeriods[0];
      const workPeriodFrom = workPeriod.from?.format(dateFormat);
      const workPeriodTo = workPeriod.to?.format(dateFormat);
      const workOrderFrom = workOrder.from?.format(dateFormat);
      const workOrderTo = workOrder.to?.format(dateFormat);

      if (workPeriodFrom !== workOrderFrom || workPeriodTo !== workOrderTo) {
        workPeriodDateDiscrepancy = true;
      }
    } else if (workOrder && workOrder.workPeriods.length > 1) {
      // More than one work period, we can assume that they do not match the work order dates
      workPeriodDateDiscrepancy = true;
    }

    this.setState({ workPeriodDateDiscrepancy });
  }

  // This whole discrepancy checking is a mess, needs analyzing and cleaning
  // Only check when needed (dates have changed), async vs. sync?
  checkWorkPeriodDateDiscrepancyRender() {
    const { workOrder } = this.state;

    let workPeriodDateDiscrepancy = false;
    if (workOrder && workOrder.workPeriods.length === 1) {
      // Check if the single existing period matches the work order dates or not
      const dateFormat = 'YYYY-MM-DD';
      const workPeriod = workOrder.workPeriods[0];
      const workPeriodFrom = workPeriod.from?.format(dateFormat);
      const workPeriodTo = workPeriod.to?.format(dateFormat);
      const workOrderFrom = workOrder.from?.format(dateFormat);
      const workOrderTo = workOrder.to?.format(dateFormat);

      if (workPeriodFrom !== workOrderFrom || workPeriodTo !== workOrderTo) {
        workPeriodDateDiscrepancy = true;
      }
    } else if (workOrder && workOrder.workPeriods.length > 1) {
      // More than one work period, we can assume that they do not match the work order dates
      workPeriodDateDiscrepancy = true;
    }

    return workPeriodDateDiscrepancy;
  }

  handleChangeForDate(attr, value) {
    const { uiStore: { currentUser: { accountInfo } } } = this.props;
    const { workOrder, workPeriodDateDiscrepancy, editingWorkPeriods } = this.state;

    const formatString = 'DD.MM.YYYY';

    const valueAsDate = moment(value).format('YYYY-MM-DD');
    const date = dateWithoutTime(valueAsDate);
    // const oldAttr = workOrder[attr];

    // Set default workPeriod from date
    if (workOrder.workPeriods && workOrder.workPeriods.length === 1 && (!accountInfo.workPeriodsEnabled || !editingWorkPeriods)) {
      const updatedWorkOrder = { ...workOrder };
      if (attr === 'from') { // && (!workOrder.workPeriods[0].from || !workOrder.workPeriods[0].id)) {
        updatedWorkOrder.workPeriods[0].from = date;
      }

      if (attr === 'to') { // && (!workOrder.workPeriods[0].to || !workOrder.workPeriods[0].id)) {
        updatedWorkOrder.workPeriods[0].to = date;
      }

      this.setState({
        workOrder: updatedWorkOrder,
      });
    } else {
      this.checkWorkPeriodDateDiscrepancy();
    }

    // const periodsWithPendingDateChanges = workOrder.workPeriods.filter((period) => period.id && period[attr].format('DD.MM.YY') == oldAttr.format('DD.MM.YY'));
    // if (periodsWithPendingDateChanges.length > 0) {
    //   console.log('SHOULD ASK ABOUT UPDATING: ', periodsWithPendingDateChanges);
    //   // Call a separate method that gives the new date and the attribute to be changed (from/to), and the target (WorkOrder/WorkPeriod)
    //   // The new date is inserted into the target's attributes (if workPeriod = needs to match requirements)
    //   // Same method is called in WorkPeriodForm
    //   // "Prepare changes" and list them in the confirmation dialog, e.g.
    //   //  - Workdays to be added
    //   //  - Workdays to be deleted
    //   //  - WorkPeriods' dates to be changed (to what?)
    //   //  - WorkOrder dates to be changed (to what?)

    // } else {
    //   this.setState({
    //     workOrder: { ...workOrder, [attr]: date },
    //     inputChanged: true,
    //   });
    // }

    const oldWoFrom = { ...workOrder }.from;
    const oldWoTo = { ...workOrder }.to;
    let oldCurrentDate = moment(oldWoFrom);
    const oldLastDate = moment(oldWoTo);
    const oldDatesInRange = [oldCurrentDate.format(formatString)];
    while (oldCurrentDate < oldLastDate) {
      oldCurrentDate = oldCurrentDate.add(1, 'days');
      oldDatesInRange.push(oldCurrentDate.format(formatString));
    }

    if (accountInfo.workPeriodsEnabled && (workOrder.workPeriods.length > 1 || (workOrder.workPeriods.length === 1 && (editingWorkPeriods || workPeriodDateDiscrepancy)))) {
      this.setState({
        workOrder: { ...workOrder, [attr]: date },
        inputChanged: true,
        showDateChangeNotificationDialog: true,
        dialogText: 'Keikan kestoa on muutettu. Muokkaa tarvittaessa myös jaksojen kestoja.',
        confirmButtonText: 'Muokkaa työjaksoa',
        targetTab: 1,
      });
    } else {
      const woFrom = attr === 'from' ? date : workOrder.from;
      const woTo = attr === 'to' ? date : workOrder.to;

      if (woFrom && woTo) {
        // Account doesn't have work periods enabled
        // Assuming only one work period
        // We want to update the calendar entries automatically according to the new work order dates
        let currentDate = moment(woFrom);
        const lastDate = moment(woTo);
        const datesInRange = [currentDate.format(formatString)];

        while (currentDate < lastDate) {
          currentDate = currentDate.add(1, 'days');
          datesInRange.push(currentDate.format(formatString));
        }

        workOrder.workPeriods[0].invitations.forEach((invitation) => {
          // const sortedCalendarEntries = invitation.calendarEntries.slice().sort((a, b) => a.from.format(formatString) < b.from.format(formatString));
          // const firstEntry = sortedCalendarEntries[0];
          // const lastEntry = sortedCalendarEntries[sortedCalendarEntries.length - 1];
          // const firstEntryFrom = firstEntry.from.format(formatString);
          // const lastEntryFrom = lastEntry.from.format(formatString);
          // const woFromFormatted = moment(oldWoFrom).format(formatString);
          // const woToFormatted = moment(oldWoTo).format(formatString);
          // Calendar entries are oldest first (actually sorted or just happen to be this way?)
          // const startSame = firstEntryFrom === woFromFormatted;
          // const endSame = lastEntryFrom === woToFormatted;

          // Only touch invitations + calendarEntries that match the previous work order dates
          // Comparing just date lengths like this is imperfect but an invitation going outside the work order dates is its own case, the above commented code is buggy
          if (invitation.calendarEntries.length === oldDatesInRange.length) {
            // Check each calendar entry in each invitation
            invitation.calendarEntries.map((entry) => {
              if (!datesInRange.includes(entry.from.format(formatString))) {
                // Entry from not in the work order date array, cancelling
                if (invitation.canParticipate) {
                  entry.status = 'cancelled';
                } else {
                  entry._destroy = true;
                }
              }
              return entry;
            });

            // Check each work order date
            datesInRange.forEach((woDate) => {
              if (!invitation.calendarEntries.find((entry) => entry.from.format(formatString) === woDate)) {
                // Date is in work order dates but not in invitation's calendar entries, add as a new entry
                invitation.calendarEntries.push(
                  new CalendarEntry({
                    userId: invitation.userId,
                    from: moment(woDate, formatString),
                    to: moment(woDate, formatString),
                  }),
                );
              }
            });
          }
        });
      }
    }

    this.setState({
      workOrder: { ...workOrder, [attr]: date },
      inputChanged: true,
    });
  }

  workOrderDateUpdateReminder = (workPeriod) => {
    const { workOrder } = this.state;

    const dateFormat = 'YYYY-MM-DD';
    const workPeriodFrom = workPeriod.from.format(dateFormat);
    const workPeriodTo = workPeriod.to.format(dateFormat);
    const workOrderFrom = workOrder.from.format(dateFormat);
    const workOrderTo = workOrder.to.format(dateFormat);

    if (workPeriodFrom < workOrderFrom || workPeriodTo > workOrderTo) {
      this.setState({
        showDateChangeNotificationDialog: true,
        dialogText: workPeriod.id ? 'Jakson uusi kesto menee keikan keston ulkopuolelle. Muokkaa myös keikan keston päivämääriä.' : 'Uuden jakson kesto menee keikan keston ulkopuolelle. Muokkaa myös keikan keston päivämääriä.',
        confirmButtonText: 'Muokkaa keikan kestoa',
        targetTab: 0,
      });
    }
  }

  // This is messy
  handleChangeDeadline() {
    const { inviteDeadline, inviteDeadlineTime } = this.state;

    // We want to combine the input date and input time into a single deadline that can be inserted in work order invitations on submit
    const inviteDeadlineDate = inviteDeadline.split(' ')[0];
    const hours = inviteDeadlineTime.split(':')?.[0];
    const minutes = inviteDeadlineTime.split(':')?.[1];
    const inviteDeadlineDateTime = `${inviteDeadlineDate} ${hours || '00'}:${minutes || '00'}:00`;

    this.setState({
      inviteDeadline: inviteDeadlineDateTime,
    });
  }

  getWorkPeriods(foundWorkOrder = null) {
    const {
      employerWorkOrderStore: { getWorkPeriods },
    } = this.props;
    const { workOrder } = this.state;

    if (foundWorkOrder?.id || workOrder?.id) {
      getWorkPeriods(foundWorkOrder?.id || workOrder.id).then((result) => {
        // We need to check discrepancy if there's one work period (editingWorkPeriods is on if not default case)
        let editingWorkPeriods = false;
        const dateFormat = 'YYYY-MM-DD';
        const workPeriodFrom = result?.[0].from.format(dateFormat);
        const workPeriodTo = result?.[0].to.format(dateFormat);
        const workOrderFrom = foundWorkOrder ? foundWorkOrder.from.format(dateFormat) : workOrder.from.format(dateFormat);
        const workOrderTo = foundWorkOrder ? foundWorkOrder.to.format(dateFormat) : workOrder.to.format(dateFormat);

        if (result.length > 1) {
          editingWorkPeriods = true;
        } else if (result.length === 1 && (workPeriodFrom !== workOrderFrom || workPeriodTo !== workOrderTo)) {
          editingWorkPeriods = true;
        }

        this.setState((prevState) => ({
          workOrder: { ...prevState.workOrder, workPeriods: result },
          editingWorkPeriods,
        }));
      });
    }
  }

  getEmployers() {
    const { employerWorkOrderStore } = this.props;
    const employers = employerWorkOrderStore.getEmployers();

    this.setState({ employers });
  }

  addCheckBox(attr, label) {
    const { workOrder } = this.state;

    return (
      <Checkbox {...{
        checked: workOrder?.[attr] != null ? workOrder[attr] : false,
        label,
        className: 'employer-checkbox',
        onChange: (event) => {
          const value = event.target.checked;
          if (workOrder?.[attr] !== value && event.target.validity.valid) {
            // workOrder.changeAttribute(attr, value);
            this.setState({
              workOrder: { ...workOrder, [attr]: value },
            });
          }
        },
      }}
      />
    );
  }

  handlePrivilegeCheckbox(checked, employerId, privilegeType) {
    const { privilegesToBeCreated } = this.state;
    const privilegesToBeCreatedTemp = [...privilegesToBeCreated];

    if (checked) {
      // Checkbox value is true, push a new privilege into the temporary array (which is merged into the actual object later, for saving)
      privilegesToBeCreatedTemp.push({ user_id: employerId, privilege_type: privilegeType });
      this.setState({
        privilegesToBeCreated: privilegesToBeCreatedTemp,
        inputChanged: true,
      });
    } else {
      // Checkbox value is false, filter the unsaved privilege related to the checkbox out of the temporary array
      const remainingTempPrivileges = privilegesToBeCreatedTemp.filter((privilege) => privilege.user_id !== employerId || privilege.privilege_type !== privilegeType);

      this.setState({
        privilegesToBeCreated: remainingTempPrivileges,
      });
    }
  }

  renderBasicInfo() {
    const {
      inputChanged, address, autocompleteAccomodations, employers,
    } = this.state;
    const { t, uiStore: { currentUser: { accountInfo } } } = this.props;

    const styles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
    };


    return (
      <GridCell
        span={12}
      >
        <GridInner
          style={{ rowGap: '0px' }}
        >
          <GridCell
            span={12}
            style={{
              margin: '0px -16px',
            }}
          >
            <Typography
              style={{
                paddingLeft: '25px',
              }}
              use="headline6"
              tag="h2"
              className="headline"
            >
              Perustiedot
            </Typography>
          </GridCell>

          <GridCell
            className="employer-work-order"
            span={12}
          >
            {this.addTextField('name', t('work_order.labels.name'), true, styles)}
          </GridCell>
          <GridCell
            className="employer-work-order"
            span={12}
          >
            {this.inputDropDown('purchaser', t('work_order.labels.purchaser'), true, styles, true)}
          </GridCell>
          <GridCell
            className="employer-work-order"
            span={12}
          >
            {this.inputDropDown('location', t('work_order.labels.location'), true, styles, true)}
          </GridCell>
          <GridCell
            className="employer-work-order"
            span={12}
          >
            {this.addTextField('description', t('work_order.labels.description'), false, styles, true)}
          </GridCell>
          <GridCell
            span={12}
          >
            <GridInner>
              <GridCell
                className="employer-work-order"
                desktop={6}
                tablet={4}
                phone={2}
              >
                {this.addDayPicker('from', t('work_order.labels.from'), true, styles)}
              </GridCell>
              <GridCell
                className="employer-work-order"
                desktop={6}
                tablet={4}
                phone={2}
              >
                {this.addDayPicker('to', t('work_order.labels.to'), true, styles, 'DayPickerInput-Overlay-Right')}
              </GridCell>
            </GridInner>
          </GridCell>

          {/* ACCOMODATION START */}
          <GridCell
            span={12}
            style={{
              margin: '0px -16px',
            }}
          >
            <Typography
              style={{
                paddingLeft: '25px',
              }}
              use="headline6"
              tag="h2"
              className="headline"
            >
              Majoitustiedot
            </Typography>
          </GridCell>

          <GridCell span={12}>
            <AutocompletableAddress address={address} updateAddress={this.updateAddress} autocompleteAddresses={autocompleteAccomodations} nameNotRequired />
          </GridCell>
          {/* ACCOMODATION END */}

          <GridCell
            span={12}
            style={{
              margin: '0px -16px',
            }}
          >
            <Typography
              style={{
                paddingLeft: '25px',
              }}
              use="headline6"
              tag="h2"
              className="headline"
            >
              Lisätietoja
            </Typography>
          </GridCell>
          <GridCell
            span={12}
          >
            {this.addTextField('accomodationDescription', t('work_order.labels.accomodationDescription'), false, styles, true)}
          </GridCell>

          <GridCell
            span={12}
            style={{
              margin: '0px -16px',
            }}
          >
            <CollapsibleList
              style={{ margin: '10px 0' }}
              handle={(
                <SimpleListItem
                  className="mdc-typography--headline6 headline fake-headline-list-item"
                  text="Työnjohtajat"
                  metaIcon="chevron_right"
                />
              )}
            >
              <div style={{ padding: '15px 40px' }}>
                <p style={{ fontSize: '18px' }}>Vastaava työnjohtaja</p>
                <Elevation
                  z={5}
                  style={{ borderRadius: '0px 0px 3px 3px' }}
                >
                  <List
                    style={{
                      marginTop: '10px',
                      color: 'black',
                      padding: '0px',
                    }}
                  >
                    <ListGroup>
                      {employers ? employers.case({
                        pending: () => <GridCell style={{ textAlign: 'center' }}><p>Ladataan</p></GridCell>,
                        rejected: () => <GridCell style={{ textAlign: 'center' }}><p>Lataaminen epäonnistui!</p></GridCell>,
                        fulfilled: () => (
                          this.renderPrivilegeItems('responsible_employership')
                        ),
                      }) : ''}
                    </ListGroup>
                  </List>
                </Elevation>

                <p style={{ fontSize: '18px', marginTop: '30px' }}>Tuntikirjausten hyväksyjä</p>
                <Elevation
                  z={5}
                  style={{ borderRadius: '0px 0px 3px 3px' }}
                >
                  <List
                    style={{
                      marginTop: '10px',
                      color: 'black',
                      padding: '0px',
                    }}
                  >
                    <ListGroup>
                      {employers ? employers.case({
                        pending: () => <GridCell style={{ textAlign: 'center' }}><p>Ladataan</p></GridCell>,
                        rejected: () => <GridCell style={{ textAlign: 'center' }}><p>Lataaminen epäonnistui!</p></GridCell>,
                        fulfilled: () => (
                          this.renderPrivilegeItems('work_hour_acceptance')
                        ),
                      }) : ''}
                    </ListGroup>
                  </List>
                </Elevation>
              </div>
            </CollapsibleList>
          </GridCell>


          <GridCell
            span={12}
            style={{
              margin: '0px -16px',
            }}
          >
            <Typography
              style={{
                paddingLeft: '25px',
              }}
              use="headline6"
              tag="h2"
              className="headline"
            >
              Hallinnolliset tiedot
            </Typography>
          </GridCell>

          {accountInfo.billingIdsEnabled && (
            <GridCell
              span={12}
            >
              {this.addTextField('billingId', t('work_order.labels.billingId'), false, styles, null, null, false)}
            </GridCell>
          )}
          <GridCell
            span={12}
          >
            {this.addTextField('extid', t('work_order.labels.extid'), false, styles)}
          </GridCell>
          <GridCell
            span={12}
          >
            {this.addTextField('adminInfo', t('work_order.labels.adminInfo'), false, styles, true)}
          </GridCell>
          {inputChanged && (
            <GridCell
              span={12}
            >
              {this.addCheckBox('sendAsSms', t('work_order.labels.sendAsSms'))}
            </GridCell>
          )}
        </GridInner>
      </GridCell>
    );
  }

  renderPrivilegeItems(privilegeType) {
    const { employers } = this.state;

    return (
      <Elevation
        z={5}
        style={{ borderRadius: '0px 0px 3px 3px' }}
      >
        <List
          style={{
            marginTop: '10px',
            color: 'black',
            padding: '0px',
          }}
        >
          <ListGroup>
            {employers.value.map((employer, index) => (
              <React.Fragment
                key={`${privilegeType}-${employer.id}`}
              >
                {this.renderPrivilegeItem(privilegeType, employer, index)}
                {index !== employers.length - 1 && <ListDivider />}
              </React.Fragment>
            ))}
            {employers.length === 0 && (
              <div style={{
                display: 'flex',
                alignItems: 'center',
                height: '30px',
                marginLeft: '16px',
              }}
              >
                Ei työnjohtajia
              </div>
            )}
          </ListGroup>
        </List>
      </Elevation>
    );
  }

  renderPrivilegeItem(privilegeType, employer, index) {
    const { workOrder: { userPrivileges }, privilegesToBeCreated } = this.state;
    // Check if there's a saved privilege related to this item (so that we can delete it if we wish, etc.)
    const relevantPrivilege = userPrivileges?.find((privilege) => privilege.user_id === employer.id && privilege.privilege_type === privilegeType);
    // Check if there's a privilege that hasn't been saved yet related to this item (used for checkbox values)
    const newPrivilege = privilegesToBeCreated.find((privilege) => privilege.user_id === employer.id && privilege.privilege_type === privilegeType);
    let checked = !!newPrivilege;

    return (
      <ListItem
        key={index}
        className="availability-list-item"
        style={{
          paddingLeft: relevantPrivilege ? '50px' : '10px',
          height: 'initial',
          minHeight: '48px',
          overflow: 'visible',
        }}
        onClick={() => {
          // Prevent adding a privilege twice
          if (relevantPrivilege) {
            return;
          }
          checked = !checked;
          this.handlePrivilegeCheckbox(checked, employer.id, privilegeType);
        }}
      >
        {/* Render the checkbox if this employer's privilege (by privilegeType) hasn't been saved yet */}
        {!relevantPrivilege && (
          <Checkbox
            checked={checked}
            readOnly
          />
        )}
        {`${employer.last_name}, ${employer.first_name}`}
        {/* Render the delete button if this employer's privilege (by privilegeType) has already been saved */}
        <ListItemMeta>
          {relevantPrivilege && (
            <IconButton
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                this.confirmDeletePrivilege(relevantPrivilege, employer);
              }}
              style={{ color: '#645F5F' }}
              icon="delete_outlined"
            />
          )}
        </ListItemMeta>
      </ListItem>
    );
  }

  hasNewInvites() {
    const { workOrder: { workPeriods } } = this.state;

    const foundNewInvite = workPeriods?.find((period) => period.invitations.find((invitation) => !invitation.id));

    return foundNewInvite;
  }

  renderBottomButtons() {
    const { uiStore, uiStore: { currentView }, t } = this.props;
    const { inputChanged, inviteDeadlineTime, isSaving } = this.state;
    const deadlineStyles = { backgroundColor: '#FFFFFF', width: '100%', margin: '10px 0', textTransform: 'capitalize' };

    if ((currentView.mode === 'new' && inputChanged) || (currentView.mode === 'edit' && inputChanged)) {
      return (
        <GridCell
          span={12}
          className="employer-bottom-buttons"
        >
          <GridInner style={{ gridGap: '10px' }}>
            {this.hasNewInvites() && (
              <>
                <GridCell
                  // className='employer-work-order'
                  span={12}
                  style={{ fontSize: '16px' }}
                >
                  Uusien kutsujen voimassaolo
                </GridCell>

                <GridCell
                  className="employer-work-order"
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  {this.addDeadline('inviteDeadline', t('work_order.labels.inviteDeadline'), true, deadlineStyles)}
                </GridCell>

                <GridCell
                  className="employer-work-order"
                  desktop={6}
                  tablet={4}
                  phone={2}
                >
                  <CustomTimeField
                    input={<TextField label="Klo." />}
                    styles={{ ...deadlineStyles, height: '56px' }}
                    value={inviteDeadlineTime}
                    attr={null}
                    doOnChange={(_, value) => {
                      this.setState({
                        inviteDeadlineTime: value,
                      }, () => {
                        this.handleChangeDeadline();
                      });
                    }}
                    doOnBlur={(_, value) => {
                      this.setState({
                        inviteDeadlineTime: value,
                      }, () => {
                        this.handleChangeDeadline();
                      });
                    }}
                    handleEnter={handleEnter}
                  />
                </GridCell>
              </>
            )}

            <GridCell
              span={12}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  className="employer-accept-button"
                  type="submit"
                  style={{
                    borderRadius: '0px',
                    minWidth: '100px',
                    width: this.hasNewInvites() && 'fit-content',
                    height: '38.5px',
                  }}
                  disabled={isSaving}
                >
                  {isSaving ? (
                    <LoadingSpinner color="black" size="mini" />
                  ) : (
                    this.hasNewInvites() ? 'Tallenna ja kutsu' : 'Tallenna'
                  )}
                </Button>

                <Button
                  type="button"
                  className="employer-reject-button"
                  onClick={() => window.history.back()}
                >
                  Peruuta
                </Button>
              </div>
            </GridCell>
          </GridInner>
        </GridCell>
      );
    }
    return (
      <GridCell
        span={12}
        className="employer-bottom-buttons"
        style={{
          textAlign: 'end',
        }}
      >
        <Button
          className="employer-reject-button"
          type="button"
          style={{
            borderRadius: '0px',
            minWidth: '100px',
          }}
          onClick={() => uiStore.showEmployerWorkOrders()}
        >
            Sulje
        </Button>
      </GridCell>
    );
  }

  handleBlur(attr, evt) {
    const { errors } = this.state;
    const { t } = this.props;

    const { name, value } = evt.target;
    const error = validate[name](value);

    if (error) {
      this.setState({
        errors: {
          ...errors,
          [name]: t(error, { label: t(`work_order.labels.${attr}`) }),
        },
      });
    } else if (attr === 'accomodationName') {
      // Typically doing this in the input onChanged but now doing it here instead because the accomodationName is handled by AutoCompletableAddress
      delete errors[attr];
      this.setState({
        errors: { ...errors },
      });
    }
  }

  renderSaveMessage() {
    const { openSnackbar, saveStatusMessage } = this.state;

    return (
      <Snackbar
        open={openSnackbar}
        style={{
          textAlign: 'center',
          top: '0',
        }}
        onClose={() => {
          this.setState({
            openSnackbar: false,
            saveStatusMessage: '',
          });
        }}
        message={saveStatusMessage}
        timeout={2000}
      />
    );
  }

  confirmDeletePrivilege(privilege, employer) {
    let message;
    if (privilege.privilege_type === 'work_hour_acceptance') {
      message = `Haluatko varmasti poistaa henkilön ${employer.first_name} ${employer.last_name} tuntikirjauksien hyväksyjistä?`;
    } else if (privilege.privilege_type === 'responsible_employership') {
      message = `Haluatko varmasti poistaa henkilön ${employer.first_name} ${employer.last_name} vastaavista työnjohtajista?`;
    }
    if (privilege != null && employer != null) {
      this.setState({
        showDeletePrivilegeDialog: true,
        privilegeToDelete: privilege,
        deleteMessage: message,
      });
    }
  }

  renderDeletePrivilegeDialog() {
    const { showDeletePrivilegeDialog, deleteMessage, privilegeToDelete } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDeletePrivilegeDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            this.handleDeletePrivilege(privilegeToDelete);
          }
          this.setState({
            showDeletePrivilegeDialog: false,
            privilegeToDelete: null,
            deleteMessage: '',
          });
        }}
      >
        {this.renderDeleteDialogContent(deleteMessage)}
      </Dialog>
    );
  }

  renderDateChangeNotificationDialog() {
    const {
      showDateChangeNotificationDialog, dialogText, confirmButtonText, targetTab,
    } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showDateChangeNotificationDialog}
        onClose={(evt) => {
          if (evt.detail.action === 'accept') {
            // this.handleDeletePrivilege(privilegeToDelete)
            this.setState({
              activeTab: targetTab,
            });
          }
          this.setState({
            showDateChangeNotificationDialog: false,
          });
        }}
      >
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{dialogText}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
              width: 'fit-content',
            }}
            action="accept"
          >
            {confirmButtonText}
          </DialogButton>

          <DialogButton
            className="employer-reject-button"
            action="close"
          >
            Myöhemmin
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  saveLocation = (location) => {
    const { locationStore, employerWorkOrderStore: { addNewLocation } } = this.props;
    const { errors } = this.state;

    if (location.id == null || location.id === '') {
      const createPromise = fromPromise(new Promise((resolve, reject) => locationStore.createLocation(location, resolve, reject)));
      when(
        () => createPromise.state !== 'pending',
        () => {
          createPromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: (newLocation) => {
              // Pushing the newly created location into the store's array so that it is rendered as a <Select> <option>
              addNewLocation(newLocation);

              // Reset the error caused by closing the select without choosing a value
              const updatedErrors = { ...errors };
              delete updatedErrors.location;

              this.setState((prevState) => ({
                workOrder: { ...prevState.workOrder, location: newLocation },
                errors: updatedErrors,
                inputChanged: true,
                tempLocation: {},
              }));
            },
          });
        },
      );
    }
  }

  savePurchaser = (purchaser) => {
    const { purchaserStore, employerWorkOrderStore: { addNewPurchaser } } = this.props;
    const { errors } = this.state;

    if (purchaser.id == null || purchaser.id === '') {
      const createPromise = fromPromise(new Promise((resolve, reject) => purchaserStore.createPurchaser(purchaser, resolve, reject)));
      when(
        () => createPromise.state !== 'pending',
        () => {
          createPromise.case({
            pending: () => { },
            rejected: (err) => {
              console.log('Tallennus epäonnistui', err);
            },
            fulfilled: (newPurchaser) => {
              // Pushing the newly created location into the store's array so that it is rendered as a <Select> <option>
              addNewPurchaser(newPurchaser);

              // Reset the error caused by closing the select without choosing a value
              const updatedErrors = { ...errors };
              delete updatedErrors.purchaser;

              this.setState((prevState) => ({
                workOrder: { ...prevState.workOrder, purchaser: newPurchaser },
                errors: updatedErrors,
                inputChanged: true,
                tempPurchaser: {},
              }));
            },
          });
        },
      );
    }
  }

  saveWorkPeriod = (workPeriod) => {
    const { uiStore: { currentUser: { accountInfo } } } = this.props;
    const { workOrder: { workPeriods } } = this.state;
    const updatedWorkPeriods = [...workPeriods];

    if (!workPeriod.id && !workPeriod.tempId) {
      // Generate a temporary ID so that we can find the unsaved draft in the UI and update it later
      workPeriod.tempId = uuidv4();
    }

    const foundWorkPeriodIndex = updatedWorkPeriods.findIndex(
      (oldWorkPeriod) => (workPeriod.id && workPeriod.id === oldWorkPeriod.id) || (workPeriod.tempId && workPeriod.tempId === oldWorkPeriod.tempId),
    );

    const updatedWorkPeriod = { ...workPeriod, from: moment(workPeriod.from), to: moment(workPeriod.to) };
    let oldWorkPeriod;
    if (foundWorkPeriodIndex !== -1) {
      oldWorkPeriod = { ...updatedWorkPeriods[foundWorkPeriodIndex] };
      updatedWorkPeriods[foundWorkPeriodIndex] = updatedWorkPeriod;
    } else {
      updatedWorkPeriods.push(updatedWorkPeriod);
    }

    const dateFormat = 'YYYY-MM-DD';
    const periodDatesChanged = oldWorkPeriod?.from && oldWorkPeriod?.to && updatedWorkPeriod.from && updatedWorkPeriod.to && (oldWorkPeriod?.from?.format(dateFormat) !== updatedWorkPeriod.from.format(dateFormat) || oldWorkPeriod?.to?.format(dateFormat) !== updatedWorkPeriod.to.format(dateFormat));

    this.setState((prevState) => ({
      workOrder: { ...prevState.workOrder, workPeriods: updatedWorkPeriods },
      inputChanged: true,
    }), () => {
      if (periodDatesChanged && accountInfo.workPeriodsEnabled) {
        this.workOrderDateUpdateReminder(updatedWorkPeriod);
      }
    });

    this.handleWorkPeriodDialogClose();
    this.checkWorkPeriodDateDiscrepancy();
  }

  renderLocationDialog() {
    const { showLocationDialog, tempLocation } = this.state;

    return (
      <LocationDialog
        open={showLocationDialog}
        handleClose={this.handleLocationDialogClose}
        // We never use this for editing here so location is always an empty object at first
        location={tempLocation}
        saveLocation={this.saveLocation}
        dialogValue={this.state.dialogValue}
      />
    );
  }

  renderPurchaserDialog() {
    const { showPurchaserDialog, tempPurchaser } = this.state;

    return (
      <PurchaserDialog
        open={showPurchaserDialog}
        handleClose={this.handlePurchaserDialogClose}
        // We never use this for editing here so location is always an empty object at first
        purchaser={tempPurchaser}
        savePurchaser={this.savePurchaser}
        dialogValue={this.state.dialogValue}
      />
    );
  }

  renderSettingsDialog() {
    const { showSettingsDialog, workOrder } = this.state;

    return (
      <WorkOrderSettingsDialog
        open={showSettingsDialog}
        handleClose={this.handleSettingsDialogClose}
        workOrder={workOrder}
        updateAttr={this.updateAttr}
        updateSetting={this.updateSetting}
        cloneWorkOrder={this.cloneWorkOrder}
        deleteWorkOrder={this.deleteWorkOrder}
      />
    );
  }

  renderWorkPeriodDialog() {
    const { showWorkPeriodDialog, workOrder, currentWorkPeriod } = this.state;

    return (
      <WorkPeriodDialog
        open={showWorkPeriodDialog}
        onClose={this.handleWorkPeriodDialogClose}
        onSave={this.saveWorkPeriod}
        updateWorkPeriod={this.saveWorkPeriod}
        workOrder={workOrder}
        workPeriod={currentWorkPeriod}
        customDateRangePickerOverlay={customDateRangePickerOverlay}
      />
    );
  }

  handleLocationDialogClose = () => {
    this.setState({
      showLocationDialog: false,
    });
  }

  handlePurchaserDialogClose = () => {
    this.setState({
      showPurchaserDialog: false,
    });
  }

  handleSettingsDialogClose = () => {
    this.setState({
      showSettingsDialog: false,
    });
  }

  handleWorkPeriodDialogClose = () => {
    this.setState({
      showWorkPeriodDialog: false,
      currentWorkPeriod: new WorkPeriod(),
    });
  }

  renderDeleteDialogContent(deleteMessage) {
    return (
      <>
        <DialogContent
          style={{ color: 'white' }}
        >
          <p>{deleteMessage}</p>
        </DialogContent>
        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <DialogButton
            className="employer-accept-button"
            style={{
              borderRadius: '0px',
            }}
            action="accept"
          >
            Kyllä
          </DialogButton>

          <DialogButton
            className="employer-reject-button"
            action="close"
          >
            Peruuta
          </DialogButton>
        </DialogActions>
      </>
    );
  }

  handleDeletePrivilege = (privilege) => {
    const { employerWorkOrderStore } = this.props;
    const deletePromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.deleteUserPrivilege(privilege, resolve, reject)));

    when(
      () => deletePromise.state !== 'pending',
      () => {
        deletePromise.case({
          pending: () => {
          },
          rejected: () => {
            this.setState({
              openSnackbar: true,
              saveStatusMessage: 'Poisto epäonnistui',
            });
          },
          fulfilled: (updatedWorkOrder) => {
            this.setState((prevState) => ({
              openSnackbar: true,
              saveStatusMessage: 'Poisto onnistui',
              inputChanged: false,
              workOrder: {
                ...updatedWorkOrder,
                sendAsSms: prevState.workOrder.sendAsSms,
                invitations: prevState.workOrder.invitations,
              },
            }));
          },
        });
      },
    );
  }

  // Is this really necessary or can this be done with some simple "prop changed --> re-render" logic?
  updateAttachmentState = (newAttachment) => {
    const { workOrder } = this.state;
    const foundIndex = workOrder.attachments.findIndex((attachment) => attachment.id === newAttachment.id);

    if (foundIndex !== -1) {
      const attachments = [...workOrder.attachments];
      attachments[foundIndex] = newAttachment;

      this.setState({
        workOrder: { ...workOrder, attachments },
      });
    } else {
      const attachments = [...workOrder.attachments];
      attachments.push(newAttachment);

      this.setState({
        workOrder: { ...workOrder, attachments },
      });
    }
  }

  // Is this really necessary or can this be done with some simple "prop changed --> re-render" logic?
  updateWorkTaskState = (newWorkTask) => {
    // Skip this for child work tasks, otherwise they're pushed as parents into the UI in addition to pushed as children
    if (!newWorkTask.parentId) {
      const { workOrder } = this.state;
      const foundIndex = workOrder.workTasks.findIndex((workTask) => workTask.id === newWorkTask.id);
      const updatedWorkTasks = [...workOrder.workTasks];

      if (foundIndex !== -1) {
        updatedWorkTasks[foundIndex] = newWorkTask;
      } else {
        updatedWorkTasks.push(newWorkTask);
      }
      this.setState({
        workOrder: { ...workOrder, workTasks: updatedWorkTasks },
      });
    }
  }

  deleteWorkTaskState = (deletedId) => {
    // Assuming no work task hierarchy (parentId)
    const { workOrder } = this.state;
    const foundIndex = workOrder.workTasks.findIndex((workTask) => workTask.id === deletedId);
    const updatedWorkTasks = [...workOrder.workTasks];

    if (foundIndex !== -1) {
      updatedWorkTasks.splice(foundIndex, 1);
    }

    this.setState({
      workOrder: { ...workOrder, workTasks: updatedWorkTasks },
    });
  }

  deleteWorkTaskFileFromState = (workTaskId, workTaskParentId, signedIds, callback = null) => {
    const { workOrder } = this.state;
    const updatedWorkTasks = [...workOrder.workTasks];

    if (!workTaskParentId) {
      const foundIndex = workOrder.workTasks.findIndex((workTask) => workTask.id === workTaskId);

      if (foundIndex !== -1) {
        updatedWorkTasks[foundIndex].files = updatedWorkTasks[foundIndex].files.filter((file) => !signedIds.includes(file.signedId));
      }
    } else {
      const parentIndex = workOrder.workTasks.findIndex((workTask) => workTask.id === workTaskParentId);
      const childIndex = workOrder.workTasks[parentIndex]?.childTasks.findIndex((childTask) => childTask.id === workTaskId);

      if (parentIndex !== -1 && childIndex !== -1) {
        updatedWorkTasks[parentIndex].childTasks[childIndex].files = updatedWorkTasks[parentIndex].childTasks[childIndex].files.filter((file) => !signedIds.includes(file.signedId));
      }
    }

    this.setState({
      workOrder: { ...workOrder, workTasks: updatedWorkTasks },
    }, () => {
      if (callback) {
        callback();
      }
    });
  }

  deleteAttachmentFromState = (id) => {
    const { workOrder } = this.state;

    const attachments = workOrder.attachments.filter((attachment) => attachment.id !== id);

    this.setState({
      workOrder: { ...workOrder, attachments },
    });
  }

  deleteFileFromState = (id) => {
    const { workOrder } = this.state;

    const attachmentIndex = workOrder.attachments.findIndex((attachment) => attachment.id === id);

    const attachments = [...workOrder.attachments];

    attachments[attachmentIndex] = { ...attachments[attachmentIndex], fileURL: null, filename: null };

    this.setState({
      workOrder: { ...workOrder, attachments },
    });
  }

  deleteWorkOrder = () => {
    const { workOrder } = this.state;
    const { uiStore, employerWorkOrderStore } = this.props;
    const deletePromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.delete(workOrder.id, resolve, reject)));

    when(
      () => deletePromise.state !== 'pending',
      () => {
        deletePromise.case({
          pending: () => {
          },
          rejected: (e) => {
            if (e === 'hours-or-invitations-error') {
              this.setState({
                openSnackbar: true,
                saveStatusMessage: 'Poisto epäonnistui: keikalla on työntekijöitä tai kirjauksia',
              });
            } else {
              this.setState({
                openSnackbar: true,
                saveStatusMessage: 'Poisto epäonnistui',
              });
            }
          },
          fulfilled: () => {
            uiStore.showEmployerWorkOrders(null, `Keikka ${workOrder.name} poistettu`);
          },
        });
      },
    );
  }

  cloneWorkOrder = () => {
    const { originalWorkOrder } = this.state;
    const { employerWorkOrderStore, employerWorkOrderStore: { getAvailabilities } } = this.props;
    const clonePromise = fromPromise(new Promise((resolve, reject) => employerWorkOrderStore.clone(originalWorkOrder.id, resolve, reject)));

    when(
      () => clonePromise.state !== 'pending',
      () => {
        clonePromise.case({
          pending: () => {
          },
          rejected: () => {
            this.setState({
              openSnackbar: true,
              saveStatusMessage: 'Kopiointi epäonnistui',
            });
          },
          fulfilled: (workOrderCopy) => {
            this.getWorkPeriods(workOrderCopy);
            const availabilities = getAvailabilities(workOrderCopy.id, workOrderCopy.from.format('YYYY-MM-DD'), workOrderCopy.to.format('YYYY-MM-DD'));
            this.setState({
              openSnackbar: true,
              saveStatusMessage: 'Keikka kopioitu',
              inputChanged: false,
              woName: workOrderCopy.name,
              workOrder: { ...workOrderCopy, invitations: [] },
              originalWorkOrder: workOrderCopy,
              availabilities,
            });
          },
        });
      },
    );
  }

  selectedAction = (action) => {
    const { originalWorkOrder } = this.state;

    if (action === 'clone' && originalWorkOrder) {
      this.cloneWorkOrder();
    }
  }

  handleClose = () => {
    this.setState({ workTaskDialogOpen: false });
  }

  renderWorkPeriods() {
    const { workOrder: { workPeriods } } = this.state;

    const processedWorkPeriods = workPeriods.filter((period) => !period._destroy)?.map((period) => {
      const filteredInvitations = period.invitations.filter((inv) => !inv._destroy);
      const sortedInvitations = [...filteredInvitations].sort((a, b) => a.userFullName.localeCompare(b.userFullName));
      const sentInvitationsCount = [...new Set(filteredInvitations.filter((inv) => inv.canParticipate === true).map((inv) => inv.userId))].length;

      return {
        ...period,
        sentInvitationsCount,
        invitationsCountText: period.invitationsCount ? `${sentInvitationsCount}/${period.invitationsCount || 0}` : sentInvitationsCount,
        invitationsGroupedByUser: groupBy(sortedInvitations, 'userFullName'),
      };
    });

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {processedWorkPeriods.sort((a, b) => b.from.unix() - a.from.unix()).map((period) => {
          let invitationsClass = 'invitations-neutral';
          if (period.invitationsCount && period.invitationsCount > period.sentInvitationsCount) {
            invitationsClass = 'invitations-warning';
          } else if (period.invitationsCount && period.invitationsCount <= period.sentInvitationsCount) {
            invitationsClass = 'invitations-ok';
          }

          const cardClass = getDurationColorClasses(period.from, period.to);

          return (
            <Card
              key={period.id || period.tempId}
              className={cardClass}
              style={{ backgroundColor: 'white', width: '100%', cursor: 'pointer' }}
              onClick={() => this.openWorkPeriodDialog(period)}
            >
              <Grid
                style={{
                  width: '100%',
                  // Set padding-bottom to 0 because of CollapsibleList providing its own empty space
                  padding: '16px 16px 0 16px',
                }}
                className="rmwc-grip-gap-10"
              >
                <GridCell
                  span={12}
                >
                  <GridInner>
                    <GridCell
                      style={{ fontSize: '14px' }}
                      desktop={6}
                      tablet={4}
                      phone={2}
                    >
                      <span>{period.name}</span>
                    </GridCell>

                    <GridCell
                      style={{
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        textAlign: 'end',
                      }}
                      desktop={6}
                      tablet={4}
                      phone={2}
                    >
                      <span>{shortDateRangeWithDay(period.from, period.to)}</span>
                    </GridCell>
                  </GridInner>
                </GridCell>

                <GridCell
                  className="employer-work-order-text-gray-sm"
                  style={{ fontSize: '12px', whiteSpace: 'pre-wrap', marginLeft: '10px' }}
                  span={12}
                >
                  {period.description}
                </GridCell>

                <GridCell
                  style={{ fontSize: '12px' }}
                  span={12}
                >
                  <CollapsibleList
                    style={{ margin: '10px 0' }}
                    startOpen={cardClass === 'work-order-card-border ongoing-color'}
                    handle={(
                      <SimpleListItem
                        // The availability-list-item class here is to remove the ripple effect
                        className="fake-headline-list-item availability-list-item"
                        // Stop propagation within the collapsiblelist to prevent opening dialog
                        onClick={(evt) => evt.stopPropagation()}
                        style={{ padding: 0 }}
                        text={(
                          <span
                            role="button"
                            className={invitationsClass}
                            style={{
                              display: 'flex',
                              justifyContent: 'end',
                              alignItems: 'center',
                              width: 'fit-content',
                              height: 'fit-content',
                            }}
                          >
                            <PeopleOutlineIcon
                              fontSize="small"
                              style={{
                                marginRight: '3px',
                              }}
                            />
                            <span>
                              {period.invitationsCountText}
                            </span>
                          </span>
                        )}
                        metaIcon="chevron_right"
                      />
                    )}
                  >
                    <div>
                      {Object.keys(period.invitationsGroupedByUser).map((userName) => (
                        <WorkOrderInvitationListItem
                          key={`work-order-invitation-item-${userName}`}
                          userName={userName}
                          invitations={period.invitationsGroupedByUser[userName]}
                          editMode={false}
                        />
                      ))}
                    </div>
                  </CollapsibleList>
                </GridCell>
              </Grid>
            </Card>
          );
        })}
      </div>
    );
  }

  setEditingWorkPeriods(value) {
    this.setState({
      editingWorkPeriods: value,
    });
  }

  // Used for the settings dialog, doesn't have validation
  updateSetting = (attr, value) => {
    if (attr === 'employeesCanViewAllTasks' && value === false) {
      // A stupid way to constrain that workTaskEmployeeManagementEnabled can't be true employeesCanViewAllTasks is false
      this.setState((prevState) => ({
        workOrder: {
          ...prevState.workOrder,
          workTaskEmployeeManagementEnabled: false,
          settings: {
            ...prevState.workOrder.settings,
            [attr]: value,
          },
        },
        inputChanged: true,
      }));
    } else {
      // Normal behavior
      this.setState((prevState) => ({
        workOrder: { ...prevState.workOrder, settings: { ...prevState.workOrder.settings, [attr]: value } },
        inputChanged: true,
      }));
    }
  }

  // Used for the settings dialog, doesn't have validation
  updateAttr = (attr, value) => {
    this.setState((prevState) => ({
      workOrder: { ...prevState.workOrder, [attr]: value },
      inputChanged: true,
    }));
  }

  openWorkPeriodDialog = (period = null) => {
    const { workOrder: { workPeriods } } = this.state;

    this.setState({
      showWorkPeriodDialog: true,
      currentWorkPeriod: !period ? { name: `Jakso ${workPeriods.length + 1}` } : period,
      inputChanged: true,
      // newInvites: true,
    });
  }

  render() {
    const { t, uiStore, uiStore: { currentUser: { accountInfo, accountId }, mobileMode } } = this.props;
    const {
      activeTab, workOrder, woName, editingWorkPeriods,
    } = this.state;

    // Default preset
    const validitiesPresetDefault = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
    ];

    // TODO: Hardcoded to account IDS 7, 10 and 3
    const validitiesPreset1 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.roadSafetyCard.number',
      'details.cards.roadSafetyCard.validThrough',
      'details.cards.roadSafetyCard.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
      'details.cards.id06Card.number',
      'details.cards.id06Card.validThrough',
      'details.cards.id06Card.imageURL',
    ];

    // TODO: Hardcoded to account ID 42
    const validitiesPreset2 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
      'details.cards.id06Card.number',
      'details.cards.id06Card.validThrough',
      'details.cards.id06Card.imageURL',
      'edit',
    ];

    const validitiesPreset3 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
    ];

    const validitiesPreset49 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
    ];

    const validitiesPreset58 = [
      'lastName',
      'firstName',
      'details.cards.occupationalSafetyCard.number',
      'details.cards.occupationalSafetyCard.validThrough',
      'details.cards.occupationalSafetyCard.imageURL',
      'details.cards.hotWorkLicense.number',
      'details.cards.hotWorkLicense.validThrough',
      'details.cards.hotWorkLicense.imageURL',
      'details.cards.firstAidTraining.class',
      'details.cards.firstAidTraining.validThrough',
      'details.cards.firstAidTraining.imageURL',
      'details.cards.valttiCard.number',
      'details.cards.valttiCard.validThrough',
      'details.cards.valttiCard.imageURL',
      'details.cards.machineryPermit.imageURL',
      'details.cards.ssgCard.number',
      'details.cards.ssgCard.validThrough',
      'details.cards.ssgCard.imageURL',
    ];

    let validitiesPreset;
    switch (accountId) {
      case 7:
      case 10:
      case 3:
        validitiesPreset = validitiesPreset1;
        break;
      case 42:
        validitiesPreset = validitiesPreset2;
        break;
      case 1:
        validitiesPreset = validitiesPreset3;
        break;
      case 49:
        validitiesPreset = validitiesPreset49;
        break;
      case 58:
        validitiesPreset = validitiesPreset58;
        break;
      default:
        validitiesPreset = validitiesPresetDefault;
    }

    return (
      <div
        className="work-order__list"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: '#1E2330',
            color: 'var(--mdc-theme-primary, #6200ee)',
            padding: '15px',
          }}
        >
          <Button
            variant="text"
            startIcon={<Icon>arrow_left</Icon>}
            style={{
              color: 'var(--mdc-theme-primary, #6200ee)',
              fontSize: '20px',
              textTransform: 'none',
              textAlign: 'left',
              whiteSpace: 'break-spaces',
              hyphens: 'auto',
            }}
            onClick={() => uiStore.showEmployerWorkOrders()}
          >
            {woName || 'Uusi keikka'}
          </Button>
          <IconButton style={{ color: 'white ' }} onClick={() => this.setState({ showSettingsDialog: true })} icon="settings" />
        </div>

        {/* Note: this HTML structure is significantly different from the "main" navigation tabs in other views */}
        <div style={{ backgroundColor: '#0D121C' }}>
          <TabBar
            style={{ maxWidth: '700px' }}
            activeTabIndex={activeTab}
            onActivate={(evt) => this.setState({ activeTab: evt.detail.index })}
          >
            <Tab icon="work" label={!mobileMode && 'Tiedot'} />
            <Tab icon="people" label={!mobileMode && t('work_order.tabs.participants')} />
            <Tab icon="folder_shared" label={!mobileMode && t('work_order.tabs.user_table')} />
            <Tab icon="attach_file" label={!mobileMode && t('work_order.tabs.attachments')} />
            {accountInfo.hourlyWorkOrdersEnabled && workOrder?.workHourType === 'hourly' && (
              <Tab
                icon="format_list_bulleted"
                label={!mobileMode && 'Työt'}
              />
            )}
          </TabBar>
        </div>

        <form onSubmit={this.handleSubmit} noValidate>
          <Grid
            style={{
              padding: '16px 16px 0px 16px',
              paddingTop: mobileMode ? 0 : null,
              maxWidth: activeTab !== 2 && activeTab !== 4 && '800px',
            }}
          >

            {activeTab === 0 && (
              this.renderBasicInfo()
            )}

            {activeTab === 1 && (
              <>
                <GridCell
                  span={12}
                  style={{ display: 'flex' }}
                >
                  <Typography
                    style={{
                      paddingLeft: mobileMode ? 0 : '25px',
                      flexGrow: 1,
                    }}
                    use="headline6"
                    tag="h2"
                    className="headline"
                  >
                    {/* {workOrder.workPeriods && accountInfo.workPeriodsEnabled && (workOrder.workPeriods.length > 1 || editingWorkPeriods) ? 'Osallistujat | Työjaksot' : 'Osallistujat'} */}
                    Osallistujat
                  </Typography>

                  {accountInfo.workPeriodsEnabled && (
                    <div style={{
                      display: 'flex', flexDirection: 'column', justifyContent: 'center', margin: '0 10px',
                    }}
                    >
                      <Switch
                        className="work-period-switch"
                        checked={editingWorkPeriods}
                        onChange={(evt) => this.setEditingWorkPeriods(evt.currentTarget.checked)}
                        use="text-hint-on-dark"
                        label="Työjaksot"
                        disabled={workOrder.workPeriods.length > 1 || (workOrder.workPeriods.length === 1 && this.checkWorkPeriodDateDiscrepancyRender())}
                      />
                    </div>
                  )}
                </GridCell>

                {workOrder.workPeriods.length === 0 && (
                  <GridCell
                    span={12}
                    style={{ textAlign: 'center' }}
                  >
                    <LoadingSpinner color="black" />
                  </GridCell>
                )}

                {(workOrder.workPeriods.length > 1 || editingWorkPeriods) && (
                  <GridCell span={12} style={{ textAlign: 'right', margin: '-10px 0' }}>
                    <Button
                      className="employer-accept-button"
                      type="button"
                      style={{
                        borderRadius: '0px',
                        minWidth: '100px',
                        width: 'fit-content',
                        height: 'fit-content',
                        // margin: '0 16px',
                      }}
                      onClick={() => this.openWorkPeriodDialog()}
                      startIcon={<AddIcon />}
                    >
                      Uusi jakso
                    </Button>
                  </GridCell>
                )}

                {accountInfo.workPeriodsEnabled && workOrder.workPeriods && (workOrder.workPeriods.length > 1 || editingWorkPeriods) ? (
                  <GridCell span={12}>
                    {this.renderWorkPeriods()}
                  </GridCell>
                ) : (
                  <GridCell span={12}>
                    {workOrder.workPeriods[0] && (
                      <WorkPeriodForm
                        key={workOrder.workPeriods[0].id || workOrder.workPeriods[0].tempId}
                        workOrder={workOrder}
                        workPeriod={workOrder.workPeriods[0]}
                        onSave={this.saveWorkPeriod}
                        updateWorkPeriod={this.saveWorkPeriod}
                        singleWorkPeriod
                        editingWorkPeriods={editingWorkPeriods}
                        setEditingWorkPeriods={this.setEditingWorkPeriods}
                      />
                    )}
                  </GridCell>
                )}
              </>
            )}

            {activeTab === 2 && (
              <>
                {mobileMode && (
                  <GridCell
                    span={12}
                    style={{
                      margin: '0px -16px',
                    }}
                  >
                    <Typography
                      style={{
                        paddingLeft: '25px',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Listat
                    </Typography>
                  </GridCell>
                )}
                <GridCell span={12}>
                  {workOrder.uniqueParticipants && workOrder.uniqueParticipants.length > 0 ? (
                    <EmployeesTable
                      data={workOrder.uniqueParticipants}
                      hasMargins={false}
                      csvFilename={`${workOrder.name}_${moment().format('DD-MM-YYYY')}`}
                      view="work-order-detail"
                      presets={{
                        basic: [
                          'lastName',
                          'firstName',
                          'details.cards.occupationalSafetyCard.number',
                          'details.cards.occupationalSafetyCard.validThrough',
                          'details.cards.hotWorkLicense.number',
                          'details.cards.hotWorkLicense.validThrough',
                          'details.cards.firstAidTraining.class',
                          'details.cards.firstAidTraining.validThrough',
                          'birthDate',
                          'taxNumber',
                          'email',
                          'phoneNumber',
                          'city',
                        ],
                        contactInfo: [
                          'lastName',
                          'firstName',
                          'phoneNumber',
                          'email',
                          'street',
                          'zipCode',
                          'city',
                          'iceName',
                          'iceNumber',
                        ],
                        validities: validitiesPreset,
                        accomodation: [
                          'lastName',
                          'firstName',
                          'street',
                          'zipCode',
                          'city',
                          'email',
                          'phoneNumber',
                          'birthDate',
                        ],
                        clothing: [
                          'lastName',
                          'firstName',
                          'details.clothing.size',
                          'details.clothing.number',
                          'details.clothing.shoeSize',
                          'details.clothing.hasHelmet',
                          'details.clothing.helmetSize',
                          'helmetImageUrl',
                        ],
                        // The key 'all' is used to ignore the columns array in the EmployeesTable.toggleColumnVisibilityPreset()
                        // We keep do this instead of naming every column in the 'all' preset to keep things more dynamic, if more columns are added
                        all: [],
                      }}
                    />
                  ) : 'Keikalla ei ole osallistujia'}
                </GridCell>
              </>
            )}
            {activeTab === 3 && (
              // this.getInstructions(workOrder)
              <>
                {mobileMode && (
                  <GridCell
                    span={12}
                    style={{
                      margin: '0px -16px',
                    }}
                  >
                    <Typography
                      style={{
                        paddingLeft: '25px',
                      }}
                      use="headline6"
                      tag="h2"
                      className="headline"
                    >
                      Liitteet
                    </Typography>
                  </GridCell>
                )}
                {workOrder.id ? (
                  <EmployerWorkOrderAttachment
                    attachments={workOrder.attachments}
                    workOrderId={workOrder.id}
                    inputRef={React.createRef()}
                    updateAttachmentState={this.updateAttachmentState}
                    deleteAttachmentFromState={this.deleteAttachmentFromState}
                    deleteFileFromState={this.deleteFileFromState}
                  />
                ) : (
                  <GridCell
                    span={12}
                    style={{ textAlign: 'center' }}
                  >
                    <p>Tallenna keikka lisätäksesi liitteitä</p>
                  </GridCell>
                )}
              </>
            )}

            {/* {activeTab === 3 && this.renderWorkTasks()} */}
            {activeTab === 4 && (
              <>
                {workOrder.id ? (
                  <GridCell span={12}>
                    <WorkTasks
                      workOrderId={workOrder.id}
                      workTasks={workOrder.workTasks}
                      deleteWorkTaskState={this.deleteWorkTaskState}
                      // workOrderParticipants={workOrder.workOrderInvitations}
                      workOrderParticipants={workOrder.participants}
                    />
                  </GridCell>
                ) : (
                  <GridCell
                    span={12}
                    style={{ textAlign: 'center' }}
                  >
                    <p>Tallenna keikka lisätäksesi töitä</p>
                  </GridCell>
                )}
              </>
            )}

            {this.renderBottomButtons()}

          </Grid>
        </form>
        {this.renderSaveMessage()}
        {this.renderDeletePrivilegeDialog()}
        {this.renderLocationDialog()}
        {this.renderPurchaserDialog()}
        {this.renderSettingsDialog()}
        {this.renderWorkPeriodDialog()}
        {this.renderDateChangeNotificationDialog()}
      </div>
    );
  }
}

export default EmployerWorkOrderDetail;
