import { action, observable, set } from 'mobx';
import moment, { dateWithoutTime } from '../utils/moment';
import TripRoute from './TripRoute';

const getDate = (val) => (val != null ? dateWithoutTime(val) : val);

export default class StartTrip {
  @observable id;

  @observable date;

  @observable time;

  @observable status;

  @observable uiWorkOrderId;

  @observable workOrderTripId;

  @observable tripRoute;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      status: o.status,
      date: o.date,
      time: o.time,
      ui_work_order_id: o.uiWorkOrderId,
      work_order_trip_id: o.workOrderTripId,
      // trip_routes_attributes: o.tripRoute.map(tripRoute => TripRoute.toJson(tripRoute)),
      trip_routes_attributes: o.tripRoute.map((tripRoute) => TripRoute.toJson(tripRoute)),
    };
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  @action changeAttribute(attr, value) {
    this[attr] = value;
  }

  @action changeAttr(attr, value) {
    set(this, attr, value);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at);

    // update content only if received more up to date content – this may happen e.g. when multiple updates are delayed due to slow network
    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return null;
    }

    const values = {
      id: o.id,
      date: getDate(o.date),
      time: moment(o.time).format('HH:mm'),
      uiWorkOrderId: o.ui_work_order_id,
      workOrderTripId: o.work_order_trip_id,
      status: o.status == null ? 'draft' : o.status, // MIGHT NEED TO CHANGE THIS
      employerComment: o.employer_comment,
    };

    let tripRoute = [];
    if (o.trip_routes != null) {
      tripRoute = o.trip_routes.map((item) => TripRoute.fromJsonProperties(item));
    } else {
      tripRoute.push(new TripRoute({}));
    }
    values.tripRoute = tripRoute;

    return this.updateProperties(values);
  }

  static fromJsonProperties(object) {
    const startTrip = new StartTrip({});
    startTrip.updatePropertiesFromJson(object);
    return startTrip;
  }

  static fromJson(json) {
    return StartTrip.fromJsonProperties(JSON.parse(json));
  }
}
