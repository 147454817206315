import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// import {
//   Transition,
//   animated,
//   config,
// } from 'react-spring';
import { Button } from '@material-ui/core'; // IconButton

// import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
// import DashboardIcon from '@material-ui/icons/Dashboard';
// import FilterListIcon from '@material-ui/icons/FilterList';
// import Brightness1Icon from '@material-ui/icons/Brightness1';
import StopIcon from '@material-ui/icons/Stop';
import AddIcon from '@material-ui/icons/Add';

// import EmployerTimelogCard from './employerTimelogCard';
// import EmployerTimelogTable from './employerTimelogTable';
import EmployerAbsenceTable from './EmployerAbsenceTable';
// import FilterDialog, { customFilterComparison } from '../shared/FilterDialog';
import LoadingSpinner from '../shared/LoadingSpinner';
// import EmployeeInfoDialog from './EmployeeInfoDialog';
// import WorkOrderInfoDialog from './WorkOrderInfoDialog';
// import WorkOrderTrip from '../timelog/WorkOrderTrip';
// import EmployerWorkOrderTripCard from '../employer-work-order-trips/employerWorkOrderTripCard';
import moment, { dateRange } from '../utils/moment';
import './index.css';
import CalendarEntry from '../models/CalendarEntry';
import AbsencePeriodDialog from '../absences/absence-period-dialog';
import AbsencePeriod from '../models/AbsencePeriod';

// const TransitionedCards = (props) => {
//   const {
//     sortedWorkHoursWithMeta,
//     updateWorkHour,
//     openRejectWorkHourDialog,
//     openEmployeeInfoDialog,
//     openWorkOrderInfoDialog,
//     afterUpdate,
//     openWorkOrderTripAcceptanceDialog,
//     // daysTillDeadlines,
//   } = props;

//   return (
//     <Transition
//       items={sortedWorkHoursWithMeta}
//       from={{ opacity: 0 }}
//       enter={{ opacity: 1 }}
//       leave={{ opacity: 0 }}
//       // delay={100}
//       config={config.default}
//       keys={(item) => `transition_${item.work_hour.status === 'not_created' ? `${item.work_hour.user_id}-${item.work_hour.date}-${item.work_hour.work_order_id}` : item.work_hour.id}`}
//     >
//       {(styles, item) => (
//         <animated.div style={styles}>
//           <EmployerTimelogCard
//             workHourWithMeta={item}
//             key={item.work_hour.id}
//             updateWorkHour={updateWorkHour}
//             openRejectWorkHourDialog={openRejectWorkHourDialog}
//             openEmployeeInfoDialog={openEmployeeInfoDialog}
//             openWorkOrderInfoDialog={openWorkOrderInfoDialog}
//             afterUpdate={afterUpdate}
//             openWorkOrderTripAcceptanceDialog={openWorkOrderTripAcceptanceDialog}
//             // nextDeadline={item.salary_period.days_till_deadline === daysTillDeadlines[0]}
//           />
//         </animated.div>
//       )}
//     </Transition>
//   );
// };


@inject('uiStore', 'absenceStore', 't', 'actionCableStore')
@observer
class EmployerAbsences extends Component {
  // cableApp = {};

  constructor(props) {
    super(props);
    const { uiStore: { mobileMode, currentEmployerAcceptanceMode } } = props;

    this.state = {
      // originalWorkHoursWithMeta: null,
      // currentWorkHoursWithMeta: null,
      currentAbsenceEntries: null,
      // originalWorkOrderTripsWithMeta: null,
      // currentWorkOrderTripsWithMeta: null,
      // filterDialogOpen: false,
      // filterCount: 0,
      currentFilters: ['work_hour.status.pending'], // Must contain the FilterDialog defaultFilters
      // viewMode: mobileMode ? 'cards' : 'table',
      // Used for database queries
      absenceEntriesSelected: [],
      // Used for MUIDataTable
      tableRowsSelected: [],
      // columnVisibility: {},

      // showEmployeeInfoDialog: false,
      // employeeInfoDialogData: null,

      // showWorkOrderInfoDialog: false,
      // workOrderInfoDialogData: null,
      // daysTillDeadlines: [],
      salaryPeriods: [],
      absencePeriodDialogOpen: false,
    };

    if (currentEmployerAcceptanceMode) {
      // Use the user-set mode
      this.state.viewMode = currentEmployerAcceptanceMode;
    } else {
      // Use default mode according to screen size (mobile vs. desktop)
      this.state.viewMode = mobileMode ? 'cards' : 'table';
    }

    // this.toggleFilterDialog = this.toggleFilterDialog.bind(this);
    // this.toggleFilterHours = this.toggleFilterHours.bind(this);
    // this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    const { actionCableStore } = this.props;

    // this.getTimelogs();
    this.getAbsenceEntries();
    this.cable = actionCableStore.cableOn
      ? actionCableStore.subscribe('EmployerWorkHoursChannel', this.processUpdateResponse)
      : null;
  }

  componentWillUnmount() {
    const { actionCableStore } = this.props;

    if (this.cable) {
      actionCableStore.unsubscribe(this.cable);
    }
  }

  // getTimelogs() {
  //   const { timelogStore, uiStore: { currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props;

  //   timelogStore.getResponsibleEmployerTimelogs().then((data) => {
  //     const salaryPeriods = [];
  //     let processedHours = data.hours;
  //     if (salaryPeriodCategoriesEnabled) {
  //       processedHours = data.hours.map((hour) => {
  //         const updatedHour = { ...hour };
  //         if (!salaryPeriods.find((salaryPeriod) => salaryPeriod.id === hour.salary_period.id)) {
  //           salaryPeriods.push(hour.salary_period);
  //         }
  //         const salaryPeriodFrom = moment(hour.salary_period.from).format('dd DD.MM.');
  //         const salaryPeriodTo = moment(hour.salary_period.to).format('dd DD.MM.');
  //         updatedHour.salary_period.nameWithDates = `${hour.salary_period.name}, ${salaryPeriodFrom} - ${salaryPeriodTo}`;
  //         return updatedHour;
  //       });

  //       this.setState({
  //         // Sort the days till deadlines ascending: earliest deadline first
  //         salaryPeriods, // daysTillDeadlines.sort((a, b) => a - b),
  //       });
  //     }

  //     this.setState({
  //       originalWorkHoursWithMeta: processedHours,
  //       salaryPeriod: data.salary_period,
  //     });
  //   });
  // }

  getAbsenceEntries() {
    console.log('GETTING ABSENCE ENTRIES');
    const { absenceStore, uiStore: { currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props; //

    absenceStore.getEmployerAbsenceEntries().then((data) => {
      const salaryPeriods = [];

      console.log('RESPONSE IN EMPLOYER-ABSENCES: ', data);
      let processedAbsenceEntries = data.absence_entries;

      if (salaryPeriodCategoriesEnabled) {
        processedAbsenceEntries = data.absence_entries.map((absenceEntry) => {
          const updatedEntry = { ...absenceEntry };
          if (!salaryPeriods.find((salaryPeriod) => salaryPeriod.id === absenceEntry.salary_period.id)) {
            salaryPeriods.push(absenceEntry.salary_period);
          }
          const salaryPeriodFrom = moment(absenceEntry.salary_period.from).format('dd DD.MM.');
          const salaryPeriodTo = moment(absenceEntry.salary_period.to).format('dd DD.MM.');
          updatedEntry.salary_period.nameWithDates = `${absenceEntry.salary_period.name}, ${salaryPeriodFrom} - ${salaryPeriodTo}`;
          return updatedEntry;
        });

        this.setState({
          // Sort the days till deadlines ascending: earliest deadline first
          salaryPeriods, // daysTillDeadlines.sort((a, b) => a - b),
        });
      }

      // this.setState({
      //   originalWorkHoursWithMeta: processedHours,
      //   salaryPeriod: data.salary_period,
      // });

      const absenceEntries = processedAbsenceEntries.map((absenceEntryJson) => ({
        ...absenceEntryJson,
        calendar_entry: CalendarEntry.fromJsonProperties(absenceEntryJson.calendar_entry),
      }));

      this.setState({
        currentAbsenceEntries: absenceEntries,
        salaryPeriod: data.salary_period,
        usersWithAbsencesEnabled: data.users_with_absences_enabled,
      });
    });
  }

  processUpdateResponse = (response) => {
    if (response.type === 'work_hour') {
      if (response.method === 'update') {
        this.updateWorkHourStatusAndComment(response.body, response.body.status, response.body.employer_comment);
        // this.updateWorkHourEmployeeAccept(response.body.work_hours);
      } else {
        // Assuming "get" method and an array of hour IDs
        this.getWorkHours(response.body.ids);
      }
    }

    if (response.body.deleted_ids) {
      this.deleteWorkHourCard(response.body.deleted_ids);
    }
  }

  processSelfUpdateResponse = (response) => {
    response.body.updated_hours.forEach((hour) => this.updateWorkHourValues(hour));
  }

  setActiveFilters = (currentFilters) => {
    this.setState({ currentFilters });
  }

  // getWorkHours = (ids) => {
  //   const { timelogStore } = this.props;
  //   timelogStore.getResponsibleEmployerTimelogsById(ids).then((hoursWithMeta) => {
  //     this.updateWorkHourEmployeeAccept(hoursWithMeta);
  //   });
  // }

  // updateWorkHourStatusAndComment = (workHour, newStatus, employerComment) => {
  //   const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
  //   const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];
  //   const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];
  //   const {
  //     id: workHourId,
  //     user_id: userId,
  //     work_order_id: workOrderId,
  //     date,
  //   } = workHour;

  //   const foundOriginalWorkHour = originalWorkHoursWithMeta.find((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
  //   const updatedOriginalWorkHour = { ...foundOriginalWorkHour };

  //   // let matchingFilters = [];
  //   if (foundOriginalWorkHour) {
  //     // Stupid, there's old_work_hour but it's status is an integer, not string
  //     // originalStatus = foundOriginalWorkHour.work_hour.status;
  //     updatedOriginalWorkHour.work_hour.status = newStatus;
  //     // Need to update the employer comment to keep it up to date for the employer
  //     updatedOriginalWorkHour.work_hour.employer_comment = employerComment;
  //   }

  //   const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
  //   const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
  //   // Do the current filters match the work hour?

  //   if (foundOriginalWorkHourIndex !== 1) {
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.status = newStatus;
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.employer_comment = employerComment;
  //   }

  //   const filtersMatch = customFilterComparison(updatedOriginalWorkHour, currentFilters);

  //   if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
  //     // Found but filters do not match, removing
  //     updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
  //   } else if (foundCurrentWorkHourIndex !== -1) {
  //     // Found and either the filters match or there's no filters at all, updating
  //     updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = updatedOriginalWorkHour;
  //   } else if (filtersMatch || currentFilters.length === 0) {
  //     // Not found but filters match or there's no filters at all, adding
  //     updatedCurrentWorkHoursWithMeta.push(updatedOriginalWorkHour);
  //   }

  //   this.setState({
  //     originalWorkHoursWithMeta: [...updatedOriginalWorkHoursWithMeta],
  //     currentWorkHoursWithMeta: [...updatedCurrentWorkHoursWithMeta],
  //   });
  // }

  // handleRejectedEmpty = (newWorkHour) => {
  //   const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
  //   const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];
  //   const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];

  //   const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => !item.work_hour.id && item.work_hour.user_id === newWorkHour.user_id && item.work_hour.date === newWorkHour.date && item.work_hour.work_order_id === newWorkHour.work_order_id);
  //   const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => !item.work_hour.id && item.work_hour.user_id === newWorkHour.user_id && item.work_hour.date === newWorkHour.date && item.work_hour.work_order_id === newWorkHour.work_order_id);

  //   let updatedOriginalWorkHour;
  //   if (foundOriginalWorkHourIndex !== 1) {
  //     // updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour = newWorkHour;
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.id = newWorkHour.id;
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.status = newWorkHour.status;
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour.employer_comment = newWorkHour.employer_comment;
  //     updatedOriginalWorkHour = { ...updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex] };
  //   }

  //   const filtersMatch = customFilterComparison(updatedOriginalWorkHour, currentFilters);

  //   if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
  //     // Found but filters do not match, removing
  //     updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
  //   } else if (foundCurrentWorkHourIndex !== -1) {
  //     // Found and either the filters match or there's no filters at all, updating
  //     updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = updatedOriginalWorkHour;
  //   } else if (filtersMatch || currentFilters.length === 0) {
  //     // Not found but filters match or there's no filters at all, adding
  //     updatedCurrentWorkHoursWithMeta.push(updatedOriginalWorkHour);
  //   }

  //   this.setState({
  //     originalWorkHoursWithMeta: [...updatedOriginalWorkHoursWithMeta],
  //     currentWorkHoursWithMeta: [...updatedCurrentWorkHoursWithMeta],
  //   });
  // }

  // updateWorkHour = (workHourWithMeta) => {
  //   const {
  //     work_hour: {
  //       id: workHourId,
  //       user_id: userId,
  //       work_order_id:
  //       workOrderId,
  //       date,
  //     },
  //   } = workHourWithMeta;

  //   const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
  //   const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];
  //   const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];

  //   const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
  //   let foundOriginalWorkHour = null;
  //   // let matchingFilters = [];
  //   if (foundOriginalWorkHourIndex !== -1) {
  //     foundOriginalWorkHour = workHourWithMeta; // originalWorkHoursWithMeta[foundOriginalWorkHourIndex];
  //     // Stupid, there's old_work_hour but it's status is an integer, not string
  //     // originalStatus = foundOriginalWorkHour.work_hour.status;
  //     // foundOriginalWorkHour.work_hour.status = newStatus;
  //     // // Need to update the employer comment to keep it up to date for the employer
  //     // foundOriginalWorkHour.work_hour.employer_comment = employerComment;
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex] = workHourWithMeta;
  //   }

  //   const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (!item.work_hour.id && item.work_hour.user_id === userId && item.work_hour.date === date && item.work_hour.work_order_id === workOrderId));
  //   // Do the current filters match the work hour?
  //   const filtersMatch = customFilterComparison(foundOriginalWorkHour, currentFilters);

  //   if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
  //     // Found but filters do not match, removing
  //     updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
  //   } else if (foundCurrentWorkHourIndex !== -1) {
  //     // Found and either the filters match or there's no filters at all, updating
  //     updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = foundOriginalWorkHour;
  //   } else if (filtersMatch || currentFilters.length === 0) {
  //     // Not found but filters match or there's no filters at all, adding
  //     updatedCurrentWorkHoursWithMeta.push(foundOriginalWorkHour);
  //   }

  //   this.setState({
  //     currentWorkHoursWithMeta: updatedCurrentWorkHoursWithMeta,
  //     originalWorkHoursWithMeta: updatedOriginalWorkHoursWithMeta,
  //   });
  // }

  // After current user's self-initiated bulk update, for work hours without meta
  // updateWorkHourValues = (workHour) => {
  //   const {
  //     id: workHourId,
  //     work_order_id: workOrderId,
  //     date,
  //     user_id: userId,
  //   } = workHour;

  //   const { originalWorkHoursWithMeta, currentWorkHoursWithMeta, currentFilters } = this.state;
  //   const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta];
  //   const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta];

  //   // const foundOriginalWorkHourIndex = originalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId);
  //   const foundOriginalWorkHourIndex = updatedOriginalWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId || (item.work_hour.work_order_id === workOrderId && item.work_hour.date === date && item.work_hour.user_id === userId));
  //   let foundOriginalWorkHour = null;

  //   if (foundOriginalWorkHourIndex !== -1) {
  //     // We update the keys one by one in order to maintain the old oldering from Rails generate_employer_hour_with_meta
  //     const updatedWorkHour = { ...originalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour };
  //     Object.keys(updatedWorkHour).forEach((attr) => {
  //       updatedWorkHour[attr] = workHour[attr];
  //     });
  //     updatedOriginalWorkHoursWithMeta[foundOriginalWorkHourIndex].work_hour = updatedWorkHour;

  //     foundOriginalWorkHour = { ...originalWorkHoursWithMeta[foundOriginalWorkHourIndex], work_hour: updatedWorkHour };
  //   }

  //   const foundCurrentWorkHourIndex = updatedCurrentWorkHoursWithMeta.findIndex((item) => item.work_hour.id === workHourId);
  //   // Do the current filters match the work hour?
  //   const filtersMatch = customFilterComparison(foundOriginalWorkHour, currentFilters);

  //   if (foundCurrentWorkHourIndex !== -1 && !filtersMatch) {
  //     // Found but filters do not match, removing
  //     updatedCurrentWorkHoursWithMeta.splice(foundCurrentWorkHourIndex, 1);
  //   } else if (foundCurrentWorkHourIndex !== -1) {
  //     // Found and either the filters match or there's no filters at all, updating
  //     updatedCurrentWorkHoursWithMeta[foundCurrentWorkHourIndex] = foundOriginalWorkHour;
  //   } else if (filtersMatch || currentFilters.length === 0) {
  //     // Not found but filters match or there's no filters at all, adding
  //     updatedCurrentWorkHoursWithMeta.push(foundOriginalWorkHour);
  //   }

  //   this.setState({
  //     originalWorkHoursWithMeta: updatedOriginalWorkHoursWithMeta,
  //     currentWorkHoursWithMeta: updatedCurrentWorkHoursWithMeta,
  //   });
  // }

  // deleteWorkHourCard = (deletedWorkHourIds) => {
  //   const { originalWorkHoursWithMeta, currentWorkHoursWithMeta } = this.state;
  //   const updatedCurrentWorkHoursWithMeta = [...currentWorkHoursWithMeta].filter((workHourWithMeta) => !deletedWorkHourIds.includes(workHourWithMeta.work_hour.id));
  //   const updatedOriginalWorkHoursWithMeta = [...originalWorkHoursWithMeta].filter((workHourWithMeta) => !deletedWorkHourIds.includes(workHourWithMeta.work_hour.id));

  //   this.setState({
  //     currentWorkHoursWithMeta: updatedCurrentWorkHoursWithMeta,
  //     originalWorkHoursWithMeta: updatedOriginalWorkHoursWithMeta,
  //   });
  // }

  // closeRejectWorkHourDialog = () => {
  //   this.setState({
  //     showRejectWorkHourDialog: false,
  //     rejectingSendSMS: false,
  //     rejectingEmployerComment: '',
  //     rejectWorkHourDialogTitle: '',
  //   });
  // }

  // closeEmployeeInfoDialog = () => {
  //   this.setState({
  //     showEmployeeInfoDialog: false,
  //     employeeInfoDialogData: null,
  //   });
  // }

  // closeWorkOrderInfoDialog = () => {
  //   this.setState({
  //     showWorkOrderInfoDialog: false,
  //     workOrderInfoDialogData: null,
  //   });
  // }

  // openRejectWorkHourDialog = (rejectingWorkHour, rejectingWorkOrderId, rejectWorkHourDialogTitle, mode) => {
  //   this.setState({
  //     rejectingMode: mode,
  //     showRejectWorkHourDialog: true,
  //     rejectingWorkHour,
  //     rejectingWorkOrderId,
  //     rejectWorkHourDialogTitle,
  //   });
  // }

  // openEmployeeInfoDialog = (employee) => {
  //   this.setState({
  //     showEmployeeInfoDialog: true,
  //     employeeInfoDialogData: employee,
  //   });
  // }

  // openWorkOrderInfoDialog = (workOrderInfo) => {
  //   this.setState({
  //     showWorkOrderInfoDialog: true,
  //     workOrderInfoDialogData: workOrderInfo,
  //   });
  // }

  // openWorkOrderTripAcceptanceDialog = (workOrderTripId) => {
  //   const { timelogStore } = this.props;
  //   this.setState({
  //     showWorkOrderTripDialog: true,
  //   }, () => {
  //     new Promise((resolve, reject) => timelogStore.getResponsibleEmployerWorkOrderTripsById(workOrderTripId, resolve, reject))
  //       .then((woTrip) => {
  //         // Success
  //         const workOrderTrip = WorkOrderTrip.fromJsonProperties(woTrip);
  //         this.setState({
  //           openedWorkOrderTripWithMeta: workOrderTrip,
  //         });
  //       })
  //       .catch((err) => {
  //         // Failure
  //         this.setState({
  //           openedWorkOrderTripWithMeta: null,
  //         }, console.log('ERROR: ', err));
  //       });
  //   });
  // }

  setAbsenceEntriesSelected = (absenceEntries, rows) => {
    this.setState({
      tableRowsSelected: rows,
      absenceEntriesSelected: absenceEntries,
    });
  }

  closeAbsencePeriodDialog = () => {
    this.setState({
      newAbsencePeriod: null,
      absencePeriodDialogOpen: false,
    });
  }

  updateAbsencePeriodState = (absenceEntries) => {
    const { currentAbsenceEntries } = this.state;

    const updatedCurrentAbsenceEntries = [...currentAbsenceEntries];

    absenceEntries.forEach((absenceEntryJson) => {
      const absenceEntry = { ...absenceEntryJson, calendar_entry: CalendarEntry.fromJsonProperties(absenceEntryJson.calendar_entry) };
      const foundIndex = updatedCurrentAbsenceEntries.findIndex((existingEntry) => existingEntry.id === absenceEntry.id);
      if (foundIndex !== -1) {
        updatedCurrentAbsenceEntries[foundIndex] = absenceEntry;
      } else {
        updatedCurrentAbsenceEntries.push(absenceEntry);
      }
      // Filters implented -> also updated the original absence entries, then check if filters match
    });

    this.setState({
      currentAbsenceEntries: updatedCurrentAbsenceEntries,
    });
  }

  updateAbsenceEntry = (absenceEntry) => {
    const { currentAbsenceEntries } = this.state;
    // const updatedCurrentAbsenceEntries = [...currentAbsenceEntries];
    const foundEntry = currentAbsenceEntries.find((existingEntry) => existingEntry.id === absenceEntry.id);

    if (foundEntry) {
      foundEntry.values = absenceEntry.values;
      foundEntry.calendar_entry = absenceEntry.calendarEntry;
      foundEntry.description = absenceEntry.description;
    }
  }

  handleDeleteAbsenceEntry = (absenceEntry, cb) => {
    const { absenceStore } = this.props;

    return absenceStore.deleteAbsenceEntry(absenceEntry).then(() => {
      const { currentAbsenceEntries } = this.state;
      const updatedCurrentAbsenceEntries = [...currentAbsenceEntries];
      const foundEntryIndex = updatedCurrentAbsenceEntries.findIndex((existingEntry) => existingEntry.id === absenceEntry.id);

      if (foundEntryIndex !== -1) {
        updatedCurrentAbsenceEntries.splice(foundEntryIndex, 1);
      }
      this.setState({
        currentAbsenceEntries: updatedCurrentAbsenceEntries,
      }, () => {
        if (cb) {
          cb();
        }
      });
    }).catch((err) => {
      console.error(err);
    });
  }

  // deleteAbsenceEntry = (absenceEntry) => {
  //   const { currentAbsenceEntries } = this.state;
  //   const updatedCurrentAbsenceEntries = [...currentAbsenceEntries];
  //   const foundEntryIndex = updatedCurrentAbsenceEntries.findIndex((existingEntry) => existingEntry.id === absenceEntry.id);

  //   if (foundEntryIndex !== -1) {
  //     updatedCurrentAbsenceEntries.splice(foundEntryIndex, 1);
  //   }
  //   this.setState({
  //     currentAbsenceEntries: updatedCurrentAbsenceEntries,
  //   });
  // }

  acceptChecked() {
    const { absenceStore } = this.props;
    const { currentAbsenceEntries, absenceEntriesSelected } = this.state;

    absenceStore.employerAcceptMultipleEntries(absenceEntriesSelected).then((response) => {
      const updatedCurrentAbsenceEntries = [...currentAbsenceEntries];
      response.forEach((updatedAbsenceEntry) => {
        const foundIndex = updatedCurrentAbsenceEntries.findIndex((existingEntry) => existingEntry.id === updatedAbsenceEntry.id);
        if (foundIndex !== -1) {
          updatedCurrentAbsenceEntries[foundIndex].status = updatedAbsenceEntry.status;
        }
        // Filters implented -> also updated the original absence entries, then check if filters match
      });

      this.setState({
        currentAbsenceEntries: updatedCurrentAbsenceEntries,
        absenceEntriesSelected: [],
        tableRowsSelected: [],
      });
    });
  }

  showNewAbsencePeriodDialog() {
    this.setState({
      newAbsencePeriod: new AbsencePeriod(),
      absencePeriodDialogOpen: true,
    });
  }

  renderAbsencePeriodDialog() {
    const { absencePeriodDialogOpen, newAbsencePeriod, usersWithAbsencesEnabled } = this.state;
    return (
      <AbsencePeriodDialog
        employerMode
        usersWithAbsencesEnabled={usersWithAbsencesEnabled}
        open={absencePeriodDialogOpen}
        absencePeriod={newAbsencePeriod} // currentAbsencePeriod
        onClose={this.closeAbsencePeriodDialog}
        // handleDeleteAbsencePeriod={this.handleDeleteAbsencePeriod}
        // WIP
        updateAbsencePeriodState={this.updateAbsencePeriodState}
      />
    );
  }

  render() {
    const { uiStore } = this.props;
    // const { timelogStore: { workHourFilters } } = this.props;
    const {
      // originalWorkHoursWithMeta,
      // originalWorkOrderTripsWithMeta,
      // currentWorkHoursWithMeta,
      // currentWorkOrderTripsWithMeta,
      // filterHours,
      // filterTrips,
      // filterDialogOpen,
      // filterCount,
      // viewMode,
      currentFilters,
      tableRowsSelected,
      // absenceEntriesSelected,
      // columnVisibility,
      // showEmployeeInfoDialog,
      // employeeInfoDialogData,
      // showWorkOrderInfoDialog,
      // workOrderInfoDialogData,
      salaryPeriod,
      // daysTillDeadlines,
      salaryPeriods,
      currentAbsenceEntries,
      // absencePeriodDialogOpen,
    } = this.state;
    const { uiStore: { mobileMode, currentUser: { accountInfo: { salaryPeriodCategoriesEnabled } } } } = this.props;

    // const acceptMultipleDisabled = absenceEntriesSelected.find((whData) => !whData.id);
    const acceptMultipleDisabled = false;

    // const filters = [
    //   { title: 'Tila', key: 'work_hour.status', translate: true },
    //   { title: 'Kirjausten hyväksyjä', key: 'accepting_employers', translate: false },
    //   { title: 'Keikka', key: 'work_order_info.name', translate: false },
    //   { title: 'Työntekijä', key: 'user.full_name', translate: false },
    // ];

    // if (salaryPeriodCategoriesEnabled) {
    //   filters.unshift({ title: 'Menossa oleva palkkakausi', key: 'salary_period.nameWithDates', translate: false });
    // }

    return (
      <div className="employer-timelog-wrapper" style={{ width: '100%' }}>
        <div
          style={{
            padding: '10px',
            width: '100%',
            // textAlign: 'end',
            // maxWidth: '350px',
            display: 'flex',
            // justifyContent: viewMode === 'table' ? 'space-between' : 'flex-end',
            // flexWrap: viewMode === 'table' ? 'wrap-reverse' : 'initial',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
          className="filter-button"
        >
          <div className="employer-timelogs-header">
            <div className={uiStore.mobileMode ? 'employer-timelogs-title employer-timelogs-title-mobile' : 'employer-timelogs-title'}>
              {salaryPeriod && !salaryPeriodCategoriesEnabled && `Jakso: ${dateRange(salaryPeriod.from, salaryPeriod.to)}`}
              {salaryPeriodCategoriesEnabled && (
                <span style={{ textTransform: 'initial' }}>Poissaolojen hyväksyntä</span>
              )}
              {salaryPeriodCategoriesEnabled && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '14px',
                    textTransform: 'none',
                    marginTop: '5px',
                  }}
                >
                  {/* Should be smallest days till deadline first */}
                  {/* {daysTillDeadlines?.map((deadline, index) => {
                    if (deadline >= 0) {
                      return (
                        <div style={{ display: 'flex' }}>
                          <Brightness1Icon style={{ fontSize: '18px', color: index === 0 ? '#FF6A00' : '#FF9E00' }} />
                          <span>{`${Math.abs(deadline)} pv jäljellä`}</span>
                        </div>
                      );
                    }
                    return (
                      <div style={{ display: 'flex' }}>
                        <Brightness1Icon style={{ fontSize: '18px', color: index === 0 ? '#FF6A00' : '#FF9E00' }} />
                        <span
                          style={{ marginLeft: '3px' }}
                        >
                          {`${Math.abs(deadline)} pv myöhässä`}
                        </span>
                      </div>
                    );
                  })} */}

                  {salaryPeriods?.map((timelogSalaryPeriod) => (
                    <div style={{ display: 'flex' }}>
                      <StopIcon style={{ fontSize: '35px', margin: '-5px', color: timelogSalaryPeriod.color }} />
                      <span style={{ alignSelf: 'center', textTransform: 'capitalize', marginRight: '5px' }}>
                        {timelogSalaryPeriod.nameShort ? (
                          `${timelogSalaryPeriod.nameShort}, ${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`
                        ) : (
                          `${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`
                        )}
                      </span>
                      {/* <span style={{ alignSelf: 'center', marginRight: '5px' }}>
                        {`${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`}
                      </span> */}
                      <span style={{ fontStyle: 'italic', alignSelf: 'center' }}>
                        {`${timelogSalaryPeriod.daysTillDeadline} pv`}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <Button
              startIcon={<AddIcon />}
              className="employer-accept-button"
              style={{
                margin: '10px',
                width: 'fit-content',
                height: '36px',
              }}
              onClick={() => this.showNewAbsencePeriodDialog()}
            >
              Uusi poissaolo
            </Button>

            {/* <div>
              <Button
                type="button"
                onClick={() => this.toggleFilterDialog()}
                className="employer-reject-button mdc-button"
                startIcon={<FilterListIcon />}
                style={{
                  // width: '100%',
                  // maxWidth: '350px',
                  width: '335px',
                }}
              >
                Suodata
                {filterCount !== 0 && (
                  <span style={{ marginLeft: '5px' }}>
                    (
                      {filterCount}
                    )
                  </span>
                )}
              </Button>

              <IconButton
                style={{
                  display: uiStore.mobileMode ? 'none' : 'initial',
                  padding: 0,
                  width: '60px',
                  marginLeft: '20px',
                  height: '36px',
                }}
                className="employer-reject-button"
                onClick={() => this.toggleViewMode()}
                size="small"
              >
                {viewMode === 'table' ? <DashboardIcon fontSize="inherit" /> : <FormatListBulletedIcon fontSize="inherit" />}
              </IconButton>
            </div> */}
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              <Button
                className="employer-accept-button"
                style={{ height: '36px', width: '180px', marginLeft: mobileMode && '10px' }}
                onClick={() => { this.acceptChecked(); }}
                disabled={acceptMultipleDisabled || tableRowsSelected.length === 0}
              >
                Hyväksy valitut
              </Button>
            </div>
          </div>
        </div>
        <div>
          {/* Require originalWorkHoursWithMeta for the rendering here because otherwise FilterDialog renders with null originalWorkHoursWithMeta and would require some ComponentDidUpdate code */}
          {/* {originalWorkHoursWithMeta && (
            <FilterDialog
              open={filterDialogOpen}
              toggleDialog={this.toggleFilterDialog}
              originalData={originalWorkHoursWithMeta}
              dataTitle="Suodata tunnit"
              // dataTitle2="Suodata matkat"
              filterOptions={{
                // NOTE: Using lodash's get() let's you use nested attributes as a single string like so: 'work_order.date'
                // Multiple filters can be combined (OR operator) by separating the attrs with '||'
                filters,
                defaultFilters: [
                  {
                    // filter: 'work_hour.status.pending',
                    filter: {
                      key: 'work_hour.status',
                      value: 'pending',
                    },
                    // Required for generating buttons for these default filters so that users can turn them off when there's no matching data
                    // IMPORTANT: Needs to match the filterOptions.filters object
                    filterMeta: {
                      title: 'Tila',
                      key: 'work_hour.status',
                      translate: true,
                    },
                  },
                  {
                    // IMPORTANT: filter parts constructed with user-given strings need to be wrapped in single quote
                    // This is to prevent things from breaking when they use .
                    // filter: `accepting_employers.${currentUser.lastName}, ${currentUser.firstName}`,
                    filter: {
                      key: 'accepting_employers',
                      value: `${currentUser.lastName}, ${currentUser.firstName}`,
                    },
                    filterMeta: {
                      title: 'Kirjausten hyväksyjä',
                      key: 'accepting_employers',
                      translate: false,
                    },
                  },
                ],
              }}
              filterData={this.filterData}
              // filterData2={this.filterData2}
              translationPrefix="work_hour_filters"
              setActiveFilters={this.setActiveFilters}
              previousFilters={workHourFilters}
            />
          )} */}

          {!currentAbsenceEntries && (
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          )}

          {/* {viewMode === 'cards' && (
            <div className="employer-card-wrapper">
              {
                // !filterHours && sortedWorkHoursWithMeta?.map((workHourWithMeta) => <EmployerTimelogCard workHourWithMeta={workHourWithMeta} key={workHourWithMeta.work_hour.id} />)
                !filterHours && currentWorkHoursWithMeta && (
                  <TransitionedCards
                    sortedWorkHoursWithMeta={currentWorkHoursWithMeta}
                    updateWorkHour={this.updateWorkHourStatusAndComment}
                    openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                    closeRejectWorkHourDialog={this.closeRejectWorkHourDialog}
                    openEmployeeInfoDialog={this.openEmployeeInfoDialog}
                    openWorkOrderInfoDialog={this.openWorkOrderInfoDialog}
                    afterUpdate={this.processSelfUpdateResponse}
                    openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                    // daysTillDeadlines={daysTillDeadlines}
                  />
                )
              }
            </div>
          )} */}

          {currentAbsenceEntries && (
            <EmployerAbsenceTable
              absenceEntries={currentAbsenceEntries}
              currentFilters={currentFilters}
              // updateWorkHour={this.updateWorkHourStatusAndComment}
              rowsSelected={tableRowsSelected}
              setAbsenceEntriesSelected={this.setAbsenceEntriesSelected}
              // openRejectWorkHourDialog={this.openRejectWorkHourDialog}
              // columnVisibility={columnVisibility}
              // setColumnVisibility={this.setColumnVisibility}
              // afterUpdate={this.processSelfUpdateResponse}
              salaryPeriods={salaryPeriods}
              // openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
              // daysTillDeadlines={daysTillDeadlines}
              updateAbsenceEntry={this.updateAbsenceEntry}
              deleteAbsenceEntry={this.handleDeleteAbsenceEntry}
            />
          )}

          {/* {viewMode === 'cards' && currentWorkHoursWithMeta?.length === 0 && (
            <div style={{ padding: '20px' }}>Tuntikirjauksia ei löytynyt</div>
          )} */}

          {this.renderAbsencePeriodDialog()}
          {/* {this.renderRejectWorkHourDialog()}
          {this.renderWorkOrderTripAcceptanceDialog()}
          <EmployeeInfoDialog open={showEmployeeInfoDialog} employee={employeeInfoDialogData} onClose={this.closeEmployeeInfoDialog} />
          <WorkOrderInfoDialog open={showWorkOrderInfoDialog} workOrderInfo={workOrderInfoDialogData} onClose={this.closeWorkOrderInfoDialog} /> */}
        </div>
      </div>
    );
  }
}

export default EmployerAbsences;
