import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Icon,
  Button,
} from '@material-ui/core';

@inject('t')
@observer
class DeepViewHeader extends Component {
  render() {
    const { content, showPreviousView } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          backgroundColor: '#1E2330',
          color: 'var(--mdc-theme-primary, #6200ee)',
          padding: '15px',
        }}
      >
        <Button
          variant="text"
          startIcon={<Icon>arrow_left</Icon>}
          style={{
            color: 'var(--mdc-theme-primary, #6200ee)',
            fontSize: '20px',
            textTransform: 'none',
            textAlign: 'left',
            whiteSpace: 'break-spaces',
            hyphens: 'auto',
          }}
          onClick={() => showPreviousView()}
        >
          {content}
        </Button>
      </div>
    );
  }
}

export default DeepViewHeader;
