import { action, observable } from 'mobx';
import moment from '../utils/moment';
import CalendarEntry from '../models/CalendarEntry';

export default class WorkOrderInvitation {
  @observable id;

  @observable inviteDeadline;

  @observable responsibleEmployer;

  @observable userId;

  @observable workOrderId;

  @observable canParticipate;

  @observable calendarEntries = [];

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updateProperties(data) {
    // eslint-disable-line prefer-object-spread
    return Object.assign(this, data);
  }

  static toJson(o) {
    return {
      id: o.id,
      invite_deadline: o.inviteDeadline,
      user_id: o.userId,
      work_order_id: o.workOrderId,
      account_id: o.accountId,
      _destroy: o._destroy,
      calendar_entries_attributes: o.calendarEntries ? o.calendarEntries.map(CalendarEntry.toJson) : [],
    };
  }

  @action updatePropertiesFromJson(o) {
    const values = {
      id: o.id,
      inviteDeadline: moment(o.invite_deadline),
      userId: o.user_id,
      userFirstName: o.user_first_name,
      userLastName: o.user_last_name,
      userFullName: `${o.user_last_name}, ${o.user_first_name}`,
      workOrderId: o.work_order_id,
      canParticipate: o.can_participate,
      calendarEntries: o.calendar_entries ? o.calendar_entries.map(CalendarEntry.fromJsonProperties) : [],
    };

    return this.updateProperties(values);
  }

  static fromJsonProperties(o) {
    const workOrderInvitation = new WorkOrderInvitation({});
    workOrderInvitation.updatePropertiesFromJson(o);
    return workOrderInvitation;
  }
}
