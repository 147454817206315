import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { fromPromise } from 'mobx-utils';
import { when } from 'mobx';
// import MUIDataTable from "mui-datatables";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import { TextField } from '@rmwc/textfield';
import LoadingSpinner from '../shared/LoadingSpinner';
import EmployerTimelogTable from '../employer-timelog/employerTimelogTable';
import moment from '../utils/moment';

import './salaryPeriodProcessing.css';
import EmployerAbsenceTable from '../employer-absences/EmployerAbsenceTable';
import CalendarEntry from '../models/CalendarEntry';

const handleEnter = (e) => {
  if (e.key === 'Enter' && document.activeElement.tagName !== 'TEXTAREA') {
    document.activeElement.blur();
  }
};

// EmployeesTable.jsx tableSelectionButton()
const viewSelectionButton = (t, view, activeView, label, onClick) => (
  <Button
    className={activeView === view ? 'selected-table-selection-button' : 'table-selection-button'}
    onClick={() => onClick(view)}
    type="button"
    key={`${view}-button`}
  >
    {label}
    {/* {t(`employer_table.columnPresets.${preset}`)} */}
  </Button>
);

const textareaResize = (evt) => {
  // Need to reset the height to 1px since the minimum scrollHeight is the element height,
  // meaning we need to reset the height to recalculate the scrollHeight or it won't stop growing
  // eslint-disable-next-line no-param-reassign
  evt.target.style.height = '1px';
  // eslint-disable-next-line no-param-reassign
  evt.target.style.height = `${evt.target.scrollHeight}px`;
};

@inject('t', 'uiStore', 'timelogStore', 'absenceStore', 'salaryPeriodStore')
@observer
class SalaryPeriodProcessing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // showAddNewWorkOrder: false,
      // billingStatusDialogOpen: false,
      // openBilling: null,
      // workOrdersPerPage: this.defaultWorkOrdersPerPage,
      currentSalaryPeriods: null,
      selectedSalaryPeriod: null,
      columnVisibility: {},
      currentWorkHoursWithMeta: null,
      currentAbsenceEntries: null,
      lockingSalaryPeriod: false,
      closingSalaryPeriod: false,
      activeView: 'work-hour-report',
    };
  }

  componentDidMount() {
    // const { employerWorkOrderStore, uiStore: { currentView } } = this.props;

    // this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage, this.defaultSort);
    // if (employerWorkOrderStore.billingWorkOrderCache) {
    //   this.setState({
    //     workOrders: employerWorkOrderStore.billingWorkOrderCache.workOrders,
    //     workOrdersCount: employerWorkOrderStore.billingWorkOrderCache.count,
    //     offset: employerWorkOrderStore.billingWorkOrderCache.offset,
    //     workOrdersPerPage: employerWorkOrderStore.billingWorkOrderCache.limit,
    //     sort: employerWorkOrderStore.billingWorkOrderCache.sort,
    //   });
    // } else {
    //   this.queryWorkOrders(this.defaultOffset, this.defaultWorkOrdersPerPage, this.defaultSort);
    // }

    this.queryCurrentSalaryPeriods();
  }

  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateWindowDimensions);
  // }

  // updateWindowDimensions = () => {
  //   this.setState({ width: window.innerWidth });
  // }

  // loadWorkOrders() {
  //   const { employerWorkOrderStore } = this.props;
  //   employerWorkOrderStore.getMoreWorkOrders();
  // }

  // openBillingStatusDialog(billing, workOrderName) {
  //   this.setState({
  //     openBilling: { ...billing, workOrderName },
  //     billingStatusDialogOpen: true,
  //   });
  // }

  // handleBillingStatusDialogClose = () => {
  //   this.setState({
  //     openBilling: null,
  //     billingStatusDialogOpen: false,
  //   });
  // }

  setColumnVisibility = (columnVisibility) => {
    this.setState({ columnVisibility });
  }

  changePage = (page, sortOrder, rowsPerPage) => {
    // const { employerWorkOrderStore } = this.props;
    // this.setState({
    //   isLoading: true,
    // });

    const offset = page * rowsPerPage;
    this.queryWorkOrders(offset);
  };

  changeView = (view) => {
    console.log('Changing to view: ', view);
    this.setState({
      activeView: view,
    });
  }

  // changeSorting = (column, direction) => {
  //   let sort;

  //   if (column === 'billingId') {
  //     sort = `work_order.billing_id-${direction}`;
  //   } else if (!column.includes('.')) {
  //     // Assuming a work order column by default, we need the 'dot notation' here for postgreSQL query generation
  //     sort = `work_order.${column}-${direction}`;
  //   } else {
  //     sort = `${column}-${direction}`;
  //   }

  //   this.setState({
  //     sort,
  //   }, () => {
  //     // Re-query using the new sorting, starting at offset 0
  //     this.queryWorkOrders(0, null, sort);
  //   });
  // }

  // changeReport = (event, newValue) => {
  //   this.setState({
  //     selectedReport: newValue,
  //   });
  // };

  selectSalaryPeriod = (selectedId) => {
    const { currentSalaryPeriods, selectedSalaryPeriod } = this.state;
    if (selectedId !== selectedSalaryPeriod.id) {
      const foundSalaryPeriod = currentSalaryPeriods.find((period) => period.id === selectedId);

      this.setState({
        selectedSalaryPeriod: foundSalaryPeriod,
      }, () => {
        this.queryWorkHours(foundSalaryPeriod);
        this.queryAbsences(foundSalaryPeriod);
      });
    }
  }

  lockSelectedPeriod() {
    const { salaryPeriodStore } = this.props;
    const { selectedSalaryPeriod } = this.state;

    // A hack because pending setState apparently doesn't work in promise case
    this.setState({
      lockingSalaryPeriod: true,
    });

    const lockPromise = fromPromise(new Promise((resolve, reject) => salaryPeriodStore.lockSalaryPeriod(selectedSalaryPeriod.id, resolve, reject)));
    when(
      () => lockPromise.state !== 'pending',
      () => {
        lockPromise.case({
          pending: () => {
            this.setState({ lockingSalaryPeriod: true });
          },
          rejected: (err) => {
            this.setState({ lockingSalaryPeriod: false });
            console.error('ERROR: ', err);
          },
          fulfilled: (res) => {
            const { currentSalaryPeriods } = this.state;

            const newPeriod = { ...res, salary_period_message_content: selectedSalaryPeriod.salary_period_message_content };
            const updatedCurrentSalaryPeriods = [...currentSalaryPeriods];
            const foundIndex = updatedCurrentSalaryPeriods.findIndex((sp) => res.id === sp.id);
            if (foundIndex !== -1) {
              updatedCurrentSalaryPeriods[foundIndex] = newPeriod;
            }
            // Update the salary period state
            this.setState({
              lockingSalaryPeriod: false,
              // Do not overwrite first message with null (not included in response)
              currentSalaryPeriods: updatedCurrentSalaryPeriods,
              selectedSalaryPeriod: newPeriod,
            });
          },
        });
      },
    );
  }

  closeSelectedPeriod() {
    const { salaryPeriodStore } = this.props;
    const { selectedSalaryPeriod } = this.state;

    // A hack because pending setState apparently doesn't work in promise case
    this.setState({
      closingSalaryPeriod: true,
    });

    const closePromise = fromPromise(new Promise((resolve, reject) => salaryPeriodStore.closeSalaryPeriod(selectedSalaryPeriod.id, resolve, reject)));
    when(
      () => closePromise.state !== 'pending',
      () => {
        closePromise.case({
          pending: () => {
            this.setState({ closingSalaryPeriod: true });
          },
          rejected: (err) => {
            this.setState({ closingSalaryPeriod: false });
            console.error('ERROR: ', err);
          },
          fulfilled: (res) => {
            const { currentSalaryPeriods } = this.state;

            const newPeriod = { ...res, salary_period_message_content: selectedSalaryPeriod.salary_period_message_content };
            const updatedCurrentSalaryPeriods = [...currentSalaryPeriods];
            const foundIndex = updatedCurrentSalaryPeriods.findIndex((sp) => res.id === sp.id);
            if (foundIndex !== -1) {
              updatedCurrentSalaryPeriods[foundIndex] = newPeriod;
            }
            // Update the salary period state
            this.setState({
              closingSalaryPeriod: false,
              // Do not overwrite first message with null (not included in response)
              currentSalaryPeriods: updatedCurrentSalaryPeriods,
              selectedSalaryPeriod: newPeriod,
            });
          },
        });
      },
    );
  }

  handleBlur() {
    const { salaryPeriodStore } = this.props;
    const { selectedSalaryPeriod } = this.state;

    const updateMessagePromise = fromPromise(new Promise((resolve, reject) => salaryPeriodStore.updateMessage(selectedSalaryPeriod.id, selectedSalaryPeriod.salary_period_message_content, resolve, reject)));
    when(
      () => updateMessagePromise.state !== 'pending',
      () => {
        updateMessagePromise.case({
          pending: () => { },
          rejected: (err) => {
            console.error('ERROR: ', err);
          },
          fulfilled: (res) => {
            // Update the salary period state or something
            this.setState({
              selectedSalaryPeriod: { ...selectedSalaryPeriod, salary_period_message_content: res.content },
            });
          },
        });
      },
    );
  }

  addFirstMessageField(label, required, styles = null, textarea = false, extraOnChange = null, disabled = false) {
    const { selectedSalaryPeriod, errors } = this.state;

    return (
      <TextField {...{
        style: { ...styles },
        className: disabled ? 'disabled-employer-text-field' : null,
        textarea,
        label,
        required,
        name: 'first-message',
        // invalid: errors[attr],
        // helpText: errors[attr] && errors[attr],
        type: 'text',
        disabled,
        onChange: (event) => {
          const { value } = event.target;
          // const messages = [{ content: value }];
          // delete errors[attr]
          this.setState({
            selectedSalaryPeriod: { ...selectedSalaryPeriod, salary_period_message_content: value },
            // errors: { ...errors, [attr]: null }
            errors: { ...errors },
          });
          if (extraOnChange) {
            extraOnChange(event);
          }
        },
        onBlur: (event) => {
          // required && this.handleBlur(attr, event)
          this.handleBlur(event);
        },
        rootProps: { ripple: false },
        onKeyPress: handleEnter,
        // eslint-disable-next-line camelcase
        value: selectedSalaryPeriod?.salary_period_message_content != null ? selectedSalaryPeriod?.salary_period_message_content : '',
      }}
      />
    );
  }

  queryAbsences(salaryPeriod) {
    const { absenceStore } = this.props;

    this.setState({
      currentAbsenceEntries: null,
    }, () => {
      absenceStore.getEmployerAbsenceEntriesBySalaryPeriod(salaryPeriod).then((data) => {
        // const salaryPeriods = [];
        this.setState({
          currentAbsenceEntries: data.map((entry) => ({ ...entry, calendar_entry: CalendarEntry.fromJsonProperties(entry.calendar_entry) })),
        });
      });
    });
  }

  queryWorkHours(salaryPeriod) {
    const { timelogStore } = this.props;

    this.setState({
      currentWorkHoursWithMeta: null,
    }, () => {
      timelogStore.getEmployerTimelogsByDates(salaryPeriod).then((data) => {
        // const salaryPeriods = [];
        this.setState({
          currentWorkHoursWithMeta: data,
        });
      });
    });
  }

  // Version compatible with querying work hours by arbitrary dates
  // 1. retrieve salary periods
  // 2. use salary period dates (or any other date) to query work hours
  queryCurrentSalaryPeriods() {
    const { salaryPeriodStore } = this.props;

    const salaryPeriodsPromise = fromPromise(new Promise((resolve, reject) => salaryPeriodStore.getCurrentSalaryPeriods(resolve, reject)));

    when(
      () => salaryPeriodsPromise.state !== 'pending',
      () => {
        salaryPeriodsPromise.case({
          pending: () => {
            // this.setState({ isLoading: true });
          },
          rejected: (err) => {
            // this.setState({ isLoading: false });
            console.error('ERROR: ', err);
          },
          fulfilled: (res) => {
            const defaultSalaryPeriodCategory = res.salary_period_categories.find((cat) => cat.is_default);
            const defaultSalaryPeriod = res.current_salary_periods.find((sp) => sp.salary_period_category_id === defaultSalaryPeriodCategory.id);
            this.setState({
              // isLoading: false,
              salaryPeriodCategories: res.salary_period_categories,
              currentSalaryPeriods: res.current_salary_periods,
              selectedSalaryPeriod: defaultSalaryPeriod,
            }, () => {
              const { selectedSalaryPeriod } = this.state;
              const { uiStore: { currentUser: { accountInfo: { absencesEnabled } } } } = this.props;
              // if (activeView === 'work-hour-report') {
              //   // Query work hours
              //   this.queryWorkHours(selectedSalaryPeriod);
              // } else if (absencesEnabled && activeView === 'absence-report') {
              //   this.queryAbsences(selectedSalaryPeriod);
              // }
              // Query other currently open reports here

              this.queryWorkHours(selectedSalaryPeriod);
              if (absencesEnabled) {
                this.queryAbsences(selectedSalaryPeriod);
              }
            });
          },
        });
      },
    );
  }

  renderMenuItem(salaryPeriod) {
    const { salaryPeriodCategories } = this.state;
    const foundCategory = salaryPeriodCategories.find((cat) => cat.id === salaryPeriod.salary_period_category_id);
    if (foundCategory.name_short) {
      return (
        `${foundCategory.name_short}, ${moment(salaryPeriod.from).format('dd D.M.')} - ${moment(salaryPeriod.to).format('dd D.M.')}`
      );
    }
    return (
      `${moment(salaryPeriod.from).format('dd D.M.')} - ${moment(salaryPeriod.to).format('dd D.M.')}`
    );
  }

  renderLockSalaryPeriodDialog() {
    const { showLockSalaryPeriodDialog, lockSalaryPeriodError } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showLockSalaryPeriodDialog}
        onClose={() => this.setState({ showLockSalaryPeriodDialog: false })}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <p>Haluatko varmasti lukita palkkakauden?</p>
          {lockSalaryPeriodError && (
            <p style={{ color: 'red' }}>
              {lockSalaryPeriodError}
            </p>
          )}
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => {
              this.setState({ showLockSalaryPeriodDialog: false });
              this.lockSelectedPeriod();
            }}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => this.setState({ showLockSalaryPeriodDialog: false })}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  renderCloseSalaryPeriodDialog() {
    const { showCloseSalaryPeriodDialog, closeSalaryPeriodError } = this.state;

    return (
      <Dialog
        className="employer-delete-modal"
        open={showCloseSalaryPeriodDialog}
        onClose={() => this.setState({ showCloseSalaryPeriodDialog: false })}
      >
        <DialogContent
          style={{ fontSize: '16px' }}
        >
          <p>Haluatko varmasti päättää palkkakauden?</p>
          {closeSalaryPeriodError && (
            <p style={{ color: 'red' }}>
              {closeSalaryPeriodError}
            </p>
          )}
        </DialogContent>

        <DialogActions
          style={{
            justifyContent: 'space-between',
            padding: '15px',
          }}
        >
          <Button
            type="button"
            className="employer-accept-button"
            onClick={() => {
              this.setState({ showCloseSalaryPeriodDialog: false });
              this.closeSelectedPeriod();
            }}
          >
            Kyllä
          </Button>

          <Button
            type="button"
            className="employer-reject-button"
            onClick={() => this.setState({ showCloseSalaryPeriodDialog: false })}
          >
            Peruuta
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    const { t, uiStore: { currentUser: { accountInfo: { absencesEnabled } } } } = this.props;
    const {
      selectedSalaryPeriod,
      columnVisibility,
      currentWorkHoursWithMeta,
      currentAbsenceEntries,
      lockingSalaryPeriod,
      closingSalaryPeriod,
      currentSalaryPeriods,
      activeView,
    } = this.state;

    const textareaStyles = {
      backgroundColor: '#FFFFFF',
      width: '100%',
      margin: '0.3em 0',
      whiteSpace: 'pre',
    };

    return (
      <>
        <div className="salary-period-processing-actions">
          {currentSalaryPeriods && currentSalaryPeriods.length > 1 && (
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Palkkakausi
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedSalaryPeriod?.id}
                onChange={(event) => {
                  this.selectSalaryPeriod(event.target.value);
                }}
              // disabled
              >
                {currentSalaryPeriods.map((salaryPeriod) => (
                  <MenuItem value={salaryPeriod.id} style={{ display: 'block', padding: '10px' }}>
                    {/* {`${salaryPeriod.from} - ${salaryPeriod.to}`} */}
                    {/* {`${salaryPeriod.nameShort}, ${moment(timelogSalaryPeriod.from).format('dd D.M.')} - ${moment(timelogSalaryPeriod.to).format('dd D.M.')}`} */}
                    {this.renderMenuItem(salaryPeriod)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {currentSalaryPeriods && currentSalaryPeriods.length === 1 && (
            <div style={{ fontSize: '16px', textTransform: 'capitalize' }}>
              {`${moment(currentSalaryPeriods[0].from).format('dd D.M.')} - ${moment(currentSalaryPeriods[0].to).format('dd D.M.')}`}
            </div>
          )}

          {/* RMWC Select */}
          {/* <Select
            // style={{ top: 'auto !important' }}
            {...{
              className: 'ml-1 pk-select profile-input-95-view-width no-border-bottom label-style', // mdc-theme--text-primary-on-dark
              label: 'Palkkakausi',
              // onBlur: () => {
              //   if (this.__original__.role !== employee.role) {
              //     this.selectSalaryPeriod(employee);
              //   }
              // },
              onChange: (event) => {
                this.selectSalaryPeriod(event.target.value); // eslint-disable-line no-param-reassign
              },
              // onInput: (event) => {
              //   if (this.__original__.role !== event.target.value) {
              //     employee.role = event.target.value; // eslint-disable-line no-param-reassign
              //     setUserChanged(employee);
              //   }
              // },
              // defaultChecked: this.__original__?.role || '-',
              rootProps: { ripple: false },
              value: selectedSalaryPeriod?.id,
            }}
          >
            {currentSalaryPeriods && currentSalaryPeriods.map((salaryPeriod) => (
              <option value={salaryPeriod.id} label={`${salaryPeriod.from} - ${salaryPeriod.to}`} />
            ))}
          </Select> */}

          {selectedSalaryPeriod && selectedSalaryPeriod.status === 'opened' && (
            <Button
              className="employer-accept-button"
              style={{ height: '36px', width: '180px' }}
              onClick={() => this.setState({ showLockSalaryPeriodDialog: true })}
              disabled={lockingSalaryPeriod}
            >
              {lockingSalaryPeriod ? <LoadingSpinner color="black" size="mini" /> : 'Lukitse'}
            </Button>
          )}

          {selectedSalaryPeriod && selectedSalaryPeriod.status === 'locked' && (
            <Button
              className="employer-accept-button"
              style={{ height: '36px', width: '180px' }}
              onClick={() => this.setState({ showCloseSalaryPeriodDialog: true })}
              disabled={closingSalaryPeriod}
            >
              {closingSalaryPeriod ? <LoadingSpinner color="black" size="mini" /> : 'Päätä'}
            </Button>
          )}

          {selectedSalaryPeriod && selectedSalaryPeriod.status === 'closed' && (
            <Button
              style={{ height: '36px', width: '180px' }}
              disabled
            >
              Päätetty
            </Button>
          )}
        </div>

        {/* <div>
          {selectedSalaryPeriod && (
            <div>{`${selectedSalaryPeriod.from} - ${selectedSalaryPeriod.to}`}</div>
          )}
        </div> */}

        {/* <div className="salary-period-processing-tabs">
          <Tabs
            value={selectedReport}
            onChange={this.changeReport}
            variant="fullWidth"
            // indicatorColor="secondary"
            // textColor="secondary"
            aria-label="icon label tabs"
          >
            <Tab icon={<AccessTimeIcon />} label="Tuntiraportti" />
            <Tab icon={<DirectionsCarIcon />} label="Matkaraportti" disabled />
            <Tab
                icon={
                  <SvgIcon>
                    {bankIcon}
                  </SvgIcon>
                }
                label="Työaikapankkiraportti"
                disabled
              />
          </Tabs>
        </div> */}

        <div style={{ display: 'inline-block', width: '100%', marginTop: '20px' }}>
          {viewSelectionButton(t, 'work-hour-report', activeView, 'Tuntiraportti', this.changeView)}
          {absencesEnabled && viewSelectionButton(t, 'absence-report', activeView, 'Poissaoloraportti', this.changeView)}
        </div>

        {selectedSalaryPeriod && selectedSalaryPeriod.status !== 'opened' && (
          <div className="salary-period-processing-first-message" style={{ alignSelf: 'start', marginLeft: '20px', width: '500px' }}>
            {this.addFirstMessageField('Lisätietoa palkanlaskijalle', false, textareaStyles, true, textareaResize, selectedSalaryPeriod.status === 'closed')}
          </div>
        )}

        {activeView === 'work-hour-report' && (
          <div style={{ width: '100%' }}>
            {currentWorkHoursWithMeta && (
              <EmployerTimelogTable
                workHours={currentWorkHoursWithMeta}
                // currentFilters={currentFilters}
                // updateWorkHour={this.updateWorkHourStatusAndComment}
                // rowsSelected={tableRowsSelected}
                // setWorkHoursSelected={this.setWorkHoursSelected}
                // openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                columnVisibility={columnVisibility}
                setColumnVisibility={this.setColumnVisibility}
                // afterUpdate={this.processSelfUpdateResponse}
                salaryPeriods={[]}
                // openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                disableActions
              />
            )}
            {!currentWorkHoursWithMeta && (
              <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            )}
          </div>
        )}

        {activeView === 'absence-report' && (
          <div style={{ width: '100%' }}>
            {currentAbsenceEntries && (
              <EmployerAbsenceTable
                absenceEntries={currentAbsenceEntries}
                // currentFilters={currentFilters}
                // updateWorkHour={this.updateWorkHourStatusAndComment}
                // rowsSelected={tableRowsSelected}
                // setWorkHoursSelected={this.setWorkHoursSelected}
                // openRejectWorkHourDialog={this.openRejectWorkHourDialog}
                // columnVisibility={columnVisibility}
                // setColumnVisibility={this.setColumnVisibility}
                // afterUpdate={this.processSelfUpdateResponse}
                salaryPeriods={[]}
                // openWorkOrderTripAcceptanceDialog={this.openWorkOrderTripAcceptanceDialog}
                disableActions
              />
            )}
            {!currentWorkHoursWithMeta && (
              <div style={{ width: '100%', paddingTop: '20px', textAlign: 'center' }}>
                <LoadingSpinner color="black" />
              </div>
            )}
          </div>
        )}
        {/* {this.renderBillingTable()} */}
        {this.renderLockSalaryPeriodDialog()}
        {this.renderCloseSalaryPeriodDialog()}
      </>
    );
  }
}

export default SalaryPeriodProcessing;
