import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import {
  Card, CardActions, CardActionIcon, CardActionButtons, CardActionButton, CardActionIcons,
} from '@rmwc/card';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import { Icon } from '@material-ui/core';

import { buttonColors } from '../calendar/EmployeeCalendar';

@inject('employerWorkOrderStore', 'uiStore', 't')
@observer
class BillingAttachmentCard extends Component {
  handleStatusChange(id) {
    const { employerWorkOrderStore, updateBillingAttachmentState } = this.props;
    employerWorkOrderStore.acceptBillingAttachment(id, updateBillingAttachmentState);
  }

  render() {
    const {
      attachment, attachmentType, handleCardClick, confirmDelete, processing, showFilePreviewDialog,
    } = this.props;

    return (
      <Card
        style={{ backgroundColor: 'white', width: '100%', cursor: 'pointer' }}
        // onClick={!processing && attachmentType !== 'salaryPeriod' ? () => handleCardClick(attachment, attachmentType) : null}
        onClick={attachmentType === 'billing' ? () => handleCardClick(attachment, attachmentType) : () => showFilePreviewDialog(attachment.files[0])}
      >
        <CardActions>
          <CardActionButtons>
            {attachmentType === 'salaryPeriod' && <Icon style={{ color: 'black' }}>access_time</Icon>}
            <div
              style={{
                fontSize: '14px',
                padding: '0px 8px',
              }}
            >
              {attachmentType === 'billing' ? attachment.name : 'Tuntikirjausten liite'}
            </div>
          </CardActionButtons>

          {!processing && attachmentType === 'billing' && (
            <CardActionIcons>
              <CardActionIcon
                icon="delete_outlined"
                onClick={(e) => {
                  confirmDelete('attachment', attachment.id, attachment.workOrderId, attachment.name);
                  e.stopPropagation();
                }}
              />
            </CardActionIcons>
          )}
        </CardActions>

        {attachment.description && (
          <div style={{ fontSize: '14px', padding: '0px 16px 5px 16px', color: 'rgba(0, 0, 0, 0.7)' }}>{attachment.description}</div>
        )}

        {attachment.files.map((file, index) => (
          <div
            key={file.signedId}
            role="button"
            style={{
              fontSize: '12px',
              margin: (attachment.files.length === 0 || index > 0) ? '0 0 15px 0' : '15px 0',
              padding: '0 16px',
              color: 'rgba(0, 0, 0, 0.7)',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <span>Liite&nbsp;</span>
              <span style={{ fontStyle: 'italic' }}>
                {file.filename}
              </span>
            </span>
            {attachmentType === 'billing' && (
              <span
                style={{ color: 'black' }}
                onClick={(e) => {
                  e.stopPropagation();
                  showFilePreviewDialog(file);
                }}
                role="button"
              >
                Katso
              </span>
            )}
          </div>
        ))}

        <Grid
          style={{
            width: '100%',
            padding: '0px 16px 16px',
          }}
        >
          <GridInner
            style={{ rowGap: '4px' }}
          >
            {/* <GridCell
                span={12}
                style={{ fontSize: '14px' }}
              >
                {attachment.name}
              </GridCell> */}

            {/* <GridCell
              span={12}
              style={{
                textAlign: 'center',
                fontSize: '14px',
                justifySelf: 'right',
              }}
            >
              <div
                style={{
                  minWidth: '100px',
                  color: attachment.status !== 'accepted' ? buttonColors.orange.color : buttonColors.green.color,
                  fontWeight: 'bold',
                }}
              >
                {attachmentType === 'billing' ? (
                  <span>
                    {attachment.status !== 'accepted' ? 'Odottaa' : 'Käsitelty'}
                  </span>
                ) : (
                  <span />
                )}
              </div>
            </GridCell> */}

            <GridCell
              span={12}
            >
              <GridInner>
                <GridCell
                  desktop={6}
                  tablet={4}
                  phone={2}
                  style={{
                    alignSelf: 'flex-end',
                    fontSize: '12px',
                  }}
                >
                  <GridCell
                    desktop={12}
                    tablet={8}
                    phone={4}
                  >
                    {/* <span>
                      {`Lisätty ${attachment.createdAt.format('dd DD.MM.YY HH.mm')}`}
                    </span> */}
                    <span>
                      Lisätty:&nbsp;
                    </span>
                    <span>
                      {`${attachment.uploadedBy.firstName[0]}. ${attachment.uploadedBy.lastName}`}
                    </span>
                  </GridCell>

                  <GridCell
                    desktop={12}
                    tablet={8}
                    phone={4}
                  >
                    {/* <span>
                      {`${attachment.uploadedBy.firstName} ${attachment.uploadedBy.lastName}`}
                    </span> */}
                    <span style={{ textTransform: 'capitalize' }}>
                      {attachment.createdAt.format('dd DD.MM.YY HH.mm')}
                    </span>
                  </GridCell>
                </GridCell>
                <GridCell
                  desktop={6}
                  tablet={4}
                  phone={2}
                  style={{
                    textAlign: 'right',
                    fontSize: '12px',
                  }}
                >
                  {attachment.closedBy ? (
                    <>
                      <GridCell
                        desktop={12}
                        tablet={8}
                        phone={4}
                      >
                        <span style={{ color: buttonColors.green.color }}>
                          Käsitelty:&nbsp;
                        </span>
                        <span>
                          {`${attachment.closedBy.firstName[0]}. ${attachment.closedBy.lastName}`}
                        </span>
                      </GridCell>
                      <GridCell
                        desktop={12}
                        tablet={8}
                        phone={4}
                      >
                        <span style={{ textTransform: 'capitalize' }}>
                          {attachment.closedAt.format('dd DD.MM.YY HH.mm')}
                        </span>
                      </GridCell>
                    </>
                  ) : (
                    <div
                      style={{
                        minWidth: '100px',
                        color: attachment.status !== 'accepted' ? buttonColors.orange.color : buttonColors.green.color,
                        fontWeight: 'bold',
                      }}
                    >
                      {attachmentType === 'billing' ? (
                        <span>
                          {attachment.status !== 'accepted' ? 'Odottaa' : 'Käsitelty'}
                        </span>
                      ) : (
                        <span />
                      )}
                    </div>
                  )}

                </GridCell>
              </GridInner>
            </GridCell>
          </GridInner>
        </Grid>
        {processing && (
          <CardActions>
            {attachment.status !== 'accepted' && (
              <CardActionButtons
                style={{
                  padding: '0px 8px',
                }}
              >
                <CardActionButton
                  style={{
                    border: '1px solid',
                    borderRadius: '0px',
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleStatusChange(attachment.id);
                  }}
                >
                  Käsittele
                </CardActionButton>
              </CardActionButtons>
            )}
          </CardActions>
        )}

      </Card>
    );
  }
}

export default BillingAttachmentCard;
