import { action, observable } from 'mobx';
import moment from '../utils/moment';
import User from '../models/User';

export default class SalaryPeriodAttachment {
  @observable id;

  @observable salaryPeriodId;

  @observable updatedAt;

  @observable createdAt;

  @observable files = [];

  // @observable uploaderId;

  // @observable uploaderName;

  @observable uploadedBy;

  constructor(data) {
    // eslint-disable-line prefer-object-spread
    Object.assign(this, data);
  }

  @action updatePropertiesFromJson(o) {
    const updatedAt = moment(o.updated_at).local();

    if (this.updatedAt != null && this.updatedAt.isAfter(updatedAt)) {
      return;
    }

    const values = {
      updatedAt,
      createdAt: moment(o.created_at).local(),
      filename: o.filename,
      fileUrl: o.file_url,
      id: o.id,
      salaryPeriodId: o.salary_period_id,
      // uploaderId: o.uploader_id,
      // uploaderName: o.uploader_name,
      uploadedBy: User.fromJsonProperties(o.uploaded_by),
      userId: o.user_id,
      workOrderId: o.work_order_id,
      // A transitionary object for unifying billing attachments and these (for employer billing views)
      files: [{
        signedId: o.signed_id,
        createdAt: moment(o.created_at).local(),
        filename: o.filename,
        fileURL: o.file_url,
      }],
    };

    Object.assign(this, values);
  }

  static fromJsonProperties(object) {
    const attachment = new SalaryPeriodAttachment({});
    attachment.updatePropertiesFromJson(object);
    return attachment;
  }

  static fromJson(json) {
    return SalaryPeriodAttachment.fromJsonProperties(JSON.parse(json));
  }
}
