/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Grid, GridInner, GridCell } from '@rmwc/grid';
import SalaryPeriodProcessing from './SalaryPeriodProcessing';
import SalaryPeriodHistory from './SalaryPeriodHistory';
import TimelogReport from '../timelog-report';

@inject('t', 'uiStore', 'timelogStore', 'absenceStore', 'salaryPeriodStore')
@observer
class SalaryPeriodProcessingIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTabIndex: 0,
    };
  }

  selectTab(index) {
    this.setState({ activeTabIndex: index });
  }

  render() {
    const { t } = this.props;
    const { activeTabIndex } = this.state;

    // const showTimelogReport = new URLSearchParams(window.location.search).get('report');

    return (
      <>
        <Grid
          style={{ width: '100%', height: '100%', padding: '0 0 16px 0' }}
        >
          <GridInner
            style={{ rowGap: '0px' }}
          >
            <GridCell
              span={12}
            >
              <div
                className="view-heading"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <span
                    role="button"
                    onClick={() => this.selectTab(0)}
                    className={`heading-tab ${activeTabIndex === 0 ? 'active' : 'inactive'}`}
                    style={{ marginLeft: 0 }}
                  >
                    {t('view_titles.salary-period-processing')}
                  </span>
                  <span role="button" onClick={() => this.selectTab(1)} className={`heading-tab ${activeTabIndex === 1 ? 'active' : 'inactive'}`}>
                    {t('view_titles.salary-period-history')}
                  </span>
                  {/* {showTimelogReport && ( */}
                  <span role="button" onClick={() => this.selectTab(2)} className={`heading-tab ${activeTabIndex === 2 ? 'active' : 'inactive'}`}>
                    {t('view_titles.timelog-report')}
                  </span>
                  {/* )} */}
                </div>
              </div>
            </GridCell>
            <GridCell
              span={12}
            >
              {activeTabIndex === 0 && <SalaryPeriodProcessing />}
              {activeTabIndex === 1 && <SalaryPeriodHistory />}
              {activeTabIndex === 2 && <TimelogReport />}
            </GridCell>
          </GridInner>
        </Grid>
      </>
    );
  }
}

export default SalaryPeriodProcessingIndex;
