import { observable, action } from 'mobx';
import { dateWithoutTime } from '../utils/moment';

export default class Availability {
  @observable id;

  @observable available;

  @observable from;

  @observable to;

  constructor(object) {
    this.updateProperties(object);
  }

  @action updatePropertiesFromJson(object) {
    this.updateProperties({
      id: object.id,
      available: object.available,
      from: dateWithoutTime(object.from),
      to: dateWithoutTime(object.to),
    });
  }

  @action updateProperties({
    id, available, from, to,
  }) {
    this.id = id;
    this.available = available;
    this.from = from;
    this.to = to;
  }

  static fromJsonProperties(object) {
    const user = new Availability({});
    user.updatePropertiesFromJson(object);
    return user;
  }

  static fromJson(json) {
    return Availability.fromJsonProperties(JSON.parse(json));
  }
}
