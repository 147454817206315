/* eslint-disable */
import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import {
//   Dialog, DialogActions, Button, DialogContent, DialogTitle,
// } from '@material-ui/core';
// import { ThemeProvider } from '@rmwc/theme';
// import { Button } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@rmwc/dialog';
import { Button } from '@rmwc/button';
import SalaryPeriod from '../timelog/SalaryPeriod';
import WorkOrder from '../calendar/WorkOrder';
import WorkOrderTrip from '../timelog/WorkOrderTrip';
import LoadingSpinner from '../shared/LoadingSpinner';
import TimelogView from '../timelog/TimelogView';
import Theme from '../Theme';
import './employerTimelogViewDialog.css';

@inject('t', 'employerContextStore', 'timelogStore')
@observer
class EmployerTimelogViewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      workOrderWithSalaryPeriod: null,
      workOrderTrips: [],
      bulkUpdatePackages: {},
      showFullNestedDialog: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { employerContextStore, open, workOrderIds, workOrderTripId, employeeId } = this.props;

    if (open && !prevProps.open) {
      this.setState({
        workOrdersWithSalaryPeriod: null,
        workOrderTrips: null,
      });

      // Set the employee id for work hour and work order trip-related database queries. We want to create them for employee, not the employer
      employerContextStore.setCurrentEmployeeId(employeeId);
      if (workOrderTripId) {
        employerContextStore.getWorkOrderTripContext(employeeId, workOrderTripId).then((result) => {
          // const salaryPeriod = SalaryPeriod.fromJsonProperties(result.current_salary_period);
          // const workOrder = WorkOrder.fromJsonProperties(result.work_order);
          // const workOrderWithSalaryPeriod = employerContextStore.setSalaryPeriodToWorkOrder(workOrder, salaryPeriod);
          // const workOrderTrips = result.work_order_trips.map((trip_json) => (WorkOrderTrip.fromJsonProperties(trip_json)));

          // this.setState({
          //   workOrderWithSalaryPeriod: workOrderWithSalaryPeriod,
          //   workHours: workOrderWithSalaryPeriod.workOrder.workHours,
          //   workOrderTrips: workOrderTrips,
          // });

          // const salaryPeriod = SalaryPeriod.fromJsonProperties(result.current_salary_period);
          // // const workOrder = WorkOrder.fromJsonProperties(result.work_order);
          // const workOrdersWithSalaryPeriod = result.work_orders.map((workOrder) => {
          //   const workOrderFromJson = WorkOrder.fromJsonProperties(workOrder);
          //   return employerContextStore.setSalaryPeriodToWorkOrder(workOrderFromJson, salaryPeriod)
          // });
          // // const workOrdersWithSalaryPeriod = employerContextStore.setSalaryPeriodToWorkOrder(workOrder, salaryPeriod);
          // const workOrderTrips = result.work_order_trips.map((trip_json) => (WorkOrderTrip.fromJsonProperties(trip_json)));

          // this.setState({
          //   workOrdersWithSalaryPeriod: workOrdersWithSalaryPeriod,
          //   workOrderTrips: workOrderTrips,
          // });
          this.handleContextQueryResult(result);
        });
      } else {
        employerContextStore.getWorkOrdersContext(employeeId, workOrderIds).then((result) => {
          this.handleContextQueryResult(result);
          // const salaryPeriod = SalaryPeriod.fromJsonProperties(result.current_salary_period);
          // // const workOrder = WorkOrder.fromJsonProperties(result.work_order);
          // const workOrdersWithSalaryPeriod = result.work_orders.map((workOrder) => {
          //   const workOrderFromJson = WorkOrder.fromJsonProperties(workOrder);
          //   return employerContextStore.setSalaryPeriodToWorkOrder(workOrderFromJson, salaryPeriod)
          // });
          // // const workOrdersWithSalaryPeriod = employerContextStore.setSalaryPeriodToWorkOrder(workOrder, salaryPeriod);
          // const workOrderTrips = result.work_order_trips.map((trip_json) => (WorkOrderTrip.fromJsonProperties(trip_json)));

          // this.setState({
          //   workOrdersWithSalaryPeriod: workOrdersWithSalaryPeriod,
          //   workOrderTrips: workOrderTrips,
          // });
        });
      }
    }
    // else if (!open && prevProps.open) {
    //   console.log('closing and nulling current employee id');
    //   // Null the employee ID on dialog close so that employer's own work hours/trips work
    //   employerContextStore.setCurrentEmployeeId(null);
    //   // this.setState({
    //   //   workOrder: null,
    //   //   workOrderTrips: null,
    //   // });
    // }
  }

  componentWillUnmount() {
    const { employerContextStore } = this.props;
    employerContextStore.setCurrentEmployeeId(null);
  }

  handleContextQueryResult(result) {
    const { employerContextStore } = this.props;

    const salaryPeriod = SalaryPeriod.fromJsonProperties(result.current_salary_period);
    // const workOrder = WorkOrder.fromJsonProperties(result.work_order);
    const workOrdersWithSalaryPeriod = result.work_orders.map((workOrder) => {
      const workOrderFromJson = WorkOrder.fromJsonProperties(workOrder);
      return employerContextStore.setSalaryPeriodToWorkOrder(workOrderFromJson, salaryPeriod)
    });
    // const workOrdersWithSalaryPeriod = employerContextStore.setSalaryPeriodToWorkOrder(workOrder, salaryPeriod);
    const workOrderTrips = result.work_order_trips.map((trip_json) => (WorkOrderTrip.fromJsonProperties(trip_json)));

    this.setState({
      workOrdersWithSalaryPeriod: workOrdersWithSalaryPeriod,
      workOrderTrips: workOrderTrips,
    });
  }

  resetUpdatedByEmployer = (updatedWorkHours) => {
    const { workOrdersWithSalaryPeriod } = this.state;
    const updatedWorkOrderProps = [...workOrdersWithSalaryPeriod];

    updatedWorkHours.forEach((updatedWorkHour) => {
      const foundWorkOrder = updatedWorkOrderProps.find((woWithSalaryPeriod) => woWithSalaryPeriod.workOrder.id === updatedWorkHour.workOrderId);
      const oldWorkHourIndex = foundWorkOrder?.workOrder.workHours.findIndex((workHour) => workHour.date === updatedWorkHour.date);
      if (oldWorkHourIndex !== -1) {
        foundWorkOrder.workOrder.workHours[oldWorkHourIndex].updatedByEmployer = false;
      } else {
        console.error('Work hour not found by index');
      }
    });

    this.setState((prevProps) => ({
      // workOrderWithSalaryPeriod: {...prevProps.workOrderWithSalaryPeriod, updatedWorkOrderProp},
      workOrdersWithSalaryPeriod: updatedWorkOrderProps,
    }));
  }

  buildBulkUpdatePackage = (workOrderParam, workHours, employerContextTripId) => {
    this.setState((prevState) => ({
      bulkUpdatePackages: {
        ...prevState.bulkUpdatePackages,
        [workOrderParam.id]: {
          workOrderParam,
          workHours,
          employerContextTripId,
        },
      }
    }));
  }

  onClose() {
    const { onClose } = this.props;

    this.setState({
      // Reset the bulk update packages that were waiting to be saved
      bulkUpdatePackages: {},
      // Reset hiding the title and action buttons, just to minimize the chances of user being locked out of actions
      showFullNestedDialog: false,
    });

    onClose();
  }

  // A hack to fix the issues caused by mdc vs rmwc nested dialogs (this dialog + TimelogView -> TripRoute dialog)
  // Without manually hiding this dialog's title and actions elements they cover the TripRoute dialog elements make it unusable in iOS Safari
  toggleShowFullNestedDialog = () => {
    this.setState((prevState) => ({
      showFullNestedDialog: !prevState.showFullNestedDialog,
    }));
  }

  render() {
    // const { t } = this.props;
    const { open, employerContextStore, workOrderTripId, initialTabIndex, timelogStore, highlightWorkHourId, afterUpdate } = this.props;
    const { workOrdersWithSalaryPeriod, workOrderTrips, bulkUpdatePackages, showFullNestedDialog } = this.state;
    // Remove this later
    console.log('R1');

    return (
      <Dialog
        className="modal-size employer-timelog-view-dialog"
        // className={mode !== 'employer' ? 'mui-dialog-background' : ''}
        open={open}
        onClose={(evt) => {
          evt.stopPropagation();
          // Null the employee ID on dialog close so that employer's own work hours/trips work
          employerContextStore.setCurrentEmployeeId(null);
          this.onClose();
        }}
      >
        {/* <DialogTitle>{`Korjaa: ${workOrderTrips.startDate?.date.format('D.M.') || '?'}-${workOrderTrips.endDate?.toDate.format('D.M.') || '?'}`}</DialogTitle> */}
        <DialogTitle
          style={{ display: showFullNestedDialog && 'none' }}
        >
          Korjaa
        </DialogTitle>
        <DialogContent
          style={{ fontSize: '16px', color: 'white', padding: 0 }}
        >
          {/* <div>Timelog view here</div> */}
          {!workOrdersWithSalaryPeriod && (
            <div style={{ width: '100%', textAlign: 'center', padding: '20px' }}>
              <LoadingSpinner color="black" />
            </div>
          )}

          {/* <Theme> */}
          {workOrdersWithSalaryPeriod && (
            <div style={{ width: '100%' }}>
              {workOrdersWithSalaryPeriod.map((workOrderWithSalaryPeriod) => (
                <TimelogView
                  key={workOrderWithSalaryPeriod.workOrder.id}
                  disabled={workOrderWithSalaryPeriod.disabled}
                  locked={workOrderWithSalaryPeriod.locked}
                  from={workOrderWithSalaryPeriod.from}
                  periodId={workOrderWithSalaryPeriod.periodId}
                  to={workOrderWithSalaryPeriod.to}
                  workOrder={{...workOrderWithSalaryPeriod.workOrder}}
                  periodFrom={workOrderWithSalaryPeriod.periodFrom}
                  periodTo={workOrderWithSalaryPeriod.periodTo}
                  workOrderTrips={workOrderTrips}
                  allowanceAutofillReminder={workOrderWithSalaryPeriod.allowanceAutofillReminder}
                  employerUpdateMode={true}
                  resetUpdatedByEmployer={this.resetUpdatedByEmployer}
                  initialTabIndex={initialTabIndex || undefined}
                  employerContextTripId={workOrderTripId}
                  employerContextTripFrom={workOrderTripId ? workOrderTrips[0].startTrip.date : null}
                  employerContextTripTo={workOrderTripId ? (workOrderTrips[0].endTrip?.toDate || workOrderWithSalaryPeriod.periodTo) : null}
                  relayUpdatedWorkHours={this.buildBulkUpdatePackage}
                  highlightWorkHourId={highlightWorkHourId}
                  openDialogCallback={this.toggleShowFullNestedDialog}
                />
              ))}
            </div>
          )}
          {/* </Theme> */}
        </DialogContent>
        <DialogActions
          className={Object.keys(bulkUpdatePackages).length > 0 ? "dialog-actions-less-padding justify-content-space-between" : "dialog-actions-less-padding"}
          style={{
            display: showFullNestedDialog && 'none',
            gap: '20px',
          }}
        >
          {Object.keys(bulkUpdatePackages).length > 0 ? (
            <>
              <Button
                className="employer-accept-button"
                style={{
                  borderRadius: '0px',
                  paddingTop: 0,
                  paddingBottom: 0,
                  lineHeight: '1.3',
                }}
                onClick={() => {
                  // TODO: Optimize
                  // Call employerUpdate only once with all the bulkUpdatePackages instead of this loop
                  Object.values(bulkUpdatePackages).forEach((updatePackage) => {
                    timelogStore.employerUpdateAndAccept(updatePackage.workOrderParam, updatePackage.workHours, updatePackage.employerContextTripId, afterUpdate);
                  });
                  this.onClose();
                }}
              >
                Tallenna ja hyväksy
              </Button>

              <Button
                className="employer-accept-button"
                style={{
                  borderRadius: '0px',
                }}
                onClick={() => {
                  // TODO: Optimize
                  // Call employerUpdate only once with all the bulkUpdatePackages instead of this loop
                  Object.values(bulkUpdatePackages).forEach((updatePackage) => {
                    timelogStore.employerUpdate(updatePackage.workOrderParam, updatePackage.workHours, updatePackage.employerContextTripId, afterUpdate);
                  });
                  this.onClose();
                }}
              >
                Tallenna
              </Button>

              <Button
                className="employer-reject-button"
                style={{
                  borderRadius: '0px',
                }}
                onClick={() => this.onClose()}
              >
                Peruuta
              </Button>
            </>
          ) : (
            <Button
              className="employer-reject-button"
              style={{
                borderRadius: '0px',
              }}
              onClick={() => this.onClose()}
            >
              Sulje
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default EmployerTimelogViewDialog;
