import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
// import { fromPromise } from 'mobx-utils';
// import { when } from 'mobx';
import {
  Card, CardActions, CardActionButtons, CardActionButton,
} from '@rmwc/card';
// import { TextField } from '@rmwc/textfield';
import { Icon } from '@rmwc/icon';
// import { Checkbox } from '@rmwc/checkbox';
// import {
//   Dialog, DialogActions, DialogButton, DialogContent, // DialogTitle,
// } from '@rmwc/dialog';
// import { CollapsibleList, SimpleListItem } from '@rmwc/list';
// import { Button } from '@material-ui/core';
import { isEqual } from 'lodash';
import moment, { shortDateRange } from '../utils/moment';
import { buttonColors } from '../calendar/EmployeeCalendar';
// import LoadingSpinner from '../shared/LoadingSpinner';
// import WorkOrderTrip from '../timelog/WorkOrderTrip';
// import StartTrip from '../timelog/StartTrip';
// import EndTrip from '../timelog/EndTrip';
import EmployerTimelogViewDialog from './EmployerTimelogViewDialog';
import './employerTimelogCard.css';
import SalaryPeriodAttachmentDialog from './SalaryPeriodAttachmentDialog';
// eslint-disable-next-line import/no-cycle
// import EmployerWorkOrderTripCard from '../employer-work-order-trips/employerWorkOrderTripCard';

const renderRouteLocations = (routeLocations, oldRouteLocations) => {
  const routeLocationWrappers = [];

  const sortedRouteLocations = routeLocations.sort((a, b) => (a.location_order.valueOf() - b.location_order.valueOf()));
  const sortedOldRouteLocations = oldRouteLocations?.sort((a, b) => (a.location_order.valueOf() - b.location_order.valueOf()));

  const currentRouteLocationElements = [];
  sortedRouteLocations.forEach((routeLocation, index) => {
    currentRouteLocationElements.push(
      <div key={routeLocation.id} style={{ marginTop: index === 0 ? '0' : '10px' }}>
        <span>{routeLocation.street}</span>
        {routeLocation.zip_code && <span style={{ marginLeft: '5px' }}>{routeLocation.zip_code}</span>}
        {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
      </div>,
    );
  });

  routeLocationWrappers.push(
    <div key="current_wrapper">
      {currentRouteLocationElements}
    </div>,
  );

  const oldRouteLocationElements = [];
  // If route has changed, render the old route too
  if (sortedOldRouteLocations && !isEqual(sortedRouteLocations, sortedOldRouteLocations)) {
    sortedOldRouteLocations.forEach((routeLocation, index) => {
      oldRouteLocationElements.push(
        <div key={`${routeLocation.id}_old_location`} style={{ marginTop: index === 0 ? '0' : '10px' }} className="old-value">
          <span>{routeLocation.street}</span>
          {routeLocation.zip_code && <span style={{ marginLeft: '5px' }}>{routeLocation.zip_code}</span>}
          {routeLocation.city && <span style={{ marginLeft: '5px' }}>{routeLocation.city}</span>}
        </div>,
      );
    });

    routeLocationWrappers.push(
      <div key="old_wrapper" style={{ marginTop: '10px' }}>
        {oldRouteLocationElements}
      </div>,
    );
  }

  return routeLocationWrappers;
};

const renderTripRoutes = (tripRoutes, oldTripRoutes, key) => {
  const tripRouteElements = [];
  tripRoutes.forEach((tripRoute, index) => {
    if (tripRoute.kms_type === key) {
      tripRouteElements.push(
        <div key={`${tripRoute.id}_trip_route`} style={{ paddingLeft: '10px' }}>
          <div>{`Reitti ${index + 1}:`}</div>
          <div style={{ paddingLeft: '20px', paddingTop: '5px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Selite:</span>
              <span style={{ textAlign: 'end', hyphens: 'auto', marginBottom: '5px' }}>{tripRoute.description}</span>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span>Etäisyys (km):</span>
              <span>{tripRoute.kms}</span>
            </div>
          </div>
          <div style={{ padding: '10px 0 10px 25px' }}>
            {/* eslint-disable-next-line camelcase */}
            {renderRouteLocations(tripRoute.route_locations, oldTripRoutes?.[index]?.route_locations)}
          </div>
        </div>,
      );
    }
  });
  return tripRouteElements;
};

// const renderComparedRouteValues = (currentValue, oldRouteLocations, oldValue) => {
//   if (currentValue || oldValue) {
//     currentValue = currentValue || '?'
//     oldValue = oldValue || '?'
//     // The previous trip version does not exist, no need to show old value as ?
//     if (!oldRouteLocations) {
//       oldValue = null;
//     }
//     if (currentValue === oldValue) {
//       // No change
//       return (
//         <div>
//           <span>{currentValue}</span>
//         </div>
//       );
//     } else {
//       // Value changed
//       return (
//         <div>
//           <span>{currentValue}</span>
//           <span className="old-value">{oldValue}</span>
//         </div>
//       );
//     }
//   } else {
//     // Both values null, render nothing
//     return null;
//   }
// }

const renderAbsenceEntryTypeNames = (absenceEntryObjects) => {
  const names = absenceEntryObjects.map((obj) => obj.absence_type_official_name);
  const uniqueNames = [...new Set(names)];
  const renderString = uniqueNames.join(', ');
  return renderString;
};

@inject('timelogStore', 'uiStore', 't')
@observer
class EmployerTimelogCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // rejecting: false,
      // sendSMS: false,
      // loading: false,

      // showWorkOrderTripDialog: false,
      showSalaryPeriodAttachmentDialog: false,
      employerTimelogViewDialogOpen: false,
    };
  }

  // updateWorkOrderTrip = (newWorkOrderTrip) => {
  //   const { showWorkOrderTripDialog } = this.state;

  //   if (showWorkOrderTripDialog) {
  //     this.setState({
  //       openedWorkOrderTripWithMeta: newWorkOrderTrip,
  //     });
  //   }
  // }

  toggleSalaryPeriodAttachmentDialog = () => {
    this.setState((prevState) => ({ showSalaryPeriodAttachmentDialog: !prevState.showSalaryPeriodAttachmentDialog }));
  }

  closeEmployerTimelogViewDialog = () => {
    this.setState({
      employerTimelogViewDialogOpen: false,
    });
  }

  employerAccept(workOrderId, workHourId) {
    const { timelogStore, uiStore, updateWorkHour } = this.props;
    const user = uiStore.currentUser;

    this.setState({
      // loading: true,
    }, () => {
      new Promise((resolve, reject) => timelogStore.employerAccept(user, workOrderId, workHourId, resolve, reject))
        .then((response) => {
          // Success
          this.setState({
            // loading: false,
          }, updateWorkHour(response.body, response.body.status, response.body.employer_comment));
        })
        .catch((err) => {
          // Failure
          this.setState({
            // loading: false,
          }, console.log('ERROR: ', err));
        });
    });
  }

  openEmployerTimelogViewDialog(workHourId) {
    this.setState({
      employerTimelogViewDialogOpen: true,
      highlightWorkHourId: workHourId,
    });
  }

  renderInnerTitle() {
    const {
      workHourWithMeta: { work_hour: { status, employer_comment: employerComment }, salary_period_attachments: salaryPeriodAttachments },
    } = this.props;

    let icon = '';
    let text = '';
    let color = null;

    if (status === 'rejected') {
      icon = 'info';
      text = 'Lähetetty korjattavaksi';
      color = 'var(--mdc-theme-primary)';
    } else if (status === 'pending') {
      icon = 'done';
      text = 'Odottaa hyväksyntää';
      color = 'var(--mdc-theme-primary)';
    } else if (status === 'accepted') {
      icon = 'done_all';
      text = 'Hyväksytty';
      color = buttonColors.green.color;
    } else if (status === 'draft') {
      icon = 'edit';
      text = 'Kirjattu ja odottaa työntekijän hyväksyntää';
      color = 'var(--mdc-theme-primary)';
    } else if (status === 'not_created') {
      icon = 'help_outline';
      text = 'Odottaa kirjausta';
      color = 'var(--mdc-theme-primary)';
    }

    return (
      <>
        <div className="employer-work-hour-card-title" style={{ paddingTop: 0 }}>
          {/* <Icon icon={icon} style={{ fontSize: '22px', color, position: 'absolute', marginTop: '4px' }} /> */}
          <div
            style={{ color, display: 'flex' }}
          >
            <Icon icon={icon} style={{ fontSize: '22px' }} />
            <div style={{ marginLeft: '5px', alignSelf: 'center' }}>
              {text}
            </div>
          </div>
          {status !== 'draft' && salaryPeriodAttachments && salaryPeriodAttachments.length > 0 && (
            <div
              role="button"
              style={{ cursor: 'pointer', alignSelf: 'center' }}
              onClick={() => this.toggleSalaryPeriodAttachmentDialog()}
            >
              {`Liitteet: ${salaryPeriodAttachments.length} kpl`}
            </div>
          )}
        </div>

        {status !== 'accepted' && status !== 'draft' && employerComment && (
          <div className="employer-work-hour-card-title" style={{ paddingTop: 0 }}>
            <div className="employer-work-hour-card-title-inner" style={{ padding: 0 }}>
              {status !== 'rejected' && (<Icon icon="info" />)}
              <div
                className="employer-work-hour-card-title-inner-text"
                style={{ paddingLeft: employerComment && status === 'rejected' ? '24px' : '0' }}
              >
                {`"${employerComment}"`}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  renderTrip(workOrderTrip) {
    const { dialogMode, openWorkOrderTripAcceptanceDialog } = this.props;
    const tripDateRange = shortDateRange(workOrderTrip.start_trip.date, workOrderTrip.end_trip.to_date);

    return (
      <div
        key={workOrderTrip.id}
        onClick={() => !dialogMode && openWorkOrderTripAcceptanceDialog(workOrderTrip.id)}
        style={{
          display: 'flex',
          flexDirection: 'column',
          cursor: 'pointer',
          textAlign: 'end',
        }}
        role="button"
      >
        {`Matka ${tripDateRange}`}
      </div>
    );
  }

  renderWorkTaskEntries() {
    const { workHourWithMeta } = this.props;

    return (
      <div>
        {/* Current work task entries */}
        {workHourWithMeta.work_hour.work_task_entries.map((workTaskEntry) => (
          <div key={workTaskEntry.id} style={{ padding: '10px' }}>
            <div>{workTaskEntry.task_identifier_two ? `${workTaskEntry.task_identifier_one} - ${workTaskEntry.task_identifier_two}` : workTaskEntry.task_identifier_one}</div>
            <div style={{ marginLeft: '10px' }}>
              <span style={{ fontWeight: '600' }}>
                {`${workTaskEntry.from ? moment(workTaskEntry.from).format('HH:mm') : '?'} - ${workTaskEntry.to ? moment(workTaskEntry.to).format('HH:mm') : '?'}`}
              </span>
            </div>
            <div style={{ marginLeft: '10px' }}>
              {/* Time normal */}
              {workTaskEntry.time_normal && (
                <span>{`Normaali: ${workTaskEntry.time_normal}`}</span>
              )}
              {/* Time 50 */}
              {workTaskEntry.time_50 && (
                <span style={{ marginLeft: workTaskEntry.time_normal ? '20px' : '0' }}>
                  {`50%: ${workTaskEntry.time_50}`}
                </span>
              )}
              {/* Time 100 */}
              {workTaskEntry.time_100 && (
                <span style={{ marginLeft: workTaskEntry.time_normal || workTaskEntry.time_50 ? '20px' : '0' }}>
                  {`100%: ${workTaskEntry.time_100}`}
                </span>
              )}
            </div>
            {/* Description */}
            {workTaskEntry.description && (
              <div style={{ marginLeft: '10px' }}>
                {workTaskEntry.description}
              </div>
            )}
          </div>
        ))}

        {/* Render the old work task entries if they're not equal to the current ones, for comparison */}
        {!isEqual(workHourWithMeta.work_hour.work_task_entries, workHourWithMeta.old_work_hour.work_task_entries) && (
          workHourWithMeta.old_work_hour.work_task_entries?.map((workTaskEntry) => (
            <div key={`${workTaskEntry.id}-old`} style={{ padding: '10px' }} className="old-value">
              <div>
                {workTaskEntry.task_identifier_two ? `${workTaskEntry.task_identifier_one} - ${workTaskEntry.task_identifier_two}` : workTaskEntry.task_identifier_one}
              </div>
              {/* REMOVE THIS DIV?? */}
              <div style={{ marginLeft: '10px' }}>
                <span style={{ fontWeight: '600' }}>
                  {`${workTaskEntry.from ? moment(workTaskEntry.from).format('HH:mm') : '?'} - ${workTaskEntry.to ? moment(workTaskEntry.to).format('HH:mm') : '?'}`}
                </span>
              </div>
              <div style={{ marginLeft: '10px' }}>
                {/* Time normal */}
                {workTaskEntry.time_normal && (
                  <span>{`Normaali: ${workTaskEntry.time_normal}`}</span>
                )}
                {/* Time 50 */}
                {workTaskEntry.time_50 && (
                  <span style={{ marginLeft: workTaskEntry.time_normal ? '20px' : '0' }}>
                    {`50%: ${workTaskEntry.time_50}`}
                  </span>
                )}
                {/* Time 100 */}
                {workTaskEntry.time_100 && (
                  <span style={{ marginLeft: '20px' }}>
                    {`100%: ${workTaskEntry.time_100}`}
                  </span>
                )}
              </div>
              {/* Description */}
              {workTaskEntry.description && (
                <div style={{ marginLeft: '10px' }}>
                  {workTaskEntry.description}
                </div>
              )}
            </div>
          ))
        )}
      </div>
    );
  }

  renderAttributeValues(key, currentValue, previousValues) {
    const { t } = this.props;
    const valueElements = [];
    const previousValue = previousValues[key];

    // ENUM ATTRIBUTES, need to be stacked + translated
    // First value: either currentValue or a null placeholder to signify removal
    if (currentValue) {
      // Boolean translation
      if (key === 'emergency_work') {
        valueElements.push(<span key={key}>{t(`timelog.attributes.${currentValue}`)}</span>);
      } else {
        // TODO: ROUND HERE
        valueElements.push(<span key={key}>{currentValue}</span>);
      }
    } else if (previousValue) {
      // Boolean attribute placeholder for null value
      if (key === 'emergency_work') {
        valueElements.push(<span key={key}>{t('timelog.attributes.false')}</span>);
        // Standard numeric attribute placeholder for null value
      } else if (key === 'description') {
        valueElements.push(<span key={key}>?</span>);
      } else {
        valueElements.push(<span key={key}>0</span>);
      }
    }

    // Second value: either previousValue or a null placeholder to signify a brand new value
    if (previousValue && previousValue !== currentValue) {
      if (key === 'emergency_work') {
        valueElements.push(
          <span key={`${key}-old`} className="old-value">{t(`timelog.attributes.${previousValue}`)}</span>,
        );
      } else {
        // TODO: ROUND HERE
        valueElements.push(
          <span key={`${key}-old`} className="old-value">{previousValue}</span>,
        );
      }
    } else if (currentValue && previousValue === null) {
      valueElements.push(
        <span key={`${key}-old`} className="old-value">?</span>,
      );
    }

    return valueElements;
  }

  // eslint-disable-next-line class-methods-use-this
  renderTime() {
    const {
      workHourWithMeta: { work_hour: workHour, old_work_hour: oldWorkHour, work_hour: { status } },
    } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span
          className="employer-work-hour-card-date"
        >
          {moment(workHour.date).format('dd DD.MM.YYYY')}
        </span>
        {/* We do not want to show the user-given time values for drafts because they'll likely change */}
        {status !== 'draft' && (
          <span>
            {workHour.from ? moment(workHour.from).format('HH:mm') : '?'}
            {/* If "from" has changed from a non-null value to another */}
            {oldWorkHour.from && oldWorkHour.from !== workHour.from ? <span className="old-value">{moment(oldWorkHour.from).format('HH:mm')}</span> : null}
            {/* If "from" didn't exist in the previous work hour version but exists now */}
            {oldWorkHour.from === null && workHour.from !== null ? <span className="old-value">?</span> : null}
            &nbsp;-&nbsp;
            {workHour.to ? moment(workHour.to).format('HH:mm') : '?'}
            {/* If "to" has changed from a non-null value to another */}
            {oldWorkHour.to && oldWorkHour.to !== workHour.to ? <span className="old-value">{moment(oldWorkHour.to).format('HH:mm')}</span> : null}
            {/* If "to" didn't exist in the previous work hour version but exists now */}
            {oldWorkHour.to === null && workHour.to !== null ? <span className="old-value">?</span> : null}
          </span>
        )}
      </div>
    );
  }

  renderButtons() {
    const {
      workHourWithMeta,
      workHourWithMeta: { work_hour: workHour, work_hour: { status, work_order_id: workOrderId, user_id: userId } },
      openRejectWorkHourDialog,
      afterUpdate,
    } = this.props;
    // const { sendSMS, rejecting, employerTimelogViewDialogOpen } = this.state;
    const { employerTimelogViewDialogOpen, highlightWorkHourId } = this.state;

    const rejectDialogTitle = `${workHourWithMeta.user.full_name} - ${workHourWithMeta.work_order_info.name}, ${moment(workHourWithMeta.work_hour.date).format('dd DD.MM.YY')}`;

    return (
      <div>
        {/* <Button onClick={() => this.openEmployerTimelogViewDialog()}>Korjaa itse placeholder</Button> */}
        <EmployerTimelogViewDialog
          workOrderIds={[workOrderId]}
          employeeId={userId}
          open={employerTimelogViewDialogOpen}
          onClose={this.closeEmployerTimelogViewDialog}
          highlightWorkHourId={highlightWorkHourId}
          afterUpdate={afterUpdate}
        />

        {status !== 'draft' && (
          <>
            <CardActions>
              <CardActionButtons className="work-hour-card-buttons">
                {/* Hide the accept button if the work hour has already been accepted or we are in the reject mode */}
                {/* {rejecting || status === 'accepted' ? (<div style={{ width: '87px' }} />) : (
                  <CardActionButton
                    className="employer-accept-button"
                    // style={{ width: '87px' }}
                    onClick={() => this.employerAccept(workOrderId, workHour.id)}
                  >
                    Hyväksy
                  </CardActionButton>
                )} */}

                <CardActionButton
                  className="employer-reject-button"
                  // style={{ width: '87px' }}
                  style={{ width: '140px' }}
                  // onClick={() => this.employerReject(workHourWithMeta.work_hour.work_order_id, workHourWithMeta.work_hour.id)}
                  onClick={() => this.openEmployerTimelogViewDialog(workHour.id)}
                >
                  Korjaa itse
                </CardActionButton>

                <CardActionButton
                  className="employer-reject-button"
                  style={{ width: '140px' }}
                  onClick={() => {
                    if (openRejectWorkHourDialog) {
                      openRejectWorkHourDialog(workHour, workOrderId, rejectDialogTitle, 'single');
                    }
                  }}
                >
                  Pyydä korjaus
                </CardActionButton>

                {/* {rejecting ? (
                  <CardActionButton
                    className="employer-reject-button"
                    // style={{ width: '87px' }}
                    // onClick={() => this.employerReject(workHourWithMeta.work_hour.work_order_id, workHourWithMeta.work_hour.id)}
                    onClick={() => this.employerReject(workOrderId, workHour.id, sendSMS)}
                  >
                    Lähetä
                  </CardActionButton>
                ) : null}

                {rejecting ? (
                  <CardActionButton
                    className="employer-cancel-button"
                    style={{ width: '117px' }}
                    onClick={() => this.cancelReject()}
                  >
                    Peruuta
                  </CardActionButton>
                ) : (
                  <CardActionButton
                    className="employer-reject-button"
                    style={{ width: '117px' }}
                    onClick={() => { this.setState({ rejecting: true }); }}
                  >
                    Korjattava
                  </CardActionButton>
                )} */}
              </CardActionButtons>
            </CardActions>
            {status !== 'accepted' && status !== 'not_created' && (
              <CardActions style={{ paddingTop: '0' }}>
                <CardActionButtons className="work-hour-card-buttons">
                  <CardActionButton
                    className="employer-accept-button"
                    style={{ width: '100%' }}
                    onClick={() => this.employerAccept(workOrderId, workHour.id)}
                  >
                    Hyväksy
                  </CardActionButton>
                </CardActionButtons>
              </CardActions>
            )}
          </>
        )}
      </div>
    );
  }

  renderAttributes(workHour, previousValues) {
    const { t, workHourWithMeta, uiStore: { currentUser: { accountInfo: { workOrderTripsEnabled } } } } = this.props;
    const workHourTypeElements = [];

    // We do not want to render the following columns as regular compared values
    const disallowed = [
      'id',
      'user_id',
      'work_order_id',
      'account_id',
      'created_at',
      'updated_at',
      'date',
      'status',
      'from',
      'to',
      'employer_comment',
      'trip_routes',
      'work_task_entries',
      'accepted_by_id',
      'accepted_by',
      'accepted_at',
      'rejected_by_id',
      'rejected_by',
      'rejected_at',
      'latest_employee_version',
      // Rendered separately, last
      'allowance',
      'tax_exempt_trip_expenses',
      'description',
      'work_order_trip_employer_comment',
    ];
    // For the route that is sometimes nested in the work hour to explain kms
    const currentRoutes = workHourWithMeta.work_hour.trip_routes;
    const oldRoutes = workHourWithMeta.old_work_hour.trip_routes;

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(workHour)) {
      if ((value || previousValues[key]) && !disallowed.includes(key)) {
        if (value === 'false') {
          if (previousValues[key]) {
            workHourTypeElements.push(
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
                key={key}
              >
                <span>{t(`timelog.attributes.${key}`)}</span>
                <span>{this.renderAttributeValues(key, value, previousValues)}</span>
              </div>,
            );
            workHourTypeElements.push(renderTripRoutes(currentRoutes, oldRoutes, key));
          }
        } else if (previousValues[key] === 'false') {
          if (value) {
            workHourTypeElements.push(
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '10px',
                }}
                key={key}
              >
                <span>{t(`timelog.attributes.${key}`)}</span>
                <span>{this.renderAttributeValues(key, value, previousValues)}</span>
              </div>,
            );
            workHourTypeElements.push(renderTripRoutes(currentRoutes, oldRoutes, key));
          }
        } else {
          workHourTypeElements.push(
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '10px',
              }}
              key={key}
            >
              <span>{t(`timelog.attributes.${key}`)}</span>
              <span className={key === 'description' ? 'employer-work-hour-card-description' : null}>{this.renderAttributeValues(key, value, previousValues)}</span>
            </div>,
          );
          workHourTypeElements.push(renderTripRoutes(currentRoutes, oldRoutes, key));
        }
      }
    }

    // if (workHour.tax_exempt_trip_expenses.length > 0) {
    //   // workHourTypeElements.push(<div>{`${workHour.tax_exempt_trip_expenses.length} matkakustannusten korvausta`}</div>);
    //   workHourTypeElements.push(
    //     <CollapsibleList
    //       handle={(
    //         <SimpleListItem
    //           className="allowance-list-item"
    //           text={(
    //             <div style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
    //               {/* {allowanceSumRows} */}
    //               {workHour.tax_exempt_trip_expenses.length === 1 ? `${workHour.tax_exempt_trip_expenses.length} matkakustannusten korvaus` : `${workHour.tax_exempt_trip_expenses.length} matkakustannusten korvausta` }
    //             </div>
    //           )}
    //           metaIcon="chevron_right"
    //         />
    //       )}
    //     >
    //       <div style={{ display: 'flex', flexDirection: 'column', padding: '0 10px' }}>
    //         {workHour.tax_exempt_trip_expenses.map((expense) => (
    //           <div>{`${expense.name}: ${expense.value}`}</div>
    //         ))}
    //       </div>
    //     </CollapsibleList>,
    //   );
    // }

    const currentAllowance = workHour.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value;
    const previousAllowance = previousValues.tax_exempt_trip_expenses?.find((expense) => expense.name === 'allowance')?.value;
    if (currentAllowance || previousAllowance) {
      const allowanceWithTrip = [];
      const stackedElement = [];
      // Current allowance value (left side, top)
      if (currentAllowance) {
        stackedElement.push(<span key="allowance">{t(`timelog.attributes.${currentAllowance}`)}</span>);
      } else if (previousAllowance) {
        stackedElement.push(<span key="allowance">{t('timelog.attributes.none')}</span>);
      }
      // Previous allowance value (left side, bottom, beneath the current value)
      if (previousAllowance && previousAllowance !== currentAllowance) {
        stackedElement.push(<span key="allowance-old" className="old-value">{t(`timelog.attributes.${previousAllowance}`)}</span>);
      } else if (currentAllowance && previousAllowance === null) {
        stackedElement.push(<span key="allowance-old" className="old-value">{t('timelog.attributes.none')}</span>);
      }
      allowanceWithTrip.push(
        <div key="allowance" style={{ display: 'flex', flexDirection: 'column' }}>
          {stackedElement}
        </div>,
      );

      if (workOrderTripsEnabled) {
        // The trip (right side)
        if (Object.keys(workHourWithMeta.work_order_trip).length !== 0) {
          allowanceWithTrip.push(this.renderTrip(workHourWithMeta.work_order_trip));
        } else {
          allowanceWithTrip.push(<div key="no-trip" style={{ textAlign: 'end', color: 'orangered' }}>Ei matkaa</div>);
        }
      }

      workHourTypeElements.push(<div key="allowance-with-trip" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>{allowanceWithTrip}</div>);
    }

    if (workHour.description) {
      workHourTypeElements.push(
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
          key="description"
        >
          <span>{t('timelog.attributes.description')}</span>
          <span className="employer-work-hour-card-description">
            {this.renderAttributeValues('description', workHour.description, previousValues)}
          </span>
        </div>,
      );
    }

    return (
      <div
        style={{
          padding: '10px',
        }}
      >
        {workHourTypeElements}
      </div>
    );
  }

  renderSalaryPeriodAttachmentDialog() {
    const { workHourWithMeta } = this.props;
    const { showSalaryPeriodAttachmentDialog } = this.state;

    return (
      <SalaryPeriodAttachmentDialog
        title={`${workHourWithMeta.user.first_name}. ${workHourWithMeta.user.last_name} - ${workHourWithMeta.work_order_info.name}`}
        // title={`${workHourWithMeta.user.name} - ${workHourWithMeta.work_order_name}`}
        open={showSalaryPeriodAttachmentDialog}
        files={workHourWithMeta.salary_period_attachments}
        toggleDialog={this.toggleSalaryPeriodAttachmentDialog}
      />
    );
  }

  render() {
    const {
      workHourWithMeta,
      dialogMode,
      uiStore: { currentUser, currentUser: { accountInfo: { salaryPeriodCategoriesEnabled, absencesEnabled } } },
      openEmployeeInfoDialog,
      openWorkOrderInfoDialog,
      // nextDeadline,
    } = this.props;

    // let border;
    // if (salaryPeriodCategoriesEnabled) {
    //   // Same colors as in billing statuses, red and orange
    //   border = nextDeadline ? '6px solid #FF6A00' : '6px solid #FF9E00';
    // }

    return (
      <>
        {this.renderSalaryPeriodAttachmentDialog()}
        <Card
          className="employer-work-hour-card no-bg"
          key={workHourWithMeta.work_hour.status === 'not_created' ? `${workHourWithMeta.work_hour.user_id}-${workHourWithMeta.work_hour.date}-${workHourWithMeta.work_hour.work_order_id}` : workHourWithMeta.work_hour.id}
          style={{ position: 'relative', margin: dialogMode ? '0' : '' }}
        >
          <div
            style={{ borderLeft: salaryPeriodCategoriesEnabled && `6px solid ${workHourWithMeta.salary_period.color}` }}
          >
            <div
              className="employer-work-hour-card-title"
            >
              <span
                role="button"
                onClick={() => openEmployeeInfoDialog && openEmployeeInfoDialog(workHourWithMeta.user)}
                style={{ cursor: 'pointer' }}
              >
                {workHourWithMeta.user.full_name}
              </span>

              <span
                role="button"
                onClick={() => openWorkOrderInfoDialog && openWorkOrderInfoDialog(workHourWithMeta.work_order_info)}
                style={{ cursor: 'pointer' }}
              >
                {workHourWithMeta.work_order_info.name}
              </span>
            </div>

            {this.renderInnerTitle()}
          </div>

          <div
            style={{
              padding: '10px',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            {this.renderTime()}
          </div>

          {/* eslint-disable-next-line camelcase */}
          {absencesEnabled && workHourWithMeta?.absence_entries && workHourWithMeta?.absence_entries.length > 0 && (
            <div
              style={{
                whiteSpace: 'nowrap',
                margin: workHourWithMeta.work_hour.status !== 'not_created' ? '0 10px 10px 10px' : '',
                // Use padding instead of margin if status is not_created, margin goes outside the container
                padding: workHourWithMeta.work_hour.status !== 'not_created' ? '' : '0 10px 10px 10px',
                display: 'flex',
              }}
            >
              {/* eslint-disable-next-line camelcase */}
              {/* <Icon icon="beach_access" style={{ fontSize: '20px' }} /> */}
              {/* <span style={{ margin: 'auto 0', whiteSpace: 'normal', marginLeft: '5px' }}>
                {renderAbsenceEntryTypeNames(workHourWithMeta.absence_entries)}
              </span> */}

              <div
                style={{
                  whiteSpace: 'nowrap',
                  width: 'fit-content',
                  height: 'fit-content',
                  backgroundColor: '#E5EAEE',
                  color: '#7E62D0',
                  padding: '2px',
                  fontSize: '11px',
                  lineHeight: 1,
                  textTransform: 'uppercase',
                  margin: 'auto 0',
                }}
              >
                {renderAbsenceEntryTypeNames(workHourWithMeta.absence_entries)}
              </div>
            </div>
          )}

          {workHourWithMeta.work_hour.status !== 'draft' && (
            <div
              style={{
                padding: '0 20px 0 20px',
                fontSize: '14px',
              }}
            >
              {workHourWithMeta.work_hour.work_task_entries && this.renderWorkTaskEntries()}
              {this.renderAttributes(workHourWithMeta.work_hour, workHourWithMeta.old_work_hour)}
            </div>
          )}

          {/* Show the action buttons only if the employer can actually use them (one of the accepting employers) */}
          {/* {workHourWithMeta.accepting_employers.includes(currentUser.getFullname()) ? (
            this.renderButtons()) : (
              <div>Keikan tuntikirjausten hyväksyjät voivat käsitellä tuntikirjauksen</div>
          )} */}
          {workHourWithMeta.accepting_employers.includes(currentUser.getFullname()) && this.renderButtons()}
        </Card>
      </>
    );
  }
}

export default EmployerTimelogCard;
